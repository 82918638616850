import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import i18n from '../../i18n';
import * as yup from 'yup';
import Background from '../../assets/images/bg-sing-in.jpg';

import * as actions from '../../actions/app.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormInput } from '../../components/Form';

const validationSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  onSubmit = (values, { setSubmitting, setErrors }) => {
    this.props.signIn({ username: values.username.trim(), password: values.password.trim() }, setErrors);
    setSubmitting(false);
  };

  showPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { showPassword } = this.state;

    return (
      <div className="form-signin" style={{ backgroundImage: `url("${Background}")` }}>
        <div className="logo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 86">
            <defs>
              <path
                id="a"
                d="M27.207 31.269c0 8.927 7.263 16.189 16.189 16.189 8.927 0 16.19-7.262 16.19-16.189 0-8.926-7.263-16.189-16.19-16.189-8.926 0-16.189 7.263-16.189 16.189"
              />
              <linearGradient id="b" x1="7.181%" x2="95.174%" y1="26.898%" y2="74.374%">
                <stop offset="0%" stopColor="#497980" />
                <stop offset="100%" stopColor="#243D49" />
              </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
              <path
                fill="#212529"
                d="M84.793 76.924v-2.008H74.083l-2.75 3.464-2.718-3.464H58.358v9.97h7.568v-2.008h-4.864v-2.065h4.561V78.82h-4.561v-1.895h4.864v-1.467l4.033 4.988v4.441h2.78v-4.44l4.486-5.512v9.952h7.568v-2.008H79.93v-2.065h4.563V78.82h-4.563v-1.895h4.864zm-35.076 1.132c0-.415-.133-.715-.4-.899-.267-.183-.708-.275-1.322-.275h-1.934v2.32h1.934c.614 0 1.055-.09 1.322-.27.267-.179.4-.471.4-.876zm1.525 4.002l1.693 2.828h-3.051l-1.844-3.182c-.231-.368-.599-.551-1.103-.551h-.845v3.733h-2.735v-9.97h5.075c1.29 0 2.26.261 2.909.785.65.523.975 1.28.975 2.27 0 .754-.222 1.392-.665 1.916-.444.523-1.067.875-1.873 1.054.614.122 1.102.495 1.464 1.117zM38.13 80.87l-1.464-3.197L35.2 80.87h2.93zm-.392-5.954l4.985 9.97h-2.75l-.951-2.064h-4.713l-.952 2.064h-2.794l4.97-9.97h2.205zm-10.408 5.7l-4.879-5.7h-2.024v9.97h2.583v-5.7l4.864 5.7h2.054v-9.97H27.33v5.7zm-11.51.169c0 .716-.172 1.268-.515 1.655-.342.386-.835.58-1.48.58s-1.138-.194-1.48-.58c-.342-.387-.513-.939-.513-1.655v-5.87h-2.72v5.771c0 1.414.4 2.489 1.201 3.224.8.736 1.971 1.104 3.512 1.104 1.53 0 2.702-.37 3.513-1.11.81-.741 1.215-1.813 1.215-3.218v-5.77H15.82v5.869zM3.78 82.779h4.532v2.107H1v-9.97h2.78v7.863zM44.232 59.505v-11.46c11.216-.764 22.208-8.98 27.02-13.061-1.888 13.457-13.193 23.93-27.02 24.521zM14.758 35.07c4.821 4.213 15.806 12.618 27.01 13.01v11.425c-13.797-.589-25.085-11.019-27.01-24.435zm27.01-32.576V13.92c-11.204.392-22.189 8.797-27.01 13.01 1.925-13.417 13.213-23.847 27.01-24.436zm29.485 24.522c-4.813-4.08-15.805-12.298-27.021-13.06V2.494c13.827.59 25.132 11.065 27.02 24.522zm-27.021 5.216h26.211c-5.161 4.239-15.588 11.636-26.211 12.385V32.232zm-2.464 0v12.43c-10.715-.346-21.23-8.062-26.344-12.43h26.344zm0-2.464H15.424c5.114-4.367 15.629-12.085 26.344-12.43v12.43zm2.464-12.385c10.623.749 21.05 8.146 26.211 12.385H44.232V17.383zM43 0C25.879 0 12 13.879 12 31c0 17.121 13.879 31 31 31 17.121 0 31-13.879 31-31C74 13.879 60.121 0 43 0z"
              />
              <path
                fill="url(#b)"
                d="M27.207 31.269c0 8.927 7.263 16.189 16.189 16.189 8.927 0 16.19-7.262 16.19-16.189 0-8.926-7.263-16.189-16.19-16.189-8.926 0-16.189 7.263-16.189 16.189"
              />
              <path
                fill="#212529"
                d="M42.897 14.237c-9.535 0-17.264 7.671-17.264 17.133s7.73 17.133 17.264 17.133c9.534 0 17.263-7.67 17.263-17.133 0-9.462-7.73-17.133-17.263-17.133m0 2.448c8.159 0 14.797 6.588 14.797 14.685 0 8.098-6.638 14.685-14.797 14.685-8.16 0-14.797-6.587-14.797-14.685 0-8.097 6.637-14.685 14.797-14.685"
              />
            </g>
          </svg>
        </div>
        <Formik
          onSubmit={this.onSubmit}
          initialValues={{ username: '', password: '' }}
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                {/*<FormInput*/}
                {/*  label={i18n.t('fields:email')}*/}
                {/*  name="username"*/}
                {/*  type="email"*/}
                {/*  className="form-item form-item--one"*/}
                {/*  value={values.username}*/}
                {/*  onBlur={handleBlur}*/}
                {/*  onChange={(name, value) => setFieldValue(name, value)}*/}
                {/*  errorMessage={false}*/}
                {/*/>*/}
                <div className="mb-14">
                  <input
                    id="username"
                    type="text"
                    value={values.username}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="form-control"
                    placeholder={i18n.t('fields:username')}
                  />
                  {errors.username && touched.username && (
                    <div className="invalid-feedback">{errors.username}</div>
                  )}
                </div>

                <div className="form-group-icon form-group-icon-right mb-16">
                  <input
                    id="password"
                    value={values.password}
                    className="form-control"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={i18n.t('fields:password')}
                  />
                  <button type="button" className="btn" onClick={this.showPassword}>
                    {showPassword ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}
                  </button>
                  {errors.password && touched.password && (
                    <div className="invalid-feedback">{errors.password}</div>
                  )}
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn--primary btn--block" disabled={!isValid}>
                    {i18n.t('buttons:signIn')}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
        <div className="copyright">
          ©2022{' '}
          <a href="https://lunar-eye.com/" target="_blank" rel="noopener noreferrer">
            Lunar Eye
          </a>
          . All rights reserved.
          <div>
            <Link to={'/'} target="_blank" rel="noopener noreferrer" className="mr-10">
              Terms of Service
            </Link>
            <Link to={'/'} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  signIn: (data, setError) => dispatch(actions.signIn(data, setError)),
});

export default connect(null, mapDispatchToProps)(SignIn);
