import * as ActionTypes from '../constants/notification.constants';

const initialState = {
  alerts: [],
  allAlerts: [],
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_NOTIFICATION: {
      const updatedAlerts =
        state.alerts.find((el) => el.cameraUuid === action.payload.cameraUuid) !== undefined
          ? state.alerts.map((not) => {
              if (not.cameraUuid === action.payload.cameraUuid) {
                return {
                  ...not,
                  data: [action.payload, ...not.data].slice(0, 20),
                };
              } else {
                return not;
              }
            })
          : [...state.alerts, { cameraUuid: action.payload.cameraUuid, data: [action.payload] }];
      return {
        ...state,
        alerts: updatedAlerts,
        allAlerts: [action.payload, ...state.allAlerts].slice(0, 20),
      };
    }

    case ActionTypes.REMOVE_NOTIFICATION: {
      const updatedAlerts = state.alerts.map((not) =>
        not.cameraUuid === action.payload.cameraUuid
          ? {
              cameraUuid: not.cameraUuid,
              data: not.data.filter((notif) => notif.notificationId !== action.payload.id),
            }
          : not
      );
      return {
        ...state,
        alerts: updatedAlerts,
      };
    }

    case ActionTypes.DISABLE_NOTIFICATION:
    // return state.map((n) => (n.id === action.payload ? { ...n, ...{ active: false } } : n));

    default:
      return state;
  }
};
