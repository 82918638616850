import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../../i18n';
import BaseModal from './BaseModal';
import SubjectPhotos from '../SubjectView/SubjectPhotos';

class ModalAdd extends BaseModal {
  state = {
    ...this.state,
    photos: [],
  };

  getSubjectPhotos = photos => {
    this.setState({ photos });
    return this.state.photos;
  };

  renderSubjectPhotos() {
    return <SubjectPhotos getCallback={this.getSubjectPhotos} />;
  }
}

ModalAdd.defaultProps = {
  ...BaseModal.defaultProps,
  header: i18n.t('subjectModals:addTitle'),
};

ModalAdd.propTypes = {
  uploadSubjectPhoto: PropTypes.func,
  deleteSubjectPhoto: PropTypes.func,
};

export default ModalAdd;
