import React from 'react';
import { Collapse, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const AccordionItem = ({ title, isOpen, onOpen, children }) => {
  return (
    <div>
      {isOpen ? (
        <FontAwesomeIcon icon={faChevronDown} size="sm" onClick={onOpen} />
      ) : (
        <FontAwesomeIcon icon={faChevronRight} size="sm" onClick={onOpen} />
      )}
      <button style={{ color: 'white' }} onClick={onOpen}>
        {title}
      </button>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
};

export default AccordionItem;
