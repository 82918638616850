import request from '../utils/request';

export function uploadMedia(formData) {
  return request({
    url: '/medias',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function removeMedia(uuid) {
  return request({
    url: `/medias/${uuid}`,
    method: 'delete',
  });
}
