import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

const DropdownMenuItem = ({ title, icon, menuItems }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const childrenLinks = menuItems.map((item) => item.to);
  const location = useLocation();
  return (
    <Dropdown isOpen={isMenuOpen} toggle={() => setIsMenuOpen(!isMenuOpen)}>
      <DropdownToggle className="dropdown__button--no-padding">
        <p
          className={cx('header__menu-item', 'header__menu-item--dropdown', 'header__nav-link', {
            'header__nav-link--active': isMenuOpen || childrenLinks.indexOf(location.pathname) >= 0,
          })}
          onClick={(e) => {
            e.preventDefault();
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          <FontAwesomeIcon icon={icon} />
          <span className="header__menu-item__title">{title}</span>
          <FontAwesomeIcon
            icon={isMenuOpen ? faChevronUp : faChevronDown}
            className="header__menu-item__chevron-down"
          />
        </p>
      </DropdownToggle>
      <DropdownMenu container="body" className="header__nav__dropdown-menu">
        {menuItems.map((item) => (
          <DropdownItem key={item.title}>
            <NavLink
              key={item.title}
              to={item.to}
              className="header__nav-link header__nav-link--menu-item"
              onClick={(e) => setIsMenuOpen(false)}
              activeClassName="header__nav-link--active"
            >
              {item.icon && <FontAwesomeIcon icon={item.icon} />}
              <p>{item.title}</p>
            </NavLink>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

DropdownMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object,
  menuItems: PropTypes.array.isRequired,
};

export default DropdownMenuItem;
