import * as ActionTypes from '../constants/player.constants';
import * as CamerasTypes from '../constants/cameras.constants';
import * as HistoryTypes from '../constants/historyData.constants';
import { searchRecordingByTimestamp, getPlayTime, duplicateVideo } from '../utils';
import moment from 'moment';

import airportVideo from '../mocks/clips/airport_15fps_origin.mp4';
import { activityMock } from '../mocks/activityMock';
import airportVideoDetections from '../mocks/clips/show_objects_video_test.mp4';
import { tempActivityMock } from '../mocks/tempActivityMock';
import { analyticsMock } from '../mocks/analytics';

const initialState = {
  sound: true,
  live: true,
  speed: 1,
  pause: false,
  range: process.env.REACT_APP_DEFAULT_PLAYER_RANGE,
  lastCurrentUpdate: null,
  current: null,
  recording: null,
  play: null,
  screen: null,
  requestForHistory: false,
  timestamps: {
    alerts: {},
    widgets: {},
    processing: {},
    recording: [],
    bboxes: {},
  },
};

export const playerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_LIVE_PLAYER: {
      let rec = { recording: null, play: null };
      let status = action.payload ? action.payload : !state.live;
      let current = moment().format();
      if (current) {
        const recording = searchRecordingByTimestamp(current, state.timestamps.recording);
        rec = {
          recording: recording,
          play: getPlayTime(current, recording),
        };
        console.log(
          'SETCURRENT by SET_LIVE_PLAYER part1',
          current,
          status ? null : getPlayTime(current, recording)
        );
      }
      console.log('SETCURRENT by SET_LIVE_PLAYER part2', current);

      return {
        ...state,
        live: status,
        pause: false,
        screen: null,
        current,
        ...rec,
      };
    }

    case ActionTypes.SET_PAUSE_PLAYER:
      return {
        ...state,
        pause: !state.pause,
      };

    case ActionTypes.SET_SOUND_PLAYER:
      return {
        ...state,
        sound: !state.sound,
      };

    case ActionTypes.SET_RANGE_PLAYER:
      return {
        ...state,
        range: action.payload,
      };

    case ActionTypes.SET_SPEED_UP_PLAYER:
      return {
        ...state,
        speed: state.speed + 1,
      };

    case ActionTypes.SET_SPEED_DOWN_PLAYER:
      return {
        ...state,
        speed: state.speed - 1,
      };

    case ActionTypes.SET_CURRENT_PLAYER: {
      let rec = {};
      let current = action.payload.time;
      let recording = searchRecordingByTimestamp(current, state.timestamps.recording);
      rec = {
        recording,
        play: recording ? getPlayTime(current, recording) : null,
      };
      console.log(
        'SETCURRENT by SET_CURRENT_PLAYER',
        current,
        recording ? getPlayTime(current, recording) : null,
        action.payload.saveLive ? state.live : false
      );

      return {
        ...state,
        live: action.payload.saveLive ? state.live : false,
        lastCurrentUpdate: +moment().format('x'),
        screen: null,
        current,
        ...rec,
      };
    }

    case CamerasTypes.GET_CAMERA_ALERTS_RESULT: {
      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          alerts: action.payload.items.map((x) => x.timestamp).sort((a, b) => a - b),
        },
      };
    }

    case CamerasTypes.GET_CAMERA_RECORDINGS_RESULT: {
      return {
        ...state,
      };
    }

    case CamerasTypes.CREATE_CAMERA_RECORDINGS: {
      const recordings = duplicateVideo(
        moment().subtract(24, 'h').valueOf(),
        action.payload.duration * 1000,
        24 * 60 * 60 * 1000 + 30 * 60 * 1000,
        action.payload.url,
        null
      );
      let current = moment().format();
      const recording = searchRecordingByTimestamp(current, recordings);
      const play = getPlayTime(current, recording);

      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          recording: recordings,
        },
        live: true,
        pause: false,
        screen: null,
        current,
        recording,
        play,
      };
    }

    case CamerasTypes.GET_DATASTREAM_RESULT: {
      return {
        ...state,
        recording: {
          ...state.recording,
          detectionsUrl: action.payload.showObjectsVideoUrl
            ? action.payload.showObjectsVideoUrl
            : state.recording.url,
        },
        timestamps: {
          ...state.timestamps,
          recording: state.timestamps.recording.map((recording) => ({
            ...recording,
            detectionsUrl: action.payload.showObjectsVideoUrl
              ? action.payload.showObjectsVideoUrl
              : recording.url,
          })),
        },
      };
    }

    case CamerasTypes.EXTEND_CAMERA_RECORDINGS: {
      const videoDuration = state.timestamps.recording[0].to - state.timestamps.recording[0].from;
      const videoUrl = state.timestamps.recording[0].url;
      const videoDetectionsUrl = state.timestamps.recording[0].detectionsUrl;
      const recordings = duplicateVideo(
        moment().subtract(24, 'h').valueOf(),
        videoDuration,
        24 * 60 * 60 * 1000 + 30 * 60 * 1000,
        videoUrl,
        videoDetectionsUrl
      );
      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          recording: recordings,
        },
      };
    }

    case ActionTypes.SET_TIMESTAMPS_VALUE: {
      return {
        ...state,
        timestamps: {
          ...state.timestamps,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case HistoryTypes.GET_HISTORY_DATA_RESULT: {
      return {
        ...state,
        requestForHistory: false,
        timestamps: {
          ...state.timestamps,
          bboxes: action.payload,
        },
      };
    }

    case ActionTypes.SET_RECORDING_SCREEN: {
      return {
        ...state,
        isLive: false,
        screen: action.payload,
        play: null,
      };
    }

    case ActionTypes.SET_STEP_FORWARD:
      return state;

    case ActionTypes.SET_STEP_BACKWARD:
      return state;

    case CamerasTypes.SET_CAMERA_UNMOUNT:
      return initialState;

    default:
      return state;
  }
};
