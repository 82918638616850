import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import cx from 'classnames';

import { FormInput, FormSelect } from '../Form';
import i18n from '../../i18n';

const AddNameModal = ({ className, isOpen, options, existingNames, onSubmit, onClose, getEquipments }) => {
  const [name, setName] = useState('');
  const [optionLists, setOptionLists] = useState({});
  const [optionValues, setOptionValues] = useState({});

  useEffect(() => {
    options.map((option) => {
      switch (option) {
        case 'equipment':
          getEquipments(
            { page: 0, size: 100 },
            {
              onSuccess: (equipments) =>
                setOptionLists((prev) => ({
                  ...prev,
                  [option]: equipments.items?.map((equipment) => ({
                    label: equipment.name,
                    value: equipment.uuid,
                  })),
                })),
            }
          );
          setOptionValues((prev) => ({ ...prev, [option]: null }));
          break;

        default:
          break;
      }
    });
  }, []);

  const handleSubmit = () => {
    const result = {};

    result.name = name;
    result.options = options.reduce((acc, curr) => ({ ...acc, [curr]: optionValues[curr] }), {});

    if (!existingNames.includes(name)) onSubmit(result);
  };

  return (
    <Modal isOpen={isOpen} className={cx('', className)} fade>
      <ModalHeader toggle={onClose}>{i18n.t('workflowModals:addNameTitle')}</ModalHeader>
      <ModalBody>
        <div className="form-row">
          <FormInput
            type="text"
            className="form-item form-item--one"
            label={i18n.t('common:name')}
            value={name}
            onChange={setName}
            errorMessage={name.length && existingNames.includes(name) ? 'This name already exists' : ''}
          />
        </div>

        {options.map((option) => (
          <div className="form-row_mt-10" key={option}>
            <FormSelect
              className="form-item form-item--one"
              label={`${option
                .split('')
                .map((char, index) => (index === 0 ? char.toUpperCase() : char))
                .join('')}`}
              options={optionLists[option]}
              selected={optionValues[option]}
              isSearch={true}
              onChange={(value) => setOptionValues((prev) => ({ ...prev, [option]: value }))}
            />
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn--secondary" type="submit" disabled={!name.length} onClick={handleSubmit}>
          {i18n.t('buttons:ok')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default AddNameModal;
