import { queryStringify } from '../utils/index';
import request from '../utils/request';

export function getEquipmentTypes(params) {
  return request({
    url: `/equipmentTypes?${queryStringify(params)}`,
    method: 'get',
  });
}

export function getEquipmentType(uuid) {
  return request({
    url: `/equipmentTypes?${uuid}`,
    method: 'get',
  });
}

export function addEquipmentType(params) {
  return request({
    url: '/equipmentTypes',
    method: 'post',
    data: params,
  });
}

export function updateEquipmentType(uuid, params) {
  return request({
    url: `/equipmentTypes/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteEquipmentType(uuid) {
  return request({
    url: `/equipmentTypes/${uuid}`,
    method: 'delete',
  });
}
