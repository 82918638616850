import * as ActionTypes from '../constants/subjects.constants';

const initialState = {
  subject: {},
  loadingGetSubject: false,
  loadingAddSubject: false,
  loadingUpdateSubject: false,
  loadingDeleteSubject: false,
  loadingDeletePhoto: false,
};

export const subjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SUBJECT:
      return {
        ...state,
        loadingGetSubject: true,
      };

    case ActionTypes.ADD_SUBJECT:
      return {
        ...state,
        loadingAddSubject: true,
      };

    case ActionTypes.UPDATE_SUBJECT:
      return {
        ...state,
        loadingUpdateSubject: true,
      };

    case ActionTypes.DELETE_SUBJECT:
      return {
        ...state,
        loadingDeleteSubject: true,
      };

    case ActionTypes.GET_SUBJECT_SUCCESS:
      return {
        ...state,
        subject: action.payload,
        loadingGetSubject: false,
      };

    case ActionTypes.UPDATE_SUBJECT_SUCCESS:
      return {
        ...state,
        subject: action.payload,
        loadingUpdateSubject: false,
      };

    case ActionTypes.DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        subject: {},
        loadingDeleteSubject: false,
      };

    case ActionTypes.GET_SUBJECT_ERROR:
      return {
        ...state,
        loadingGetSubject: false,
      };

    case ActionTypes.ADD_SUBJECT_SUCCESS:
    case ActionTypes.ADD_SUBJECT_ERROR:
      return {
        ...state,
        loadingAddSubject: false,
      };

    case ActionTypes.UPDATE_SUBJECT_ERROR:
      return {
        ...state,
        loadingUpdateSubject: false,
      };

    case ActionTypes.DELETE_SUBJECT_ERROR:
      return {
        ...state,
        loadingDeleteSubject: false,
      };

    case ActionTypes.RESET_SUBJECT_STATE:
      return initialState;

    default:
      return state;
  }
};
