import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from '../../i18n';

const Loading = ({ title = '' }) => {
  return (
    <div className="loading">
      <FontAwesomeIcon icon="spinner" size="lg" spin />
      <span>{title ? `${title}...` : i18n.t('loading')}</span>
    </div>
  );
};

export default Loading;
