import React, { Component } from 'react';
import { connect } from 'react-redux';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import Masonry from 'react-masonry-css';

import CameraListElement from './CameraListElement';
import Loading from '../../../../components/Loading';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import WatchFilter from '../../../../components/WatchFilter';

import notificationsService from '../../../../services/NotificationsService';
import * as actions from '../../../../actions/cameras.actions';
import * as workflowActions from '../../../../actions/workflows.action';
import * as localActions from '../../../../actions/locations.actions';
import { isArrayLength } from '../../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlaceOfWorship, faPlus } from '@fortawesome/free-solid-svg-icons';
import CameraListElementWidget from './CameraListElementWidget';
import WidgetModal from './CameraWidgetModal/WidgetModal';
import {
  showActivity,
  showCars,
  showFaces,
  showGroups,
  showHeatmap,
  showHeatmapQueues,
  showLines,
  showQueues,
  showZones,
} from '../../../../constants/cameras.constants';
import heatmapStatic from '../../../../mocks/images/elevator-heatmap-static.jpg';

const Columns = {
  default: Math.floor((window.innerWidth - 410) / 263),
  7540: 26,
  7275: 25,
  7010: 24,
  6745: 23,
  6480: 22,
  6215: 21,
  5950: 20,
  5685: 19,
  5420: 18,
  5155: 17,
  4890: 16,
  4625: 15,
  4360: 14,
  4095: 13,
  3830: 12,
  3565: 11,
  3300: 10,
  3035: 9,
  2770: 8,
  2505: 7,
  2240: 6,
  1975: 5,
  1720: 4,
  1460: 3,
  1200: 2,
  935: 1,
};

class CameraList extends Component {
  constructor(props) {
    super(props);
    this.stompClient = null;
    this.timer = null;
    this.analyticWidgets = [];
    this.widgetsIntervals = [];
    this.alertsIntervals = [];
    this.state = {
      wfAnalytics: [],
      active: [
        { title: 'Status', key: 'All' },
        { title: 'Location', key: 'All' },
      ],
      activeWfs: [],
      list: [],
      cameraPreviews: {},
      isSocketConnected: false,
      modalOpen: false,
      heatmapOnline: null,
    };
    this.container = React.createRef();
    this.componentMounted = React.createRef();
  }

  componentDidMount() {
    this.componentMounted.current = true;
    this.props.setCameraLiveReset();
    this.props.getLocations(
      { onlyActive: true },
      {
        onSuccess: (data) => {
          this.props.changeCamerasValue(
            'locations',
            data.items.map((loc) => ({ name: loc.name, value: loc.uuid }))
          );
          this.props.getFilters('CAMERA', {
            onSuccess: (filters) => {
              filters.filter.map((filter) =>
                this.props.changeCamerasValue(`${filter.property}`, filter.value)
              );
              this.props.getCameras(
                {
                  locationUuids:
                    filters.filter.find((filter) => filter.property === 'Location') !== undefined
                      ? data.items.find(
                          (loc) =>
                            loc.name ===
                            filters.filter.find((filter) => filter.property === 'Location')?.value
                        )?.uuid
                      : null,
                  enabled:
                    this.props.Status !== 'All' ? (this.props.Status === 'RUNNING' ? true : false) : null,
                },
                {
                  onSuccess: (data) => {
                    this.setState({ list: data.items });
                    data.items.map((camera) => {
                      fetch(camera.previewUrl)
                        .then((response) => response.blob())
                        .then((blob) => {
                          this.setState((prev) => ({
                            cameraPreviews: {
                              ...prev.cameraPreviews,
                              [camera.uuid]: URL.createObjectURL(blob),
                            },
                          }));
                        });
                    });
                  },
                }
              );
            },
          });
        },
      }
    );
    this.getAnalytics();
  }

  getAnalytics = () => {
    this.setState({ wfAnalytics: [] });
    this.props.getWorkflows(
      {},
      {
        onSuccess: (workflows) => {
          this.props.getWorkflows(
            { size: workflows.total, page: 0 },
            {
              onSuccess: (wfs) => {
                wfs.items.forEach((wf) => {
                  if (wf.started) {
                    this.setState((prev) => ({
                      activeWfs: [...prev.activeWfs, wf.uuid],
                    }));
                    wf.cameras.map((camera) => {
                      this.props.getDatastream(wf.uuid, camera.uuid, {
                        onSuccess: (results) => {
                          fetch(results.alertsUrl)
                            .then((response) => response.json())
                            .then((data) => {
                              if (data.errorCode) return;
                              let timeout = 0;
                              const event = data;
                              const times = Object.keys(event.alerts).map(
                                (item) => Math.floor(item / 100) * 100
                              );
                              const events = Object.values(event.alerts);
                              this.componentMounted.current &&
                                this.alertsIntervals.push(
                                  setInterval(() => {
                                    timeout > times[times.length - 1] ? (timeout = 0) : (timeout += 100);
                                    if (
                                      times.includes(timeout) &&
                                      events[times.indexOf(timeout)].length !== 0
                                    ) {
                                      const notifications = events[times.indexOf(timeout)];
                                      notifications.map((not, index) => {
                                        const notification = Object.assign({}, not);
                                        notification.notificationId = Math.random()
                                          .toString(36)
                                          .substring(2, 9);
                                        notification.time = Date.now();
                                        notification.cameraUuid = camera.uuid;
                                        notification.workflowName = wf.name;
                                        notification.eventType = not.eventType;
                                        notification.thumbUrl = this.state.cameraPreviews[camera.uuid];
                                        notificationsService.add(notification);
                                      });
                                    }
                                  }, 100)
                                );
                            });
                        },
                      });
                    });
                  }
                });
                this.props.getWidgets(
                  {},
                  {
                    onSuccess: (data) => {
                      this.props.changeCamerasValue('widgets', data);
                      this.widgetConstructor(data);
                    },
                  }
                );
                wfs.items.map((wf) => {
                  this.props.getWorkflowAnalytics(wf.uuid, {
                    onSuccess: (analytic) => {
                      this.setState({
                        wfAnalytics: [
                          ...this.state.wfAnalytics,
                          {
                            wf: wf,
                            cameras: wf.cameras.map((item) => item.name),
                            analytics: analytic.map(
                              (type) =>
                                (type = [...showHeatmap, ...showHeatmapQueues].includes(type.type)
                                  ? 'heatmap'
                                  : showActivity.includes(type.type)
                                  ? 'activity'
                                  : showQueues.includes(type.type)
                                  ? 'queue'
                                  : showFaces.includes(type.type)
                                  ? 'faces'
                                  : showLines.includes(type.type)
                                  ? 'counting'
                                  : showGroups.includes(type.type)
                                  ? 'groups'
                                  : showCars.includes(type.type)
                                  ? 'alpr'
                                  : '')
                            ),
                          },
                        ],
                      });
                    },
                  });
                });
              },
            }
          );
        },
      }
    );
  };

  getUpdateHeatmap = (widget) => {
    this.props.getReadyHeatmap(
      widget.workflow.uuid,
      widget.camera.uuid,
      {
        start: Date.now() - widget.config.heatmap.heatmapUpdateInterval,
        end: Date.now(),
      },
      {
        onSuccess: (data) => {
          this.props.changeCamerasValue(
            'heatmapImage',
            this.props.heatmapImage.map((heat) => heat.uuid).indexOf(widget.uuid) !== -1
              ? this.props.heatmapImage.map((heat) =>
                  heat.uuid === widget.uuid
                    ? { uuid: widget.uuid, image: `data:image/jpeg;base64,${data.image}` }
                    : heat
                )
              : [
                  ...this.props.heatmapImage,
                  { uuid: widget.uuid, image: `data:image/jpeg;base64,${data.image}` },
                ]
          );
        },
        onError: (err) => {
          this.props.getReadyHeatmap(
            widget.workflow.uuid,
            widget.camera.uuid,
            {
              start: Date.now() - widget.config.heatmap.heatmapUpdateInterval,
              end: Date.now(),
            },
            {
              onSuccess: (data) => {
                this.props.changeCamerasValue(
                  'heatmapImage',
                  this.props.heatmapImage.map((heat) => heat.uuid).indexOf(widget.uuid) !== -1
                    ? this.props.heatmapImage.map((heat) =>
                        heat.uuid === widget.uuid
                          ? { uuid: widget.uuid, image: `data:image/jpeg;base64,${data.image}` }
                          : heat
                      )
                    : [
                        ...this.props.heatmapImage,
                        { uuid: widget.uuid, image: `data:image/jpeg;base64,${data.image}` },
                      ]
                );
              },
            }
          );
        },
      }
    );
  };

  widgetConstructor = (data) => {
    this.props.changeCamerasValue('activeWidget', []);
    this.props.changeCamerasValue('activeAccumWidget', []);
    data.forEach((widget, index) => {
      switch (widget.type) {
        case 'heatmap':
          if (widget.config.heatmap.online) {
            this.props.getDatastream(widget.workflow.uuid, widget.camera.uuid, {
              onSuccess: (data) => this.setState({ heatmapOnline: data.showObjectsVideoUrl }),
            });
          } else {
            // this.props.getReadyHeatmap(
            //   widget.workflow.uuid,
            //   widget.camera.uuid,
            //   {
            //     start: widget.config.heatmap.from,
            //     end: widget.config.heatmap.to,
            //   },
            //   {
            //     onSuccess: (data) => {
            //       this.props.changeCamerasValue(
            //         'heatmapImage',
            //         this.props.heatmapImage.map((heat) => heat.uuid).indexOf(widget.uuid) !== -1
            //           ? this.props.heatmapImage.map((heat) =>
            //               heat.uuid === widget.uuid
            //                 ? { uuid: widget.uuid, image: `data:image/jpeg;base64,${data.image}` }
            //                 : heat
            //             )
            //           : [
            //               ...this.props.heatmapImage,
            //               { uuid: widget.uuid, image: `data:image/jpeg;base64,${data.image}` },
            //             ]
            //       );
            //     },
            //   }
            // );
          }
          break;

        case 'inout':
          if (
            this.props.subscribeWidgets.indexOf(`${widget.camera.uuid}--LineCrossingObjectsCounting`) === -1
          ) {
            this.props.changeCamerasValue('activeWidget', [
              ...this.props.activeWidget,
              {
                id: widget.camera.uuid,
                wfUuid: widget.workflow.uuid,
                analytic: 'LineCrossingObjectsCounting',
              },
            ]);
          }
          this.props.changeCamerasValue('subscribeWidgets', [
            ...this.props.subscribeWidgets,
            `${widget.camera.uuid}--LineCrossingObjectsCounting`,
          ]);
          break;

        case 'queue':
          if (this.props.subscribeWidgets.indexOf(`${widget.camera.uuid}--QueuesDetectorAnalytics`) === -1) {
            this.props.changeCamerasValue('activeWidget', [
              ...this.props.activeWidget,
              {
                id: widget.camera.uuid,
                wfUuid: widget.workflow.uuid,
                analytic: 'QueuesDetectorAnalytics',
              },
            ]);
          }
          this.props.changeCamerasValue('subscribeWidgets', [
            ...this.props.subscribeWidgets,
            `${widget.camera.uuid}--QueuesDetectorAnalytics`,
          ]);
          break;

        case 'activity':
          if (
            this.props.subscribeWidgets.indexOf(
              `${widget.camera.uuid}--ZonesRealTimeActivityDurationAnalytics`
            ) === -1
          ) {
            this.props.changeCamerasValue('activeWidget', [
              ...this.props.activeWidget,
              {
                id: widget.camera.uuid,
                wfUuid: widget.workflow.uuid,
                analytic: 'ZonesRealTimeActivityDurationAnalytics',
              },
            ]);
          }
          this.props.changeCamerasValue('subscribeWidgets', [
            ...this.props.subscribeWidgets,
            `${widget.camera.uuid}--ZonesRealTimeActivityDurationAnalytics`,
          ]);
          break;

        case 'accumActivity':
          if (this.props.accumWidgets.indexOf(`${widget.camera.uuid}--ZonesAccumulationAnalytics`) === -1) {
            this.props.changeCamerasValue('activeAccumWidget', [
              ...this.props.activeAccumWidget,
              {
                id: widget.camera.uuid,
                wfUuid: widget.workflow.uuid,
                analytic: 'ZonesAccumulationAnalytics',
              },
            ]);
          }
          this.props.changeCamerasValue('accumWidgets', [...this.props.accumWidgets, widget.camera.uuid]);
          break;
      }
    });
    this.subscribeAnalyticsTopic();
    this.subscribeCameraTopic();
  };

  componentDidUpdate(prev) {
    prev.subscribeWidgets !== this.props.subscribeWidgets &&
      prev.subscribeWidgets.find((item) => this.props.subscribeWidgets.indexOf(item) === -1) &&
      this.stompClient?.unsubscribe(
        prev.subscribeWidgets.find((item) => this.props.subscribeWidgets.indexOf(item) === -1)
      );

    prev.accumWidgets !== this.props.accumWidgets &&
      prev.accumWidgets.find((item) => this.props.accumWidgets.indexOf(item) === -1) &&
      this.stompClient?.unsubscribe(
        prev.accumWidgets.find((item) => this.props.accumWidgets.indexOf(item) === -1)
      );

    prev.addedWidget !== this.props.addedWidget &&
      this.props.addedWidget !== '' &&
      this.props.getWidgets(
        {},
        {
          onSuccess: (data) => {
            this.widgetConstructor(
              data.filter((widget) => this.props.widgets.map((it) => it.uuid).indexOf(widget.uuid) === -1)
            );
            this.props.changeCamerasValue('widgets', data);
          },
        }
      );

    (prev.Location !== this.props.Location || prev.Status !== this.props.Status) &&
      this.props.locations.length !== 0 &&
      this.props.getCameras(
        {
          page: 0,
          size: 100,
          locationUuids:
            this.props.Location !== 'All'
              ? this.props.locations.find((loc) => loc.name === this.props.Location)?.value
              : null,
          enabled: this.props.Status !== 'All' ? (this.props.Status === 'RUNNING' ? true : false) : null,
        },
        { onSuccess: (data) => this.setState({ list: data.items }) }
      );
  }

  subscribeAnalyticsTopic = (client) => {
    this.props.activeWidget.length !== 0 &&
      this.props.activeWidget.forEach((widget) => {
        if (this.state.activeWfs.includes(widget.wfUuid)) {
          this.props.getDatastream(widget.wfUuid, widget.id, {
            onSuccess: (results) => {
              fetch(results.widgetsUrl)
                .then((response) => response.json())
                .then((data) => {
                  if (data.errorCode) return;
                  if (widget.analytic === 'LineCrossingObjectsCounting') {
                    let timeout = 0;
                    const event = data;
                    const times = Object.keys(event.widgets.inOut).map(
                      (item) => Math.round(item / 100) * 100
                    );
                    const events = Object.values(event.widgets.inOut);
                    this.componentMounted.current &&
                      this.widgetsIntervals.push(
                        setInterval(() => {
                          timeout > times[times.length - 1] ? (timeout = 0) : (timeout += 100);
                          event.widgets.inOut &&
                            times.includes(timeout) &&
                            this.props.changeWidgetsValue(
                              'InOutWidget',
                              events[times.indexOf(timeout)].linesCrossingCount.map((item) => ({
                                id: item.lineId,
                                in: item.in,
                                out: item.out,
                              }))
                            );
                        }, 100)
                      );
                  } else if (widget.analytic === 'QueuesDetectorAnalytics') {
                    let timeout = 0;
                    const event = data;
                    const times = Object.keys(event.widgets.queueSize).map(
                      (item) => Math.round(item / 100) * 100
                    );
                    const events = Object.values(event.widgets.queueSize);
                    this.analyticWidgets.push(`QueuesDetectorAnalytics`);
                    this.componentMounted.current &&
                      this.widgetsIntervals.push(
                        setInterval(() => {
                          timeout > times[times.length - 1] ? (timeout = 0) : (timeout += 100);
                          events[times.indexOf(timeout)] &&
                            this.props.changeWidgetsValue(
                              'queues',
                              events[times.indexOf(timeout)].flat().map((item) => ({
                                id: item.zoneId,
                                members: item.size,
                              }))
                            );
                        }, 100)
                      );
                  } else if (widget.analytic === 'ZonesRealTimeActivityDurationAnalytics') {
                    let timeout = 0;
                    const event = data;
                    const times = Object.keys(event.widgets.realtimeActivity).map(
                      (item) => Math.round(item / 100) * 100
                    );
                    const events = Object.values(event.widgets.realtimeActivity);
                    this.analyticWidgets.push(`ZonesRealTimeActivityDurationAnalytics`);
                    this.componentMounted.current &&
                      this.widgetsIntervals.push(
                        setInterval(() => {
                          timeout > times[times.length - 1] ? (timeout = 0) : (timeout += 100);
                          times.includes(timeout) &&
                            events[times.indexOf(timeout)].length !== 0 &&
                            this.props.changeWidgetsValue(
                              'activities',
                              events[times.indexOf(timeout)].map((item) => ({
                                id: item.zoneId,
                                value: `${Math.floor(item.duration / 3600)
                                  .toString()
                                  .padStart(2, '0')}:${Math.floor((item.duration % 3600) / 60)
                                  .toString()
                                  .padStart(2, '0')}:${Math.round(item.duration % 60)
                                  .toString()
                                  .padStart(2, '0')}`,
                              }))
                            );
                        }, 100)
                      );
                  }
                });
            },
          });
        }
      });
  };

  subscribeCameraTopic = (client) => {
    this.props.activeAccumWidget.length !== 0 &&
      this.props.activeAccumWidget.forEach((widget) => {
        if (this.state.activeWfs.includes(widget.wfUuid)) {
          this.props.getDatastream(widget.wfUuid, widget.id, {
            onSuccess: (results) => {
              fetch(results.widgetsUrl)
                .then((response) => response.json())
                .then((data) => {
                  if (data.errorCode) return;
                  let timeout = 0;
                  const event = data;
                  const times = Object.keys(event.widgets.accumulationActivity).map(
                    (item) => Math.round(item / 100) * 100
                  );
                  const events = Object.values(event.widgets.accumulationActivity);
                  this.componentMounted.current &&
                    this.widgetsIntervals.push(
                      setInterval(() => {
                        timeout > times[times.length - 1] ? (timeout = 0) : (timeout += 100);
                        times.includes(timeout) &&
                          this.props.changeWidgetsValue(
                            'accumActivities',
                            events[times.indexOf(timeout)].map((ev) => ({
                              id: ev.zoneId,
                              analytic: ev.activityAnalytics.map((analytic) => ({
                                accumulationIntervalName: analytic.accumulationIntervalName,
                                activityDuration: `${Math.floor(analytic.activityDuration / 3600)
                                  .toString()
                                  .padStart(2, '0')}:${Math.floor((analytic.activityDuration % 3600) / 60)
                                  .toString()
                                  .padStart(2, '0')}:${(analytic.activityDuration % 60)
                                  .toString()
                                  .padStart(2, '0')}`,
                              })),
                            }))
                          );
                      }, 100)
                    );
                });
            },
          });
        }
      });
  };

  componentWillUnmount = () => {
    this.componentMounted.current = false;
    this.widgetsIntervals.forEach((wInterval) => clearInterval(wInterval));
    this.alertsIntervals.forEach((aInterval) => clearInterval(aInterval));
    this.props.changeCamerasValue('subscribeWidgets', []);
    this.props.changeCamerasValue('accumWidgets', []);
    this.props.changeCamerasValue('InOutWidget', []);
    this.props.changeCamerasValue('queues', []);
    this.props.changeCamerasValue('activities', []);
    this.props.changeCamerasValue('accumActivities', []);
  };

  onScrolledBottom = () => {
    const { total, page, size, isNexPageLoading } = this.props;
    if ((page + 1) * size < total && !isNexPageLoading) {
      this.props.getCamerasNextPage();
    }
  };

  render() {
    const { list, isLoading, isNexPageLoading, widgets, heatmap } = this.props;
    const camerasFilters = [
      {
        name: 'status',
        title: 'Status',
        options: ['RUNNING', 'STOPPED'],
        active: this.props.Status,
      },
      {
        name: 'location',
        title: 'Location',
        options: this.props.locations.map((loc) => loc.name),
        active: this.props.Location,
      },
    ];

    return (
      <>
        <WatchFilter
          title="Cameras"
          filters={camerasFilters}
          onChange={(title, key) => {
            this.props.changeCamerasValue(`${title}`, key);
            this.props.addFilter({
              filter: [
                {
                  property: 'Status',
                  value: title === 'Status' ? key : this.props.Status,
                },
                {
                  property: 'Location',
                  value: title === 'Location' ? key : this.props.Location,
                },
              ],
              filterEntity: 'CAMERA',
              sort: [
                {
                  direction: 'ASC',
                  property: 'Status',
                },
              ],
            });
          }}
        />
        <InfiniteScroll
          ref={this.container}
          className="dashboard__content"
          loading={isNexPageLoading}
          onScrolledBottom={this.onScrolledBottom}
        >
          {isLoading && <Loading />}
          {!isLoading && (
            <div className="dashboard__list-wrapper">
              <ul className="dashboard__list">
                {isArrayLength(this.state.list) &&
                  this.state.list.map((e) => (
                    <li key={e.uuid}>
                      <CameraListElement
                        camera={e}
                        enabled={e.enabled}
                        wfAnalytics={this.state.wfAnalytics.filter((wf) => wf.cameras.includes(e.name))}
                      />
                    </li>
                  ))}
              </ul>
            </div>
          )}
          <div className="d-flex m-2 mt-4">
            <span className="h4 mb-0">Analytics</span>
            <button
              className="table__page-button btn btn--secondary ml-2 mt-1"
              style={{ height: '24px', width: '24px', padding: '0' }}
              onClick={() =>
                this.setState({
                  modalOpen: true,
                })
              }
            >
              <FontAwesomeIcon icon={faPlus} style={{ height: '14px', width: '14px' }} />
            </button>
          </div>
          <div className="dashboard__list-wrapper">
            <Masonry
              breakpointCols={Columns}
              className="dashboard__list--masonry"
              columnClassName="masonry--column"
            >
              {isArrayLength(widgets) &&
                widgets.map((e) => (
                  <CameraListElementWidget
                    key={e.uuid}
                    camera={{
                      ...e.camera,
                      previewUrl: this.state.list.find((camera) => camera.uuid === e.camera.uuid)?.previewUrl,
                    }}
                    workflow={e.workflow}
                    typeWidget={e.type}
                    height={e.height}
                    config={{
                      ...e.config,
                      heatmap: {
                        ...e.config.heatmap,
                        heatmapOnline: this.state.heatmapOnline,
                        heatmapStatic: heatmapStatic,
                      },
                    }}
                    uuid={e.uuid}
                    deleteWidget={(uuid) => this.props.deleteWidget(uuid)}
                  />
                ))}
            </Masonry>
          </div>
        </InfiniteScroll>
        <WidgetModal
          addWidget={(params) => {
            this.props.addWidget(params);
            this.setState({ modalOpen: false });
          }}
          modalOpen={this.state.modalOpen}
          closeModal={() => this.setState({ modalOpen: false })}
          cameras={list}
        />
      </>
    );
  }
}

const mapStateToProps = ({ cameras }) => {
  const {
    list: { items, total },
    query: { size, page },
    loading: { getCameras, nextPage },
    widgets,
    heatmap,
    Status,
    Location,
    locations,
    activeWidget,
    activeAccumWidget,
    heatmapImage,
    subscribeWidgets,
    accumWidgets,
    addedWidget,
  } = cameras;
  return {
    list: items,
    total,
    size,
    page,
    widgets,
    heatmap,
    Status,
    Location,
    locations,
    activeWidget,
    subscribeWidgets,
    activeAccumWidget,
    accumWidgets,
    heatmapImage,
    addedWidget,
    isLoading: getCameras,
    isNexPageLoading: nextPage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCameras: (params, action) => dispatch(actions.getCameras(params, action)),
  getCamerasNextPage: () => dispatch(actions.getCamerasNextPage()),
  setCameraLiveReset: () => dispatch(actions.setCameraLiveReset()),
  changeCamerasValue: (key, value) => dispatch(actions.changeCamerasValue(key, value)),
  changeWidgetsValue: (key, value) => dispatch(actions.changeWidgetsValue(key, value)),
  getReadyHeatmap: (workflowUuid, cameraUuid, params, action) =>
    dispatch(actions.getReadyHeatmap(workflowUuid, cameraUuid, params, action)),
  getWorkflows: (params, actions) => dispatch(workflowActions.getWorkflows(params, actions)),
  getWorkflowAnalytics: (params, actions) => dispatch(workflowActions.getWorkflowAnalytics(params, actions)),
  getWidgets: (params, action) => dispatch(actions.getWidgets(params, action)),
  addWidget: (params) => dispatch(actions.addWidget(params)),
  deleteWidget: (uuid) => dispatch(actions.deleteWidget(uuid)),
  addFilter: (params) => dispatch(actions.addFilter(params)),
  getFilters: (params, action) => dispatch(actions.getFilters(params, action)),
  getLocations: (params, actions) => dispatch(localActions.getLocations(params, actions)),
  getDatastream: (workflowUuid, cameraUuid, acts) =>
    dispatch(actions.getDatastream(workflowUuid, cameraUuid, acts)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CameraList);
