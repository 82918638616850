import React from 'react';
import PropTypes from 'prop-types';

import NotFound from '../../../../components/NotFound';
import SubjectListElement from './SubjectListElement';
import { isArrayLength } from '../../../../utils';
import i18n from '../../../../i18n';

const SubjectList = ({ list, search, loading }) => {
  return (
    <div className="wrap-subject-list">
      {isArrayLength(list) ? (
        <ul className="list subject-list">
          {list.map(e => (
            <SubjectListElement key={e.uuid} subject={e} />
          ))}
        </ul>
      ) : !loading ? (
        <NotFound
          isSearch={search}
          searchText={i18n.t('subjects:searchNotFound')}
          notFoundText={i18n.t('subjects:notFound')}
        />
      ) : null}
    </div>
  );
};

SubjectList.propTypes = {
  list: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default SubjectList;
