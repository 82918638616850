import React, { Component } from 'react';

import CameraList from './CameraList';
import LatestAlerts from './LatestAlerts';

class Watch extends Component {
  componentDidMount() {
    // firebaseService.init();
  }

  componentWillUnmount() {
    // firebaseService.destroy();
  }

  render() {
    return (
      <div className="dashboard h-100">
        <div className="dashboard__wrapper h-100">
          <div className="dashboard__item dashboard__item--cameras">
            <CameraList />
          </div>
          <div className="dashboard__item dashboard__item--alerts">
            <LatestAlerts />
          </div>
        </div>
      </div>
    );
  }
}

export default Watch;
