import * as ActionTypes from '../constants/alerts.constants';

const initialState = {
  list: {
    items: [],
    total: 0,
  },
  size: 20,
  page: 0,
  eventSort: 'desc',
  timestamp: 'desc',
  workflow: null,
  camera: null,
  processing: null,
  isLoading: false,
};

export const alertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALERTS:
    case ActionTypes.GET_ALERTS_NEXT_PAGE:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_ALERTS_RESULT: {
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items, total },
        size: size, 
        page: page,
        isLoading: false,
      };
    }

    case ActionTypes.GET_ALERTS_NEXT_PAGE_RESULT: {
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items: [...state.list.items, ...items], total },
        query: { size, page },
        isLoading: false,
      };
    }

    case ActionTypes.GET_ALERTS_ERROR:
    case ActionTypes.GET_ALERTS_NEXT_PAGE_ERROR:
      return {
        ...state,
        list: { items: [], total: 0},
        isLoading: false,
      };

    case ActionTypes.ADD_ALERT:
      return {
        ...state,
        list: { items: [action.item, ...state.list.items], total: state.list.total + 1 },
      };
    case ActionTypes.CHANGE_ALERT_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    default:
      return state;
  }
};
