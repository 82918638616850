import React, { Component } from 'react';
import i18n from '../../../i18n';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Modal, ModalBody, ModalHeader, ModalFooter, CustomInput } from 'reactstrap';
import Loader from '../../../components/Loader';
import cx from 'classnames';

import { FormInput } from '../../../components/Form';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .max(250, i18n.t('validationMessages:length', { field: 'name', length: 250 }))
    .required(i18n.t('validationMessages:required', { field: 'name' })),
});

class BaseModal extends Component {
  state = {
    message: null,
  };

  hideMessage = () => {
    if (this.state.message) {
      this.setState({ message: null });
      if (this.props.params.errorMsg && typeof this.props.params.resetError === 'function') {
        this.props.params.resetError();
      }
    }
  };

  onSubmit = (values) => {
    const {
      params: { location },
      onSubmit,
    } = this.props;

    if (location.name === values.name) {
      this.setState({ message: i18n.t('form:hasNoChanges') });
    } else {
      const newLocation = { ...location, ...values };

      onSubmit(newLocation);
    }
  };

  render() {
    const { message } = this.state;
    const {
      params: { isOpen, location },
      header,
      onClose,
      loading,
      submitBtnText,
      closeBtnText,
      className,
    } = this.props;

    return (
      <Modal isOpen={isOpen} className={cx('', className)} fade>
        <ModalHeader toggle={onClose}>{header}</ModalHeader>
        <Formik
          onSubmit={this.onSubmit}
          initialValues={{
            name: location.name,
          }}
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values: { name },
              touched,
              errors,
              isValid,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <ModalBody>
                  <div className="form-fields">
                    <FormInput
                      name="name"
                      type="text"
                      label={i18n.t('locationsModals:labelName')}
                      value={name}
                      className="form-item form-item--one"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      errorMessage={errors.name && touched.name ? errors.name : ''}
                    />
                  </div>
                </ModalBody>

                <ModalFooter>
                  <Loader loading={loading} />
                  {message || null}
                  <button type="button" className="btn btn--dark" disabled={loading} onClick={onClose}>
                    {closeBtnText}
                  </button>
                  <button className="btn btn--secondary" type="submit" disabled={!isValid || loading}>
                    {submitBtnText}
                  </button>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

BaseModal.defaultProps = {
  loading: false,
  submitBtnText: i18n.t('buttons:save'),
  closeBtnText: i18n.t('buttons:cancel'),
};

export default BaseModal;
