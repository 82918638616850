import * as ActionTypes from '../constants/schemas.constants';

export const getProcessingTypes = (actions) => ({
  type: ActionTypes.GET_PROCESSING_TYPES,
  actions,
});

export const getAnalyticTypes = (type, actions) => ({
  type: ActionTypes.GET_ANALYTIC_TYPES,
  payload: { type },
  actions,
});

export const getTriggerTypes = (type, actions) => ({
  type: ActionTypes.GET_TRIGGERS_TYPES,
  payload: { type },
  actions,
});

export const getProcessingSchema = (type, actions) => ({
  type: ActionTypes.GET_PROCESSING_SCHEMA,
  payload: { type },
  actions,
});

export const getAnalyticSchema = (type, actions) => ({
  type: ActionTypes.GET_ANALYTIC_SCHEMA,
  payload: { type },
  actions,
});

export const getTriggerSchema = (type, actions) => ({
  type: ActionTypes.GET_TRIGGER_SCHEMA,
  payload: { type },
  actions,
});

export const getModelSchema = (procConfUUID, modelId, actions) => ({
  type: ActionTypes.GET_MODEL_SCHEMA,
  payload: { procConfUUID, modelId },
  actions,
});
