import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { contrastingColors } from '../../../../constants/workflows.constants';

const CameraListElementTextWidget = ({
  inout,
  queue,
  activity,
  accumActivity,
  InOutWidget,
  queues,
  activities,
  accumActivities,
}) => {
  return (
    <div className="camera-list__widget">
      <Table responsive className="table-dark" size="sm">
        <thead>
          {inout.length !== 0 && (
            <tr>
              <th>LINE</th>
              <th>IN</th>
              <th>OUT</th>
            </tr>
          )}
          {queue.length !== 0 && (
            <tr>
              <th>ZONE</th>
              <th>SIZE</th>
            </tr>
          )}
          {(activity.length !== 0 || accumActivity.length !== 0) && (
            <tr>
              <th>WORKSPACE</th>
              <th>WORKING TIME</th>
            </tr>
          )}
        </thead>
        <tbody>
          {inout &&
            inout.length !== 0 &&
            inout.map((item, index) => (
              <tr style={{ color: contrastingColors[index] }} key={item.uuid}>
                <td>{item.name}</td>
                <td>
                  {InOutWidget.find((it) => it.id === item.uuid)
                    ? InOutWidget.find((it) => it.id === item.uuid)?.in
                    : '--'}
                </td>
                <td>
                  {InOutWidget.find((it) => it.id === item.uuid)
                    ? InOutWidget.find((it) => it.id === item.uuid)?.out
                    : '--'}
                </td>
              </tr>
            ))}
          {queue &&
            queue.length !== 0 &&
            queue.map((item, index) => (
              <tr style={{ color: contrastingColors[index] }} key={item.uuid}>
                <td>{item.name}</td>
                <td>
                  {queues.find((it) => it.id === item.uuid)
                    ? queues.find((it) => it.id === item.uuid)?.members
                    : '--'}
                </td>
              </tr>
            ))}
          {activity.length !== 0 &&
            activity.map((item, index) => (
              <tr style={{ color: contrastingColors[index] }} key={item.uuid}>
                <td>{item.name}</td>
                <td>
                  {activities.find((it) => it.id === item.uuid)
                    ? activities.find((it) => it.id === item.uuid)?.value
                    : '--'}
                </td>
              </tr>
            ))}
          {accumActivity.length !== 0 &&
            accumActivity.map((item, index) => (
              <Fragment key={item.uuid}>
                <tr style={{ color: contrastingColors[index] }}>
                  <td>{item.name}</td>
                  <td></td>
                </tr>
                {accumActivities.find((it) => it.id === item.uuid) ? (
                  accumActivities
                    .find((it) => it.id === item.uuid)
                    ?.analytic.map((an) => (
                      <tr>
                        <td>{an.accumulationIntervalName}</td>
                        <td>{an.activityDuration}</td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td>interval Name</td>
                    <td>--:--:--</td>
                  </tr>
                )}
              </Fragment>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = ({ cameras }) => {
  const { InOutWidget, queues, activities, accumActivities } = cameras;
  return {
    InOutWidget,
    queues,
    activities,
    accumActivities,
  };
};

export default connect(mapStateToProps, null)(CameraListElementTextWidget);
