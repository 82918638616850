import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Loader = ({ loading, className }) => {
  return (
    loading && (
      <div className={cx('loader-wrap', className)}>
        <div>
          <div className={'loader'}>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    )
  );
};

Loader.defaultProps = {
  className: '',
};

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  absolute: PropTypes.bool,
  className: PropTypes.string,
};

export default Loader;
