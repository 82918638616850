import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { SUBJECTS, WATCH } from '../../../../constants/routes.constants';
import { ALERT_TIME_FORMAT } from '../../../../constants/alerts.constants';
import { getSubjectSex, getSubjectAge } from '../helpers';
import { isArrayLength } from '../../../../utils';

const SubjectListElement = ({ subject }) => {
  const { uuid, photos, name, sex, height, lastAlertEvent } = subject;
  const redirectLink = `${SUBJECTS}/${uuid}`;

  return (
    <li className="subject-item">
      <Link to={redirectLink} className="subject-item-ava">
        {isArrayLength(photos) ? (
          <img src={photos[0].url} alt={name} />
        ) : (
          <img src="https://via.placeholder.com/70x70" alt={name} />
        )}
      </Link>
      <div className="subject-item-name">
        <Link to={redirectLink}>
          <strong>{name}</strong>
        </Link>
      </div>
      <div className="subject-item-info">
        <span>{getSubjectSex(sex)}</span>
        {getSubjectAge(subject)}
        <span>{height}</span>
      </div>
      <div className="subject-item-location">
        {lastAlertEvent && (
          <Link
            to={{
              pathname: `${WATCH}/${lastAlertEvent.camera.uuid}`,
              state: { setCurrent: moment(lastAlertEvent.timestamp).toDate(), alert: lastAlertEvent },
            }}
          >
            <strong>
              <span>{moment(lastAlertEvent.timestamp).format(`! ${ALERT_TIME_FORMAT}`)}</span>
            </strong>
            <span>{lastAlertEvent.camera.name}</span>
            <span>{lastAlertEvent.lastAlertLocation}</span>
          </Link>
        )}
      </div>
    </li>
  );
};

SubjectListElement.propTypes = {
  subject: PropTypes.object,
};

export default SubjectListElement;
