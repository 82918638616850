import React, { Component } from 'react';
import Markup from './Markup';
import PropTypes from 'prop-types';

class TrainingMarkup extends Component {
  render() {
    return <Markup />;
  }
}

TrainingMarkup.propTypes = {};

export default TrainingMarkup;
