export const GET_USERS = 'GET_USERS';
export const GET_USERS_RESULT = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const GET_USER = 'GET_USER';
export const GET_USER_RESULT = 'GET_USER_RESULT';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const GET_ME = 'GET_ME';
export const GET_ME_RESULT = 'GET_ME_RESULT';
export const GET_ME_ERROR = 'GET_ME_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const UPDATE_ME = 'UPDATE_ME';
export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS';
export const UPDATE_ME_ERROR = 'UPDATE_ME_ERROR';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const CHANGE_USER_VALUE = 'CHANGE_USER_VALUE';
export const CHANGE_MODAL_USER_VALUE = 'CHANGE_MODAL_USER_VALUE';
export const CHANGE_USER_PAGE_VALUE = 'CHANGE_USER_PAGE_VALUE';

export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';

export const ADD_ROLES_TO_USER = 'ADD_ROLES_TO_USER';
export const UPDATE_ROLES_TO_USER = 'UPDATE_ROLES_TO_USER';
export const DELETE_ROLE_TO_USER = 'DELETE_ROLE_TO_USER';

export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const RESEND_EMAIL = 'RESEND_EMAIL';
