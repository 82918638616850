import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Table from '../../components/Table';
import CameraModal from './Modals/CameraModal';
import i18n from '../../i18n';
import DropdownDotMenu from '../../components/DropdownDotMenu';

import * as camerasActions from '../../actions/cameras.actions';
import ModalDelete from '../../components/ModalDelete';
import InformationModal from '../../components/InformationModal/InformationModal';
import { cameraStatuses, cameraStreamStatuses } from '../../constants/cameras.constants';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const CamerasList = ({
  camerasFilters,
  camerasItems,
  total,
  size,
  page,
  isLoading,
  getCameras,
  verifyCameraAction,
  addCameraAction,
  updateCameraAction,
  deleteCameraAction,
  changeCamerasFilters,
}) => {
  const [modalInfo, setModalInfo] = useState({ id: 0, type: 'create', isOpen: false, errorMsg: '' });
  const [isModalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    getCameras({
      page: 0,
      size: 20,
      name: camerasFilters.name,
      enabled: camerasFilters.status !== 'All' ? (camerasFilters.status === 'Active' ? true : false) : null,
      sort: `enabled-desc,${camerasFilters.sortName}-${camerasFilters.sortType}`,
    });
  }, [camerasFilters.name, camerasFilters.sortName, camerasFilters.sortType, camerasFilters.status]);

  const addCamera = (params) => {
    addCameraAction(params, getModalRequestActions());
  };

  const updateCamera = (params) => {
    updateCameraAction({ ...params, uuid: modalInfo.id }, getModalRequestActions());
  };

  const deleteCamera = (uuid) => {
    deleteCameraAction(uuid, getModalRequestActions());
  };

  const handleSubmitModal = (params) => {
    setModalLoading(true);
    switch (modalInfo.type) {
      case 'create':
        addCamera(params);
        break;
      case 'edit':
        updateCamera(params);
        break;
      case 'delete':
        deleteCamera(modalInfo.id);
        break;
      case 'Error handling':
        setModalInfo({ isOpen: false, type: 'Error handling' });
      default:
        break;
    }
  };

  const getModalRequestActions = () => {
    return {
      onSuccess: (data) => {
        handleCloseModal();
        getCameras({ sort: 'enabled-desc' });
      },
      onError: (error) => {
        openInfoModal('Error', '', error.message);
      },
      onComplete: () => {
        setModalLoading(false);
      },
    };
  };

  const handleOpenModal = (type) => {
    setModalInfo({
      type: 'create',
      isOpen: true,
    });
  };

  const handleCloseModal = () => {
    setModalInfo({ id: 0, type: 'create', isOpen: false, errorMsg: '' });
  };

  const createRows = (rows) => {
    return rows.map((item) => ({
      id: item.uuid,
      cells: [
        {
          label: item.name,
          cellComponent: <h5 className="table__cell table__cell--bold">{item.name}</h5>,
        },
        {
          label: item.location && item.location.name,
        },
        {
          label: item.fps,
        },
        {
          label: item.status && item.status.status,
          cellComponent: (
            <span
              className={
                item.status && item.status.status === cameraStreamStatuses.RUNNING ? 'text-text-active' : ''
              }
            >
              {item.status && item.status.status === cameraStreamStatuses.RUNNING
                ? cameraStatuses.ACTIVE
                : cameraStatuses.INACTIVE}
            </span>
          ),
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                {
                  name: 'Edit',
                  onClick: () => {
                    setModalInfo({ id: item.uuid, camera: item, type: 'edit', isOpen: true, errorMsg: '' });
                  },
                },
                {
                  name: 'Delete',
                  onClick: () => {
                    setModalInfo({
                      id: item.uuid,
                      camera: item,
                      type: 'delete',
                      isOpen: true,
                      errorMsg: '',
                    });
                  },
                },
              ]}
            />
          ),
        },
      ],
    }));
  };

  const openInfoModal = (header, body, errorMsg) => {
    setModalInfo({
      id: 0,
      type: 'info',
      isOpen: true,
      info: {
        header,
        body,
        errorMsg,
      },
    });
  };

  const headerOptions = [
    {
      label: 'Name',
      sortable: true,
      onSortClick: () =>
        changeCamerasFilters('sortType', camerasFilters.sortType === 'desc' ? 'asc' : 'desc'),
    },
    {
      label: 'Location',
    },
    {
      label: 'FPS',
    },
    {
      label: 'Status',
    },
    {
      label: '',
    },
  ];

  return (
    <>
      <Table
        rows={createRows(camerasItems)}
        headerOptions={headerOptions}
        title={i18n.t('nav:cameras')}
        onClick={() => handleOpenModal('create')}
        filters={[{ name: 'status', title: 'Status', options: ['Active', 'Inactive'] }]}
        onChangeFilters={(title, key) => changeCamerasFilters(title.toLowerCase(), key)}
        search={true}
        searchSubmit={(value) => changeCamerasFilters('name', value)}
        totalRows={total}
        size={size}
        page={page}
        changePage={(data) => getCameras({ page: data, sort: 'enabled-desc' })}
        isLoading={isLoading}
        disabled={modalInfo.isOpen}
      />
      {modalInfo.isOpen && ['create', 'edit'].includes(modalInfo.type) && (
        <CameraModal
          isOpen={modalInfo.isOpen}
          camera={modalInfo.camera}
          type={modalInfo.type}
          onClose={handleCloseModal}
          onSubmit={handleSubmitModal}
          verifyCamera={verifyCameraAction}
          title={
            modalInfo.type === 'create' ? i18n.t('cameraModals:addTitle') : i18n.t('cameraModals:editTitle')
          }
          className="modal-users"
          isLoading={isModalLoading}
          errorMsg={modalInfo.errorMsg}
        />
      )}
      {modalInfo.isOpen && modalInfo.type === 'delete' && (
        <ModalDelete
          loading={isModalLoading}
          isOpen={modalInfo.isOpen}
          errorMsg={modalInfo.errorMsg}
          header={i18n.t('cameraModals:deleteTitle')}
          body={i18n.t('cameraModals:deleteBody', { cameraName: modalInfo.camera.name })}
          onClose={handleCloseModal}
          onDelete={handleSubmitModal}
        />
      )}
      {modalInfo.isOpen && modalInfo.type === 'info' && (
        <InformationModal
          isOpen={modalInfo.isOpen}
          header={modalInfo.info.header}
          body={modalInfo.info.body}
          errorMsg={modalInfo.info.errorMsg}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

CamerasList.propTypes = {
  camerasItems: PropTypes.array,
};

const mapStateToProps = (state) => {
  const {
    list: { items: camerasItems, total },
    query: { size, page },
    loading: { getCameras: isLoading },
    camerasFilters,
  } = state.cameras;
  return {
    camerasFilters,
    camerasItems,
    total,
    size,
    page,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCameras: (params) => dispatch(camerasActions.getCameras(params)),
  verifyCameraAction: (url, actions) => dispatch(camerasActions.verifyCamera(url, actions)),
  addCameraAction: (params, actions) => dispatch(camerasActions.addCamera(params, actions)),
  updateCameraAction: (params, actions) => dispatch(camerasActions.updateCamera(params, actions)),
  deleteCameraAction: (uuid, actions) => dispatch(camerasActions.deleteCamera(uuid, actions)),
  changeCamerasFilters: (key, value) => dispatch(camerasActions.changeCamerasFilters(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CamerasList);
