import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormInput, FormSelect } from '../../../components/Form';
import i18n from '../../../i18n';

const TrainingModal = ({ training, modal, modelTypes, baseModel, onClose, info, onChange, onSubmit }) => {
  return (
    <Modal isOpen={modal} fade>
      <ModalHeader toggle={() => onClose()}>{i18n.t(`training:startTraining`)}</ModalHeader>
      <ModalBody>
        <FormInput
          name="name"
          type="text"
          label={i18n.t('fields:name')}
          value={info.name}
          className="form-item form-item--one mb-10"
          onChange={(name, value) => {
            onChange(name, value);
          }}
          errorMessage={false}
        />
        <FormSelect
          name="modelType"
          label={i18n.t('training:modelType')}
          selected={info.modelType}
          options={modelTypes}
          isSearch={true}
          className="form-item form-item--one mb-10"
          onChange={(name, value) => {
            onChange(name, value);
          }}
          errorMessage={false}
        />
        <FormSelect
          name="baseModel"
          label={i18n.t('training:baseModel')}
          selected={info.baseModel}
          options={baseModel}
          isSearch={true}
          className="form-item form-item--one mb-10"
          onChange={(name, value) => {
            onChange(name, value);
          }}
          errorMessage={false}
        />
        <FormSelect
          name="dataset"
          label={i18n.t('training:dataset')}
          selected={info.dataset}
          options={training.dataset.items.map((it) => ({ label: it.name, value: it.uuid }))}
          isSearch={true}
          className="form-item form-item--one mb-10"
          onChange={(name, value) => {
            onChange(name, value);
          }}
          errorMessage={false}
        />
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn--dark" onClick={() => onClose()}>
          {i18n.t('buttons:cancel')}
        </button>
        <button
          className="btn btn--secondary"
          type="submit"
          disabled={!info.name || !info.modelType || !info.baseModel || !info.dataset}
          onClick={() => onSubmit()}
        >
          {i18n.t('training:seeMetrics')}
        </button>
        <button
          className="btn btn--secondary"
          type="submit"
          disabled={!info.name || !info.modelType || !info.baseModel || !info.dataset}
          onClick={() => onSubmit()}
        >
          {i18n.t('training:start')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({ training, model }) => {
  return { training, model };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingModal);
