import * as ActionTypes from '../constants/training.constants';

export const changeModalValue = (type, key, value) => ({
  type: ActionTypes.CHANGE_MODAL_TRAINING_VALUE,
  payload: { type, key, value },
});

export const changeValue = (key, value) => ({
  type: ActionTypes.CHANGE_TRAINING_VALUE,
  payload: { key, value },
});

export const getDatasetMarkup = (params, actions) => ({
  type: ActionTypes.GET_DATASETS_MARKUP,
  payload: params,
  actions,
});

export const createDatasetMarkup = (params) => ({
  type: ActionTypes.ADD_DATASET_MARKUP,
  payload: params,
});

export const addMarkupAction = (uuid, params) => ({
  type: ActionTypes.ADD_DATASET_MARKUP_MEDIA,
  payload: { uuid, params },
});

export const addSampleAction = (uuid, params) => ({
  type: ActionTypes.ADD_SAMPLE,
  payload: { uuid, params },
});

export const setViaAction = (params) => ({
  type: ActionTypes.SET_VIA,
  payload: params,
});

export const changeFiles = (params) => ({
  type: ActionTypes.CHANGE_FILES,
  payload: params,
});
