import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Stage, Layer, Rect, Line, Text, Arrow } from 'react-konva';
import { calculateCentralPoint, calculateDistantPoint } from '../../utils/draws';

import { contrastingColors } from '../../constants/workflows.constants';

const DrawingLayerLive = ({ width, height, zones, processing, showOptions }) => {
  const canvasEl = useRef(null);
  const [activityInterval, setActivityInterval] = useState(null);
  const [zonesActivity, setZonesActivity] = useState({});

  useEffect(() => {
    if (activityInterval) {
      clearTimeout(activityInterval);
    }

    if (processing.zonesActivityStatus) {
      const obj = processing.zonesActivityStatus.reduce((prev, curr) => {
        const zoneId = curr.zoneId;
        const zoneActive = curr.zoneActive;
        return { ...prev, [zoneId]: zoneActive };
      }, {});

      setZonesActivity(obj);
      setActivityInterval(
        setTimeout(() => {
          setZonesActivity({});
        }, 5000)
      );
    }
  }, [processing]);

  const drawLines =
    zones.lines && zones.lines.length
      ? zones.lines.map((line, index) => {
          const points = [
            line.endPoints[0].x * width,
            line.endPoints[0].y * height,
            line.endPoints[1].x * width,
            line.endPoints[1].y * height,
          ];
          const centralPoint = calculateCentralPoint(...points);
          const normalPoint = calculateDistantPoint(...points, 30);

          return (
            <Fragment key={`line-${index}`}>
              <Line points={points} stroke={contrastingColors[index]} />
              <Arrow
                points={[centralPoint.x, centralPoint.y, normalPoint.x, normalPoint.y]}
                stroke={contrastingColors[index]}
                fill={contrastingColors[index]}
              />
            </Fragment>
          );
        })
      : null;

  const drawRoi =
    zones.roi && zones.roi.length
      ? zones.roi.map((rectangle) => {
          return (
            <Rect
              stroke={'yellow'}
              fill={'transparent'}
              strokeWidth={3}
              cornerRadius={2}
              x={rectangle.x * width}
              y={rectangle.y * height}
              width={rectangle.width * width}
              height={rectangle.height * height}
            />
          );
        })
      : null;

  const drawZoi =
    zones.zoi && zones.zoi.length
      ? zones.zoi.map((polygon, index) => {
          const points = [];
          const entrypoint = [];
          polygon.points.map((point) => {
            points.push(point.x * width, point.y * height);
          });

          polygon.entrypoint.map((point) => {
            entrypoint.push(point.x * width, point.y * height);
          });

          return (
            <Fragment key={`zone-${index}`}>
              <Line
                closed
                stroke={contrastingColors[index]}
                fill={`${contrastingColors[index] + (zonesActivity[polygon.id] ? '4D' : '00')}`}
                strokeWidth={3}
                points={points}
              />
              <Line closed stroke={contrastingColors[index]} strokeWidth={3} points={entrypoint} />
            </Fragment>
          );
        })
      : null;

  return (
    <Stage style={{ position: 'absolute' }} ref={canvasEl} width={width} height={height}>
      <Layer listening={false}>
        {showOptions.isShowLines && drawLines}
        {showOptions.isShowZones && drawZoi}
      </Layer>
    </Stage>
  );
};

export default DrawingLayerLive;
