import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { isArrayLength } from '../../../../utils';
import { DASHBOARD } from '../../../../constants/routes.constants';
import CameraEventElement from '../../../../components/CameraEventElement';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import { remove } from '../../../../actions/notification.actions';
import { getCameras } from '../../../../actions/cameras.actions';
import i18n from '../../../../i18n';
import WatchFilter from '../../../../components/WatchFilter';
import { subjectTypes } from '../../../../constants/alerts.constants';
import { getWorkflows, getWorkflowTriggers } from '../../../../actions/workflows.action';

const LatestAlerts = ({
  notifications,
  allNotifications,
  isLoading,
  onScrolledBottom,
  remove,
  getCameras,
  getWorkflows,
  getWorkflowTriggers,
}) => {
  const [cameras, setCameras] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [activeTriggers, setActiveTriggers] = useState([]);
  const [filterTriggers, setFilterTriggers] = useState([]);
  const [active, setActive] = useState([
    { title: 'Type', key: 'All' },
    { title: 'Camera', key: 'All' },
  ]);

  useEffect(() => {
    getCameras(
      { page: 0, size: 100 },
      {
        onSuccess: (cameras) => {
          setCameras(cameras.items);
        },
      }
    );
    getWorkflows(
      {},
      {
        onSuccess: (workflows) => {
          getWorkflows(
            { size: workflows.total, page: 0 },
            {
              onSuccess: (wfs) => setWorkflows(wfs.items),
            }
          );
        },
      }
    );
  }, []);

  useEffect(() => {
    workflows.forEach((item) => {
      getWorkflowTriggers(
        item.uuid,
        { page: 0, size: 100 },
        {
          onSuccess: (triggers) => {
            setActiveTriggers((prev) => [...triggers.map((item) => item.type), ...prev]);
          },
        }
      );
    });
  }, [workflows]);

  useEffect(() => {
    setFilterTriggers(
      subjectTypes
        .filter((trigger) => activeTriggers?.some((elem) => trigger.triggers.includes(elem)) === true)
        .map((item) => (item = item.name))
    );
  }, [activeTriggers]);

  return (
    <div className="dashboard__alerts-wrapper h-100">
      <WatchFilter
        title={i18n.t('watch:latestActivityTitle')}
        filters={[
          { title: 'Type', options: filterTriggers },
          { title: 'Camera', options: cameras.map((cam) => cam.name) },
        ]}
        onChange={(title, key) =>
          setActive(
            active.map((item) =>
              item.title === title ? { title: title, key: key } : { title: item.title, key: item.key }
            )
          )
        }
      />
      <InfiniteScroll loading={isLoading} className="latest-alerts" onScrolledBottom={onScrolledBottom}>
        <div className="latest-alerts__list-wrapper">
          <div className="latest-alerts__list">
            {isArrayLength(allNotifications)
              ? (active[1].key === 'All'
                  ? allNotifications
                  : notifications
                      .filter((el) =>
                        active[1].key === 'All'
                          ? true
                          : active[1].key === cameras.filter((data) => data.uuid === el.cameraUuid)[0]?.name
                      )
                      .map((el) => el.data)
                      .flat()
                )
                  .filter((el) =>
                    active[0].key === 'All'
                      ? true
                      : active[0].key ===
                        subjectTypes.filter((data) => data.triggers.includes(el.eventType))[0]?.name
                  )
                  .map((e, index) =>
                    index < 20 ? (
                      <CameraEventElement
                        id={e.notificationId}
                        item={{
                          subject:
                            subjectTypes.filter((data) => data.triggers.includes(e.eventType))[0]?.name +
                            ' ' +
                            (e.data?.plateLabel ? e.data.plateLabel : '') +
                            (e.data?.information?.name !== undefined ? e.data.information?.name : ''),
                          action: e.workflowName,
                          camera: cameras.filter((data) => data.uuid === e.cameraUuid)[0]?.name,
                          timestamp: e.time,
                          thumbUrl: e.thumbUrl,
                        }}
                        deleteAlert={(id) => {
                          remove(id, e.cameraUuid);
                        }}
                        to={{
                          pathname: `${DASHBOARD}/${e.cameraUuid}`,
                          state: { alert: e },
                        }}
                      />
                    ) : (
                      ''
                    )
                  )
              : !isLoading && (
                  <div className="selected-camera__no-selected">
                    <p className="text-text-no-selected selected-camera__no-connected">
                      {i18n.t('notFound')}
                    </p>
                  </div>
                )}
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
};

LatestAlerts.propTypes = {
  notifications: PropTypes.array,
  filters: PropTypes.array,
  isLoading: PropTypes.bool,
  deleteAlert: PropTypes.func,
  getAlerts: PropTypes.func,
  getAlertsNextPage: PropTypes.func,
  getCameras: PropTypes.func,
  getWorkflows: PropTypes.func,
  getWorkflowTriggers: PropTypes.func,
};

const mapStateToProps = ({ notifications }) => {
  return {
    notifications: notifications.alerts,
    allNotifications: notifications.allAlerts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  remove: (id, cameraUuid) => dispatch(remove(id, cameraUuid)),
  getCameras: (params, actions) => dispatch(getCameras(params, actions)),
  getWorkflows: (params, actions) => dispatch(getWorkflows(params, actions)),
  getWorkflowTriggers: (uuid, params, actions) => dispatch(getWorkflowTriggers(uuid, params, actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LatestAlerts);
