import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '../../components/Table';
import i18n from '../../i18n';
import DropdownDotMenu from '../../components/DropdownDotMenu';

import ModalAdd from './Modals/ModalAdd';
import ModalEdit from './Modals/ModalEdit';

import * as locationActions from '../../actions/locations.actions';
import ModalDelete from '../../components/ModalDelete';

const MODAL_ADD = 'modalAdd';
const MODAL_EDIT = 'modalEdit';
const MODAL_DELETE = 'modalDelete';
const defaultModal = {
  isOpen: false,
  errorMsg: '',
  location: {},
  resetError: function () {
    this.errorMsg = '';
  },
};

class LocationsList extends Component {
  state = {
    modalAdd: { ...defaultModal },
    modalEdit: { ...defaultModal },
    modalDelete: { ...defaultModal },
  };

  componentDidMount() {
    this.props.getLocations({ size: 10 });
  }

  addLocation = (location) => {
    this.props.addLocation(location, {
      onSuccess: () => {
        this.props.getLocations();
      },
    });
    this.closeModal(MODAL_ADD);
  };

  updateLocation = (location) => {
    this.props.updateLocation(this.state[MODAL_EDIT].location.uuid, location, {
      onSuccess: () => {
        this.props.getLocations();
      },
    });
    this.closeModal(MODAL_EDIT);
  };

  deleteLocation = () => {
    this.props.deleteLocation(this.state[MODAL_DELETE].location.uuid, {
      onSuccess: () => {
        this.props.getLocations();
      },
    });
    this.closeModal(MODAL_DELETE);
  };

  fetchLocationData = (uuid) => {
    this.props.getLocation(uuid, {
      onSuccess: (location) => {
        this.setState({
          [MODAL_EDIT]: {
            ...this.state[MODAL_EDIT],
            location,
            isOpen: true,
          },
        });
      },
    });
  };

  toggleModal = (modalName, uuid) => {
    if (modalName === MODAL_EDIT) {
      this.fetchLocationData(uuid);
    } else {
      this.setState({
        [modalName]: {
          ...this.state[modalName],
          isOpen: !this.state[modalName].isOpen,
        },
      });
    }
  };

  closeModal = (modalName) => {
    this.setState({ [modalName]: { ...defaultModal } });
  };

  createRows = (rows) => {
    return rows.map((location) => ({
      id: location.uuid,
      cells: [
        {
          label: location.name,
          cellComponent: <h5 className="table__cell table__cell--bold">{location.name}</h5>,
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                { name: 'Edit', onClick: () => this.toggleModal(MODAL_EDIT, location.uuid) },
                { name: 'Deactivate', onClick: () => {}, hasBorderBottom: true },
                { divider: true },
                {
                  name: 'Delete',
                  onClick: () => {
                    this.setState({
                      [MODAL_DELETE]: {
                        ...this.state[MODAL_DELETE],
                        location,
                        isOpen: true,
                      },
                    });
                  },
                },
              ]}
            />
          ),
        },
      ],
    }));
  };

  render() {
    const headerOptions = [
      {
        label: 'Name',
      },
      {
        label: '',
      },
    ];

    const { modalAdd, modalEdit, modalDelete } = this.state;

    return (
      <>
        <Table
          rows={this.createRows(this.props.list)}
          headerOptions={headerOptions}
          title={i18n.t('nav:locations')}
          totalRows={this.props.total}
          size={this.props.size}
          page={this.props.page}
          isLoading={this.props.isLoading}
          onClick={() => this.toggleModal(MODAL_ADD)}
          changePage={(page) => this.props.getLocations({ page })}
        />
        {modalAdd.isOpen && (
          <ModalAdd
            loading={false}
            params={modalAdd}
            onSubmit={this.addLocation}
            onClose={() => this.closeModal(MODAL_ADD)}
            className="modal-locations"
          />
        )}
        {modalEdit.isOpen && (
          <ModalEdit
            loading={false}
            params={modalEdit}
            onSubmit={this.updateLocation}
            onClose={() => this.closeModal(MODAL_EDIT)}
            className="modal-locations"
          />
        )}
        {modalDelete.isOpen && (
          <ModalDelete
            isOpen={modalDelete.isOpen}
            header={i18n.t('locationsModals:deleteTitle')}
            body={i18n.t('locationsModals:deleteBody', { locationName: modalDelete.location.name })}
            onClose={() => this.closeModal(MODAL_DELETE)}
            onDelete={this.deleteLocation}
          />
        )}
      </>
    );
  }
}

LocationsList.propTypes = {
  locationsList: PropTypes.array,
};

const mapStateToProps = ({ locations }) => {
  const {
    list: { items, total },
    query: { size, page, name },
    isLoading,
  } = locations;
  return { list: items, size, page, total, isLoading };
};

const mapDispatchToProps = (dispatch) => ({
  getLocations: (params, actions) => dispatch(locationActions.getLocations(params, actions)),
  getLocation: (uuid, actions) => dispatch(locationActions.getLocation(uuid, actions)),
  addLocation: (params, actions) => dispatch(locationActions.addLocation(params, actions)),
  updateLocation: (uuid, params, actions) => dispatch(locationActions.updateLocation(uuid, params, actions)),
  deleteLocation: (uuid, actions) => dispatch(locationActions.deleteLocation(uuid, actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationsList);
