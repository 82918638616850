import * as types from './actions';
import { attachModalTabs } from '../../../../constants/workflows.constants';

export const initialState = {
  availableTabs: [attachModalTabs[0].value, attachModalTabs[1].value],
  activeTab: attachModalTabs[0].value,
  activeTabChanged: false,
  availableCameras: [],
  cameras: [
    {
      selected: null,
      cameraFrame: null,
      elements: [],
      elementOptions: {},
      loadedZones: {},
      loading: false,
      loadingError: false,
    },
  ],
  triggers: [],
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CHANGE_VALUE:
      return {
        ...state,
        [payload.key]: payload.value,
      };

    case types.ADD_CAMERA:
      return {
        ...state,
        cameras: [
          ...state.cameras,
          {
            selected: null,
            cameraFrame: null,
            elements: [],
            elementOptions: {},
            loadedZones: {},
            loading: false,
            loadingError: false,
          },
        ],
      };

    case types.REMOVE_CAMERA:
      return {
        ...state,
        cameras: [...state.cameras.filter((camera, index) => index !== payload.index)],
      };

    case types.CHANGE_CAMERA:
      return {
        ...state,
        cameras: [
          ...state.cameras.map((camera, index) => {
            if (index === payload.index) {
              return {
                ...camera,
                [payload.key]: payload.value,
              };
            } else {
              return camera;
            }
          }),
        ],
      };

    case types.ADD_TRIGGER:
      return {
        ...state,
        triggers: [
          ...state.triggers,
          {
            availableTriggers: [],
            selectedCamera: null,
            selectedTrigger: null,
            zones: [],
            selectedZone: null,
            config: [],
            enabled: true,
          },
        ],
      };

    case types.REMOVE_TRIGGER:
      return {
        ...state,
        triggers: [...state.triggers.filter((trigger, index) => index !== payload.index)],
      };

    case types.CHANGE_TRIGGER:
      return {
        ...state,
        triggers: [
          ...state.triggers.map((trigger, index) => {
            if (index === payload.index) {
              return {
                ...trigger,
                [payload.key]: payload.value,
              };
            } else {
              return trigger;
            }
          }),
        ],
      };

    default:
      return state;
  }
};
