import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import moment from 'moment';

import DropdownDotMenu from '../../components/DropdownDotMenu';
import Table from '../../components/Table';
import ModalDelete from '../../components/ModalDelete';

import Avatar from '../../components/Avatar';
import ModalAdd from './Modals/ModalAdd';
import ModalEdit from './Modals/ModalEdit';

import * as subjectActions from '../../actions/subject.actions';
import * as locationActions from '../../actions/locations.actions';

import { personsSex } from '../../constants/subjects.constants';

const MODAL_ADD = 'modalAdd';
const MODAL_EDIT = 'modalEdit';
const MODAL_DELETE = 'modalDelete';
const defaultModal = {
  isOpen: false,
  errorMsg: '',
  person: {},
  resetError: function () {
    this.errorMsg = '';
  },
};

class PersonsList extends Component {
  state = {
    modalAdd: { ...defaultModal },
    modalEdit: { ...defaultModal },
    modalDelete: { ...defaultModal },
  };

  componentDidMount() {
    this.props.getPersons({ size: 10 });
  }

  addPerson = (person) => {
    this.props.addPerson(person, {
      onSuccess: () => {
        this.props.getPersons();
      },
    });
    this.closeModal(MODAL_ADD);
  };

  updatePerson = (uuid, person) => {
    this.props.updatePerson(uuid, person, {
      onSuccess: () => {
        this.props.getPersons();
      },
    });
    this.closeModal(MODAL_EDIT);
  };

  deletePerson = () => {
    this.props.deletePerson(this.state[MODAL_DELETE].person.uuid, {
      onSuccess: () => {
        this.props.getPersons();
      },
    });
    this.closeModal(MODAL_DELETE);
  };

  fetchPersonData = (uuid) => {
    this.props.getPerson(uuid, {
      onSuccess: (person) => {
        this.setState({
          [MODAL_EDIT]: {
            ...this.state[MODAL_EDIT],
            person: {
              ...person,
              location: person.location ? { label: person.location.name, value: person.location.uuid } : null,
            },
            isOpen: true,
          },
        });
      },
    });
  };

  toggleModal = (modalName, uuid) => {
    if (modalName === MODAL_EDIT) {
      this.fetchPersonData(uuid);
    } else {
      this.setState({
        [modalName]: {
          ...this.state[modalName],
          isOpen: !this.state[modalName].isOpen,
        },
      });
    }
  };

  closeModal = (modalName) => {
    this.setState({ [modalName]: { ...defaultModal } });
  };

  createRows = (rows) => {
    return rows.map((person) => ({
      id: person.uuid,
      cells: [
        {
          label: person.name,
          align: 'right',
          padding: '0px',
          cellComponent: (
            <div className="d-flex align-items-center">
              <Avatar
                avatarUrl={person.photos && person.photos.length ? person.photos[0].url : ''}
                width={40}
                name={person.name}
              />
              <h5 className="table__cell table__cell--bold ml-2">{person.name}</h5>
            </div>
          ),
        },
        {
          label: person.location?.name,
        },
        {
          label: personsSex[person.sex],
        },
        {
          label: person.birthday && moment(person.birthday).format(moment.HTML5_FMT.DATE),
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                { name: 'Edit', onClick: () => this.toggleModal(MODAL_EDIT, person.uuid) },
                { divider: true },
                {
                  name: 'Delete',
                  onClick: () => {
                    this.setState({
                      [MODAL_DELETE]: {
                        ...this.state[MODAL_DELETE],
                        person,
                        isOpen: true,
                      },
                    });
                  },
                },
              ]}
            />
          ),
        },
      ],
    }));
  };

  render() {
    const headerOptions = [
      {
        label: 'Name',
      },
      {
        label: 'Working Place',
      },
      {
        label: 'Sex',
      },
      {
        label: 'Birthday',
      },
      {
        label: '',
      },
    ];

    const { modalAdd, modalEdit, modalDelete } = this.state;

    return (
      <>
        <Table
          rows={this.createRows(this.props.list)}
          headerOptions={headerOptions}
          title={i18n.t('nav:persons')}
          totalRows={this.props.total}
          size={this.props.size}
          page={this.props.page}
          isLoading={this.props.isLoading}
          onClick={() => this.toggleModal(MODAL_ADD)}
          changePage={(page) => this.props.getPersons({ page: page })}
        />
        {modalAdd.isOpen && (
          <ModalAdd
            loading={false}
            params={modalAdd}
            type="create"
            onSubmit={this.addPerson}
            onClose={() => this.closeModal(MODAL_ADD)}
            className="modal-persons"
            getLocations={this.props.getLocations}
          />
        )}
        {modalEdit.isOpen && (
          <ModalEdit
            loading={false}
            params={modalEdit}
            type="edit"
            onSubmit={this.updatePerson}
            onClose={() => this.closeModal(MODAL_EDIT)}
            className="modal-persons"
            getLocations={this.props.getLocations}
          />
        )}
        {modalDelete.isOpen && (
          <ModalDelete
            isOpen={modalDelete.isOpen}
            header={i18n.t('personModals:deleteTitle')}
            body={i18n.t('personModals:deleteBody', { personName: modalDelete.person.name })}
            onClose={() => this.closeModal(MODAL_DELETE)}
            onDelete={this.deletePerson}
          />
        )}
      </>
    );
  }
}

PersonsList.propTypes = {
  personsList: PropTypes.array,
};

const mapStateToProps = ({ persons, personView }) => {
  const {
    list: { items, total },
    query: { size, page, name },
    isLoading,
  } = persons;
  const { loadingAddPerson } = personView.person;
  return { list: items, size, page, total, isLoading, loadingAddPerson };
};

const mapDispatchToProps = (dispatch) => ({
  getLocations: (params, actions) => dispatch(locationActions.getLocations(params, actions)),
  getPersons: (params, actions) => dispatch(subjectActions.getPersons(params, actions)),
  addPerson: (params, actions) => dispatch(subjectActions.addPerson(params, actions)),
  updatePerson: (uuid, params, actions) => dispatch(subjectActions.updatePerson(uuid, params, actions)),
  deletePerson: (uuid, actions) => dispatch(subjectActions.deletePerson(uuid, actions)),
  getPerson: (uuid, actions) => dispatch(subjectActions.getPerson(uuid, actions)),
  setSearchPersons: (text) => dispatch(subjectActions.setSearchPersons(text)),
  setNumberPagePersons: (number) => dispatch(subjectActions.setNumberPageSubjects(number)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonsList);
