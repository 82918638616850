export const CHANGE_REPORTS_VALUE = 'CHANGE_REPORTS_VALUE';
export const CHANGE_ANALYTIC_FILTER_VALUE = 'CHANGE_ANALYTIC_FILTER_VALUE';
export const CHANGE_MAINTENANCE_FILTER_VALUE = 'CHANGE_MAINTENANCE_FILTER_VALUE';

export const RESET_ANALYTIC_FILTERS = 'RESET_ANALYTIC_FILTERS';
export const RESET_MAINTENANCE_FILTERS = 'RESET_MAINTENANCE_FILTERS';

export const analyticsForReports = [
  'LineCrossingObjectsCounting',
  'ZonesHeatMapGeneratorByDetections',
  'ZonesHeatMapGeneratorByQueues',
  'ZonesAccumulationAnalytics',
  'ZonesRealTimeActivityDurationAnalytics',
  'SubjectsPlateAnalyzer',
  'SubjectsVectorAnalyzer',
  'GroupsDetectorAnalytics',
  'QueuesDetectorAnalytics',
];

export const analyticsWithPreview = [
  'LineCrossingObjectsCounting',
  'ZonesHeatMapGeneratorByDetections',
  'ZonesHeatMapGeneratorByQueues',
  'ZonesAccumulationAnalytics',
  'ZonesRealTimeActivityDurationAnalytics',
  'GroupsDetectorAnalytics',
  'QueuesDetectorAnalytics',
];

export const analyticsWithTable = [
  'ZonesAccumulationAnalytics',
  'ZonesRealTimeActivityDurationAnalytics',
  'SubjectsPlateAnalyzer',
  'SubjectsVectorAnalyzer',
  'GroupsDetectorAnalytics',
  'QueuesDetectorAnalytics',
  'LineCrossingObjectsCounting',
];
