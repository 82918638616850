import React, { useMemo, useState, useRef, useEffect } from 'react';
import Select, { components } from 'react-select';

import dropdownArrow from '../../../assets/images/dropdownArrow.svg';
import Avatar from '../../Avatar';

const { Option, MultiValueLabel } = components;

const IconMultiValueLabel = (props) => (
  <MultiValueLabel {...props}>
    <Avatar
      className="form-option-icon"
      key={props.data.id}
      avatarUrl={props.data.avatarUrl}
      width={18}
      name={props.data.label}
      fontSize={10}
    />
    {props.data.label}
  </MultiValueLabel>
);

const IconOption = (props) => (
  <Option {...props}>
    <Avatar
      className="form-option-icon"
      key={props.data.id}
      avatarUrl={props.data.avatarUrl}
      width={24}
      name={props.data.label}
      fontSize={10}
    />
    {props.data.label}
  </Option>
);

export function FormMultipleSelect({
  label,
  name,
  value,
  options,
  onChange,
  onBlur,
  onScrollToBottom,
  onInputChange,
  className,
  errorMessage,
  hasIcon,
}) {
  const [isFocus, setFocus] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setFocus(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  const handleInputChange = (search) => {
    onInputChange && onInputChange(search);
  };

  const handleChange = (value) => {
    name ? onChange(name, value) : onChange(value);
  };

  const handleBlur = (e) => {
    setFocus(false);
    onBlur && onBlur(e);
  };

  const DropdownIndicator = () => {
    return <img style={{ width: '14px', height: '14px' }} src={dropdownArrow} alt="arrow" />;
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      padding: '8px 20px',
      fontWeight: state.data.fontWeight,
      cursor: 'pointer',
      backgroundColor: state.isSelected ? '#497a81' : 'transparent',
      ':active': {
        backgroundColor: state.isSelected ? '#497a81' : 'transparent',
      },
      ':hover': {
        backgroundColor: state.isSelected ? '#497a81' : '#2e3236',
      },
    }),
    menuList: (base) => ({
      ...base,
      marginRight: '1px',

      '::-webkit-scrollbar': {
        width: '4px',
        backgroundColor: '#2e3236',
      },
      '::-webkit-scrollbar-track': {
        borderRadius: '3px',
        backgroundColor: '#2e3236',
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '3px',
        backgroundColor: '#497a81',
      },
    }),
    menu: (base) => ({
      ...base,
      position: 'absolute',
      width: '100%',
      padding: '10px 0px',
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)',
      zIndex: '2',
      backgroundColor: '#3c4248',
      borderRadius: '6px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      paddingTop: '18px',
      zIndex: 1,
    }),
    input: (base) => ({
      ...base,
      color: 'white',
      paddingLeft: '1px',
      cursor: 'text',
      zIndex: 1,
    }),
    multiValue: (base) => ({
      ...base,
      minHeight: '18px',
      marginLeft: '4px',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      color: 'white',
    }),
    multiValueLabel: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      padding: '0px',
      paddingLeft: hasIcon ? '0' : '6px',
      paddingRight: '2px',
      fontSize: '12px',
      color: 'white',
    }),
    control: (base, state) => ({
      ...base,
      width: '100%',
      height: '100%',
      minHeight: 'none',
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      borderRadius: '6px',
      border: 'none',
      cursor: 'default',
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      outline: 'none',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      padding: '0 13px',
    }),
  };

  return (
    <div className={className} ref={selectRef}>
      <label
        htmlFor={name}
        className={
          (value && value.length) || isFocus
            ? 'form-item--responsive-label--active'
            : 'form-item--responsive-label'
        }
      >
        {label}
      </label>

      <Select
        components={{
          Option: hasIcon ? IconOption : Option,
          MultiValueLabel: hasIcon ? IconMultiValueLabel : MultiValueLabel,
          DropdownIndicator,
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
        }}
        styles={customStyles}
        options={options}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onInputChange={(text) => handleInputChange(text)}
        onMenuScrollToBottom={onScrollToBottom}
        onFocus={() => setFocus(true)}
        placeholder=""
        isMulti={true}
      />

      {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
    </div>
  );
}
