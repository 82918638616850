import React, { PureComponent } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';

import CameraListElementRealtime from './CameraListElementRealtime';
import CameraListElementScreens from './CameraListElementScreens';
import { ALERT_TIME_FORMAT } from '../../../../constants/alerts.constants';
import { DASHBOARD } from '../../../../constants/routes.constants';
import * as actions from '../../../../actions/cameras.actions';
import icon from '../../../../assets/images/frame-activity-menu.svg';
import i18n from '../../../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';

class CameraListElement extends PureComponent {
  onChangeVisible = (visible) => {
    if (!visible && this.props.isLive) {
      this.props.setCameraLiveReset();
    }
  };

  onChangeLive = (e) => {
    e && e.preventDefault();
    if (this.props.isLive) {
      this.props.setCameraLiveReset(this.props.camera.uuid);
    } else {
      this.props.setCameraLive(this.props.camera.uuid);
    }
  };

  render() {
    const { camera, isLive, time, enabled, wfAnalytics } = this.props;

    var analytic = [],
      workflows = [];
    wfAnalytics.length !== 0 &&
      wfAnalytics.map((wf) =>
        wf.analytics.map((an) => {
          if (an !== '') {
            analytic.push(an);
            workflows.push({ analytic: an, started: wf.wf.started });
          }
        })
      );

    analytic = analytic.sort().filter((it, index) => analytic.sort().indexOf(it) === index);
    const VSSetting = {
      onChange: this.onChangeVisible,
      partialVisibility: true,
    };
    return (
      <VisibilitySensor {...VSSetting}>
        {({ isVisible }) => {
          return (
            <Link to={`${DASHBOARD}/${camera.uuid}`}>
              <figure className="frame">
                {isLive ? (
                  <CameraListElementRealtime camera={camera} />
                ) : (
                  <CameraListElementScreens camera={camera} enabled={enabled} isVisible={isVisible} />
                )}
                <figcaption>
                  <div className="frame__header">
                    {enabled && (
                      <>
                        <div className="frame__time">{time}</div>
                        <button
                          onClick={this.onChangeLive}
                          className={classnames('btn btn--live', { ['btn--live-active']: isLive })}
                        >
                          <span>{i18n.t('watch:liveBtn')}</span>
                        </button>
                      </>
                    )}
                  </div>
                  <div className="frame__bottom">
                    <div className="w-50 h-100 d-flex align-items-end">
                      <div className="frame__title">
                        <p>{camera.name}</p>
                      </div>
                    </div>
                    <div className="frame__activity">
                      {analytic.map((it) => (
                        <div className="d-flex" key={it}>
                          <FontAwesomeIcon
                            style={{ fontSize: '8px', margin: 'auto 0', paddingRight: '2px' }}
                            className={
                              workflows.find((wf) => wf.analytic === it && wf.started === true)
                                ? 'text-text-active'
                                : 'text-text-error'
                            }
                            icon={faDotCircle}
                          />
                          <p>{i18n.t(`watch:${it}`)}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </figcaption>
              </figure>
            </Link>
          );
        }}
      </VisibilitySensor>
    );
  }
}

const mapStateToProps = ({ cameras }, { camera, time }) => {
  const uuid = camera.uuid;
  const screencast = cameras.screencasts.find((x) => x.uuid === uuid);
  return {
    isLive: cameras.live.includes(camera.uuid),
    time:
      screencast && screencast && screencast.timestamp !== time
        ? moment(screencast.timestamp).format(ALERT_TIME_FORMAT)
        : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCameraLive: (uuid) => dispatch(actions.setCameraLive(uuid)),
  setCameraLiveReset: (uuid) => dispatch(actions.setCameraLiveReset(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CameraListElement);
