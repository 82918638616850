export const maintenanceHistory = {
  equipmentInfo: {
    id: '704786ff-34f0-4759-ae0c-38b52bef2e91',
    type: 'Forklift 1',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    location: 'Manufacturing #1, Main Entrance',
    maintenancePeriod: '3 Months',
    lastMaintenance: new Date(),
  },
  breakings: [
    {
      id: '1',
      name: 'Breaking 1',
      date: new Date(),
      problemId: '31352',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      downtime: '3 Days',
    },
    {
      id: '2',
      name: 'Breaking 2',
      date: new Date(),
      problemId: '23432',
      description:
        'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
      downtime: '10 Days',
    },
    {
      id: '3',
      name: 'Breaking 3',
      date: new Date(),
      problemId: '35',
      downtime: '2 Days',
    },
    {
      id: '4',
      name: 'Breaking 4',
      date: new Date(),
      problemId: '352',
      description: 'Quis nostrud exercitation ullamco laboris nisi ut aliquip ',
      downtime: '2 Days',
    },
    {
      id: '5',
      name: 'Breaking 5',
      date: new Date(),
      problemId: '3445',
      downtime: '5 Days',
    },
  ],

  maintenanceProcedures: [
    {
      id: '1',
      name: 'Maintenance 1',
      date: new Date(),
      operations: ['Operation 1', 'Operation 2', 'Operation 3'],
    },
    {
      id: '2',
      name: 'Maintenance 2',
      date: new Date(),
      operations: ['Operation 1', 'Operation 2'],
    },
    {
      id: '3',
      name: 'Maintenance 3',
      date: new Date(),
      operations: ['Operation 1'],
    },
    {
      id: '4',
      name: 'Maintenance 4',
      date: new Date(),
      operations: ['Operation 1', 'Operation 2', 'Operation 3', 'Operation 4'],
    },
    {
      id: '5',
      name: 'Maintenance 5',
      date: new Date(),
      operations: ['Operation 1', 'Operation 3'],
    },
  ],
};
