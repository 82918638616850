import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import rootSaga from '../sagas';
import rootReducer from '../reducers';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger({
  predicate: (getState, action) =>
    !['SET_SUBSCRIBE_CAMERA', 'SET_EVENTS_CACHE', 'COPY_EVENTS_TO_CACHE'].includes(action.type),
  collapsed: true,
});

const middleware = [sagaMiddleware, routerMiddleware(history)];
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

const enhancers = [applyMiddleware(...middleware)];
const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Prevent recomputing reducers for `replaceReducer`
        shouldHotReload: false,
      })
    : compose;

export default preloadedState => {
  const store = createStore(rootReducer(history), preloadedState, composeEnhancers(...enhancers));

  sagaMiddleware.run(rootSaga);
  return store;
};
