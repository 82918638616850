import {
  faVideo,
  faUsers,
  faCogs,
  faCalendarAlt,
  faCaretDown,
  faExpand,
  faCompress,
  faEye,
  faEyeSlash,
  faSignOutAlt,
  faVolumeUp,
  faVolumeMute,
  faCamera,
  faPlay,
  faPause,
  faForward,
  faStepForward,
  faBackward,
  faStepBackward,
  faSearch,
  faMapMarkerAlt,
  faPen,
  faTrashAlt,
  faUnlink,
  faBell,
  faInfoCircle,
  faArrowLeft,
  faSpinner,
  faAngleLeft,
  faAngleRight,
  faTimes,
  faExclamationTriangle,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

export default () =>
  library.add(
    faVideo,
    faUsers,
    faCogs,
    faCalendarAlt,
    faCaretDown,
    faExpand,
    faCompress,
    faEye,
    faEyeSlash,
    faSignOutAlt,
    faVolumeUp,
    faVolumeMute,
    faCamera,
    faPlay,
    faPause,
    faForward,
    faStepForward,
    faBackward,
    faStepBackward,
    faSearch,
    faMapMarkerAlt,
    faPen,
    faTrashAlt,
    faUnlink,
    faBell,
    faInfoCircle,
    faArrowLeft,
    faSpinner,
    faAngleLeft,
    faAngleRight,
    faTimes,
    faExclamationTriangle,
    faPlus
  );
