import request from '../utils/request';
import { queryStringify } from '../utils';

export function getAlerts(params) {
  return request({
    url: `/events`,
    method: 'get',
    data: params,
  });
}

export function getAlertRoutes(params) {
  return request({
    url: `/alertRoutes?${queryStringify(params)}`,
    method: 'get',
  });
}

export function addAlertRoute(params) {
  return request({
    url: '/alertRoutes',
    method: 'post',
    data: params,
  });
}

export function getAlertRoute(uuid) {
  return request({
    url: `/alertRoutes/${uuid}`,
    method: 'get',
  });
}

export function deleteAlertRoute(uuid) {
  return request({
    url: `/alertRoutes/${uuid}`,
    method: 'delete',
  });
}
