import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import SignIn from '../container/public/signIn';
// import NotFound from '../container/public/notFound';

const AuthRouter = () => {
  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      {/* <Route component={NotFound} /> */}
      <Redirect to="/" />
    </Switch>
  );
};

export default AuthRouter;
