import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';

import * as actions from '../../../actions/player.actions';
import { TIMELINE_RENGE_LIST } from '../constants';
import cx from 'classnames';

class PlayerControls extends Component {
  setRange = (e) => {
    const val = e.target.value;
    this.props.setRange(val);
  };

  render() {
    const { live, pause, sound, range, speed } = this.props;
    return (
      <div className="camera-view__timeline-controls">
        {/*
          !!! WARNING !!!
          Not deleted unused constants and methods !
          TODO: Сomment out the code and delete inline style
        */}
        <div className="zoom" style={{ minWidth: '60px' }}>
          <div className="time">
            {Object.keys(TIMELINE_RENGE_LIST).map((x, i) => {
              const { label, value } = TIMELINE_RENGE_LIST[x];
              const isActive = range === value;
              return (
                <label key={i} htmlFor={`range${label}`} className={isActive ? 'is-active' : undefined}>
                  <input
                    type="radio"
                    name="range"
                    value={value}
                    checked={isActive}
                    id={`range${label}`}
                    onChange={this.setRange}
                  />
                  {label}
                </label>
              );
            })}
          </div>
          <div className="camera-view__timeline-controls__speed">
            <button onClick={() => this.props.setSpeedDown()}>-</button>
            <div>{`${speed}x`}</div>
            <button onClick={() => this.props.setSpeedUp()}>+</button>
          </div>
        </div>
        <div className="camera-view__timeline-controls__control">
          <button onClick={() => this.props.setStepStart()}>
            <FontAwesomeIcon icon="backward" />
          </button>
          <button onClick={() => this.props.setStepBackward()}>
            <FontAwesomeIcon icon="step-backward" />
          </button>
          <button onClick={() => this.props.setPause()} disabled={live}>
            {pause ? (
              <FontAwesomeIcon icon="play" style={{ width: 18, height: 18 }} />
            ) : (
              <FontAwesomeIcon icon="pause" style={{ width: 18, height: 18 }} />
            )}
          </button>
          <button onClick={() => this.props.setStepForward()}>
            <FontAwesomeIcon icon="step-forward" />
          </button>
          <button onClick={() => this.props.setStepEnd()}>
            <FontAwesomeIcon icon="forward" />
          </button>
        </div>
        <div className="camera-view__timeline-actions">
          <button className="camera-view__timeline-actions-button">
            <FontAwesomeIcon icon="camera" />
          </button>
          <button onClick={() => this.props.setSound()} className="camera-view__timeline-actions-button">
            {sound ? <FontAwesomeIcon icon="volume-up" /> : <FontAwesomeIcon icon="volume-mute" />}
          </button>
          <button
            onClick={() => this.props.setLive()}
            className={cx('btn btn--live', { 'btn--live-active': live })}
          >
            LIVE
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ player: { controls } }) => {
  return {
    live: controls.live,
    pause: controls.pause,
    sound: controls.sound,
    range: controls.range,
    speed: controls.speed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setLive: () => dispatch(actions.setLive()),
  setPause: () => dispatch(actions.setPause()),
  setSound: () => dispatch(actions.setSound()),
  setSpeedUp: () => dispatch(actions.setSpeedUp()),
  setStepForward: () => dispatch(actions.setStepForward()),
  setStepBackward: () => dispatch(actions.setStepBackward()),
  setStepStart: () => dispatch(actions.setStepStart()),
  setStepEnd: () => dispatch(actions.setStepEnd()),
  setSpeedDown: () => dispatch(actions.setSpeedDown()),
  setRange: (value) => dispatch(actions.setRange(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerControls);
