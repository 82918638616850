import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import i18n from '../../../../i18n';
import { getSubjectAge, getSubjectSex } from '../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SUBJECTS } from '../../../../constants/routes.constants';
import SubjectPhotos from './SubjectPhotos';
import SubjectAlerts from './SubjectAlerts';
import ModelsService from '../../../../services/ModelsService';
import * as actions from '../../../../actions/subject.actions';
import ModalEdit from '../Modals/ModalEdit';
import ModalDelete from '../../../../components/ModalDelete';
import Loader from '../../../../components/Loader';

const MODAL_EDIT = 'modalEdit';
const MODAL_DELETE = 'modalDelete';
const defaultModal = {
  isOpen: false,
  errorMsg: '',
  resetError: function () {
    this.errorMsg = '';
  },
};

class SubjectView extends Component {
  state = {
    modalEdit: { ...defaultModal },
    modalDelete: { ...defaultModal },
  };

  componentDidMount() {
    const { uuid } = this.props.match.params;

    if (uuid) {
      this.props.getSubject(uuid);
    }
  }

  componentWillUnmount() {
    this.props.resetSubjectState();
  }

  updateSubject = (params) => {
    this.props.updateSubject(
      { ...params, uuid: this.props.subject.uuid },
      {
        onSuccess: () => this.closeModal(MODAL_EDIT),
        onError: (error) => this.setModalError(MODAL_EDIT, error),
      }
    );
  };

  deleteSubject = () => {
    this.props.deleteSubject(this.props.subject.uuid, {
      onSuccess: () => {
        this.closeModal(MODAL_DELETE);
        this.props.history.replace(SUBJECTS);
      },
      onError: (error) => this.setModalError(MODAL_DELETE, error),
    });
  };

  toggleModal = (modalName) => {
    this.setState({
      [modalName]: {
        ...this.state[modalName],
        isOpen: !this.state[modalName].isOpen,
      },
    });
  };

  setModalError = (modalName, error) => {
    this.setState({
      [modalName]: {
        ...this.state[modalName],
        errorMsg: error.message,
      },
    });
  };

  closeModal = (modalName) => {
    this.setState({ [modalName]: { ...defaultModal } });
  };

  render() {
    const { modalEdit, modalDelete } = this.state;
    const { subject, loadingGetSubject, loadingUpdateSubject, loadingDeleteSubject, match } = this.props;

    return (
      <div className="dashboard subject">
        <div className="dashboard__wrapper">
          <div className="dashboard__item subject-info">
            <Loader loading={loadingGetSubject} />
            <div className="subject-info-link">
              <Link to={SUBJECTS}>
                <FontAwesomeIcon icon="arrow-left" />
                {i18n.t('buttons:back')}
              </Link>
            </div>
            <div className="subject-info-head">
              <div className="subject-info-head-text">
                <strong>{subject.name}</strong>
                <span>{getSubjectSex(subject.sex)}</span>
                <span>{getSubjectAge(subject)}</span>
                <span>{subject.height}</span>
              </div>
              <div className="subject-info-btn-group">
                <button className="btn--transparent mr-10" onClick={() => this.toggleModal(MODAL_EDIT)}>
                  <FontAwesomeIcon icon="pen" />
                </button>
                <button className="btn--transparent" onClick={() => this.toggleModal(MODAL_DELETE)}>
                  <FontAwesomeIcon icon="trash-alt" />
                </button>
              </div>
            </div>
            <SubjectPhotos />
          </div>
          <div className="dashboard__item  dashboard__item--alerts">
            <SubjectAlerts uuid={match.params.uuid} name={subject.name} />
          </div>
          {modalEdit.isOpen && (
            <ModalEdit
              loading={loadingUpdateSubject}
              params={{ ...modalEdit, subject }}
              onClose={() => this.closeModal(MODAL_EDIT)}
              onSubmit={this.updateSubject}
              className="modal-subjects"
            />
          )}
          {modalDelete.isOpen && (
            <ModalDelete
              loading={loadingDeleteSubject}
              isOpen={modalDelete.isOpen}
              errorMsg={modalDelete.errorMsg}
              header={i18n.t('subjectModals:deleteTitle')}
              body={i18n.t('subjectModals:deleteBody', { subjectName: subject.name })}
              onClose={() => this.closeModal(MODAL_DELETE)}
              onDelete={this.deleteSubject}
            />
          )}
        </div>
      </div>
    );
  }
}

SubjectView.propTypes = {
  match: PropTypes.object,
  subject: ModelsService.getSubjectModel(),
  loadingGetSubject: PropTypes.bool,
  loadingUpdateSubject: PropTypes.bool,
  loadingDeleteSubject: PropTypes.bool,
  getSubject: PropTypes.func,
  updateSubject: PropTypes.func,
  deleteSubject: PropTypes.func,
  resetSubjectState: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { subject, loadingGetSubject, loadingUpdateSubject, loadingDeleteSubject } =
    state.subjectView.subject;
  return {
    subject,
    loadingGetSubject,
    loadingUpdateSubject,
    loadingDeleteSubject,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSubject: (uuid) => dispatch(actions.getSubject(uuid)),
  updateSubject: (params, reqActions) => dispatch(actions.updateSubject(params, reqActions)),
  deleteSubject: (uuid, reqActions) => dispatch(actions.deleteSubject(uuid, reqActions)),
  resetSubjectState: () => dispatch(actions.resetSubjectState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubjectView);
