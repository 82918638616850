import * as ActionTypes from '../constants/training.constants';

const initialState = {
  loading: false,
  dataset: {
    items: [],
    total: 0,
  },
  markup: null,
  action: false,
  files: null,
  frame: {
    context: null,
    canvas: null,
  },
  labelCurrent: 0,
  labels: [''],
  educationModal: {
    name: '',
    modelType: null,
    baseModal: null,
    dataset: null,
  },
  datasetModal: {
    name: '',
    modelType: null,
    parameters: [],
    datasets: [],
  },
};

export const trainingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_VIA:
      return {
        ...state,
        action: action.payload,
      };
    case ActionTypes.CHANGE_FILES:
      return {
        ...state,
        files: action.payload,
      };
    case ActionTypes.GET_DATASETS_MARKUP:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.ADD_DATASET_MARKUP:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.UPDATE_DATASET_MARKUP:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DELETE_DATASET_MARKUP:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_DATASETS_MARKUP_RESULT:
      const { items, total } = action.payload;
      return {
        ...state,
        loading: false,
        dataset: { items, total },
      };

    case ActionTypes.UPDATE_DATASET_MARKUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.DELETE_DATASET_MARKUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.GET_DATASETS_MARKUP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.ADD_DATASET_MARKUP_SUCCESS:
    case ActionTypes.ADD_DATASET_MARKUP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.UPDATE_DATASET_MARKUP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.DELETE_DATASET_MARKUP_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.CHANGE_MODAL_TRAINING_VALUE:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          [action.payload.key]: action.payload.value,
        },
      };
    case ActionTypes.CHANGE_TRAINING_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case ActionTypes.RESET_DATASET_MARKUP_STATE:
      return initialState;

    default:
      return state;
  }
};
