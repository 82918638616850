import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DropdownDotMenu from '../DropdownDotMenu';

import ModelsService from '../../services/ModelsService';
import { ALERT_TIME_FORMAT } from '../../constants/alerts.constants';
import Avatar from '../Avatar';
import cx from 'classnames';
import { subjectTypes } from '../../constants/alerts.constants';
const placeholder = 'https://via.placeholder.com/330x185';

const LatestAlertsElement = ({ item, to, callback = () => {}, deleteAlert, onTimeClick = () => {}, id }) => {
  const { camera, action, subject, timestamp, thumbUrl, url } = item;
  const Component = to && !callback ? Link : 'div';

  return (
    <>
      <div className="latest-alerts__item-wrapper">
        <div
          className={cx('latest-alerts__item', {
            'latest-alerts__item--error': subjectTypes.find((item) => item.name === subject.trim())?.error,
            'latest-alerts__item--warning': item.warning,
          })}
        >
          <Link to={to}>
            <Avatar
              width={44}
              avatarUrl={thumbUrl || url ? thumbUrl || url : placeholder}
              className="latest-alerts__item-image"
            />
          </Link>
          <div className="latest-alerts__item-info">
            <div className="latest-alerts__item-info__item-wrapper">
              <div className="latest-alerts__item-info__item">
                <p
                  className="latest-alerts__item-info__text latest-alerts__item-info__text--link latest-alerts__item-info__text--name"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={subject}
                >
                  {subject?.length > 20 ? subject.substring(0, 20) + '...' : subject}
                </p>
              </div>
              <div className="latest-alerts__item-info__item">
                <div style={{ display: 'flex' }}>
                  <p
                    onClick={() => onTimeClick(timestamp)}
                    style={{ cursor: 'pointer' }}
                    className="latest-alerts__item-info__text latest-alerts__item-info__text--opacity"
                  >
                    {timestamp ? moment(timestamp).format(ALERT_TIME_FORMAT) : ''}
                  </p>
                  <DropdownDotMenu
                    options={[
                      {
                        name: 'Delete',
                        onClick: (e) => {
                          deleteAlert(id);
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="latest-alerts__item-info__item-wrapper">
              <div className="latest-alerts__item-info__item">
                <Component to={to} onClick={() => callback && callback(item)}>
                  <p
                    className="latest-alerts__item-info__text latest-alerts__item-info__text--link latest-alerts__item-info__text--opacity"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={camera}
                  >
                    {camera?.length > 20 ? camera.substring(0, 20) + '...' : camera}
                  </p>
                </Component>
              </div>
              <div className="latest-alerts__item-info__item">
                <div>
                  <p
                    className="latest-alerts__item-info__text"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={action}
                  >
                    {action?.length > 15 ? action.substring(0, 15) + '...' : action}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

LatestAlertsElement.propTypes = {
  isVisible: PropTypes.bool,
  callback: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default withRouter(LatestAlertsElement);
