import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as d3 from 'd3';

import {
  EVENT_TYPE_COLOR,
  EVENT_TYPES,
  EVENT_SUBJECT_TYPE_COLOR,
  EVENT_SUBJECT_TYPE,
  getOffsetTime,
  getTiksTime,
} from '../constants';
import { ALERT_TIME_FORMAT, timelineKnownEventTypes } from '../../../constants/alerts.constants';
import * as actions from '../../../actions/player.actions';
import { isTouchDevice } from '../../../utils';
import i18n from '../../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';

const TIMELINE_UPDATE_INTERVAL = 10000;
const MIN_TIMELINE_UPDATE_INTERVAL = 1000;

class PlayerTimeline extends Component {
  constructor(props) {
    super(props);
    // node groups
    this.svg = null;
    this.chart = React.createRef();
    this.innerChart = null;
    this.graphGroup = null;
    this.recodingGroup = null;
    this.eventsGroup = null;
    this.circleGroup = null;

    this.offset = {
      top: 15,
      right: 26,
      bottom: 10,
      left: 26,
    };
    this.lineHeight = 6;
    this.eventHeight = this.lineHeight / 2;
    this.w = null;
    this.h = null;
    this.x = null;
    this.tAxis = null;
    this.mAxis = null;
    this.mAxisFormat = d3.timeFormat('%b %e');
    this.drag = d3
      .drag()
      .on('start', this.dragStartCallback)
      .on('drag', this.dragMoveCallback)
      .on('end', this.dragEndCallback);
    this.circleRadius = 8;
    this.dragCircle = null;
    this.resizeTimer = null;
    this.tooltip = null;
    this.interval = null;
    this.pxMS = 0;
    this.lastTimelineUpdate = 0;
  }

  initialSize = (width, height) => {
    const { left, right, top, bottom } = this.offset;
    this.w = width - left - right;
    this.h = height - top - bottom;
  };

  initialAxis = (range) => {
    const { w, mAxisFormat } = this;
    const domain = getOffsetTime(range);
    this.x = d3
      .scaleTime()
      .domain([domain.from(), domain.to()])
      .range([0, w - 2])
      .clamp(true);

    this.tAxis = d3
      .axisBottom()
      .scale(this.x)
      .tickSize(7, 0)
      .tickFormat(domain.format)
      .ticks(getTiksTime(domain.ticks));
    this.mAxis = d3.axisBottom().scale(this.x).ticks(1).tickSize(21, 0).tickFormat(mAxisFormat);
  };

  initialDraw = () => {
    const { w, h, offset, chart, tAxis, mAxis, lineHeight } = this;
    const width = w + offset.left + offset.right;
    const height = h + offset.top + offset.bottom;
    this.svg = d3
      .select(chart.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .classed('svg-wrapper', true);
    this.innerChart = this.svg
      .append('g')
      .classed('inner-chart', true)
      .attr('transform', `translate(${offset.left}, ${offset.top})`);
    this.innerChart
      .append('g')
      .classed('axis date', true)
      .attr('transform', `translate(0, ${h - offset.top - offset.bottom})`)
      .transition()
      .call(tAxis);
    this.innerChart
      .append('g')
      .classed('axis month', true)
      .attr('transform', `translate(0, ${h - offset.top - offset.bottom})`)
      .transition()
      .call(mAxis);

    this.graphGroup = this.innerChart.append('g').classed('graph-group', true);
    this.graphGroup
      .append('rect')
      .attr('width', w)
      .attr('height', lineHeight)
      .attr('fill', '#000')
      .attr('stroke', '#000')
      .attr('stroke-width', '0.5')
      .attr('transform', `translate(0, 0)`)
      .classed('camera-view__timeline', true);

    this.recodingGroup = this.graphGroup
      .append('g')
      .classed('recoding-group', true)
      .attr('fill', EVENT_TYPE_COLOR[EVENT_TYPES[3]])
      .attr('transform', `translate(0, 0)`);

    this.eventsGroup = this.graphGroup
      .append('g')
      .classed('events-group', true)
      .attr('transform', `translate(0, 0)`);

    this.circleGroup = this.innerChart.append('g').classed('circle-group', true);
    this.tooltip = d3.selectAll('.camera-view__player-wrapper').append('div').classed('frames', true);

    this.times = d3.selectAll('.camera-view__player-wrapper').append('div').classed('times', true);

    this.pxMS = this.calculatePixelMilliseconds();
  };

  componentDidMount() {
    const { chart, props } = this;
    window.addEventListener('resize', this.updateDimensions);
    this.initialSize(chart.current.offsetWidth, 60);
    this.initialAxis(props.range);
    this.initialDraw();
    this.runInterval();
  }

  runInterval = () => {
    this.interval = d3.interval(this.intervalUpdate, TIMELINE_UPDATE_INTERVAL);
  };

  stopInterval = () => {
    if (this.interval) {
      this.interval.stop();
      this.interval = null;
    }
  };

  intervalUpdate = () => {
    this.updateLineData();
  };

  calculatePixelMilliseconds = () => {
    let px1 = moment(this.x.invert(1)).format('x');
    let px2 = moment(this.x.invert(2)).format('x');
    return +px2 - +px1;
  };

  componentDidUpdate(prevProps) {
    const { current, pause } = this.props;
    this.updateCircle(current);

    if (prevProps.pause !== pause) {
      if (pause) {
        this.stopInterval();
      } else {
        this.runInterval();
      }
    }
    const prevEventsLen = prevProps.events.length;
    const prevAlertsLen = prevProps.alerts.length;
    const prevRecLen = prevProps.recordings.length;
    const eventsLen = this.props.events.length;
    const alertsLen = this.props.alerts.length;
    const recLen = this.props.recordings.length;

    if (!pause && (prevEventsLen !== eventsLen || prevAlertsLen !== alertsLen || prevRecLen !== recLen)) {
      this.updateLineData();
    }
  }

  componentWillUnmount() {
    this.stopInterval();
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.svg.remove();
      this.initialSize(this.chart.current.offsetWidth, 60);
      this.initialAxis(this.props.range);
      this.initialDraw();
      this.updateLineData();
    }, 100);
  };

  dragStartCallback = (d) => {
    if (!this.props.pause) {
      this.stopInterval();
    }

    this.handlerMoveTimes(d);
  };

  dragMoveCallback = (d) => {
    this.handlerMoveTimes(d);
  };

  dragEndCallback = () => {
    const { x, w, props, times } = this;
    const currentPosX = this.getCurrentPosX();
    const coordinate = x.invert(currentPosX);

    if (w - 2 === currentPosX) {
      props.setLive(true);
    } else {
      this.setCurrentTime(coordinate);
    }

    times.style('display', 'none');

    if (!this.props.pause) {
      this.runInterval();
    }
  };

  handlerMoveTimes = (d) => {
    const { x, times, graphGroup } = this;
    const currentPosX = this.getCurrentPosX();
    const graphGroupRect = graphGroup.node().getBoundingClientRect();
    const left = graphGroupRect.left + currentPosX;
    const headerHeight = 69; // 69 - header height
    const top = graphGroupRect.y + graphGroupRect.height - headerHeight + 15;

    console.log(`handlerMoveTimes ${left} ${top} ${moment(x.invert(d.x)).format('h:mm A')}`);
    times
      .style('display', 'flex')
      .style('left', left + 'px')
      .style('top', top + 'px')
      .html(`${moment(x.invert(currentPosX)).format('h:mm A')}`);
    this.dragCircle.attr('cx', currentPosX);
  };

  getCurrentPosX = () => Math.max(0, Math.min(this.w - 2, d3.event.x));

  graphGroupCallback = (event) => {
    if (d3.event.target.tagName === 'text' && d3.event.target.classList.contains('exclamatory')) {
      d3.event.preventDefault();
    } else {
      const coordinate = d3.mouse(event);
      const current = this.x.invert(coordinate[0]);
      this.setCurrentTime(current);
    }
  };

  setCurrentTime = (time) => {
    this.props.setCurrent(time);
  };

  updateAxis = (range) => {
    this.initialAxis(range);
    this.innerChart.select('.date').transition().call(this.tAxis);
    this.innerChart.select('.month').transition().call(this.mAxis);
  };

  updateEvents = (events) => {
    const { x, eventHeight } = this;
    const rect = this.eventsGroup.selectAll('rect').data(events);
    const width = ({ eventTimestamp }) =>
      Math.max(1, x(moment(eventTimestamp).toDate()) - x(moment(eventTimestamp).toDate()));
    const transform = ({ subject, eventTimestamp }) => {
      let y = subject ? 0 : eventHeight;
      return `translate(${x(moment(eventTimestamp).toDate())},${y})`;
    };
    const fill = ({ eventType }) => {
      return this.props.faceLegend
        ? timelineKnownEventTypes?.includes(eventType)
          ? EVENT_SUBJECT_TYPE_COLOR[EVENT_SUBJECT_TYPE[0]]
          : EVENT_SUBJECT_TYPE_COLOR[EVENT_SUBJECT_TYPE[1]]
        : EVENT_SUBJECT_TYPE_COLOR[EVENT_SUBJECT_TYPE[2]];
    };

    rect.join(
      (enter) =>
        enter
          .append('rect')
          .attr('fill', fill)
          .classed('eventLine', true)
          .attr('transform', transform)
          .attr('height', eventHeight)
          .attr('width', width),
      (update) => update.attr('fill', fill).attr('transform', transform).attr('width', width)
    );

    rect.exit().remove();
  };

  updateRecording = (recordings) => {
    const { x, w, lineHeight } = this;
    const rect = this.recodingGroup.selectAll('rect').data(recordings);
    const width = ({ to, from }) => (to ? Math.max(1, x(to) - x(from)) : Math.max(1, w - 2 - x(from)));
    const transform = ({ from }) => `translate(${x(from)}, 0)`;
    rect.join(
      (enter) =>
        enter
          .append('rect')
          .classed('recodingLine', true)
          .attr('transform', transform)
          .attr('height', lineHeight)
          .attr('width', width),
      (update) => update.attr('transform', transform).attr('width', width)
    );
  };

  updateCircle = (current) => {
    const { x, w, drag, circleRadius } = this;
    const position = !current ? w - 2 : x(current);
    this.dragCircle = this.circleGroup.selectAll('circle').data([{ x: position }]);
    this.dragCircle.join(
      (enter) =>
        enter
          .append('circle')
          .attr('cx', (d) => d.x)
          .attr('cy', circleRadius - 5)
          .attr('r', circleRadius)
          .attr('stroke', '#fff')
          .attr('stroke-width', '2')
          .attr('cursor', 'pointer')
          .style('fill', '#497a81')
          .call(drag),
      (update) => update.transition().attr('cx', (d) => d.x)
    );
  };

  updateLineData = () => {
    if (moment().valueOf() - this.lastTimelineUpdate > MIN_TIMELINE_UPDATE_INTERVAL) {
      const { range, events, alerts, recordings } = this.props;
      const { graphGroupCallback } = this;
      this.updateAxis(range);
      this.updateEvents(events);
      this.updateRecording(recordings);
      this.graphGroup.on('click', function () {
        graphGroupCallback(this);
      });
      this.lastTimelineUpdate = moment().valueOf();
    }
  };

  render() {
    return (
      <Fragment>
        <div className="camera-view__chart-wrapper">
          <button className="camera-view__chart-button">
            <FontAwesomeIcon className="camera-view__chart-button__icon" icon={faChevronCircleLeft} />
          </button>
          <div ref={this.chart} id="chart" className="camera-view__chart" />

          <button className="camera-view__chart-button camera-view__chart-button--disabled">
            <FontAwesomeIcon className="camera-view__chart-button__icon" icon={faChevronCircleRight} />
          </button>
        </div>
        <ul className="camera-view__chart-legend">
          {this.props.faceLegend ? (
            <>
              <li>
                <span className="square" style={{ backgroundColor: EVENT_TYPE_COLOR[EVENT_TYPES[1]] }} />
                <span>{i18n.t('graphLegend:known')}</span>
              </li>
              <li>
                <span className="square" style={{ backgroundColor: EVENT_TYPE_COLOR[EVENT_TYPES[2]] }} />
                <span>{i18n.t('graphLegend:unknown')}</span>
              </li>
            </>
          ) : (
            <li>
              <span className="square" style={{ backgroundColor: EVENT_TYPE_COLOR[EVENT_TYPES[0]] }} />
              <span>{i18n.t('graphLegend:alert')}</span>
            </li>
          )}
          <li>
            <span className="square" style={{ backgroundColor: EVENT_TYPE_COLOR[EVENT_TYPES[4]] }} />
            <span>{i18n.t('graphLegend:record')}</span>
          </li>
          <li>
            <span className="square" />
            <span>{i18n.t('graphLegend:noRecord')}</span>
          </li>
        </ul>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ player }) => {
  const { events, alerts, recordings, controls } = player;
  const { current, range, pause, live } = controls;
  return {
    faceLegend: events.faceLegend,
    events: events.timeline,
    alerts: alerts.list.items,
    recordings: recordings.list.items,
    current: current ? moment(current).toDate() : null,
    range,
    pause,
    live,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrent: (time) => dispatch(actions.setCurrent(time)),
  setLive: (status) => dispatch(actions.setLive(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerTimeline);
