import * as d3 from 'd3';

export const EVENT_TYPE_COLOR = {
  ALERT_DETECTED: '#ffe906',
  FACE_DETECTED: '#1dff00',
  UNKNOWN_FACE_DETECTED: '#ff001d',
  VIDEO_RECORDING_STARTED: '#44484d',
  VIDEO_RECORDING_ENDED: '#44484d',
  MOVEMENT_DETECTED: '#a3a3a3',
};

export const EVENT_TYPES = Object.keys(EVENT_TYPE_COLOR);

export const EVENT_SUBJECT_TYPE_COLOR = {
  KNOWN: '#1dff00',
  UNKNOWN: '#ff001d',
  ALERT: '#ffe906',
  RECORD: '#a3a3a3'
};

export const EVENT_SUBJECT_TYPE = Object.keys(EVENT_SUBJECT_TYPE_COLOR);

export const TIMELINE_RENGE_LIST = {
  1140: {
    label: '24h',
    value: '24hr',
  },
  720: {
    label: '12h',
    value: '12hr',
  },
  60: {
    label: '1h',
    value: '1hr',
  },
  10: {
    label: '10m',
    value: '10m',
  },
  1: {
    label: '1m',
    value: '1m',
  },
};

export const OFFSET_TIME = {
  1140: {
    ticks: [d3.timeHour.every(2), d3.timeHour.every(3), d3.timeHour.every(4), d3.timeHour.every(8)],
    format: d3.timeFormat('%_I:%M %p'),
    from: () => d3.timeHour.offset(new Date(), -24),
    to: () => new Date(),
  },
  720: {
    ticks: [d3.timeHour.every(1), d3.timeHour.every(2), d3.timeHour.every(3), d3.timeHour.every(4)],
    format: d3.timeFormat('%_I:%M %p'),
    from: () => d3.timeHour.offset(new Date(), -12),
    to: () => new Date(),
  },
  60: {
    ticks: [
      d3.timeMinute.every(5),
      d3.timeMinute.every(10),
      d3.timeMinute.every(15),
      d3.timeMinute.every(20),
    ],
    format: d3.timeFormat('%H:%M %p'),
    from: () => d3.timeHour.offset(new Date(), -1),
    to: () => new Date(),
  },
  10: {
    ticks: [d3.timeMinute.every(1), d3.timeMinute.every(1), d3.timeMinute.every(2), d3.timeMinute.every(2)],
    format: d3.timeFormat('%H:%M %p'),
    from: () => d3.timeMinute.offset(new Date(), -10),
    to: () => new Date(),
  },
  1: {
    ticks: [
      d3.timeSecond.every(5),
      d3.timeSecond.every(10),
      d3.timeSecond.every(10),
      d3.timeSecond.every(15),
    ],
    format: d3.timeFormat('%M:%S'),
    from: () => d3.timeMinute.offset(new Date(), -1),
    to: () => new Date(),
  },
};

export const getOffsetTime = str => {
  let r;
  switch (str) {
    case TIMELINE_RENGE_LIST[1140].value:
      r = OFFSET_TIME[1140];
      break;
    case TIMELINE_RENGE_LIST[720].value:
      r = OFFSET_TIME[720];
      break;
    case TIMELINE_RENGE_LIST[60].value:
      r = OFFSET_TIME[60];
      break;
    case TIMELINE_RENGE_LIST[10].value:
      r = OFFSET_TIME[10];
      break;
    case TIMELINE_RENGE_LIST[1].value:
      r = OFFSET_TIME[1];
      break;

    default:
      r = OFFSET_TIME[1140];
  }
  return r;
};

export const getTiksTime = ticks => {
  const size = window.innerWidth;
  let res = ticks[0];
  if (992 < size && size < 1200) {
    res = ticks[1];
  } else if (768 < size && size < 992) {
    res = ticks[2];
  } else if (size < 768) {
    res = ticks[3];
  }
  // tiks arr [lg < 1200px, md < 992 < 1200, sm < 768 < 992, xs < 450 < 768]
  return res;
};
