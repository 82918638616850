import { queryStringify } from '../utils/index';
import request from '../utils/request';

export function getEquipments(params) {
  return request({
    url: `/equipments?${queryStringify(params)}`,
    method: 'get',
  });
}

export function getEquipment(uuid) {
  return request({
    url: `/equipments?${uuid}`,
    method: 'get',
  });
}

export function addEquipment(params) {
  return request({
    url: '/equipments',
    method: 'post',
    data: params,
  });
}

export function updateEquipment(uuid, params) {
  return request({
    url: `/equipments/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteEquipment(uuid) {
  return request({
    url: `/equipments/${uuid}`,
    method: 'delete',
  });
}
