import { takeEvery, call, put, select, all } from 'redux-saga/effects';

import * as ActionTypes from '../constants/training.constants';
import * as api from '../api/training';

export function* getDatasetsMarkup({ payload, actions = {} }) {
  try {
    const response = yield call(api.getDatasets, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_DATASETS_MARKUP_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_DATASETS_MARKUP_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getDatasetMarkup({ payload, actions = {} }) {
  try {
    const response = yield call(api.getDataset, payload.uuid);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_DATASET_MARKUP_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_DATASET_MARKUP_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addDatasetMarkup({ payload, actions = {} }) {
  try {
    const response = yield call(api.createDataset, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_DATASET_MARKUP_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_DATASET_MARKUP_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateDatasetMarkup({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateDataset, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_DATASET_MARKUP_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_DATASET_MARKUP_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteDatasetMarkup({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteDataset, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_DATASET_MARKUP_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_DATASET_MARKUP_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getDatasetMarkupMedias({ payload, actions = {} }) {
  try {
    const response = yield call(api.getDatasetMedias, payload.uuid);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_DATASET_MARKUP_MEDIA_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_DATASET_MARKUP_MEDIA_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addDatasetMarkupMedia({ payload, actions = {} }) {
  try {
    const response = yield call(api.createDatasetMedia, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_DATASET_MARKUP_MEDIA_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_DATASET_MARKUP_MEDIA_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addSample({ payload, actions = {} }) {
  try {
    const response = yield call(api.createSample, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_SAMPLE_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_SAMPLE_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateDatasetMarkupMedia({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateDatasetMedia, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_DATASET_MARKUP_MEDIA_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_DATASET_MARKUP_MEDIA_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteDatasetMarkupMedia({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteDatasetMedia, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_DATASET_MARKUP_MEDIA_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_DATASET_MARKUP_MEDIA_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* trainingSagas() {
  yield all([
    takeEvery(ActionTypes.GET_DATASETS_MARKUP, getDatasetsMarkup),
    takeEvery(ActionTypes.GET_DATASET_MARKUP, getDatasetMarkup),
    takeEvery(ActionTypes.ADD_DATASET_MARKUP, addDatasetMarkup),
    takeEvery(ActionTypes.UPDATE_DATASET_MARKUP, updateDatasetMarkup),
    takeEvery(ActionTypes.DELETE_DATASET_MARKUP, deleteDatasetMarkup),

    takeEvery(ActionTypes.ADD_SAMPLE, addSample),

    takeEvery(ActionTypes.GET_DATASET_MARKUP_MEDIA, getDatasetMarkupMedias),
    takeEvery(ActionTypes.ADD_DATASET_MARKUP_MEDIA, addDatasetMarkupMedia),
    takeEvery(ActionTypes.UPDATE_DATASET_MARKUP_MEDIA, updateDatasetMarkupMedia),
    takeEvery(ActionTypes.DELETE_DATASET_MARKUP_MEDIA, deleteDatasetMarkupMedia),
  ]);
}
