import * as viewAnnotatorExport from './_via_view_annotator';
import * as config from './_via_config';
import * as controlPanelExport from './_via_control_panel';
import * as dataExport from './_via_data';
import * as editorExport from './_via_editor';
import * as importExport from './_via_import_export';
import * as shareExport from './_via_share';
import * as utilExport from './_via_util';
import * as viewManagerExport from './_via_view_manager';

export function _via(via_container_training) {
  this._ID = '_via';

  console.log('Initializing VGG Image Annotator (VIA) version ' + config._VIA_VERSION);
  this.via_container_training = via_container_training;

  this.d = new dataExport._via_data();
  var conf = { ENDPOINT: config._VIA_REMOTE_STORE };
  this.s = new shareExport._via_share(this.d, conf);
  //// define the html containers
  this.control_panel_container = document.createElement('div');
  this.control_panel_container.setAttribute('id', 'via_control_panel_container');
  this.via_container_training.appendChild(this.control_panel_container);

  this.view_container = document.createElement('div');
  this.view_container.setAttribute('id', 'view_container');
  this.via_container_training.appendChild(this.view_container);

  this.editor_container = document.createElement('div');
  this.editor_container.setAttribute('id', 'editor_container');
  this.editor_container.classList.add('hide');
  this.via_container_training.appendChild(this.editor_container);

  this.message_container = document.createElement('div');
  this.message_container.setAttribute('id', '_via_message_container');
  this.message_container.setAttribute('class', 'message_container');
  this.message_container.addEventListener('click', utilExport._via_util_msg_hide);
  this.message_panel = document.createElement('div');
  this.message_panel.setAttribute('id', '_via_message');
  this.message_container.appendChild(this.message_panel);
  this.via_container_training.appendChild(this.message_container);

  //// initialise content creators and managers
  this.ie = new importExport._via_import_export(this.d);

  this.va = new viewAnnotatorExport._via_view_annotator(this.d, this.view_container);
  this.editor = new editorExport._via_editor(this.d, this.va, this.editor_container);

  this.view_manager_container = document.createElement('div');
  this.view_manager_container.setAttribute('id', 'view_manager_container');
  this.vm = new viewManagerExport._via_view_manager(this.d, this.va, this.view_manager_container);
  this.vm._init();

  // control panel shows the view_manager_container
  this.cp = new controlPanelExport._via_control_panel(this.control_panel_container, this);
  this.cp._set_region_shape('RECTANGLE');

  // event handlers for buttons in the control panel
  this.cp.on_event(
    'region_shape',
    this._ID,
    function (data, event_payload) {
      this.va.set_region_draw_shape(event_payload.shape);
    }.bind(this)
  );
  this.cp.on_event(
    'editor_toggle',
    this._ID,
    function (data, event_payload) {
      this.editor.toggle();
    }.bind(this)
  );
  this.cp.on_event(
    'magnifier_toggle',
    this._ID,
    function (data, event_payload) {
      if (this.va.view_mode === viewAnnotatorExport._VIA_VIEW_MODE.IMAGE1) {
        this.va.file_annotator[0][0]._magnifier_toggle();
      }
    }.bind(this)
  );
  this.cp.on_event(
    'zoom_in',
    this._ID,
    function (data, event_payload) {
      if (this.va.view_mode === viewAnnotatorExport._VIA_VIEW_MODE.IMAGE1) {
        this.va.file_annotator[0][0]._zoom_in();
      }
    }.bind(this)
  );
  this.cp.on_event(
    'zoom_out',
    this._ID,
    function (data, event_payload) {
      if (this.va.view_mode === viewAnnotatorExport._VIA_VIEW_MODE.IMAGE1) {
        this.va.file_annotator[0][0]._zoom_out();
      }
    }.bind(this)
  );
  this.cp.on_event(
    'fit_screen',
    this._ID,
    function (data, event_payload) {
      if (this.va.view_mode === viewAnnotatorExport._VIA_VIEW_MODE.IMAGE1) {
        this.va.file_annotator[0][0]._zoom_fit_screen();
      }
    }.bind(this)
  );

  // keyboard event handlers
  //this.via_container_training.focus()
  //this.via_container_training.addEventListener('keydown', this._keydown_handler.bind(this));
  window.addEventListener('keydown', this._keydown_handler.bind(this)); // @todo: should be attached only to VIA application container

  // update VIA version number
  var el = document.getElementById('via_page_container');
  var pages = el.getElementsByClassName('via_page');
  var n = pages.length;
  for (var i = 0; i < n; ++i) {
    if (pages[i].dataset.pageid === 'page_about') {
      var content0 = pages[i].innerHTML;
      pages[i].innerHTML = content0.replace('_config._VIA_VERSION__', config._VIA_VERSION);
    }
  }

  // // ready
  // utilExport._via_util_msg_show(
  //   config._VIA_NAME + ' (' + config._VIA_NAME_SHORT + ') ' + config._VIA_VERSION + ' ready.'
  // );
}

_via.prototype._hook_on_browser_resize = function () {
  if (typeof this.va.vid !== 'undefined') {
    this.va.view_show(this.va.vid);
  }
};

_via.prototype._keydown_handler = function (e) {
  // avoid handling events when text input field is in focus
  if (e.target.type !== 'text' && e.target.type !== 'textarea') {
    this.va._on_event_keydown(e);
  }
};
