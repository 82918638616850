import React, { Component } from 'react';
import { connect } from 'react-redux';
import videojs from 'video.js';
import '@videojs/http-streaming';
import _ from 'lodash';
import moment from 'moment';

import DrawingLayerRecordings from '../../DrawingLayer/DrawingLayerRecordings';

import DataTimeService from '../../../services/DateTimeService';
import * as actions from '../../../actions/player.actions';
import * as historyActions from '../../../actions/historyData.actions';
import * as workflowActions from '../../../actions/workflows.action';
import * as cameraActions from '../../../actions/cameras.actions';

import { DEFAULT_VIDEO_RATIO } from '../../../constants/cameras.constants';
import DateTimeService from '../../../services/DateTimeService';

class PlayerRecordings extends Component {
  constructor(props) {
    super(props);
    this.video = React.createRef();
    this.interval = null;
    this.state = {
      player: null,
      canPlay: false,
      tWidth: 0,
      tHeight: 0,
      errors: '',
      timerId: null,
      rerender: false,
    };
  }

  componentDidMount() {
    this.player = videojs(
      this.video.current,
      {
        autoplay: true,
        controls: false,
        html5: {
          nativeAudioTracks: false,
          nativeVideoTracks: false,
        },
      },
      () => this.handleRecording(true)
    );

    this.interval = setInterval(() => {
      this.props.setMockTime(this.player.currentTime());
    }, 100);

    this.player.on('canplaythrough', () => {
      const { play, pause } = this.props;

      if (!this.state.canPlay) {
        this.setState({ canPlay: true });
      }

      const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
      if (isSafari) {
        if (Math.round(this.player.currentTime()) !== Math.round(play)) {
          console.warn(
            'The start time of the video does not match the calculated: ' + this.player.currentTime(),
            play
          );
          this.player.currentTime(play);
        } else {
          console.log('Video can play through: ' + this.player.currentTime(), play);
        }
      } else {
        console.log('Video can play through: ' + this.player.currentTime(), play);
      }
      document.dispatchEvent(new Event('canPlayThrough'));
    });

    this.player.on('error', () => {});

    this.player.on('play', () => {
      console.log('Video playback started: ' + this.player.currentTime());
      if (this.props.pause) {
        this.props.setPause();
      }
    });

    this.player.on('pause', () => {
      console.log('Video playback paused: ' + this.player.currentTime());
      this.props.setCurrent(this.props.recording?.from + Math.round(this.player.currentTime() * 1000), true);
      if (!this.props.pause) {
        this.props.setPause();
        this.player.pause();
      }
    });

    this.player.on('seeking', () => {
      if (this.player.currentTime() > this.props.play) {
        console.log('Video seeking: ' + this.player.currentTime(), this.props.play);
        this.player.currentTime(this.props.play);
      }
    });

    this.player.on('seeked', () => {
      console.log('Video seek ended: ' + this.player.currentTime(), this.props.play);
    });

    this.player.on('end', () => {
      console.log('Video playback end.');
      this.props.setCurrent(this.props.recording?.from + Math.round(this.player.currentTime() * 1000), true);
    });

    this.player.on('ended', () => {
      console.log('Video playback ended.');
      if (this.player.paused()) {
        this.props.setCurrent(moment(this.props.current).add(100, 'millisecond'), true);
      }
    });

    this.player.on('loadstart', () => {
      console.log('Video load start.' + this.player.currentTime(), this.props.play);
    });

    this.player.on('durationchange', () => {
      console.log('Video duration change: ' + this.player.currentTime());
    });

    this.player.on('loadedmetadata', () => {
      console.log('Video loaded metadata.' + this.player.currentTime(), this.props.play);
    });

    this.player.on('loadeddata', () => {
      console.log('Video loaded data.' + this.player.currentTime(), this.props.play);
      this.props.setCurrent(moment(this.props.current).add(1, 'millisecond'), true);
    });

    this.player.on('canplay', () => {
      console.log('Video can play.' + this.player.currentTime(), this.props.play);
    });

    this.player.on('waiting', () => {
      console.beer('Video waiting.' + this.player.currentTime(), this.props.play);
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      pause,
      play,
      recording,
      current,
      currentTime,
      lastCurrentUpdate,
      copyEventsToCache,
      camera,
      events,
      cameraUuid,
      activeWorkflowUuid,
      activeWorkflowType,
      getZonesForWorkflowCamera,
      setActiveCameraZones,
    } = this.props;

    const { width, height } = this.handleResizeWindow();
    if (prevState.tWidth !== width || prevState.tHeight !== height) {
      this.setState({
        tWidth: width,
        tHeight: height,
      });
    }

    if (prevProps.camera.options.isShowObjects !== camera.options.isShowObjects) {
      this.props.setCurrent(this.props.recording?.from + Math.round(this.player.currentTime() * 1000), true);
      this.player.src({
        src: camera.options.isShowObjects ? recording?.detectionsUrl : recording?.url,
      });
    }

    if (!_.isEqual(events, prevProps.events)) {
      copyEventsToCache(moment(current).valueOf());
    }

    if (prevProps.recording !== recording) {
      const isUrlChanged =
        (prevProps.camera.options.isShowObjects
          ? prevProps.recording?.detectionsUrl
          : prevProps.recording?.url) !==
        (camera.options.isShowObjects ? recording?.detectionsUrl : recording?.url);
      this.handleRecording(isUrlChanged);
    }

    if (
      (prevProps.recording === recording && prevProps.play !== play) ||
      prevProps.current !== current ||
      prevProps.lastCurrentUpdate !== lastCurrentUpdate
    ) {
      if (play) {
        setTimeout(() => {
          this.player.currentTime(play);
          this.handlePlay();
        }, 0);
      }
    }

    if (prevProps.pause !== pause) {
      this.handlePlay();
    }
  };

  getHistoryDataTS = (from, to, actions) => {
    if (!this.props.activeWorkflowUuid || !this.props.activeWorkflowType) return [];

    const newTo = to ? to : moment(from).add(30, 'seconds').valueOf();
    this.props.getHistoryData(
      {
        cameraUuid: this.props.cameraUuid,
        workflowUuid: this.props.activeWorkflowUuid,
        from: from,
        to: newTo,
      },
      actions
    );
  };

  handleResizeWindow = () => {
    let width;
    let height;

    const streamRatio =
      this.video.current.videoWidth && this.video.current.videoHeight
        ? this.video.current.videoWidth / this.video.current.videoHeight
        : DEFAULT_VIDEO_RATIO;
    const containerRatio = this.video.current.offsetWidth / this.video.current.offsetHeight;

    if (streamRatio >= containerRatio) {
      width = this.video.current.offsetWidth;
      height = this.video.current.offsetWidth / streamRatio;
    } else {
      width = this.video.current.offsetHeight * streamRatio;
      height = this.video.current.offsetHeight;
    }

    return { width, height };
  };

  handleRecording = () => {
    const { recording, camera } = this.props;
    // isUrlChanged &&
    this.player.src({
      src: camera.options.isShowObjects ? recording?.detectionsUrl : recording?.url,
      type: 'video/mp4',
      withCredentials: true,
    });

    this.player.currentTime(this.props.play);
    this.handlePlay();
  };

  handlePlay = () => (!this.props.pause ? this.player.play() : this.player.pause());

  componentWillUnmount = () => {
    clearInterval(this.interval);
    // this.player && this.player.dispose();
    this.timerId && clearInterval(this.timerId);
  };

  render() {
    const { tHeight, tWidth } = this.state;
    const { activeWorkflowType, bboxes, requestForHistory, camera, pause } = this.props;

    return (
      <div>
        <div data-vjs-player>
          <video
            ref={this.video}
            className="camera-view__player-video video-js"
            playsInline={true}
            autoPlay={true}
            muted={true}
          >
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
          <DrawingLayerRecordings
            height={tHeight}
            width={tWidth}
            recordPause={pause}
            detectionType={activeWorkflowType}
            tsDetections={bboxes}
            zones={camera.zones}
            showOptions={camera.options}
            cameraFPS={camera.fps ? camera.fps : 5}
            getHistoryDataTS={this.getHistoryDataTS}
            recordingFromTime={this.props.recording?.from}
            getPlayerOffsetTime={() => (this.player ? Math.round(this.player.currentTime() * 1000) : 0)}
            setCurrent={this.props.setCurrent}
          />
          <div
            style={{
              position: 'absolute',
              bottom: '50px',
              right: '20px',
              zIndex: 30,
              color: 'white',
              fontSize: '18px',
              textShadow: '0 0 1px black',
            }}
          >
            {moment(this.props.recording?.from + Math.round(this.player?.currentTime() * 1000)).format(
              'h:mm:ss A, MMMM DD'
            )}
          </div>
          {this.state.errors !== '' && (
            <div className="d-flex h-100 align-items-end h6">
              <h3>{this.state.errors}</h3>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { camera, controls, isLoading } = state.player;
  return {
    camera,
    events: state.player.events.list.items,
    recording: controls.recording,
    bboxes: controls.timestamps.processing,
    requestForHistory: controls.requestForHistory,
    lastCurrentUpdate: controls.lastCurrentUpdate,
    current: controls.current,
    pause: controls.pause,
    play: controls.play,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrent: (time, saveLive) => dispatch(actions.setCurrent(time, saveLive)),
  setPause: () => dispatch(actions.setPause()),
  setLive: (status) => dispatch(actions.setLive(status)),
  copyEventsToCache: (startTS) => dispatch(actions.copyEventsToCache(startTS)),
  getHistoryData: (params, actions) => dispatch(historyActions.getHistoryData(params, actions)),
  getZonesForWorkflowCamera: (workflowUuid, cameraUuid, actions) =>
    dispatch(workflowActions.getZonesForWorkflowCamera(workflowUuid, cameraUuid, actions)),
  setActiveCameraZones: (zones) => dispatch(cameraActions.setActiveCameraZones(zones)),
  createCameraRecordings: (url, duration) => dispatch(cameraActions.createCameraRecordings(url, duration)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerRecordings);
