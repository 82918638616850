import { queryStringify } from '../utils/index';
import request from '../utils/request';

export function getUsers(params) {
  return request({
    url: `/users`,
    method: 'get',
    data: params,
  });
}

export function getUser(uuid) {
  return request({
    url: `/users/${uuid}`,
    method: 'get',
  });
}

export function addUser(params) {
  return request({
    url: '/users',
    method: 'post',
    data: params,
  });
}

export function updateUser(params) {
  return request({
    url: `/users`,
    method: 'post',
    data: params,
  });
}

export function updateMe(params) {
  return request({
    url: `/users/me`,
    method: 'post',
    data: params,
  });
}

export function deleteUser(uuid) {
  return request({
    url: `/users/${uuid}`,
    method: 'delete',
  });
}

export function uploadMedia(params) {
  return request({
    url: `/medias`,
    method: 'post',
    data: params,
  });
}

export function addRolesToUser(uuid, params) {
  return request({
    url: `/users/${uuid}/roles`,
    method: 'post',
    data: params,
  });
}

export function updateRolesToUser(uuid, params) {
  return request({
    url: `/users/${uuid}/roles`,
    method: 'put',
    data: params,
  });
}

export function deleteRoleToUser(uuid, roleUuid) {
  return request({
    url: `/users/${uuid}/roles/${roleUuid}`,
    method: 'delete',
  });
}

export function getMe() {
  return request({
    url: `/users/me`,
    method: 'get',
  });
}

export function changePassword(params) {
  return request({
    url: `/users/change-password`,
    method: 'post',
    data: params,
  });
}

export function updateEmail(params) {
  return request({
    url: `/users/me/email`,
    method: 'post',
    data: params,
  });
}

export function confirmEmail(params) {
  return request({
    url: `/users/me/email/confirm`,
    method: 'post',
    data: params,
  });
}

export function resendEmail() {
  return request({
    url: `/users/me/email/resend`,
    method: 'post',
  });
}
