import React from 'react';
import Filter from '../Filter';
import PropTypes from 'prop-types';
import cx from 'classnames';

const WatchFilter = ({ title, filters, className, onChange }) => {
  return (
    <div className={cx('dashboard__filter', className)}>
      <h3>{title}</h3>
      <div className="dashboard__filter-wrap">
        {filters &&
          filters.map(({ title, options, active }) => (
            <Filter
              key={title}
              title={title}
              activeValue={active}
              activeValue={active}
              options={options}
              onClick={(title, item) => onChange(title, item)}
            />
          ))}
      </div>
    </div>
  );
};

WatchFilter.defaultProps = {
  className: '',
};

WatchFilter.propTypes = {
  title: PropTypes.string.isRequired,
  filters: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default WatchFilter;
