import * as ActionTypes from '../constants/roles.constants';

const initialState = {
  list: {
    items: [],
    total: 0,
  },
  query: {
    size: 10,
    page: 0,
  },
  authorities: [],
  name: '',
  descripttion: '',
  isLoading: false,
};

export const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ROLES:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_ROLES_RESULT:
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items, total },
        query: { size, page },
        isLoading: false,
      };

    case ActionTypes.GET_ROLES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.GET_AUTHORITIES:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_AUTHORITIES_RESULT:
      return {
        ...state,
        authorities: action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_AUTHORITIES_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.ADD_ROLE:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.ADD_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_ROLE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.UPDATE_ROLE:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.UPDATE_ROLE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_ROLE:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_ROLE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.CHANGE_PAGE_VALUE:
      return {
        ...state,
        query: {
          page: action.payload.value,
          size: state.query.size,
        },
      };
    case ActionTypes.CHANGE_ROLE_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
};
