import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Img } from 'react-image';

class PlayerAlert extends Component {
  render() {
    const { screen = {} } = this.props;
    const { thumbUrl, url, subject = {} } = screen;
    return (
      <div className="player-placeholder">
        <Img
          src={url || thumbUrl}
          loader={
            <div className="image-loading">
              <FontAwesomeIcon icon="spinner" size="lg" spin />
            </div>
          }
          alt={subject.name}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ player }) => {
  return { current: player.controls.current };
};

export default connect(mapStateToProps, null)(PlayerAlert);
