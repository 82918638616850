import * as ActionTypes from '../constants/accounts.constants';

const initialState = {
  list: {
    items: [],
    total: 0,
  },
  query: {
    size: 10,
    page: 0,
  },
  modalInf: {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    phone: '',
    avatar: '',
    avatarFile: null,
    rolesItems: [],
    uuid: '',
    image: null,
  },
  isLoading: false,
};

export const accountUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_USERS_ACCOUNT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_USERS_ACCOUNT_RESULT:
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items, total },
        query: { size, page },
        isLoading: false,
      };

    case ActionTypes.GET_USERS_ACCOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.ADD_USER_TO_ACCOUNT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.ADD_USER_TO_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_USER_TO_ACCOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.UPDATE_USER_TO_ACCOUNT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.UPDATE_USER_TO_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.UPDATE_USER_TO_ACCOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_USER_TO_ACCOUNT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.DELETE_USER_TO_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_USER_TO_ACCOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CHANGE_ACCOUNT_USER_PAGE_VALUE:
      return {
        ...state,
        query: {
          page: action.payload,
          size: state.query.size,
        },
      };
    case ActionTypes.CHANGE_ACCOUNT_USER_VALUE:
      return {
        ...state,
        modalInf: {
          ...state.modalInf,
          [action.payload.key]: action.payload.value,
        },
      };
    case ActionTypes.CHANGE_ACCOUNT_MODAL_USER_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
};
