import { useEffect, useState } from 'react';
import * as viaa from './js/_via';
import * as utilExport from './js/_via_util';
import TrainingModal from './Modal/TrainingModal';
import * as trainingActions from '../../actions/training.action';
import * as modelsActions from '../../actions/models.action';
import { modelTypes, baseModel } from '../../constants/training.constants';

import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import DatasetModal from './Modal/DatasetModal';
import { _VIA_RSHAPE } from './js/_via_metadata';
import { FormSelect } from '../../components/Form';

// const tmp = [
//   {
//     uuid: '1_udEsNUFq',
//     time: 1.42,
//     markup: { type: 'RECTANGLE', x: 0.001433804, y: 1.094371841, width: 0.358589431, height: 0.288546931 },
//   },
//   {
//     uuid: '1_udWsPCLq',
//     time: 2.42,
//     markup: { type: 'RECTANGLE', x: 0.928575203, y: 0.913866426, width: 0.358589431, height: 0.288546931 },
//   },
//   {
//     uuid: '1_udEjNOFq',
//     time: 3.42,
//     markup: { type: 'RECTANGLE', x: 0.826949187, y: 0.733361011, width: 0.358589431, height: 0.288546931 },
//   },
// ];

const Markup = ({
  training,
  model,
  changeModalValue,
  changeValue,
  getModels,
  getDatasetMarkup,
  createDatasetMarkup,
  addMarkupAction,
  setVia,
  changeFiles,
  addSampleAction,
}) => {
  const [modal, setModal] = useState(false);
  const [addDatasetModal, setAddDataset] = useState(false);

  useEffect(() => {
    var via_container_training = document.getElementById('via_container_training');
    training.via = new viaa._via(via_container_training);
    getModels();
    setInterval(() => {
      // console.log(document);
      // console.log(training.via.va.file_annotator[0][0].file_html_element.videoWidth);
      // var dr = document.createElement('canvas');
      // dr.width = training.via.va.file_annotator[0][0].cwidth;
      // dr.height = training.via.va.file_annotator[0][0].cheight;
      // var context = dr.getContext('2d', { alpha: false });
      // context.drawImage(
      //   training.via.va.file_annotator[0][0].file_html_element,
      //   0,
      //   0,
      //   training.via.va.file_annotator[0][0].file_html_element.videoWidth,
      //   training.via.va.file_annotator[0][0].file_html_element.videoHeight,
      //   0,
      //   0,
      //   training.via.va.file_annotator[0][0].cwidth,
      //   training.via.va.file_annotator[0][0].cheight
      // );
      // .tempr_canvas.toDataURL());
      // const tmp =
      //   via?.va?.file_annotator.length !== 0 &&
      //   via.va.file_annotator[0][0].creg &&
      //   markupConstructor(
      //     via.va.file_annotator[0][0].creg,
      //     via.va.file_annotator[0][0].cwidth,
      //     via.va.file_annotator[0][0].cheight
      //   );
      // addMarkupAction('test1', {
      //   items: tmp.map((box) => ({
      //     datasetUuid: 'test1',
      //     markupType: box.type,
      //     markup: box.markup,
      //     url: 'test',
      //     uuid: box.uuid,
      //   })),
      // });
      //   via?.va?.file_annotator.length !== 0 &&
      //     markupTransformer(tmp, via.va.file_annotator[0][0].cwidth, via.va.file_annotator[0][0].cheight, via);
    }, 10000);
    window.addEventListener('resize', () => training.via._hook_on_browser_resize());
  }, []);

  useEffect(() => {
    switch (training.action) {
      case 'move_frame':
        // training.frame.context.drawImage(
        //   training.via.va.file_annotator[0][0].file_html_element,
        //   0,
        //   0,
        //   training.via.va.file_annotator[0][0].file_html_element.videoWidth,
        //   training.via.va.file_annotator[0][0].file_html_element.videoHeight,
        //   0,
        //   0,
        //   training.via.va.file_annotator[0][0].cwidth,
        //   training.via.va.file_annotator[0][0].cheight
        // );
        // const tmpmarkup = markupConstructor(
        //   training.markup,
        //   training.via.va.file_annotator[0][0].cwidth,
        //   training.via.va.file_annotator[0][0].cheight
        // );
        // addSampleAction({
        //   base64file: training.frame,
        //   data: tmpmarkup.map((tmp) => ({
        //     markup: tmp.markup,
        //     markupType: tmp.type,
        //     parameters: { uuid: tmp.uuid },
        //   })),
        // });
        changeValue('action', false);
        changeValue('markup', null);
        changeValue('frame', null);
        break;
    }
  }, [training.action]);

  useEffect(() => {
    // training.files && setAddDataset(true);
  }, [training.files]);

  useEffect(() => {}, [training.labelCurrent]);

  const closeAddDataset = () => {
    changeFiles(null);
    setAddDataset(false);
    training.via.vm._on_del_view(training.via.vm);
    changeValue('datasetModal', {
      name: '',
      modelType: null,
      parameters: [],
      datasets: [],
    });
  };

  const addTimeline = (via, data) => {
    data.forEach((bbox) => {
      via.d.store.metadata[bbox.uuid] = {
        av: { 1: 'default' },
        flg: 0,
        vid: '1',
        xy: bbox.markup,
        z: [bbox.time],
      };
      via._tmetadata_boundary_add_spatial_mid(bbox.uuid);
    });
    via._tmetadata_gtimeline_draw();
  };

  const markupTransformer = (data, width, height, via) => {
    const tmp = data.map((bbox) => Object.values(bbox.markup));
    tmp.forEach((bbox, ind) => {
      bbox[0] = _VIA_RSHAPE[bbox[0]];
      if (Array.isArray(bbox[1])) {
        bbox[1].forEach((box, index) => {
          bbox[2 * index + 1] = box * width;
        });
        bbox[2].forEach((box, index) => {
          bbox[2 * index + 2] = box * height;
        });
      } else
        for (let i = 1; i < bbox.length; i++) {
          i % 2 !== 0 ? (bbox[i] *= width) : (bbox[i] *= height);
        }
    });
    tmp.forEach((bbox, index) => {
      via.va.file_annotator[0][0].d.cache.mid_list[1]
        ? via.va.file_annotator[0][0].d.cache.mid_list[1].push(data[index].uuid)
        : (via.va.file_annotator[0][0].d.cache.mid_list = { 1: [data[index].uuid] });
      data[index].markup = bbox;
      // via.va.file_annotator[0][0].creg[uuids[index]] = bbox;
      // via.va.file_annotator[0][0]._creg_draw(uuids[index]);
    });
    addTimeline(via.va.temporal_segmenter, data);
  };

  const markupConstructor = (data, width, height) => {
    let uuids = Object.keys(data);
    return Object.values(data).map((bbox, index) => {
      switch (bbox[0]) {
        case 1:
          return {
            uuid: uuids[index],
            type: Object.keys(_VIA_RSHAPE)[bbox[0] - 1],
            markup: {
              x: bbox[1] / width,
              y: bbox[2] / height,
            },
          };
        case 2:
        case 4:
          return {
            uuid: uuids[index],
            type: Object.keys(_VIA_RSHAPE)[bbox[0] - 1],
            markup: {
              x: bbox[1] / width,
              y: bbox[2] / height,
              width: bbox[3] / width,
              height: bbox[4] / height,
            },
          };
        case 3:
          return {
            uuid: uuids[index],
            type: Object.keys(_VIA_RSHAPE)[bbox[0] - 1],
            markup: {
              x: bbox[1] / width,
              y: bbox[2] / height,
              radius: bbox[3] / width,
            },
          };
        case 5:
          return {
            uuid: uuids[index],
            type: Object.keys(_VIA_RSHAPE)[bbox[0] - 1],
            markup: {
              x: [bbox[1] / width, bbox[3] / width],
              y: [bbox[2] / height, bbox[4] / height],
            },
          };
        case 6:
        case 7:
          return {
            uuid: uuids[index],
            type: Object.keys(_VIA_RSHAPE)[bbox[0] - 1],
            markup: {
              x: bbox.filter((box, index) => index % 2 > 0).map((box) => box / width),
              y: bbox.filter((box, index) => index !== 0 && index % 2 === 0).map((box) => box / height),
            },
          };
      }
    });
  };

  const createDatasetMarkupAction = () => {
    createDatasetMarkup({
      name: training.datasetModal.name,
      labels: training.datasetModal.datasets,
      modelType: training.datasetModal.modelType.label,
      parameters: {},
    });
  };

  return (
    <div className="training_markup_container">
      <svg style={{ display: 'none' }} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <symbol id="shape_rectangle">
            <rect width="18" height="14" x="3" y="5" stroke="white" fill="none"></rect>
          </symbol>
          <symbol id="shape_extreme_rectangle">
            <rect width="18" height="14" x="3" y="5" stroke="black" fill="none"></rect>
            <circle r="2" cx="3" cy="10" stroke="black" fill="grey"></circle>
            <circle r="2" cx="10" cy="19" stroke="black" fill="grey"></circle>
            <circle r="2" cx="15" cy="5" stroke="black" fill="grey"></circle>
            <circle r="2" cx="21" cy="14" stroke="black" fill="grey"></circle>
          </symbol>
          <symbol id="shape_circle">
            <circle r="9" cx="12" cy="12" stroke="white" fill="none"></circle>
          </symbol>
          <symbol id="shape_extreme_circle">
            <circle r="9" cx="12" cy="12" stroke="black" fill="none"></circle>
            <circle r="2" cx="3" cy="10" stroke="black" fill="grey"></circle>
            <circle r="2" cx="19" cy="6" stroke="black" fill="grey"></circle>
            <circle r="2" cx="16" cy="20" stroke="black" fill="grey"></circle>
          </symbol>
          <symbol id="shape_ellipse">
            <ellipse rx="10" ry="8" cx="12" cy="12" stroke="white" fill="none"></ellipse>
          </symbol>
          <symbol id="shape_point">
            <circle r="3" cx="12" cy="12" stroke="white" fill="grey"></circle>
          </symbol>
          <symbol id="shape_polygon">
            <path d="M 4 12 L 10 2 L 20 6 L 18 16 L 8 20 z" stroke="white" fill="none"></path>
          </symbol>
          <symbol id="shape_polyline">
            <line x1="3" y1="4" x2="8" y2="18" stroke="black" fill="none" />
            <line x1="8" y1="18" x2="14" y2="6" stroke="black" />
            <line x1="14" y1="6" x2="20" y2="14" stroke="black" />
            <circle r="2" cx="3" cy="4" stroke="black"></circle>
            <circle r="2" cx="8" cy="18" stroke="black"></circle>
            <circle r="2" cx="14" cy="6" stroke="black"></circle>
            <circle r="2" cx="20" cy="14" stroke="black"></circle>
          </symbol>
          <symbol id="shape_line">
            <line x1="6" y1="6" x2="19" y2="19" stroke="black" />
            <circle r="2" cx="6" cy="6" stroke="black"></circle>
            <circle r="2" cx="19" cy="19" stroke="black"></circle>
          </symbol>

          <symbol id="micon_settings">
            <path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"></path>
          </symbol>
          <symbol id="micon_save">
            <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"></path>
          </symbol>
          <symbol id="micon_open">
            <path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V8h16v10z"></path>
          </symbol>
          <symbol id="micon_upload">
            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z" />
          </symbol>
          <symbol id="micon_download">
            <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z" />
          </symbol>
          <symbol id="micon_delete">
            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
          </symbol>
          <symbol id="micon_copy">
            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path>
          </symbol>
          <symbol id="micon_paste">
            <path d="M19 2h-4.18C14.4.84 13.3 0 12 0c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm7 18H5V4h2v3h10V4h2v16z"></path>
          </symbol>
          <symbol id="micon_insertcomment">
            <path d="M20 2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"></path>
          </symbol>
          <symbol id="micon_edit">
            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
          </symbol>

          {/* <!-- File Manager --> */}
          <symbol id="micon_lib_add">
            <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z" />
          </symbol>
          <symbol id="micon_add_circle">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
          </symbol>
          <symbol id="micon_remove_circle">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z" />
          </symbol>
          <symbol id="micon_navigate_next">
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
          </symbol>
          <symbol id="micon_navigate_prev">
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
          </symbol>
          {/* <!-- Import/Export --> */}
          <symbol id="micon_import">
            <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"></path>
          </symbol>
          <symbol id="micon_export">
            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
          </symbol>
          <symbol id="micon_import_export">
            <path d="M9 3L5 6.99h3V14h2V6.99h3L9 3zm7 14.01V10h-2v7.01h-3L15 21l4-3.99h-3z" />
          </symbol>

          {/* <!-- composed by Abhishek Dutta from existing materian icons, 31 Jan. 2019 --> */}
          <symbol id="micon_add_image">
            <path d="M19 7v2.99s-1.99.01-2 0V7h-3s.01-1.99 0-2h3V2h2v3h3v2h-3z" />
            <path transform="translate(-9,-12) scale(1.8 1.8)" d="M5 19l3-4 2 3 3-4 4 5H5z" />
          </symbol>
          {/* <!-- composed by Abhishek Dutta from existing materian icons, 31 Jan. 2019 --> */}
          <symbol id="micon_add_media">
            <path d="M19 7v2.99s-1.99.01-2 0V7h-3s.01-1.99 0-2h3V2h2v3h3v2h-3z" />
            <path transform="translate(-10,-7) scale(1.6 1.6)" d="M10 16.5l6-4.5-6-4.5v9z" />
          </symbol>
          {/* <!-- composed by Abhishek Dutta from existing materian icons, 13 May. 2019 --> */}
          <symbol id="micon_add_remote">
            <path d="M4.5 11h-2V9H1v6h1.5v-2.5h2V15H6V9H4.5v2zm2.5-.5h1.5V15H10v-4.5h1.5V9H7v1.5zm5.5 0H14V15h1.5v-4.5H17V9h-4.5v1.5zm9-1.5H18v6h1.5v-2h2c.8 0 1.5-.7 1.5-1.5v-1c0-.8-.7-1.5-1.5-1.5zm0 2.5h-2v-1h2v1z" />
          </symbol>

          <symbol id="micon_share">
            <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z" />
          </symbol>
          {/* <!-- Video player controls --> */}
          <symbol id="micon_play">
            <path d="M8 5v14l11-7z" />
          </symbol>
          <symbol id="micon_pause">
            <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
          </symbol>
          <symbol id="micon_mark_start">
            <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
          </symbol>
          <symbol id="micon_mark_end">
            <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z" />
          </symbol>

          {/* <!-- Temporal Segments --> */}
          <symbol id="micon_add">
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
          </symbol>

          <symbol id="micon_import_export">
            <path d="M9 3L5 6.99h3V14h2V6.99h3L9 3zm7 14.01V10h-2v7.01h-3L15 21l4-3.99h-3z" />
          </symbol>

          {/* <!-- Restore --> */}
          <symbol id="micon_restore_load">
            <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm-2 16c-2.05 0-3.81-1.24-4.58-3h1.71c.63.9 1.68 1.5 2.87 1.5 1.93 0 3.5-1.57 3.5-3.5S13.93 9.5 12 9.5c-1.35 0-2.52.78-3.1 1.9l1.6 1.6h-4V9l1.3 1.3C8.69 8.92 10.23 8 12 8c2.76 0 5 2.24 5 5s-2.24 5-5 5z" />
          </symbol>
          <symbol id="micon_restore_save">
            <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />
          </symbol>
          <symbol id="micon_keyboard">
            <path d="M20 5H4c-1.1 0-1.99.9-1.99 2L2 17c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-9 3h2v2h-2V8zm0 3h2v2h-2v-2zM8 8h2v2H8V8zm0 3h2v2H8v-2zm-1 2H5v-2h2v2zm0-3H5V8h2v2zm9 7H8v-2h8v2zm0-4h-2v-2h2v2zm0-3h-2V8h2v2zm3 3h-2v-2h2v2zm0-3h-2V8h2v2z" />
          </symbol>
          <symbol id="micon_education">
            <path d="M3.33 8L10 12l10-6-10-6L0 6h10v2H3.33zM0 8v8l2-2.22V9.2L0 8zm10 12l-5-3-2-1.2v-6l7 4.2 7-4.2v6L10 20z" />
          </symbol>
        </defs>
      </svg>

      {/* <!-- VIA Information Pages --> */}
      <div id="via_page_container">
        <div data-pageid="page_import_export" className="via_page via_page_export">
          <div className="toolbar">
            <span onClick={() => utilExport._via_util_page_hide()} className="text_button">
              &times;
            </span>
          </div>
          <h2>Export</h2>
          <ul>
            <li>
              Select Export Format:
              <select id="via_page_export_format" defaultValue={'via3_csv'}>
                <option value="via3_csv">VIA3 (CSV)</option>
                <option value="temporal_segments_csv">Only Temporal Segments as CSV</option>
              </select>
            </li>
          </ul>
          <h2>Import</h2>
          <ul>
            <li>
              VIA Shared Project:{' '}
              <input
                id="via_page_import_pid"
                type="text"
                placeholder="e.g. 71578187-3cd3-45d0-8198-7c441fbc06af"
                style={{ width: '25em' }}
              />
            </li>
            <li>
              VIA2 project created (json file):
              <input id="via_page_import_via2_project_json" type="file" />
            </li>
          </ul>

          <div className="controls">
            <button
              id="via_page_button_import"
              onClick={(event) => utilExport._via_util_page_process_action(event)}
            >
              Import
            </button>
            <button
              id="via_page_button_export"
              onClick={(event) => utilExport._via_util_page_process_action(event)}
            >
              Export
            </button>
            <button onClick={() => utilExport._via_util_page_hide()}>Cancel</button>
          </div>
        </div>

        <div data-pageid="page_fileuri_bulk_add" className="via_page">
          <div className="toolbar">
            <span onClick={() => utilExport._via_util_page_hide()} className="text_button">
              &times;
            </span>
          </div>
          <p>
            File Type:&nbsp;
            <select id="via_page_fileuri_filetype" defaultValue={'4'}>
              <option value="2">Image</option>
              <option value="4" selected>
                Video
              </option>
              <option value="8">Audio</option>
              <option value="0">Detect Automatically</option>
            </select>
          </p>
          <h2>Paste File URI (one URI per line)</h2>
          <textarea
            id="via_page_fileuri_urilist"
            placeholder="For example, (1) http://www.robots.ox.ac.uk/~vgg/software/via/images/via_logo.png ; (2) file:///data/images/img001.jpg ; (3) file:///C:/Documents%20and%20Settings/tlm/video001.mp4"
            rows="10"
            cols="80"
          ></textarea>
          <h2>Import URI from a File</h2>
          <input id="via_page_fileuri_importfile" type="file" />

          <div className="controls">
            <button
              id="via_page_fileuri_button_bulk_add"
              onClick={(event) => utilExport._via_util_page_process_action(event)}
            >
              Add
            </button>
            <button onClick={() => utilExport._via_util_page_hide()}>Cancel</button>
          </div>
        </div>

        <div data-pageid="page_share_not_shared_yet" className="via_page">
          <div className="toolbar">
            <span onClick={() => utilExport._via_util_page_hide()} className="text_button">
              &times;
            </span>
          </div>
          <h2>Sharing this Project</h2>
          <p>
            This project has not been shared yet. If you want to share this project with others and allow them
            to contribute to this VIA project, click{' '}
            <svg className="svg_icon" viewBox="0 0 24 24">
              <use xlinkHref="#micon_upload"></use>
            </svg>{' '}
            button in the toolbar.
          </p>
          <h2>Loading a Shared Project</h2>
          <p>
            A unique project-id is assigned to every shared VIA project. You must have received such a unique
            project-id when someone asked you to contribute to a VIA project. Click{' '}
            <svg className="svg_icon" viewBox="0 0 24 24">
              <use xlinkHref="#micon_download"></use>
            </svg>{' '}
            button in the toolbar to open a shared project.
          </p>
          <p>
            If you do not have access to a shared project-id and you can explore the following publicly shared
            demonstration projects:
            <ul>
              <li>Video Annotation: e302eadf-aa53-4a5a-b958-11175692c928</li>
              <li>Audio Annotation: d4a9bc87-9652-42c3-a336-f41e18d638e6</li>
            </ul>
          </p>

          <div className="controls">
            <button onClick={() => utilExport._via_util_page_hide()}>Close</button>
          </div>
        </div>

        <div data-pageid="page_share_already_shared" className="via_page">
          <div className="toolbar">
            <span onClick={() => utilExport._via_util_page_hide()} className="text_button">
              &times;
            </span>
          </div>
          <p style={{ color: 'red' }}>
            This feature is not stable yet. If you encounter any issues, please{' '}
            <a href="https://gitlab.com/vgg/via/issues">report</a> it.
          </p>
          <h3>Information about this shared project</h3>
          <p id="via_page_share_project_info"></p>
          <h3>How can others contribute to this project?</h3>
          <p>
            This project has already been shared and therefore anyone can contribute to this project. To
            contribute to this project, other users should to follow these steps:
            <ol>
              <li>Open the VIA application (version 3.0.3 or higher) in a web browser.</li>
              <li>
                Click{' '}
                <svg className="svg_icon" viewBox="0 0 24 24">
                  <use xlinkHref="#micon_download"></use>
                  <title>Share this project and your updates with others</title>
                </svg>{' '}
                button in the top toolbar.
              </li>
              <li>
                Enter the following project-id: <span id="via_page_share_project_id"></span>
              </li>
              <li>
                Make changes to the project and click{' '}
                <svg className="svg_icon" viewBox="0 0 24 24">
                  <use xlinkHref="#micon_upload"></use>
                </svg>{' '}
                to share your updates with others.
              </li>
            </ol>
          </p>
          <h3>Important Notes</h3>
          <ul>
            <li>
              Do not store private or confidential information in a shared VIA project. Furthermore, be
              careful when you share your project-id with others as it allows them to make any changes to your
              project.
            </li>
            <li>
              The VIA servers do not maintain backup copy of the shared VIA projects. In the event of disk
              failure, all data will be lost. So, we strongly advise you to always keep a local copy of your
              project data.
            </li>
            <li>
              We <strong>cannot guarantee</strong> 24/7 availability of VIA project share servers. In the
              event of hardware or disk failure, the VIA project share servers will be offline for an extended
              period of time.
            </li>
            <li>
              This VIA share feature should <strong>not</strong> be used for large scale collaborative
              annotation projects. For such use cases, we advise you to setup a dedicated server with
              sufficient backup and secutiry.
            </li>
            <li>The shared VIA project should not exceed 1MB in size.</li>
          </ul>
          <div className="controls">
            <button onClick={() => utilExport._via_util_page_hide()}>Close</button>
          </div>
        </div>

        <div data-pageid="page_share_open_shared" className="via_page">
          <div className="toolbar">
            <span onClick={() => utilExport._via_util_page_hide()} className="text_button">
              &times;
            </span>
          </div>
          <h2>Open a Shared Project</h2>
          <p>
            A unique project-id is assigned to every shared VIA project. For example, the following two
            project-id have been publicly shared for demonstration purposes:
            <ul>
              <li>Video Annotation: e302eadf-aa53-4a5a-b958-11175692c928</li>
              <li>Audio Annotation: d4a9bc87-9652-42c3-a336-f41e18d638e6</li>
            </ul>
          </p>
          <p>To open a shared project, enter the project-id below:</p>
          <table>
            <tbody>
              <tr>
                <td>
                  <label htmlFor="via_page_input_pid">Project Id</label>
                </td>
                <td>
                  <input
                    style={{ width: '25em' }}
                    type="text"
                    placeholder="e.g. e302eadf-aa53-4a5a-b958-11175692c928"
                    id="via_page_input_pid"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="controls">
            <button
              id="via_page_button_open_shared"
              onClick={(event) => utilExport._via_util_page_process_action(event)}
            >
              Open Shared Project
            </button>
            <button onClick={() => utilExport._via_util_page_hide()}>Cancel</button>
          </div>
        </div>

        <div data-pageid="page_demo_instructions" className="via_page">
          <div className="toolbar">
            <span onClick={() => utilExport._via_util_page_hide()} className="text_button">
              &times;
            </span>
          </div>
          <h1>Some Quick Tips</h1>
          <ul>
            <li>
              Press <span className="key">Space</span> key at any time to play or pause the audio or video.
            </li>
            <li>
              Use <span className="key">a</span> key to add a new temporal segment. Here are more{' '}
              <span
                className="text_button"
                // onClick={() => utilExport._via_util_show_info_page('page_keyboard_shortcut')}
              >
                shortcuts
              </span>
              .
            </li>
            <li>
              Pre-defined temporal segments appear at the bottom (colourful boxes). Using mouse, select them,
              move them and delete them (using <span className="key">Backspace</span> key)
            </li>
            <li>
              Move your mouse over the temporal segment timeline and roll your mouse button to zoom in/out
            </li>
            <li>
              Add new timeline by adding a new entry to the Timeline List (at the bottom) and press "Update"
              button. For example, "Speaker1,Speaker2" to add timelines for two speakers.
            </li>
            <li>
              Use the{' '}
              <span
                className="text_button"
                // onClick={() => utilExport.via.editor.show()}
              >
                attribute editor
              </span>{' '}
              to define or update attributes (e.g. name, colour, speaker_id, etc) of user defined spatial
              regions or temporal segments.
            </li>
          </ul>
        </div>

        <div data-pageid="page_keyboard_shortcut" className="via_page via_page_shortcut">
          <div className="toolbar">
            <span onClick={() => utilExport._via_util_page_hide()} className="text_button">
              &times;
            </span>
          </div>
          <h1 className="h1">Keyboard Shortcuts</h1>
          <h3 className="h3">General</h3>
          <table>
            <thead>
              <tr>
                <th>Command</th>
                <th>Shortcut</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Play/Pause Media</td>
                <td>
                  <span className="key">Space</span>
                </td>
              </tr>
              <tr>
                <td>Toggle Media Mute</td>
                <td>
                  <span className="key">m</span>
                </td>
              </tr>
              <tr>
                <td>Increase / Decrease Media Playback Speed</td>
                <td>
                  <span className="key">+</span> / <span className="key">-</span>
                </td>
              </tr>
              <tr>
                <td>Move Media Time Backward by 1, ..., 9 sec. (Ctrl to move forward)</td>
                <td>
                  <span className="key">Ctrl</span> + <span className="key">1</span>,{' '}
                  <span className="key">2</span>, ..., <span className="key">9</span>
                </td>
              </tr>
              <tr>
                <td>Move Media Time Forward or Backward by 5 frames</td>
                <td>
                  <span className="key">N</span> / <span className="key">P</span>
                </td>
              </tr>{' '}
              <tr>
                <td>Add Temporal Segment at Current Time</td>
                <td>
                  <span className="key">a</span>
                </td>
              </tr>
              <tr>
                <td>Update the edge (left or right) of last added segment to current time</td>
                <td>
                  <span className="key">Shift</span> + <span className="key">a</span>
                </td>
              </tr>
              <tr>
                <td>Select Previous / Next Temporal Segment Timeline</td>
                <td>
                  <span className="key">&uarr;</span> / <span className="key">&darr;</span>
                </td>
              </tr>
              <tr>
                <td>Reorder timeline list by moving current timeline above/below its current position</td>
                <td>
                  <span className="key">Ctrl</span> + <span className="key">&uarr;</span> /{' '}
                  <span className="key">&darr;</span>
                </td>
              </tr>
              <tr>
                <td>Select [Previous] Next Temporal Segment (e.g. 3sec to 5sec)</td>
                <td>
                  <span className="key">Shift</span> + <span className="key">Tab</span>
                </td>
              </tr>
              <tr>
                <td>Select Temporal Segment at Current Time (if any)</td>
                <td>
                  <span className="key">Enter</span>
                </td>
              </tr>
              <tr>
                <td>Move to Previous / Next Video Frame</td>
                <td>
                  <span className="key">l</span> / <span className="key">r</span>
                </td>
              </tr>
              <tr>
                <td>Jump to Start/End of Video</td>
                <td>
                  <span className="key">Shift</span> + <span className="key">s</span> /{' '}
                  <span className="key">e</span>
                </td>
              </tr>
              <tr>
                <td>Move to previous/next video frame</td>
                <td>
                  <span className="key">&larr;</span> / <span className="key">&rarr;</span>
                </td>
              </tr>
              <tr>
                <td>Shift Visible Timeline by 60 sec.</td>
                <td>
                  <span className="key">Shift</span> + <span className="key">&larr;</span> /{' '}
                  <span className="key">&rarr;</span>
                </td>
              </tr>
              <tr>
                <td>Zoom In/Out the Temporal Segment Timeline</td>
                <td>
                  Mouse Wheel
                  <br />
                </td>
              </tr>
              <tr>
                <td>Pan the Temporal Segment Timeline Horizontally</td>
                <td>
                  <span className="key">Shift</span> + Mouse Wheel
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className="h3">When a Temporal Segment is Selected</h3>
          <table>
            <thead>
              <tr>
                <th>Command</th>
                <th>Shortcut</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Play/Pause Video Locked to Segment Boundary</td>
                <td>
                  <span className="key">Spc</span>
                </td>
              </tr>
              <tr>
                <td>Delete Selected Temporal Segment</td>
                <td>
                  <span className="key">Backspace</span>
                </td>
              </tr>
              <tr>
                <td>Select [Previous] Next Temporal Segment</td>
                <td>
                  [<span className="key">Shift</span>] + <span className="key">Tab</span>
                </td>
              </tr>
              <tr>
                <td>Unselect Temporal Segment</td>
                <td>
                  <span className="key">Esc</span>
                </td>
              </tr>
              <tr>
                <td>Increase/Decrease the Extent of Left Edge (Ctrl updates by 1 sec.)</td>
                <td>
                  [<span className="key">Ctrl</span>] + <span className="key">l</span> /{' '}
                  <span className="key">L</span>
                </td>
              </tr>
              <tr>
                <td>Increase/Decrease the Extent of Right edge (Ctrl updates by 1 sec.)</td>
                <td>
                  [<span className="key">Ctrl</span>] + <span className="key">r</span> /{' '}
                  <span className="key">R</span>
                </td>
              </tr>

              <tr>
                <td>Jump to Start/End of Temporal Segment</td>
                <td>
                  <span className="key">s</span> / <span className="key">e</span>
                </td>
              </tr>
              <tr>
                <td>Move Selected Temporal Segment (Ctrl updates by 1 sec.)</td>
                <td>
                  [<span className="key">Ctrl</span>] + <span className="key">&larr;</span> /{' '}
                  <span className="key">&rarr;</span>
                </td>
              </tr>
              <tr>
                <td>Merge Selected Temporal Segment with the Segment on Left/Right</td>
                <td>
                  <span className="key">Shift</span> + <span className="key">&larr;</span> /{' '}
                  <span className="key">&rarr;</span>
                </td>
              </tr>
              <tr>
                <td>Move Selected Temporal Segment to Previous / Next Temporal Segment Timeline</td>
                <td>
                  <span className="key">&uarr;</span> / <span className="key">&darr;</span>
                </td>
              </tr>
            </tbody>
          </table>

          <h3 className="h3"> Spatial Regions in an Image or a Video Frame</h3>
          <table>
            <thead>
              <tr>
                <th>Command</th>
                <th>Shortcut</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Select All Regions</td>
                <td>
                  <span className="key">Ctrl</span> + a
                </td>
              </tr>
              <tr>
                <td>Delete Selected Regions</td>
                <td>
                  <span className="key">Backspace</span> or <span className="key">Delete</span>
                </td>
              </tr>
              <tr>
                <td>Toggle visibility of region shape boundary</td>
                <td>
                  <span className="key">b</span>
                </td>
              </tr>
              <tr>
                <td>Toggle visibility of region label</td>
                <td>
                  <span className="key">l</span>
                </td>
              </tr>
            </tbody>
          </table>

          <p>&nbsp;</p>
        </div>
      </div>

      {/* <!-- used by _via_view_annotator._show_start_info() --> */}
      <div id="via_start_info_content" className="hide">
        <ul>
          <li>
            To start annotation of an image, audio and video, select <strong>local files</strong> or{' '}
            <strong>add files</strong> (local files using file:// and remote files using http:// URI). You can
            also add a list of file URI in <strong>bulk</strong>.
          </li>
          <li>
            Press <span className="key">Space</span> key to play or pause an audio or video at any time (more
            keyboard <strong>shortcuts</strong>
            ).
          </li>
          <li>
            Spatial regions (e.g. 50x80 pixel rectangular bounding box) can be defined for image and video
            frame when video is paused. Temporal segments (e.g. video segment from time 3.1 sec. to 12.5 sec)
            can be defined for audio and video files.
          </li>
          <li>
            Use the <strong>attribute editor</strong> to define or update attributes (e.g. name, colour, etc.)
            of user defined regions.
          </li>
        </ul>
      </div>

      <div className="via_container_training" id="via_container_training">
        <div className="icons--group">
          {/* {training.labels.length !== 0 && (
            <select
              style={{ color: 'white', backgroundColor: '#497a81', border: '1px solid #cfcfcf' }}
              className="view_selector mr-1"
              onChange={(e) => {
                changeValue('labelCurrent', Number(e.target.value));
              }}
            >
              {training.labels.map((lab, index) => (
                <option value={index} selected={Number(training.labelCurrent) === index}>
                  {lab}
                </option>
              ))}
            </select>
          )} */}
          <FontAwesomeIcon
            title="Education(Unavailable)"
            className="icon--item"
            icon={faGraduationCap}
            onClick={() => {
              getDatasetMarkup();
              setModal(true);
            }}
          />
          <FontAwesomeIcon
            title="Dataset"
            className="icon--item"
            icon={faCloudDownloadAlt}
            onClick={() => setAddDataset(true)}
          />
        </div>
      </div>

      {modal && (
        <TrainingModal
          modal={modal}
          info={training.educationModal}
          modelTypes={modelTypes}
          baseModel={baseModel}
          onClose={() => setModal(false)}
          onChange={(name, value) => changeModalValue('educationModal', name, value)}
          onSubmit={() => console.log('heh')}
        />
      )}
      {addDatasetModal && (
        <DatasetModal
          modal={addDatasetModal}
          info={training.datasetModal}
          modelTypes={modelTypes}
          onClose={() => closeAddDataset()}
          onChange={(name, value) => changeModalValue('datasetModal', name, value)}
          onSubmit={() => createDatasetMarkupAction()}
        />
      )}

      <script src="..//_via_util.js"></script>
      <script src="./js/_via_const.js"></script>
      <script src="./js/_via_config.js"></script>

      <script src="./js/_via_event.js"></script>
      <script src="./js/_via_control_panel.js"></script>
      <script src="./js/_via_metadata.js"></script>
      <script src="./js/_via_file.js"></script>
      <script src="./js/_via_attribute.js"></script>
      <script src="./js/_via_view.js"></script>
      <script src="./js/_via_data.js"></script>
      <script src="./js/_via_share.js"></script>
      <script src="./js/_via_import_export.js"></script>

      <script src="./js/_via_video_thumbnail.js"></script>
      <script src="./js/_via_file_annotator.js"></script>
      <script src="./js/_via_temporal_segmenter.js"></script>
      <script src="./js/_via_view_annotator.js"></script>

      <script src="./js/_via_view_manager.js"></script>

      <script src="./js/_via_editor.js"></script>
      <script src="./js/_via_debug_project.js"></script>
      <script src="./js/_via.js"></script>
    </div>
  );
};

const mapStateToProps = ({ training, model }) => {
  return { training, model };
};

const mapDispatchToProps = (dispatch) => ({
  changeModalValue: (type, key, value) => dispatch(trainingActions.changeModalValue(type, key, value)),
  changeValue: (key, value) => dispatch(trainingActions.changeValue(key, value)),
  getDatasetMarkup: (params) => dispatch(trainingActions.getDatasetMarkup(params)),
  changeFiles: (params) => dispatch(trainingActions.changeFiles(params)),
  createDatasetMarkup: (params) => dispatch(trainingActions.createDatasetMarkup(params)),
  getModels: (params) => dispatch(modelsActions.getModels(params)),
  addMarkupAction: (uuid, params) => dispatch(trainingActions.addMarkupAction(uuid, params)),
  setVia: (params) => dispatch(trainingActions.setViaAction(params)),
  addSampleAction: (uuid, params) => dispatch(trainingActions.addSampleAction(uuid, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Markup);
