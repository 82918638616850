import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';

import * as player from '../../actions/player.actions';
import * as actions from '../../actions/cameras.actions';
import * as workflowActions from '../../actions/workflows.action';
import * as streamActions from '../../actions/stream.actions';
import PlayerRecordingNotFound from './PlayerRecordings/PlayerRecordingNotFound';
import { LOADING_LIMIT } from '../../constants/cameras.constants';
import PlayerRecordings from './PlayerRecordings';
import PlayerTimeline from './PlayerTimeline';
import PlayerControls from './PlayerControls';
import PlayerAlert from './PlayerAlert';
import PlayerLive from './PlayerLive';
import i18n from '../../i18n';
import PlayerOptions from './PlayerAlert/PlayerOptions';

class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasWebRTCError: false,
      isLoading: true,
    };
    this.timer = null;
  }

  componentDidMount() {
    const { cameraUUID } = this.props;
  }

  getTimestamps = () => {
    this.props.getCameraEventsGroupedTimestamps();
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidUpdate = (prevProps) => {
    const { recordings, setStateCurrent, setCurrent, current, isLive, location, lastCurrentUpdate, screen } =
      this.props;
    if (prevProps.recordings !== recordings) {
      if (setCurrent) {
        if (!screen) {
          setStateCurrent(setCurrent);
        }
        // reset state after set current time
        this.props.resetCurrentState(location.pathname);
      }
    }

    if (this.props.camera.options.isShowObjects !== prevProps.camera.options.isShowObjects) {
      this.setState({ hasWebRTCError: false, isLoading: true });
    }

    if (this.props.camera.activeWorkflowUuid !== prevProps.camera.activeWorkflowUuid) {
      this.setState({ hasWebRTCError: false, isLoading: true });
      this.props.resetCameraEvents();
      this.props.getCameraEventsGroupedTimestamps();
    }

    if (prevProps.isLive !== isLive) {
      if (isLive) {
        this.setState({ isLoading: true });
      }
    } else {
      setTimeout(() => this.onCanNotConnect(), LOADING_LIMIT);
    }
  };

  onCanNotConnect = () => {
    this.setState({ isLoading: false });
  };

  onWebRTCError = () => {
    this.setState({ hasWebRTCError: true, isLoading: false });
  };

  render() {
    const { hasWebRTCError, isLoading } = this.state;
    const { camera, isLive, recording, screen, setCurrent, recordingsLoading } = this.props;
    return (
      <div className="camera-view__player-container">
        <div className="camera-view__player-analytics">
          <PlayerOptions
            playerWorkflows={this.props.playerWorkflows}
            playerAccumAnalytics={this.props.playerAccumAnalytics}
            playerHeatmap={[this.props.playerHeatmap[0], this.props.playerHeatmap[2], camera.heatmap]}
            objects={this.props.objects}
            activity={this.props.activity}
            accumActivity={this.props.accumActivity}
            lines={this.props.lines}
            zones={this.props.zones}
            queue={this.props.queue}
            started={this.props.started}
            playerAnalytics={this.props.playerAnalytics}
            playerOptions={this.props.playerOptions}
            InOutWidget={this.props.InOutWidget}
            options={this.props.camera.options}
            activeWorkflowUuid={this.props.activeWorkflowUuid}
            chooseWorkflow={(data) => this.props.chooseWorkflow(data)}
            setHeatmapInterval={(min, max) => this.props.setHeatmapInterval(min, max)}
            changeOptionValue={this.props.changeActiveCameraOption}
          />
        </div>
        <div className="camera-view__player">
          {isLoading ? (
            <div className="camera-view__player-loader">
              <FontAwesomeIcon icon="spinner" size="lg" spin />
            </div>
          ) : (
            <div className="camera-view__player-error">
              <FontAwesomeIcon icon="unlink" />
              <span>{i18n.t('cameraModals:previewError')}</span>
            </div>
          )}
          {recording && recording.url && (
            <div className="camera-view__player-video">
              <PlayerRecordings
                setMockTime={(time) => this.props.setMockTime(time)}
                cameraUuid={this.props.cameraUUID}
                activeWorkflowUuid={this.props.activeWorkflowUuid}
                activeWorkflowType={this.props.activeWorkflowType}
              />
            </div>
          )}
          {!isLive && !recording && screen && <PlayerAlert screen={screen} />}
        </div>
        {
          <div className="camera-view__timeline">
            <PlayerControls />
            <PlayerTimeline />
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ player, router }) => {
  const { camera, controls, recordings } = player;
  const { state } = router.location;
  return {
    camera,
    recordingsTS: controls.timestamps.recording,
    lastCurrentUpdate: controls.lastCurrentUpdate,
    setCurrent: state ? state.setCurrent : null,
    recording: controls.recording,
    recordings: recordings.list,
    range: controls.range,
    recordingsLoading: recordings.isLoading,
    location: router.location,
    current: controls.current,
    isLive: controls.live,
    pause: controls.pause,
    screen: controls.screen,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setStateCurrent: (time) => dispatch(player.setCurrent(time)),
  getCameraEvents: (uuid) => dispatch(actions.getCameraEvents(uuid)),
  resetCameraEvents: () => dispatch(actions.resetCameraEvents()),
  getCameraAlerts: (uuid) => dispatch(actions.getCameraAlerts(uuid)),
  getCameraRecordings: (uuid) => dispatch(actions.getCameraRecordings(uuid)),
  getZonesForWorkflowCamera: (workflowUuid, cameraUuid, actions) =>
    dispatch(workflowActions.getZonesForWorkflowCamera(workflowUuid, cameraUuid, actions)),
  changeActiveCameraOption: (key, value) => dispatch(actions.changeActiveCameraOption(key, value)),
  resetCurrentState: (pathname) => dispatch(replace({ pathname, state: {} })),
  getCameraEventsGroupedTimestamps: (params) => dispatch(actions.getCameraEventsGroupedTimestamps(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Player);
