import { queryStringify } from '../utils/index';
import request from '../utils/request';

export function getDatasets(params) {
  return request({
    url: `/datasets`,
    method: 'get',
    data: params,
  });
}

export function getDataset(uuid) {
  return request({
    url: `/datasets/${uuid}`,
    method: 'get',
  });
}

export function createDataset(params) {
  return request({
    url: '/datasets',
    method: 'post',
    data: params,
  });
}

export function updateDataset(params) {
  return request({
    url: `/datasets`,
    method: 'post',
    data: params,
  });
}

export function deleteDataset(uuid) {
  return request({
    url: `/datasets/${uuid}`,
    method: 'delete',
  });
}

export function getDatasetMedias(uuid) {
  return request({
    url: `/datasets/${uuid}/medias`,
    method: 'get',
  });
}

export function createDatasetMedia(uuid, params) {
  return request({
    url: `/datasets/${uuid}/medias`,
    method: 'post',
    data: params,
  });
}

export function updateDatasetMedia(uuid, params) {
  return request({
    url: `/datasets/${uuid}/medias`,
    method: 'put',
    data: params,
  });
}

export function deleteDatasetMedia(uuid, params) {
  return request({
    url: `/datasets/${uuid}/medias`,
    method: 'delete',
    data: params,
  });
}

export function createSample(uuid, params) {
  return request({
    url: `/datasets/${uuid}/samples`,
    method: 'post',
    data: params,
  });
}
