import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DoubleChevron } from '../../../assets/images/doubleChevron.svg';

const PlayerCollapse = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="player-collapse">
      <div className="player-collapse__title" onClick={() => setIsOpen(!isOpen)}>
        <p>{title}</p>
        <DoubleChevron
          className={`player-collapse__title-icon ${isOpen ? 'player-collapse__title-icon--open' : ''}`}
        />
      </div>
      <div className={`player-collapse__collapse ${isOpen ? 'player-collapse__collapse--open' : ''}`}>
        {children}
      </div>
    </div>
  );
};

PlayerCollapse.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string,
};

export default PlayerCollapse;
