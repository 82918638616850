import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableFilters from './TableFilters';
import TablePagination from './TablePagination';
import HeaderCell from './HeaderCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

const Table = ({
  rows,
  title,
  headerOptions,
  isLoading,
  filters,
  filtersElements,
  titleFilters,
  totalRows,
  disabled,
  size,
  changePage,
  page,
  search,
  onChangeParams,
  onChangeFilters,
  onClick,
  buttonComponent,
  maxWidth,
  disableWreapperPadding,
  hideAddButton,
  hidePagination,
  searchSubmit,
  activeRowId,
  setActiveRow,
  editable,
}) => {
  const handleSortTable = (params) => {};

  return (
    <div
      className={cx('table__container', {
        'table__container--disabled': disabled,
        'table__container--no--padding': disableWreapperPadding,
      })}
      style={{ maxWidth: maxWidth ? maxWidth : '100%' }}
    >
      <div className="table__block">
        <div className="table__title-row">
          <p className="table__title page-title">{title}</p>
          {titleFilters}
        </div>
        <div className="table__toolbar">
          {filtersElements}
          <div className="table__filters">
            {buttonComponent ? (
              buttonComponent
            ) : !hideAddButton ? (
              <button className="table__page-button btn btn--secondary" onClick={onClick}>
                <FontAwesomeIcon icon={faPlus} className="btn__icon-start" />
                New
              </button>
            ) : null}
            <TableFilters
              filters={filters}
              search={search}
              onFilterChange={(title, key) => onChangeFilters(title, key)}
              searchSubmit={(value) => searchSubmit(value)}
            />
          </div>
          {!hidePagination && (
            <TablePagination
              isLoading={isLoading}
              totalRows={totalRows}
              page={page ? page : 0}
              perPage={size ? size : 20}
              onChangePage={(data) => changePage(data)}
            />
          )}
        </div>
        <div className="table__wrapper">
          <div className="table__wrapper__inner">
            <table className={`table__table ${isLoading ? 'table--loading' : ''}`}>
              <thead>
                <tr>
                  <th></th>
                  {headerOptions.map((cell, index) => {
                    const { label, align, sortable, onSortClick } = cell;
                    return (
                      <HeaderCell
                        key={label ? label : index}
                        label={label}
                        align={align}
                        sortable={sortable}
                        onSortClick={onSortClick ? onSortClick : handleSortTable}
                      />
                    );
                  })}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {rows.length && !isLoading ? (
                  rows.map((row, index) => {
                    const { id, cells } = row;
                    return (
                      <tr
                        key={id}
                        className={editable && activeRowId === id ? 'active' : ''}
                        onClick={() => (typeof setActiveRow == 'function' ? setActiveRow(id) : {})}
                      >
                        <td></td>
                        {cells.map((cell) => {
                          const { label, align, padding, cellComponent, editableCellComponent } = cell;
                          return editable && activeRowId === id ? (
                            <td
                              key={id + label}
                              id={label}
                              align={align ? align : 'left'}
                              style={{ padding: padding ? padding : '' }}
                            >
                              {editableCellComponent ? editableCellComponent : label || '-'}
                            </td>
                          ) : (
                            <td
                              key={id + label}
                              id={label}
                              align={align ? align : 'left'}
                              style={{ padding: padding ? padding : '' }}
                            >
                              {cellComponent ? cellComponent : label || '-'}
                            </td>
                          );
                        })}
                        <td></td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="20" className="text-muted text-center">
                      {!isLoading ? 'No records' : 'Loading...'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  rows: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  headerOptions: PropTypes.array,
  isLoading: PropTypes.bool,
  filters: PropTypes.array,
  totalRows: PropTypes.number.isRequired,
  onChangeParams: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  disableWreapperPadding: PropTypes.bool,
  hideAddButton: PropTypes.bool,
  buttonComponent: PropTypes.element,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Table;
