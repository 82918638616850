import { takeEvery, call, put, all, select, takeLatest, delay } from 'redux-saga/effects';
import * as ActionTypes from '../constants/subjects.constants';
import * as api from '../api/subjects';

export function* getSubjects({ payload }) {
  const subjects = yield select((s) => s.subjects);
  try {
    const response = yield call(api.getSubjects, { ...subjects.query, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_SUBJECTS_RESULT, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SUBJECTS_ERROR });
  }
}

export function* getSubject({ payload }) {
  try {
    const response = yield call(api.getSubject, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_SUBJECT_SUCCESS, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SUBJECT_ERROR });
  }
}

export function* getSubjectEvents({ payload }) {
  let { uuid, params } = payload;
  const events = yield select((s) => s.subjectView.events);
  try {
    const response = yield call(api.getSubjectEvents, uuid, { ...events.query, ...params });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_SUBJECT_EVENTS_RESULT, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SUBJECT_EVENTS_ERROR });
  }
}

export function* getSubjectEventsNextPage({ payload }) {
  const events = yield select((s) => s.subjectView.events);
  const next = events.query.page + 1;
  try {
    const response = yield call(api.getSubjectEvents, payload.uuid, { ...events.query, page: next });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_SUBJECT_EVENTS_NEXT_PAGE_RESULT, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SUBJECT_EVENTS_NEXT_PAGE_ERROR });
  }
}

export function* addSubject({ payload, actions = {} }) {
  try {
    const response = yield call(api.addSubject, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_SUBJECT_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_SUBJECT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateSubject({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateSubject, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_SUBJECT_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_SUBJECT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteSubject({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteSubject, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_SUBJECT_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_SUBJECT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* onDebounceSearchWatcher() {
  yield delay(500);
  yield put({ type: ActionTypes.GET_SUBJECTS });
}

export function* getPersons({ payload, actions = {} }) {
  const persons = yield select((s) => s.persons);
  try {
    const response = yield call(api.getPersons, { ...persons.query, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_PERSONS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PERSONS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getPerson({ payload, actions = {} }) {
  try {
    const response = yield call(api.getPerson, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_PERSON_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PERSON_ERROR });
  }
}

export function* getPersonEvents({ payload, actions }) {
  const { uuid, params } = payload;
  const events = yield select((s) => s.personView.events);
  try {
    const response = yield call(api.getSubjectEvents, uuid, { ...events.query, ...params });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_PERSON_EVENTS_RESULT, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PERSON_EVENTS_ERROR });
  }
}

export function* addPerson({ payload, actions = {} }) {
  try {
    const response = yield call(api.addPerson, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_PERSON_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_PERSON_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updatePerson({ payload, actions = {} }) {
  try {
    const response = yield call(api.updatePerson, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_PERSON_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_PERSON_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deletePerson({ payload, actions = {} }) {
  try {
    const response = yield call(api.deletePerson, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_PERSON_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_PERSON_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* onDebouncePersonsSearchWatcher() {
  yield delay(500);
  yield put({ type: ActionTypes.GET_PERSONS });
}

export function* getCars({ payload, actions = {} }) {
  const cars = yield select((s) => s.cars);
  try {
    const response = yield call(api.getCars, { ...cars.query, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_CARS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CARS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getCar({ payload, actions = {} }) {
  try {
    const response = yield call(api.getCar, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_CAR_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CAR_ERROR });
  }
}

export function* addCar({ payload, actions = {} }) {
  try {
    const response = yield call(api.addCar, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_CAR_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_CAR_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateCar({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateCar, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_CAR_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_CAR_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteCar({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteCar, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_CAR_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_CAR_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* subjectSagas() {
  yield all([
    takeEvery(ActionTypes.GET_SUBJECTS, getSubjects),
    takeEvery(ActionTypes.SET_NUMBER_PAGE_SUBJECTS, getSubjects),
    takeEvery(ActionTypes.GET_SUBJECT, getSubject),
    takeEvery(ActionTypes.GET_SUBJECT_EVENTS, getSubjectEvents),
    takeEvery(ActionTypes.GET_SUBJECT_EVENTS_NEXT_PAGE, getSubjectEventsNextPage),
    takeEvery(ActionTypes.ADD_SUBJECT, addSubject),
    takeEvery(ActionTypes.UPDATE_SUBJECT, updateSubject),
    takeEvery(ActionTypes.DELETE_SUBJECT, deleteSubject),
    takeLatest(ActionTypes.SET_TEXT_SEARCH_SUBJECTS, onDebounceSearchWatcher),
    takeLatest(ActionTypes.GET_PERSONS, getPersons),
    takeLatest(ActionTypes.GET_PERSON, getPerson),
    takeLatest(ActionTypes.GET_PERSON_EVENTS, getPersonEvents),
    takeLatest(ActionTypes.ADD_PERSON, addPerson),
    takeLatest(ActionTypes.UPDATE_PERSON, updatePerson),
    takeLatest(ActionTypes.DELETE_PERSON, deletePerson),
    takeLatest(ActionTypes.SET_TEXT_SEARCH_PERSONS, onDebouncePersonsSearchWatcher),
    takeLatest(ActionTypes.GET_CARS, getCars),
    takeLatest(ActionTypes.GET_CAR, getCar),
    takeLatest(ActionTypes.ADD_CAR, addCar),
    takeLatest(ActionTypes.UPDATE_CAR, updateCar),
    takeLatest(ActionTypes.DELETE_CAR, deleteCar),
  ]);
}
