import * as ActionTypes from '../constants/equipmentType.constants';

const initialState = {
  list: {
    items: [],
    total: 0,
  },
  query: {
    size: 10,
    page: 0,
  },
  isLoading: false,
};

export const equipmentTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_EQUIPMENT_TYPES:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_EQUIPMENT_TYPES_RESULT:
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items, total },
        query: { size, page },
        isLoading: false,
      };

    case ActionTypes.GET_EQUIPMENT_TYPES_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_EQUIPMENT_TYPE:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.ADD_EQUIPMENT_TYPE_SUCCESS:
      return {
        ...state,
      };

    case ActionTypes.ADD_EQUIPMENT_TYPE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.UPDATE_EQUIPMENT_TYPE:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.UPDATE_EQUIPMENT_TYPE_SUCCESS:
      return {
        ...state,
      };

    case ActionTypes.UPDATE_EQUIPMENT_TYPE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_EQUIPMENT_TYPE:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.DELETE_EQUIPMENT_TYPE_SUCCESS:
      return {
        ...state,
      };

    case ActionTypes.DELETE_EQUIPMENT_TYPE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
