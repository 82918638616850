import i18n from '../../../../i18n';

import BaseModal from './BaseModal';

class ModalEdit extends BaseModal {}

ModalEdit.defaultProps = {
  ...BaseModal.defaultProps,
  header: i18n.t('subjectModals:editTitle'),
};

export default ModalEdit;
