import * as ActionTypes from '../constants/subjects.constants';

export const resetSubjectState = () => ({ type: ActionTypes.RESET_SUBJECT_STATE });
export const resetSubjectEventsState = () => ({ type: ActionTypes.RESET_SUBJECT_EVENTS_STATE });

export const getSubjects = (params) => ({
  type: ActionTypes.GET_SUBJECTS,
  payload: params,
});

export const getSubject = (uuid, actions) => ({
  type: ActionTypes.GET_SUBJECT,
  payload: uuid,
  actions,
});

export const getSubjectEvents = (uuid, params) => ({
  type: ActionTypes.GET_SUBJECT_EVENTS,
  payload: { uuid, params },
});

export const getSubjectEventsNextPage = (uuid) => ({
  type: ActionTypes.GET_SUBJECT_EVENTS_NEXT_PAGE,
  payload: { uuid },
});

export const addSubject = (params, actions) => ({
  type: ActionTypes.ADD_SUBJECT,
  payload: params,
  actions,
});

export const updateSubject = (params, actions) => ({
  type: ActionTypes.UPDATE_SUBJECT,
  payload: params,
  actions,
});

export const deleteSubject = (uuid, actions) => ({
  type: ActionTypes.DELETE_SUBJECT,
  payload: uuid,
  actions,
});

export const setNumberPageSubjects = (number) => ({
  type: ActionTypes.SET_NUMBER_PAGE_SUBJECTS,
  payload: number,
});

export const setSearchSubjects = (text) => ({
  type: ActionTypes.SET_TEXT_SEARCH_SUBJECTS,
  payload: text,
});

export const getPersons = (params, actions) => ({
  type: ActionTypes.GET_PERSONS,
  payload: params,
  actions,
});

export const getPerson = (uuid, actions) => ({
  type: ActionTypes.GET_PERSON,
  payload: uuid,
  actions,
});

export const addPerson = (params, actions) => ({
  type: ActionTypes.ADD_PERSON,
  payload: params,
  actions,
});

export const updatePerson = (uuid, params, actions) => ({
  type: ActionTypes.UPDATE_PERSON,
  payload: { uuid, params },
  actions,
});

export const deletePerson = (uuid, actions) => ({
  type: ActionTypes.DELETE_PERSON,
  payload: uuid,
  actions,
});

export const getPersonEvents = (uuid, actions) => ({
  type: ActionTypes.GET_PERSON_EVENTS,
  payload: uuid,
  actions,
});

export const setNumberPagePersons = (number) => ({
  type: ActionTypes.SET_NUMBER_PAGE_PERSONS,
  payload: number,
});

export const setSearchPersons = (text) => ({
  type: ActionTypes.SET_TEXT_SEARCH_PERSONS,
  payload: text,
});

export const getCars = (params, actions) => ({
  type: ActionTypes.GET_CARS,
  payload: params,
  actions,
});

export const getCar = (uuid, actions) => ({
  type: ActionTypes.GET_CAR,
  payload: uuid,
  actions,
});

export const addCar = (params, actions) => ({
  type: ActionTypes.ADD_CAR,
  payload: params,
  actions,
});

export const updateCar = (uuid, params, actions) => ({
  type: ActionTypes.UPDATE_CAR,
  payload: { uuid, params },
  actions,
});

export const deleteCar = (uuid, actions) => ({
  type: ActionTypes.DELETE_CAR,
  payload: uuid,
  actions,
});
