import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n';

import Table from '../../components/Table';
import Avatar from '../../components/Avatar';
import DropdownDotMenu from '../../components/DropdownDotMenu';
import ModalDelete from '../../components/ModalDelete';
import AlertRouteModal from './Modals/AlertRouteModal';

import * as alertActions from '../../actions/alerts.action';

import { destinations } from '../../constants/alerts.constants';

const AlertRoutesList = ({
  list,
  size,
  page,
  total,
  isLoading,
  getAlertRoutes,
  getAlertRoute,
  addAlertRouteAction,
  updateAlertRouteAction,
  deleteAlertRouteAction,
}) => {
  const [modalInfo, setModalInfo] = useState({ id: 0, type: 'create', isOpen: false, errorMsg: '' });

  useEffect(() => {
    getAlertRoutes();
  }, []);

  const handleOpenModal = () => {
    setModalInfo({
      type: 'create',
      isOpen: true,
    });
  };

  const handleCloseModal = () => {
    setModalInfo({ id: 0, type: 'create', isOpen: false, errorMsg: '' });
  };

  const handleSubmitModal = (params) => {
    switch (modalInfo.type) {
      case 'create':
        addAlertRoute(params);
        break;
      case 'edit':
        updateAlertRoute(params);
        break;
      case 'delete':
        deleteAlertRoute(modalInfo.id);
        break;
      default:
        break;
    }
  };

  const addAlertRoute = (params) => {
    addAlertRouteAction(params, getModalRequestActions());
  };

  const updateAlertRoute = (params) => {
    updateAlertRouteAction(params, getModalRequestActions());
  };

  const deleteAlertRoute = (uuid) => {
    deleteAlertRouteAction(uuid, getModalRequestActions());
  };

  const getModalRequestActions = () => {
    return {
      onSuccess: () => {
        handleCloseModal();
        getAlertRoutes();
      },
      onError: (error) => {
        setModalInfo({ errorMsg: error.message });
      },
    };
  };

  const createRows = (rows) => {
    return rows.map((item) => ({
      id: item.uuid,
      cells: [
        {
          label: item.name,
          cellComponent: <h5 className="table__cell table__cell--bold">{item.name}</h5>,
        },
        {
          label: item.users.map((user) => `${user.firstName} ${user.lastName}`).join(', '),
          cellComponent: (
            <div className="table__cell__long-list">
              {item.users.map((user) => (
                <Avatar
                  className="alerts__table-avatar"
                  key={user.uuid}
                  avatarUrl={user.avatar.url ? user.avatar.url : ''}
                  name={`${user.firstName} ${user.lastName}`}
                  width={24}
                  fontSize={10}
                />
              ))}
            </div>
          ),
        },
        {
          label: item.alertDestinations.map((destination) => destinations[destination]).join(', '),
        },
        {
          label: item.alertTypes.join(', '),
          cellComponent: <div className="table__cell__long-list">{item.alertTypes.join(', ')}</div>,
        },
        {
          label: item.workflows.map((workflow) => workflow.name).join(', '),
          cellComponent: (
            <div className="table__cell__long-list">
              {item.workflows.map((workflow) => workflow.name).join(', ')}
            </div>
          ),
        },
        {
          label: item.cameras.map((camera) => camera.name).join(', '),
          cellComponent: (
            <div className="table__cell__long-list">
              {item.cameras.map((camera) => camera.name).join(', ')}
            </div>
          ),
        },
        {
          label: item.locations.map((location) => location.name).join(', '),
          cellComponent: (
            <div className="table__cell__long-list">
              {item.locations.map((location) => location.name).join(', ')}
            </div>
          ),
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                {
                  name: 'Edit',
                  onClick: () => {
                    setModalInfo({
                      id: item.uuid,
                      alertRoute: item,
                      type: 'edit',
                      isOpen: true,
                      errorMsg: '',
                    });
                  },
                },
                {
                  name: 'Delete',
                  onClick: () => {
                    setModalInfo({
                      id: item.uuid,
                      alertRoute: item,
                      type: 'delete',
                      isOpen: true,
                      errorMsg: '',
                    });
                  },
                },
              ]}
            />
          ),
        },
      ],
    }));
  };

  const headerOptions = [
    {
      label: 'Name',
    },
    {
      label: 'Recipients',
    },
    {
      label: 'Destinations',
    },
    {
      label: 'Event Types',
    },
    {
      label: 'Workflows',
    },
    {
      label: 'Cameras',
    },
    {
      label: 'Locations',
    },
    {
      label: '',
    },
  ];

  return (
    <>
      <Table
        rows={createRows(list)}
        headerOptions={headerOptions}
        title={i18n.t('nav:alertRoutes')}
        onClick={() => handleOpenModal('create')}
        totalRows={total}
        size={size}
        page={page}
        changePage={(data) => getAlertRoutes({ page: data })}
        isLoading={isLoading}
        disabled={modalInfo.isOpen}
      />
      {modalInfo.isOpen && modalInfo.type !== 'delete' && (
        <AlertRouteModal
          title={
            modalInfo.type === 'create'
              ? i18n.t('alertRoutesModals:addTitle')
              : i18n.t('alertRoutesModals:editTitle')
          }
          isOpen={modalInfo.isOpen}
          alertRoute={modalInfo.alertRoute}
          type={modalInfo.type}
          onClose={handleCloseModal}
          onSubmit={handleSubmitModal}
          className="modal-users"
          errorMsg={modalInfo.errorMsg}
          isLoading={false}
        />
      )}
      {modalInfo.isOpen && modalInfo.type === 'delete' && (
        <ModalDelete
          loading={false}
          isOpen={modalInfo.isOpen}
          errorMsg={modalInfo.errorMsg}
          header={i18n.t('alertRoutesModals:deleteTitle')}
          body={i18n.t('alertRoutesModals:deleteBody', { alertRouteName: modalInfo.alertRoute.name })}
          onClose={handleCloseModal}
          onDelete={handleSubmitModal}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ alertRoutes }) => {
  const {
    list: { items, total },
    query: { size, page },
    isLoading,
  } = alertRoutes;
  return { list: items, size, page, total, isLoading };
};

const mapDispatchToProps = (dispatch) => ({
  getAlertRoutes: (params, actions) => dispatch(alertActions.getAlertRoutes(params, actions)),
  getAlertRoute: (uuid, actions) => dispatch(alertActions.getAlertRoute(uuid, actions)),
  addAlertRouteAction: (params, actions) => dispatch(alertActions.addAlertRoute(params, actions)),
  updateAlertRouteAction: (params, actions) => dispatch(alertActions.updateAlertRoute(params, actions)),
  deleteAlertRouteAction: (uuid, actions) => dispatch(alertActions.deleteAlertRoute(uuid, actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertRoutesList);
