import * as ActionTypes from '../constants/player.constants';
import moment from 'moment';

export const setLive = (status) => ({ type: ActionTypes.SET_LIVE_PLAYER, payload: status });
export const setPause = () => ({ type: ActionTypes.SET_PAUSE_PLAYER });
export const setSound = () => ({ type: ActionTypes.SET_SOUND_PLAYER });
export const setRange = (range) => ({ type: ActionTypes.SET_RANGE_PLAYER, payload: range });
export const setSpeedUp = () => ({ type: ActionTypes.SET_SPEED_UP_PLAYER });
export const setSpeedDown = () => ({ type: ActionTypes.SET_SPEED_DOWN_PLAYER });
export const setStepForward = () => ({ type: ActionTypes.SET_STEP_FORWARD });
export const setStepBackward = () => ({ type: ActionTypes.SET_STEP_BACKWARD });
export const setStepStart = () => ({ type: ActionTypes.SET_STEP_START });
export const setStepEnd = () => ({ type: ActionTypes.SET_STEP_END });
export const setCurrent = (time, saveLive) => {
  let format = moment(time).format(moment.HTML5_FMT.DATETIME_LOCAL_MS);
  let timestamp = moment(time).format('x');
  console.warn(`🍕 You set ${format} Timestamp: ${timestamp} ms.`);
  return {
    type: ActionTypes.SET_CURRENT_PLAYER,
    payload: { time: moment(time).format(moment.HTML5_FMT.DATETIME_LOCAL_MS), saveLive },
  };
};
export const setEventsCache = (eventsCache) => ({ type: ActionTypes.SET_EVENTS_CACHE, eventsCache });
export const copyEventsToCache = (payload) => ({ type: ActionTypes.COPY_EVENTS_TO_CACHE, payload });
export const setRecordingScreen = (alert) => ({ type: ActionTypes.SET_RECORDING_SCREEN, payload: alert });
export const setTimestampsValue = (key, value) => ({
  type: ActionTypes.SET_TIMESTAMPS_VALUE,
  payload: { key, value },
});
