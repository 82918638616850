import request from '../utils/request';

export function signIn(params) {
  return request({
    url: '/users/sign-in',
    method: 'post',
    data: params,
    withCredentials: true,
  });
}
