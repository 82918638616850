import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';

const NotFound = ({ notFoundText, isSearch, searchText }) => {
  let text = i18n.t('notFound');
  if (notFoundText && !isSearch) {
    text = notFoundText;
  } else if (isSearch) {
    text = searchText;
  }

  return <div className="not-found text-center">{text}</div>;
};

NotFound.propTypes = {
  notFoundText: PropTypes.string,
  searchText: PropTypes.string,
  isSearch: PropTypes.string,
};

export default NotFound;
