import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class PlayerRecordingNotFound extends PureComponent {
  render() {
    return <div className="player-placeholder">Recording Not Found</div>;
  }
}

const mapStateToProps = ({ player }) => {
  return { current: player.controls.current };
};

export default connect(mapStateToProps, null)(PlayerRecordingNotFound);
