import React, { useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import cx from 'classnames';

const ReportFilter = ({ title, options, selected, className, onClick }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  return (
    <Dropdown
      className={cx('filter-item', className ? className : '', { 'filter-item--active': isSelectOpen })}
      isOpen={isSelectOpen}
      toggle={() => setIsSelectOpen(!isSelectOpen)}
    >
      <DropdownToggle className="w-100">
        <p
          data-toggle="tooltip"
          data-placement="top"
          title={selected && selected.value !== 'select' ? selected.label : title}
          className="filter-item__title"
        >
          {selected && selected.value !== 'select' ? selected.label : title}
        </p>
        <FontAwesomeIcon
          icon={isSelectOpen ? faChevronUp : faChevronDown}
          className="filter-item__chevron-down"
        />
      </DropdownToggle>
      <DropdownMenu container="body">
        {options?.map((item, index) => (
          <DropdownItem
            key={item.value ? item.value : index}
            onClick={() => onClick({ label: item.label, value: item.value })}
          >
            {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ReportFilter;
