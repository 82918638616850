import _ from 'lodash';
import axios from 'axios';
import pathToRegexp from 'path-to-regexp';

import * as actions from '../actions/app.actions';
import { store } from '../index';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem('token');
    config.headers.authorization = token;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const fetch = options => {
  let { method = 'get', data, url, headers, withCredentials = false } = options;
  headers = headers || {};

  const cloneData = data instanceof FormData ? data : _.cloneDeep(data);

  try {
    let domin = '';
    if (url.match(/[a-zA-z]+:\/\/[^/]*/)) {
      [domin] = url.match(/[a-zA-z]+:\/\/[^/]*/);
      url = url.slice(domin.length);
    }
    const match = pathToRegexp.parse(url);
    url = pathToRegexp.compile(url)(data);
    for (let item of match) {
      if (item instanceof Object && item.name in cloneData) {
        delete cloneData[item.name];
      }
    }
    url = domin + url;
  } catch (e) {
    console.error(e.message);
  }

  switch (method.toLowerCase()) {
    case 'get':
      return axios.get(url, {
        params: cloneData,
        headers: headers,
      });
    case 'delete':
      return axios.delete(url, {
        data: cloneData,
      });
    case 'post': {
      if (withCredentials) {
        return axios.post(url, cloneData, { headers, withCredentials });
      } else {
        return axios.post(url, cloneData, { headers });
      }
    }
    case 'put':
      return axios.put(url, cloneData);
    case 'patch':
      return axios.patch(url, cloneData);
    default:
      return axios(options);
  }
};

export default function request(options) {
  return fetch(options)
    .then(response => {
      const { statusText, status, data } = response;
      return Promise.resolve({
        success: true,
        message: statusText,
        code: status,
        results: data,
      });
    })
    .catch(error => {
      const { response } = error;
      let code;
      let message;
      if (response && response instanceof Object) {
        const { data, statusText, status } = response;
        code = status;
        message = data.messages || statusText;
      } else {
        code = 600;
        message = error.messages || 'Network Error';
      }

      if (code === 401) {
        console.log('401 Unauthorized');
        store.dispatch(actions.signOut());
        return Promise.resolve({ success: false, code });
      }

      return Promise.reject({ success: false, code, message: message });
    });
}
