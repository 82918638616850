import React from 'react';
import i18n from '../../i18n';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ModalDelete = ({ isOpen, header, body, errorMsg, onClose }) => {
  return (
    <Modal isOpen={isOpen} size="sm">
      <ModalHeader toggle={onClose}>{header}</ModalHeader>
      <ModalBody>
        <p>{body}</p>
        {errorMsg && (
          <>
            <br />
            <p className="text-text-error">{errorMsg}</p>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn--secondary" onClick={onClose}>
          {i18n.t('buttons:ok')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

ModalDelete.defaultProps = {
  loading: false,
};

export default ModalDelete;
