import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../components/Table';
import { connect } from 'react-redux';
import i18n from '../../i18n';

import DropdownDotMenu from '../../components/DropdownDotMenu';
import * as rolesActions from '../../actions/roles.actions';
import * as usersActions from '../../actions/users.actions';
import * as accountsActions from '../../actions/accountUsers.action';
import * as accActions from '../../actions/accounts.action';

// mocks
import ModalAdd from './Modals/ModalAdd';
import ModalEdit from './Modals/ModalEdit';
import Avatar from '../../components/Avatar';
import { Link } from 'react-router-dom';
import { USERS } from '../../constants/routes.constants';
import InformationModal from '../../components/InformationModal/InformationModal';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormSelect } from '../../components/Form';

const defaultModal = {
  isOpen: false,
  errorMsg: '',
  user: {},
  resetError: function () {
    this.errorMsg = '';
  },
};

class AccountUsers extends Component {
  state = {
    modalAdd: false,
    modalEdit: false,
    modalDelete: { ...defaultModal },
    modalError: false,
    modalChoise: false,
    uuid: null,
  };

  componentDidMount() {
    this.props.match.params.uuid
      ? this.props.getUsers(this.props.match.params.uuid)
      : this.props.getAccounts(
          {},
          {
            onSuccess: () => {
              this.setState({ modalChoise: true });
            },
          }
        );
    this.props.getRoles();
  }

  componentDidUpdate(prev) {
    prev.page !== this.props.page && this.props.getUsers();
  }
  componentWillUnmount() {
    this.props.changeModalValue('list', { items: [], total: 0 });
  }

  addUser = () => {
    const userInfo = this.props.modalInf;
    const roless = this.props.roles.list.items.filter((role) => userInfo.rolesItems.includes(role.uuid));

    const media = new FormData();
    media.append('file', userInfo.avatarFile);
    media.append('mediaTarget', 'AVATAR');
    if (
      userInfo.firstName !== '' &&
      userInfo.lastName !== '' &&
      userInfo.userName !== '' &&
      userInfo.avatar !== '' &&
      userInfo.email !== '' &&
      userInfo.phone !== '' &&
      userInfo.password !== '' &&
      userInfo.rolesItems.length !== 0
    ) {
      this.props.uploadMedia(media, {
        onSuccess: (image) =>
          this.props.addUser(
            {
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
              username: userInfo.userName,
              email: userInfo.email,
              phone: userInfo.phone,
              password: userInfo.password,
              roles: roless,
              avatar: image,
            },
            {
              onSuccess: (data) =>
                this.props.addRolesToUser(data.uuid, userInfo.rolesItems, {
                  onSuccess: () => {
                    this.props.getUsers();
                  },
                }),
            }
          ),
      });
      this.onClose();
    }
  };

  updateUser = () => {
    const userInfo = this.props.modalInf;
    const roless = this.props.roles.list.items.filter((role) => userInfo.rolesItems.includes(role.uuid));

    if (userInfo.avatarFile !== undefined) {
      const media = new FormData();
      media.append('file', userInfo.avatarFile);
      media.append('mediaTarget', 'AVATAR');
      this.props.uploadMedia(media, {
        onSuccess: (image) => {
          this.props.updateUser(
            {
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
              username: userInfo.userName,
              email: userInfo.email,
              phone: userInfo.phone,
              password: userInfo.password,
              roles: roless,
              avatar: image,
              uuid: userInfo.uuid,
            },
            {
              onSuccess: (data) => {
                this.props.addRolesToUser(data.uuid, userInfo.rolesItems, {
                  onSuccess: () => {
                    this.props.getUsers();
                    this.props.changeModalValue('modalInf', {
                      firstName: '',
                      lastName: '',
                      userName: '',
                      email: '',
                      password: '',
                      phone: '',
                      avatar: '',
                      avatarFile: null,
                      rolesItems: [],
                    });
                  },
                });
              },
            }
          );
          this.onClose();
        },
      });
    } else if (
      userInfo.firstName !== '' &&
      userInfo.lastName !== '' &&
      userInfo.userName !== '' &&
      userInfo.avatar !== '' &&
      userInfo.email !== '' &&
      userInfo.phone !== '' &&
      userInfo.password !== '' &&
      roless !== []
    ) {
      this.props.updateUser(
        {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          username: userInfo.userName,
          email: userInfo.email,
          phone: userInfo.phone,
          password: userInfo.password,
          roles: roless,
          avatar: userInfo.image,
          uuid: userInfo.uuid,
        },
        {
          onSuccess: (data) => {
            this.props.addRolesToUser(data.uuid, userInfo.rolesItems, {
              onSuccess: () => {
                this.props.getUsers();
                this.props.changeModalValue('modalInf', {
                  firstName: '',
                  lastName: '',
                  userName: '',
                  email: '',
                  password: '',
                  phone: '',
                  avatar: '',
                  avatarFile: null,
                  rolesItems: [],
                });
              },
            });
          },
        }
      );
      this.onClose();
    }
  };
  onClose = () => {
    this.setState({ modalEdit: false, modalAdd: false, modalError: false });
    this.props.changeModalValue('modalInf', {
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
      phone: '',
      avatar: '',
      avatarFile: null,
      rolesItems: [],
    });
  };
  editUser = (uuid) => {
    this.props.getUser(this.props.match.params.uuid, uuid, {
      onSuccess: (data) => {
        this.props.changeModalValue('modalInf', {
          firstName: data.firstName,
          lastName: data.lastName,
          userName: data.username,
          email: data.email,
          password: data.password,
          phone: data.phone,
          uuid: data.uuid,
          image: data.avatar,
          avatar: data.avatar?.url,
          rolesItems: data.roles?.map((role) => role.uuid),
        });
        this.setState({ modalEdit: true });
      },
    });
  };

  toggleModal = (modalName) => {
    this.setState({
      [modalName]: {
        ...this.state[modalName],
        isOpen: !this.state[modalName].isOpen,
      },
    });
  };

  closeModal = (modalName) => {
    this.setState({ [modalName]: { ...defaultModal } });
  };

  createRows = (rows) => {
    return rows.map((user) => ({
      id: user.uuid,
      cells: [
        {
          label: '',
          cellComponent: (
            <div className="alerts__table-avatar-wrapper">
              <Avatar
                className="alerts__table-avatar"
                key={user.uuid}
                avatarUrl={user.avatar?.url ? user.avatar.url : ''}
                width={24}
                fontSize={10}
              />
            </div>
          ),
        },
        {
          label: user.username,
          cellComponent: <h5 className="table__cell table__cell--bold">{user.username}</h5>,
        },
        {
          label: user.email,
        },
        {
          label: user.roles.map((role) => role.name + ' '),
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                {
                  name: 'Delete',
                  onClick: () => {
                    this.props.deleteUser(this.props.match.params.uuid, user.uuid, {
                      onSuccess: () => this.props.getUsers(),
                      onError: (error) => this.setState({ modalError: error.message }),
                    });
                  },
                },
              ]}
            />
          ),
        },
      ],
    }));
  };

  onChooseAccount = () => {
    this.props.getUsers(this.state.uuid.value);
    this.setState({ modalChoise: false });
  };

  render() {
    const headerOptions = [
      {
        label: '',
      },
      {
        label: 'Username',
      },
      {
        label: 'Email',
      },
      {
        label: 'Roles',
      },
      {
        label: '',
      },
    ];

    const { modalAdd, modalEdit, modalDelete, modalError } = this.state;

    return (
      <>
        <Table
          rows={this.createRows(this.props.items)}
          headerOptions={headerOptions}
          title={i18n.t('nav:users')}
          totalRows={this.props.total}
          size={this.props.size}
          page={this.props.page}
          changePage={(page) => this.props.changeUserValue(page)}
          isLoading={false}
          onClick={() => this.setState({ modalAdd: true })}
          hideAddButton={true}
        />
        {modalAdd && (
          <ModalAdd
            loading={false}
            isOpen={modalAdd}
            add={true}
            roles={this.props.roles.list.items}
            params={this.props.modalInf}
            changeValue={(name, value) => this.props.changeUserValue(name, value)}
            onSubmit={() => this.addUser()}
            onClose={() => this.onClose()}
            className="modal-users"
          />
        )}
        {modalEdit && (
          <ModalEdit
            loading={false}
            isOpen={modalEdit}
            roles={this.props.roles.list.items}
            showRoles={true}
            params={this.props.modalInf}
            changeValue={(name, value) => this.props.changeUserValue(name, value)}
            onSubmit={() => this.updateUser()}
            onClose={() => this.onClose()}
            className="modal-users"
          />
        )}
        {modalError && (
          <InformationModal
            isOpen={modalError}
            header={'Error'}
            errorMsg={modalError}
            onClose={() => this.onClose()}
          />
        )}
        {this.state.modalChoise && (
          <Modal isOpen={this.state.modalChoise} size="sm">
            <ModalHeader toggle={() => this.setState({ modalChoise: false })}>Accounts</ModalHeader>
            <ModalBody>
              <p className="m-2">Choose Account</p>
              <FormSelect
                name="account"
                label={i18n.t('limitsModals:account')}
                selected={this.state.uuid}
                isSearch={true}
                options={this.props.accounts.list.items.map((it) => ({ label: it.name, value: it.uuid }))}
                className="form-item form-item--one"
                onChange={(name, value) => {
                  this.setState({ uuid: value });
                }}
              />
            </ModalBody>
            <ModalFooter>
              <button className="btn btn--dark" onClick={() => this.setState({ modalChoise: false })}>
                {i18n.t('buttons:cancel')}
              </button>
              <button className="btn btn--secondary" onClick={() => this.onChooseAccount()}>
                {i18n.t('buttons:save')}
              </button>
            </ModalFooter>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ roles, accountUsers, accounts }) => {
  const {
    list: { items, total },
    query: { size, page },
    modalInf,
  } = accountUsers;
  return {
    roles,
    items,
    modalInf,
    total,
    size,
    page,
    accounts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRoles: (params, actions) => dispatch(rolesActions.getRoles(params, actions)),
  getAccounts: (params, actions) => dispatch(accActions.getAccounts(params, actions)),
  getUsers: (uuid, params, actions) => dispatch(accountsActions.getUsersToAccount(uuid, params, actions)),
  getUser: (uuid, userUuid, actions) => dispatch(accountsActions.getUserToAccount(uuid, userUuid, actions)),
  addUser: (params, actions) => dispatch(usersActions.addUser(params, actions)),
  updateUser: (params, actions) => dispatch(usersActions.updateUser(params, actions)),
  deleteUser: (uuid, userUuid, actions) =>
    dispatch(accountsActions.deleteUserToAccount(uuid, userUuid, actions)),
  changePageValue: (value) => dispatch(accountsActions.changePageValue(value)),
  changeUserValue: (key, value) => dispatch(accountsActions.changeUserValue(key, value)),
  changeModalValue: (key, value) => dispatch(accountsActions.changeModalValue(key, value)),
  uploadMedia: (params, actions) => dispatch(usersActions.uploadMedia(params, actions)),
  addRolesToUser: (uuid, params, actions) => dispatch(usersActions.addRolesToUser(uuid, params, actions)),
  updateRolesToUser: (uuid, params, actions) =>
    dispatch(usersActions.updateRolesToUser(uuid, params, actions)),
  deleteRoleToUser: (uuid, roleUuid, actions) =>
    dispatch(usersActions.deleteRoleToUser(uuid, roleUuid, actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountUsers);
