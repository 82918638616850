import * as ActionTypes from '../constants/reports.constants';

export const changeValue = (key, value) => ({
  type: ActionTypes.CHANGE_REPORTS_VALUE,
  payload: { key, value },
});

export const changeAnalyticFilter = (key, value) => ({
  type: ActionTypes.CHANGE_ANALYTIC_FILTER_VALUE,
  payload: { key, value },
});

export const resetAnalyticFilters = () => ({
  type: ActionTypes.RESET_ANALYTIC_FILTERS,
});

export const changeMaintenanceFilter = (key, value) => ({
  type: ActionTypes.CHANGE_MAINTENANCE_FILTER_VALUE,
  payload: { key, value },
});

export const resetMaintenanceFilters = () => ({
  type: ActionTypes.RESET_MAINTENANCE_FILTERS,
});
