import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '../../components/Table';
import i18n from '../../i18n';
import DropdownDotMenu from '../../components/DropdownDotMenu';
import ModalAdd from './Modals/ModalAdd';
import ModalEdit from './Modals/ModalEdit';
import { modalTypes } from '../../constants/modals.constants';
import * as actions from '../../actions/equipmentType.actions';

const defaultModal = {
  isOpen: false,
  errorMsg: '',
  role: {},
  resetError: function () {
    this.errorMsg = '';
  },
};
class EquipmentTypesList extends Component {
  state = {
    modalAdd: { ...defaultModal },
    modalEdit: { ...defaultModal },
    modalDelete: { ...defaultModal },
    editUuid: '',
  };

  componentDidMount() {
    this.props.getEquipmentTypes(
      {},
      {
        onSuccess: (items) => {},
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }

  addLocation = (location) => {
    this.closeModal(modalTypes.MODAL_ADD);
    this.closeModal(modalTypes.MODAL_EDIT);
  };

  toggleModal = (modalName, params) => {
    this.setState({
      [modalName]: {
        ...this.state[modalName],
        ...params,
        isOpen: !this.state[modalName].isOpen,
      },
    });
  };

  closeModal = (modalName) => {
    this.setState({ [modalName]: { ...defaultModal } });
  };

  createRows = (rows) => {
    return rows.map((location) => ({
      id: location.uuid,
      cells: [
        {
          label: location.name,
          cellComponent: <h5 className="table__cell table__cell--bold">{location.name}</h5>,
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                {
                  name: 'Edit',
                  onClick: () => {
                    this.setState({ editUuid: location.uuid });
                    this.toggleModal(modalTypes.MODAL_EDIT, { location });
                  },
                },
                {
                  name: 'Delete',
                  onClick: () => {
                    this.props.deleteEquipmentType(location.uuid, {
                      onSuccess: () => this.props.getEquipmentTypes({}, {}),
                    });
                  },
                },
              ]}
            />
          ),
        },
      ],
    }));
  };
  render() {
    const { items, total } = this.props.list;
    const { size, page } = this.props.query;
    const { modalAdd, modalEdit, modalDelete } = this.state;
    const headerOptions = [
      {
        label: 'Name',
      },
      {
        label: '',
      },
    ];

    const addEquip = (data) => {
      this.props.addEquipmentType(
        { accountUuid: localStorage.getItem('accountUuid'), name: data },
        { onSuccess: () => this.props.getEquipmentTypes({}, {}) }
      );
    };

    const updateEquip = (data) => {
      this.props.updateEquipmentType(
        this.state.editUuid,
        { accountUuid: localStorage.getItem('accountUuid'), name: data, uuid: this.state.editUuid },
        { onSuccess: () => this.props.getEquipmentTypes({}, {}) }
      );
    };

    return (
      <>
        <Table
          rows={this.createRows(items ? items : [])}
          headerOptions={headerOptions}
          title={i18n.t('equipment:equipmentType')}
          totalRows={total}
          size={size}
          page={page}
          isLoading={this.props.isLoading}
          changePage={(page) => this.props.getEquipmentTypes({ page: page })}
          onClick={() => this.toggleModal(modalTypes.MODAL_ADD)}
        />
        {modalAdd.isOpen && (
          <ModalAdd
            loading={false}
            params={modalAdd}
            onSubmit={(value) => {
              addEquip(value);
              this.closeModal(modalTypes.MODAL_ADD);
            }}
            onClose={() => this.closeModal(modalTypes.MODAL_ADD)}
            className="modal-locations"
          />
        )}
        {modalEdit.isOpen && (
          <ModalEdit
            loading={false}
            params={modalEdit}
            onSubmit={(value) => {
              updateEquip(value);
              this.closeModal(modalTypes.MODAL_EDIT);
            }}
            onClose={() => this.closeModal(modalTypes.MODAL_EDIT)}
            className="modal-locations"
          />
        )}
      </>
    );
  }
}

EquipmentTypesList.propTypes = {
  locationsList: PropTypes.array,
};

const mapStateToProps = ({ equipmentType }) => {
  const { list, query, isLoading } = equipmentType;
  return { list, isLoading, query };
};

const mapDispatchToProps = (dispatch) => ({
  getEquipmentTypes: (params, action) => dispatch(actions.getEquipmentTypes(params, action)),
  addEquipmentType: (params, action) => dispatch(actions.addEquipmentType(params, action)),
  updateEquipmentType: (uuid, params, action) => dispatch(actions.updateEquipmentType(uuid, params, action)),
  deleteEquipmentType: (params, action) => dispatch(actions.deleteEquipmentType(params, action)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentTypesList);
