import * as ActionTypes from '../constants/accounts.constants';

export const getUsersToAccount = (uuid, params, actions) => ({
  type: ActionTypes.GET_USERS_ACCOUNT,
  payload: { uuid, params },
  actions,
});
export const getUserToAccount = (uuid, userUuid, actions) => ({
  type: ActionTypes.GET_USER_TO_ACCOUNT,
  payload: { uuid, userUuid },
  actions,
});
export const deleteUserToAccount = (uuid, userUuid, actions) => ({
  type: ActionTypes.DELETE_USER_TO_ACCOUNT,
  payload: { uuid, userUuid },
  actions,
});
export const changeUserValue = (key, value) => ({
  type: ActionTypes.CHANGE_ACCOUNT_USER_VALUE,
  payload: { key, value },
});

export const changeModalValue = (key, value) => ({
  type: ActionTypes.CHANGE_ACCOUNT_MODAL_USER_VALUE,
  payload: { key, value },
});

export const changePageValue = (value) => ({
  type: ActionTypes.CHANGE_ACCOUNT_USER_PAGE_VALUE,
  payload: value,
});
