import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import eye from '../../../assets/images/icons-eye.svg';

export function FormInput({
  type,
  label,
  name,
  error = false,
  value,
  onChange,
  onBlur,
  changeType,
  maxLength,
  className,
  errorMessage,
  disabled,
}) {
  const [isFocus, setFocus] = useState(false);
  const inputRef = useRef(null);

  const handleChange = (e) => {
    if (maxLength) {
      const { value, maxLength } = e.target;
      const message = value.slice(0, maxLength);
      name ? onChange(name, message) : onChange(message);
    } else {
      name ? onChange(name, e.target.value) : onChange(e.target.value);
    }
  };

  const handleBlur = (e) => {
    setFocus(false);
    onBlur && onBlur(e);
  };

  return (
    <div className={className}>
      <label
        htmlFor={name}
        style={{ color: `${error ? '#d10000' : 'rgba(255, 255, 255, 0.5)'}` }}
        className={value || isFocus ? 'form-item--responsive-label--active' : 'form-item--responsive-label'}
        onClick={() => (inputRef ? inputRef.current.focus() : null)}
      >
        {label}
      </label>

      <input
        name={name}
        type={type}
        ref={inputRef}
        className={`form-control ${value || isFocus ? 'form-input--active' : ''}`}
        value={value || ''}
        onChange={handleChange}
        onFocus={() => setFocus(true)}
        onBlur={(e) => handleBlur(e)}
        maxLength={maxLength}
        disabled={disabled}
      />

      {label.includes('Password') ? (
        <img
          className={type === 'password' ? 'form-item__input-eye--crossed' : 'form-item__input-eye'}
          src={eye}
          alt="Show Password"
          onClick={changeType}
        />
      ) : null}

      {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
    </div>
  );
}
