import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from '../../i18n';

class Search extends Component {
  state = {
    search: '',
  };

  onDebounceChange = evt => {
    const input = evt.target.value;
    this.setState({ search: input }, () => {
      this.props.onSearch(input);
    });
  };

  render() {
    const { search } = this.state;
    const { loading } = this.props;
    return (
      <div className="form-group-icon form-group-icon-left search-field">
        <input
          type="text"
          value={this.props.search}
          onChange={this.onDebounceChange}
          className="form-control form-control--small"
          placeholder={i18n.t('fields:searchInputPlaceholder')}
        />
        <button type="button" className="btn">
          {search && loading ? <FontAwesomeIcon icon="spinner" spin /> : <FontAwesomeIcon icon="search" />}
        </button>
      </div>
    );
  }
}

Search.propTypes = {
  search: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default Search;
