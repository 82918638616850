import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

class InfiniteScroll extends Component {
  constructor(props) {
    super(props);
    this.doc = React.createRef();
  }

  handleScroll = () => {
    const { scrollAccuracy, onScrolledBottom = null } = this.props;
    const { scrollTop, scrollLeft, scrollHeight, scrollWidth, offsetHeight, offsetWidth } = this.doc.current;
    if (scrollTop) {
      if (scrollTop + scrollAccuracy >= scrollHeight - offsetHeight) {
        onScrolledBottom && onScrolledBottom();
      }
    } else if (scrollLeft) {
      if (scrollLeft + scrollAccuracy >= scrollWidth - offsetWidth) {
        onScrolledBottom && onScrolledBottom();
      }
    }
  };

  componentDidMount = () => {
    this.props.reff && this.props.reff(this.doc);
    if (this.doc.current && typeof this.doc.current.addEventListener === 'function') {
      this.doc.current.addEventListener('scroll', this.handleScroll);
    }
  };

  componentWillUnmount = () => {
    if (this.doc.current && typeof this.doc.current.removeEventListener === 'function') {
      this.doc.current.removeEventListener('scroll', this.handleScroll);
    }
  };

  render() {
    const { className, children, tag: Component = 'div', loading = false, loadingClass } = this.props;
    return (
      <Component ref={this.doc} className={classnames('infinity-scroll-wrapper', className)}>
        {children}
        {loading && (
          <div className={classnames('infinity-scroll-loading', loadingClass)}>
            <FontAwesomeIcon icon="spinner" size="lg" spin />
          </div>
        )}
      </Component>
    );
  }
}

InfiniteScroll.propTypes = {
  tag: PropTypes.string,
  classNames: PropTypes.string,
  loadingClass: PropTypes.string,
  loading: PropTypes.bool,
  scrollAccuracy: PropTypes.number,
  onScrolledBottom: PropTypes.func,
};

InfiniteScroll.defaultProps = {
  scrollAccuracy: 40,
};

export default InfiniteScroll;
