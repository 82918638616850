import Janus from '../janus.es';
import Cookies from 'universal-cookie';
import axios from 'axios';

class WebRTCService {
  constructor() {
    this.janus = null;
    this.streaming = null;
    this.streamsList = [];
    this.streamId = null;
  }

  async getStreamsList() {
    return new Promise((resolve, reject) => {
      this.streaming.send({
        message: { request: 'list' },
        success: (result) => {
          console.log('++++++++++++++++++ async getStreamsList() success');
          console.log(result);
          if (!result || result.error) {
            reject(result.error);
          }

          if (result && result.streaming === 'list' && Array.isArray(result.list)) {
            const streamsList = result.list
              .filter((e) => e.description.includes('name') && e.description.includes('{'))
              .map((e) => {
                console.log('going to parse description');
                e.description = JSON.parse(e.description);
                return e;
              });
            console.log('resolve streamsList');
            resolve(streamsList);
            // resolve(result.list);
          }

          reject('Unknown error');
        },
      });
    }).catch((error) => {
      console.log(error);
      return [];
    });
  }

  getStreamIdByName(name) {
    const stream = this.streamsList.find((e) => e.description.name === name);
    console.log('getStreamIdByCameraUuid', stream);
    console.log(stream);
    return stream ? stream.id : null;
  }

  async startStream(cameraUuid, streamName, videoContainer, audioContainer, callbacks = {}) {
    if (!cameraUuid) {
      console.log('Invalid cameraUuid');
      return;
    }

    const turnCredentials = await axios.get(`${process.env.REACT_APP_TURN_AUTH_URL}/credentials`, {
      headers: { authorization: localStorage.getItem('token') },
    });

    Janus.init({
      debug: process.env.NODE_ENV === 'development' ? 'all' : false,
      callback: () => {
        if (!Janus.isWebrtcSupported()) {
          console.log('No WebRTC support...');
          return;
        }

        const cookies = new Cookies();

        if (!cookies.get('token')) {
          cookies.set('token', localStorage.getItem('token'));
        }

        this.janus = new Janus({
          server: [
            `${process.env.REACT_APP_JANUS_SERVER_HOST}?token=${localStorage.getItem(
              'token'
            )}&cameraUuid=${cameraUuid}`,
          ],
          iceServers: [
            {
              urls: turnCredentials.data.uris,
              username: turnCredentials.data.username,
              credential: turnCredentials.data.password,
            },
            { urls: `stun:${process.env.REACT_APP_STUN_URL}` },
          ],
          success: () => {
            console.log('Janus initialized');

            if (typeof callbacks.onInit === 'function') {
              callbacks.onInit();
              console.log('------------callbacks.onInit()');
            }

            if (this.janus) {
              console.log('------------if (this.janus)');
              this.janus.attach({
                plugin: 'janus.plugin.streaming',
                success: async (pluginHandle) => {
                  this.streaming = pluginHandle;
                  const plugin = this.streaming.getPlugin();
                  const pluginId = this.streaming.getId();
                  console.log(`Plugin attached! (${plugin}, id=${pluginId})`);
                  this.streamsList = await this.getStreamsList();
                  console.log('------------this.streamsList = await this.getStreamsList();');
                  console.log(this.streamsList);
                  console.log('camera uuid is ' + cameraUuid);
                  this.streamId = this.getStreamIdByName(streamName);

                  if (!this.streamId) {
                    console.log('Invalid streamId');
                  } else {
                    console.log('------------before this.streaming.send({ m');
                    this.streaming?.send({ message: { request: 'watch', id: parseInt(this.streamId, 10) } });
                    console.log('------------after this.streaming.send({ m');
                  }
                },
                error: (error) => {
                  console.error(error);
                },
                onmessage: (msg, jsep) => {
                  if (msg.error) {
                    this.stopStream();
                    return;
                  }

                  if (jsep) {
                    console.debug('Handling SDP...');
                    console.debug(jsep);
                    this.streaming?.createAnswer({
                      jsep,
                      media: { audioSend: false, videoSend: false, data: true },
                      success: (jsep) => {
                        console.debug('Got SDP!');
                        console.debug(jsep);
                        this.streaming.send({ message: { request: 'start' }, jsep });
                      },
                      error: (error) => {
                        console.error('WebRTC error:', error);
                      },
                    });
                  }
                },
                onremotetrack: (track, mid, on) => {
                  Janus.debug('Remote track (mid=' + mid + ') ' + (on ? 'added' : 'removed') + ':', track);
                  if (!on) {
                    return;
                  }
                  if (track.kind === 'audio') {
                    let stream = new MediaStream();
                    stream.addTrack(track.clone());
                    Janus.log('Created remote audio stream:', stream);
                    Janus.attachMediaStream(audioContainer, stream);
                  } else {
                    let stream = new MediaStream();
                    stream.addTrack(track.clone());
                    Janus.log('Created remote video stream:', stream);
                    Janus.attachMediaStream(videoContainer, stream);
                  }
                },
                oncleanup: function () {
                  Janus.log(' ::: Got a cleanup notification :::');
                },

                detached: () => {
                  console.log(`Janus detached from stream with cameraUuid ${cameraUuid}`);
                },
              });
              console.log('------------after this.janus.attach');
            }
          },
          error: (error) => {
            console.log('Error from startStream');
            console.error(error);

            if (typeof callbacks.onError === 'function') {
              callbacks.onError(error);
            }
          },
        });
      },
    });
  }

  stopStream() {
    if (this.janus) {
      this.janus.destroy({ asyncRequest: false });
      this.janus = null;
      this.streaming = null;
      this.streamsList = [];
      this.streamId = null;
      console.log('Janus session destroyed');
    }
  }
}

export default new WebRTCService();
