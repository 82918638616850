import * as ActionTypes from '../constants/notification.constants';

export const add = (notification) => ({ type: ActionTypes.ADD_NOTIFICATION, payload: notification });
export const remove = (id, cameraUuid) => ({
  type: ActionTypes.REMOVE_NOTIFICATION,
  payload: { id, cameraUuid },
});
export const disable = (notificationId) => ({
  type: ActionTypes.DISABLE_NOTIFICATION,
  payload: notificationId,
});
