import React from 'react';
import i18n from '../../i18n';

const NotFound = () => {
  return (
    <div className="w-100 h-100 not--found--page">
      <div className="not--found--page__item">{i18n.t('pagenotFound')}</div>
    </div>
  );
};

export default NotFound;
