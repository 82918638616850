import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import { connect } from 'react-redux';
import i18n from '../../i18n';

import DropdownDotMenu from '../../components/DropdownDotMenu';
import * as accountActions from '../../actions/accounts.action';

import { Link } from 'react-router-dom';
import { ACCOUNTLIMITS, ACCOUNTS, ACCOUNTUSERS } from '../../constants/routes.constants';
import AccountModal from './AccountModal';
import InformationModal from '../../components/InformationModal/InformationModal';

const AccountsList = ({
  items,
  total,
  page,
  size,
  modalInf,
  getAccounts,
  getAccount,
  addAccount,
  updateAccount,
  deleteAccount,
  changeAccountValue,
  changeModalValue,
  changePageValue,
}) => {
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalError, setModalError] = useState(false);

  useEffect(() => {
    getAccounts();
  }, []);

  useEffect(() => {
    getAccounts();
  }, [page]);

  const addAcc = () => {
    addAccount(
      {
        name: modalInf.name,
        credentials: {
          username: modalInf.userName,
          password: modalInf.password,
        },
      },
      {
        onSuccess: (data) => getAccounts(),
        onError: (error) => setModalError(error.message),
      }
    );
    onClose();
  };

  const updateAcc = () => {
    updateAccount(
      {
        name: modalInf.name,
        uuid: modalInf.uuid,
      },
      {
        onSuccess: (data) => {
          getAccounts();
        },
      }
    );
    onClose();
  };

  const onClose = () => {
    setModalEdit(false);
    setModalAdd(false);
    setModalError(false);
    changeAccountValue('modalInf', {
      name: '',
      userName: '',
      password: '',
      uuid: '',
    });
  };

  const editAccount = (uuid, name) => {
    changeAccountValue('modalInf', {
      name: name,
      uuid: uuid,
    });
    setModalEdit(true);
  };

  const createRows = (rows) => {
    return rows.map((account) => ({
      id: account.uuid,
      cells: [
        {
          label: account.name,
          cellComponent: <h5 className="table__cell table__cell--bold">{account.name}</h5>,
        },
        {
          label: account.accountType,
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                { name: <Link to={`${ACCOUNTLIMITS}/${account.uuid}`}>Account limits</Link> },
                { name: <Link to={`${ACCOUNTUSERS}/${account.uuid}`}>Account users</Link> },

                { name: 'Edit', onClick: () => editAccount(account.uuid, account.name) },
              ]}
            />
          ),
        },
      ],
    }));
  };

  const headerOptions = [
    {
      label: 'Name',
    },
    {
      label: 'Account Type',
    },
    {
      label: '',
    },
  ];

  return (
    <>
      <Table
        rows={createRows(items)}
        headerOptions={headerOptions}
        title={i18n.t('nav:accounts')}
        totalRows={total}
        size={size}
        page={page}
        changePage={(page) => changePageValue(page)}
        isLoading={false}
        onClick={() => setModalAdd(true)}
      />
      {modalAdd && (
        <AccountModal
          modal={modalAdd}
          add={true}
          onClose={() => onClose()}
          title="addTitle"
          info={modalInf}
          onChange={(name, value) => changeModalValue(name, value)}
          onSubmit={() => addAcc()}
          onClose={() => onClose()}
        />
      )}
      {modalEdit && (
        <AccountModal
          modal={modalEdit}
          onClose={() => onClose()}
          title="editTitle"
          info={modalInf}
          onChange={(name, value) => changeModalValue(name, value)}
          onSubmit={() => updateAcc()}
          onClose={() => onClose()}
        />
      )}
      {modalError && (
        <InformationModal
          isOpen={modalError}
          header={'Error'}
          errorMsg={modalError}
          onClose={() => onClose()}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ accounts }) => {
  const {
    list: { items, total },
    query: { size, page },
    modalInf,
  } = accounts;
  return {
    items,
    modalInf,
    total,
    size,
    page,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAccounts: (params, actions) => dispatch(accountActions.getAccounts(params, actions)),
  addAccount: (params, actions) => dispatch(accountActions.addAccount(params, actions)),
  updateAccount: (params, actions) => dispatch(accountActions.updateAccount(params, actions)),
  changePageValue: (value) => dispatch(accountActions.changePageValue(value)),
  changeAccountValue: (key, value) => dispatch(accountActions.changeAccountValue(key, value)),
  changeModalValue: (key, value) => dispatch(accountActions.changeModalValue(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsList);
