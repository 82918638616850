import * as ActionTypes from '../constants/cameras.constants';
import * as PlayerTypes from '../constants/player.constants';
import * as EventTypes from '../constants/event.constants';

import { getOffsetTime } from '../components/Player/constants';
import DateTimeService from '../services/DateTimeService';

const GTS = (d) => DateTimeService.getTimestamp(d);

const initialState = {
  eventsCache: [],
  list: {
    items: [],
    total: 0,
    size: 1000000,
    page: 0,
  },
  timeline: [],
  query: {
    types: [],
  },
  faceLegend: false,
  isLoading: false,
  isError: null,
};

export const cameraEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_CAMERA_EVENT: {
      const items = [action.payload, ...state.list.items].splice(0, 10);
      return {
        ...state,
        list: {
          ...state.list,
          items,
          page: Math.floor(items.length / state.list.size),
          total: items.length + 1,
        },
      };
    }

    case ActionTypes.RESET_CAMERA_EVENTS: {
      return {
        ...state,
        timeline: [],
      };
    }

    case ActionTypes.GET_CAMERA_EVENTS_GROUPED_TIMESTAMPS_RESULT:
      return {
        ...state,
        timeline: [
          ...state.timeline,
          ...Object.keys(action.payload.results).map((obj) => ({
            eventTimestamp: obj * action.payload.interval,
            eventType: action.payload.results[obj][0].eventType,
          })),
        ],
        isLoading: false,
      };

    case ActionTypes.SET_CAMERA_EVENTS_TIMESTAMPS:
      return {
        ...state,
        timeline: action.payload,
        isLoading: false,
      };

    case PlayerTypes.SET_EVENTS_CACHE: {
      return {
        ...state,
        eventsCache: action.eventsCache,
      };
    }

    case PlayerTypes.SET_RANGE_PLAYER: {
      let newRange = getOffsetTime(action.payload);
      return {
        ...state,
        list: {
          ...state.list,
          items: [],
          total: 0,
          page: 0,
        },
        query: {
          ...state.query,
          from: GTS(newRange.from),
          to: GTS(newRange.to),
        },
      };
    }

    case ActionTypes.SET_CAMERA_UNMOUNT:
      return initialState;
    default:
      return state;
  }
};
