import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n';
import NumericInput from 'react-numeric-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import ReportFilter from '../../components/Report/ReportFilter';
import Table from '../../components/Table';
import * as limitsActions from '../../actions/limits.actions';
import * as usersActions from '../../actions/users.actions';

import { TotalResourcesRows } from '../../constants/limits.constants';

const LimitsList = ({
  me,
  myList,
  isLoading,
  isLoadingUserLimits,
  isLoadingAccountLimits,
  getMyLimits,
  getUserAllLimits,
  getUsers,
  updateLimit,
}) => {
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ label: '', value: '' });
  const [activeRow, setActiveRow] = useState({ table: '', id: '' });
  const [rowChanges, setRowChanges] = useState({});
  const [hasEditPermission, setHasEditPermission] = useState(false);

  useEffect(() => {
    setSelectedUser({ label: me.username, value: me.uuid });
    setHasEditPermission(
      me.authorities.some((authority) =>
        ['ADMINISTRATION', 'ALL', 'WRITE_RESOURCE_LIMIT'].includes(authority)
      )
    );
    getMyLimits();
    getUsers(
      {},
      {
        onSuccess: (users) => {
          setUserOptions(users.items.map((user) => ({ label: user.username, value: user.uuid })));
        },
      }
    );
  }, []);

  useEffect(() => {
    setRowChanges({});
  }, [activeRow]);

  const handleCancelChanges = () => {
    setTimeout(() => setActiveRow({ table: '', id: '' }), 0);
  };

  const handleApplyChanges = () => {
    let user = { uuid: selectedUser.value, username: selectedUser.label };
    let processingType = null;
    let resource = null;
    if (activeRow.table === 'user-total') {
      resource = TotalResourcesRows[activeRow.id].value;
    } else if (activeRow.table === 'user-types') {
      processingType = myList.userLimits?.processingLimits?.limits[activeRow.id].processingType;
    }
    const keys = Object.keys(rowChanges);
    keys.map((key, index) => {
      const updatedLimit = {
        user,
        limit: rowChanges[key],
      };
      switch (key) {
        case 'fps': {
          updatedLimit.processingType = processingType;
          updatedLimit.resource = 'PROCESSING_FPS';
          break;
        }

        case 'instance': {
          updatedLimit.processingType = processingType;
          updatedLimit.resource = 'PROCESSING_INSTANCE';
          break;
        }

        case 'limit': {
          updatedLimit.resource = resource;
          break;
        }

        default:
          break;
      }

      updateLimit(updatedLimit);
      if (index === keys.length - 1) {
        getUserAllLimits(user.uuid);
      }
    });
    setTimeout(() => setActiveRow({ table: '', id: '' }), 0);
  };

  const handleSetActiveRow = (table, id) => {
    if (table === activeRow.table && id === activeRow.id) return;
    setActiveRow({ table: table, id });
  };

  const createRows = (rows) => {
    return rows.map((limit, index) => ({
      id: limit.uuid || index,
      cells: [
        {
          label: limit.processingType,
        },
        {
          label: limit.fps.used + ' / ' + limit.fps.limit,
          editableCellComponent: (
            <div style={{ whiteSpace: 'nowrap' }}>
              {limit.fps.used} /{' '}
              <div style={{ width: '50px' }} className="table-editable__numeric-input">
                <NumericInput
                  type="number"
                  min={0}
                  max={999}
                  step={1}
                  value={rowChanges.fps ? rowChanges.fps : limit.fps.limit}
                  onChange={(value) => setRowChanges((prev) => ({ ...prev, fps: value }))}
                />
              </div>
            </div>
          ),
        },
        {
          label: limit.instance.used + ' / ' + limit.instance.limit,
          editableCellComponent: (
            <div style={{ whiteSpace: 'nowrap' }}>
              {limit.instance.used} /{' '}
              <div style={{ width: '50px' }} className="table-editable__numeric-input">
                <NumericInput
                  type="number"
                  min={0}
                  max={999}
                  step={1}
                  value={rowChanges.instance ? rowChanges.instance : limit.instance.limit}
                  onChange={(value) => setRowChanges((prev) => ({ ...prev, instance: value }))}
                />
              </div>
            </div>
          ),
        },
        {
          label: ' ',
          align: 'right',
          editableCellComponent: (
            <div className="table-editable__tools">
              <button className="table-editable__tools-btn" onClick={handleApplyChanges}>
                <FontAwesomeIcon className="" icon={faCheck} />
              </button>
              {' | '}
              <button className="table-editable__tools-btn" onClick={handleCancelChanges}>
                <FontAwesomeIcon className="" icon={faTimes} />
              </button>
            </div>
          ),
        },
      ],
    }));
  };

  const createRowsForSecondTable = (rows) => {
    return rows.map((limit, index) => ({
      id: limit.uuid || index,
      cells: [
        {
          label: TotalResourcesRows[index].label,
        },
        {
          label: limit.used + ' ',
        },
        {
          label: limit.limit + ' ',
          editableCellComponent: (
            <div style={{ width: '50px' }} className="table-editable__numeric-input">
              <NumericInput
                type="number"
                min={0}
                max={999}
                step={1}
                value={rowChanges.limit ? rowChanges.limit : limit.limit}
                onChange={(value) => setRowChanges((prev) => ({ ...prev, limit: value }))}
              />
            </div>
          ),
        },
        {
          label: ' ',
          align: 'right',
          editableCellComponent: (
            <div className="table-editable__tools">
              <button className="table-editable__tools-btn" onClick={handleApplyChanges}>
                <FontAwesomeIcon className="" icon={faCheck} />
              </button>
              {' | '}
              <button className="table-editable__tools-btn" onClick={handleCancelChanges}>
                <FontAwesomeIcon className="" icon={faTimes} />
              </button>
            </div>
          ),
        },
      ],
    }));
  };

  const headerOptions = [
    {
      label: 'Processing Type',
    },
    {
      label: 'FPS',
    },
    {
      label: 'Instance',
    },
    {
      label: '',
    },
  ];

  const headerSecondTable = [
    {
      label: 'Name',
    },
    {
      label: 'Used',
    },
    {
      label: 'Limit',
    },
    {
      label: '',
    },
  ];

  const filters = (
    <ReportFilter
      title="Workflow"
      options={userOptions}
      selected={selectedUser}
      onClick={(user) => {
        setSelectedUser(user);
        getUserAllLimits(user.value);
        setActiveRow({});
      }}
    />
  );

  return (
    <div className="resource-limits-page">
      <div className="w-50 h-100">
        <div>
          <Table
            rows={createRowsForSecondTable(
              myList && myList.accountLimits
                ? [
                    myList.accountLimits.cameraConsumerLimit,
                    myList.accountLimits.processingLimits.totalFps,
                    myList.accountLimits.processingLimits.totalInstances,
                    myList.accountLimits.analyticsFpsLimit,
                    myList.accountLimits.analyticsInstanceLimit,
                    myList.accountLimits.eventGeneratorFpsLimit,
                  ]
                : []
            )}
            headerOptions={headerSecondTable}
            isLoading={isLoadingAccountLimits}
            hidePagination={true}
            hideAddButton={true}
            title={i18n.t('nav:accLimits')}
          />
        </div>
        <Table
          rows={createRows(
            myList && myList.accountLimits ? myList.accountLimits.processingLimits.limits : []
          )}
          headerOptions={headerOptions}
          title=""
          isLoading={isLoadingAccountLimits}
          hideAddButton={true}
          hidePagination={true}
        />
      </div>
      <div className="w-50">
        <div>
          <Table
            rows={createRowsForSecondTable(
              myList && myList.userLimits
                ? [
                    myList.userLimits.cameraConsumerLimit,
                    myList.userLimits.processingLimits.totalFps,
                    myList.userLimits.processingLimits.totalInstances,
                    myList.userLimits.analyticsFpsLimit,
                    myList.userLimits.analyticsInstanceLimit,
                    myList.userLimits.eventGeneratorFpsLimit,
                  ]
                : []
            )}
            headerOptions={headerSecondTable}
            titleFilters={hasEditPermission ? filters : null}
            isLoading={isLoadingUserLimits}
            hidePagination={true}
            hideAddButton={true}
            title={i18n.t('nav:userLimits')}
            activeRowId={activeRow.table === 'user-total' ? activeRow.id : ''}
            setActiveRow={(id) => (hasEditPermission ? handleSetActiveRow('user-total', id) : {})}
            editable={hasEditPermission}
          />
        </div>
        <Table
          rows={createRows(myList && myList.userLimits ? myList.userLimits.processingLimits.limits : [])}
          headerOptions={headerOptions}
          isLoading={isLoadingUserLimits}
          hideAddButton={true}
          hidePagination={true}
          activeRowId={activeRow.table === 'user-types' ? activeRow.id : ''}
          setActiveRow={(id) => (hasEditPermission ? handleSetActiveRow('user-types', id) : {})}
          editable={hasEditPermission}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ limits, users }) => {
  const { isLoading, isLoadingUserLimits, isLoadingAccountLimits, myList } = limits;
  const { me } = users;
  return { me, isLoading, isLoadingUserLimits, isLoadingAccountLimits, myList };
};

const mapDispatchToProps = (dispatch) => ({
  getMyLimits: (params, actions) => dispatch(limitsActions.getMyLimits(params, actions)),
  getUserAllLimits: (uuid, actions) => dispatch(limitsActions.getUserAllLimits(uuid, actions)),
  updateLimit: (params, actions) => dispatch(limitsActions.updateLimit(params, actions)),
  getUsers: (params, actions) => dispatch(usersActions.getUsers(params, actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LimitsList);
