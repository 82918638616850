import AlertEnum from '../enums/alert.enum';
import * as ActionTypes from '../constants/alerts.constants';

export const addAlert = (notification) => {
  return {
    type: ActionTypes.ADD_ALERT,
    item: notification,
  };
};

export const getAlerts = (params) => ({
  type: ActionTypes.GET_ALERTS,
  payload: params,
});

export const getAlertsNextPage = () => ({
  type: ActionTypes.GET_ALERTS_NEXT_PAGE,
});

export const changeAlertValue = (key, value) => ({
  type: ActionTypes.CHANGE_ALERT_VALUE,
  payload: {
    key,
    value,
  },
});

export const getAlertRoutes = (params, actions) => ({
  type: ActionTypes.GET_ALERT_ROUTES,
  payload: params,
  actions,
});

export const getAlertRoute = (uuid, actions) => ({
  type: ActionTypes.GET_ALERT_ROUTE,
  payload: uuid,
  actions,
});

export const addAlertRoute = (params, actions) => ({
  type: ActionTypes.ADD_ALERT_ROUTE,
  payload: params,
  actions,
});

export const updateAlertRoute = (params, actions) => ({
  type: ActionTypes.ADD_ALERT_ROUTE,
  payload: params,
  actions,
});

export const deleteAlertRoute = (uuid, actions) => ({
  type: ActionTypes.DELETE_ALERT_ROUTE,
  payload: uuid,
  actions,
});
