import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { appReducer } from './app.reducer';
import { cameraReducer } from './camera.reducer';
import { camerasReducer } from './cameras.reducer';
import { alertsReducer } from './alerts.reducer';
import { alertRoutesReducer } from './alertRoutes.reducer';
import { subjectsReducer } from './subjects.reducer';
import { cameraEventsReducer } from './camera.events.reducer';
import { cameraAlertsReducer } from './camera.alerts.reducer';
import { cameraRecordingsReducer } from './camera.recordings.reducer';
import { playerReducer } from './player.reducer';
import { subjectReducer } from './subject.reducer';
import { subjectEventsReducer } from './subject.events.reducer';
import { notificationsReducer } from './notifications.reducer';
import { eventsReducer } from './events.reducer';
import { workflowsReducer } from './workflows.reducer';
import { personsReducer } from './persons.reducer';
import { personReducer } from './person.reducer';
import { personEventsReducer } from './person.events.reducer';
import { carsReducer } from './cars.reducer';
import { carReducer } from './car.reducer';
import { locationsReducer } from './locations.reducer';
import { equipmentsReducer } from './equipment.reducer';
import { equipmentTypesReducer } from './equipmentType.reducer';
import { rolesReducer } from './roles.reducer';
import { usersReducer } from './users.reducer';
import { limitsReducer } from './limits.reducer';
import { accountsReducer } from './accounts.reducer';
import { accountUsersReducer } from './accountUsers.reducer';
import { trainingReducer } from './training.reducer';
import { modelsReducer } from './models.reducer';
import { reportsReducer } from './reports.reducer';

export default (history) => {
  return combineReducers({
    router: connectRouter(history),
    alerts: alertsReducer,
    alertRoutes: alertRoutesReducer,
    cameras: camerasReducer,
    workflows: workflowsReducer,
    player: combineReducers({
      controls: playerReducer,
      camera: cameraReducer,
      events: cameraEventsReducer,
      alerts: cameraAlertsReducer,
      recordings: cameraRecordingsReducer,
    }),
    subjects: subjectsReducer,
    subjectView: combineReducers({
      subject: subjectReducer,
      events: subjectEventsReducer,
    }),
    persons: personsReducer,
    personView: combineReducers({
      person: personReducer,
      events: personEventsReducer,
    }),
    cars: carsReducer,
    carView: combineReducers({
      car: carReducer,
    }),
    locations: locationsReducer,
    notifications: notificationsReducer,
    app: appReducer,
    events: eventsReducer,
    equipment: equipmentsReducer,
    equipmentType: equipmentTypesReducer,
    roles: rolesReducer,
    users: usersReducer,
    limits: limitsReducer,
    accounts: accountsReducer,
    accountUsers: accountUsersReducer,
    training: trainingReducer,
    model: modelsReducer,
    reports: reportsReducer,
  });
};
