import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  CustomInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import cx from 'classnames';

import Loader from '../../../../components/Loader';
import i18n from '../../../../i18n';
import { FormSelect } from '../../../../components/Form';

import * as workflowActions from '../../../../actions/workflows.action';
import * as camerasActions from '../../../../actions/cameras.actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DrawingPanel from '../../../../components/DrawingPanel/DrawingPanel';

import { modifyModalTabs, triggersForZones } from '../../../../constants/workflows.constants';
import * as modalActions from '../AttachCameraModal/actions';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import Form from '@rjsf/core';
import * as workflowsActions from '../../../../actions/workflows.action';

const ModifyCameraModal = ({
  title,
  workflowUuid,
  isOpen,
  onSubmit,
  onClose,
  isLoading,
  className,
  verifyCamera,
  getWorkflowCameras,
  getZonesForWorkflowCamera,
  getTriggersForWorkflowCamera,
  updateWorkflowCameraTrigger,
  setZonesToWorkflowCamera,
}) => {
  const [connectedCameras, setConnectedCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [zones, setZones] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [availableTriggers, setAvailableTriggers] = useState([]);
  const [elements, setElements] = useState([]);
  const [elementOptions, setElementOptions] = useState({});
  const [cameraTriggers, setCameraTriggers] = useState([]);
  const [cameraFrame, setCameraFrame] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState('');

  const [availableTabs, setAvailableTabs] = useState([modifyModalTabs[0].value]);
  const [activeTab, setActiveTab] = useState(modifyModalTabs[0].value);

  useEffect(() => {
    getWorkflowCameras(
      workflowUuid,
      {},
      {
        onSuccess: (cameras) => {
          setConnectedCameras([
            ...cameras.items.map((camera) => ({
              label: camera.name,
              value: camera.uuid,
              streamUrl: camera.streamUrl,
            })),
          ]);
        },
      }
    );
  }, []);

  useEffect(() => {
    if (selectedCamera) {
      setAvailableTabs([modifyModalTabs[0].value]);
      setLoading(true);

      getZonesForWorkflowCamera(workflowUuid, selectedCamera.value, {
        onSuccess: (zones) => {
          setZones(zones);
          setZoneOptions(zones.zoi.map((zone) => ({ label: zone.name, value: zone.id })));

          getTriggersForWorkflowCamera(
            workflowUuid,
            selectedCamera.value,
            { scope: 'DATA_STREAM' },
            {
              onSuccess: (triggers) => {
                const zoneTriggers = triggers.map((trigger) => ({
                  ...trigger,
                  label: trigger.type,
                  value: trigger.uuid,
                  schema: triggersForZones.includes(trigger.type)
                    ? modifyTriggerSchema(
                        trigger.schema,
                        zones.zoi.map((zone) => zone.name)
                      )
                    : trigger.schema,
                }));
                setAvailableTriggers(zoneTriggers);
                setCameraTriggers(
                  zoneTriggers
                    .filter((trigger) =>
                      Array.isArray(trigger.config)
                        ? trigger.config.length
                        : Object.keys(trigger.config).length
                    )
                    .map((trigger) => {
                      return {
                        selectedCamera: selectedCamera,
                        selectedTrigger: trigger,
                        config: triggersForZones.includes(trigger.type)
                          ? trigger.config.map((item) => ({
                              ...item,
                              zoneId: zones.zoi.find((zone) => zone.id === item.zoneId).name,
                            }))
                          : trigger.config,
                        enabled: trigger.enabled,
                      };
                    })
                );
              },
            }
          );
        },
      });

      verifyCamera(selectedCamera.streamUrl, {
        onSuccess: (res) => {
          setCameraFrame(`data:image/jpeg;base64,${res.data}`);
          setAvailableTabs([modifyModalTabs[0].value, modifyModalTabs[1].value]);
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
          setLoadingError("Can't load frame!");
          setAvailableTabs([modifyModalTabs[0].value, modifyModalTabs[1].value]);
        },
      });
    }
  }, [selectedCamera]);

  const updateZonesForWorkflow = (elements) => {
    let updatedZones = {};
    const lines = [];
    const roi = [];
    const zoi = [];

    elements.forEach((element) => {
      switch (element.type) {
        case 'line':
          const existingLine = zones.lines ? zones.lines.find((line) => line.name === element.name) : null;
          const line = {
            endPoints: [
              { x: element.relative.x1, y: element.relative.y1 },
              { x: element.relative.x2, y: element.relative.y2 },
            ],
            id: existingLine && existingLine.id,
            name: element.name,
            normal: {
              x: element.normal.x,
              y: element.normal.y,
            },
          };
          lines.push(line);
          break;

        case 'rectangle':
          const existingRoi = zones.roi ? zones.roi.find((item) => item.name === element.name) : null;
          const minX = Math.min(element.relative.x1, element.relative.x2);
          const maxX = Math.max(element.relative.x1, element.relative.x2);
          const minY = Math.min(element.relative.y1, element.relative.y2);
          const maxY = Math.max(element.relative.y1, element.relative.y2);

          const rectangle = {
            id: existingRoi && existingRoi.id,
            name: element.name,
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY,
          };
          roi.push(rectangle);
          break;

        case 'polygon':
          const existingPolygon = zones.zoi ? zones.zoi.find((item) => item.name === element.name) : null;
          const polygon = {
            id: existingPolygon && existingPolygon.id,
            name: element.name,
            points: element.relPoints,
            equipmentUuid: elementOptions[element.name]?.equipment?.value,
          };
          zoi.push(polygon);
          break;

        case 'doublePolygon':
          if (element.entrypoint.length) {
            const existingDPolygon = zones.zoi ? zones.zoi.find((item) => item.name === element.name) : null;
            const doublePolygon = {
              id: existingDPolygon && existingDPolygon.id,
              name: element.name,
              points: element.relPoints,
              entrypoint: element.relEntrypoint,
            };
            zoi.push(doublePolygon);
          }
          break;
      }
    });

    updatedZones = {
      lines,
      roi,
      zoi,
    };

    setZonesToWorkflowCamera(workflowUuid, selectedCamera.value, updatedZones, {
      onSuccess: (zones) => {
        setZones(zones);
        setZoneOptions(zones.zoi.map((zone) => ({ label: zone.name, value: zone.id })));

        const zoneTriggers = availableTriggers.map((trigger) => ({
          ...trigger,
          schema: triggersForZones.includes(trigger.type)
            ? modifyTriggerSchema(
                trigger.schema,
                zones.zoi.map((zone) => zone.name)
              )
            : trigger.schema,
        }));
        setAvailableTriggers(zoneTriggers);
      },
    });
  };

  const verifyModal = () => {
    if (selectedCamera) {
      const triggers = cameraTriggers.filter((trigger) =>
        Array.isArray(trigger.config) ? trigger.config.length : Object.keys(trigger.config).length
      );
      const uploadTriggers = triggers.map((trigger) => {
        let config = {};
        if (triggersForZones.includes(trigger.selectedTrigger.type)) {
          config = trigger.config.map((item) => ({
            ...item,
            zoneId: zones.zoi.find((zone) => zone.name === item.zoneId).id,
          }));
        } else {
          config = trigger.config;
        }

        return {
          analyticsType: trigger.selectedTrigger.analyticsType,
          analyticsUuid: trigger.selectedTrigger.analyticsUuid,
          config: config,
          enabled: trigger.enabled,
          schema: trigger.selectedTrigger.schema,
          type: trigger.selectedTrigger.type,
          uuid: trigger.selectedTrigger.uuid,
        };
      });

      uploadTriggers.length &&
        uploadTriggers.forEach((trigger, index) =>
          updateWorkflowCameraTrigger(workflowUuid, selectedCamera.value, trigger.uuid, trigger, {})
        );

      updateZonesForWorkflow(elements);
      onSubmit();
    }
  };

  const switchTab = (tab) => {
    if (availableTabs.includes(tab)) {
      if (activeTab === modifyModalTabs[0].value) {
        if (selectedCamera) {
          updateZonesForWorkflow(elements);
        }
        setActiveTab(tab);
      } else {
        setActiveTab(tab);
      }
    }
  };

  const modifyTriggerSchema = (schema, zones) => {
    const schemaCopy = { ...schema };

    schemaCopy.items.properties.zoneId = {
      ...schemaCopy.items.properties.zoneId,
      enum: zones ? zones : [],
    };

    return schemaCopy;
  };

  const selectTriggerType = (index, trigger) => {
    setCameraTriggers((prev) =>
      prev.map((item, i) => {
        if (i === index) {
          return { ...item, selectedTrigger: trigger, config: [] };
        }
        return item;
      })
    );
  };

  const addTrigger = () => {
    setCameraTriggers((prev) => [
      ...prev,
      {
        selectedCamera: selectedCamera,
        selectedTrigger: null,
        config: [],
        enabled: true,
      },
    ]);
  };

  const removeTrigger = (index) => {
    setCameraTriggers((prev) => prev.filter((trigger, i) => i !== index));
  };

  const changeTrigger = (index, key, value) => {
    setCameraTriggers((prev) =>
      prev.map((trigger, i) => {
        if (i === index) {
          return { ...trigger, [key]: value };
        }
        return trigger;
      })
    );
  };

  const generateTriggers = cameraTriggers.map((trigger, index) => (
    <div key={index} className="form__creation">
      <div className="form__creation__header">
        <div className="form-row">
          <FormSelect
            label={i18n.t('workflowModals:labelSelectedCamera')}
            selected={trigger.selectedCamera}
            options={selectedCamera ? [selectedCamera] : []}
            className="form-item form-item--two"
            disabled={true}
            onChange={() => {}}
            errorMessage={false}
          />

          <FormSelect
            label={i18n.t('workflowModals:labelTriggerType')}
            selected={trigger.selectedTrigger}
            options={availableTriggers.length ? availableTriggers : []}
            isSearch={true}
            className="form-item form-item--two"
            onChange={(trigger) => selectTriggerType(index, trigger)}
            errorMessage={false}
          />
        </div>

        <button className="form__creation__header-btn" onClick={() => removeTrigger(index)}>
          <FontAwesomeIcon icon={faTimes} className="form__creation__header-times" />
        </button>
      </div>

      {trigger.selectedCamera && trigger.selectedTrigger ? (
        triggersForZones.includes(trigger.selectedTrigger.type) && !zoneOptions.length ? (
          'Has no zones'
        ) : (
          <>
            <div className="form-row_mt-10">
              <div className="form-switch-item">
                <CustomInput
                  type="switch"
                  checked={trigger.enabled}
                  onChange={(e) => changeTrigger(index, 'enabled', e.target.checked)}
                  id={`${trigger.triggerType}-${index}`}
                  name="Enabled"
                  className="custom-switch--small"
                />
                <p className="form-switch-label">{i18n.t('workflowModals:labelEnabled')}</p>
              </div>
            </div>

            <div className="form__creation-fields">
              <Form
                schema={trigger.selectedTrigger.schema}
                formData={trigger.config}
                onChange={(data) => changeTrigger(index, 'config', data.formData)}
                onError={() => {}}
                children={[]}
              />
            </div>
          </>
        )
      ) : null}
    </div>
  ));

  return (
    <Modal isOpen={isOpen} className={cx('', className)} fade>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>

      <ModalBody>
        <Nav tabs className="workflow-form__camera-tabs">
          <NavItem>
            <NavLink
              className={cx({ active: activeTab === modifyModalTabs[0].value })}
              onClick={() => switchTab(modifyModalTabs[0].value)}
              disabled={!availableTabs.includes(modifyModalTabs[0].value)}
            >
              {i18n.t('workflowModals:cameraTab')}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={cx({ active: activeTab === modifyModalTabs[1].value })}
              onClick={() => switchTab(modifyModalTabs[1].value)}
              disabled={!availableTabs.includes(modifyModalTabs[1].value)}
            >
              {i18n.t('workflowModals:triggersTab')}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} className="workflow-form__tab-content">
          <TabPane tabId={modifyModalTabs[0].value}>
            <div className="form-row">
              <FormSelect
                label={i18n.t('workflowModals:labelAttachedCamera')}
                className="form-item form-item--one"
                selected={selectedCamera}
                options={connectedCameras}
                onChange={(camera) => setSelectedCamera(camera)}
                isSearch={true}
                errorMessage={false}
              />
            </div>

            {selectedCamera && (
              <div className="camera-preview">
                {loading && (
                  <div className="connect">
                    <Loader loading={loading} />
                    <span>{i18n.t('cameraModals:previewLoading')}</span>
                  </div>
                )}
                {!loading && loadingError && (
                  <div className="error">
                    <FontAwesomeIcon icon="unlink" />
                    <span>{i18n.t('cameraModals:previewError')}</span>
                  </div>
                )}
                {cameraFrame && (
                  <DrawingPanel
                    className="mt-20"
                    frame={cameraFrame}
                    id="modify-camera"
                    elements={elements}
                    elementOptions={elementOptions}
                    zones={zones}
                    setElements={(elements) => setElements(elements)}
                    setElementOptions={(options) => setElementOptions(options)}
                  />
                )}
              </div>
            )}
          </TabPane>

          <TabPane tabId={modifyModalTabs[1].value}>
            {generateTriggers}

            <button className="form__creation__add-btn" onClick={addTrigger} disabled={!selectedCamera}>
              <FontAwesomeIcon icon={faPlus} />
              Add Trigger
            </button>
          </TabPane>
        </TabContent>
      </ModalBody>

      <ModalFooter>
        <Loader loading={isLoading} />
        <button type="button" className="btn btn--dark" disabled={isLoading} onClick={onClose}>
          {i18n.t('buttons:cancel')}
        </button>

        <button className="btn btn--secondary" type="submit" onClick={verifyModal}>
          {i18n.t('buttons:save')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  verifyCamera: (url, actions) => dispatch(camerasActions.verifyCamera(url, actions)),
  getWorkflowCameras: (uuid, params, actions) =>
    dispatch(workflowActions.getWorkflowCameras(uuid, params, actions)),
  getTriggersForWorkflowCamera: (workflowUuid, cameraUuid, params, actions) =>
    dispatch(workflowsActions.getTriggersForWorkflowCamera(workflowUuid, cameraUuid, params, actions)),
  getZonesForWorkflowCamera: (workflowUuid, cameraUuid, actions) =>
    dispatch(workflowsActions.getZonesForWorkflowCamera(workflowUuid, cameraUuid, actions)),
  updateWorkflowCameraTrigger: (workflowUuid, cameraUuid, triggerUuid, params, actions) =>
    dispatch(
      workflowsActions.updateWorkflowCameraTrigger(workflowUuid, cameraUuid, triggerUuid, params, actions)
    ),
  setZonesToWorkflowCamera: (workflowUuid, cameraUuid, params, actions) =>
    dispatch(workflowActions.setZonesToWorkflowCamera(workflowUuid, cameraUuid, params, actions)),
});

export default connect(null, mapDispatchToProps)(ModifyCameraModal);
