import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ModalAdd from './Modals/ModalAdd';
import ModelsService from '../../../services/ModelsService';
import Pagination from '../../../components/Pagination';
import Loader from '../../../components/Loader';
import Search from '../../../components/Search';
import SubjectList from './SubjectList';
import i18n from '../../../i18n';

import { SUBJECT_SEX_MALE, SUBJECT_TYPE_KNOWN } from '../../../constants/subjects.constants';
import * as actions from '../../../actions/subject.actions';

const defaultSubject = {
  age: '',
  birthday: '',
  height: '',
  name: '',
  photos: [],
  sex: SUBJECT_SEX_MALE,
  subjectType: SUBJECT_TYPE_KNOWN,
};
const defaultModal = {
  isOpen: false,
  errorMsg: '',
  subject: {},
  resetError: function() {
    this.errorMsg = '';
  },
};

class Subjects extends Component {
  state = {
    modalAdd: { ...defaultModal },
  };

  componentDidMount() {
    this.props.getSubjects();
  }

  onSearch = text => {
    this.props.setSearchSubjects(text);
  };

  onPage = number => {
    this.props.setNumberPageSubjects(number);
  };

  addSubject = subject => {
    this.props.addSubject(subject, {
      onSuccess: () => {
        this.closeModal();
        this.props.getSubjects();
      },
      onError: error => this.setModalError(error),
    });
  };

  toggleModal = subject => {
    this.setState({
      modalAdd: {
        ...this.state.modalAdd,
        isOpen: !this.state.modalAdd.isOpen,
        subject,
      },
    });
  };

  setModalError = error => {
    this.setState({
      modalAdd: {
        ...this.state.modalAdd,
        errorMsg: error.message,
      },
    });
  };

  closeModal = () => {
    this.setState({ modalAdd: { ...defaultModal } });
  };

  render() {
    const { modalAdd } = this.state;
    const { list, size, page, name, total, isLoading, loadingAddSubject } = this.props;

    return (
      <div className="mt-20 w-100">
        <Loader loading={isLoading} />
        <div className="head-page-list">
          <button
            className="btn btn btn--secondary"
            onClick={() => this.toggleModal({ ...defaultSubject })}
          >
            {i18n.t('subjects:buttonAddSubject')}
          </button>
          <Pagination size={size} page={page} total={total} onPage={this.onPage} />
          <Search search={name} loading={isLoading} onSearch={this.onSearch} />
        </div>
        <SubjectList list={list} search={name} loading={isLoading} />
        {modalAdd.isOpen && (
          <ModalAdd
            loading={loadingAddSubject}
            params={modalAdd}
            onClose={this.closeModal}
            onSubmit={this.addSubject}
            className="modal-subjects"
          />
        )}
      </div>
    );
  }
}

Subjects.propTypes = {
  list: PropTypes.arrayOf(ModelsService.getSubjectModel()),
  size: PropTypes.number,
  page: PropTypes.number,
  name: PropTypes.string,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  loadingAddSubject: PropTypes.bool,
  getSubjects: PropTypes.func,
  addSubject: PropTypes.func,
};

const mapStateToProps = ({ subjects, subjectView }) => {
  const {
    list: { items, total },
    query: { size, page, name },
    isLoading,
  } = subjects;
  const { loadingAddSubject } = subjectView.subject;
  return { list: items, size, page, name, total, isLoading, loadingAddSubject };
};

const mapDispatchToProps = dispatch => ({
  getSubjects: params => dispatch(actions.getSubjects(params)),
  addSubject: (params, reqActions) => dispatch(actions.addSubject(params, reqActions)),
  setSearchSubjects: text => dispatch(actions.setSearchSubjects(text)),
  setNumberPageSubjects: number => dispatch(actions.setNumberPageSubjects(number)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subjects);
