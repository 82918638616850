import { all } from 'redux-saga/effects';
import appSagas from './app.sagas';
import alertsSagas from './alerts.sagas';
import camerasSagas from './cameras.sagas';
import subjectSagas from './subject.sagas';
import playerSagas from './player.sagas';
import mediaSagas from './media.sagas';
import schemasSagas from './schemas.sagas';
import workflowsSagas from './workflows.sagas';
import locationsSagas from './locations.sagas';
import equipmentSagas from './equipment.sagas';
import equipmentTypeSagas from './equipmentType.sagas';
import rolesSagas from './roles.sagas';
import usersSagas from './users.sagas';
import historyDataSagas from './historyData.sagas';
import limitsSagas from './limits.sagas';
import accountsSagas from './accounts.sagas';
import trainingSagas from './training.sagas';
import modelsSagas from './models.sagas';

export default function* mainSaga() {
  yield all([
    appSagas(),
    alertsSagas(),
    camerasSagas(),
    subjectSagas(),
    playerSagas(),
    mediaSagas(),
    schemasSagas(),
    workflowsSagas(),
    locationsSagas(),
    equipmentSagas(),
    equipmentTypeSagas(),
    rolesSagas(),
    usersSagas(),
    historyDataSagas(),
    limitsSagas(),
    accountsSagas(),
    trainingSagas(),
    modelsSagas(),
  ]);
}
