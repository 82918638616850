import * as ActionTypes from '../constants/cameras.constants';

const initialState = {
  list: {
    items: [],
    total: 0,
  },
  query: {
    size: 100,
    page: 0,
  },
  isLoading: false,
};

export const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CAMERA_FRAMES:
      return {
        ...state,
        list: initialState.list,
        isLoading: true,
      };

    case ActionTypes.GET_CAMERA_FRAMES_NEXT_PAGE:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_CAMERA_FRAMES_RESULT: {
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items, total },
        query: { size, page },
        isLoading: false,
      };
    }

    case ActionTypes.GET_CAMERA_FRAMES_NEXT_PAGE_RESULT: {
      const { items, total, size, page } = action.payload;
      const filteredFrames = items.filter((e) => state.list.items.findIndex((s) => s.uuid === e.uuid) === -1);
      return {
        ...state,
        list: { items: [...state.list.items, ...filteredFrames], total },
        query: { size, page },
        isLoading: false,
      };
    }

    case ActionTypes.GET_CAMERA_FRAMES_ERROR:
    case ActionTypes.GET_CAMERA_FRAMES_NEXT_PAGE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
