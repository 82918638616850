import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import {Img} from 'react-image';
import cx from 'classnames';

const UploadPhotosListElement = ({ photo, remove }) => {
  const onRemove = e => {
    e && e.preventDefault();
    remove(photo);
  };

  return (
    <li className="upload-gallery-item">
      <div className={cx('upload-gallery-content', { ' --warning': photo.isError })}>
        {/* eslint-disable */}
        <a
          href="#"
          className={cx('upload-gallery-remove', { disabled: photo.now && !photo.uuid && !photo.isError })}
          onClick={onRemove}
        >
          <FontAwesomeIcon icon="times" />
        </a>
        {/* eslint-enable */}
        {photo.now && !photo.uuid ? (
          photo.isError ? (
            <figure>
              <FontAwesomeIcon icon="exclamation-triangle" size="4x" />
              <figcaption>
                {photo.error.code === 400 && photo.error.message.length ? (
                  <span>
                    Photo doesn't <br /> meet requirements
                  </span>
                ) : (
                  photo.error.message[0]
                )}
              </figcaption>
            </figure>
          ) : (
            <div className="image-loading">
              <FontAwesomeIcon icon="spinner" size="lg" spin />
            </div>
          )
        ) : (
          <Img
            src={photo.url}
            loader={
              <div className="image-loading">
                <FontAwesomeIcon icon="spinner" size="lg" spin />
              </div>
            }
            alt="Lorem, ipsum dolor."
          />
        )}
      </div>
    </li>
  );
};

export default UploadPhotosListElement;
