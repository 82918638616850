import React, { Component } from 'react';
import i18n from '../../../i18n';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Loader from '../../../components/Loader';
import cx from 'classnames';

import { FormInput } from '../../../components/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faInfo } from '@fortawesome/free-solid-svg-icons';
import CheckboxGroup from 'react-checkbox-group';

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(250, i18n.t('validationMessages:length', { field: 'firstName', length: 250 }))
    .required(i18n.t('validationMessages:required', { field: 'firstName' })),
  lastName: yup
    .string()
    .max(250, i18n.t('validationMessages:length', { field: 'lastName', length: 250 }))
    .required(i18n.t('validationMessages:required', { field: 'lastName' })),
  email: yup.string(),
  permissions: yup.object(),
  password: yup.string(),
});

class BaseModal extends Component {
  state = {
    message: null,
  };

  render() {
    const {
      isOpen,
      header,
      onClose,
      loading,
      submitBtnText,
      closeBtnText,
      className,
      changeValue,
      onSubmit,
      params,
      nullParams,
      roles,
      showRoles = false,
      add = false,
    } = this.props;
    const { firstName, lastName, userName, password, phone, avatar, avatarFile, rolesItems } = params;

    function previewFile(fil) {
      const file = fil.files[0];
      const filereader = new FileReader();
      changeValue('avatarFile', file);
      filereader.readAsDataURL(file);

      filereader.onload = function () {
        changeValue('avatar', filereader.result);
      };
    }

    return (
      <Modal isOpen={isOpen} className={cx('', className)} fade>
        <ModalHeader toggle={onClose}>{header}</ModalHeader>
        <Formik
          onSubmit={this.onSubmit}
          initialValues={{
            inputType: 'password',
          }}
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values: { inputType },
              touched,
              errors,
              isValid,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <ModalBody>
                  <div className="form-fields mb-28">
                    <div className="form-row">
                      <FormInput
                        name="firstName"
                        type="text"
                        label={i18n.t('usersModals:labelFirstName') + ' *'}
                        value={firstName}
                        className="form-item form-item--two"
                        onBlur={handleBlur}
                        error={nullParams.includes('firstName')}
                        onChange={(name, value) => {
                          changeValue(name, value);
                        }}
                        errorMessage={false}
                      />

                      <FormInput
                        name="lastName"
                        type="text"
                        label={i18n.t('usersModals:labelLastName') + ' *'}
                        value={lastName}
                        className="form-item form-item--two"
                        onBlur={handleBlur}
                        error={nullParams.includes('lastName')}
                        onChange={(name, value) => {
                          changeValue(name, value);
                        }}
                        errorMessage={false}
                      />
                    </div>

                    <div className="form-row w-100">
                      <div className="flex-column form-item--two">
                        <FormInput
                          name="userName"
                          type="text"
                          label={i18n.t('fields:username') + ' *'}
                          value={userName}
                          className="form-item form-item--one mb-10"
                          onBlur={handleBlur}
                          error={nullParams.includes('userName')}
                          onChange={(name, value) => {
                            changeValue(name, value);
                          }}
                          errorMessage={false}
                        />
                        {add && (
                          <FormInput
                            name="password"
                            label="Password"
                            type={inputType}
                            label={i18n.t('fields:password') + ' *'}
                            value={password}
                            className="form-item form-item--one mb-10"
                            onBlur={handleBlur}
                            error={nullParams.includes('password')}
                            onChange={(name, value) => {
                              changeValue(name, value);
                            }}
                            changeType={() =>
                              setFieldValue('inputType', inputType === 'password' ? 'text' : 'password')
                            }
                            errorMessage={false}
                          />
                        )}
                      </div>
                      <div className="d-flex flex-column justify-content-center form-item--two">
                        <label
                          htmlFor="formFileSm"
                          className={`file--custom--input`}
                          style={{
                            color: `${nullParams.includes('avatar') ? '#d10000' : 'rgba(255, 255, 255)'}`,
                          }}
                        >
                          Upload Photo *
                          <input id="formFileSm" type="file" onChange={(e) => previewFile(e.target)} />
                        </label>
                        {avatar === undefined || avatar === '' ? (
                          <FontAwesomeIcon icon={faUser} size="lg" className="file--icon" />
                        ) : (
                          <img src={avatar}></img>
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <FormInput
                        name="phone"
                        type="text"
                        label={i18n.t('fields:phone') + ' *'}
                        value={phone}
                        className={`form-item form-item--two`}
                        error={nullParams.includes('phone')}
                        onBlur={handleBlur}
                        onChange={(name, value) => {
                          changeValue(name, value);
                        }}
                        errorMessage={false}
                      />
                    </div>
                  </div>
                  {(showRoles || add) && (
                    <>
                      <h2 class={`form-group-title ${nullParams.includes('roles') && 'invalid-feedback'}`}>
                        {i18n.t('usersModals:labelRoles') + ' *'}
                      </h2>
                      <div className="form--checkbox--group">
                        <CheckboxGroup
                          name="roles"
                          value={rolesItems}
                          onChange={(value) => changeValue('rolesItems', value)}
                        >
                          {(Checkbox) => (
                            <>
                              {roles.map((role) => (
                                <label className="form--checkbox--item">
                                  <Checkbox value={role.uuid} /> {role.name}
                                  <span
                                    class="ml-2"
                                    style={{ cursor: 'pointer' }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={role.description === '' ? 'Role description' : role.description}
                                  >
                                    <i class="fa-solid fa-circle-info"></i>
                                  </span>
                                </label>
                              ))}
                            </>
                          )}
                        </CheckboxGroup>
                      </div>
                    </>
                  )}
                </ModalBody>

                <ModalFooter>
                  <Loader loading={loading} />
                  <button type="button" className="btn btn--dark" disabled={loading} onClick={onClose}>
                    {closeBtnText}
                  </button>
                  <button className="btn btn--secondary" type="submit" disabled={loading} onClick={onSubmit}>
                    {submitBtnText}
                  </button>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

BaseModal.defaultProps = {
  loading: false,
  submitBtnText: i18n.t('buttons:save'),
  closeBtnText: i18n.t('buttons:cancel'),
};

export default BaseModal;
