import { queryStringify } from '../utils/index';
import request from '../utils/request';

export function getAccounts(params) {
  return request({
    url: `/accounts`,
    method: 'get',
    data: params,
  });
}

export function addAccount(params) {
  return request({
    url: '/accounts',
    method: 'post',
    data: params,
  });
}

export function updateAccount(params) {
  return request({
    url: `/accounts`,
    method: 'post',
    data: params,
  });
}

export function getAccountUsers(uuid, params) {
  return request({
    url: `/accounts/${uuid}/users`,
    method: 'get',
    data: params,
  });
}

export function addUserToAccount(uuid, params) {
  return request({
    url: `/accounts/${uuid}/users`,
    method: 'post',
    data: params,
  });
}

export function getUserToAccount(uuid, userUuid) {
  return request({
    url: `/accounts/${uuid}/users/${userUuid}`,
    method: 'get',
  });
}

export function deleteUserToAccount(uuid, userUuid) {
  return request({
    url: `/accounts/${uuid}/users/${userUuid}`,
    method: 'delete',
  });
}

export function getAccountLimits(uuid, params) {
  return request({
    url: `/accounts/${uuid}/resource-limits`,
    method: 'get',
    data: params,
  });
}
