import { queryStringify } from '../utils/index';
import request from '../utils/request';

export function getSubjects(params) {
  return request({
    url: '/subjects',
    method: 'get',
    data: params,
  });
}

export function getSubject(uuid) {
  return request({
    url: `/subjects/${uuid}`,
    method: 'get',
  });
}

export function getSubjectEvents(uuid, params) {
  return request({
    url: `/subjects/${uuid}/events?${queryStringify(params)}`,
    method: 'get',
  });
}

export function addSubject(params) {
  return request({
    url: '/subjects',
    method: 'post',
    data: params,
  });
}

export function updateSubject(params) {
  // Backend Fake Up!
  const subjectPUTModify = (subject) => {
    if (subject.uuid) {
      delete subject.uuid;
    }
    if (subject.accountUuid) {
      delete subject.accountUuid;
    }
    return { ...subject };
  };

  return request({
    url: `/subjects/${params.uuid}`,
    method: 'put',
    data: subjectPUTModify(params),
  });
}

export function deleteSubject(uuid) {
  return request({
    url: `/subjects/${uuid}`,
    method: 'delete',
  });
}

export function getPersons(params) {
  return request({
    url: `/subjects/persons?${queryStringify(params)}`,
    method: 'get',
  });
}

export function addPerson(params) {
  return request({
    url: '/subjects/persons',
    method: 'post',
    data: params,
  });
}

export function getPerson(uuid) {
  return request({
    url: `/subjects/persons/${uuid}`,
    method: 'get',
  });
}

export function updatePerson(uuid, params) {
  return request({
    url: `/subjects/persons/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deletePerson(uuid) {
  return request({
    url: `/subjects/persons/${uuid}`,
    method: 'delete',
  });
}

export function getPersonEvents(uuid, params) {
  return request({
    url: `/subjects/persons/${uuid}/events?${queryStringify(params)}`,
    method: 'delete',
  });
}

export function getCars(params) {
  return request({
    url: `/subjects/cars?${queryStringify(params)}`,
    method: 'get',
  });
}

export function addCar(params) {
  return request({
    url: '/subjects/cars',
    method: 'post',
    data: params,
  });
}

export function getCar(uuid) {
  return request({
    url: `/subjects/cars/${uuid}`,
    method: 'get',
  });
}

export function updateCar(uuid, params) {
  return request({
    url: `/subjects/cars/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteCar(uuid) {
  return request({
    url: `/subjects/cars/${uuid}`,
    method: 'delete',
  });
}
