export const GET_PROCESSING_CONFIGS = 'GET_PROCESSING_CONFIGS';
export const GET_PROCESSING_CONFIG = 'GET_PROCESSING_CONFIG';
export const GET_ANALYTIC_BASE_CONFIG = 'GET_ANALYTIC_BASE_CONFIG';

export const GET_WORKFLOWS = 'GET_WORKFLOWS';
export const GET_WORKFLOWS_RESULT = 'GET_WORKFLOWS_RESULT';
export const GET_WORKFLOWS_ERROR = 'GET_WORKFLOWS_ERROR';

export const GET_WORKFLOWS_NEXT_PAGE = 'GET_WORKFLOWS_NEXT_PAGE';
export const GET_WORKFLOWS_NEXT_PAGE_RESULT = 'GET_WORKFLOWS_NEXT_PAGE_RESULT';
export const GET_WORKFLOWS_NEXT_PAGE_ERROR = 'GET_WORKFLOWS_NEXT_PAGE_ERROR';

export const GET_WORKFLOWS_USING_CAMERAS = 'GET_WORKFLOWS_USING_CAMERAS';

export const GET_WORKFLOW = 'GET_WORKFLOW';
export const ADD_WORKFLOW = 'ADD_WORKFLOW';
export const ADD_WORKFLOW_SUCCESS = 'ADD_WORKFLOW_SUCCESS';
export const ADD_WORKFLOW_ERROR = 'ADD_WORKFLOW_ERROR';
export const UPDATE_WORKFLOW = 'UPDATE_WORKFLOW';
export const UPDATE_WORKFLOW_SUCCESS = 'UPDATE_WORKFLOW_SUCCESS';
export const UPDATE_WORKFLOW_ERROR = 'UPDATE_WORKFLOW_ERROR';
export const DELETE_WORKFLOW = 'DELETE_WORKFLOW';
export const DELETE_WORKFLOW_SUCCESS = 'DELETE_WORKFLOW_SUCCESS';
export const DELETE_WORKFLOW_ERROR = 'DELETE_WORKFLOW_ERROR';

export const ADD_PROCESSING_TO_WORKFLOW = 'ADD_PROCESSING_TO_WORKFLOW';
export const GET_WORKFLOW_PROCESSING = 'GET_WORKFLOW_PROCESSING';
export const ADD_ANALYTICS_TO_WORKFLOW = 'ADD_ANALYTICS_TO_WORKFLOW';
export const GET_WORKFLOW_ANALYTICS = 'GET_WORKFLOW_ANALYTICS';
export const DELETE_WORKFLOW_ANALYTICS = 'DELETE_WORKFLOW_ANALYTICS';
export const ADD_TRIGGERS_TO_WORKFLOW = 'ADD_TRIGGERS_TO_WORKFLOW';
export const UPDATE_WORKFLOW_CAMERA_TRIGGER = 'UPDATE_WORKFLOW_CAMERA_TRIGGER';
export const GET_WORKFLOW_TRIGGERS = 'GET_WORKFLOW_TRIGGERS';
export const ADD_CAMERAS_TO_WORKFLOW = 'ADD_CAMERAS_TO_WORKFLOW';
export const GET_WORKFLOW_CAMERAS = 'GET_WORKFLOW_CAMERAS';
export const DELETE_WORKFLOW_CAMERA = 'DELETE_WORKFLOW_CAMERA';

export const SET_ZONES_TO_WORKFLOW_CAMERA = 'SET_ZONES_TO_WORKFLOW_CAMERA';
export const GET_ZONES_FOR_WORKFLOW_CAMERA = 'GET_ZONES_FOR_WORKFLOW_CAMERA';
export const ADD_ZONES_FOR_WORKFLOW_CAMERA = 'ADD_ZONES_FOR_WORKFLOW_CAMERA';
export const DELETE_ZONE_FROM_WORKFLOW_CAMERA = 'DELETE_ZONE_FROM_WORKFLOW_CAMERA';

export const GET_ANALYTICS_FOR_WORKFLOW_CAMERA = 'GET_ANALYTICS_FOR_WORKFLOW_CAMERA';
export const GET_TRIGGERS_FOR_WORKFLOW_CAMERA = 'GET_TRIGGERS_FOR_WORKFLOW_CAMERA';

export const GET_AVAILABLE_ANALYTICS = 'GET_AVAILABLE_ANALYTICS';
export const GET_AVAILABLE_TRIGGERS = 'GET_AVAILABLE_TRIGGERS';

export const START_WORKFLOW = 'START_WORKFLOW';
export const STOP_WORKFLOW = 'STOP_WORKFLOW';

export const CHANGE_WORKFLOW_RUNNING_STATUS = 'CHANGE_WORKFLOW_RUNNING_STATUS';
export const CHANGE_CAMERA_RUNNING_STATUS = 'CHANGE_CAMERA_RUNNING_STATUS';
export const CHANGE_PROCESSING_RUNNING_STATUS = 'CHANGE_PROCESSING_RUNNING_STATUS';
export const CHANGE_ANALYTIC_RUNNING_STATUS = 'CHANGE_ANALYTICS_RUNNING_STATUS';
export const CHANGE_TRIGGER_RUNNING_STATUS = 'CHANGE_TRIGGER_RUNNING_STATUS';

export const GET_EXISTING_PROCESSING_TYPES = 'GET_EXISTING_PROCESSING_TYPES';

export const CHANGE_WORKFLOW_FILTERS = 'CHANGE_WORKFLOW_FILTERS';

export const GET_WORKFLOW_PRESETS = 'GET_WORKFLOW_PRESETS';
export const GET_WORKFLOW_PRESETS_SUCCESS = 'GET_WORKFLOW_PRESETS_SUCCESS';
export const GET_WORKFLOW_PRESETS_ERROR = 'GET_WORKFLOW_PRESETS_ERROR';

export const GET_WORKFLOW_PRESET = 'GET_WORKFLOW_PRESET';

export const ADD_WORKFLOW_PRESET = 'ADD_WORKFLOW_PRESET';
export const ADD_WORKFLOW_PRESET_SUCCESS = 'ADD_WORKFLOW_PRESET_SUCCESS';
export const ADD_WORKFLOW_PRESET_ERROR = 'ADD_WORKFLOW_PRESET_ERROR';

export const UPDATE_WORKFLOW_PRESET = 'UPDATE_WORKFLOW_PRESET';
export const UPDATE_WORKFLOW_PRESET_SUCCESS = 'UPDATE_WORKFLOW_PRESET_SUCCESS';
export const UPDATE_WORKFLOW_PRESET_ERROR = 'UPDATE_WORKFLOW_PRESET_ERROR';

export const DELETE_WORKFLOW_PRESET = 'DELETE_WORKFLOW_PRESET';
export const DELETE_WORKFLOW_PRESET_SUCCESS = 'DELETE_WORKFLOW_PRESET_SUCCESS';
export const DELETE_WORKFLOW_PRESET_ERROR = 'DELETE_WORKFLOW_PRESET_ERROR';

export const workflowModalTabs = [
  { label: 'General', value: 'general' },
  { label: 'Processing', value: 'processing' },
  { label: 'Analytics', value: 'analytics' },
  { label: 'Triggers', value: 'triggers' },
];

export const attachModalTabs = [
  { label: 'Cameras', value: 'cameras' },
  { label: 'Triggers', value: 'triggers' },
];

export const modifyModalTabs = [
  { label: 'Camera', value: 'camera' },
  { label: 'Triggers', value: 'triggers' },
];

export const workflowStatuses = {
  ACTIVE: 'Active',
  PARTLY: 'Partly',
  INACTIVE: 'Inactive',
  PENDING: 'Pending...',
};

export const workflowStates = [
  'STARTING_CAMERA_CONSUMER',
  'CAMERA_CONSUMER_STARTED',
  'SAVING_CAMERA_CONFIG',
  'CAMERA_CONFIG_SAVED',
  'PROCESSING_CONNECTED',
  'CONNECTING_PROCESSING',
  'CONNECTING_ANALYTICS',
  'ANALYTICS_CONNECTED',
  'CONNECTING_EVENT_GENERATOR',
  'EVENT_GENERATOR_CONNECTED',
  'DISCONNECTING_EVENT_GENERATOR',
  'EVENT_GENERATOR_DISCONNECTED',
  'DELETING_CAMERA_CONFIG',
  'CAMERA_CONFIG_DELETED',
  'DISCONNECTING_ANALYTICS',
  'ANALYTICS_DISCONNECTED',
  'DISCONNECTING_PROCESSING',
  'PROCESSING_DISCONNECTED',
  'ERROR',
  'START_ROLLBACK',
  'END_ROLLBACK',
  'WORKFLOW_STARTED',
  'WORKFLOW_STOPPED',
  'CAMERA_STARTED',
  'CAMERA_STOPPED',
];

export const workflowSuccessStates = [
  'WORKFLOW_STARTED',
  'WORKFLOW_STOPPED',
  'CAMERA_STARTED',
  'CAMERA_STOPPED',
];

export const workflowErrorStates = ['ERROR', 'START_ROLLBACK', 'END_ROLLBACK'];

export const configStatuses = {
  DRAFT: 'DRAFT',
  READY: 'READY',
  STARTED: 'STARTED',
};

export const commonStatuses = {
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
  ERROR: 'ERROR',
};

export const triggersForZones = [
  'AccumulationIntervalHighActivity',
  'AccumulationIntervalLowActivity',
  'ZoneHighActivity',
  'ZoneLowActivity',
  'ZoneHighOccupancy',
  'ZoneLowOccupancy',
  'ZoneMinDistance',
];

export const contrastingColors = [
  '#FFFF00',
  '#1CE6FF',
  '#FF34FF',
  '#FF4A46',
  '#008941',
  '#006FA6',
  '#A30059',
  '#FFDBE5',
  '#7A4900',
  '#0000A6',
  '#63FFAC',
  '#B79762',
  '#004D43',
  '#8FB0FF',
  '#997D87',
  '#5A0007',
  '#809693',
  '#FEFFE6',
  '#1B4400',
  '#4FC601',
  '#3B5DFF',
  '#4A3B53',
  '#FF2F80',
  '#61615A',
  '#BA0900',
  '#6B7900',
  '#00C2A0',
  '#FFAA92',
  '#FF90C9',
  '#B903AA',
  '#D16100',
  '#DDEFFF',
  '#000035',
  '#7B4F4B',
  '#A1C299',
  '#300018',
  '#0AA6D8',
  '#013349',
  '#00846F',
  '#372101',
  '#FFB500',
  '#C2FFED',
  '#A079BF',
  '#CC0744',
  '#C0B9B2',
  '#C2FF99',
  '#001E09',
  '#00489C',
  '#6F0062',
  '#0CBD66',
  '#EEC3FF',
  '#456D75',
  '#B77B68',
  '#7A87A1',
  '#788D66',
  '#885578',
  '#FAD09F',
  '#FF8A9A',
  '#D157A0',
  '#BEC459',
  '#456648',
  '#0086ED',
  '#886F4C',
  '#34362D',
  '#B4A8BD',
  '#00A6AA',
  '#452C2C',
  '#636375',
  '#A3C8C9',
  '#FF913F',
  '#938A81',
  '#575329',
  '#00FECF',
  '#B05B6F',
  '#8CD0FF',
  '#3B9700',
  '#04F757',
  '#C8A1A1',
  '#1E6E00',
  '#7900D7',
  '#A77500',
  '#6367A9',
  '#A05837',
  '#6B002C',
  '#772600',
  '#D790FF',
  '#9B9700',
  '#549E79',
  '#FFF69F',
  '#201625',
  '#72418F',
  '#BC23FF',
  '#99ADC0',
  '#3A2465',
  '#922329',
  '#5B4534',
  '#FDE8DC',
  '#404E55',
  '#0089A3',
  '#CB7E98',
  '#A4E804',
  '#324E72',
  '#6A3A4C',
];
