export const WATCH = '/watch';
export const DASHBOARD = '/dashboard';
export const SUBJECTS = '/subjects';
export const SETTINGS = '/settings';
export const USERS = '/administration/users';
// export const EQUIPMENT = '/administration/equipment-management';
export const MEMBERS = '/administration/members';
export const ENVIRONMENT = '/environment-designer';
export const WORKFLOW = '/workflow';
export const CAMERAS = '/cameras';
export const USERPROFILE = '/user/me';
export const TRAININGMARKUP = '/training-markup';
export const ROLES = '/administration/roles';
export const PERSONS = '/administration/persons';
export const CARS = '/administration/cars';
export const LOCATIONS = '/administration/locations';
export const EQUIPMENTTYPES = '/administration/equipment-types';
export const EQUIPMENTS = '/administration/equipments';
export const ALERTMANAGEMENT = '/alerts';
export const ALERTROUTES = '/alert-routes';
export const REPORT = '/report';
export const ANALYTICAL_REPORTS = '/analytical-reports';
export const LIMITS = '/my-limits';
export const USERLIMITS = '/administration/users/limits';
export const ACCOUNTS = '/accounts';
export const ACCOUNTUSERS = '/accounts/users';
export const ACCOUNTLIMITS = '/accounts/limits';
