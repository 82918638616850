import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import BaseModal from './BaseModal';
import PersonPhotos from '../PersonView/PersonPhotos';

class ModalAdd extends BaseModal {
  state = {
    ...this.state,
    photos: [],
  };

  getPersonPhotos = (photos) => {
    this.setState({ photos });
    return this.state.photos;
  };

  renderPersonPhotos() {
    return <PersonPhotos getCallback={this.getPersonPhotos} />;
  }
}

ModalAdd.defaultProps = {
  ...BaseModal.defaultProps,
  header: i18n.t('personModals:addTitle'),
};

export default ModalAdd;
