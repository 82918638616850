import { queryStringify } from '../utils/index';
import request from '../utils/request';

export function getModels(params) {
  return request({
    url: `/models?${queryStringify(params)}`,
    method: 'get',
  });
}

export function getModel(uuid) {
  return request({
    url: `/models/${uuid}`,
    method: 'get',
  });
}

export function addModel(params) {
  return request({
    url: '/models',
    method: 'post',
    data: params,
  });
}

export function updateModel(params) {
  return request({
    url: `/models`,
    method: 'put',
    data: params,
  });
}

export function deleteModel(uuid) {
  return request({
    url: `/models/${uuid}`,
    method: 'delete',
  });
}
