import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CustomInput, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import cx from 'classnames';
import { Formik } from 'formik';
import * as yup from 'yup';
import i18n from '../../../i18n';
import { RTSP_REGEXP } from '../../../constants/cameras.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FormInput, FormNumericInput, FormSelect } from '../../../components/Form';
import Loader from '../../../components/Loader';

import _ from 'lodash';

import * as locationActions from '../../../actions/locations.actions';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .max(250, i18n.t('validationMessages:length', { field: 'name', length: 250 }))
    .required(i18n.t('validationMessages:required', { field: 'name' })),
  location: yup.object().required(i18n.t('validationMessages:required', { field: 'location' })),
  streamUrl: yup
    .string()
    .matches(RTSP_REGEXP, i18n.t('validationMessages:url', { field: 'url' }))
    .required(i18n.t('validationMessages:required', { field: 'url' })),
});

const CameraModal = ({
  title,
  isOpen,
  type,
  camera,
  onSubmit,
  verifyCamera,
  onClose,
  isLoading,
  className,
  errorMsg,
  getLocations,
}) => {
  const [message, setMessage] = useState(null);
  const [isChanged, setChanged] = useState(false);
  const [hasStreamError, setStreamError] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [cameraFrame, setCameraFrame] = useState(null);
  const [verifyTimer, setVerifyTimer] = useState(null);
  const [notVerifiedUrl, setNotVerifiedUrl] = useState(null);
  const [verifiedUrl, setVerifiedUrl] = useState(null);

  const [locations, setLocations] = useState([]);
  const [locationQuery, setLocationQuery] = useState({ name: '', page: 0, size: 20 });

  useEffect(() => {
    if (type === 'edit' && camera.streamUrl) {
      setVerifiedUrl(camera.streamUrl);
      delayedVerify(camera.streamUrl);
    }

    return () => {
      verifyTimer && clearInterval(verifyTimer);
    };
  }, []);

  useEffect(() => {
    getLocations(
      {
        name: locationQuery.name,
        page: 0,
        size: locationQuery.page * locationQuery.size + locationQuery.size,
      },
      {
        onSuccess: (locations) => {
          setLocations(() => [
            ...locations.items.map((location) => ({ label: location.name, value: location.uuid })),
          ]);
        },
      }
    );
  }, [locationQuery.name, locationQuery.page]);

  const delayedVerify = useCallback(
    _.debounce((url) => {
      setConnecting(true);
      verifyCamera(url, {
        onSuccess: (res) => {
          if (res.success) {
            setCameraFrame(`data:image/jpeg;base64,${res.data}`);
            setVerifiedUrl(url);
            setStreamError(false);
          } else {
            setCameraFrame(null);
            setVerifiedUrl(null);
            setStreamError(true);
          }
          setConnecting(false);
        },
        onError: () => {
          setCameraFrame(null);
          setVerifiedUrl(null);
          setStreamError(true);
          setConnecting(false);
        },
      });
    }, 700),
    []
  );

  const delayedQuery = useCallback(
    _.debounce((inputValue) => setLocationQuery({ name: inputValue, page: 0, size: 20 }), 500),
    []
  );

  const handleScrollToBottom = () => {
    setLocationQuery((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const handleInputChange = (searchString) => {
    delayedQuery(searchString);
  };

  const verify = (values) => {
    const camera = { ...values };
    if (camera.location) {
      camera.location = { uuid: camera.location.value };
    }

    onSubmit(camera);
  };

  const handleStreamUrlChange = (value) => {
    if (value.length && RTSP_REGEXP.test(value)) {
      setNotVerifiedUrl(value);
      delayedVerify(value);
    } else {
      setCameraFrame(null);
      setVerifiedUrl(null);
    }
  };

  return (
    <Modal isOpen={isOpen} className={cx('', className)} fade>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>

      <Formik
        onSubmit={verify}
        initialValues={{
          name: type === 'create' ? '' : camera.name,
          location:
            type === 'create'
              ? null
              : camera.location && { label: camera.location.name, value: camera.location.uuid },
          streamUrl: type === 'create' ? '' : camera.streamUrl ? camera.streamUrl : '',
          fps: type === 'create' ? 2 : camera.fps,
          enabled: type === 'create' ? false : camera.enabled,
        }}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values: { name, location, streamUrl, fps, enabled },
            touched,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isValid,
          } = props;
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <ModalBody>
                <div className="form-fields  mb-28">
                  <div className="form-row">
                    <FormInput
                      name="name"
                      type="text"
                      label={i18n.t('cameraModals:labelName')}
                      value={name}
                      className="form-item form-item--one"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.name && touched.name ? errors.name : ''}
                    />
                  </div>

                  <div className="form-row">
                    <FormSelect
                      name="location"
                      label={i18n.t('cameraModals:labelLocation')}
                      selected={location}
                      options={locations}
                      isSearch={true}
                      className="form-item form-item--one"
                      onBlur={handleBlur}
                      onInputChange={handleInputChange}
                      onScrollToBottom={handleScrollToBottom}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.location && touched.location ? errors.location : ''}
                    />
                  </div>

                  <div className="form-row">
                    <FormInput
                      name="streamUrl"
                      type="text"
                      label={i18n.t('cameraModals:labelStreamUrl')}
                      value={streamUrl}
                      className="form-item form-item--one"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        handleStreamUrlChange(value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.streamUrl && touched.streamUrl ? errors.streamUrl : ''}
                    />
                  </div>

                  <div className="form-row">
                    <FormNumericInput
                      name="fps"
                      label={i18n.t('cameraModals:labelFps')}
                      value={fps}
                      className="form-item form-item--one"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={false}
                    />
                  </div>

                  <div className="form-switch-item mb-14">
                    <CustomInput
                      type="switch"
                      checked={enabled}
                      onChange={(e) => {
                        setFieldValue('enabled', e.target.checked);
                        !isChanged && setChanged(true);
                      }}
                      id="camera-enabled"
                      name="enabled"
                      className="custom-switch--small"
                    />
                    <p className="form-switch-label">{i18n.t('cameraModals:labelEnabled')}</p>
                  </div>
                </div>

                <div className="video-upload">
                  <label htmlFor="streamUrl">{i18n.t('cameraModals:labelPreview')}</label>
                  <div className="camera-preview">
                    {!streamUrl || errors.streamUrl ? (
                      <>
                        <FontAwesomeIcon icon="video" />
                        <span>{i18n.t('cameraModals:previewNoConnected')}</span>
                      </>
                    ) : (
                      <>
                        {connecting && (
                          <div className="connect">
                            <Loader loading={connecting} />
                            <span>{i18n.t('cameraModals:previewLoading')}</span>
                          </div>
                        )}
                        {!connecting && hasStreamError && (
                          <div className="error">
                            <FontAwesomeIcon icon="unlink" />
                            <span>{i18n.t('cameraModals:previewError')}</span>
                          </div>
                        )}
                        {verifiedUrl && <img src={cameraFrame} alt="" />}
                      </>
                    )}
                  </div>
                </div>
              </ModalBody>

              <ModalFooter>
                <Loader loading={isLoading} />
                {message || null}
                <button type="button" className="btn btn--dark" disabled={isLoading} onClick={onClose}>
                  {i18n.t('buttons:cancel')}
                </button>
                <button
                  className="btn btn--secondary"
                  type="submit"
                  disabled={isLoading || !isChanged || !isValid || streamUrl !== verifiedUrl}
                >
                  {i18n.t('buttons:save')}
                </button>
              </ModalFooter>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getLocations: (params, actions) => dispatch(locationActions.getLocations(params, actions)),
});

export default connect(null, mapDispatchToProps)(CameraModal);
