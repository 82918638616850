import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import webRTCService from '../../../../services/WebRTCService';
import { LOADING_LIMIT } from '../../../../constants/cameras.constants';
import i18n from '../../../../i18n';

class CameraListElementRealtime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  render() {
    return (
      <div className="frame__video">
        <video src={this.props.camera.demoStreamUrl} autoPlay loop />
      </div>
    );
  }
}

export default CameraListElementRealtime;
