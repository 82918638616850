import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CustomInput, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import i18n from '../../../i18n';

import * as personActions from '../../../actions/subject.actions';
import cx from 'classnames';
import { FormInput, FormNumericInput, FormSelect } from '../../../components/Form';
import Loader from '../../../components/Loader';
import _ from 'lodash';

const validationSchema = yup.object().shape({
  plateLabel: yup.string().required(i18n.t('validationMessages:required', { field: 'plateLabel' })),
});

const CarModal = ({
  title,
  isOpen,
  type,
  car,
  onSubmit,
  onClose,
  className,
  errorMsg,
  getPersons,
  isLoading,
}) => {
  const [isChanged, setChanged] = useState(false);

  const [persons, setPersons] = useState([]);
  const [personQuery, setPersonQuery] = useState({ name: '', page: 0, size: 20 });

  useEffect(() => {
    getPersons(
      {
        name: personQuery.name,
        page: 0,
        size: personQuery.page * personQuery.size + personQuery.size,
      },
      {
        onSuccess: (persons) => {
          setPersons((prev) => [
            ...persons.items.map((person) => ({ label: person.name, value: person.uuid })),
          ]);
        },
      }
    );
  }, [personQuery.name, personQuery.page]);

  const delayedQuery = useCallback(
    _.debounce((inputValue) => setPersonQuery({ name: inputValue, page: 0, size: 20 }), 500),
    []
  );

  const handleScrollToBottom = () => {
    setPersonQuery((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const handleInputChange = (searchString) => {
    delayedQuery(searchString);
  };

  const verify = (values) => {
    const car = { ...values };
    if (car.subject) {
      car.subject = { uuid: car.subject.value };
    }

    onSubmit(car);
  };

  return (
    <Modal isOpen={isOpen} className={cx('', className)} fade>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>

      <Formik
        onSubmit={verify}
        initialValues={{
          plateLabel: type === 'create' ? '' : car.plateLabel,
          subject:
            type === 'create' ? null : car.subject && { label: car.subject.name, value: car.subject.uuid },
          description: type === 'create' ? '' : car.description,
          manufacturer: type === 'create' ? '' : car.manufacturer,
          model: type === 'create' ? '' : car.model,
          vehicleType: type === 'create' ? '' : car.vehicleType,
          color: type === 'create' ? '' : car.color,
          vin: type === 'create' ? '' : car.vin,
          year: type === 'create' ? '' : car.year,
        }}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values: { plateLabel, subject, description, manufacturer, model, vehicleType, color, vin, year },
            touched,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isValid,
          } = props;

          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <ModalBody>
                <div className="form-fields">
                  <div className="form-row">
                    <FormInput
                      name="plateLabel"
                      type="text"
                      label={i18n.t('carModals:labelPlateLabel')}
                      value={plateLabel}
                      className="form-item form-item--two"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.plateLabel && touched.plateLabel ? errors.plateLabel : ''}
                    />

                    <FormSelect
                      name="subject"
                      label={i18n.t('carModals:labelOwner')}
                      selected={subject}
                      options={persons}
                      isSearch={true}
                      className="form-item form-item--two"
                      onBlur={handleBlur}
                      onInputChange={handleInputChange}
                      onScrollToBottom={handleScrollToBottom}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.subject && touched.subject ? errors.subject : ''}
                    />
                  </div>

                  <div className="form-row">
                    <FormInput
                      name="description"
                      type="text"
                      label={i18n.t('carModals:labelDescription')}
                      value={description}
                      className="form-item form-item--one"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.description && touched.description ? errors.description : ''}
                    />
                  </div>

                  <div className="form-row">
                    <FormInput
                      name="manufacturer"
                      type="text"
                      label={i18n.t('carModals:labelManufacturer')}
                      value={manufacturer}
                      className="form-item form-item--two"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.manufacturer && touched.manufacturer ? errors.manufacturer : ''}
                    />

                    <FormInput
                      name="model"
                      type="text"
                      label={i18n.t('carModals:labelModel')}
                      value={model}
                      className="form-item form-item--two"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.model && touched.model ? errors.model : ''}
                    />
                  </div>

                  <div className="form-row">
                    <FormInput
                      name="vehicleType"
                      type="text"
                      label={i18n.t('carModals:labelVehicleType')}
                      value={vehicleType}
                      className="form-item form-item--two"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.vehicleType && touched.vehicleType ? errors.vehicleType : ''}
                    />

                    <FormInput
                      name="vin"
                      type="text"
                      label={i18n.t('carModals:labelVIN')}
                      value={vin}
                      className="form-item form-item--two"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.vin && touched.vin ? errors.vin : ''}
                    />
                  </div>

                  <div className="form-row">
                    <FormInput
                      name="color"
                      type="text"
                      label={i18n.t('carModals:labelColor')}
                      value={color}
                      className="form-item form-item--two"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.color && touched.color ? errors.color : ''}
                    />

                    <FormNumericInput
                      name="year"
                      label={i18n.t('carModals:labelYear')}
                      min={0}
                      max={3000}
                      step={1}
                      value={year}
                      onBlur={handleBlur}
                      className="form-item form-item--two"
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.year && touched.year ? errors.year : ''}
                    />
                  </div>
                </div>
              </ModalBody>

              <ModalFooter>
                <Loader loading={isLoading} />

                <button type="button" className="btn btn--dark" disabled={isLoading} onClick={onClose}>
                  {i18n.t('buttons:cancel')}
                </button>

                <button
                  className="btn btn--secondary"
                  type="submit"
                  disabled={isLoading || !isChanged || !isValid}
                >
                  {i18n.t('buttons:save')}
                </button>
              </ModalFooter>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPersons: (params, actions) => dispatch(personActions.getPersons(params, actions)),
});

export default connect(null, mapDispatchToProps)(CarModal);
