import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import Table from '../../components/Table';
import i18n from '../../i18n';
import Avatar from '../../components/Avatar';
import * as actions from '../../actions/alerts.action';
import * as workflowActions from '../../actions/workflows.action';
import * as schemasActions from '../../actions/schemas.action';
import * as cameraActions from '../../actions/cameras.actions';
import { eventTypes } from '../../constants/alerts.constants';
import { DASHBOARD } from '../../constants/routes.constants';

const ONE_DAY_IN_MS = 86399000;

const defaultModal = {
  isOpen: false,
  errorMsg: '',
  role: {},
  resetError: function () {
    this.errorMsg = '';
  },
};

class AlertsList extends Component {
  state = {
    modalAdd: { ...defaultModal },
    modalEdit: { ...defaultModal },
    modalDelete: { ...defaultModal },
    filters: [
      { title: 'Workflow', options: [] },
      { title: 'Camera', options: [] },
      { title: 'Type', options: [] },
      { title: 'Event Type', options: [] },
    ],
    workflows: [],
    cameras: [],
    processings: [],
  };

  componentDidMount() {
    this.props.getAlerts({
      size: this.props.alerts.size,
      page: this.props.alerts.page,
      sort: `timestamp-${this.props.alerts.timestamp},eventType-${this.props.alerts.eventSort}`,
    });
    this.props.getCameras(
      {},
      {
        onSuccess: (cameras) => {
          this.setState({ cameras: cameras.items });
          this.props.getWorkflows(
            {},
            {
              onSuccess: (wfs) => {
                this.props.getWorkflows(
                  { size: wfs.total, page: 0 },
                  {
                    onSuccess: (workflows) => {
                      this.setState({ workflows: workflows.items });
                      this.props.getProcessingTypes({
                        onSuccess: (items) => {
                          this.setState({ processings: items });
                          this.setState({
                            filters: [
                              {
                                title: 'Workflow',
                                options: workflows.items.map((item) => ({
                                  name: item.name,
                                  value: item.uuid,
                                })),
                              },
                              {
                                title: 'Camera',
                                options: cameras.items.map((item) => ({ name: item.name, value: item.uuid })),
                              },
                              {
                                title: 'Processing Type',
                                options: items.map((item) => ({ name: item.label, value: item.value })),
                              },
                              {
                                title: 'Event Type',
                                options: eventTypes,
                              },
                            ],
                          });
                        },
                      });
                    },
                  }
                );
              },
            }
          );
        },
      }
    );
  }

  componentDidUpdate(prevProps) {
    const { workflow, camera, processing, eventType, page, eventSort, timestamp } = this.props.alerts;
    (prevProps.alerts.workflow !== workflow ||
      prevProps.alerts.camera !== camera ||
      prevProps.alerts.processing !== processing ||
      prevProps.alerts.eventType !== eventType ||
      prevProps.alerts.page !== page ||
      prevProps.alerts.eventSort !== eventSort ||
      prevProps.alerts.timestamp !== timestamp) &&
      this.props.getAlerts({
        cameraUuid: camera && camera,
        workflowUuid: workflow && workflow,
        eventType: eventType && eventType,
        processingType: processing && processing,
        size: this.props.alerts.size,
        page: page,
        sort: `timestamp-${timestamp},eventType-${eventSort}`,
      });
  }

  toggleModal = (modalName) => {
    this.setState({
      [modalName]: {
        ...this.state[modalName],
        isOpen: !this.state[modalName].isOpen,
      },
    });
  };

  closeModal = (modalName) => {
    this.setState({ [modalName]: { ...defaultModal } });
  };

  createRows = (rows) => {
    return rows.map((alert) => ({
      id: alert.uuid,
      cells: [
        {
          label: '',
          cellComponent: (
            <Link
              to={
                moment().valueOf() - alert.eventTimestamp < ONE_DAY_IN_MS
                  ? { pathname: `${DASHBOARD}/${alert.camera.uuid}`, state: { alert } }
                  : {}
              }
            >
              <div
                className="alerts__table-avatar-wrapper"
                onClick={() =>
                  moment().valueOf() - alert.eventTimestamp > ONE_DAY_IN_MS
                    ? NotificationManager.info(
                        'We only have 24 hours of recording data',
                        "Sorry, You can't follow the link",
                        3000
                      )
                    : window.alert('false')
                }
              >
                <Avatar
                  className="alerts__table-avatar"
                  key={alert.uuid}
                  avatarUrl={alert.thumbUrl}
                  width={24}
                  fontSize={10}
                />
              </div>
            </Link>
          ),
        },
        {
          label: 'Workflow',
          cellComponent: this.state.workflows.find((item) => item.uuid === alert.workflow.uuid)?.name,
        },
        {
          label: 'Camera',
          cellComponent: this.state.cameras.find((item) => item.uuid === alert.camera.uuid)?.name,
        },
        {
          label: 'Event Type',
          cellComponent: alert.eventType ? alert.eventType : '-',
        },
        {
          label: 'Time',
          cellComponent: moment(alert.eventTimestamp).format('MMMM Do YYYY, h:mm:ss A'),
        },
      ],
    }));
  };

  render() {
    const headerOptions = [
      {
        label: '',
      },
      {
        label: 'Workflow',
      },
      {
        label: 'Camera',
      },
      {
        label: 'Type',
        sortable: true,
        onSortClick: () =>
          this.props.changeAlertValue('eventSort', this.props.alerts.eventSort === 'desc' ? 'asc' : 'desc'),
      },
      {
        label: 'Time',
        sortable: true,
        onSortClick: () =>
          this.props.changeAlertValue('timestamp', this.props.alerts.timestamp === 'desc' ? 'asc' : 'desc'),
      },
    ];

    const changeFilter = (title, key) => {
      switch (title) {
        case 'Workflow':
          this.props.changeAlertValue('workflow', key === 'All' ? null : key);
          break;
        case 'Camera':
          this.props.changeAlertValue('camera', key === 'All' ? null : key);
          break;
        case 'Processing Type':
          this.props.changeAlertValue('processing', key === 'All' ? null : key);
          break;
        case 'Event Type':
          this.props.changeAlertValue('eventType', key === 'All' ? null : key);
          break;
        default: {
        }
      }
    };
    const {
      list: { items, total },
      isLoading,
    } = this.props.alerts;
    const { size, page } = this.props.alerts;
    return (
      <>
        <Table
          rows={this.createRows(items)}
          headerOptions={headerOptions}
          title={i18n.t('nav:alerts')}
          totalRows={total}
          isLoading={isLoading}
          filters={this.state.filters}
          size={size}
          page={page}
          changePage={(page) => this.props.changeAlertValue('page', page)}
          onChangeFilters={(title, key) => changeFilter(title, key)}
          hideAddButton={true}
        />
        <NotificationContainer enterTimeout={400} leaveTimeout={400} />
      </>
    );
  }
}

AlertsList.propTypes = {
  usersList: PropTypes.array,
};

const mapStateToProps = ({ alerts }) => {
  return { alerts };
};

const mapDispatchToProps = (dispatch) => ({
  getAlerts: (type, params) => dispatch(actions.getAlerts(type, params)),
  getWorkflows: (params, actions) => dispatch(workflowActions.getWorkflows(params, actions)),
  getProcessingTypes: (actions) => dispatch(schemasActions.getProcessingTypes(actions)),
  getCameras: (params, actions) => dispatch(cameraActions.getCameras(params, actions)),
  changeAlertValue: (title, key) => dispatch(actions.changeAlertValue(title, key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsList);
