import { takeEvery, call, put, select, all } from 'redux-saga/effects';

import * as ActionTypes from '../constants/roles.constants';
import * as api from '../api/roles';

export function* getRoles({ payload, actions = {} }) {
  const roles = yield select((s) => s.roles);
  try {
    const response = yield call(api.getRoles, { ...roles.query, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_ROLES_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ROLES_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getRole({ payload, actions = {} }) {
  try {
    const response = yield call(api.getRole, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_ROLE_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ROLE_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getAuthorities({ payload, actions = {} }) {
  try {
    const response = yield call(api.getAuthorities, payload.uuid);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_AUTHORITIES_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_AUTHORITIES_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addRole({ payload, actions = {} }) {
  try {
    const response = yield call(api.addRole, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_ROLE_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_ROLE_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateRole({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateRole, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_ROLE_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_ROLE_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteRole({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteRole, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_ROLE_SUCCESS, payload: payload });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_ROLE_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* rolesSagas() {
  yield all([
    takeEvery(ActionTypes.GET_ROLES, getRoles),
    takeEvery(ActionTypes.GET_AUTHORITIES, getAuthorities),
    takeEvery(ActionTypes.GET_ROLE, getRole),
    takeEvery(ActionTypes.ADD_ROLE, addRole),
    takeEvery(ActionTypes.UPDATE_ROLE, updateRole),
    takeEvery(ActionTypes.DELETE_ROLE, deleteRole),
  ]);
}
