import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AddNameModal from './AddNameModal';

import * as drawsHelper from '../../utils/draws';
import img from '../../assets/images/doubleChevron.svg';

const DrawingPanel = ({
  id,
  frame,
  className,
  elements,
  setElements,
  elementOptions,
  setElementOptions,
  zones,
  getEquipments,
}) => {
  const [canvas, setCanvas] = useState(null);
  const [canvasContext, setCanvasContext] = useState(null);
  const canvasContainer = useRef(null);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [toolType, setToolType] = useState('line');
  const [action, setAction] = useState('none');
  const [selectedElementIndex, setSelectedElementIndex] = useState(null);
  const [movingOffsets, setMovingOffsets] = useState({ offsetX: 0, offsetY: 0 });
  const [fullScreen, setFullScreen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ isOpen: false, options: [] });
  const [entrypointExpected, setEntrypointExpected] = useState(false);
  const [hintMessage, setHintMessage] = useState('');

  const [rerender, setRerender] = useState(false);
  const [lastWidth, setLastWidth] = useState(1);

  useEffect(() => {
    const canvas = document.getElementById(`canvas${id}`);

    setCanvas(canvas);
    setCanvasContext(canvas.getContext('2d'));
  }, []);

  useEffect(() => {
    if (lastWidth === 0 && !rerender) {
      setRerender(false);
    }
    if (lastWidth !== canvasContainer.current.clientWidth) {
      setRerender(true);
      setLastWidth(canvasContainer.current.clientWidth);
    }

    if (canvasContext) {
      drawElements();
    }
  });

  useEffect(() => {
    const updatedElements = elements.map((element, elIndex) => {
      if (element.type === 'polygon') {
        const updatedPoints = element.points.map((point, i) => ({
          x: canvasContainer.current.clientWidth * element.relPoints[i].x,
          y: canvasContainer.current.clientHeight * element.relPoints[i].y,
        }));
        return drawsHelper.createPolygonElement(
          element.name,
          updatedPoints,
          {},
          element.type,
          canvasContainer.current.clientWidth,
          canvasContainer.current.clientHeight
        );
      } else if (element.type === 'doublePolygon') {
        const updatedPoints = element.points.map((point, i) => ({
          x: canvasContainer.current.clientWidth * element.relPoints[i].x,
          y: canvasContainer.current.clientHeight * element.relPoints[i].y,
        }));
        const updatedEntrypoint = element.entrypoint.map((point, i) => ({
          x: canvasContainer.current.clientWidth * element.relEntrypoint[i].x,
          y: canvasContainer.current.clientHeight * element.relEntrypoint[i].y,
        }));
        return drawsHelper.createDoublePolygonElement(
          element.name,
          updatedPoints,
          updatedEntrypoint,
          {},
          element.type,
          canvasContainer.current.clientWidth,
          canvasContainer.current.clientHeight
        );
      } else {
        return drawsHelper.createElement(
          element.name,
          canvasContainer.current.clientWidth * element.relative.x1,
          canvasContainer.current.clientHeight * element.relative.y1,
          canvasContainer.current.clientWidth * element.relative.x2,
          canvasContainer.current.clientHeight * element.relative.y2,
          element.type,
          canvasContainer.current.clientWidth,
          canvasContainer.current.clientHeight
        );
      }
    });
    setElements(updatedElements);
  }, [fullScreen]);

  useEffect(() => {
    if (zones) {
      const createdElements = [];
      Object.keys(zones).forEach((key) => {
        switch (key) {
          case 'lines':
            if (zones[key].length) {
              zones[key].forEach((line) => {
                createdElements.push(
                  drawsHelper.createElement(
                    line.name,
                    canvasContainer.current.clientWidth * line.endPoints[0].x,
                    canvasContainer.current.clientHeight * line.endPoints[0].y,
                    canvasContainer.current.clientWidth * line.endPoints[1].x,
                    canvasContainer.current.clientHeight * line.endPoints[1].y,
                    'line',
                    canvasContainer.current.clientWidth,
                    canvasContainer.current.clientHeight
                  )
                );
              });
            }
            break;
          case 'roi':
            if (zones[key].length) {
              zones[key].forEach((rectangle) => {
                createdElements.push(
                  drawsHelper.createElement(
                    rectangle.name,
                    canvasContainer.current.clientWidth * rectangle.x,
                    canvasContainer.current.clientHeight * rectangle.y,
                    canvasContainer.current.clientWidth * (rectangle.x + rectangle.width),
                    canvasContainer.current.clientHeight * (rectangle.y + rectangle.height),
                    'rectangle',
                    canvasContainer.current.clientWidth,
                    canvasContainer.current.clientHeight
                  )
                );
              });
            }
            break;
          case 'zoi':
            if (zones[key].length) {
              zones[key].forEach((polygon) => {
                const updatedPoints = polygon.points.map((point, i) => ({
                  x: canvasContainer.current.clientWidth * point.x,
                  y: canvasContainer.current.clientHeight * point.y,
                }));
                if (polygon.entrypoint.length) {
                  const updatedEntrypoint = polygon.entrypoint.map((point, i) => ({
                    x: canvasContainer.current.clientWidth * point.x,
                    y: canvasContainer.current.clientHeight * point.y,
                  }));
                  createdElements.push(
                    drawsHelper.createDoublePolygonElement(
                      polygon.name,
                      updatedPoints,
                      updatedEntrypoint,
                      {},
                      'doublePolygon',
                      canvasContainer.current.clientWidth,
                      canvasContainer.current.clientHeight
                    )
                  );
                } else {
                  createdElements.push(
                    drawsHelper.createPolygonElement(
                      polygon.name,
                      updatedPoints,
                      {},
                      'polygon',
                      canvasContainer.current.clientWidth,
                      canvasContainer.current.clientHeight
                    )
                  );
                }
              });
            }
            break;
        }
      });
      setElements(createdElements);
    }
  }, [imgLoaded]);

  const updateElement = (index, name, x1, y1, x2, y2, type, clientWidth, clientHeight) => {
    const updatedElement = drawsHelper.createElement(name, x1, y1, x2, y2, type, clientWidth, clientHeight);

    const elementsCopy = [...elements];
    elementsCopy[index] = updatedElement;
    setElements(elementsCopy);
  };

  const updatePolygonElement = (index, name, points, potentialPoint, type, clientWidth, clientHeight) => {
    const updatedElement = drawsHelper.createPolygonElement(
      name,
      points,
      potentialPoint,
      type,
      clientWidth,
      clientHeight
    );

    const elementsCopy = [...elements];
    elementsCopy[index] = updatedElement;
    setElements(elementsCopy);
  };

  const updateDoublePolygonElement = (
    index,
    name,
    points,
    entrypoint,
    potentialPoint,
    type,
    clientWidth,
    clientHeight
  ) => {
    const updatedElement = drawsHelper.createDoublePolygonElement(
      name,
      points,
      entrypoint,
      potentialPoint,
      type,
      clientWidth,
      clientHeight
    );

    const elementsCopy = [...elements];
    elementsCopy[index] = updatedElement;
    setElements(elementsCopy);
  };

  const handleMouseDown = (event) => {
    const {
      target: { clientWidth, clientHeight },
      nativeEvent: { offsetX, offsetY },
    } = event;

    if (toolType === 'selection') {
      const index = drawsHelper.getElementIndexAtPosition(offsetX, offsetY, elements);
      if (index > -1) {
        if (elements[index].type !== 'polygon' && elements[index].type !== 'doublePolygon') {
          const movingOffsetX = offsetX - elements[index].x1;
          const movingOffsetY = offsetY - elements[index].y1;
          setSelectedElementIndex(index);
          setMovingOffsets({ offsetX: movingOffsetX, offsetY: movingOffsetY });
          setAction('moving');
        } else {
          setSelectedElementIndex(index);
          setMovingOffsets({ offsetX, offsetY });
          setAction('moving');
        }
      }
    } else if (toolType === 'deletion') {
      const index = drawsHelper.getElementIndexAtPosition(offsetX, offsetY, elements);
      if (index > -1) {
        const elementsCopy = [...elements];
        elementsCopy.splice(index, 1);
        setElements(elementsCopy);
      }
    } else {
      let element = {};
      if (toolType === 'polygon') {
        if (action === 'polygonDrawing') {
          const index = elements.length - 1;
          const points = elements[index].points;
          let potentialPoint = {};
          if (drawsHelper.isNearPoint(offsetX, offsetY, points[0].x, points[0].y)) {
            points.push({ x: points[0].x, y: points[0].y });
            setModalInfo({ isOpen: true, options: ['equipment'] });
            setAction('none');
          } else {
            points.push({ x: offsetX, y: offsetY });
            potentialPoint = { x: offsetX, y: offsetY };
          }
          updatePolygonElement(index, '', points, potentialPoint, toolType, clientWidth, clientHeight);
        } else {
          element = drawsHelper.createPolygonElement(
            '',
            [{ x: offsetX, y: offsetY }],
            {},
            toolType,
            clientWidth,
            clientHeight
          );
          setElements([...elements, element]);
          setAction('polygonDrawing');
        }
      } else if (toolType === 'doublePolygon') {
        if (action === 'polygonDrawing') {
          const index = elements.length - 1;
          const points = elements[index].points;
          let potentialPoint = {};
          if (drawsHelper.isNearPoint(offsetX, offsetY, points[0].x, points[0].y)) {
            points.push({ x: points[0].x, y: points[0].y });
            setModalInfo({ isOpen: true, options: [] });
            setAction('none');
            setEntrypointExpected(true);
            setHintMessage('Draw the area where the queue starts');
          } else {
            points.push({ x: offsetX, y: offsetY });
            potentialPoint = { x: offsetX, y: offsetY };
          }
          updateDoublePolygonElement(
            index,
            '',
            points,
            [],
            potentialPoint,
            toolType,
            clientWidth,
            clientHeight
          );
        } else if (action === 'entrypointDrawing') {
          const index = elements.length - 1;
          const name = elements[index].name;
          const points = elements[index].points;
          const entrypoint = elements[index].entrypoint;
          let potentialPoint = {};
          if (drawsHelper.isNearPoint(offsetX, offsetY, entrypoint[0].x, entrypoint[0].y)) {
            entrypoint.push({ x: entrypoint[0].x, y: entrypoint[0].y });
            setAction('none');
            setHintMessage('Draw the queue area');
          } else {
            entrypoint.push({ x: offsetX, y: offsetY });
            potentialPoint = { x: offsetX, y: offsetY };
          }
          updateDoublePolygonElement(
            index,
            name,
            points,
            entrypoint,
            potentialPoint,
            toolType,
            clientWidth,
            clientHeight
          );
        } else {
          if (entrypointExpected) {
            const index = elements.length - 1;
            const name = elements[index].name;
            const points = elements[index].points;
            updateDoublePolygonElement(
              index,
              name,
              points,
              [{ x: offsetX, y: offsetY }],
              {},
              toolType,
              clientWidth,
              clientHeight
            );
            setAction('entrypointDrawing');
            setEntrypointExpected(false);
          } else {
            element = drawsHelper.createDoublePolygonElement(
              '',
              [{ x: offsetX, y: offsetY }],
              [],
              {},
              toolType,
              clientWidth,
              clientHeight
            );
            setElements([...elements, element]);
            setAction('polygonDrawing');
          }
        }
      } else {
        element = drawsHelper.createElement(
          '',
          offsetX,
          offsetY,
          offsetX,
          offsetY,
          toolType,
          clientWidth,
          clientHeight
        );
        setElements([...elements, element]);
        setAction('drawing');
      }
    }
  };

  const handleMouseMove = (event) => {
    const {
      target: { clientWidth, clientHeight },
      nativeEvent: { offsetX, offsetY },
    } = event;

    if (toolType === 'selection') {
      const elIndex = drawsHelper.getElementIndexAtPosition(offsetX, offsetY, elements);
      event.target.style.cursor = elIndex === -1 ? 'default' : 'move';
    } else {
      event.target.style.cursor = 'default';
    }

    if (action === 'drawing') {
      const index = elements.length - 1;
      const { x1, y1 } = elements[index];
      updateElement(index, '', x1, y1, offsetX, offsetY, toolType, clientWidth, clientHeight);
    } else if (action === 'moving') {
      if (
        elements[selectedElementIndex].type !== 'polygon' &&
        elements[selectedElementIndex].type !== 'doublePolygon'
      ) {
        const { name, x1, x2, y1, y2, type } = elements[selectedElementIndex];
        const width = x2 - x1;
        const height = y2 - y1;
        const newX = offsetX - movingOffsets.offsetX;
        const newY = offsetY - movingOffsets.offsetY;

        updateElement(
          selectedElementIndex,
          name,
          newX,
          newY,
          newX + width,
          newY + height,
          type,
          clientWidth,
          clientHeight
        );
      } else {
        if (elements[selectedElementIndex].type === 'polygon') {
          const { name, points, type } = elements[selectedElementIndex];
          const shiftX = offsetX - movingOffsets.offsetX;
          const shiftY = offsetY - movingOffsets.offsetY;
          const updatedPoints = points.map((point) => ({ x: point.x + shiftX, y: point.y + shiftY }));
          updatePolygonElement(
            selectedElementIndex,
            name,
            updatedPoints,
            {},
            type,
            clientWidth,
            clientHeight
          );
          setMovingOffsets({ offsetX, offsetY });
        } else {
          const { name, points, entrypoint, type } = elements[selectedElementIndex];
          const shiftX = offsetX - movingOffsets.offsetX;
          const shiftY = offsetY - movingOffsets.offsetY;
          const updatedPoints = points.map((point) => ({ x: point.x + shiftX, y: point.y + shiftY }));
          const updatedEntrypoint = entrypoint.map((point) => ({ x: point.x + shiftX, y: point.y + shiftY }));
          updateDoublePolygonElement(
            selectedElementIndex,
            name,
            updatedPoints,
            updatedEntrypoint,
            {},
            type,
            clientWidth,
            clientHeight
          );
          setMovingOffsets({ offsetX, offsetY });
        }
      }
    } else if (action === 'polygonDrawing') {
      const index = elements.length - 1;
      const points = elements[index].points;
      if (toolType === 'doublePolygon') {
        updateDoublePolygonElement(
          index,
          '',
          points,
          [],
          { x: offsetX, y: offsetY },
          toolType,
          clientWidth,
          clientHeight
        );
      } else {
        updatePolygonElement(
          index,
          '',
          points,
          { x: offsetX, y: offsetY },
          toolType,
          clientWidth,
          clientHeight
        );
      }
    } else if (action === 'entrypointDrawing') {
      const index = elements.length - 1;
      const name = elements[index].name;
      const points = elements[index].points;
      const entrypoint = elements[index].entrypoint;
      updateDoublePolygonElement(
        index,
        name,
        points,
        entrypoint,
        { x: offsetX, y: offsetY },
        toolType,
        clientWidth,
        clientHeight
      );
    }
  };

  const handleMouseUp = (event) => {
    if (action !== 'polygonDrawing' && action !== 'entrypointDrawing') {
      if (action === 'drawing') {
        setModalInfo({ isOpen: true, options: [] });
      }
      setAction('none');
      setSelectedElementIndex(null);
    }
  };

  const drawElements = () => {
    canvasContext.clearRect(0, 0, canvas.width, canvas.height);
    canvasContext.strokeStyle = 'yellow';
    canvasContext.lineWidth = 2;

    elements.forEach((element) => {
      canvasContext.stroke(element.figurePath);
    });
  };

  const handleSubmitAddNameModal = (data) => {
    setModalInfo({ isOpen: false, options: [] });
    const index = elements.length - 1;
    const elementsCopy = [...elements];
    elementsCopy[index].name = data.name;

    setElements(elementsCopy);
    setElementOptions({ ...elementOptions, [data.name]: data.options });
  };

  const handleCloseAddNameModal = () => {
    setModalInfo({ isOpen: false, options: [] });
    setEntrypointExpected(false);
    toolType === 'doublePolygon' && setHintMessage('Draw the queue area');
    if (elements.length) {
      const elementsCopy = [...elements];
      elementsCopy.splice(-1);
      setElements(elementsCopy);
    }
  };

  const setToolTypeWithReset = (type) => {
    if (entrypointExpected) {
      const elementsCopy = [...elements];
      elementsCopy.splice(-1);
      setElements(elementsCopy);
      setEntrypointExpected(false);
    }
    if (type === 'doublePolygon') {
      setHintMessage('Draw the queue area');
    } else {
      setHintMessage('');
    }
    setToolType(type);
  };

  return (
    <div className={cx('drawing-panel', { [className]: className, 'drawing-panel_full-screen': fullScreen })}>
      <div className="drawing-panel__tools mb-10">
        <input
          type="radio"
          id={`line${id}`}
          checked={toolType === 'line'}
          onChange={() => setToolTypeWithReset('line')}
        />
        <label htmlFor={`line${id}`}>Line</label>

        <input
          type="radio"
          id={`rectangle${id}`}
          checked={toolType === 'rectangle'}
          onChange={() => setToolTypeWithReset('rectangle')}
        />
        <label htmlFor={`rectangle${id}`}>ROI</label>

        <input
          type="radio"
          id={`polygon${id}`}
          checked={toolType === 'polygon'}
          onChange={() => setToolTypeWithReset('polygon')}
        />
        <label htmlFor={`polygon${id}`}>ZOI</label>

        <input
          type="radio"
          id={`doublePolygon${id}`}
          checked={toolType === 'doublePolygon'}
          onChange={() => setToolTypeWithReset('doublePolygon')}
        />
        <label htmlFor={`doublePolygon${id}`}>Queue ZOI</label>

        <input
          type="radio"
          id={`selection${id}`}
          checked={toolType === 'selection'}
          onChange={() => setToolTypeWithReset('selection')}
        />
        <label htmlFor={`selection${id}`}>Moving</label>

        <input
          type="radio"
          id={`deletion${id}`}
          checked={toolType === 'deletion'}
          onChange={() => setToolTypeWithReset('deletion')}
        />
        <label htmlFor={`deletion${id}`}>Deletion</label>

        <button className="drawing-panel__tools__button" onClick={() => setFullScreen((prev) => !prev)}>
          {fullScreen ? <FontAwesomeIcon icon="compress" /> : <FontAwesomeIcon icon="expand" />}
        </button>
      </div>
      {hintMessage && (
        <div className="mb-10">
          <p>Hint: {hintMessage}</p>
        </div>
      )}
      <div ref={canvasContainer}>
        <canvas
          className="drawing-panel__canvas"
          id={`canvas${id}`}
          style={{ borderRadius: '6px' }}
          width={canvasContainer.current && canvasContainer.current.clientWidth}
          height={canvasContainer.current && canvasContainer.current.clientHeight}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        />
        {frame ? (
          <img src={frame} alt="Camera Frame" onLoad={() => setImgLoaded(true)} />
        ) : (
          <p>No selected camera</p>
        )}
      </div>

      {modalInfo.isOpen && (
        <AddNameModal
          className="modal_small"
          isOpen={modalInfo.isOpen}
          options={modalInfo.options}
          existingNames={elements ? elements.map((element) => element.name) : []}
          getEquipments={getEquipments}
          onSubmit={handleSubmitAddNameModal}
          onClose={handleCloseAddNameModal}
        />
      )}
    </div>
  );
};

export default DrawingPanel;
