import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../components/Table';
import { connect } from 'react-redux';
import i18n from '../../i18n';

import DropdownDotMenu from '../../components/DropdownDotMenu';
import { userStatuses } from '../../constants/userConstants';
import * as rolesActions from '../../actions/roles.actions';
import * as usersActions from '../../actions/users.actions';

// mocks
import ModalAdd from './Modals/ModalAdd';
import ModalEdit from './Modals/ModalEdit';
import Avatar from '../../components/Avatar';
import { Link } from 'react-router-dom';
import { USERLIMITS } from '../../constants/routes.constants';

const MODAL_ADD = 'modalAdd';
const MODAL_EDIT = 'modalEdit';
const MODAL_DELETE = 'modalDelete';
const defaultModal = {
  isOpen: false,
  errorMsg: '',
  user: {},
  resetError: function () {
    this.errorMsg = '';
  },
};

class UsersList extends Component {
  state = {
    modalAdd: false,
    modalEdit: false,
    modalDelete: { ...defaultModal },
    nullParams: [],
  };

  componentDidMount() {
    this.props.getUsers();
    this.props.getRoles();
  }

  componentDidUpdate(prev) {
    prev.page !== this.props.page && this.props.getUsers();
  }

  relocate = (uuid) => {
    window.location.assign(`${USERLIMITS}/${uuid}`);
  };

  addUser = () => {
    this.setState({ nullParams: [] });
    const userInfo = this.props.modalInf;
    const roless = this.props.roles.list.items.filter((role) => userInfo.rolesItems.includes(role.uuid));

    const media = new FormData();
    media.append('file', userInfo.avatarFile);
    media.append('mediaTarget', 'AVATAR');
    if (
      userInfo.firstName !== '' &&
      userInfo.lastName !== '' &&
      userInfo.userName !== '' &&
      userInfo.avatar !== '' &&
      userInfo.phone !== '' &&
      userInfo.password !== '' &&
      userInfo.rolesItems.length !== 0
    ) {
      this.props.uploadMedia(media, {
        onSuccess: (image) =>
          this.props.addUser(
            {
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
              username: userInfo.userName,
              phone: userInfo.phone,
              password: userInfo.password,
              roles: roless,
              avatar: image,
            },
            {
              onSuccess: (data) =>
                this.props.addRolesToUser(data.uuid, userInfo.rolesItems, {
                  onSuccess: () => {
                    this.props.getUsers();
                  },
                }),
            }
          ),
      });
      this.onClose();
    } else {
      userInfo.firstName === '' &&
        this.setState((prev) => ({ nullParams: [...prev.nullParams, 'firstName'] }));
      userInfo.lastName === '' && this.setState((prev) => ({ nullParams: [...prev.nullParams, 'lastName'] }));
      userInfo.userName === '' && this.setState((prev) => ({ nullParams: [...prev.nullParams, 'userName'] }));
      userInfo.avatar === '' && this.setState((prev) => ({ nullParams: [...prev.nullParams, 'avatar'] }));
      userInfo.phone === '' && this.setState((prev) => ({ nullParams: [...prev.nullParams, 'phone'] }));
      userInfo.password === '' && this.setState((prev) => ({ nullParams: [...prev.nullParams, 'password'] }));
      userInfo.rolesItems.length === 0 &&
        this.setState((prev) => ({ nullParams: [...prev.nullParams, 'roles'] }));
    }
  };

  updateUser = () => {
    this.setState({ nullParams: [] });
    const userInfo = this.props.modalInf;
    const roless = this.props.roles.list.items.filter((role) => userInfo.rolesItems.includes(role.uuid));

    if (userInfo.avatarFile !== undefined) {
      const media = new FormData();
      media.append('file', userInfo.avatarFile);
      media.append('mediaTarget', 'AVATAR');
      this.props.uploadMedia(media, {
        onSuccess: (image) => {
          this.props.updateUser(
            {
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
              username: userInfo.userName,
              phone: userInfo.phone,
              password: userInfo.password,
              roles: roless,
              avatar: image,
              uuid: userInfo.uuid,
            },
            {
              onSuccess: (data) => {
                this.props.addRolesToUser(data.uuid, userInfo.rolesItems, {
                  onSuccess: () => {
                    this.props.getUsers();
                    this.props.changeModalValue('modalInf', {
                      firstName: '',
                      lastName: '',
                      userName: '',
                      password: '',
                      phone: '',
                      avatar: '',
                      avatarFile: null,
                      rolesItems: [],
                    });
                  },
                });
              },
            }
          );
          this.onClose();
        },
      });
    } else if (
      userInfo.firstName !== '' &&
      userInfo.lastName !== '' &&
      userInfo.userName !== '' &&
      userInfo.avatar !== '' &&
      userInfo.phone !== '' &&
      userInfo.password !== '' &&
      roless !== []
    ) {
      this.props.updateUser(
        {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          username: userInfo.userName,
          phone: userInfo.phone,
          password: userInfo.password,
          roles: roless,
          avatar: userInfo.image,
          uuid: userInfo.uuid,
        },
        {
          onSuccess: (data) => {
            this.props.addRolesToUser(data.uuid, userInfo.rolesItems, {
              onSuccess: () => {
                this.props.getUsers();
                this.props.changeModalValue('modalInf', {
                  firstName: '',
                  lastName: '',
                  userName: '',
                  email: '',
                  password: '',
                  phone: '',
                  avatar: '',
                  avatarFile: null,
                  rolesItems: [],
                });
              },
            });
          },
        }
      );
      this.onClose();
    } else {
      userInfo.firstName === '' &&
        this.setState((prev) => ({ nullParams: [...prev.nullParams, 'firstName'] }));
      userInfo.lastName === '' && this.setState((prev) => ({ nullParams: [...prev.nullParams, 'lastName'] }));
      userInfo.userName === '' && this.setState((prev) => ({ nullParams: [...prev.nullParams, 'userName'] }));
      userInfo.avatar === '' && this.setState((prev) => ({ nullParams: [...prev.nullParams, 'avatar'] }));
      userInfo.phone === '' && this.setState((prev) => ({ nullParams: [...prev.nullParams, 'phone'] }));
      userInfo.password === '' && this.setState((prev) => ({ nullParams: [...prev.nullParams, 'password'] }));
      userInfo.rolesItems.length === 0 &&
        this.setState((prev) => ({ nullParams: [...prev.nullParams, 'roles'] }));
    }
  };
  onClose = () => {
    this.setState({ modalEdit: false, modalAdd: false, nullParams: [] });
    this.props.changeModalValue('modalInf', {
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
      phone: '',
      avatar: '',
      avatarFile: null,
      rolesItems: [],
    });
  };
  editUser = (uuid) => {
    this.props.getUser(uuid, {
      onSuccess: (data) => {
        this.props.changeModalValue('modalInf', {
          firstName: data.firstName,
          lastName: data.lastName,
          userName: data.username,
          email: data.email,
          password: data.password,
          phone: data.phone,
          uuid: data.uuid,
          image: data.avatar,
          avatar: data.avatar.url,
          rolesItems: data.roles?.map((role) => role.uuid),
        });
        this.setState({ modalEdit: true });
      },
    });
  };

  toggleModal = (modalName) => {
    this.setState({
      [modalName]: {
        ...this.state[modalName],
        isOpen: !this.state[modalName].isOpen,
      },
    });
  };

  closeModal = (modalName) => {
    this.setState({ [modalName]: { ...defaultModal } });
  };

  createRows = (rows) => {
    return rows.map((user) => ({
      id: user.uuid,
      cells: [
        {
          label: '',
          cellComponent: (
            <div className="alerts__table-avatar-wrapper">
              <Avatar
                className="alerts__table-avatar"
                key={user.uuid}
                avatarUrl={user.avatar?.url ? user.avatar.url : ''}
                width={24}
                fontSize={10}
              />
            </div>
          ),
        },
        {
          label: user.username,
          cellComponent: <h5 className="table__cell table__cell--bold">{user.username}</h5>,
        },
        {
          label: user.email,
        },
        {
          label: user.roles.map((role) => role.name + ' '),
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                { name: 'User Limits', onClick: () => this.relocate(user.uuid) },
                { name: 'Edit', onClick: () => this.editUser(user.uuid) },
                {
                  name: 'Delete',
                  onClick: () => {
                    this.props.deleteUser(user.uuid, { onSuccess: () => this.props.getUsers() });
                  },
                },
              ]}
            />
          ),
        },
      ],
    }));
  };

  render() {
    const headerOptions = [
      {
        label: '',
      },
      {
        label: 'Username',
      },
      {
        label: 'Email',
      },
      {
        label: 'Roles',
      },
      {
        label: '',
      },
    ];

    const { modalAdd, modalEdit, modalDelete } = this.state;

    return (
      <>
        <Table
          rows={this.createRows(this.props.items)}
          headerOptions={headerOptions}
          title={i18n.t('nav:users')}
          totalRows={this.props.total}
          size={this.props.size}
          page={this.props.page}
          changePage={(page) => this.props.changeUserValue(page)}
          isLoading={this.props.isLoading}
          onClick={() => this.setState({ modalAdd: true })}
        />
        {modalAdd && (
          <ModalAdd
            loading={false}
            isOpen={modalAdd}
            add={true}
            roles={this.props.roles.list.items}
            params={this.props.modalInf}
            nullParams={this.state.nullParams}
            changeValue={(name, value) => this.props.changeUserValue(name, value)}
            onSubmit={() => this.addUser()}
            onClose={() => this.onClose()}
            className="modal-users"
          />
        )}
        {modalEdit && (
          <ModalEdit
            loading={false}
            isOpen={modalEdit}
            roles={this.props.roles.list.items}
            showRoles={true}
            params={this.props.modalInf}
            nullParams={this.state.nullParams}
            changeValue={(name, value) => this.props.changeUserValue(name, value)}
            onSubmit={() => this.updateUser()}
            onClose={() => this.onClose()}
            className="modal-users"
          />
        )}
      </>
    );
  }
}

UsersList.propTypes = {
  usersList: PropTypes.array,
};

const mapStateToProps = ({ roles, users }) => {
  const {
    list: { items, total },
    query: { size, page },
    modalInf,
    isLoading,
  } = users;
  return {
    roles,
    items,
    modalInf,
    total,
    size,
    page,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRoles: (params, actions) => dispatch(rolesActions.getRoles(params, actions)),
  getUsers: (params, actions) => dispatch(usersActions.getUsers(params, actions)),
  getUser: (id, actions) => dispatch(usersActions.getUser(id, actions)),
  addUser: (params, actions) => dispatch(usersActions.addUser(params, actions)),
  updateUser: (params, actions) => dispatch(usersActions.updateUser(params, actions)),
  deleteUser: (uuid, actions) => dispatch(usersActions.deleteUser(uuid, actions)),
  changePageValue: (value) => dispatch(usersActions.changePageValue(value)),
  changeUserValue: (key, value) => dispatch(usersActions.changeUserValue(key, value)),
  changeModalValue: (key, value) => dispatch(usersActions.changeModalValue(key, value)),
  uploadMedia: (params, actions) => dispatch(usersActions.uploadMedia(params, actions)),
  addRolesToUser: (uuid, params, actions) => dispatch(usersActions.addRolesToUser(uuid, params, actions)),
  updateRolesToUser: (uuid, params, actions) =>
    dispatch(usersActions.updateRolesToUser(uuid, params, actions)),
  deleteRoleToUser: (uuid, roleUuid, actions) =>
    dispatch(usersActions.deleteRoleToUser(uuid, roleUuid, actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
