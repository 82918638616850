import { takeEvery, call, put, select, all } from 'redux-saga/effects';

import * as ActionTypes from '../constants/models.constants';
import * as api from '../api/models';

export function* getModels({ payload, actions = {} }) {
  const models = yield select((s) => s.model);
  try {
    const response = yield call(api.getModels, { ...models.query, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_MODELS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_MODELS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getModel({ payload, actions = {} }) {
  try {
    const response = yield call(api.getModel, payload.uuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addModel({ payload, actions = {} }) {
  try {
    const response = yield call(api.addModel, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_MODEL_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_MODEL_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateModel({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateModel, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_MODEL_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_MODEL_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteModel({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteModel, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_MODEL_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_MODEL_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* modelsSagas() {
  yield all([
    takeEvery(ActionTypes.GET_MODELS, getModels),
    takeEvery(ActionTypes.GET_MODEL, getModel),
    takeEvery(ActionTypes.ADD_MODEL, addModel),
    takeEvery(ActionTypes.UPDATE_MODEL, updateModel),
    takeEvery(ActionTypes.DELETE_MODEL, deleteModel),
  ]);
}
