export const GET_CAMERAS = 'GET_CAMERAS';
export const GET_CAMERAS_RESULT = 'GET_CAMERAS_RESULT';
export const GET_CAMERAS_ERROR = 'GET_CAMERAS_ERROR';

export const GET_CAMERAS_NEXT_PAGE = 'GET_CAMERAS_NEXT_PAGE';
export const GET_CAMERAS_NEXT_PAGE_RESULT = 'GET_CAMERAS_NEXT_PAGE_RESULT';
export const GET_CAMERAS_NEXT_PAGE_ERROR = 'GET_CAMERAS_NEXT_PAGE_ERROR';

export const GET_CAMERA = 'GET_CAMERA';
export const GET_CAMERA_RESULT = 'GET_CAMERA_RESULT';
export const GET_CAMERA_ERROR = 'GET_CAMERA_ERROR';

export const ADD_CAMERA = 'ADD_CAMERA';
export const ADD_CAMERA_SUCCESS = 'ADD_CAMERA_SUCCESS';
export const ADD_CAMERA_ERROR = 'ADD_CAMERA_ERROR';

export const UPDATE_CAMERA = 'UPDATE_CAMERA';
export const UPDATE_CAMERA_SUCCESS = 'UPDATE_CAMERA_SUCCESS';
export const UPDATE_CAMERA_ERROR = 'UPDATE_CAMERA_ERROR';

export const DELETE_CAMERA = 'DELETE_CAMERA';
export const DELETE_CAMERA_SUCCESS = 'DELETE_CAMERA_SUCCESS';
export const DELETE_CAMERA_ERROR = 'DELETE_CAMERA_ERROR';

export const GET_CAMERA_EVENTS = 'GET_CAMERA_EVENTS';
export const GET_CAMERA_EVENTS_RESULT = 'GET_CAMERA_EVENTS_RESULT';
export const GET_CAMERA_EVENTS_ERROR = 'GET_CAMERA_EVENTS_ERROR';

export const GET_CAMERA_EVENTS_GROUPED_TIMESTAMPS = 'GET_CAMERA_EVENTS_GROUPED_TIMESTAMPS';
export const GET_CAMERA_EVENTS_GROUPED_TIMESTAMPS_RESULT = 'GET_CAMERA_EVENTS_GROUPED_TIMESTAMPS_RESULT';
export const SET_CAMERA_EVENTS_TIMESTAMPS = 'SET_CAMERA_EVENTS_TIMESTAMPS';
export const GET_CAMERA_EVENTS_GROUPED_TIMESTAMPS_ERROR = 'GET_CAMERA_EVENTS_GROUPED_TIMESTAMPS_ERROR';

export const GET_CAMERA_ALERTS = 'GET_CAMERA_ALERTS';
export const GET_CAMERA_ALERTS_RESULT = 'GET_CAMERA_ALERTS_RESULT';
export const GET_CAMERA_ALERTS_ERROR = 'GET_CAMERA_ALERTS_ERROR';

export const ADD_CAMERA_ALERT = 'ADD_CAMERA_ALERT';
export const ADD_CAMERA_EVENT = 'ADD_CAMERA_EVENT';
export const RESET_CAMERA_EVENTS = 'RESET_CAMERA_EVENTS';

export const GET_CAMERA_FRAMES = 'GET_CAMERA_FRAMES';
export const GET_CAMERA_FRAMES_RESULT = 'GET_CAMERA_FRAMES_RESULT';
export const GET_CAMERA_FRAMES_ERROR = 'GET_CAMERA_FRAMES_ERROR';

export const GET_CAMERA_FRAMES_NEXT_PAGE = 'GET_CAMERA_FRAMES_NEXT_PAGE';
export const GET_CAMERA_FRAMES_NEXT_PAGE_RESULT = 'GET_CAMERA_FRAMES_NEXT_PAGE_RESULT';
export const GET_CAMERA_FRAMES_NEXT_PAGE_ERROR = 'GET_CAMERA_FRAMES_NEXT_PAGE_ERROR';

export const GET_CAMERA_RECORDINGS = 'GET_CAMERA_RECORDINGS';
export const GET_CAMERA_RECORDINGS_RESULT = 'GET_CAMERA_RECORDINGS_RESULT';
export const SET_CAMERA_RECORDINGS_LIST = 'SET_CAMERA_RECORDINGS_LIST';
export const GET_CAMERA_RECORDINGS_ERROR = 'GET_CAMERA_RECORDINGS_ERROR';
export const CREATE_CAMERA_RECORDINGS = 'CREATE_CAMERA_RECORDINGS';
export const EXTEND_CAMERA_RECORDINGS = 'EXTEND_CAMERA_RECORDINGS';
export const GET_DATASTREAM = 'GET_DATASTREAM';
export const GET_DATASTREAM_RESULT = 'GET_DATASTREAM_RESULT';
export const GET_DATASTREAM_ERROR = 'GET_DATASTREAM_ERROR';

export const GET_READY_HEATMAP = 'GET_READY_HEATMAP';
export const GET_READY_HEATMAP_RESULT = 'GET_READY_HEATMAP_RESULT';
export const GET_READY_HEATMAP_ERROR = 'GET_READY_HEATMAP_ERROR';

export const GET_READY_HEATMAP_FOR_QUEUES = 'GET_READY_HEATMAP_FOR_QUEUES';
export const GET_READY_HEATMAP_FOR_QUEUES_RESULT = 'GET_READY_HEATMAP_FOR_QUEUES_RESULT';
export const GET_READY_HEATMAP_FOR_QUEUES_ERROR = 'GET_READY_HEATMAP_FOR_QUEUES_ERROR';

export const SET_SUBSCRIBE_CAMERA = 'SET_SUBSCRIBE_CAMERA';
export const SET_UNSUBSCRIBE_CAMERA = 'SET_UNSUBSCRIBE_CAMERA';
export const SET_CAMERA_LIVE = 'SET_CAMERA_LIVE';
export const SET_CAMERA_LIVE_RESET = 'SET_CAMERA_LIVE_RESET';
export const SET_CAMERA_UNMOUNT = 'SET_CAMERA_UNMOUNT';

export const SET_ACTIVE_CAMERA_WORKFLOW = 'SET_ACTIVE_CAMERA_WORKFLOW';
export const SET_ACTIVE_CAMERA_ZONES = 'SET_ACTIVE_CAMERA_ZONES';
export const CHANGE_ACTIVE_CAMERA_OPTION = 'CHANGE_ACTIVE_CAMERA_OPTION';

export const GET_DASHBOARD_WIDGETS = 'GET_DASHBOARD_WIDGETS';
export const GET_DASHBOARD_WIDGETS_SUCCESS = 'GET_DASHBOARD_WIDGETS_SUCCESS';
export const GET_DASHBOARD_WIDGETS_ERROR = 'GET_DASHBOARD_WIDGETS_ERROR';

export const ADD_DASHBOARD_WIDGET = 'ADD_DASHBOARD_WIDGET';
export const ADD_DASHBOARD_WIDGET_SUCCESS = 'ADD_DASHBOARD_WIDGET_SUCCESS';

export const CHANGE_CAMERAS_VALUE = 'CHANGE_CAMERAS_VALUE';
export const CHANGE_CAMERAS_FILTERS = 'CHANGE_CAMERAS_FILTERS';
export const CHANGE_CAMERA_HEATMAP_VALUE = 'CHANGE_CAMERA_HEATMAP_VALUE';
export const CHANGE_WIDGETS_VALUE = 'CHANGE_WIDGETS_VALUE';
export const DELETE_WIDGET = 'DELETE_WIDGET';
export const DELETE_WIDGET_SUCCESS = 'DELETE_WIDGET_SUCCESS';
export const RESET_CAMERA_VIEW_WIDGETS = 'RESET_CAMERA_VIEW_WIDGETS';

export const ADD_FILTER = 'ADD_FILTER';
export const ADD_FILTER_SUCCESS = 'ADD_FILTER_SUCCESS';
export const ADD_FILTER_ERROR = 'ADD_FILTER_ERROR';

export const GET_FILTERS = 'GET_FILTERS';
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS';
export const GET_FILTERS_ERROR = 'GET_FILTERS_ERROR';

export const LIFE_STREAM_DRAWER_START = 'LIFE_STREAM_DRAWER_START';
export const LIFE_STREAM_DRAWER_STOP = 'LIFE_STREAM_DRAWER_STOP';

export const GET_LOCATIONS = 'GET_LOCATIONS';

export const VERIFY_CAMERA = 'VERIFY_CAMERA';
export const RTSP_REGEXP = /^(?:(rtsp|Rtsp|rtmp|Rtmp|RTMP|http|HTTP|https|HTTPS):\/\/)(.*)$/;
export const EVENT_TIME_FORMAT = 'h:mm:ss A, MMMM DD';
export const LOADING_LIMIT = 30000;
export const DEFAULT_VIDEO_RATIO = 1920 / 1080;

export const defaultOffsetsByFps = {
  1: -150,
  2: 90,
  3: 150,
  4: 170,
  5: 180,
  6: 200,
  7: 210,
  8: 220,
  9: 230,
  10: 240,
  11: 240,
  12: 240,
  13: 250,
  14: 250,
  15: 250,
  16: 260,
  17: 260,
  18: 260,
  19: 260,
  20: 260,
  default: 260,
};

export const CHANGE_CAMERA_VALUE = 'CHANGE_CAMERA_VALUE';

export const cameraRecognitionTypes = {
  MULTIPLE: 'multiple',
  SINGLE: 'single',
};

export const cameraStatuses = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const cameraStreamStatuses = {
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
  ERROR: 'ERROR',
};

export const widgetAnalytics = {
  activity: 'ZonesRealTimeActivityDurationAnalytics',
  queue: 'QueuesDetectorAnalytics',
  inout: 'LineCrossingObjectsCounting',
};

export const showObjects = [
  'SubjectsVectorAnalyzer',
  'SubjectsPlateAnalyzer',
  'QueuesDetectorAnalytics',
  'GroupsDetectorAnalytics',
  'MatchDetectedObjectsWithZones',
];

export const showObjectsByAlertType = [
  'GROUP_MIN_DISTANCE_DETECTED',
  'SMALL_GROUP_DETECTED',
  'LARGE_GROUP_DETECTED',
  'MIN_DISTANCE_IN_ZONE_DETECTED',
  'FACE_ALERT_OCCURRED',
  'FACE_DETECTED',
  'CAR_ALERT_OCCURRED',
  'CAR_DETECTED',
  'FACE_WITHOUT_MASK_DETECTED',
  'LARGE_QUEUE_DETECTED',
  'INSIDE_ZONE_DETECTED',
  'OUTSIDE_ZONE_DETECTED',
];

export const showObjectsByEventType = [
  'GROUP_MIN_DISTANCE',
  'SMALL_GROUP',
  'LARGE_GROUP',
  'KNOWN_FACE_VECTOR_SUBJECTS',
  'UNKNOWN_FACE_VECTOR_SUBJECTS',
  'KNOWN_LICENSE_PLATE_SUBJECTS',
  'UNKNOWN_LICENSE_PLATE_SUBJECTS',
  'FACE_WITHOUT_MASK',
  'LARGE_QUEUE',
  'DETECTIONS_INSIDE_ZONE',
  'DETECTIONS_OUTSIDE_ZONE',
];

export const showZones = [
  'ZonesActivityAnalytics',
  'ZonesActivityDurationAnalytics',
  'ZonesRealTimeActivityDurationAnalytics',
  'ZonesAccumulationAnalytics',
  'GroupsDetectorAnalytics',
  'MatchDetectedObjectsWithZones',
  'QueuesDetectorAnalytics',
];

export const showZonesByAlertType = [
  'ZONE_LOW_OCCUPANCY',
  'ZONE_HIGH_OCCUPANCY',
  'ZONE_MIN_DISTANCE',
  'ZONE_LOW_ACTIVITY',
  'ZONE_HIGH_ACTIVITY',
  'ZONE_ACTIVITY_DETECTED',
];

export const showZonesByEventType = [
  'LOW_OCCUPANCY_DETECTED',
  'HIGH_OCCUPANCY_DETECTED',
  'LOW_ACTIVITY_IN_ZONE_DETECTED',
  'HIGH_ACTIVITY_IN_ZONE_DETECTED',
  'ZONE_ACTIVITY_DETECTED',
];

export const showActivity = ['ZonesRealTimeActivityDurationAnalytics'];

export const showLines = ['LineCrossingObjectsCounting'];

export const showHeatmap = ['ZonesHeatMapGeneratorByDetections'];

export const showHeatmapQueues = ['ZonesHeatMapGeneratorByQueues'];

export const showQueues = ['QueuesDetectorAnalytics'];

export const showAccumulation = ['ZonesAccumulationAnalytics'];

export const showFaces = ['SubjectsVectorAnalyzer'];

export const showCars = ['SubjectsPlateAnalyzer'];

export const showGroups = ['GroupsDetectorAnalytics'];
