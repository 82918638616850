import React, { useMemo, useState, useRef, useEffect } from 'react';
import Select from 'react-select';

import dropdownArrow from '../../../assets/images/dropdownArrow.svg';

export function FormSelect({
  label,
  name,
  selected,
  options,
  onChange,
  onBlur,
  className,
  isSearch,
  errorMessage,
  disabled,
  onScrollToBottom,
  onInputChange,
}) {
  const [isFocus, setFocus] = useState(false);
  const [isCleared, setCleared] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setFocus(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  const handleInputChange = (search) => {
    onInputChange && onInputChange(search);
  };

  const handleChange = (value) => {
    name ? onChange(name, value) : onChange(value);
  };

  const handleBlur = (e) => {
    setFocus(false);
    onBlur && onBlur(e);
  };

  const DropdownIndicator = () => {
    return <img style={{ width: '14px', height: '14px' }} src={dropdownArrow} alt="arrow" />;
  };

  const customStyles = useMemo(() => ({
    option: (provided, state) => ({
      ...provided,
      padding: '8px 20px',
      fontWeight: state.data.fontWeight,
      cursor: 'pointer',
      backgroundColor: state.isSelected ? '#497a81' : 'transparent',
      ':active': {
        backgroundColor: state.isSelected ? '#497a81' : 'transparent',
      },
      ':hover': {
        backgroundColor: state.isSelected ? '#497a81' : '#2e3236',
      },
    }),
    menuList: (base) => ({
      ...base,
      marginRight: '1px',
      maxHeight: '150px',

      '::-webkit-scrollbar': {
        width: '8px',
        backgroundColor: '#2e3236',
      },
      '::-webkit-scrollbar-track': {
        borderRadius: '3px',
        backgroundColor: '#2e3236',
      },
      '::-webkit-scrollbar-thumb': {
        borderRadius: '3px',
        backgroundColor: '#497a81',
      },
    }),
    menu: (base) => ({
      ...base,
      position: 'absolute',
      width: '100%',
      maxHeight: '170px',
      padding: '10px 0px',
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)',
      zIndex: '20',
      backgroundColor: '#3c4248',
      borderRadius: '6px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
      zIndex: 1,
    }),
    input: (base) => ({
      ...base,
      color: 'white',
      width: '100%',
      marginTop: '16px',
      paddingLeft: '1px',
      cursor: 'text',
      zIndex: 1,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'white',
      padding: '15px 0px 0px 1px',
    }),
    control: (base, state) => ({
      ...base,
      width: '100%',
      height: '100%',
      minHeight: 'none',
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      borderRadius: '6px',
      border: 'none',
      cursor: 'default',
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      outline: 'none',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      padding: '0 13px',
    }),
  }));

  return (
    <div className={className} ref={selectRef}>
      <label
        htmlFor={name}
        className={
          selected || isFocus ? 'form-item--responsive-label--active' : 'form-item--responsive-label'
        }
      >
        {label}
      </label>

      <Select
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        styles={customStyles}
        options={options}
        value={isSearch ? (isCleared ? '' : selected) : selected}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={() => setFocus(true)}
        onMenuOpen={() => setCleared(true)}
        onMenuClose={() => setCleared(false)}
        placeholder=""
        isSearchable={isSearch}
        isDisabled={disabled}
        onInputChange={(text) => handleInputChange(text)}
        onMenuScrollToBottom={onScrollToBottom}
      />

      {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
    </div>
  );
}
