import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CustomInput, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import i18n from '../../../i18n';

import cx from 'classnames';
import { FormInput, FormMultipleSelect } from '../../../components/Form';
import Loader from '../../../components/Loader';

import { eventTypes, destinations } from '../../../constants/alerts.constants';

import * as workflowActions from '../../../actions/workflows.action';
import * as cameraActions from '../../../actions/cameras.actions';
import * as locationActions from '../../../actions/locations.actions';
import * as userActions from '../../../actions/users.actions';
import _ from 'lodash';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .max(250, i18n.t('validationMessages:length', { field: 'name', length: 250 }))
    .required(i18n.t('validationMessages:required', { field: 'name' })),
  alertDestinations: yup
    .array()
    .required(i18n.t('validationMessages:required', { field: 'alertDestinations' })),
});

const AlertRouteModal = ({
  title,
  isOpen,
  type,
  alertRoute,
  onSubmit,
  onClose,
  className,
  errorMsg,
  isLoading,
  getUsers,
  getWorkflows,
  getCameras,
  getLocations,
}) => {
  const [isChanged, setChanged] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [workflowOptions, setWorkflowOptions] = useState([]);
  const [cameraOptions, setCameraOptions] = useState([]);
  const [locationOptions, setLocationOption] = useState([]);

  const [userQuery, setUserQuery] = useState({ firstName: '', page: 0, size: 20 });
  const [workflowQuery, setWorkflowQuery] = useState({ name: '', page: 0, size: 20 });
  const [cameraQuery, setCameraQuery] = useState({ name: '', page: 0, size: 20 });
  const [locationQuery, setLocationQuery] = useState({ name: '', page: 0, size: 20 });

  useEffect(() => {
    getUsers(
      {
        firstName: userQuery.firstName,
        page: 0,
        size: userQuery.page * userQuery.size + userQuery.size,
      },
      {
        onSuccess: (users) => {
          setUserOptions(
            users.items.map((user) => ({
              label: `${user.firstName} ${user.lastName}`,
              value: user.uuid,
              avatarUrl: user.avatar.url ? user.avatar.url : '',
            }))
          );
        },
      }
    );
  }, [userQuery.firstName, userQuery.page]);

  useEffect(() => {
    getWorkflows(
      {
        name: workflowQuery.name,
        page: 0,
        size: workflowQuery.page * workflowQuery.size + workflowQuery.size,
      },
      {
        onSuccess: (workflows) => {
          setWorkflowOptions(
            workflows.items.map((workflow) => ({ label: workflow.name, value: workflow.uuid }))
          );
        },
      }
    );
  }, [workflowQuery.name, workflowQuery.page]);

  useEffect(() => {
    getCameras(
      {
        name: cameraQuery.name,
        page: 0,
        size: cameraQuery.page * cameraQuery.size + cameraQuery.size,
      },
      {
        onSuccess: (cameras) => {
          setCameraOptions(cameras.items.map((camera) => ({ label: camera.name, value: camera.uuid })));
        },
      }
    );
  }, [cameraQuery.name, cameraQuery.page]);

  useEffect(() => {
    getLocations(
      {
        name: locationQuery.name,
        page: 0,
        size: locationQuery.page * locationQuery.size + locationQuery.size,
      },
      {
        onSuccess: (locations) => {
          setLocationOption(() => [
            ...locations.items.map((location) => ({ label: location.name, value: location.uuid })),
          ]);
        },
      }
    );
  }, [locationQuery.name, locationQuery.page]);

  const delayedQuery = useCallback(
    _.debounce((type, inputValue) => {
      switch (type) {
        case 'user':
          setUserQuery({ firstName: inputValue, page: 0, size: 20 });
          break;
        case 'workflow':
          setWorkflowQuery({ name: inputValue, page: 0, size: 20 });
          break;
        case 'camera':
          setCameraQuery({ name: inputValue, page: 0, size: 20 });
          break;
        case 'location':
          setLocationQuery({ name: inputValue, page: 0, size: 20 });
          break;

        default:
          break;
      }
    }, 500),
    []
  );

  const handleScrollToBottom = (type) => {
    switch (type) {
      case 'user':
        setUserQuery((prev) => ({ ...prev, page: prev.page + 1 }));
        break;
      case 'workflow':
        setWorkflowQuery((prev) => ({ ...prev, page: prev.page + 1 }));
        break;
      case 'camera':
        setCameraQuery((prev) => ({ ...prev, page: prev.page + 1 }));
        break;
      case 'location':
        setLocationQuery((prev) => ({ ...prev, page: prev.page + 1 }));
        break;

      default:
        break;
    }
  };

  const handleInputChange = (type, searchString) => {
    delayedQuery(type, searchString);
  };

  const verify = (values) => {
    const alertRoute = { ...values };

    if (alertRoute.name !== '' && alertRoute.alertDestinations.length) {
      alertRoute.users = alertRoute.users.map((user) => ({ uuid: user.value }));
      alertRoute.alertDestinations = alertRoute.alertDestinations.map((destination) => destination.value);
      alertRoute.alertTypes = alertRoute.alertTypes.map((type) => type.value);
      alertRoute.workflows = alertRoute.workflows.map((workflow) => ({ uuid: workflow.value }));
      alertRoute.cameras = alertRoute.cameras.map((camera) => ({ uuid: camera.value }));
      alertRoute.locations = alertRoute.locations.map((location) => ({ uuid: location.value }));

      onSubmit(alertRoute);
    }
  };

  return (
    <Modal isOpen={isOpen} className={cx('', className)} fade>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>

      <Formik
        onSubmit={verify}
        initialValues={{
          uuid: type === 'create' ? null : alertRoute.uuid,
          name: type === 'create' ? '' : alertRoute.name,
          users:
            type === 'create'
              ? []
              : alertRoute.users.map((user) => ({
                  label: `${user.firstName} ${user.lastName}`,
                  value: user.uuid,
                  avatarUrl: user.avatar.url ? user.avatar.url : '',
                })),
          alertDestinations:
            type === 'create'
              ? []
              : alertRoute.alertDestinations.map((item) => ({ label: item, value: item })),
          alertTypes:
            type === 'create' ? [] : alertRoute.alertTypes.map((item) => ({ label: item, value: item })),
          workflows:
            type === 'create'
              ? []
              : alertRoute.workflows.map((item) => ({ label: item.name, value: item.uuid })),
          cameras:
            type === 'create'
              ? []
              : alertRoute.cameras.map((item) => ({ label: item.name, value: item.uuid })),
          locations:
            type === 'create'
              ? []
              : alertRoute.locations.map((item) => ({ label: item.name, value: item.uuid })),
        }}
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values: { name, users, alertDestinations, alertTypes, workflows, cameras, locations },
            touched,
            errors,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isValid,
          } = props;

          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <ModalBody>
                <div className="form-fields">
                  <div className="form-row">
                    <FormInput
                      name="name"
                      type="text"
                      label={i18n.t('alertRoutesModals:labelName')}
                      value={name}
                      className="form-item form-item--one"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.name && touched.name ? errors.name : ''}
                    />
                  </div>

                  <div className="form-row">
                    <FormMultipleSelect
                      label={i18n.t('alertRoutesModals:labelRecipients')}
                      name="users"
                      value={users}
                      className="form-item form-item--one"
                      options={userOptions}
                      onBlur={handleBlur}
                      onInputChange={(search) => handleInputChange('user', search)}
                      onScrollToBottom={() => handleScrollToBottom('user')}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.users && touched.users ? errors.users : ''}
                      hasIcon={true}
                    />
                  </div>

                  <div className="form-row">
                    <FormMultipleSelect
                      label={i18n.t('alertRoutesModals:labelDestinations')}
                      name="alertDestinations"
                      value={alertDestinations}
                      className="form-item form-item--one"
                      options={Object.keys(destinations).map((type) => ({
                        label: destinations[type],
                        value: type,
                      }))}
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={
                        errors.alertDestinations && touched.alertDestinations ? errors.alertDestinations : ''
                      }
                    />
                  </div>

                  <div className="form-row">
                    <FormMultipleSelect
                      label={i18n.t('alertRoutesModals:labelEventTypes')}
                      name="alertTypes"
                      value={alertTypes}
                      className="form-item form-item--one"
                      options={eventTypes.map((type) => ({ label: type, value: type }))}
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.alertTypes && touched.alertTypes ? errors.alertTypes : ''}
                    />
                  </div>

                  <div className="form-row">
                    <FormMultipleSelect
                      label={i18n.t('alertRoutesModals:labelWorkflows')}
                      name="workflows"
                      value={workflows}
                      className="form-item form-item--one"
                      options={workflowOptions}
                      onBlur={handleBlur}
                      onInputChange={(search) => handleInputChange('workflow', search)}
                      onScrollToBottom={() => handleScrollToBottom('workflow')}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.workflows && touched.workflows ? errors.workflows : ''}
                    />
                  </div>

                  <div className="form-row">
                    <FormMultipleSelect
                      label={i18n.t('alertRoutesModals:labelCameras')}
                      name="cameras"
                      value={cameras}
                      className="form-item form-item--one"
                      options={cameraOptions}
                      onBlur={handleBlur}
                      onInputChange={(search) => handleInputChange('camera', search)}
                      onScrollToBottom={() => handleScrollToBottom('camera')}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.cameras && touched.cameras ? errors.cameras : ''}
                    />
                  </div>

                  <div className="form-row">
                    <FormMultipleSelect
                      label={i18n.t('alertRoutesModals:labelLocations')}
                      name="locations"
                      value={locations}
                      className="form-item form-item--one"
                      options={locationOptions}
                      onBlur={handleBlur}
                      onInputChange={(search) => handleInputChange('location', search)}
                      onScrollToBottom={() => handleScrollToBottom('location')}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        !isChanged && setChanged(true);
                      }}
                      errorMessage={errors.locations && touched.locations ? errors.locations : ''}
                    />
                  </div>
                </div>
              </ModalBody>

              <ModalFooter>
                <Loader loading={isLoading} />

                <button type="button" className="btn btn--dark" disabled={isLoading} onClick={onClose}>
                  {i18n.t('buttons:cancel')}
                </button>

                <button
                  className="btn btn--secondary"
                  type="submit"
                  disabled={isLoading || !isChanged || !isValid}
                >
                  {i18n.t('buttons:save')}
                </button>
              </ModalFooter>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getUsers: (params, actions) => dispatch(userActions.getUsers(params, actions)),
  getWorkflows: (params, actions) => dispatch(workflowActions.getWorkflows(params, actions)),
  getCameras: (params, actions) => dispatch(cameraActions.getCameras(params, actions)),
  getLocations: (params, actions) => dispatch(locationActions.getLocations(params, actions)),
});

export default connect(null, mapDispatchToProps)(AlertRouteModal);
