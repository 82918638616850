import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '../../components/Table';
import i18n from '../../i18n';

import { Link } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import { Card, Col, Row } from 'reactstrap';
import DropdownDotMenu from '../../components/DropdownDotMenu';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import BreakingsTable from './BreakingsTable';
import MaintenanceProceduresTable from './MaintenanceProceduresTable';
import * as actions from '../../actions/equipment.actions'

import { maintenanceHistory } from '../../mocks/equipment';

class MaintenanceHistory extends Component {
  componentDidMount() {
    this.props.getEquipment( this.props.match?.params.id, {onSuccess: (result) => {}})
  }
  render() {
    const info = this.props.list?.items[0];
    return (
      <PageWrapper title={i18n.t('equipment:maintenanceHistory')}>
        <div className="maintenance-history overflow-hidden">
          <Card className="maintenance-history__info">
            <Row>
              <Col xs={12} md={6}>
                <div className="maintenance-history__info-item">
                  <p className="maintenance-history__info-item__title">{i18n.t('equipment:equipmentId')}</p>
                  <p className="maintenance-history__info-item__content">{info.uuid}</p>
                </div>
                <div className="maintenance-history__info-item">
                  <p className="maintenance-history__info-item__title">{i18n.t('equipment:equipmentType')}</p>
                  <p className="maintenance-history__info-item__content">{info.type.name}</p>
                </div>
                <div className="maintenance-history__info-item">
                  <p className="maintenance-history__info-item__title">{i18n.t('equipment:description')}</p>
                  <p className="maintenance-history__info-item__content">{info.description}</p>
                </div>
              </Col>

              <Col xs={12} md={6}>
                <div className="maintenance-history__info-item">
                  <p className="maintenance-history__info-item__title">{i18n.t('equipment:location')}</p>
                  <p className="maintenance-history__info-item__content">{info.location.name}</p>
                </div>
                <div className="maintenance-history__info-item">
                  <p className="maintenance-history__info-item__title">
                    {i18n.t('equipment:maintenancePeriod')}
                  </p>
                  <p className="maintenance-history__info-item__content">{info.maintenancePeriod}</p>
                </div>
                <div className="maintenance-history__info-item">
                  <p className="maintenance-history__info-item__title">
                    {i18n.t('equipment:lastMaintenance')}
                  </p>
                  <p className="maintenance-history__info-item__content">
                    {moment(info.lastMaintenance).format('MMMM DD, YYYY')}
                    <button className="p-1 ml-1 btn btn--color-secondary btn--transparent">
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                  </p>
                </div>
              </Col>
            </Row>
          </Card>
          <Row className="maintenance-history__table g-5">
            <Col xs={12} md={6}>
              <div className="maintenance-history__table mr-2">
                <BreakingsTable items={maintenanceHistory.breakings} />
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="maintenance-history__table ml-2">
                <MaintenanceProceduresTable items={maintenanceHistory.maintenanceProcedures} />
              </div>
            </Col>
          </Row>
        </div>
      </PageWrapper>
    );
  }
}
MaintenanceHistory.propTypes = {
  usersList: PropTypes.array,
};
const mapStateToProps = ({ equipment }) => {
  const { list, isLoading } = equipment;
  return { list, isLoading };
};

const mapDispatchToProps = (dispatch) => ({
  getEquipment: (uuid, action) => dispatch(actions.getEquipment(uuid, action)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceHistory);
