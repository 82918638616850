import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { connect } from 'react-redux';

import * as actions from '../../actions/medias.actions';
import UploadPhotosList from './UploadPhotosList';

const uploadFile = files => {
  let formData;
  if (files && files.length) {
    formData = new FormData();
    formData.append('file', files[0]);
  }
  return formData;
};

class UploadPhotos extends Component {
  state = {
    upload: [],
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { upload } = this.state;
    const { subject, get } = this.props;
    if (prevProps.subject.photos !== subject.photos) {
      if (upload.length) {
        const arr = upload.map(x => {
          if (x.uuid) {
            let equal = subject.photos && subject.photos.find(y => y.uuid === x.uuid);
            return !equal ? x : equal;
          }
          return x;
        });
        this.setState({ upload: arr });
      } else if (subject.photos && subject.photos.length) {
        this.setState({ upload: [...subject.photos] });
      }
    }

    if (prevState.upload !== upload) {
      get && get(upload.filter(x => x.uuid));
    }
  };

  uploadPhoto = e => {
    const now = Date.now();
    const file = uploadFile(e.target.files);
    const callback = result => {
      if (!result.success) {
        this.uploadPhotoError(now, result);
      } else {
        this.uploadPhotoSuccess(now, result);
      }
    };
    this.setState(
      state => ({ upload: [...state.upload, { now, isError: false, error: null }] }),
      () => {
        this.props.uploadMedia(file, callback);
      }
    );
  };

  uploadPhotoError = (now, error) => {
    this.setState(
      state => ({
        ...state,
        upload: state.upload.map(x => (x.now === now ? { ...x, isError: true, error } : x)),
      }),
      () => {
        console.error(`Temporary image ${now} received an error while loading`, error);
      }
    );
  };

  uploadPhotoSuccess = (now, result) => {
    this.setState(
      state => ({
        ...state,
        upload: state.upload.map(x => (x.now === now ? result.results : x)),
      }),
      () => {
        const { subject } = this.props;
        if (subject.uuid) {
          let upload = this.state.upload.filter(x => x.uuid);
          let updateSubject = { ...subject, photos: [...upload] };
          this.props.update(updateSubject);
        }
      }
    );
  };

  removePhoto = photo => {
    const photoUUID = photo.uuid;
    if (photo.now && photo.isError) {
      this.setState(state => ({ ...state, upload: state.upload.filter(x => x.now !== photo.now) }));
    } else if (photoUUID) {
      this.setState(
        state => ({ ...state, upload: state.upload.filter(x => x.uuid !== photoUUID) }),
        () => {
          this.props.removeMedia(photoUUID);
        }
      );
    }
  };

  render() {
    const { upload } = this.state;
    return (
      <ul className="upload-gallery-list">
        <li className="upload-gallery-item">
          <input
            type="file"
            className="sr-only"
            id="subject-photo-upload"
            accept=".png, .jpg, .jpeg"
            onChange={this.uploadPhoto}
          />
          <label htmlFor="subject-photo-upload" className="upload-gallery-content --upload">
            <FontAwesomeIcon icon="plus" size="lg" />
          </label>
        </li>
        <UploadPhotosList list={upload} remove={this.removePhoto} />
      </ul>
    );
  }
}

UploadPhotos.propTypes = {
  get: PropTypes.func,
  uploadMedia: PropTypes.func,
  removeMedia: PropTypes.func,
  subject: PropTypes.object,
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  uploadMedia: (file, callbacks) => dispatch(actions.uploadMedia(file, callbacks)),
  removeMedia: uuid => dispatch(actions.removeMedia(uuid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadPhotos);
