import { queryStringify } from '../utils/index';
import request from '../utils/request';

export function getCameras(params) {
  return request({
    url: `/cameras?${queryStringify(params)}`,
    method: 'get',
  });
}

export function getCamera(uuid) {
  return request({
    url: `/cameras/${uuid}`,
    method: 'get',
  });
}

export function getCameraEvents(uuid, params) {
  return request({
    url: `/cameras/${uuid}/events?${queryStringify(params)}`,
    method: 'get',
  });
}

export function getCameraEventsGroupedTimestamps(params) {
  return request({
    url: `/events/groupedTimestamps?${queryStringify(params)}`,
    method: 'get',
  });
}

export function getCameraAlerts(uuid, params) {
  return request({
    url: `/cameras/${uuid}/alerts?${queryStringify(params)}`,
    method: 'get',
  });
}

export function getCameraRecordings(uuid, params) {
  return request({
    url: `/cameras/${uuid}/recordings?${queryStringify(params)}`,
    method: 'get',
  });
}

export function getDatastream(workflowUuid, cameraUuid) {
  return request({
    url: `/dataStreams/${workflowUuid}/${cameraUuid}`,
    method: 'get',
  });
}

export function verifyCamera(params) {
  return request({
    url: '/cameras/verify',
    method: 'post',
    data: params,
  });
}

export function addCamera(params) {
  return request({
    url: '/cameras',
    method: 'post',
    data: params,
  });
}

export function updateCamera(params) {
  return request({
    url: `/cameras/${params.uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteCamera(uuid) {
  return request({
    url: `/cameras/${uuid}`,
    method: 'delete',
  });
}

export function getCameraFrames(uuid, params) {
  return request({
    url: `/cameras/${uuid}/frames?${queryStringify(params)}`,
    method: 'get',
  });
}

export function getReadyHeatmap(workflowUuid, cameraUuid, params) {
  return request({
    url: `/data/heatmaps-by-objects/${workflowUuid}/${cameraUuid}/zones`,
    method: 'get',
    data: params,
  });
}

export function getReadyHeatmapForQueues(workflowUuid, cameraUuid, params) {
  return request({
    url: `/data/heatmaps-by-queues/${workflowUuid}/${cameraUuid}/zones`,
    method: 'get',
    data: params,
  });
}

export function getWidgets(params) {
  return request({
    url: '/widgets',
    method: 'get',
    data: params,
  });
}

export function addWidget(params) {
  return request({
    url: '/widgets',
    method: 'post',
    data: params,
  });
}

export function deleteWidget(uuid) {
  return request({
    url: `/widgets/${uuid}`,
    method: 'delete',
  });
}

export function addFilter(params) {
  return request({
    url: '/filters',
    method: 'post',
    data: params,
  });
}

export function getFilters(params) {
  return request({
    url: `/filters/${params}`,
    method: 'get',
  });
}
