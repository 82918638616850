import * as ActionTypes from '../constants/cameras.constants';

const initialState = {
  activeWorkflowUuid: null,
  activeWorkflowType: null,
  cameraUuid: null,
  zones: [],
  options: {
    isShowObjects: false,
    isShowLines: false,
    isShowZones: false,
    updateHeatmap: false,
  },
  isLoading: false,
  isHeatmapLoading: false,
  isError: null,
  heatmap: {
    online: false,
    start: Date.now(),
    end: Date.now(),
    interval: 0,
  },
  inout: [],
  lineCrossing: [],
  queue: [],
  accumAnalytic: [],
  analytic: [],
  processing: [],
};

export const cameraReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CAMERA:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_CAMERA_RESULT:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_CAMERA_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };
    case ActionTypes.GET_READY_HEATMAP:
      return {
        ...state,
        isHeatmapLoading: true,
      };

    case ActionTypes.GET_READY_HEATMAP_RESULT:
      return {
        ...state,
        ...action.payload,
        isHeatmapLoading: false,
      };

    case ActionTypes.GET_READY_HEATMAP_ERROR:
      return {
        ...state,
        isHeatmapLoading: false,
      };
    case ActionTypes.GET_READY_HEATMAP_FOR_QUEUES:
      return {
        ...state,
        isHeatmapLoading: true,
      };

    case ActionTypes.GET_READY_HEATMAP_FOR_QUEUES_RESULT:
      return {
        ...state,
        ...action.payload,
        isHeatmapLoading: false,
      };

    case ActionTypes.GET_READY_HEATMAP_FOR_QUEUES_ERROR:
      return {
        ...state,
        isHeatmapLoading: false,
      };
    case ActionTypes.SET_ACTIVE_CAMERA_WORKFLOW:
      return {
        ...state,
        activeWorkflowUuid: action.payload.uuid,
        activeWorkflowType: action.payload.type,
      };

    case ActionTypes.SET_ACTIVE_CAMERA_ZONES:
      return {
        ...state,
        zones: action.payload.zones,
      };

    case ActionTypes.CHANGE_ACTIVE_CAMERA_OPTION:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.key]: action.payload.value,
        },
      };

    case ActionTypes.CHANGE_CAMERA_HEATMAP_VALUE:
      return {
        ...state,
        heatmap: {
          ...state.heatmap,
          [action.payload.key]: action.payload.value,
        },
      };

    case ActionTypes.CHANGE_CAMERA_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case ActionTypes.RESET_CAMERA_VIEW_WIDGETS:
      return {
        ...state,
        inout: [],
        lineCrossing: [],
        queue: [],
        accumAnalytic: [],
        analytic: [],
      };

    case ActionTypes.SET_CAMERA_UNMOUNT:
      return initialState;

    default:
      return state;
  }
};
