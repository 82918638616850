import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormNumericInput, FormSelect } from '../../components/Form';
import { Resources } from '../../constants/limits.constants';
import i18n from '../../i18n';

const LimitModal = ({ modal, onClose, title, procTypes, info, onChange, onSubmit }) => {
  return (
    <Modal isOpen={modal} fade>
      <ModalHeader toggle={() => onClose()}>{i18n.t(`limitsModals:${title}`)}</ModalHeader>
      <ModalBody>
        <FormNumericInput
          type="numeric"
          className="form-item form-item--one mb-28"
          label={i18n.t('limitsModals:limit')}
          value={info.limit}
          onChange={(value) => {
            onChange('limit', value);
          }}
        />
        <FormSelect
          label={i18n.t('limitsModals:procType')}
          selected={info.procType}
          options={[{ label: 'NONE', value: '' }, ...procTypes]}
          isSearch={true}
          className="form-item form-item--one mb-28"
          onChange={(value) => {
            onChange('procType', value);
          }}
          errorMessage={false}
        />
        <FormSelect
          label={i18n.t('limitsModals:resource')}
          selected={info.resource}
          options={Resources}
          isSearch={true}
          className="form-item form-item--one mb-28"
          onChange={(value) => {
            onChange('resource', value);
          }}
          errorMessage={false}
        />
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn--dark" onClick={() => onClose()}>
          {i18n.t('buttons:cancel')}
        </button>
        <button
          className="btn btn--secondary"
          type="submit"
          disabled={info.limit === 0 || info.resource === ''}
          onClick={() => onSubmit()}
        >
          {i18n.t('buttons:save')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default LimitModal;
