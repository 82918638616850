import * as ActionTypes from '../constants/medias.constants';

export const uploadMedia = (formData, callbacks) => ({
  type: ActionTypes.UPLOAD_MEDIA,
  payload: formData,
  callbacks,
});

export const removeMedia = uuid => ({
  type: ActionTypes.REMOVE_MEDIA,
  payload: uuid,
});
