import { takeEvery, call, put, all, select } from 'redux-saga/effects';
import * as ActionTypes from '../constants/alerts.constants';
import * as api from '../api/alerts';

export function* getAlerts({ payload }) {
  const alerts = yield select((s) => s.alerts);
  try {
    const response = yield call(api.getAlerts, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_ALERTS_RESULT, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ALERTS_ERROR });
  }
}

export function* getAlertsNextPage() {
  const alerts = yield select((s) => s.alerts);
  const next = alerts.query.page + 1;
  try {
    const response = yield call(api.getAlerts, { ...alerts.query, page: next });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_ALERTS_NEXT_PAGE_RESULT, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ALERTS_NEXT_PAGE_ERROR });
  }
}

export function* getAlertRoutes({ payload, actions = {} }) {
  const alertRoutes = yield select((s) => s.alertRoutes);
  try {
    const response = yield call(api.getAlertRoutes, { ...alertRoutes.query, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_ALERT_ROUTES_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ALERT_ROUTES_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getAlertRoute({ payload, actions = {} }) {
  try {
    const response = yield call(api.getAlertRoute, payload);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addAlertRoute({ payload, actions = {} }) {
  try {
    const response = yield call(api.addAlertRoute, payload);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteAlertRoute({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteAlertRoute, payload);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* alertsSagas() {
  yield all([
    takeEvery(ActionTypes.GET_ALERTS, getAlerts),
    takeEvery(ActionTypes.GET_ALERTS_NEXT_PAGE, getAlertsNextPage),
    takeEvery(ActionTypes.GET_ALERT_ROUTES, getAlertRoutes),
    takeEvery(ActionTypes.GET_ALERT_ROUTE, getAlertRoute),
    takeEvery(ActionTypes.ADD_ALERT_ROUTE, addAlertRoute),
    takeEvery(ActionTypes.DELETE_ALERT_ROUTE, deleteAlertRoute),
  ]);
}
