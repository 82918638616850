import React from 'react';
import PropTypes from 'prop-types';
import { avatarViews } from '../../constants/userConstants';

const Avatar = ({ avatarUrl, name, view, width, fontSize, className }) => {
  return (
    <div
      className={`${className} d-flex align-items-center justify-content-center`}
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        backgroundImage: avatarUrl ? `url(${avatarUrl})` : '',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: width ? width : 40,
        height: width ? width : 40,
        borderRadius: view && view === avatarViews.ROUND ? '50%' : '2px',
      }}
    >
      
      {!avatarUrl && name && (
        <p style={{ fontSize: fontSize ? fontSize : 14, opacity: 0.3 }}>
          {name.split(' ')[0].substring(0, 1) +
            ' ' +
            (name.split(' ')[1]?.substring(0, 1) ? name.split(' ')[1]?.substring(0, 1) : '')}
        </p>
      )}
    </div>
  );
};

Avatar.propTypes = {
  avatarUrl: PropTypes.string.isRequired,
  view: PropTypes.string,
  name: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default Avatar;
