import * as ActionTypes from '../constants/users.constants';

export const getUsers = (params, actions) => ({
  type: ActionTypes.GET_USERS,
  payload: params,
  actions,
});

export const getUser = (id, actions) => ({
  type: ActionTypes.GET_USER,
  payload: id,
  actions,
});

export const addUser = (params, actions) => ({
  type: ActionTypes.ADD_USER,
  payload: params,
  actions,
});

export const updateUser = (params, actions) => ({
  type: ActionTypes.UPDATE_USER,
  payload: params,
  actions,
});

export const updateMe = (params, actions) => ({
  type: ActionTypes.UPDATE_ME,
  payload: params,
  actions,
});

export const deleteUser = (uuid, actions) => ({
  type: ActionTypes.DELETE_USER,
  payload: uuid,
  actions,
});

export const changeUserValue = (key, value) => ({
  type: ActionTypes.CHANGE_USER_VALUE,
  payload: { key, value },
});

export const changeModalValue = (key, value) => ({
  type: ActionTypes.CHANGE_MODAL_USER_VALUE,
  payload: { key, value },
});

export const changePageValue = (value) => ({
  type: ActionTypes.CHANGE_USER_PAGE_VALUE,
  payload: value,
});

export const uploadMedia = (params, actions) => ({
  type: ActionTypes.UPLOAD_MEDIA,
  payload: params,
  actions,
});

export const addRolesToUser = (uuid, params, actions) => ({
  type: ActionTypes.ADD_ROLES_TO_USER,
  payload: { uuid, params },
  actions,
});
export const updateRolesToUser = (uuid, params, actions) => ({
  type: ActionTypes.UPDATE_ROLES_TO_USER,
  payload: { uuid, params },
  actions,
});
export const deleteRoleToUser = (uuid, roleUuid, actions) => ({
  type: ActionTypes.DELETE_ROLE_TO_USER,
  payload: { uuid, roleUuid },
  actions,
});

export const getMe = (actions) => ({
  type: ActionTypes.GET_ME,
  actions,
});

export const changePassword = (params, actions) => ({
  type: ActionTypes.CHANGE_PASSWORD,
  payload: params,
  actions,
});

export const updateEmail = (params, actions) => ({
  type: ActionTypes.UPDATE_EMAIL,
  payload: params,
  actions,
});

export const confirmEmail = (params, actions) => ({
  type: ActionTypes.CONFIRM_EMAIL,
  payload: params,
  actions,
});

export const resendEmail = (actions) => ({
  type: ActionTypes.RESEND_EMAIL,
  actions,
});
