import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Modal, ModalBody, ModalHeader, ModalFooter, CustomInput } from 'reactstrap';
import Loader from '../../../components/Loader';
import cx from 'classnames';

import { FormDatePicker, FormInput, FormNumericInput, FormSelect } from '../../../components/Form';
import isNull from 'lodash/isNull';

const validationSchema = yup.object().shape({
  equipmentName: yup.string().required(i18n.t('validationMessages:required', { field: 'equipmentName' })),
  location: yup.object().required(i18n.t('validationMessages:required', { field: 'location' })),
  type: yup.object().required(i18n.t('validationMessages:required', { field: 'type' })),
});

class BaseModal extends Component {
  state = {
    message: null,
    locations: [],
    equipmentTypes: [],
    locationQuery: { name: '', page: 0, size: 20 },
    equipmentTypeQuery: { name: '', page: 0, size: 20 },
  };

  componentDidMount() {
    this.props.getLocations(
      {
        name: this.state.locationQuery.name,
        page: 0,
        size: this.state.locationQuery.size,
      },
      {
        onSuccess: (locations) => {
          this.setState({
            locations: [...locations.items],
          });
        },
      }
    );

    this.props.getEquipmentTypes(
      {
        name: this.state.equipmentTypeQuery.name,
        page: 0,
        size: this.state.equipmentTypeQuery.size,
      },
      {
        onSuccess: (equipmentTypes) => {
          this.setState({
            equipmentTypes: [...equipmentTypes.items],
          });
        },
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { locationQuery, equipmentTypeQuery } = this.state;

    if (
      prevState.locationQuery.name !== locationQuery.name ||
      prevState.locationQuery.page !== locationQuery.page
    ) {
      this.props.getLocations(
        {
          name: locationQuery.name,
          page: 0,
          size: locationQuery.page * locationQuery.size + locationQuery.size,
        },
        {
          onSuccess: (locations) => {
            this.setState({
              locations: [...locations.items],
            });
          },
        }
      );
    }

    if (
      prevState.equipmentTypeQuery.name !== equipmentTypeQuery.name ||
      prevState.equipmentTypeQuery.page !== equipmentTypeQuery.page
    ) {
      this.props.getEquipmentTypes(
        {
          name: equipmentTypeQuery.name,
          page: 0,
          size: equipmentTypeQuery.page * equipmentTypeQuery.size + equipmentTypeQuery.size,
        },
        {
          onSuccess: (equipmentTypes) => {
            this.setState({
              equipmentTypes: [...equipmentTypes.items],
            });
          },
        }
      );
    }
  }

  handleScrollToBottom = (type) => {
    switch (type) {
      case 'location':
        this.setState((prev) => ({
          locationQuery: { ...prev.locationQuery, page: prev.locationQuery.page + 1 },
        }));
        break;
      case 'equipmentType':
        this.setState((prev) => ({
          equipmentTypeQuery: { ...prev.equipmentTypeQuery, page: prev.equipmentTypeQuery.page + 1 },
        }));
        break;
    }
  };

  handleInputChange = (type, searchString) => {
    switch (type) {
      case 'location':
        this.setState({ locationQuery: { name: searchString, page: 0, size: 20 } });
        break;
      case 'equipmentType':
        this.setState({ equipmentTypeQuery: { name: searchString, page: 0, size: 20 } });
        break;
    }
  };

  render() {
    const { message, locations, equipmentTypes } = this.state;

    const {
      params: { isOpen, data },
      header,
      onClose,
      loading,
      submitBtnText,
      onSubmit,
      closeBtnText,
      className,
    } = this.props;

    return (
      <Modal isOpen={isOpen} className={cx('', className)} fade>
        <ModalHeader toggle={onClose}>{header}</ModalHeader>
        <Formik
          onSubmit={this.props.onSubmit}
          initialValues={{
            equipmentName: data && data.name ? data.name : '',
            arucoMarket: data && data.arucoMarker ? data.arucoMarker : 24,
            description: data && data.description ? data.description : '',
            location: data && data.location ? { label: data.location.name, value: data.location.uuid } : null,
            type: data && data.type ? { label: data.type.name, value: data.type.uuid } : null,
            connectedIOT: false,
            maintenancePeriod: data && data.maintenancePeriod ? data.maintenancePeriod : 1,
            lastMaintenance: data && data.lastMaintenance ? data.lastMaintenance : null,
          }}
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values: {
                equipmentName,
                arucoMarket,
                description,
                location,
                type,
                connectedIOT,
                dataExchangeProtocol,
                connectionParameters,
                maintenancePeriod,
                lastMaintenance,
              },
              touched,
              errors,
              isValid,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <ModalBody>
                  <div className="form-fields mb-28">
                    <div className="form-row">
                      <FormInput
                        name="equipmentName"
                        type="text"
                        label={`${i18n.t('equipmentModals:labelEquipmentName')}*`}
                        value={equipmentName}
                        className="form-item form-item--two"
                        onBlur={handleBlur}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        errorMessage={
                          errors.equipmentName && touched.equipmentName ? errors.equipmentName : ''
                        }
                      />

                      <FormInput
                        name="arucoMarket"
                        type="text"
                        label={i18n.t('equipmentModals:labelArucoMarket')}
                        value={arucoMarket}
                        className="form-item form-item--two"
                        onBlur={handleBlur}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        Connected
                        errorMessage={errors.arucoMarket && touched.arucoMarket ? errors.arucoMarket : ''}
                      />
                    </div>

                    <div className="form-row">
                      <FormInput
                        name="description"
                        type="text"
                        label={i18n.t('equipmentModals:labelDescription')}
                        value={description}
                        className="form-item form-item--one"
                        onBlur={handleBlur}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        errorMessage={errors.description && touched.description ? errors.description : ''}
                      />
                    </div>

                    <div className="form-row">
                      <FormSelect
                        label={`${i18n.t('equipmentModals:labelLocation')}*`}
                        name="location"
                        selected={location}
                        options={locations.map((item) => ({ label: item.name, value: item.uuid }))}
                        isSearch={true}
                        className="form-item form-item--two"
                        onBlur={handleBlur}
                        onInputChange={(search) => this.handleInputChange('location', search)}
                        onScrollToBottom={() => this.handleScrollToBottom('location')}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        errorMessage={errors.location && touched.location ? errors.location : ''}
                      />

                      <FormSelect
                        label={`${i18n.t('equipmentModals:labelType')}*`}
                        name="type"
                        selected={type}
                        options={equipmentTypes.map((item) => ({ label: item.name, value: item.uuid }))}
                        isSearch={true}
                        className="form-item form-item--two"
                        onBlur={handleBlur}
                        onInputChange={(search) => this.handleInputChange('equipmentType', search)}
                        onScrollToBottom={() => this.handleScrollToBottom('equipmentType')}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                        errorMessage={errors.type && touched.type ? errors.type : ''}
                      />
                    </div>
                  </div>

                  <div className="form-switch-item mb-14">
                    <CustomInput
                      type="switch"
                      disabled={true}
                      onChange={(e) => setFieldValue('connectedIOT', e.target.checked)}
                      id="connectedIOT"
                      name="connectedIOT"
                      className="custom-switch--small"
                    />
                    <p className="form-switch-label">{i18n.t('equipmentModals:labelIOTConnected')}</p>
                  </div>

                  <div className="form-row_mb-28">
                    <FormInput
                      name="dataExchangeProtocol"
                      type="text"
                      disabled={true}
                      label={i18n.t('equipmentModals:labelDataExchangeProtocol')}
                      value={dataExchangeProtocol}
                      className="form-item form-item--two"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      errorMessage={
                        errors.dataExchangeProtocol && touched.dataExchangeProtocol
                          ? errors.dataExchangeProtocol
                          : ''
                      }
                    />

                    <FormInput
                      name="connectionParameters"
                      type="text"
                      disabled={true}
                      label={i18n.t('equipmentModals:labelConnectionParameters')}
                      value={connectionParameters}
                      className="form-item form-item--two"
                      onBlur={handleBlur}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      errorMessage={
                        errors.connectionParameters && touched.connectionParameters
                          ? errors.connectionParameters
                          : ''
                      }
                    />
                  </div>

                  <div className="form-row">
                    <FormNumericInput
                      name="maintenancePeriod"
                      label={i18n.t('equipmentModals:labelMaintenancePeriod')}
                      step={1}
                      value={maintenancePeriod}
                      onBlur={handleBlur}
                      className="form-item form-item--two"
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                        setFieldValue('birthday', null);
                      }}
                      errorMessage={
                        errors.maintenancePeriod && touched.maintenancePeriod ? errors.maintenancePeriod : ''
                      }
                    />

                    <FormDatePicker
                      label={i18n.t('equipmentModals:labelLastMaintenance')}
                      name="lastMaintenance"
                      value={lastMaintenance ? new Date(lastMaintenance) : null}
                      dropdownMode="select"
                      className="form-item form-item--two"
                      onBlur={handleBlur}
                      onChange={(name, date) => {
                        setFieldValue(name, !isNull(date) ? new Date(date).toISOString() : null);
                        setFieldValue('age', '');
                      }}
                      placeholder="mm/dd/yyyy"
                      errorMessage={
                        errors.lastMaintenance && touched.lastMaintenance ? errors.lastMaintenance : ''
                      }
                    />
                  </div>
                </ModalBody>

                <ModalFooter>
                  <Loader loading={loading} />
                  {message || null}
                  <button type="button" className="btn btn--dark" disabled={loading} onClick={onClose}>
                    {closeBtnText}
                  </button>
                  <button className="btn btn--secondary" type="submit" disabled={false}>
                    {submitBtnText}
                  </button>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

BaseModal.defaultProps = {
  loading: false,
  submitBtnText: i18n.t('buttons:save'),
  closeBtnText: i18n.t('buttons:cancel'),
};

export default BaseModal;
