export const SET_LIVE_PLAYER = 'SET_LIVE_PLAYER';
export const SET_PAUSE_PLAYER = 'SET_PAUSE_PLAYER';
export const SET_RANGE_PLAYER = 'SET_RANGE_PLAYER';
export const SET_SOUND_PLAYER = 'SET_SOUND_PLAYER';
export const SET_SPEED_UP_PLAYER = 'SET_SPEED_UP_PLAYER';
export const SET_SPEED_DOWN_PLAYER = 'SET_SPEED_DOWN_PLAYER';
export const SET_CURRENT_PLAYER = 'SET_CURRENT_PLAYER';
export const SET_STEP_FORWARD = 'SET_STEP_FORWARD';
export const SET_STEP_BACKWARD = 'SET_STEP_BACKWARD';
export const SET_STEP_START = 'SET_STEP_START';
export const SET_STEP_END = 'SET_STEP_END';
export const SET_EVENTS_CACHE = 'SET_EVENTS_CACHE';
export const COPY_EVENTS_TO_CACHE = 'COPY_EVENTS_TO_CACHE';
export const SET_RECORDING_SCREEN = 'SET_RECORDING_SCREEN';
export const SET_TIMESTAMPS_VALUE = 'SET_TIMESTAMPS_VALUE';
