import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

class MembersList extends Component {
  state = {
   
  };
  render () {
    return (
      'MembersList'
    )
  }
}

MembersList.propTypes = {
  membersList: PropTypes.array,
}

export default MembersList;