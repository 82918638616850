/* eslint-disable import/no-anonymous-default-export*/
export default {
  common: {
    pagenotFound: 'Page Not Found',
    notFound: 'Not Found',
    loading: 'Loading...',
    somethingWentWrong: 'Something went wrong',
    unknownSubject: 'Unknown',
    unknownCamera: 'Unknown',
    unknownLocation: 'Unknown',
    search: 'Search',
    name: 'Name',
    date: 'Date',
  },
  notifications: {
    subjectPostfix: 'detected',
  },
  form: {
    hasNoChanges: 'Nothing to update',
  },
  buttons: {
    signIn: 'Sign In',
    signOut: 'Sign Out',
    start: 'Start',
    stop: 'Stop',
    cancel: 'Cancel',
    save: 'Save',
    ok: 'Ok',
    next: 'Next',
    delete: 'Delete',
    back: 'Back',
    changePassword: 'Change Password',
    confirmEmail: 'Confirm Email',
    resendEmail: 'Resend Email',
    updateEmail: 'Link Email',
  },
  fields: {
    email: 'Email',
    name: 'Name',
    password: 'Password',
    searchInputPlaceholder: 'Search',
    username: 'Username',
    phone: 'Phone',
    avatar: 'Avatar',
    code: 'Code',
  },
  cameraModals: {
    labelName: 'Name',
    labelLocation: 'Location',
    labelStreamUrl: 'URL',
    labelFps: 'FPS',
    labelPreview: 'Preview',
    labelEnabled: 'Enabled',
    addTitle: 'Add Camera',
    editTitle: 'Edit Camera',
    deleteTitle: 'Delete Camera',
    deleteBody: 'Are you sure you want to delete camera {{cameraName}}?',
    previewNoConnected: 'No camera connected',
    previewLoading: 'Connecting',
    previewError: "Can't connect to the stream",
    inactiveCamera: ' Camera is disabled ',
    error: 'Error',
  },
  workflowModals: {
    addTitle: 'Add Workflow',
    editTitle: 'Edit Workflow',
    attachTitle: 'Attach cameras to {{workflowName}}',
    modifyTitle: 'Modify camera for {{workflowName}}',
    detachTitle: 'Detach cameras from {{workflowName}}',
    addNameTitle: 'Add Name',
    cancelCreation: 'Cancel creation',
    deleteTitle: 'Delete Workflow',
    deleteBody: 'Are you sure you want to delete workflow {{workflowName}}?',
    generalTab: 'General',
    camerasTab: 'Cameras',
    cameraTab: 'Camera',
    processingTab: 'Processing',
    analyticsTab: 'Analytics',
    triggersTab: 'Triggers',
    labelBatchSize: 'Optimal Batch Size',
    labelProcessingType: 'Processing Type',
    labelAnalyticType: 'Analytic Type',
    labelTriggerType: 'Trigger Type',
    labelWorkflowName: 'Workflow Name',
    labelBaseConfig: 'Base Configuration',
    labelSelectedCamera: 'Selected Camera',
    labelSelectedZone: 'Selected Zone',
    labelAttachedCamera: 'Attached Camera',
    labelEnabled: 'Enabled',
    labelPlainJSON: 'Plain JSON',
    errorTrigger: 'Trigger Error',
    availableWidget: 'Available Widget',
    addPreset: 'Preset Name',
  },
  subjectModals: {
    addTitle: 'New Subject',
    editTitle: 'Edit Subject',
    deleteTitle: 'Delete Subject',
    deleteBody: 'Are you sure you want to delete subject {{subjectName}}?',
    labelName: 'Name',
    labelSex: 'Sex',
    labelSexMale: 'Male',
    labelSexFemale: 'Female',
    labelSexOther: 'Other',
    labelBirthday: 'Birthday',
    labelAge: 'Age',
    labelHeight: 'Height',
    labelRole: 'Role',
    labelWorkingPlace: 'Working Place',
  },
  personModals: {
    addTitle: 'New Person',
    editTitle: 'Edit Person',
    deleteTitle: 'Delete Person',
    deleteBody: 'Are you sure you want to delete person {{personName}}?',
    labelName: 'Name',
    labelSex: 'Sex',
    labelSexMale: 'Male',
    labelSexFemale: 'Female',
    labelSexOther: 'Other',
    labelBirthday: 'Birthday',
    labelAge: 'Age',
    labelRole: 'Role',
    labelWorkingPlace: 'Working Place',
  },
  carModals: {
    addTitle: 'New Car',
    editTitle: 'Edit Car',
    deleteTitle: 'Delete Car',
    deleteBody: 'Are you sure you want to delete car {{plateLabel}}?',
    labelPlateLabel: 'Plate Label',
    labelOwner: 'Owner',
    labelDescription: 'Description',
    labelManufacturer: 'Manufacturer',
    labelModel: 'Model',
    labelVehicleType: 'Vehicle Type',
    labelColor: 'Color',
    labelVIN: 'VIN',
    labelYear: 'Year',
  },
  equipmentModals: {
    addTitle: 'New Equipment',
    editTitle: 'Edit Equipment',
    deleteTitle: 'Delete Equipment',
    deleteBody: 'Are you sure you want to delete equipment {{equipmentID}}?',
    labelEquipmentName: 'Equipment Name',
    labelArucoMarket: 'ArUco Marker',
    labelDescription: 'Description',
    labelLocation: 'Location',
    labelType: 'Type',
    labelRole: 'Role',
    labelRoleActive: 'Active',
    labelRolePassive: 'Passive',
    labelAllowedProductionItems: 'Allowed Production Items',
    labelIOTConnected: 'IOT Connected',
    labelDataExchangeProtocol: 'Data Exchange Protocol',
    labelConnectionParameters: 'Connection Parameters',
    labelMaintenancePeriod: 'Maintenance Period',
    labelLastMaintenance: 'Last Maintenance',
  },
  usersModals: {
    addTitle: 'New User',
    editTitle: 'Edit User',
    deleteTitle: 'Delete User',
    deleteBody: 'Are you sure you want to delete user {{userName}}?',
    labelFirstName: 'First Name',
    labelLastName: 'Last Name',
    labelRoles: 'ROLES',
    labelPassword: 'PASSWORD',
    labelRandom: 'Random',
    labelCustom: 'Custom',
    labelSendDetails: 'Send account details via email',
    changePassword: 'Change Password',
  },
  rolesModals: {
    addTitle: 'New Role',
    editTitle: 'Edit Role',
    deleteTitle: 'Delete Role',
    deleteBody: 'Are you sure you want to delete role {{roleName}}?',
    labelName: 'Name',
    labelPermissions: 'PERMISSIONS',
    description: 'Description',
  },
  limitsModals: {
    addTitle: 'New Limit',
    editTitle: 'Edit Limit',
    limit: 'Limit',
    procType: 'Processing Type',
    resource: 'Resource',
    account: 'Account',
  },
  accountsModals: {
    addTitle: 'New Account',
    editTitle: 'Edit Account',
    account: 'Account',
    accType: 'Account Type',
    updateEmail: 'Link Email',
    confirmEmail: 'Confirm Email',
    resendEmail: 'Resend Email',
  },
  alertModals: {
    addTitle: 'New Alert',
    editTitle: 'Edit Alert',
    deleteTitle: 'Delete Alert',
    deleteBody: 'Are you sure you want to delete alert {{alertName}}?',
    labelName: 'Name',
    labelRecipients: 'Recipients',
    labelEvents: 'Events',
  },
  alertRoutesModals: {
    addTitle: 'New Alert Route',
    editTitle: 'Edit Alert Route',
    deleteTitle: 'Delete Alert Route',
    deleteBody: 'Are you sure you want to delete alert {{alertRouteName}}?',
    labelName: 'Name',
    labelRecipients: 'Recipients',
    labelDestinations: 'Destinations',
    labelEventTypes: 'Event Types',
    labelWorkflows: 'Workflows',
    labelCameras: 'Cameras',
    labelLocations: 'Locations',
  },
  locationsModals: {
    addTitle: 'New Location',
    editTitle: 'Edit Location',
    deleteTitle: 'Delete Location',
    deleteBody: 'Are you sure you want to delete location {{locationName}}?',
    labelName: 'Name',
    labelRecipients: 'Recipients',
    labelEvents: 'Events',
  },
  equipmentTypesModals: {
    addTitle: 'New Equipment Type',
    editTitle: 'Edit Equipment Type',
    deleteTitle: 'Delete Equipment Type',
    deleteBody: 'Are you sure you want to delete equipment type {{locationName}}?',
    labelName: 'Name',
  },
  imageUploader: {
    labelPhotos: 'Photos',
    labelRequirements: 'Requirements',
    requirementsTooltipPartOne: 'The face size is greater than or equal to 200x200 pixels.',
    requirementsTooltipPartTwo: 'The visible part of a face is not less than 100%.',
    requirementsTooltipPartThree:
      'The pitch is less than 30 degrees face down and less than 40 degrees face up. ' +
      'The yaw is less than 40 degrees in either direction.',
    requirementsTooltipPartFour:
      'The visible part of a face is not obscured nor tightly cropped and has the full head and shoulders.',
  },
  validationMessages: {
    required: '{{field}} is a required field',
    url: '{{field}} must be a valid URL',
    email: 'Please enter valid email',
    length: '{{field}} must be no more than {{length}} characters',
  },
  nav: {
    watch: 'Watch',
    subjects: 'Subjects',
    settings: 'Settings',
    events: 'Events',
    administration: 'Administration',
    alerts: 'Alerts',
    alertRoutes: 'Alert Routes',
    limits: 'Resource Limits Configuration',
    accLimits: 'Account Limits',
    userLimits: 'User Limits',
    alertManagement: 'Alert Management',
    reporting: 'Reporting',
    trainingMarkup: 'Training & Markup',
    environmentDesigner: 'Environment Designer',
    workflow: 'Workflow',
    cameras: 'Cameras',
    dashboard: 'Dashboard',
    users: 'Users',
    members: 'Members',
    equipmentManagement: 'Equipment Management',
    equipment: 'Equipment',
    roles: 'Roles',
    persons: 'Persons',
    cars: 'Cars',
    locations: 'Locations',
    equipmentTypes: 'Equipment Types',
    equipments: 'Equipments',
    accounts: 'Accounts',
    accountUsers: 'Account Users',
    accountLimits: 'Account Limits',
  },
  watch: {
    liveBtn: 'LIVE',
    latestAlertsTitle: 'Latest Alerts',
    latestActivityTitle: 'Latest Activity',
    activity: 'ACTIVITY',
    queue: 'QUEUE',
    faces: 'FACES',
    heatmap: 'HEATMAP',
    counting: 'COUNTING',
    alpr: 'ALPR',
    groups: 'GROUPS',
  },
  subjects: {
    buttonAddSubject: 'New Subject',
    alertsTitle: 'Alerts with {{name}}',
    sexMale: 'Male',
    sexFemale: 'Female',
    sexOther: 'Other',
    notFound: 'No Subjects',
    searchNotFound: 'No Subjects found',
  },
  settings: {
    tableLabelCameraName: 'Camera Name',
    tableLabelLocation: 'Location',
    buttonAddCamera: 'Add Camera',
    invalidUrl: 'Invalid url',
    invalidFrameRate: 'Frame rate should be in the range is from 1 to 6',
  },
  events: {
    placeholder: 'Select Camera',
  },
  signIn: {
    loginError: 'Wrong username or password',
    serverError: 'Something bad happened',
  },
  graphLegend: {
    alert: 'Alert',
    known: 'Known',
    unknown: 'Unknown',
    noRecord: 'No record',
    record: 'Record',
  },
  player: {
    options: 'OPTIONS',
    analytics: 'REALTIME ACTIVITY',
    accumAnalytics: 'ACCUMULATION ACTIVITY',
    workflow: 'WORKFLOWS',
    heatmap: 'HEAT MAP',
    queue: 'QUEUE',
    inout: 'IN/OUT',
  },
  environmentDesigner: {
    noSelected: 'No camera selected',
    noConnected: 'No camera connected',
    general: 'General',
    humans: 'Humans',
    objects: 'Objects',
    areas: 'Areas',
    faceRecognition: 'FACE RECOGNITION',
    movementsRecognition: 'MOVEMENTS RECOGNITION',
    gesturesRecognition: 'GESTURES RECOGNITION',
    emotionsRecognition: 'EMOTIONS RECOGNITION',
    groupsTracking: 'GROUPS TRACKING',
    recognition: 'Recognition',
    gender: 'Gender',
    selectByAge: 'Select by Age',
    multiple: 'Multiple',
    single: 'Single',
    newCamera: 'New Camera',
  },
  trainingMarkup: {
    project: 'Project',
    import: 'Import',
    export: 'Export',
    sections: 'SECTIONS',
    projectSettings: 'PROJECT SETTINGS',
    frameIDName: 'Frame ID-name',
    selectSectionsTrackers: 'Select sections to set it’s trackers',
    showClassesID: 'Show classes ID',
    transformation: 'Transformation',
    resize: 'Resize',
    undistortion: 'Undistortion',
    align: 'Align',
    crop: 'Crop',
    enableDnn: 'Enable DNN model prediction',
    section: 'Section',
    segmentation: 'Segmentation',
    predict: 'Predict',
    tracker: 'Tracker',
    uploadDnnButton: 'Upload DNN model',
  },
  equipment: {
    maintenanceHistory: 'Maintenance History',
    breakings: 'Breakings',
    maintenanceProcedures: 'Maintenance Procedures',
    equipmentId: 'Equipment ID',
    equipmentType: 'Equipment Type',
    description: 'Description',
    location: 'Location',
    maintenancePeriod: 'Maintenance Period',
    lastMaintenance: 'Last Maintenance',
    downtime: 'Downtime',
    problemID: 'Problem ID',
    operation: 'Operation',
  },
  training: {
    startTraining: 'Start Training',
    addDataset: 'Add Dataset',
    modelType: 'Model Type',
    baseModel: 'Base Model',
    dataset: 'Dataset',
    start: 'Start Training',
    seeMetrics: 'See Metrics',
  },
  reporting: {
    maintenanceReport: 'Maintenance Report',
    analyticalReport: 'Analytical Report',
  },
};
/* eslint-enable */
