import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import calendarIcon from '../../../assets/images/shapeCalendar.svg';

export function FormDatePicker({
  label,
  name,
  value,
  onChange,
  disabled,
  onBlur,
  maxDate,
  dropdownMode,
  className,
  placeholder,
  errorMessage,
}) {
  const [isFocus, setFocus] = useState(false);
  const pickerRef = useRef(null);

  const handleIconClick = () => {
    if (isFocus) {
      pickerRef.current.setBlur();
    } else {
      pickerRef.current.setFocus();
    }
  };

  const handleChange = (value) => {
    name ? onChange(name, value) : onChange(value);
  };

  const handleBlur = (e) => {
    setFocus(false);
    onBlur && onBlur(e);
  };

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={
          placeholder
            ? 'form-item--responsive-label--active'
            : value || value === 0 || isFocus
            ? 'form-item--responsive-label--active'
            : 'form-item--responsive-label'
        }
      >
        {label}
      </label>

      <DatePicker
        ref={pickerRef}
        name={name}
        placeholderText={placeholder}
        selected={value ? new Date(value) : null}
        onBlur={handleBlur}
        disabled={disabled}
        showMonthDropdown
        showYearDropdown
        dropdownMode={dropdownMode}
        maxDate={maxDate ? maxDate : new Date()}
        className={`form-control ${
          placeholder ? 'form-input--active' : value || value === 0 || isFocus ? 'form-input--active' : ''
        }`}
        onChange={handleChange}
        onFocus={() => setFocus(true)}
      />

      <img src={calendarIcon} alt="calendar" className="form-datepicker-icon" onClick={handleIconClick} />

      {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
    </div>
  );
}
