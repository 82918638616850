import React, { useEffect, useReducer, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  CustomInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import Loader from '../../../../components/Loader';
import i18n from '../../../../i18n';

import * as camerasActions from '../../../../actions/cameras.actions';
import * as workflowsActions from '../../../../actions/workflows.action';
import { getEquipments } from '../../../../actions/equipment.actions';
import * as modalActions from './actions';
import { reducer, initialState } from './reducer';

import { FormSelect } from '../../../../components/Form';
import DrawingPanel from '../../../../components/DrawingPanel/DrawingPanel';

import { attachModalTabs, triggersForZones } from '../../../../constants/workflows.constants';
import Form from '@rjsf/core';
import * as workflowActions from '../../../../actions/workflows.action';

const AttachCameraModal = ({
  title,
  workflowUuid,
  isOpen,
  onSubmit,
  onClose,
  isLoading,
  className,
  errorMsg,
  getCameras,
  verifyCamera,
  getTriggersForWorkflowCamera,
  getZonesForWorkflowCamera,
  updateWorkflowCameraTrigger,
  addCamerasToWorkflow,
  setZonesToWorkflowCamera,
  getWorkflows,
  getEquipments,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [triggerError, setTriggerError] = useState([]);

  const [cameraQuery, setCameraQuery] = useState({ name: '', page: 0, size: 20 });

  useEffect(() => {
    getCameras(
      {
        name: cameraQuery.name,
        page: 0,
        size: cameraQuery.page * cameraQuery.size + cameraQuery.size,
      },
      {
        onSuccess: (cameras) => {
          dispatch(
            modalActions.changeValue('availableCameras', [
              ...cameras.items.map((camera) => ({
                label: camera.name,
                value: camera.uuid,
                streamUrl: camera.streamUrl,
              })),
            ])
          );
        },
      }
    );
  }, [cameraQuery.name, cameraQuery.page]);

  const delayedQuery = useCallback(
    _.debounce((inputValue) => setCameraQuery({ name: inputValue, page: 0, size: 20 }), 500),
    []
  );

  const handleScrollToBottom = () => {
    setCameraQuery((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const handleInputChange = (searchString) => {
    delayedQuery(searchString);
  };

  const attachCamerasToWorkflow = (cameras) => {
    const camerasWithZones = cameras.map((camera) => {
      const cameraWithZones = {
        cameraId: camera.selected.value,
      };

      const lines = [];
      const roi = [];
      const zoi = [];
      camera.elements.forEach((element) => {
        switch (element.type) {
          case 'line':
            const existingLine = camera.loadedZones.lines
              ? camera.loadedZones.lines.find((line) => line.name === element.name)
              : null;
            const line = {
              endPoints: [
                { x: element.relative.x1, y: element.relative.y1 },
                { x: element.relative.x2, y: element.relative.y2 },
              ],
              id: existingLine && existingLine.id,
              name: element.name,
              normal: {
                x: element.normal.x,
                y: element.normal.y,
              },
            };
            lines.push(line);
            break;

          case 'rectangle':
            const existingRoi = camera.loadedZones.roi
              ? camera.loadedZones.roi.find((item) => item.name === element.name)
              : null;
            const minX = Math.min(element.relative.x1, element.relative.x2);
            const maxX = Math.max(element.relative.x1, element.relative.x2);
            const minY = Math.min(element.relative.y1, element.relative.y2);
            const maxY = Math.max(element.relative.y1, element.relative.y2);

            const rectangle = {
              id: existingRoi && existingRoi.id,
              name: element.name,
              x: minX,
              y: minY,
              width: maxX - minX,
              height: maxY - minY,
            };
            roi.push(rectangle);
            break;

          case 'polygon':
            const existingPolygon = camera.loadedZones.zoi
              ? camera.loadedZones.zoi.find((item) => item.name === element.name)
              : null;
            const polygon = {
              id: existingPolygon && existingPolygon.id,
              name: element.name,
              points: element.relPoints,
              equipmentUuid: camera.elementOptions[element.name]?.equipment?.value,
            };
            zoi.push(polygon);
            break;

          case 'doublePolygon':
            if (element.entrypoint.length) {
              const existingDPolygon = camera.loadedZones.zoi
                ? camera.loadedZones.zoi.find((item) => item.name === element.name)
                : null;
              const doublePolygon = {
                id: existingDPolygon && existingDPolygon.id,
                name: element.name,
                points: element.relPoints,
                entrypoint: element.relEntrypoint,
              };
              zoi.push(doublePolygon);
            }
            break;
        }
      });

      cameraWithZones.zones = {
        lines,
        roi,
        zoi,
      };

      return cameraWithZones;
    });

    addCamerasToWorkflow(
      workflowUuid,
      camerasWithZones.map((camera) => ({ cameraId: camera.cameraId })),
      {
        onSuccess: () => {
          setZonesToWorkflowCameras(camerasWithZones);
          getWorkflows(
            {},
            {
              onSuccess: (wfs) => {
                getWorkflows({ size: wfs.total, page: 0 });
              },
            }
          );
        },
        onError: () => {
          setZonesToWorkflowCameras(camerasWithZones);
        },
      }
    );
  };

  const setZonesToWorkflowCameras = (camerasWithZones) => {
    camerasWithZones.forEach((camera) => {
      setZonesToWorkflowCamera(workflowUuid, camera.cameraId, camera.zones, {
        onSuccess: (zones) => {
          const cameraIndex = state.cameras.findIndex((item) => item.selected.value === camera.cameraId);
          dispatch(modalActions.changeCamera(cameraIndex, 'loadedZones', zones));
        },
      });
    });
  };

  const modifyTriggerSchema = (schema) => {
    const schemaCopy = { ...schema };

    schemaCopy.items.properties.zoneId = {
      ...schemaCopy.items.properties.zoneId,
      enum: [],
    };

    return schemaCopy;
  };

  const verifyModal = () => {
    let errorArray = [];
    const triggers = state.triggers.filter((trigger) => Object.keys(trigger.config).length);
    const uploadTriggers = triggers.map((trigger) => {
      let config = {};
      if (triggersForZones.includes(trigger.selectedTrigger.type)) {
        config = trigger.config.map((item) => ({
          ...item,
          zoneId: trigger.zones.find((zone) => zone.label === item.zoneId).value,
        }));
      } else {
        config = trigger.config;
      }

      return {
        analyticsType: trigger.selectedTrigger.analyticsType,
        analyticsUuid: trigger.selectedTrigger.analyticsUuid,
        config: config,
        enabled: trigger.enabled,
        schema: trigger.selectedTrigger.schema,
        type: trigger.selectedTrigger.type,
        uuid: trigger.selectedTrigger.uuid,
      };
    });

    uploadTriggers.length &&
      uploadTriggers.forEach((trigger, index) =>
        updateWorkflowCameraTrigger(
          workflowUuid,
          state.triggers[index].selectedCamera.value,
          trigger.uuid,
          trigger,
          {
            onSuccess: () => {
              index === triggers.length - 1 && errorArray.length === 0 && onClose();
            },
            onError: () => {
              errorArray.push(trigger.type);
              index === triggers.length - 1 && setTriggerError(errorArray);
            },
          }
        )
      );

    const cameras = state.cameras.filter((camera) => camera.selected);
    attachCamerasToWorkflow(cameras);

    onSubmit(cameras);
  };

  const getCameraOptions = () => {
    return state.availableCameras.filter(
      (cameraOption) =>
        !state.cameras.some((camera) => camera.selected && camera.selected.value === cameraOption.value)
    );
  };

  const switchTab = (tab) => {
    if (state.availableTabs.includes(tab)) {
      if (state.activeTab === attachModalTabs[0].value) {
        const cameras = state.cameras.filter((camera) => camera.selected);
        attachCamerasToWorkflow(cameras);

        dispatch(modalActions.changeValue('activeTab', tab));
      } else {
        dispatch(modalActions.changeValue('activeTab', tab));
      }
    }
  };

  const selectCamera = (index, camera) => {
    dispatch(modalActions.changeCamera(index, 'selected', camera));
    dispatch(modalActions.changeCamera(index, 'loading', true));
    verifyCamera(camera.streamUrl, {
      onSuccess: (res) => {
        dispatch(modalActions.changeCamera(index, 'cameraFrame', `data:image/jpeg;base64,${res.data}`));
        dispatch(modalActions.changeCamera(index, 'loading', false));
      },
      onError: (error) => {
        dispatch(modalActions.changeCamera(index, 'loading', false));
        dispatch(modalActions.changeCamera(index, 'loadingError', "Can't load frame!"));
      },
    });
  };

  const selectTriggerCamera = (index, camera) => {
    dispatch(modalActions.changeTrigger(index, 'config', {}));
    dispatch(modalActions.changeTrigger(index, 'selectedTrigger', null));
    dispatch(modalActions.changeTrigger(index, 'availableTriggers', []));
    dispatch(modalActions.changeTrigger(index, 'selectedCamera', camera));

    getZonesForWorkflowCamera(workflowUuid, camera.value, {
      onSuccess: (data) => {
        dispatch(
          modalActions.changeTrigger(
            index,
            'zones',
            data.zoi.map((zone) => ({ label: zone.name, value: zone.id }))
          )
        );
        if (
          state.triggers[index].selectedTrigger &&
          triggersForZones.includes(state.triggers[index].selectedTrigger.type)
        ) {
          const triggerCopy = { ...state.triggers[index].selectedTrigger };
          triggerCopy.schema.items.properties.zoneId.enum = data.zoi.map((zone) => zone.name);
          dispatch(modalActions.changeTrigger(index, 'selectedTrigger', triggerCopy));
        }
      },
    });

    getTriggersForWorkflowCamera(
      workflowUuid,
      camera.value,
      { scope: 'DATA_STREAM' },
      {
        onSuccess: (triggers) => {
          dispatch(
            modalActions.changeTrigger(index, 'availableTriggers', [
              ...triggers.map((trigger) => ({
                ...trigger,
                label: trigger.type,
                value: trigger.uuid,
                schema: triggersForZones.includes(trigger.type)
                  ? modifyTriggerSchema(trigger.schema)
                  : trigger.schema,
              })),
            ])
          );
        },
      }
    );
  };

  const selectTriggerType = (index, trigger) => {
    dispatch(modalActions.changeTrigger(index, 'config', {}));
    if (state.triggers[index].selectedCamera && triggersForZones.includes(trigger.type)) {
      trigger.schema.items.properties.zoneId.enum = state.triggers[index].zones.map((zone) => zone.label);
    }
    dispatch(modalActions.changeTrigger(index, 'selectedTrigger', trigger));
  };

  const generateCameras = state.cameras.map((camera, index) => (
    <div key={index} className="form__creation">
      <div className="form__creation__header">
        <div className="form-row">
          <FormSelect
            label={i18n.t('workflowModals:labelSelectedCamera')}
            selected={camera.selected}
            options={getCameraOptions()}
            isSearch={true}
            className="form-item form-item--one"
            onInputChange={handleInputChange}
            onScrollToBottom={handleScrollToBottom}
            onChange={(camera) => selectCamera(index, camera)}
            errorMessage={false}
          />
        </div>

        <button
          className="form__creation__header-btn"
          onClick={() => dispatch(modalActions.removeCamera(index))}
        >
          <FontAwesomeIcon icon={faTimes} className="form__creation__header-times" />
        </button>
      </div>

      {camera.selected && (
        <div className="camera-preview">
          {camera.loading && (
            <div className="connect">
              <Loader loading={camera.loading} />
              <span>{i18n.t('cameraModals:previewLoading')}</span>
            </div>
          )}
          {!camera.loading && camera.loadingError && (
            <div className="error">
              <FontAwesomeIcon icon="unlink" />
              <span>{i18n.t('cameraModals:previewError')}</span>
            </div>
          )}
          {camera.cameraFrame && (
            <DrawingPanel
              className="mt-20"
              frame={camera.cameraFrame}
              id={index}
              elements={camera.elements}
              setElements={(elements) => {
                dispatch(modalActions.changeCamera(index, 'elements', elements));
              }}
              elementOptions={camera.elementOptions}
              setElementOptions={(options) => {
                dispatch(modalActions.changeCamera(index, 'elementOptions', options));
              }}
              getEquipments={getEquipments}
            />
          )}
        </div>
      )}
    </div>
  ));

  const generateTriggers = state.triggers.map((trigger, index) => (
    <div key={index} className="form__creation">
      <div className="form__creation__header">
        <div className="form-row">
          <FormSelect
            label={i18n.t('workflowModals:labelSelectedCamera')}
            selected={trigger.selectedCamera}
            options={
              state.cameras.some((camera) => camera.selected)
                ? state.cameras.map((camera) => {
                    if (camera.selected)
                      return {
                        label: camera.selected.label,
                        value: camera.selected.value,
                      };
                  })
                : []
            }
            isSearch={true}
            className="form-item form-item--two"
            onChange={(camera) => selectTriggerCamera(index, camera)}
            errorMessage={false}
          />

          <FormSelect
            label={i18n.t('workflowModals:labelTriggerType')}
            selected={trigger.selectedTrigger}
            options={trigger.availableTriggers.length ? trigger.availableTriggers : []}
            isSearch={true}
            className="form-item form-item--two"
            onChange={(trigger) => selectTriggerType(index, trigger)}
            errorMessage={false}
          />
        </div>

        <button
          className="form__creation__header-btn"
          onClick={() => dispatch(modalActions.removeTrigger(index))}
        >
          <FontAwesomeIcon icon={faTimes} className="form__creation__header-times" />
        </button>
      </div>

      {trigger.selectedCamera && trigger.selectedTrigger ? (
        triggersForZones.includes(trigger.selectedTrigger.type) && !trigger.zones.length ? (
          'Has no zones'
        ) : (
          <>
            <div className="form-row_mt-10">
              <div className="form-switch-item">
                <CustomInput
                  type="switch"
                  checked={trigger.enabled}
                  onChange={(e) => dispatch(modalActions.changeTrigger(index, 'enabled', e.target.checked))}
                  id={`${trigger.triggerType}-${index}`}
                  name="Enabled"
                  className="custom-switch--small"
                />
                <p className="form-switch-label">{i18n.t('workflowModals:labelEnabled')}</p>
              </div>
            </div>

            <div className="form__creation-fields">
              <Form
                schema={trigger.selectedTrigger.schema}
                formData={trigger.config}
                onChange={(data) => {
                  dispatch(modalActions.changeTrigger(index, 'config', data.formData));
                }}
                onError={() => {}}
                children={[]}
              />
            </div>
          </>
        )
      ) : null}
    </div>
  ));

  return (
    <>
      <Modal isOpen={isOpen} className={cx('', className)} fade>
        <ModalHeader toggle={onClose}>{title}</ModalHeader>

        <ModalBody>
          <Nav tabs className="workflow-form__camera-tabs">
            <NavItem>
              <NavLink
                className={cx({ active: state.activeTab === attachModalTabs[0].value })}
                onClick={() => switchTab(attachModalTabs[0].value)}
                disabled={!state.availableTabs.includes(attachModalTabs[0].value)}
              >
                {i18n.t('workflowModals:camerasTab')}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={cx({ active: state.activeTab === attachModalTabs[1].value })}
                onClick={() => switchTab(attachModalTabs[1].value)}
                disabled={!state.availableTabs.includes(attachModalTabs[1].value)}
              >
                {i18n.t('workflowModals:triggersTab')}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={state.activeTab} className="workflow-form__tab-content">
            <TabPane tabId={attachModalTabs[0].value}>
              {generateCameras}

              <button className="form__creation__add-btn" onClick={() => dispatch(modalActions.addCamera())}>
                <FontAwesomeIcon icon={faPlus} />
                Add Camera
              </button>
            </TabPane>

            <TabPane tabId={attachModalTabs[1].value}>
              {generateTriggers}

              <button
                className="form__creation__add-btn"
                onClick={() => dispatch(modalActions.addTrigger())}
                disabled={!state.cameras.some((camera) => camera.selected)}
              >
                <FontAwesomeIcon icon={faPlus} />
                Add Trigger
              </button>
            </TabPane>
          </TabContent>
        </ModalBody>

        <ModalFooter>
          <Loader loading={isLoading} />
          {/*{message || null}*/}
          <button type="button" className="btn btn--dark" disabled={isLoading} onClick={onClose}>
            {i18n.t('buttons:cancel')}
          </button>

          <button className="btn btn--secondary" type="submit" disabled={false} onClick={verifyModal}>
            {i18n.t('buttons:save')}
          </button>
        </ModalFooter>
      </Modal>
      {triggerError.length > 0 && (
        <Modal isOpen={triggerError.length > 0} fade>
          <ModalHeader toggle={() => setTriggerError([])}>
            {i18n.t('workflowModals:errorTrigger')}
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              These triggers have not been validated and workflow is saved without them:
            </div>
            {triggerError.map((item) => (
              <div className="form-row">{item}</div>
            ))}
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn--secondary"
              type="submit"
              onClick={() => {
                onClose();
                setTriggerError([]);
              }}
            >
              {i18n.t('buttons:ok')}
            </button>
          </ModalFooter>
        </Modal>
      )}
      x
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getWorkflows: (params, actions) => dispatch(workflowActions.getWorkflows(params, actions)),
  getCameras: (params, actions) => dispatch(camerasActions.getCameras(params, actions)),
  verifyCamera: (url, actions) => dispatch(camerasActions.verifyCamera(url, actions)),
  getTriggersForWorkflowCamera: (workflowUuid, cameraUuid, params, actions) =>
    dispatch(workflowsActions.getTriggersForWorkflowCamera(workflowUuid, cameraUuid, params, actions)),
  getZonesForWorkflowCamera: (workflowUuid, cameraUuid, actions) =>
    dispatch(workflowsActions.getZonesForWorkflowCamera(workflowUuid, cameraUuid, actions)),
  updateWorkflowCameraTrigger: (workflowUuid, cameraUuid, triggerUuid, params, actions) =>
    dispatch(
      workflowsActions.updateWorkflowCameraTrigger(workflowUuid, cameraUuid, triggerUuid, params, actions)
    ),
  addCamerasToWorkflow: (uuid, params, actions) =>
    dispatch(workflowActions.addCamerasToWorkflow(uuid, params, actions)),
  setZonesToWorkflowCamera: (workflowUuid, cameraUuid, params, actions) =>
    dispatch(workflowActions.setZonesToWorkflowCamera(workflowUuid, cameraUuid, params, actions)),
  getEquipments: (params, action) => dispatch(getEquipments(params, action)),
});

export default connect(null, mapDispatchToProps)(AttachCameraModal);
