export const GET_MODELS = 'GET_MODELS';
export const GET_MODELS_RESULT = 'GET_MODELS_RESULT';
export const GET_MODELS_ERROR = 'GET_MODELS_ERROR';

export const GET_MODEL = 'GET_MODEL';

export const ADD_MODEL = 'ADD_MODEL';
export const ADD_MODEL_SUCCESS = 'ADD_MODEL_SUCCESS';
export const ADD_MODEL_ERROR = 'ADD_MODEL_ERROR';

export const UPDATE_MODEL = 'UPDATE_MODEL';
export const UPDATE_MODEL_SUCCESS = 'UPDATE_MODEL_SUCCESS';
export const UPDATE_MODEL_ERROR = 'UPDATE_MODEL_ERROR';

export const DELETE_MODEL = 'DELETE_MODEL';
export const DELETE_MODEL_SUCCESS = 'DELETE_MODEL_SUCCESS';
export const DELETE_MODEL_ERROR = 'DELETE_MODEL_ERROR';
