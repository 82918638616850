import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Table from '../../components/Table';
import i18n from '../../i18n';

import * as limitsActions from '../../actions/limits.actions';
import * as schemasActions from '../../actions/schemas.action';
import * as accountActions from '../../actions/accounts.action';

import DropdownDotMenu from '../../components/DropdownDotMenu';
import LimitModal from './Modal';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormSelect } from '../../components/Form';

const LimitsList = ({
  items,
  accounts,
  total,
  size,
  page,
  processingTypes,
  modalInf,
  isLoading,
  getLimits,
  addLimit,
  updateLimit,
  deleteLimit,
  changePageValue,
  changeModalValue,
  changeValue,
  getAccounts,
  getProcessingTypes,
  match,
}) => {
  const [modalAdd, setAddModal] = useState(false);
  const [modalEdit, setEditModal] = useState(false);
  const [modalChoise, setModalChoise] = useState(false);
  const [uuid, setUuid] = useState(null);
  const [filter, setFilter] = useState('All');

  useEffect(() => {
    match.params.uuid
      ? getLimits({ accountUuid: match.params.uuid })
      : getAccounts(
          {},
          {
            onSuccess: () => {
              setModalChoise(true);
            },
          }
        );
    getProcessingTypes({ onSuccess: (data) => changeValue('processingTypes', data) });
    return changeValue('accList', { items: [], total: 0 });
  }, []);

  useEffect(() => {
    (match.params.uuid || uuid) &&
      getLimits({
        accountUuid: match.params.uuid ? match.params.uuid : uuid.vlue,
        processingType: filter === 'All' ? null : filter,
      });
  }, [total, page, filter]);

  const createRows = (rows) => {
    return rows.map((limit) => ({
      id: limit.uuid,
      cells: [
        {
          label: limit.processingType ? limit.processingType : '-',
        },
        {
          label: limit.resource,
        },
        {
          label: limit.limit + '',
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                {
                  name: 'Edit',
                  onClick: () => {
                    changeValue('modalInf', {
                      limit: limit.limit,
                      procType: { label: limit.processingType, value: limit.processingType },
                      resource: { label: limit.resource, value: limit.resource },
                      uuid: limit.uuid,
                    });
                    setEditModal(true);
                  },
                },
                { divider: true },
                {
                  name: 'Delete',
                  onClick: () => {
                    deleteLimit(limit.uuid);
                  },
                },
              ]}
            />
          ),
        },
      ],
    }));
  };
  const headerOptions = [
    {
      label: 'Processing Type',
    },
    {
      label: 'Resource',
    },
    {
      label: 'Limit',
    },
    { label: '' },
  ];

  const onClose = () => {
    setAddModal(false);
    setEditModal(false);
    changeValue('modalInf', {
      limit: 0,
      procType: '',
      resource: '',
      uuid: '',
    });
  };

  const onChooseAccount = () => {
    getLimits({ accountUuid: uuid.value });
    setModalChoise(false);
  };

  return (
    <>
      <Table
        rows={createRows(items ? items : [])}
        headerOptions={headerOptions}
        title={i18n.t('nav:accLimits')}
        totalRows={total ? total : 0}
        size={size}
        page={page}
        filters={[
          {
            title: 'Processing Type',
            options: processingTypes.map((type) => ({ name: type.label, value: type.value })),
          },
        ]}
        onChangeFilters={(title, key) => setFilter(key)}
        changePage={(page) => changePageValue('accQuery', page)}
        onClick={() => setAddModal(true)}
      />
      {modalAdd && (
        <LimitModal
          modal={modalAdd}
          onClose={() => onClose()}
          title="addTitle"
          procTypes={processingTypes}
          info={modalInf}
          onChange={(name, value) => changeModalValue(name, value)}
          onSubmit={() => {
            addLimit({
              account: {
                accountType: accounts?.list.items.find((acc) => acc.uuid == uuid.value).accountType,
                name: accounts?.list.items.find((acc) => acc.uuid == uuid.value).name,
                uuid: uuid.value,
              },
              limit: modalInf.limit,
              processingType: modalInf.procType.value,
              resource: modalInf.resource.value,
            });
            onClose();
          }}
        />
      )}
      {modalEdit && (
        <LimitModal
          modal={modalEdit}
          onClose={() => onClose()}
          title="editTitle"
          procTypes={processingTypes}
          info={modalInf}
          onChange={(name, value) => changeModalValue(name, value)}
          onSubmit={() => {
            updateLimit({
              account: {
                accountType: accounts?.list.items.find((acc) => acc.uuid == uuid.value).accountType,
                name: accounts?.list.items.find((acc) => acc.uuid == uuid.value).name,
                uuid: uuid.value,
              },
              limit: modalInf.limit,
              processingType: modalInf.procType.value,
              resource: modalInf.resource.value,
            });
            onClose();
          }}
        />
      )}
      {modalChoise && (
        <Modal isOpen={modalChoise} size="sm">
          <ModalHeader toggle={() => setModalChoise(false)}>Accounts</ModalHeader>
          <ModalBody>
            <p className="m-2">Choose Account</p>
            <FormSelect
              name="account"
              label={i18n.t('limitsModals:account')}
              selected={uuid}
              isSearch={true}
              options={accounts?.list.items.map((it) => ({ label: it.name, value: it.uuid }))}
              className="form-item form-item--one"
              onChange={(name, value) => {
                setUuid(value);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <button className="btn btn--dark" onClick={() => setModalChoise(false)}>
              {i18n.t('buttons:cancel')}
            </button>
            <button className="btn btn--secondary" onClick={() => onChooseAccount()}>
              {i18n.t('buttons:save')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = ({ limits, accounts }) => {
  const {
    accList: { items, total },
    accQuery: { size, page },
    isLoading,
    processingTypes,
    modalInf,
  } = limits;
  return { items, total, size, isLoading, modalInf, page, processingTypes, accounts };
};

const mapDispatchToProps = (dispatch) => ({
  getLimits: (params, actions) => dispatch(limitsActions.getAccountLimits(params, actions)),
  addLimit: (params, actions) => dispatch(limitsActions.addAccountLimit(params, actions)),
  updateLimit: (params, actions) => dispatch(limitsActions.updateAccountLimit(params, actions)),
  deleteLimit: (uuid, actions) => dispatch(limitsActions.deleteAccountLimit(uuid, actions)),
  changePageValue: (name, page) => dispatch(limitsActions.changePageValue(name, page)),
  changeModalValue: (key, value) => dispatch(limitsActions.changeModalValue(key, value)),
  changeValue: (key, value) => dispatch(limitsActions.changeValue(key, value)),
  getProcessingTypes: (actions) => dispatch(schemasActions.getProcessingTypes(actions)),
  getAccounts: (params, actions) => dispatch(accountActions.getAccounts(params, actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LimitsList);
