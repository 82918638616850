import { takeEvery, call, put, select, all, take } from 'redux-saga/effects';

import * as api from '../api/locations';
import * as ActionTypes from '../constants/locations.constant';

export function* getLocations({ payload, actions = {} }) {
  const locations = yield select((s) => s.locations);
  try {
    const response = yield call(api.getLocations, { ...locations.query, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_LOCATIONS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_LOCATIONS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getLocation({ payload, actions = {} }) {
  try {
    const response = yield call(api.getLocation, payload.uuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addLocation({ payload, actions = {} }) {
  try {
    const response = yield call(api.addLocation, payload.params);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateLocation({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateLocation, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteLocation({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteLocation, payload.uuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.GET_LOCATIONS, getLocations),
    takeEvery(ActionTypes.GET_LOCATION, getLocation),
    takeEvery(ActionTypes.ADD_LOCATION, addLocation),
    takeEvery(ActionTypes.UPDATE_LOCATION, updateLocation),
    takeEvery(ActionTypes.DELETE_LOCATION, deleteLocation),
  ]);
}
