import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import DatetimeRangePicker from 'react-datetime-range-picker';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import i18n from '../../i18n';
import { Table } from 'reactstrap';

import ReportFilter from '../../components/Report/ReportFilter';
import DrawingLayer from '../../components/DrawingLayer/DrawingLayer';

import * as reportActions from '../../actions/reports.action';
import * as workflowActions from '../../actions/workflows.action';
import * as cameraActions from '../../actions/cameras.actions';

import { base64FromString } from '../../utils';
import { contrastingColors } from '../../constants/workflows.constants';
import {
  analyticsForReports,
  analyticsWithPreview,
  analyticsWithTable,
} from '../../constants/reports.constants';

import { analyticalReports } from '../../mocks/analyticalReports';
import { getPersons } from '../../actions/subject.actions';
import Avatar from '../../components/Avatar';

const AnalyticalReportsPage = ({
  reportWorkflowType,
  analyticFilters,
  changeValue,
  changeAnalyticFilter,
  resetAnalyticFilters,
  getWorkflows,
  getWorkflowAnalytics,
  getCameras,
  getPersons,
  verifyCamera,
  getZonesForWorkflowCamera,
}) => {
  const [tWidth, setTWidth] = useState(0);
  const [tHeight, setTHeight] = useState(0);
  const [workflowOptions, setWorkflowOptions] = useState([]);
  const [cameraOptions, setCameraOptions] = useState([]);
  const [cameraUrls, setCameraUrls] = useState({});
  const [savedFilters, setSavedFilters] = useState({});
  const [reportData, setReportData] = useState({});
  const [reportImage, setReportImage] = useState(null);
  const [reportZones, setReportZones] = useState({});
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectedAnalytic, setSelectedAnalytic] = useState({
    label: 'Accumulated Zones Activity',
    value: 'accumulated',
  });
  const [personsAvatars, setPersonsAvatars] = useState({});
  const [loadings, setLoadings] = useState({
    imageLoading: false,
    zonesLoading: false,
    reportLoading: false,
  });

  const imageRef = useRef(null);
  const pickerRef = useRef(null);

  useEffect(() => {
    changeAnalyticFilter('fromDate', new Date());
    changeAnalyticFilter('toDate', new Date());
    getWorkflows(
      {
        page: 0,
        size: 100,
      },
      {
        onSuccess: (workflows) =>
          setWorkflowOptions(
            workflows.items.map((workflow) => ({
              label: workflow.name,
              value: workflow.uuid,
              type: workflow.processingConfig.type,
              cameras: workflow.cameras.map((camera) => ({
                label: camera.name,
                value: camera.uuid,
              })),
            }))
          ),
      }
    );
    getCameras(
      {
        page: 0,
        size: 100,
      },
      {
        onSuccess: (cameras) => {
          setCameraOptions(
            cameras.items.map((camera) => ({
              label: camera.name,
              value: camera.uuid,
            }))
          );
          setCameraUrls(cameras.items.reduce((acc, curr) => ({ ...acc, [curr.uuid]: curr.streamUrl }), {}));
        },
      }
    );
    getPersons(
      {
        page: 0,
        size: 100,
      },
      {
        onSuccess: (persons) => {
          setPersonsAvatars(
            persons.items.reduce((acc, curr) => ({ ...acc, [curr.uuid]: curr.photos[0].url }), {})
          );
        },
      }
    );

    window.addEventListener('resize', handleResizeWindow);

    return () => {
      window.removeEventListener('resize', handleResizeWindow);
      resetAnalyticFilters();
    };
  }, []);

  const getReportData = (workflowUuid, cameraUuid, actions) => {
    const analyticalReport = analyticalReports.find(
      (report) => report.workflowUuid === workflowUuid && report.cameraUuid === cameraUuid
    );

    if (analyticalReport) {
      actions.onSuccess(analyticalReport.reportData);
    } else {
      actions.onError('Not found');
    }
  };

  const loadReportData = () => {
    setSavedFilters({
      workflow: analyticFilters.workflow.label,
      camera: analyticFilters.camera.label,
      fromDate: analyticFilters.fromDate,
      toDate: analyticFilters.toDate,
    });
    setReportImage(null);
    setReportZones({});
    setReportData({});
    setLoadings({
      imageLoading: true,
      zonesLoading: true,
      reportLoading: true,
    });
    getWorkflowAnalytics(analyticFilters.workflow.value, {
      onSuccess: (analytics) => {
        const analyticTypes = analytics.map((analytic) => analytic.type);

        if (!analyticTypes.some((analyticType) => analyticsForReports.includes(analyticType))) {
          setReportData({ default: 'Does not accumulate information' });
          setLoadings({
            imageLoading: false,
            zonesLoading: false,
            reportLoading: false,
          });
          return;
        }

        if (analyticTypes.some((analyticType) => analyticsWithPreview.includes(analyticType))) {
          verifyCamera(cameraUrls[analyticFilters.camera.value], {
            onSuccess: (response) => {
              if (response.success) {
                setReportImage(response.data);
              }
              setLoadings((prev) => ({ ...prev, imageLoading: false }));
            },
            onError: (error) => setLoadings((prev) => ({ ...prev, imageLoading: false })),
          });
        } else {
          setLoadings((prev) => ({ ...prev, imageLoading: false }));
        }

        getZonesForWorkflowCamera(analyticFilters.workflow.value, analyticFilters.camera.value, {
          onSuccess: (zones) => {
            analyticTypes.map((analyticType) => {
              switch (analyticType) {
                case 'LineCrossingObjectsCounting':
                  setReportZones({ lines: zones.lines });
                  break;

                case 'GroupsDetectorAnalytics':
                case 'QueuesDetectorAnalytics':
                  setReportZones({ ...zones });
                  break;

                case 'ZonesAccumulationAnalytics':
                  setReportZones({ zoi: zones.zoi });
                  break;

                case 'ZonesRealTimeActivityDurationAnalytics':
                  setReportZones({ zoi: zones.zoi });
                  break;
              }
            });
            setLoadings((prev) => ({ ...prev, zonesLoading: false }));
          },
          onError: (error) => setLoadings((prev) => ({ ...prev, zonesLoading: false })),
        });

        getReportData(analyticFilters.workflow.value, analyticFilters.camera.value, {
          onSuccess: (reports) => {
            setReportData(reports);
            setLoadings((prev) => ({ ...prev, reportLoading: false }));
          },
          onError: (error) => setLoadings((prev) => ({ ...prev, reportLoading: false })),
        });
      },
    });
  };

  const createTableReports = (reports) => {
    const analytics = Object.keys(reports);
    if (!analytics.some((analytic) => analyticsWithTable.includes(analytic))) return null;

    return (
      <div>
        {analytics.map((analytic) => {
          switch (analytic) {
            case 'SubjectsPlateAnalyzer': {
              return (
                <Table
                  responsive
                  style={{ margin: '0 auto', maxWidth: '700px', color: 'white', fontSize: '14px' }}
                  className="report-table"
                >
                  <thead>
                    <tr>
                      <th>Plate Label</th>
                      <th>Owner Name</th>
                      <th>Owner Working Place</th>
                      <th>Model</th>
                      <th>Vehicle Type</th>
                      <th>Color</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reports['SubjectsPlateAnalyzer'].map((item, index) => (
                      <tr key={index}>
                        <td>{item.plateLabel || '-'}</td>
                        <td>{item.owner || '-'}</td>
                        <td>{item.workingPlace || '-'}</td>
                        <td>{item.model || '-'}</td>
                        <td>{item.vehicleType || '-'}</td>
                        <td>{item.color || '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              );
              break;
            }

            case 'SubjectsVectorAnalyzer': {
              return (
                <Table
                  responsive
                  style={{ margin: '0 auto', maxWidth: '500px', color: 'white', fontSize: '14px' }}
                  className="report-table"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Working Place</th>
                      <th>Sex</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reports['SubjectsVectorAnalyzer'].map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <Avatar avatarUrl={personsAvatars[item.uuid]} width={40} name={item.name} />
                            <h5 className="table__cell table__cell--bold ml-2">{item.name}</h5>
                          </div>
                        </td>
                        <td>{item.workingPlace || '-'}</td>
                        <td>{item.sex || '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              );
              break;
            }

            case 'GroupsDetectorAnalytics': {
              return (
                <Table
                  responsive
                  style={{ margin: '0 auto', maxWidth: '500px', color: 'white', fontSize: '14px' }}
                  className="report-table"
                >
                  <tbody>
                    <tr>
                      <td>Minimum distance between people in a group</td>
                      <td>{reports['GroupsDetectorAnalytics'].minDistance || '-'}</td>
                    </tr>
                    <tr>
                      <td>Average distance between people in a group</td>
                      <td>{reports['GroupsDetectorAnalytics'].avgDistance || '-'}</td>
                    </tr>
                    <tr>
                      <td>Maximum group size</td>
                      <td>{reports['GroupsDetectorAnalytics'].maxGroupSize || '-'}</td>
                    </tr>
                    <tr>
                      <td>Average group size</td>
                      <td>{reports['GroupsDetectorAnalytics'].avgGroupSize || '-'}</td>
                    </tr>
                    <tr>
                      <td>LargeGroup alerts count</td>
                      <td>
                        {reports['GroupsDetectorAnalytics'].largeGroupAlertsCount(
                          moment(savedFilters.toDate).format('X') - moment(savedFilters.fromDate).format('X')
                        ) || '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>GroupMinDistance alerts count</td>
                      <td>
                        {reports['GroupsDetectorAnalytics'].groupMinDistanceAlertsCount(
                          moment(savedFilters.toDate).format('X') - moment(savedFilters.fromDate).format('X')
                        ) || '-'}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              );
              break;
            }

            case 'QueuesDetectorAnalytics': {
              return (
                <Table
                  responsive
                  style={{ margin: '0 auto', maxWidth: '500px', color: 'white', fontSize: '14px' }}
                  className="report-table"
                >
                  <tbody>
                    <tr>
                      <td>Minimum queue size</td>
                      <td>{reports['QueuesDetectorAnalytics'].minQueueSize || '-'}</td>
                    </tr>
                    <tr>
                      <td>Maximum queue size</td>
                      <td>{reports['QueuesDetectorAnalytics'].maxQueueSize || '-'}</td>
                    </tr>
                    <tr>
                      <td>Average queue size</td>
                      <td>{reports['QueuesDetectorAnalytics'].avgQueueSize || '-'}</td>
                    </tr>
                    <tr>
                      <td>Median queue size</td>
                      <td>{reports['QueuesDetectorAnalytics'].medQueueSize || '-'}</td>
                    </tr>
                    <tr>
                      <td>LargeQueue alerts count</td>
                      <td>
                        {reports['QueuesDetectorAnalytics'].largeQueueAlertsCount(
                          moment(savedFilters.toDate).format('X') - moment(savedFilters.fromDate).format('X')
                        ) || '-'}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              );
              break;
            }

            case 'LineCrossingObjectsCounting': {
              const linesIds = Object.keys(reports['LineCrossingObjectsCounting'].lines);
              return (
                <div className="report__elements-wrapper">
                  <div className="report__element">
                    <h2 className="report__element__title">Total</h2>
                    <div className="info-block_inout">
                      <div className="info-block__item">
                        <h3>In</h3>
                        <span>{reports['LineCrossingObjectsCounting'].total.IN}</span>
                      </div>
                      <div className="info-block__item">
                        <h3>Out</h3>
                        <span>{reports['LineCrossingObjectsCounting'].total.OUT}</span>
                      </div>
                    </div>
                  </div>
                  {linesIds.map((lineId, index) => (
                    <div key={index} className="report__element">
                      <h2 className="report__element__title" style={{ color: contrastingColors[index] }}>
                        {reportZones.lines.find((line) => line.id === lineId).name || '-'}
                      </h2>
                      <div className="info-block_inout">
                        <div className="info-block__item">
                          <h3>In</h3>
                          <span>{reports['LineCrossingObjectsCounting'].lines[lineId].IN}</span>
                        </div>
                        <div className="info-block__item">
                          <h3>Out</h3>
                          <span>{reports['LineCrossingObjectsCounting'].lines[lineId].OUT}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
              break;
            }

            case 'ZonesAccumulationAnalytics': {
              const zonesIds = Object.keys(reports['ZonesAccumulationAnalytics'].zones);

              return (
                <>
                  <div className="w-100 mb-3">
                    <ReportFilter
                      title="Workflow"
                      options={[
                        { label: 'Accumulated Zones Activity', value: 'accumulated' },
                        { label: 'Real Time Zones Activity', value: 'realtime' },
                      ]}
                      selected={selectedAnalytic}
                      onClick={(value) => {
                        console.log(value);
                        setSelectedAnalytic(value);
                      }}
                    />
                  </div>
                  {selectedAnalytic.value === 'accumulated' && (
                    <div className="report__elements-wrapper">
                      {zonesIds.map((zoneId, index) => (
                        <div key={index} className="report__element">
                          <h2 className="report__element__title" style={{ color: contrastingColors[index] }}>
                            {reportZones.zoi.find((zone) => zone.id === zoneId).name || '-'}
                          </h2>
                          <div className="info-block">
                            <div className="info-block__item">
                              <h3>Duration</h3>
                              <span>
                                {moment
                                  .duration(
                                    reports['ZonesAccumulationAnalytics'].zones[zoneId].duration,
                                    'seconds'
                                  )
                                  .format('d[d] h[h] m[m] s[s]') || '-'}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              );
              break;
            }

            case 'ZonesRealTimeActivityDurationAnalytics': {
              const zonesIds = Object.keys(reports['ZonesRealTimeActivityDurationAnalytics'].zones);
              return (
                selectedAnalytic.value === 'realtime' && (
                  <div className="report__elements-wrapper">
                    {zonesIds.map((zoneId, index) => (
                      <div key={index} className="report__element">
                        <h2 className="report__element__title" style={{ color: contrastingColors[index] }}>
                          {reportZones.zoi.find((zone) => zone.id === zoneId).name || '-'}
                        </h2>
                        <div className="info-block">
                          <div className="info-block__item">
                            <h3>Duration</h3>
                            <span>
                              {moment
                                .duration(
                                  reports['ZonesRealTimeActivityDurationAnalytics'].zones[zoneId].duration,
                                  'seconds'
                                )
                                .format('d[d] h[h] m[m] s[s]') || '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              );
              break;
            }
          }
        })}
      </div>
    );
  };

  const changeWorkflowFilter = (workflow) => {
    const selectedWorkflow = workflowOptions.find((item) => item.value === workflow.value);

    changeAnalyticFilter('workflow', workflow);
    setCameraOptions(selectedWorkflow.cameras);
    changeValue('reportWorkflowType', selectedWorkflow.type);
    !selectedWorkflow.cameras.some((item) => item.value === analyticFilters.camera?.value) &&
      changeAnalyticFilter('camera', null);
  };

  const getFilteredCameras = () => {
    const selectedWorkflow = workflowOptions.find((item) => item.value === analyticFilters.workflow.value);

    return cameraOptions.filter((camera) =>
      selectedWorkflow.cameras.some((item) => item.value === camera.value)
    );
  };

  const getFilteredWorkflows = () => {
    return workflowOptions.filter((workflow) =>
      workflow.cameras.some((camera) => camera.value === analyticFilters.camera.value)
    );
  };

  useEffect(() => {
    handleResizeWindow();
  });

  const handleResizeWindow = () => {
    setTWidth(imageRef.current?.clientWidth);
    setTHeight(imageRef.current?.clientHeight);
  };

  const hasFiltersChanged = () => {
    if (Object.keys(savedFilters).length === 0) {
      if (analyticFilters.workflow && analyticFilters.camera) {
        return true;
      } else {
        return false;
      }
    }
    if (!analyticFilters.workflow || !analyticFilters.camera) return false;
    if (
      savedFilters.workflow !== analyticFilters.workflow?.label ||
      savedFilters.camera !== analyticFilters.camera?.label ||
      savedFilters.fromDate.valueOf() !== analyticFilters.fromDate.valueOf() ||
      savedFilters.toDate.valueOf() !== analyticFilters.toDate.valueOf()
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="analytical-report-page">
      <div className="report__side-panel">
        <div className="report__toolbar">
          <div className="report__filters">
            <ReportFilter
              title="Workflow"
              options={
                analyticFilters.camera && !analyticFilters.workflow ? getFilteredWorkflows() : workflowOptions
              }
              selected={analyticFilters.workflow}
              onClick={(value) => changeWorkflowFilter(value)}
            />

            <ReportFilter
              title="Camera"
              options={
                analyticFilters.workflow && !analyticFilters.camera ? getFilteredCameras() : cameraOptions
              }
              selected={analyticFilters.camera}
              onClick={(value) => changeAnalyticFilter('camera', value)}
            />

            <div className="report__filters__datetime-range">
              <DatetimeRangePicker
                className="datetime-range-filter"
                startDate={analyticFilters.fromDate ? analyticFilters.fromDate : new Date()}
                endDate={analyticFilters.toDate ? analyticFilters.toDate : new Date()}
                onFocus={() => {
                  setPickerOpen(true);
                }}
                onBlur={() => {
                  setPickerOpen(false);
                }}
                onStartDateChange={(startDate) => {
                  changeAnalyticFilter('fromDate', startDate);
                }}
                onEndDateChange={(endDate) => {
                  changeAnalyticFilter('toDate', endDate);
                }}
              />
              <div className="report__filters__datetime-range_divider">-</div>
            </div>
          </div>

          <button
            className="btn btn--secondary"
            onClick={() => {
              loadReportData();
            }}
            disabled={!hasFiltersChanged()}
          >
            Generate New Report
          </button>
        </div>
        <div className="report__info">
          <div className="report__info__item">
            <h3>Type</h3>
            <p>{reportWorkflowType}</p>
          </div>
          {!loadings.imageLoading && !loadings.zonesLoading && !loadings.reportLoading ? (
            Object.keys(reportData).some((analyticType) => analyticsWithPreview.includes(analyticType)) &&
            createTableReports(reportData)
          ) : (
            <div className="w-100 d-flex justify-content-center mt-20">Loading...</div>
          )}
        </div>
      </div>
      <div className="report__wrapper">
        <div className="report__wrapper__inner">
          {reportData && Object.keys(reportData).length ? (
            <>
              {!loadings.imageLoading && !loadings.zonesLoading && !loadings.reportLoading ? (
                <div className="report__main">
                  {!Object.keys(reportData).some((analyticType) =>
                    analyticsWithPreview.includes(analyticType)
                  ) && createTableReports(reportData)}
                  {reportData['default']}
                  {reportImage ? (
                    <div style={{ display: 'flex' }}>
                      <div style={{ position: 'relative', display: 'inline-block', margin: 'auto' }}>
                        <img ref={imageRef} src={base64FromString(reportImage)} alt="Camera" />
                        {(reportData['ZonesHeatMapGeneratorByQueues'] ||
                          reportData['ZonesHeatMapGeneratorByDetections']) && (
                          <img
                            style={{
                              width: tWidth,
                              height: tHeight,
                              zIndex: 2,
                              position: 'absolute',
                              left: 0,
                              opacity: '0.25',
                              filter: 'alpha(opacity=25)',
                            }}
                            src={
                              reportData['ZonesHeatMapGeneratorByQueues']?.heatmap ||
                              reportData['ZonesHeatMapGeneratorByDetections']?.heatmap
                            }
                            alt="Camera"
                          />
                        )}
                        <DrawingLayer width={tWidth} height={tHeight} zones={reportZones} />
                      </div>
                    </div>
                  ) : (
                    !reportData['default'] &&
                    Object.keys(reportData).some((analyticType) =>
                      analyticsWithPreview.includes(analyticType)
                    ) && <div className="d-flex justify-content-center">Can't load image</div>
                  )}
                </div>
              ) : (
                <div className="w-100 d-flex justify-content-center mt-20">Loading...</div>
              )}
            </>
          ) : (
            <div className="report__no-data">Select options and request a report</div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ reports }) => {
  const { analyticFilters, reportWorkflowType } = reports;
  return {
    analyticFilters,
    reportWorkflowType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeValue: (key, value) => dispatch(reportActions.changeValue(key, value)),
  changeAnalyticFilter: (key, value) => dispatch(reportActions.changeAnalyticFilter(key, value)),
  resetAnalyticFilters: () => dispatch(reportActions.resetAnalyticFilters()),
  getWorkflows: (params, actions) => dispatch(workflowActions.getWorkflows(params, actions)),
  getWorkflowAnalytics: (uuid, actions) => dispatch(workflowActions.getWorkflowAnalytics(uuid, actions)),
  getCameras: (params, actions) => dispatch(cameraActions.getCameras(params, actions)),
  verifyCamera: (url, actions) => dispatch(cameraActions.verifyCamera(url, actions)),
  getZonesForWorkflowCamera: (workflowUuid, cameraUuid, actions) =>
    dispatch(workflowActions.getZonesForWorkflowCamera(workflowUuid, cameraUuid, actions)),
  getPersons: (params, actions) => dispatch(getPersons(params, actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticalReportsPage);
