import React, { Component, useCallback } from 'react';
import i18n from '../../../i18n';
import { Formik } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Loader from '../../../components/Loader';
import { FormInput, FormSelect, FormNumericInput, FormDatePicker } from '../../../components/Form';
import 'react-datepicker/dist/react-datepicker.css';
import { SUBJECT_SEX_MALE, SUBJECT_SEX_FEMALE } from '../../../constants/subjects.constants';
import isNull from 'lodash/isNull';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .max(250, i18n.t('validationMessages:length', { field: 'name', length: 250 }))
    .required(i18n.t('validationMessages:required', { field: 'name' })),
  birthday: yup.string().nullable(),
  height: yup.string().max(250, i18n.t('validationMessages:length', { field: 'height', length: 250 })),
});

class BaseModal extends Component {
  state = {
    message: null,
    locations: [],
    locationQuery: { name: '', page: 0, size: 20 },
  };

  static getDerivedStateFromProps(nextProps) {
    const {
      params: { errorMsg },
    } = nextProps;

    if (errorMsg) {
      return { message: errorMsg };
    }

    return null;
  }

  componentDidMount() {
    this.props.getLocations(
      {
        name: this.state.locationQuery.name,
        page: 0,
        size: this.state.locationQuery.size,
      },
      {
        onSuccess: (locations) => {
          this.setState({
            locations: [
              ...locations.items.map((location) => ({ label: location.name, value: location.uuid })),
            ],
          });
        },
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { locationQuery } = this.state;

    if (
      prevState.locationQuery.name !== locationQuery.name ||
      prevState.locationQuery.page !== locationQuery.page
    ) {
      this.props.getLocations(
        {
          name: locationQuery.name,
          page: 0,
          size: locationQuery.page * locationQuery.size + locationQuery.size,
        },
        {
          onSuccess: (locations) => {
            this.setState({
              locations: [
                ...locations.items.map((location) => ({ label: location.name, value: location.uuid })),
              ],
            });
          },
        }
      );
    }
  }

  handleScrollToBottom = () => {
    this.setState((prev) => ({
      locationQuery: { ...prev.locationQuery, page: prev.locationQuery.page + 1 },
    }));
  };

  handleInputChange = (searchString) => {
    this.setState({ locationQuery: { name: searchString, page: 0, size: 20 } });
  };

  hideMessage = () => {
    if (this.state.message) {
      this.setState({ message: null });
      if (this.props.params.errorMsg && typeof this.props.params.resetError === 'function') {
        this.props.params.resetError();
      }
    }
  };

  onSubmit = (values, { setSubmitting }) => {
    const {
      params: { person },
      type,
      onSubmit,
    } = this.props;

    if (
      person.name === values.name &&
      person.birthday === values.birthday &&
      person.sex === values.sex &&
      person.location?.uuid === values.location?.value
    ) {
      this.setState({ message: i18n.t('form:hasNoChanges') });
    } else {
      const newPerson = { ...person, ...values, photos: this.state.photos };
      newPerson.location = values.location ? { uuid: values.location?.value } : null;

      Object.keys(newPerson).map((key) => {
        if (!newPerson[key]) {
          delete newPerson[key];
        }
      });

      type === 'create' ? onSubmit(newPerson) : onSubmit(person.uuid, newPerson);
    }

    setSubmitting(false);
  };

  getPersonPhotos() {
    return this.props.params.person.photos;
  }

  renderPersonPhotos() {}

  render() {
    const { message } = this.state;
    const {
      params: { isOpen, person },
      header,
      onClose,
      loading,
      submitBtnText,
      closeBtnText,
      className,
    } = this.props;

    return (
      <Modal isOpen={isOpen} className={cx('', className)} fade>
        <ModalHeader toggle={onClose}>{header}</ModalHeader>
        <Formik
          onSubmit={this.onSubmit}
          initialValues={{
            name: person.name,
            birthday: person.birthday,
            sex: person.sex,
            location: person.location,
          }}
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values: { name, sex, birthday, location },
              touched,
              errors,
              isValid,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <ModalBody>
                  <div className="form-fields mb-28">
                    <div className="form-row">
                      <FormInput
                        name="name"
                        type="text"
                        label={i18n.t('personModals:labelName')}
                        value={name}
                        className="form-item form-item--two"
                        onBlur={handleBlur}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                          this.hideMessage();
                        }}
                        errorMessage={errors.name && touched.name ? errors.name : ''}
                      />

                      <div className="form-item form-item--two">
                        <div className="form-group-field">
                          <label className="form-group-field-label">{i18n.t('personModals:labelSex')}</label>
                          <div className="form-radio-group">
                            <div className="form-group-radio">
                              <input
                                name="sex"
                                type="radio"
                                id="sex-1"
                                className="sr-only"
                                checked={sex === SUBJECT_SEX_MALE}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue('sex', e.target.value);
                                  this.hideMessage();
                                }}
                                value={SUBJECT_SEX_MALE}
                              />
                              <label htmlFor="sex-1">{i18n.t('personModals:labelSexMale')}</label>
                            </div>

                            <div className="form-group-radio">
                              <input
                                name="sex"
                                type="radio"
                                id="sex-2"
                                className="sr-only"
                                checked={sex === SUBJECT_SEX_FEMALE}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue('sex', e.target.value);
                                  this.hideMessage();
                                }}
                                value={SUBJECT_SEX_FEMALE}
                              />
                              <label htmlFor="sex-2">{i18n.t('personModals:labelSexFemale')}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <FormDatePicker
                        label={i18n.t('personModals:labelBirthday')}
                        name="birthday"
                        value={birthday ? new Date(birthday) : null}
                        dropdownMode="select"
                        className="form-item form-item--two"
                        onBlur={handleBlur}
                        onChange={(name, date) => {
                          setFieldValue(name, !isNull(date) ? new Date(date).toISOString() : null);
                          setFieldValue('age', '');
                          this.hideMessage();
                        }}
                        placeholder="mm/dd/yyyy"
                        errorMessage={errors.birthday && touched.birthday ? errors.birthday : ''}
                      />

                      <FormSelect
                        label={i18n.t('personModals:labelWorkingPlace')}
                        name="location"
                        selected={location}
                        options={this.state.locations}
                        isSearch={true}
                        className="form-item form-item--two"
                        onBlur={handleBlur}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                          this.hideMessage();
                        }}
                        onInputChange={this.handleInputChange}
                        onScrollToBottom={this.handleScrollToBottom}
                        errorMessage={errors.location && touched.location ? errors.location : ''}
                      />
                    </div>
                  </div>
                  {this.renderPersonPhotos()}
                </ModalBody>

                <ModalFooter>
                  <Loader loading={loading} />
                  {message || null}
                  <button type="button" className="btn btn--dark" disabled={loading} onClick={onClose}>
                    {closeBtnText}
                  </button>
                  <button
                    className="btn btn--secondary"
                    type="submit"
                    // disabled={!isValid || loading}
                    disabled={false}
                  >
                    {submitBtnText}
                  </button>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

BaseModal.defaultProps = {
  loading: false,
  submitBtnText: i18n.t('buttons:save'),
  closeBtnText: i18n.t('buttons:cancel'),
};

export default BaseModal;
