import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { isArrayLength } from '../../../../utils';
import { WATCH } from '../../../../constants/routes.constants';
import CameraEventElement from '../../../../components/CameraEventElement';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import ModelsService from '../../../../services/ModelsService';
import * as actions from '../../../../actions/subject.actions';
import i18n from '../../../../i18n';

class SubjectAlerts extends Component {
  componentDidMount() {
    const { uuid } = this.props;
    if (uuid) {
      this.props.getSubjectEvents(uuid);
    }
  }

  onScrolledBottom = () => {
    const { uuid, total, page, size, isLoading } = this.props;
    if (page * size < total && !isLoading) {
      this.props.getSubjectEventsNextPage(uuid);
    }
  };

  componentWillUnmount() {
    this.props.resetSubjectEventsState();
  }

  render() {
    const { list, name = '', isLoading } = this.props;

    return (
      <div className="subject-alerts">
        <strong className="sticky">{i18n.t('subjects:alertsTitle', { name })}</strong>
        <InfiniteScroll
          loading={isLoading}
          className="subject-alerts-inner subject-alerts-sm-x-scroll"
          onScrolledBottom={this.onScrolledBottom}
        >
          <strong>{i18n.t('subjects:alertsTitle', { name })}</strong>
          <div className="wrap-subject-alerts-list">
            <ul className="subject-alerts-list camera-list-base">
              {isArrayLength(list)
                ? list.map(e => (
                    <li key={e.uuid} className="subject-alerts-item">
                      <CameraEventElement
                        item={e}
                        to={{
                          pathname: `${WATCH}/${e.camera.uuid}`,
                          state: { setCurrent: moment(e.timestamp).toDate(), alert: e },
                        }}
                      />
                    </li>
                  ))
                : !isLoading && <li className="not-found-list">{i18n.t('notFound')}</li>}
            </ul>
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

SubjectAlerts.propTypes = {
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string,
  list: PropTypes.arrayOf(ModelsService.getAlertModel()),
  size: PropTypes.number,
  page: PropTypes.number,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  getSubjectEvents: PropTypes.func,
  getSubjectEventsNextPage: PropTypes.func,
  resetSubjectEventsState: PropTypes.func,
};

const mapStateToProps = ({ subjectView }) => {
  const {
    list: { items, total },
    query: { size, page },
    isLoading,
  } = subjectView.events;
  return { list: items, size, page, total, isLoading };
};

const mapDispatchToProps = dispatch => ({
  getSubjectEvents: uuid => dispatch(actions.getSubjectEvents(uuid)),
  getSubjectEventsNextPage: uuid => dispatch(actions.getSubjectEventsNextPage(uuid)),
  resetSubjectEventsState: () => dispatch(actions.resetSubjectEventsState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectAlerts);
