import * as ActionTypes from '../constants/models.constants';

const initialState = {
  list: {
    items: [],
    total: 0,
  },
  query: {
    size: 50,
    page: 0,
  },
  isLoading: false,
};

export const modelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_MODELS:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_MODELS_RESULT:
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items, total },
        query: { size, page },
        isLoading: false,
      };

    case ActionTypes.GET_MODELS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_MODEL:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.ADD_MODEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_MODEL_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.UPDATE_MODEL:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.UPDATE_MODEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.UPDATE_MODEL_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_MODEL:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.DELETE_MODEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_MODEL_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
