import request from '../utils/request';
import { queryStringify } from '../utils';

export function getLocations(params) {
  return request({
    url: `/locations?${queryStringify(params)}`,
    method: 'get',
  });
}

export function addLocation(params) {
  return request({
    url: `/locations`,
    method: 'post',
    data: params,
  });
}

export function getLocation(uuid) {
  return request({
    url: `/locations/${uuid}`,
    method: 'get',
  });
}

export function updateLocation(uuid, params) {
  return request({
    url: `/locations/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteLocation(uuid) {
  return request({
    url: `/locations/${uuid}`,
    method: 'delete',
  });
}
