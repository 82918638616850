import { queryStringify } from '../utils/index';
import request from '../utils/request';

export function getRoles(params) {
  return request({
    url: `/roles`,
    method: 'get',
    data: params,
  });
}

export function getAuthorities(params) {
  return request({
    url: `/roles/authorities`,
    method: 'get',
    data: params,
  });
}

export function getRole(uuid, actions) {
  return request({
    url: `/roles/${uuid}`,
    method: 'get',
    actions,
  });
}

export function addRole(params) {
  return request({
    url: '/roles',
    method: 'post',
    data: params,
  });
}

export function updateRole(params) {
  return request({
    url: `/roles`,
    method: 'post',
    data: params,
  });
}

export function deleteRole(uuid) {
  return request({
    url: `/roles/${uuid}`,
    method: 'delete',
  });
}
