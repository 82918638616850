import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Table from '../../components/Table';

import * as subjectActions from '../../actions/subject.actions';
import i18n from '../../i18n';
import CarModal from './Modals/CarModal';
import DropdownDotMenu from '../../components/DropdownDotMenu';
import ModalDelete from '../../components/ModalDelete';

const CarsList = ({
  list,
  size,
  page,
  total,
  isLoading,
  getCars,
  getCar,
  addCarAction,
  updateCarAction,
  deleteCarAction,
}) => {
  const [modalInfo, setModalInfo] = useState({ id: 0, type: 'create', isOpen: false, errorMsg: '' });

  useEffect(() => {
    getCars();
  }, []);

  const handleOpenModal = () => {
    setModalInfo({
      type: 'create',
      isOpen: true,
    });
  };

  const handleCloseModal = () => {
    setModalInfo({ id: 0, type: 'create', isOpen: false, errorMsg: '' });
  };

  const handleSubmitModal = (params) => {
    switch (modalInfo.type) {
      case 'create':
        addCar(params);
        break;
      case 'edit':
        updateCar(params);
        break;
      case 'delete':
        deleteCar(modalInfo.id);
        break;
      default:
        break;
    }
  };

  const addCar = (params) => {
    addCarAction(params, getModalRequestActions());
  };

  const updateCar = (params) => {
    updateCarAction(modalInfo.id, params, getModalRequestActions());
  };

  const deleteCar = (uuid) => {
    deleteCarAction(uuid, getModalRequestActions());
  };

  const getModalRequestActions = () => {
    return {
      onSuccess: () => {
        handleCloseModal();
        getCars();
      },
      onError: (error) => {
        setModalInfo({ errorMsg: error.message });
      },
    };
  };

  const createRows = (rows) => {
    return rows.map((car) => ({
      id: car.uuid,
      cells: [
        {
          label: car.plateLabel,
          cellComponent: <h5 className="table__cell table__cell--bold">{car.plateLabel}</h5>,
        },
        {
          label: car.subject?.name,
        },
        {
          label: car.description,
        },
        {
          label: car.manufacturer,
        },
        {
          label: car.model,
        },
        {
          label: car.vehicleType,
        },
        {
          label: car.color,
        },
        {
          label: car.year,
        },
        {
          label: car.vin,
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                {
                  name: 'Edit',
                  onClick: () => {
                    setModalInfo({ id: car.uuid, car: car, type: 'edit', isOpen: true, errorMsg: '' });
                  },
                },
                {
                  name: 'Delete',
                  onClick: () => {
                    setModalInfo({
                      id: car.uuid,
                      car: car,
                      type: 'delete',
                      isOpen: true,
                      errorMsg: '',
                    });
                  },
                },
              ]}
            />
          ),
        },
      ],
    }));
  };

  const headerOptions = [
    {
      label: 'Plate Label',
    },
    {
      label: 'Owner',
    },
    {
      label: 'Description',
    },
    {
      label: 'Manufacturer',
    },
    {
      label: 'Model',
    },
    {
      label: 'Vehicle Type',
    },
    {
      label: 'Color',
    },
    {
      label: 'Year',
    },
    {
      label: 'VIN',
    },
    {
      label: '',
    },
  ];

  return (
    <>
      <Table
        rows={createRows(list)}
        headerOptions={headerOptions}
        title={i18n.t('nav:cars')}
        onClick={() => handleOpenModal('create')}
        totalRows={total}
        size={size}
        page={page}
        changePage={(data) => getCars({ page: data })}
        isLoading={isLoading}
        disabled={modalInfo.isOpen}
      />
      {modalInfo.isOpen && modalInfo.type !== 'delete' && (
        <CarModal
          title={modalInfo.type === 'create' ? i18n.t('carModals:addTitle') : i18n.t('carModals:editTitle')}
          isOpen={modalInfo.isOpen}
          car={modalInfo.car}
          type={modalInfo.type}
          onClose={handleCloseModal}
          onSubmit={handleSubmitModal}
          className="modal-users"
          errorMsg={modalInfo.errorMsg}
          isLoading={false}
        />
      )}
      {modalInfo.isOpen && modalInfo.type === 'delete' && (
        <ModalDelete
          loading={false}
          isOpen={modalInfo.isOpen}
          errorMsg={modalInfo.errorMsg}
          header={i18n.t('carModals:deleteTitle')}
          body={i18n.t('carModals:deleteBody', { plateLabel: modalInfo.car.plateLabel })}
          onClose={handleCloseModal}
          onDelete={handleSubmitModal}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ cars }) => {
  const {
    list: { items, total },
    query: { size, page },
    isLoading,
  } = cars;
  return { list: items, size, page, total, isLoading };
};

const mapDispatchToProps = (dispatch) => ({
  getCars: (params, actions) => dispatch(subjectActions.getCars(params, actions)),
  getCar: (uuid, actions) => dispatch(subjectActions.getCar(uuid, actions)),
  addCarAction: (params, actions) => dispatch(subjectActions.addCar(params, actions)),
  updateCarAction: (uuid, params, actions) => dispatch(subjectActions.updateCar(uuid, params, actions)),
  deleteCarAction: (uuid, actions) => dispatch(subjectActions.deleteCar(uuid, actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarsList);
