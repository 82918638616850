import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const TablePagination = ({ totalRows, isLoading, page, perPage, onChangePage }) => {
  return (
    <div className="table__pagination">
      <p className="table__pagination-totals">{`${page * perPage + 1}-${
        (page + 1) * perPage <= totalRows ? (page + 1) * perPage : totalRows
      } of ${totalRows}`}</p>
      <div className="table__pagination-buttons">
        <button
          disabled={isLoading}
          className={`table__pagination-button table__pagination-button--prev ${
            (page === 0 || isLoading) && 'table__pagination-button--disabled'
          }`}
        >
          <FontAwesomeIcon icon={faChevronLeft} onClick={() => onChangePage(page - 1)} />
        </button>
        <button
          className={`table__pagination-button table__pagination-button--next ${
            (totalRows <= (page + 1) * perPage || isLoading) && 'table__pagination-button--disabled'
          }`}
        >
          <FontAwesomeIcon icon={faChevronRight} onClick={() => onChangePage(page + 1)} />
        </button>
      </div>
    </div>
  );
};

TablePagination.propTypes = {
  totalRows: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
};

export default TablePagination;
