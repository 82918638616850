import React, { PureComponent } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import CameraListElementScreens from './CameraListElementScreens';
import { ALERT_TIME_FORMAT } from '../../../../constants/alerts.constants';
import { DASHBOARD } from '../../../../constants/routes.constants';
import * as actions from '../../../../actions/cameras.actions';
import CameraListElementTextWidget from './CameraListElementTextWidget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

class CameraListElementWidget extends PureComponent {
  onChangeVisible = (visible) => {
    if (!visible && this.props.isLive) {
      this.props.setCameraLiveReset();
    }
  };

  onChangeLive = (e) => {
    e && e.preventDefault();
    if (this.props.isLive) {
      this.props.setCameraLiveReset(this.props.camera.uuid);
    } else {
      this.props.setCameraLive(this.props.camera.uuid);
    }
  };
  render() {
    const { camera, workflow, height, typeWidget, config, uuid, deleteWidget } = this.props;
    const VSSetting = {
      onChange: this.onChangeVisible,
      partialVisibility: true,
    };
    return (
      <>
        <div className="d-flex flex-column text-center ml-1 mr-1 mt-1">
          <span data-toggle="tooltip" data-placement="top" title={workflow.name} style={{ fontSize: '12px' }}>
            {workflow.name.length > 35 ? workflow.name.substring(0, 35) + '...' : workflow.name}
          </span>
          <span
            data-toggle="tooltip"
            data-placement="top"
            title={camera.name}
            style={{ fontSize: '10px', opacity: 0.5 }}
          >
            {camera.name.length > 35 ? camera.name.substring(0, 35) + '...' : camera.name}
          </span>
        </div>
        <figure className={`frame`} style={{ height: `${(height - 1) * 164 + 138}px` }}>
          {typeWidget === 'heatmap' ? (
            <CameraListElementScreens
              camera={camera}
              enabled={true}
              heatmap={true}
              config={config.heatmap}
              isVisible={true}
            />
          ) : (
            <CameraListElementTextWidget
              camera={camera}
              inout={typeWidget === 'inout' ? config.inoutLines : []}
              queue={typeWidget === 'queue' ? config.queueZones : []}
              activity={typeWidget === 'activity' ? config.activityZones : []}
              accumActivity={typeWidget === 'accumActivity' ? config.activityZones : []}
            />
          )}
          <figcaption>
            <div className="frame__header">
              <div className="frame__time"></div>
              <FontAwesomeIcon
                style={{ cursor: 'pointer', zIndex: 50 }}
                icon={faWindowClose}
                onClick={() => deleteWidget(uuid)}
              />
            </div>
          </figcaption>
        </figure>
      </>
    );
  }
}

const mapStateToProps = ({ cameras }, { camera, time }) => {
  const uuid = camera?.uuid;
  const screencast = cameras.screencasts.find((x) => x.uuid === uuid);
  return {
    cameras,
    isLive: cameras.live.includes(camera.uuid),
    time:
      screencast && screencast && screencast.timestamp !== time
        ? moment(screencast.timestamp).format(ALERT_TIME_FORMAT)
        : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCameraLive: (uuid) => dispatch(actions.setCameraLive(uuid)),
  setCameraLiveReset: (uuid) => dispatch(actions.setCameraLiveReset(uuid)),
  changeCamerasValue: (key, value) => dispatch(actions.changeCamerasValue(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CameraListElementWidget);
