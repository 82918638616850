import * as fileExport from './_via_file';
import * as attributeExport from './_via_attribute';
import * as constExport from './_via_const';
import * as configExport from './_via_config';
import { store } from '../../../index';
import * as trainingActions from '../../../actions/training.action';

export var _via_msg_clear_timer; // holds a reference to current message timoout
export var _via_page_current; // holds a reference to current info page
export var _via_page_action_map; // holds a reference to action map for current info page

export function _via_util_get_svg_button(icon_id, title, id) {
  var el = document.createElementNS(constExport._VIA_SVG_NS, 'svg');
  el.setAttributeNS(null, 'viewBox', '0 0 24 24');
  el.innerHTML = '<use xlink:href="#' + icon_id + '"></use><title>' + title + '</title>';
  el.setAttributeNS(null, 'class', 'svg_button');
  if (typeof id !== 'undefined') {
    el.setAttributeNS(null, 'id', id);
  }
  return el;
}

export function _via_util_get_html_input_element_value(el) {
  switch (el.tagName) {
    case 'TEXTAREA':
      return el.value;

    case 'SELECT':
      return el.options[el.selectedIndex].value;

    case 'INPUT':
      return el.value;
  }
}

export function _via_util_get_filename_from_uri(uri) {
  if (uri.includes('/')) {
    var tokens = uri.split('/');
    return tokens[tokens.length - 1];
  } else {
    return uri;
  }
}

export function _via_util_file_type_to_str(type) {
  switch (type) {
    case fileExport._VIA_FILE_TYPE.IMAGE:
      return 'image';
      break;
    case fileExport._VIA_FILE_TYPE.VIDEO:
      return 'video';
      break;
    case fileExport._VIA_FILE_TYPE.AUDIO:
      return 'audio';
      break;
    default:
      return 'unknown ' + type;
  }
}

export function _via_util_file_type_str_to_id(type) {
  switch (type) {
    case 'image':
      return fileExport._VIA_FILE_TYPE.IMAGE;
      break;
    case 'video':
      return fileExport._VIA_FILE_TYPE.VIDEO;
      break;
    case 'audio':
      return fileExport._VIA_FILE_TYPE.AUDIO;
      break;
    default:
      return -1;
  }
}

export function _via_util_file_loc_to_str(loc) {
  switch (loc) {
    case fileExport._VIA_FILE_TYPE.LOCAL:
      return 'local';
      break;
    case fileExport._VIA_FILE_TYPE.URIHTTP:
      return 'http://';
      break;
    case fileExport._VIA_FILE_TYPE.URIFILE:
      return 'file://';
      break;
    case fileExport._VIA_FILE_TYPE.URIFILE:
      return 'inline';
      break;
    default:
      return 'unknown-type-id=' + loc.toString();
  }
}

export function _via_util_file_loc_str_to_id(loc) {
  switch (loc) {
    case 'local':
      return fileExport._VIA_FILE_TYPE.LOCAL;
      break;
    case 'http://':
      return fileExport._VIA_FILE_TYPE.URIHTTP;
      break;
    case 'file://':
      return fileExport._VIA_FILE_TYPE.URIFILE;
      break;
    case 'inline':
      return fileExport._VIA_FILE_TYPE.URIFILE;
      break;
    default:
      return -1;
  }
}

// export function _via_util_metadata_shape_str(shape_id) {
//   switch (shape_id) {
//     case _VIA_WHERE_SHAPE.TIME:
//       return 'time';
//       break;
//     case _VIA_WHERE_SHAPE.RECTANGLE:
//       return 'rectangle';
//       break;
//     case _VIA_WHERE_SHAPE.EXTREME_RECTANGLE:
//       return 'extreme_rectangle';
//       break;
//     case _VIA_WHERE_SHAPE.CIRCLE:
//       return 'circle';
//       break;
//     case _VIA_WHERE_SHAPE.EXTREME_CIRCLE:
//       return 'extreme_circle';
//       break;
//     case _VIA_WHERE_SHAPE.ELLIPSE:
//       return 'ellipse';
//       break;
//     case _VIA_WHERE_SHAPE.POINT:
//       return 'point';
//       break;
//     case _VIA_WHERE_SHAPE.POLYLINE:
//       return 'polyline';
//       break;
//     case _VIA_WHERE_SHAPE.POLYGON:
//       return 'polygon';
//       break;
//     default:
//       return 'unknown';
//   }
// }

export function _via_util_escape_quote_for_csv(s) {
  return s.replace(/["]/g, '""');
}

export function _via_util_load_text_file(text_file, callback_function) {
  if (text_file) {
    var text_reader = new FileReader();

    text_reader.addEventListener(
      'error',
      function () {
        console.log('Error loading data text file :  ' + text_file.name + ' !');
        callback_function('');
      },
      false
    );

    text_reader.addEventListener(
      'load',
      function () {
        store.dispatch(trainingActions.changeFiles(text_reader.result));
        callback_function(text_reader.result);
      },
      false
    );
    text_reader.readAsText(text_file, 'utf-8');
  }
}

export function _via_util_file_ext(filename) {
  return filename.substr(filename.lastIndexOf('.') + 1);
}

export function _via_util_infer_file_loc_from_filename(filename) {
  if (filename.startsWith('http://') || filename.startsWith('https://')) {
    return fileExport._VIA_FILE_LOC.URIHTTP;
  } else {
    if (filename.startsWith('file://') || filename.includes('/')) {
      return fileExport._VIA_FILE_LOC.URIFILE;
    } else {
      return fileExport._VIA_FILE_LOC.LOCAL;
    }
  }
}

export function _via_util_infer_file_type_from_filename(filename) {
  var ext = _via_util_file_ext(filename);
  switch (ext.toLowerCase()) {
    case 'ogv':
    case 'mp4':
    case 'avi':
    case 'webm':
      return fileExport._VIA_FILE_TYPE.VIDEO;
      break;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'bmp':
      return fileExport._VIA_FILE_TYPE.IMAGE;
      break;
    case 'mp3':
    case 'wav':
    case 'oga':
    case 'ogg':
      return fileExport._VIA_FILE_TYPE.AUDIO;
  }
}

export function _via_util_download_as_file(data, filename) {
  var a = document.createElement('a');
  a.href = URL.createObjectURL(data);
  a.download = filename;

  // simulate a mouse click event
  var event = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(event);
}

export function _via_util_file_select_local(type, handler, multiple) {
  var fsel = document.createElement('input');
  fsel.setAttribute('type', 'file');
  fsel.setAttribute('id', 'file_input_selector');
  fsel.setAttribute('name', 'files[]');
  if (typeof multiple === 'undefined' || multiple === true) {
    fsel.setAttribute('multiple', 'multiple');
  }
  switch (type) {
    case constExport._VIA_FILE_SELECT_TYPE.IMAGE:
      fsel.accept = 'image/*';
      break;
    case constExport._VIA_FILE_SELECT_TYPE.VIDEO:
      fsel.accept = 'video/*';
      break;
    case constExport._VIA_FILE_SELECT_TYPE.AUDIO:
      fsel.accept = 'audio/*';
      break;
    case constExport._VIA_FILE_SELECT_TYPE.TEXT:
      fsel.accept = '.csv,.txt';
      break;
    case constExport._VIA_FILE_SELECT_TYPE.JSON:
      fsel.accept = '.json';
      break;
    case constExport._VIA_FILE_SELECT_TYPE.VIDEO | constExport._VIA_FILE_SELECT_TYPE.AUDIO:
      fsel.accept = 'video/*,audio/*';
      break;
    case constExport._VIA_FILE_SELECT_TYPE.VIDEO |
      constExport._VIA_FILE_SELECT_TYPE.AUDIO |
      constExport._VIA_FILE_SELECT_TYPE.IMAGE:
      fsel.accept = 'video/*,audio/*,image/*';
      break;
    case constExport._VIA_FILE_SELECT_TYPE.WEBVTT:
      fsel.accept = '.vtt';
      break;
  }
  fsel.onchange = handler;
  fsel.click();
}

// see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
export function _via_util_rand_int(min_inclusive, max_exclusive) {
  return Math.floor(Math.random() * (max_exclusive - min_inclusive)) + min_inclusive;
}

export function _via_util_page_show(page_id, action_map) {
  _via_page_action_map = action_map;
  var el = document.getElementById('via_page_container');

  var pages = el.getElementsByClassName('via_page');
  var n = pages.length;
  for (var i = 0; i < n; ++i) {
    if (pages[i].dataset.pageid === page_id) {
      pages[i].style.display = 'inline-block';
      el.style.display = 'block';
      _via_page_current = pages[i];
    } else {
      pages[i].style.display = 'none';
    }
  }
}

export function _via_util_page_process_action(e) {
  for (var action_id in _via_page_action_map) {
    if (e.target.id === action_id) {
      var page_data = _via_util_page_gather_user_input();
      page_data['_action_id'] = e.target.id;
      _via_page_action_map[action_id].call(this, page_data);
      _via_util_page_hide();
    }
  }
}

export function _via_util_page_gather_user_input() {
  var user_input = {};

  // dropdown
  var select_list = _via_page_current.getElementsByTagName('select');
  for (var i = 0; i < select_list.length; ++i) {
    user_input[select_list[i].id] = select_list[i].options[select_list[i].selectedIndex].value;
  }

  // input
  var input_list = _via_page_current.getElementsByTagName('input');
  for (var i = 0; i < input_list.length; ++i) {
    switch (input_list[i].type) {
      case 'file':
        user_input[input_list[i].id] = input_list[i].files;
        break;
      case 'select':
        if (input_list[i].selectedIndex === -1) {
          user_input[input_list[i].id] = '';
        } else {
          user_input[input_list[i].id] = input_list[i].options[input_list[i].selectedIndex];
        }
        break;
      case 'text':
      case 'password':
        user_input[input_list[i].id] = input_list[i].value;
        break;
      default:
        console.warn('Input type=' + input_list[i].type + ' not yet implemented!');
    }
  }

  // textarea
  var textarea_list = _via_page_current.getElementsByTagName('textarea');
  for (var i = 0; i < textarea_list.length; ++i) {
    user_input[textarea_list[i].id] = textarea_list[i].value;
  }

  return user_input;
}

export function _via_util_page_hide() {
  var el = document.getElementById('via_page_container');
  if (el.style.display === 'block') {
    el.style.display = 'none';
  }
  _via_page_current = null;
}

export function _via_util_msg_show(msg, sticky) {
  var container = document.getElementById('_via_message_container');
  var content = document.getElementById('_via_message');
  if (container && content) {
    if (_via_msg_clear_timer) {
      clearTimeout(_via_msg_clear_timer);
    }
    if (typeof sticky === 'undefined' || sticky === false) {
      _via_msg_clear_timer = setTimeout(function () {
        if (document.getElementById('_via_message_container'))
          document.getElementById('_via_message_container').style.display = 'none';
      }, configExport._VIA_CONFIG.MSG_TIMEOUT);
    }

    content.innerHTML = msg + '<span class="message_panel_close_button">&times;</span>';
    container.style.display = 'block';
  }
}

export function _via_util_msg_hide() {
  document.getElementById('_via_message_container').style.display = 'none';
  if (_via_msg_clear_timer) {
    clearTimeout(_via_msg_clear_timer);
  }
}

export function _via_util_pad10(x) {
  if (x < 10) {
    return '0' + x.toString();
  } else {
    return x;
  }
}

export function _via_util_date_to_filename_str(date_str) {
  var t = new Date(date_str);
  var month_list = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return (
    _via_util_pad10(t.getDate()) +
    month_list[t.getMonth()] +
    t.getFullYear() +
    '_' +
    _via_util_pad10(t.getHours()) +
    'h' +
    _via_util_pad10(t.getMinutes()) +
    'm' +
    _via_util_pad10(t.getSeconds()) +
    's'
  );
}

export function _via_util_remote_get(uri) {
  return new Promise(function (ok_callback, err_callback) {
    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', function () {
      ok_callback(xhr.responseText);
    });
    xhr.addEventListener('timeout', function (e) {
      err_callback(e);
    });
    xhr.addEventListener('error', function (e) {
      err_callback(e);
    });
    xhr.open('GET', uri);
    xhr.send();
  });
}

export function _via_util_remote_head(uri) {
  return new Promise(function (ok_callback, err_callback) {
    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', function () {
      switch (xhr.statusText) {
        case 'OK':
          ok_callback(xhr.responseText);
          break;
        default:
          err_callback(xhr.statusText);
      }
    });
    xhr.addEventListener('timeout', function (e) {
      err_callback(e);
    });
    xhr.addEventListener('error', function (e) {
      err_callback(e);
    });
    xhr.open('HEAD', uri);
    xhr.send();
  });
}

export function _via_util_float_arr_to_fixed(arr, fixed) {
  var farr = [];
  for (var i in arr) {
    farr[i] = parseFloat(arr[i].toFixed(fixed));
  }
  return farr;
}

export function _via_util_float_to_fixed(value, fixed) {
  return parseFloat(value.toFixed(fixed));
}

export function _via_util_uuid() {
  var temp_url = URL.createObjectURL(new Blob());
  var uuid = temp_url.toString();
  URL.revokeObjectURL(temp_url);
  var slash_index = uuid.lastIndexOf('/');
  if (uuid !== -1) {
    // remove any prefix (e.g. blob:null/, blob:www.test.com/, ...)
    uuid = uuid.substr(slash_index + 1);
    uuid = uuid.replace(/-/g, '');
  }
  return uuid;
}

export function _via_util_gen_project_id() {
  return 'via' + _via_util_uuid();
}

export function _via_util_uid6() {
  var temp_url = URL.createObjectURL(new Blob());
  var uuid = temp_url.toString();
  URL.revokeObjectURL(temp_url);
  var n = uuid.length;
  // remove any prefix (e.g. blob:null/, blob:www.test.com/, ...)
  var uuid_suffix_str = '';
  for (var i = n - 12; i < n; i = i + 2) {
    uuid_suffix_str += String.fromCharCode(parseInt(uuid.substr(i, 2), 16));
  }
  return btoa(uuid_suffix_str).replace(/[-+/_]/gi, 'X');
}

export function _via_util_array_eq(a, b) {
  if (a == null || b == null) {
    return false;
  }
  if (a.length != b.length) {
    return false;
  }
  var n = a.length;
  for (var i = 0; i < n; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export function _via_util_obj_to_csv(obj, default_key) {
  var csv = [];
  for (var oid in obj) {
    if (oid === default_key) {
      csv.push('*' + obj[oid]);
    } else {
      csv.push(obj[oid]);
    }
  }
  return csv.join(',');
}

export function _via_util_attribute_to_html_element(attr) {
  var el;
  switch (attr.type) {
    case attributeExport._VIA_ATTRIBUTE_TYPE.TEXT:
      el = document.createElement('textarea');
      break;

    case attributeExport._VIA_ATTRIBUTE_TYPE.SELECT:
      el = document.createElement('select');
      var oid;
      for (oid in attr.options) {
        var oi = document.createElement('option');
        oi.setAttribute('value', oid);
        oi.innerHTML = attr.options[oid];
        if (oid == attr.default_option_id) {
          oi.setAttribute('selected', '');
        }
        el.appendChild(oi);
      }
      break;

    case attributeExport._VIA_ATTRIBUTE_TYPE.RADIO:
      el = document.createElement('table');
      for (var oid in attr.options) {
        var oi = document.createElement('input');
        oi.setAttribute('name', attr.aname);
        oi.setAttribute('type', 'radio');
        var label = document.createElement('label');
        label.innerHTML = attr.options[oid];
        var tr = document.createElement('tr');
        var td = document.createElement('td');
        td.appendChild(oi);
        td.appendChild(label);
        tr.appendChild(td);
        el.appendChild(tr);
      }
      break;

    default:
      el = document.createElement('span');
      el.innerHTML = 'UNKNOWN';
  }
  return el;
}

export function _via_util_obj2csv(d) {
  return '"' + JSON.stringify(d).replace(/["]/g, '""') + '"';
}

export function _via_util_merge_object(obj1, obj2) {
  var merged_obj = {};
  Object.assign(merged_obj, obj1);
  Object.assign(merged_obj, obj2);
  return merged_obj;
}

export function _via_util_collect_object_by_keys(object, keys) {
  var merged_obj = {};
  for (var kindex in keys) {
    Object.assign(merged_obj, object);
  }
  Object.assign(merged_obj, object);
  return merged_obj;
}

export function _via_util_clamp(x, lower_bound, upper_bound) {
  return Math.min(Math.max(x, lower_bound), upper_bound);
}

export function _via_util_merge_three_way_str(ancestor, version1, version2) {
  if (version1 === version2) {
    return version1;
  } else {
    if (ancestor === version1) {
      return version2;
    } else {
      return version1;
    }
  }
}

export function _via_seconds_to_hh_mm_ss_ms(s) {
  // result = [hh, mm, ss, ms]
  var result = [0, 0, 0, 0];
  var sec = parseFloat(s);
  var ms = parseInt((sec - parseInt(s)) * 1000);
  if (ms < 99) {
    if (ms < 9) {
      result[3] = '00' + ms.toString();
    } else {
      result[3] = '0' + ms.toString();
    }
  } else {
    result[3] = ms.toString();
  }

  if (sec > 60 * 60) {
    var hh = parseInt(sec / (60 * 60));
    if (hh > 9) {
      result[0] = hh.toString();
    } else {
      result[0] = '0' + hh;
    }
    sec = sec - result[0] * 60 * 60;
  } else {
    result[0] = '00';
  }

  if (sec > 60) {
    var mm = parseInt(sec / 60);
    if (mm > 9) {
      result[1] = mm.toString();
    } else {
      result[1] = '0' + mm;
    }
    sec = sec - result[1] * 60;
  } else {
    result[1] = '00';
  }

  var ss = parseInt(sec);
  if (ss > 9) {
    result[2] = ss.toString();
  } else {
    result[2] = '0' + ss;
  }

  return result;
}

export function _via_hh_mm_ss_ms_to_seconds(hh_mm_ss_ms) {
  // hh_mm_ss_ms = HH:MM:SS.MS
  var split1 = hh_mm_ss_ms.split('.');
  if (split1.length !== 2) {
    split1 = hh_mm_ss_ms.split(',');
    if (split1.length !== 2) {
      return -1;
    }
  }
  var split2 = split1[0].split(':');
  if (split2.length !== 3) {
    return -1;
  }
  var sec = 0;
  switch (split2.length) {
    case 3:
      sec = parseInt(split2[0]) * 60 * 60 + parseInt(split2[1]) * 60 + parseInt(split2[2]);
      break;
    case 2:
      sec = parseInt(split2[1]) * 60 + parseInt(split2[2]);
      break;
    case 1:
      sec = parseInt(split2[2]);
      break;
  }
  if (split1.length === 2) {
    sec = sec + parseInt(split1[1]) / 1000;
  }
  return sec;
}
