import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import Auth from './routes/auth.routes';
import User from './container/user';
import storeService from './services/StoreService';

class App extends Component {
  componentDidMount = () => {
    const { isLoggedIn, dispatch, getState } = this.props;
    if (isLoggedIn) {
      storeService.init(dispatch, getState);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { isLoggedIn, dispatch, getState } = this.props;
    if (prevProps.isLoggedIn !== isLoggedIn && isLoggedIn) {
      storeService.init(dispatch, getState);
    }
  };

  render() {
    const { isLoggedIn, history } = this.props;
    return <ConnectedRouter history={history}>{!isLoggedIn ? <Auth /> : <User />}</ConnectedRouter>;
  }
}

const mapStateToProps = ({ app }) => {
  return {
    isLoggedIn: app.isLoggedIn,
  };
};

App.propTypes = {
  getState: PropTypes.func,
  history: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, null)(App);
