import AlertEnum from '../enums/alert.enum';
import * as ActionTypes from '../constants/cameras.constants';

export const getCamera = (uuid, actions) => ({
  type: ActionTypes.GET_CAMERA,
  payload: uuid,
  actions,
});

export const getCameras = (params, actions) => ({
  type: ActionTypes.GET_CAMERAS,
  payload: params,
  actions,
});

export const getCamerasNextPage = () => ({
  type: ActionTypes.GET_CAMERAS_NEXT_PAGE,
});

export const getCameraEvents = (uuid, params) => ({
  type: ActionTypes.GET_CAMERA_EVENTS,
  payload: { uuid, params },
});

export const getCameraEventsGroupedTimestamps = (params) => ({
  type: ActionTypes.GET_CAMERA_EVENTS_GROUPED_TIMESTAMPS,
  payload: params,
});

export const getCameraAlerts = (uuid, params) => ({
  type: ActionTypes.GET_CAMERA_ALERTS,
  payload: { uuid, params },
});

export const getReadyHeatmap = (workflowUuid, cameraUuid, params, actions) => ({
  type: ActionTypes.GET_READY_HEATMAP,
  payload: { workflowUuid, cameraUuid, params },
  actions,
});

export const getReadyHeatmapForQueues = (workflowUuid, cameraUuid, params, actions) => ({
  type: ActionTypes.GET_READY_HEATMAP_FOR_QUEUES,
  payload: { workflowUuid, cameraUuid, params },
  actions,
});

export const addCameraAlert = (alert) => ({
  type: ActionTypes.ADD_CAMERA_ALERT,
  payload: alert,
});

export const addCameraEvent = (event) => ({
  type: ActionTypes.ADD_CAMERA_EVENT,
  payload: event,
});

export const resetCameraEvents = () => ({
  type: ActionTypes.RESET_CAMERA_EVENTS,
});

export const getCameraRecordings = (uuid, params, actions) => ({
  type: ActionTypes.GET_CAMERA_RECORDINGS,
  payload: { uuid, params },
  actions,
});

export const setCameraRecordingsList = (recordings) => ({
  type: ActionTypes.SET_CAMERA_RECORDINGS_LIST,
  payload: recordings,
});

export const setCameraEventsTimestamps = (events) => ({
  type: ActionTypes.SET_CAMERA_EVENTS_TIMESTAMPS,
  payload: events,
});

export const createCameraRecordings = (url, duration) => ({
  type: ActionTypes.CREATE_CAMERA_RECORDINGS,
  payload: { url, duration },
});

export const extendCameraRecordings = () => ({
  type: ActionTypes.EXTEND_CAMERA_RECORDINGS,
});

export const getDatastream = (workflowUuid, cameraUuid, actions) => ({
  type: ActionTypes.GET_DATASTREAM,
  payload: { workflowUuid, cameraUuid },
  actions,
});

export const verifyCamera = (url, actions) => ({
  type: ActionTypes.VERIFY_CAMERA,
  payload: { url },
  actions,
});

export const addCamera = (params, actions) => ({
  type: ActionTypes.ADD_CAMERA,
  payload: params,
  actions,
});

export const updateCamera = (params, actions) => ({
  type: ActionTypes.UPDATE_CAMERA,
  payload: params,
  actions,
});

export const deleteCamera = (uuid, actions) => ({
  type: ActionTypes.DELETE_CAMERA,
  payload: uuid,
  actions,
});

export const getFrames = (uuid, params) => ({
  type: ActionTypes.GET_CAMERA_FRAMES,
  payload: { uuid, params },
});

export const getFramesNextPage = (uuid) => ({
  type: ActionTypes.GET_CAMERA_FRAMES_NEXT_PAGE,
  payload: { uuid },
});

export const setCameraSubscribe = (frame) => ({
  type: ActionTypes.SET_SUBSCRIBE_CAMERA,
  payload: frame,
});

export const setCameraUnSubscribe = (uuid) => ({
  type: ActionTypes.SET_UNSUBSCRIBE_CAMERA,
  payload: uuid,
});

export const setCameraLive = (uuid) => ({ type: ActionTypes.SET_CAMERA_LIVE, payload: uuid });
export const setCameraLiveReset = (uuid) => ({ type: ActionTypes.SET_CAMERA_LIVE_RESET, payload: uuid });
export const setCameraUnmount = () => ({ type: ActionTypes.SET_CAMERA_UNMOUNT });

export const setActiveCameraWorkflow = (uuid, type) => ({
  type: ActionTypes.SET_ACTIVE_CAMERA_WORKFLOW,
  payload: { uuid, type },
});

export const setActiveCameraZones = (zones) => ({
  type: ActionTypes.SET_ACTIVE_CAMERA_ZONES,
  payload: { zones },
});

export const changeActiveCameraOption = (key, value) => {
  return { type: ActionTypes.CHANGE_ACTIVE_CAMERA_OPTION, payload: { key, value } };
};

export const changeCamerasValue = (key, value) => ({
  type: ActionTypes.CHANGE_CAMERAS_VALUE,
  payload: { key, value },
});

export const changeCameraValue = (key, value) => ({
  type: ActionTypes.CHANGE_CAMERA_VALUE,
  payload: { key, value },
});

export const resetCameraViewWidgets = () => ({
  type: ActionTypes.RESET_CAMERA_VIEW_WIDGETS,
});

export const changeCameraHeatmapValue = (key, value) => ({
  type: ActionTypes.CHANGE_CAMERA_HEATMAP_VALUE,
  payload: { key, value },
});

export const changeWidgetsValue = (key, value) => ({
  type: ActionTypes.CHANGE_WIDGETS_VALUE,
  payload: { key, value },
});

export const getWidgets = (params, actions) => ({
  type: ActionTypes.GET_DASHBOARD_WIDGETS,
  payload: params,
  actions,
});

export const addWidget = (params) => ({
  type: ActionTypes.ADD_DASHBOARD_WIDGET,
  payload: params,
});

export const deleteWidget = (uuid) => ({
  type: ActionTypes.DELETE_WIDGET,
  payload: uuid,
});

export const addFilter = (params) => ({
  type: ActionTypes.ADD_FILTER,
  payload: params,
});

export const getFilters = (params, actions) => ({
  type: ActionTypes.GET_FILTERS,
  payload: params,
  actions,
});

export const changeCamerasFilters = (key, value) => ({
  type: ActionTypes.CHANGE_CAMERAS_FILTERS,
  payload: { key, value },
});
