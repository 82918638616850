import { takeEvery, call, put, select, all } from 'redux-saga/effects';

import * as ActionTypes from '../constants/limits.constants';
import * as api from '../api/limits';

export function* getLimits({ payload, actions = {} }) {
  const limits = yield select((s) => s.limits);
  try {
    const response = yield call(api.getLimits, { ...limits.query, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_LIMITS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_LIMITS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getMyLimits({ payload, actions = {} }) {
  try {
    const response = yield call(api.getMyLimits, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_MY_LIMITS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_MY_LIMITS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addLimit({ payload, actions = {} }) {
  try {
    const response = yield call(api.addLimit, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_LIMIT_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_LIMIT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateLimit({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateLimit, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_LIMIT_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_LIMIT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteLimit({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteLimit, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_LIMIT_SUCCESS, payload: payload });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_LIMIT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getAccountLimits({ payload, actions = {} }) {
  const limits = yield select((s) => s.limits);
  try {
    const response = yield call(api.getAccountLimits, { ...limits.accQuery, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_ACCOUNT_LIMITS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ACCOUNT_LIMITS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addAccountLimit({ payload, actions = {} }) {
  try {
    const response = yield call(api.addAccountLimit, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_ACCOUNT_LIMIT_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_ACCOUNT_LIMIT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateAccountLimit({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateAccountLimit, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_ACCOUNT_LIMIT_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_ACCOUNT_LIMIT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteAccountLimit({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteAccountLimit, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_ACCOUNT_LIMIT_SUCCESS, payload: payload });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_ACCOUNT_LIMIT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getUserAllLimits({ payload, actions = {} }) {
  try {
    const response = yield call(api.getUserAllLimits, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_USER_ALL_LIMITS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_ALL_LIMITS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* limitsSagas() {
  yield all([
    takeEvery(ActionTypes.GET_LIMITS, getLimits),
    takeEvery(ActionTypes.GET_MY_LIMITS, getMyLimits),
    takeEvery(ActionTypes.ADD_LIMIT, addLimit),
    takeEvery(ActionTypes.UPDATE_LIMIT, updateLimit),
    takeEvery(ActionTypes.DELETE_LIMIT, deleteLimit),
    takeEvery(ActionTypes.GET_ACCOUNT_LIMITS, getAccountLimits),
    takeEvery(ActionTypes.ADD_ACCOUNT_LIMIT, addAccountLimit),
    takeEvery(ActionTypes.UPDATE_ACCOUNT_LIMIT, updateAccountLimit),
    takeEvery(ActionTypes.DELETE_ACCOUNT_LIMIT, deleteAccountLimit),
    takeEvery(ActionTypes.GET_USER_ALL_LIMITS, getUserAllLimits),
  ]);
}
