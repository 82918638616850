import React, { Component, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import webRTCService from '../../../services/WebRTCService';

import DrawingLayerLive from '../../DrawingLayer/DrawingLayerLive';

import * as workflowActions from '../../../actions/workflows.action';
import * as cameraActions from '../../../actions/cameras.actions';

import { DEFAULT_VIDEO_RATIO } from '../../../constants/cameras.constants';

const PlayerLive = ({
  camera,
  heatmap,
  events,
  onWebRTCError,
  getZonesForWorkflowCamera,
  setActiveCameraZones,
  changeCameraValue,
}) => {
  const [tWidth, setTWidth] = useState(0);
  const [tHeight, setTHeight] = useState(0);

  const video = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', handleResizeWindow);
    webRTCService.startStream(camera.uuid, 'live', video.current, null, { onError: onWebRTCError });

    return () => {
      webRTCService.stopStream();
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    if (camera.activeWorkflowUuid && camera.uuid) {
      getZonesForWorkflowCamera(camera.activeWorkflowUuid, camera.uuid, {
        onSuccess: (zones) => {
          setActiveCameraZones(zones);
        },
      });
    }
  }, [camera.activeWorkflowUuid]);

  useEffect(() => {
    webRTCService.stopStream();
    if (camera.options.isShowObjects) {
      webRTCService.startStream(camera.uuid, 'live-result', video.current, null, { onError: onWebRTCError });
    } else {
      webRTCService.startStream(camera.uuid, 'live', video.current, null, { onError: onWebRTCError });
    }
  }, [camera.options.isShowObjects]);

  useEffect(() => {
    handleResizeWindow();
  });

  const handleResizeWindow = () => {
    const streamRatio =
      video.current.videoWidth && video.current.videoHeight
        ? video.current.videoWidth / video.current.videoHeight
        : DEFAULT_VIDEO_RATIO;
    const containerRatio = video.current.offsetWidth / video.current.offsetHeight;

    if (streamRatio >= containerRatio) {
      setTWidth(video.current.offsetWidth);
      setTHeight(video.current.offsetWidth / streamRatio);
    } else {
      setTWidth(video.current.offsetHeight * streamRatio);
      setTHeight(video.current.offsetHeight);
    }
  };

  return (
    <>
      <video ref={video} muted={true} playsInline={true} autoPlay onCanPlay={() => handleResizeWindow}>
        <p>Your browser doesn't support HTML5 video.</p>
      </video>
      {heatmap && (
        <img
          src={heatmap}
          style={{
            width: tWidth,
            height: tHeight,
            zIndex: 2,
            position: 'absolute',
            opacity: '0.25',
            filter: 'alpha(opacity=25)',
          }}
        />
      )}
      <DrawingLayerLive
        width={tWidth}
        height={tHeight}
        zones={camera.zones}
        processing={camera.processing}
        clearProcessing={() => changeCameraValue('processing', [])}
        showOptions={camera.options}
      />
    </>
  );
};

const mapStateToProps = ({ player }) => {
  const { camera, isLoading, controls, events } = player;
  return { camera, events, pause: controls.pause, live: controls.live, isLoading };
};

const mapDispatchToProps = (dispatch) => ({
  getZonesForWorkflowCamera: (workflowUuid, cameraUuid, actions) =>
    dispatch(workflowActions.getZonesForWorkflowCamera(workflowUuid, cameraUuid, actions)),
  setActiveCameraZones: (zones) => dispatch(cameraActions.setActiveCameraZones(zones)),
  changeCameraValue: (key, value) => dispatch(cameraActions.changeCameraValue(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayerLive);
