import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../components/Table';
import { connect } from 'react-redux';
import i18n from '../../i18n';

import DropdownDotMenu from '../../components/DropdownDotMenu';
import ModalRole from './Modals/ModalRole';

import * as rolesActions from '../../actions/roles.actions';

class RolesList extends Component {
  state = {
    modalAdd: false,
    modalEdit: false,
    modalDelete: false,
    editRole: {},
  };

  componentDidMount() {
    this.props.getRoles();
  }

  componentDidUpdate(prev) {
    prev.page !== this.props.page && this.props.getRoles();
  }

  closeModal = () => {
    this.setState({ modalAdd: false, modalEdit: false });
    this.props.changeRoleValue('authorities', []);
    this.props.changeRoleValue('name', '');
    this.props.changeRoleValue('description', '');
  };

  addRole = () => {
    this.props.addRole(
      {
        name: this.props.name,
        authorities: this.props.authorities,
        description: this.props.description,
      },
      { onSuccess: () => this.props.getRoles() }
    );
    this.closeModal();
  };

  updateRole = () => {
    this.props.updateRole(
      {
        name: this.props.name,
        authorities: this.props.authorities,
        description: this.props.description,
        uuid: this.state.modalEdit,
      },
      { onSuccess: this.props.getRoles() }
    );
    this.closeModal();
  };

  updateModal(id) {
    this.props.getRole(id, {
      onSuccess: (data) => {
        this.setState({ modalEdit: id });
        this.props.changeRoleValue('authorities', data.authorities);
        this.props.changeRoleValue('name', data.name);
        this.props.changeRoleValue('description', data.description);
      },
    });
  }

  changeValue(name, value) {
    console.warn(name + ' ' + value);
    name === 'ALL' && value === true
      ? this.props.changeRoleValue('authorities', ['ALL'])
      : this.props.changeRoleValue(
          'authorities',
          value === true
            ? [...this.props.authorities, name]
            : this.props.authorities?.filter((auth) => auth !== name)
        );
  }

  createRows = (rows) => {
    return rows.map((role) => ({
      id: role.uuid,
      cells: [
        {
          label: role.name,
          cellComponent: <h5 className="table__cell table__cell--bold">{role.name}</h5>,
        },
        {
          label: role.authorities.map((auth) => (auth.includes('READ') ? auth.slice(5) + ' ' : '')),
        },
        {
          label: role.authorities.map((auth) => (auth.includes('WRITE') ? auth.slice(6) + ' ' : '')),
        },
        {
          label: (
            <span data-toggle="tooltip" data-placement="top" title={role.description}>
              {role.description?.length > 20 ? role.description?.substring(0, 20) + '...' : role.description}
            </span>
          ),
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                { name: 'Edit', onClick: () => this.updateModal(role.uuid) },
                {
                  name: 'Delete',
                  onClick: () => {
                    this.props.deleteRole(role.uuid, { onSuccess: () => this.props.getRoles() });
                  },
                },
              ]}
            />
          ),
        },
      ],
    }));
  };

  render() {
    const { list, total, size, page } = this.props;

    const headerOptions = [
      {
        label: 'Name',
      },
      {
        label: 'View',
      },
      {
        label: 'Edit',
      },
      { label: 'Description' },
      {
        label: '',
      },
    ];

    const { modalAdd, modalEdit } = this.state;

    return (
      <>
        <Table
          rows={this.createRows(list)}
          headerOptions={headerOptions}
          title={i18n.t('nav:roles')}
          totalRows={total}
          size={size}
          page={page}
          isLoading={this.props.isLoading}
          onClick={() => this.setState({ modalAdd: true })}
          changePage={(page) => this.props.changePageValue("query['page']", page)}
        />
        {modalAdd && (
          <ModalRole
            loading={false}
            isOpen={modalAdd}
            name={this.props.name}
            description={this.props.description}
            params={this.props.authorities}
            changeValue={(name, value) => this.props.changeRoleValue(name, value)}
            changeAuthValue={(name, value) => this.changeValue(name, value)}
            onSubmit={() => this.addRole()}
            onClose={() => this.closeModal()}
            className="modal-roles"
          />
        )}
        {modalEdit && (
          <ModalRole
            edit={true}
            loading={false}
            isOpen={modalEdit}
            name={this.props.name}
            description={this.props.description}
            params={this.props.authorities}
            changeValue={(name, value) => this.props.changeRoleValue(name, value)}
            changeAuthValue={(name, value) => this.changeValue(name, value)}
            onSubmit={() => this.updateRole()}
            onClose={() => this.closeModal()}
            className="modal-roles"
          />
        )}
      </>
    );
  }
}

RolesList.propTypes = {
  rolesList: PropTypes.array,
};

const mapStateToProps = ({ roles }) => {
  const {
    list: { items, total },
    query: { size, page },
    authorities,
    name,
    description,
    isLoading,
  } = roles;
  return {
    list: items,
    total,
    size,
    page,
    authorities,
    name,
    description,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRoles: (params, actions) => dispatch(rolesActions.getRoles(params, actions)),
  getRole: (id, actions) => dispatch(rolesActions.getRole(id, actions)),
  getAuthorities: (params, actions) => dispatch(rolesActions.getAuthorities(params, actions)),
  addRole: (params, actions) => dispatch(rolesActions.addRole(params, actions)),
  updateRole: (params, actions) => dispatch(rolesActions.updateRole(params, actions)),
  deleteRole: (uuid, actions) => dispatch(rolesActions.deleteRole(uuid, actions)),
  changePageValue: (key, value) => dispatch(rolesActions.changePageValue(key, value)),
  changeRoleValue: (key, value) => dispatch(rolesActions.changeRoleValue(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RolesList);
