import React, { Component } from 'react';
import { matchPath } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import Timer from 'setinterval';
import moment from 'moment';

import CameraViewAlerts from './CameraViewAlerts';
import Player from '../../../../components/Player';

import * as actions from '../../../../actions/cameras.actions';
import { getCameraRecordings } from '../../../../actions/cameras.actions';
import * as workflowActions from '../../../../actions/workflows.action';
import {
  getWorkflowsUsingCameras,
  getAnalyticsForWorkflowCamera,
  getTriggersForWorkflowCamera,
} from '../../../../actions/workflows.action';
import {
  setCurrent,
  setRecordingScreen,
  setPause,
  setTimestampsValue,
} from '../../../../actions/player.actions';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import {
  showObjects,
  showObjectsByAlertType,
  showObjectsByEventType,
  showLines,
  showZones,
  showZonesByAlertType,
  showZonesByEventType,
  showActivity,
  showHeatmap,
  showQueues,
  showAccumulation,
  showHeatmapQueues,
} from '../../../../constants/cameras.constants';
import notificationsService from '../../../../services/NotificationsService';

import { callWithVideoDuration } from '../../../../utils';

class CameraView extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.analyticIntervals = {};
    this.state = {
      alertsPreview: '',
      activeWorkflow: '',
      activeWorkflowType: '',
      workflows: [],
      analytic: [],
      heatmap: false,
      heatmapImage: null,
      heatmapUpdateInterval: 0,
      objects: false,
      lines: false,
      zones: false,
      activity: false,
      playerAnalytics: [],
      accumActivity: false,
      playerAccumAnalytics: [],
      InOutWidget: [],
      queue: [],
      started: true,
      heatmapQueue: false,
    };
  }

  componentDidMount() {
    const { uuid } = this.props.match.params;
    if (uuid) {
      this.props.changeCameraValue('cameraUuid', uuid);
      this.props.getCamera(uuid, {
        onSuccess: (camera) => {
          callWithVideoDuration(camera.demoStreamUrl, this.props.createCameraRecordings);
          fetch(camera.previewUrl)
            .then((response) => response.blob())
            .then((blob) => {
              this.setState({ alertsPreview: URL.createObjectURL(blob) });
            });
        },
      });
    } else {
      console.log('You need a uuid to query for the parameter.');
    }
    this.props.getWorkflowsUsingCameras(this.props.match?.params.uuid, {
      onSuccess: (data) => {
        this.setState({ workflows: data });
        this.props.getCameraRecordings(
          this.props.match?.params.uuid,
          {},
          {
            onSuccess: (recordings) => {
              const location = this.props.location;
              if (location.state && Object.keys(location.state).length) {
                const alert = location.state.alert;
                const activeWorkflow = data.find(
                  (workflow) => workflow.uuid === alert.workflowUuid || workflow.uuid === alert.workflow?.uuid
                );
                this.chooseWorkflow({
                  label: activeWorkflow.name,
                  value: activeWorkflow.uuid,
                  type: activeWorkflow.processingConfig?.type,
                });
                (showObjectsByAlertType.includes(alert.eventType) ||
                  showObjectsByEventType.includes(alert.eventType)) &&
                  this.props.changeActiveCameraOption('isShowObjects', true);
                (showZonesByAlertType.includes(alert.eventType) ||
                  showZonesByEventType.includes(alert.eventType)) &&
                  this.props.changeActiveCameraOption('isShowZones', true);
                this.props.setCurrentTime(
                  new Date(alert.eventTimestamp ? alert.eventTimestamp : alert.timestamp)
                );
              }
            },
          }
        );
      },
    });
  }

  findNearestAlert = (alerts, playerTime) => {
    const alertsTimes = Object.keys(alerts);
    let nearestTs = alertsTimes[0];
    let nearestAlert = [];
    Object.keys(alerts).map((alertTs) => {
      if (playerTime - alertTs >= 0 && playerTime - alertTs < 100 && alerts[alertTs].length) {
        nearestTs = alertTs;
        nearestAlert = alerts[alertTs];
      }
    });
    return nearestAlert.length ? nearestAlert : null;
  };

  subscribeCameraTopic = (client) => {
    const event = this.props.controls.timestamps.widgets;
    if (
      this.state.activeWorkflow !== '' &&
      this.state.workflows.find((item) => item.uuid === this.state.activeWorkflow).name === event.workflowName
    ) {
      const times =
        event.widgets && event.widgets.accumulationActivity
          ? Object.keys(event.widgets.accumulationActivity).map((item) => Math.round(item / 100) * 100)
          : [];
      const events =
        event.widgets && event.widgets.accumulationActivity
          ? Object.values(event.widgets.accumulationActivity)
          : [];
      this.analyticIntervals['CameraAnalytic'] && clearInterval(this.analyticIntervals['CameraAnalytic']);
      this.analyticIntervals['CameraAnalytic'] = setInterval(() => {
        if (times.includes(this.state.currentTime)) {
          this.props.changeCameraValue(
            'accumAnalytic',
            events[times.indexOf(this.state.currentTime)].map((item) => ({
              title: event.zoi.find((zone) => zone.id === item.zoneId).name,
              index: event.zoi.map((zone) => zone.id).indexOf(item.zoneId),
              data: item.activityAnalytics.map((analytic) => ({
                text: analytic.accumulationIntervalName,
                value: `${Math.floor(analytic.activityDuration / 3600)
                  .toString()
                  .padStart(2, '0')}:${Math.floor((analytic.activityDuration % 3600) / 60)
                  .toString()
                  .padStart(2, '0')}:${(analytic.activityDuration % 60).toString().padStart(2, '0')}`,
              })),
            }))
          );
        }
      }, 100);
    }
  };

  subscribeAnalyticsTopic = (client) => {
    const event = this.props.controls.timestamps.widgets;
    this.state.analytic.map((analytic) => {
      if (
        analytic === 'LineCrossingObjectsCounting' &&
        this.props.camera.zones.lines.find((zoi) => event.lines.map((zone) => zone.id).includes(zoi.id))
      ) {
        const times =
          event.widgets && event.widgets.inOut
            ? Object.keys(event.widgets.inOut).map((item) => Math.round(item / 100) * 100)
            : [];
        const events = event.widgets && event.widgets.inOut ? Object.values(event.widgets.inOut) : [];
        const latestevent = this.props.controls.timestamps.alerts;
        if (event.widgets && event.widgets.inOut) {
          this.analyticIntervals[analytic] && clearInterval(this.analyticIntervals[analytic]);
          this.analyticIntervals[analytic] = setInterval(() => {
            event.widgets.inOut &&
              times.includes(this.state.currentTime) &&
              this.props.changeCameraValue(
                'inout',
                events[times.indexOf(this.state.currentTime)].linesCrossingCount.map((item, index) => ({
                  name: event.lines.find((it) => it.id === item.lineId).name,
                  index: event.lines.map((line) => line.id).indexOf(item.lineId),
                  in: item.in,
                  out: item.out,
                }))
              );
            if (latestevent.alerts && this.findNearestAlert(latestevent.alerts, this.state.currentTime)) {
              const nearestAlert = this.findNearestAlert(latestevent.alerts, this.state.currentTime)[0];
              const notification = {};
              notification.notificationId = Math.random().toString(36).substring(2, 9);
              notification.time = moment(
                this.props.controls.recording?.from + this.state.currentTime
              ).valueOf();
              notification.cameraUuid = this.props.match.params.uuid;
              notification.workflowName = latestevent.workflowName;
              notification.eventType = nearestAlert.eventType;
              notification.thumbUrl = this.state.alertsPreview;
              notificationsService.add(notification);
            }
          }, 100);
        }
      } else if (
        analytic === 'QueuesDetectorAnalytics' &&
        this.props.camera.zones.zoi.find((zoi) => event.zoi.map((zone) => zone.id).includes(zoi.id))
      ) {
        const times =
          event.widgets && event.widgets.queueSize
            ? Object.keys(event.widgets.queueSize).map((item) => Math.round(item / 100) * 100)
            : [];
        const events = event.widgets && event.widgets.queueSize ? Object.values(event.widgets.queueSize) : [];
        const latestevent = this.props.controls.timestamps.alerts;
        this.analyticIntervals[analytic] && clearInterval(this.analyticIntervals[analytic]);
        this.analyticIntervals[analytic] = setInterval(() => {
          times.includes(this.state.currentTime) &&
            this.props.changeCameraValue(
              'queue',
              events[times.indexOf(this.state.currentTime)].flat().map((item) => ({
                name: event.zoi.find((zone) => zone.id === item.zoneId).name,
                index: event.zoi.map((zone) => zone.id).indexOf(item.zoneId),
                members: item.size,
              }))
            );
          if (latestevent.alerts && this.findNearestAlert(latestevent.alerts, this.state.currentTime)) {
            const nearestAlert = this.findNearestAlert(latestevent.alerts, this.state.currentTime)[0];
            const notification = {};
            notification.notificationId = Math.random().toString(36).substring(2, 9);
            notification.time = moment(
              this.props.controls.recording?.from + this.state.currentTime
            ).valueOf();
            notification.cameraUuid = this.props.match.params.uuid;
            notification.workflowName = latestevent.workflowName;
            notification.eventType = nearestAlert.eventType;
            notification.thumbUrl = this.state.alertsPreview;
            notificationsService.add(notification);
          }
        }, 100);
      } else if (
        analytic === 'ZonesRealTimeActivityDurationAnalytics' &&
        this.props.camera.zones.zoi.find((zoi) => event.zoi.map((zone) => zone.id).includes(zoi.id))
      ) {
        const times = event.widgets
          ? Object.keys(event.widgets.realtimeActivity).map((item) => Math.round(item / 100) * 100)
          : [];
        const events = event.widgets ? Object.values(event.widgets.realtimeActivity) : [];

        const latestevent = this.props.controls.timestamps.alerts;
        if (this.props.camera.zones.zoi.find((zone) => event.zoi.map((zo) => zo.id).includes(zone.id))) {
          this.analyticIntervals[analytic] && clearInterval(this.analyticIntervals[analytic]);
          this.analyticIntervals[analytic] = setInterval(() => {
            times.includes(this.state.currentTime) &&
              events[times.indexOf(this.state.currentTime)].length !== 0 &&
              this.props.changeCameraValue(
                'analytic',
                events[times.indexOf(this.state.currentTime)].map((item) => ({
                  title: this.props.camera.zones.zoi.find((zone) => zone.id === item.zoneId)?.name,
                  index: this.props.camera.zones.zoi.map((zone) => zone.id).indexOf(item.zoneId),
                  value: `${Math.floor(item.duration / 3600)
                    .toString()
                    .padStart(2, '0')}:${Math.floor((item.duration % 3600) / 60)
                    .toString()
                    .padStart(2, '0')}:${Math.round(item.duration % 60)
                    .toString()
                    .padStart(2, '0')}`,
                }))
              );
            if (latestevent.alerts && this.findNearestAlert(latestevent.alerts, this.state.currentTime)) {
              const nearestAlert = this.findNearestAlert(latestevent.alerts, this.state.currentTime)[0];
              const notification = {};
              notification.notificationId = Math.random().toString(36).substring(2, 9);
              notification.time = moment(
                this.props.controls.recording?.from + this.state.currentTime
              ).valueOf();
              notification.cameraUuid = this.props.match.params.uuid;
              notification.workflowName = latestevent.workflowName;
              notification.eventType = nearestAlert.eventType;
              notification.thumbUrl = this.state.alertsPreview;
              notificationsService.add(notification);
            }
          }, 100);
        }
      } else if (showObjects.includes(analytic)) {
        const latestevent = this.props.controls.timestamps.alerts;
        this.analyticIntervals[analytic] && clearInterval(this.analyticIntervals[analytic]);
        this.analyticIntervals[analytic] = setInterval(() => {
          if (latestevent.alerts && this.findNearestAlert(latestevent.alerts, this.state.currentTime)) {
            const nearestAlerts = this.findNearestAlert(latestevent.alerts, this.state.currentTime);
            nearestAlerts.map((alert, index) => {
              const notification = Object.assign({}, alert);
              notification.notificationId = Math.random().toString(36).substring(2, 9);
              notification.time = new Date(
                moment(this.props.controls.recording?.from + this.state.currentTime).valueOf()
              );
              notification.cameraUuid = this.props.match.params.uuid;
              notification.workflowName = latestevent.workflowName;
              notification.eventType = alert.eventType;
              notification.thumbUrl = this.state.alertsPreview;
              notificationsService.add(notification);
            });
          }
        }, 100);
      }
    });
  };

  subscribeAnalytic() {
    this.state.analytic.length !== 0 && this.subscribeAnalyticsTopic();
  }

  componentDidUpdate(prev) {
    const { controls } = this.props;

    !this.props.camera.options.updateHeatmap &&
      this.state.heatmapUpdateInterval !== 0 &&
      this.timer.clearInterval();

    if (prev.controls.timestamps.recording !== controls.timestamps.recording) {
      this.props.setCameraRecordingsList(controls.timestamps.recording);
      if (controls.timestamps.recording[0]?.detectionsUrl && controls.timestamps.alerts.alerts) {
        this.props.setCameraEventsTimestamps([]);
        this.props.setCameraEventsTimestamps(
          this.makeTimelineEvents(
            controls.timestamps.recording,
            controls.timestamps.alerts.alerts,
            controls.range
          )
        );
      }
    }

    if (prev.controls.range !== controls.range && controls.timestamps.alerts.alerts) {
      this.props.setCameraEventsTimestamps([]);
      this.props.setCameraEventsTimestamps(
        this.makeTimelineEvents(
          controls.timestamps.recording,
          controls.timestamps.alerts.alerts,
          controls.range
        )
      );
    }

    if (prev.controls.recording !== controls.recording) {
      const lastRec = controls.timestamps.recording[0];
      if (lastRec && lastRec.to - Date.now() < 2 * 60 * 1000) {
        this.props.extendCameraRecordings();
      }
    }

    if (
      (prev.controls.timestamps.widgets !== controls.timestamps.widgets ||
        prev.controls.timestamps.alerts !== controls.timestamps.alerts) &&
      Object.keys(controls.timestamps.alerts).length &&
      Object.keys(controls.timestamps.widgets).length
    ) {
      this.subscribeAnalytic();
      this.subscribeCameraTopic();
    }
  }

  handleGoBack = () => {
    this.props.history.goBack();
  };

  componentWillUnmount() {
    this.props.setCameraUnmount();
    this.props.resetCameraViewWidgets();
    Object.values(this.analyticIntervals).forEach((anInterval) => clearInterval(anInterval));
  }

  makeTimelineEvents = (recordings, alerts, range) => {
    if (recordings.length < 1) return [];
    const alertsTs = Object.keys(alerts);
    const timelineEvents = [];
    const timelineGroupedEvents = [];

    recordings.map((rec) => {
      alertsTs.map((alertTs) => {
        if (alerts[alertTs].length) {
          timelineEvents.push({
            eventTimestamp: rec.from + Number(alertTs),
            eventType: alerts[alertTs][0].eventType,
          });
        }
      });
    });

    let groupInterval = 0;
    let visibleRecStart = recordings[recordings.length - 1].from;
    switch (range) {
      case '1m':
        groupInterval = 1000;
        visibleRecStart =
          Date.now() - 60 * 1000 < recordings[recordings.length - 1].from
            ? recordings[recordings.length - 1].from
            : Date.now() - 60 * 1000;
        break;
      case '10m':
        groupInterval = 5000;
        visibleRecStart =
          Date.now() - 10 * 60 * 1000 < recordings[recordings.length - 1].from
            ? recordings[recordings.length - 1].from
            : Date.now() - 10 * 60 * 1000;
        break;
      case '1hr':
        groupInterval = 20000;
        visibleRecStart =
          Date.now() - 60 * 60 * 1000 < recordings[recordings.length - 1].from
            ? recordings[recordings.length - 1].from
            : Date.now() - 60 * 60 * 1000;
        break;
      case '12hr':
        groupInterval = 72000;
        visibleRecStart =
          Date.now() - 12 * 60 * 60 * 1000 < recordings[recordings.length - 1].from
            ? recordings[recordings.length - 1].from
            : Date.now() - 12 * 60 * 60 * 1000;
        break;
      case '24hr':
        groupInterval = 144000;
        visibleRecStart =
          Date.now() - 24 * 60 * 60 * 1000 < recordings[recordings.length - 1].from
            ? recordings[recordings.length - 1].from
            : Date.now() - 24 * 60 * 60 * 1000;
        break;
    }
    const recStart = visibleRecStart;
    const recEnd = recordings[0].to;
    const groupsCount = Math.ceil((recEnd - recStart) / groupInterval);

    for (let i = 0; i < groupsCount; i++) {
      const groupStartTs = recStart + groupInterval * i;
      const groupEndTs = groupStartTs + groupInterval;
      const groupEvents = timelineEvents.filter(
        (event) => event.eventTimestamp > groupStartTs && event.eventTimestamp < groupEndTs
      );
      groupEvents.length && timelineGroupedEvents.push(groupEvents[0]);
    }

    return timelineGroupedEvents;
  };

  fetchDatastreamData = (workflowUuid, cameraUuid) => {
    this.props.getDatastream(workflowUuid, cameraUuid, {
      onSuccess: (results) => {
        fetch(results.alertsUrl)
          .then((response) => response.json())
          .then((data) => {
            this.props.setTimestampsValue('alerts', data);
            this.props.setCameraEventsTimestamps(
              this.makeTimelineEvents(
                this.props.controls.timestamps.recording,
                data.alerts,
                this.props.controls.range
              )
            );
          });
        fetch(results.widgetsUrl)
          .then((response) => response.json())
          .then((data) => {
            this.props.setTimestampsValue('widgets', data);
          })
          .then(() => {
            this.props.getZonesForWorkflowCamera(workflowUuid, cameraUuid, {
              onSuccess: (data) => {
                this.props.setActiveCameraZones(data);
              },
            });
          });
        results.processingResultUrl &&
          fetch(results.processingResultUrl)
            .then((response) => response.json())
            .then((data) => {
              this.props.setTimestampsValue('processing', data.processingResult);
            });
      },
    });
  };

  chooseWorkflow = (item) => {
    const { uuid } = this.props.match.params;
    this.props.resetCameraViewWidgets();
    Object.values(this.analyticIntervals).forEach((anInterval) => clearInterval(anInterval));
    this.setState({
      activeWorkflow: item.value,
      activeWorkflowType: item.type,
      objects: false,
      heatmap: false,
      lines: false,
      zones: false,
      activity: false,
      InOutWidget: [],
      analytic: [],
      queue: [],
      started: true,
      playerAnalytics: [],
      accumActivity: false,
      heatmapImage: '',
      heatmapQueue: false,
    });

    this.fetchDatastreamData(item.value, uuid);
    this.props.setActiveCameraWorkflow(item.value, item.type);
    this.setState({ started: this.state.workflows.find((wf) => wf.uuid === item.value).started });
    this.props.getZonesForWorkflowCamera(item.value, uuid, {
      onSuccess: (data) => {
        this.props.setActiveCameraZones(data);
      },
    });
    this.props.getAnalyticsForWorkflowCamera(item.value, uuid, {
      onSuccess: (data) => {
        this.props.events.faceLegend = data.some((type) => type.type === 'SubjectsVectorAnalyzer');

        data.length !== 0 &&
          data.forEach((type) => {
            showHeatmap.includes(type.type) && this.setState({ heatmap: true });
            showHeatmapQueues.includes(type.type) && this.setState({ heatmap: true, heatmapQueue: true });
            if (showActivity.includes(type.type)) {
              this.props.getZonesForWorkflowCamera(item.value, uuid, {
                onSuccess: (data) => {
                  this.setState({
                    activity: true,
                    analytic: [...this.state.analytic, 'ZonesRealTimeActivityDurationAnalytics'],
                  });
                  this.props.changeCameraValue(
                    'analytic',
                    data.zoi.map((item, index) => ({
                      title: item.name,
                      index: index,
                      value: '--:--:--',
                    }))
                  );
                  this.subscribeAnalytic();
                },
              });
            }
            if (showAccumulation.includes(type.type)) {
              this.setState({ accumActivity: true });
              this.props.accumAnalytic.length === 0 &&
                this.props.getZonesForWorkflowCamera(item.value, uuid, {
                  onSuccess: (data) => {
                    this.props.changeCameraValue(
                      'accumAnalytic',
                      data.zoi.map((item, index) => ({
                        title: item.name,
                        index: index,
                        data: [{ text: 'Interval Name', value: '--:--:--' }],
                      }))
                    );
                    this.subscribeCameraTopic();
                  },
                });
            }
            if (showZones.includes(type.type)) {
              this.props.getZonesForWorkflowCamera(item.value, uuid, {
                onSuccess: (data) => {
                  data.zoi.length && this.setState({ zones: true });
                },
              });
            }
            if (showObjects.includes(type.type)) {
              this.setState({
                objects: true,
                analytic: [...this.state.analytic, type.type],
              });
              this.subscribeAnalytic();
            }

            if (showLines.includes(type.type)) {
              this.props.getZonesForWorkflowCamera(item.value, uuid, {
                onSuccess: (data) => {
                  this.setState({
                    lines: data.lines.length ? true : false,
                    analytic: [...this.state.analytic, 'LineCrossingObjectsCounting'],
                  });
                  this.props.changeCameraValue(
                    'inout',
                    data.lines.map((item, index) => ({
                      name: item.name,
                      index: index,
                      in: 0,
                      out: 0,
                    }))
                  );
                  this.subscribeAnalytic();
                },
              });
            }
            if (showQueues.includes(type.type)) {
              this.props.getZonesForWorkflowCamera(item.value, uuid, {
                onSuccess: (data) => {
                  this.setState({
                    objects: true,
                    analytic: [...this.state.analytic, 'QueuesDetectorAnalytics'],
                  });
                  this.props.changeCameraValue(
                    'queue',
                    data.zoi.map((item, index) => ({
                      name: item.name,
                      index: index,
                      members: 0,
                    }))
                  );
                  this.subscribeAnalytic();
                },
              });
            }
          });
      },
    });
  };

  render() {
    const { camera, match } = this.props;
    const { uuid } = match.params;

    const getHeatmap = (from, to) => {
      this.props.changeCameraHeatmapValue('start', from);
      this.props.changeCameraHeatmapValue('end', to);
      this.props.getReadyHeatmap(
        this.state.activeWorkflow,
        uuid,
        {
          start: from,
          end: to,
        },
        {
          onSuccess: (data) => {
            this.setState({ heatmapImage: `data:image/jpeg;base64,${data.image}` });
          },
          onError: (err) => {
            console.log(err);
          },
        }
      );
    };

    const getHeatmapUpdate = (from) => {
      this.props.changeCameraHeatmapValue('online', true);
      this.props.changeCameraHeatmapValue('interval', from);
      this.setState({ heatmapUpdateInterval: Number(from) });
      this.timer = new Timer(
        this.props.getReadyHeatmap(
          this.state.activeWorkflow,
          uuid,
          {
            start: Date.now() - from,
            end: Date.now(),
          },
          {
            onSuccess: (data) => {
              this.setState({ heatmapImage: `data:image/jpeg;base64,${data.image}` });
            },
            onError: (err) => {
              console.log(err);
            },
          }
        ),
        60000
      );
      this.timer.setInterval();
    };

    const getHeatmapForQueues = (from, to) => {
      this.props.getReadyHeatmapForQueues(
        this.state.activeWorkflow,
        uuid,
        {
          start: from,
          end: to,
        },
        {
          onSuccess: (data) => {
            this.setState({ heatmapImage: `data:image/jpeg;base64,${data.image}` });
          },
          onError: (err) => {
            console.log(err);
          },
        }
      );
    };

    const getHeatmapForQueuesUpdate = (from) => {
      this.setState({ heatmapUpdateInterval: Date.now() - from });
      this.timer = new Timer(
        this.props.getReadyHeatmapForQueues(
          this.state.activeWorkflow,
          uuid,
          {
            start: Date.now() - this.state.heatmapUpdateInterval,
            end: Date.now(),
          },
          {
            onSuccess: (data) => {
              this.setState({ heatmapImage: `data:image/jpeg;base64,${data.image}` });
            },
            onError: (err) => {
              console.log(err);
            },
          }
        ),
        18000000
      );
      this.timer.setInterval();
    };

    return (
      <div className="dashboard camera-view h-100">
        <div className="dashboard__wrapper h-100">
          <div className="dashboard__item  dashboard__item--cameras camera-view__wrapper">
            <button type="button" onClick={this.handleGoBack} className="camera-view__link-back">
              <FontAwesomeIcon icon={faArrowCircleLeft} />
              {camera.name}
            </button>
            <Player
              setMockTime={(time) =>
                this.setState({ currentTime: Math.round((Number(time) * 1000) / 100) * 100 })
              }
              cameraUUID={uuid}
              activeWorkflowUuid={this.state.activeWorkflow}
              activeWorkflowType={this.state.activeWorkflowType}
              playerWorkflows={this.state.workflows?.map(
                (item) => (item = { label: item.name, value: item.uuid, type: item.processingConfig?.type })
              )}
              started={this.state.started}
              playerAccumAnalytics={this.props.accumAnalytic}
              chooseWorkflow={(item) => this.chooseWorkflow(item)}
              playerAnalytics={this.props.analytic}
              playerHeatmap={[this.state.heatmap, this.state.heatmapImage, camera.isHeatmapLoading]}
              objects={this.state.objects}
              lines={this.state.lines}
              zones={this.state.zones}
              activity={this.state.activity}
              queue={this.props.queue}
              accumActivity={this.state.accumActivity}
              setHeatmapInterval={(from, to) =>
                this.state.heatmapQueue
                  ? to === null
                    ? getHeatmapForQueuesUpdate(from)
                    : getHeatmapForQueues(from, to)
                  : to === null
                  ? getHeatmapUpdate(from)
                  : getHeatmap(from, to)
              }
              InOutWidget={this.props.inout !== [] ? this.props.inout : false}
            />
          </div>
          <div className="dashboard__item dashboard__item--alerts">
            <CameraViewAlerts
              uuid={uuid}
              camera={camera.name}
              workflows={this.state.workflows}
              alertsPreview={this.state.alertsPreview}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ player }) => {
  const { camera, isLoading, events, controls } = player;
  const { inout, queue, accumAnalytic, analytic } = camera;
  return { camera, isLoading, events, inout, queue, accumAnalytic, analytic, controls };
};

const mapDispatchToProps = (dispatch) => ({
  getCamera: (uuid, acts) => dispatch(actions.getCamera(uuid, acts)),
  setCameraUnmount: () => dispatch(actions.setCameraUnmount()),
  addCameraEvent: (event) => dispatch(actions.addCameraEvent(event)),
  addCameraAlert: (alert) => dispatch(actions.addCameraAlert(alert)),
  setActiveCameraWorkflow: (uuid, type) => dispatch(actions.setActiveCameraWorkflow(uuid, type)),
  setActiveCameraZones: (zones) => dispatch(actions.setActiveCameraZones(zones)),
  getWorkflowsUsingCameras: (uuid, actions) => dispatch(getWorkflowsUsingCameras(uuid, actions)),
  getAnalyticsForWorkflowCamera: (workflowUuid, cameraUuid, actions) =>
    dispatch(getAnalyticsForWorkflowCamera(workflowUuid, cameraUuid, actions)),
  getTriggersForWorkflowCamera: (workflowUuid, cameraUuid, params, actions) =>
    dispatch(getTriggersForWorkflowCamera(workflowUuid, cameraUuid, params, actions)),
  getReadyHeatmap: (workflowUuid, cameraUuid, params, action) =>
    dispatch(actions.getReadyHeatmap(workflowUuid, cameraUuid, params, action)),
  getReadyHeatmapForQueues: (workflowUuid, cameraUuid, params, action) =>
    dispatch(actions.getReadyHeatmapForQueues(workflowUuid, cameraUuid, params, action)),
  getZonesForWorkflowCamera: (workflowUuid, cameraUuid, actions) =>
    dispatch(workflowActions.getZonesForWorkflowCamera(workflowUuid, cameraUuid, actions)),
  setCurrentTime: (time) => dispatch(setCurrent(time)),
  setRecordingScreen: (alert) => dispatch(setRecordingScreen(alert)),
  setPause: () => dispatch(setPause()),
  changeCameraHeatmapValue: (key, value) => dispatch(actions.changeCameraHeatmapValue(key, value)),
  changeCameraValue: (key, value) => dispatch(actions.changeCameraValue(key, value)),
  getCameraRecordings: (uuid, params, actions) => dispatch(getCameraRecordings(uuid, params, actions)),
  changeActiveCameraOption: (key, value) => dispatch(actions.changeActiveCameraOption(key, value)),
  createCameraRecordings: (url, duration) => dispatch(actions.createCameraRecordings(url, duration)),
  setCameraRecordingsList: (recordings) => dispatch(actions.setCameraRecordingsList(recordings)),
  setCameraEventsTimestamps: (events) => dispatch(actions.setCameraEventsTimestamps(events)),
  extendCameraRecordings: () => dispatch(actions.extendCameraRecordings()),
  resetCameraViewWidgets: () => dispatch(actions.resetCameraViewWidgets()),
  getDatastream: (workflowUuid, cameraUuid, acts) =>
    dispatch(actions.getDatastream(workflowUuid, cameraUuid, acts)),
  setTimestampsValue: (key, value) => dispatch(setTimestampsValue(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CameraView);
