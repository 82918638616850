import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loader from '../Loader';

const ModalDelete = ({ isOpen, header, body, onClose, onDelete, loading, errorMsg }) => {
  return (
    <Modal isOpen={isOpen} size="sm">
      <ModalHeader toggle={!loading ? onClose : () => {}}>{header}</ModalHeader>
      <ModalBody>
        <p>{body}</p>
      </ModalBody>
      <ModalFooter>
        <Loader loading={loading} />
        {errorMsg || null}
        <button className="btn btn--dark" disabled={loading} onClick={onClose}>
          {i18n.t('buttons:cancel')}
        </button>
        <button className="btn btn--secondary" disabled={loading} onClick={onDelete}>
          {i18n.t('buttons:delete')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

ModalDelete.defaultProps = {
  loading: false,
};

ModalDelete.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  errorMsg: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.string,
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
};

export default ModalDelete;
