import * as ActionTypes from '../constants/limits.constants';

const initialState = {
  list: {
    items: [],
    total: 0,
  },
  myList: {},
  query: {
    size: 10,
    page: 0,
  },
  accList: {
    items: [],
    total: 0,
  },
  accQuery: {
    size: 10,
    page: 0,
  },
  user: {},
  processingTypes: [],
  modalInf: {
    limit: 0,
    procType: '',
    resource: '',
    uuid: '',
  },
  isLoading: false,
  isLoadingUserLimits: false,
  isLoadingAccountLimits: false,
};

export const limitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_LIMITS:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_LIMITS_RESULT:
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items, total },
        query: { size, page },
        isLoading: false,
      };

    case ActionTypes.GET_LIMITS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.GET_ACCOUNT_LIMITS:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_ACCOUNT_LIMITS_RESULT:
      return {
        ...state,
        accList: { items: action.payload.items, total: action.payload.total },
        accQuery: { size: action.payload.size, page: action.payload.page },
        isLoading: false,
      };

    case ActionTypes.GET_ACCOUNT_LIMITS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.GET_MY_LIMITS:
      return {
        ...state,
        isLoading: true,
        isLoadingUserLimits: true,
        isLoadingAccountLimits: true,
      };

    case ActionTypes.GET_MY_LIMITS_RESULT:
      return {
        ...state,
        myList: action.payload,
        isLoading: false,
        isLoadingUserLimits: false,
        isLoadingAccountLimits: false,
      };

    case ActionTypes.GET_MY_LIMITS_ERROR:
      return {
        ...state,
        isLoading: false,
        isLoadingUserLimits: false,
        isLoadingAccountLimits: false,
      };

    case ActionTypes.GET_USER_ALL_LIMITS:
      return {
        ...state,
        isLoadingUserLimits: true,
      };

    case ActionTypes.GET_USER_ALL_LIMITS_RESULT:
      return {
        ...state,
        myList: {
          ...state.myList,
          userLimits: action.payload,
        },
        isLoadingUserLimits: false,
      };

    case ActionTypes.GET_USER_ALL_LIMITS_ERROR:
      return {
        ...state,
        isLoadingUserLimits: false,
      };

    case ActionTypes.ADD_LIMIT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.ADD_LIMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: {
          items: state.list.items,
          total: state.list.items.length + 1,
        },
      };

    case ActionTypes.ADD_ACCOUNT_LIMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        accList: {
          items: state.accList.items,
          total: state.accList.items.length + 1,
        },
      };

    case ActionTypes.ADD_LIMIT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.UPDATE_LIMIT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.UPDATE_LIMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: {
          items: state.list.items,
          total: state.list.items.length + 1,
        },
      };

    case ActionTypes.UPDATE_ACCOUNT_LIMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        accList: {
          items: state.accList.items,
          total: state.accList.items.length + 1,
        },
      };

    case ActionTypes.UPDATE_LIMIT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.DELETE_LIMIT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.DELETE_LIMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: {
          items: state.list.items,
          total: state.list.items.length - 1,
        },
      };

    case ActionTypes.DELETE_ACCOUNT_LIMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        accList: {
          items: state.accList.items,
          total: state.accList.items.length - 1,
        },
      };

    case ActionTypes.DELETE_LIMIT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.CHANGE_PAGE_VALUE:
      return {
        ...state,
        [action.payload.name]: {
          page: action.payload.page,
          size: state[action.payload.name].size,
        },
      };

    case ActionTypes.CHANGE_MODAL_VALUE:
      return {
        ...state,
        modalInf: {
          ...state.modalInf,
          [action.payload.key]: action.payload.value,
        },
      };

    case ActionTypes.CHANGE_LIMIT_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    default:
      return state;
  }
};
