import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

const HeaderCell = ({ label, align, sortable, onSortClick }) => {
  const [sort, setSort] = useState('desc');
  return (
    <th
      className={sortable === true ? 'sortable-cell' : ''}
      key={label}
      onClick={() => {
        setSort(sort === 'desc' ? 'asc' : 'desc');
        onSortClick();
      }}
      align={align ? align : 'left'}
    >
      {label}&nbsp;
      {sortable && (
          <FontAwesomeIcon icon={sort === 'desc' ? faSortDown : faSortUp} />
      )}
    </th>
  );
};

HeaderCell.propTypes = {
  label: PropTypes.string,
  align: PropTypes.string,
  onSortClick: PropTypes.func,
};

export default HeaderCell;
