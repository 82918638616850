import * as ActionTypes from '../constants/accounts.constants';

const initialState = {
  list: {
    items: [],
    total: 0,
  },
  query: {
    size: 10,
    page: 0,
  },
  modalInf: {
    name: '',
    accountType: '',
    userName: '',
    password: '',
    uuid: '',
  },
  isLoading: false,
};

export const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ACCOUNTS:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_ACCOUNTS_RESULT:
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items, total },
        query: { size, page },
        isLoading: false,
      };

    case ActionTypes.GET_ACCOUNTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_ACCOUNT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.ADD_ACCOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.UPDATE_ACCOUNT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.CHANGE_ACCOUNT_PAGE_VALUE:
      return {
        ...state,
        query: {
          page: action.payload,
          size: state.query.size,
        },
      };
    case ActionTypes.CHANGE_ACCOUNT_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case ActionTypes.CHANGE_MODAL_ACCOUNT_VALUE:
      return {
        ...state,
        modalInf: {
          ...state.modalInf,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};
