import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const DropdownDotMenu = ({ options, menuAlignRight, className }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <Dropdown
      className={`dropdown-dots-menu ${className ? className : ''}`}
      isOpen={isMenuOpen}
      toggle={() => setIsMenuOpen(!isMenuOpen)}
    >
      <DropdownToggle className="dropdown__button--no-padding">
        <FontAwesomeIcon icon={faEllipsisH} className="dropdown-dots-menu__icon" />
      </DropdownToggle>
      <DropdownMenu container="body">
        {options.map((item, index) => (
          <DropdownItem key={index} onClick={item.onClick} divider={item.divider} disabled={item.disabled}>
            {item.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

DropdownDotMenu.propTypes = {
  options: PropTypes.array.isRequired,
  menuAlignRight: PropTypes.bool,
  className: PropTypes.string,
};

export default DropdownDotMenu;
