import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Table from '../../components/Table';
import i18n from '../../i18n';

import * as limitsActions from '../../actions/limits.actions';
import * as schemasActions from '../../actions/schemas.action';
import * as userActions from '../../actions/users.actions';

import DropdownDotMenu from '../../components/DropdownDotMenu';
import LimitModal from './Modal';

const LimitsList = ({
  items,
  total,
  size,
  page,
  processingTypes,
  user,
  modalInf,
  isLoading,
  getLimits,
  addLimit,
  updateLimit,
  deleteLimit,
  changePageValue,
  changeModalValue,
  changeValue,
  getUser,
  getProcessingTypes,
  match,
}) => {
  const [modalAdd, setAddModal] = useState(false);
  const [modalEdit, setEditModal] = useState(false);
  const [filter, setFilter] = useState('All');

  useEffect(() => {
    getLimits({ userUuid: match.params.uuid });
    getUser(match.params.uuid, { onSuccess: (data) => changeValue('user', data) });
    getProcessingTypes({ onSuccess: (data) => changeValue('processingTypes', data) });
  }, []);

  useEffect(() => {
    getLimits({ userUuid: match.params.uuid, processingType: filter === 'All' ? null : filter });
  }, [total, page, filter]);

  const createRows = (rows) => {
    return rows.map((limit) => ({
      id: limit.uuid,
      cells: [
        {
          label: limit.processingType ? limit.processingType : '-',
        },
        {
          label: limit.resource,
        },
        {
          label: limit.limit + '',
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                {
                  name: 'Edit',
                  onClick: () => {
                    changeValue('modalInf', {
                      limit: limit.limit,
                      procType: { label: limit.processingType, value: limit.processingType },
                      resource: { label: limit.resource, value: limit.resource },
                      uuid: limit.uuid,
                    });
                    setEditModal(true);
                  },
                },
                { divider: true },
                {
                  name: 'Delete',
                  onClick: () => {
                    deleteLimit(limit.uuid);
                  },
                },
              ]}
            />
          ),
        },
      ],
    }));
  };
  const headerOptions = [
    {
      label: 'Processing Type',
    },
    {
      label: 'Resource',
    },
    {
      label: 'Limit',
    },
    { label: '' },
  ];

  const onClose = () => {
    setAddModal(false);
    setEditModal(false);
    changeValue('modalInf', {
      limit: 0,
      procType: '',
      resource: '',
      uuid: '',
    });
  };

  return (
    <>
      <Table
        rows={createRows(items)}
        headerOptions={headerOptions}
        title={i18n.t('nav:userLimits')}
        totalRows={total}
        size={size}
        page={page}
        filters={[
          {
            title: 'Processing Type',
            options: processingTypes.map((type) => ({ name: type.label, value: type.value })),
          },
        ]}
        onChangeFilters={(title, key) => setFilter(key)}
        changePage={(page) => changePageValue('query', page)}
        onClick={() => setAddModal(true)}
      />
      {modalAdd && (
        <LimitModal
          modal={modalAdd}
          onClose={() => onClose()}
          title="addTitle"
          procTypes={processingTypes}
          info={modalInf}
          onChange={(name, value) => changeModalValue(name, value)}
          onSubmit={() => {
            addLimit({
              limit: modalInf.limit,
              processingType: modalInf.procType.value,
              resource: modalInf.resource.value,
              user: user,
            });
            onClose();
          }}
        />
      )}
      {modalEdit && (
        <LimitModal
          modal={modalEdit}
          onClose={() => onClose()}
          title="editTitle"
          procTypes={processingTypes}
          info={modalInf}
          onChange={(name, value) => changeModalValue(name, value)}
          onSubmit={() => {
            updateLimit({
              limit: modalInf.limit,
              processingType: modalInf.procType.value,
              resource: modalInf.resource.value,
              user: user,
            });
            onClose();
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ limits }) => {
  const {
    list: { items, total },
    query: { size, page },
    isLoading,
    processingTypes,
    modalInf,
    user,
  } = limits;
  return { items, total, size, isLoading, modalInf, page, processingTypes, user };
};

const mapDispatchToProps = (dispatch) => ({
  getLimits: (params, actions) => dispatch(limitsActions.getLimits(params, actions)),
  addLimit: (params, actions) => dispatch(limitsActions.addLimit(params, actions)),
  updateLimit: (params, actions) => dispatch(limitsActions.updateLimit(params, actions)),
  deleteLimit: (uuid, actions) => dispatch(limitsActions.deleteLimit(uuid, actions)),
  changePageValue: (page) => dispatch(limitsActions.changePageValue(page)),
  changeModalValue: (key, value) => dispatch(limitsActions.changeModalValue(key, value)),
  changeValue: (key, value) => dispatch(limitsActions.changeValue(key, value)),
  getUser: (uuid, actions) => dispatch(userActions.getUser(uuid, actions)),
  getProcessingTypes: (actions) => dispatch(schemasActions.getProcessingTypes(actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LimitsList);
