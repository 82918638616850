import React, { useState } from 'react';
import NumericInput from 'react-numeric-input';

export function FormNumericInput({
  label,
  name,
  value,
  onChange,
  onBlur,
  min,
  disabled,
  max,
  step,
  className,
  errorMessage,
}) {
  const [isFocus, setFocus] = useState(false);

  const handleChange = (value) => {
    name ? onChange(name, value) : onChange(value);
  };

  const handleBlur = (e) => {
    setFocus(false);
    onBlur && onBlur(e);
  };

  const customStyle = {
    arrowUp: {
      top: 'auto',
      bottom: '1px',
      borderBottomColor: 'white',
    },
    arrowDown: {
      top: '4px',
      borderTopColor: 'white',
    },
  };

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={
          value || value === 0 || isFocus
            ? 'form-item--responsive-label--active'
            : 'form-item--responsive-label'
        }
      >
        {label}
      </label>

      <NumericInput
        name={name}
        type="number"
        min={min ? min : 0}
        max={max ? max : 100}
        step={step ? step : 1}
        value={value}
        disabled={disabled}
        onFocus={() => setFocus(true)}
        onBlur={handleBlur}
        className={`form-control ${value || value === 0 || isFocus ? 'form-input--active' : ''}`}
        onChange={handleChange}
        style={customStyle}
      />

      {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
    </div>
  );
}
