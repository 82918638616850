import { takeEvery, call, put, select, all } from 'redux-saga/effects';

import * as ActionTypes from '../constants/equipment.constants';
import * as api from '../api/equipment';

export function* getEquipments({ payload, actions = {} }) {
  const equipments = yield select((s) => s.equipment);
  try {
    const response = yield call(api.getEquipments, { ...equipments.query, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_EQUIPMENTS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EQUIPMENTS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getEquipment({ payload, actions = {} }) {
  try {
    const response = yield call(api.getEquipment, payload.uuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addEquipment({ payload, actions = {} }) {
  try {
    const response = yield call(api.addEquipment, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_EQUIPMENT_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_EQUIPMENT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateEquipment({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateEquipment, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_EQUIPMENT_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_EQUIPMENT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteEquipment({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteEquipment, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_EQUIPMENT_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_EQUIPMENT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* equipmentSagas() {
  yield all([
    takeEvery(ActionTypes.GET_EQUIPMENTS, getEquipments),
    takeEvery(ActionTypes.GET_EQUIPMENT, getEquipment),
    takeEvery(ActionTypes.ADD_EQUIPMENT, addEquipment),
    takeEvery(ActionTypes.UPDATE_EQUIPMENT, updateEquipment),
    takeEvery(ActionTypes.DELETE_EQUIPMENT, deleteEquipment),
  ]);
}