import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../components/Avatar';
import { avatarViews } from '../../../constants/userConstants';
import i18n from '../../../i18n';
import * as actions from '../../../actions/app.actions';
import * as userActions from '../../../actions/users.actions';
import * as roleActions from '../../../actions/roles.actions';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faUser } from '@fortawesome/free-solid-svg-icons';
import ModalEdit from '../../UsersList/Modals/ModalEdit';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormInput } from '../../../components/Form';
import UpdateEmail from './Modal/UpdateEmail';
import ConfirmEmail from './Modal/ConfirmEmail';
import InformationModal from '../../../components/InformationModal/InformationModal';

class UserProfile extends Component {
  state = {
    modalEdit: false,
    newInput: 'password',
    oldInput: 'password',
    newPass: '',
    oldPass: '',
    invalidPass: false,
    modalChangePass: false,
    modalChangeEmail: false,
    modalConfirmEmail: false,
    modalError: false,
  };
  componentDidMount() {
    this.props.getMe();
  }

  updateUser = () => {
    const userInfo = this.props.modalInf;
    const roless = this.props.roles.list.items.filter((role) => userInfo.rolesItems.includes(role.uuid));

    if (userInfo.avatarFile !== undefined) {
      const media = new FormData();
      media.append('file', userInfo.avatarFile);
      media.append('mediaTarget', 'AVATAR');
      this.props.uploadMedia(media, {
        onSuccess: (image) => {
          this.props.updateMe(
            {
              firstName: userInfo.firstName,
              lastName: userInfo.lastName,
              username: userInfo.userName,
              email: userInfo.email,
              phone: userInfo.phone,
              password: userInfo.password,
              roles: roless,
              avatar: image,
              uuid: userInfo.uuid,
            },
            {
              onSuccess: (data) => {
                this.props.addRolesToUser(data.uuid, userInfo.rolesItems, {
                  onSuccess: () => {
                    this.props.getUsers();
                    this.props.changeModalValue('modalInf', {
                      firstName: '',
                      lastName: '',
                      userName: '',
                      email: '',
                      password: '',
                      phone: '',
                      avatar: '',
                      avatarFile: null,
                      rolesItems: [],
                    });
                  },
                });
              },
            }
          );
          this.onClose();
        },
      });
    } else if (
      userInfo.firstName !== '' &&
      userInfo.lastName !== '' &&
      userInfo.userName !== '' &&
      userInfo.avatar !== '' &&
      userInfo.email !== '' &&
      userInfo.phone !== '' &&
      userInfo.password !== '' &&
      roless !== []
    ) {
      this.props.updateMe(
        {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          username: userInfo.userName,
          email: userInfo.email,
          phone: userInfo.phone,
          password: userInfo.password,
          roles: roless,
          avatar: userInfo.image,
          uuid: userInfo.uuid,
        },
        {
          onSuccess: (data) => {
            this.props.getUsers();
            this.props.addRolesToUser(data.uuid, userInfo.rolesItems, {
              onSuccess: () => {
                this.props.changeModalValue('modalInf', {
                  firstName: '',
                  lastName: '',
                  userName: '',
                  email: '',
                  password: '',
                  phone: '',
                  avatar: '',
                  avatarFile: null,
                  rolesItems: [],
                });
              },
            });
          },
        }
      );
      this.onClose();
    }
  };
  onClose = () => {
    this.setState({
      modalEdit: false,
      modalChangePass: false,
      modalChangeEmail: false,
      modalConfirmEmail: false,
      modalError: false,
      newPass: '',
      oldPass: '',
      newInput: 'password',
      oldInput: 'password',
      invalidPass: false,
    });
    this.props.changeModalValue('modalInf', {
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
      phone: '',
      avatar: '',
      avatarFile: null,
      rolesItems: [],
    });
  };
  editUser = () => {
    this.props.changeModalValue('modalInf', {
      firstName: this.props.me.firstName,
      lastName: this.props.me.lastName,
      userName: this.props.me.username,
      email: this.props.me.email,
      password: this.props.me.password,
      phone: this.props.me.phone,
      uuid: this.props.me.uuid,
      image: this.props.me.avatar,
      avatar: this.props.me.avatar?.url ? this.props.me.avatar?.url : '',
      rolesItems: this.props.me.roles?.map((role) => role.uuid),
    });
    this.setState({ modalEdit: true });
  };

  changePass() {
    this.state.newPass !== '' &&
      this.state.oldPass !== '' &&
      this.props.changePassword(
        {
          oldPassword: this.state.oldPass,
          newPassword: this.state.newPass,
        },
        {
          onSuccess: () => this.onClose(),
          onError: (data) => {
            data.code === 404 &&
              this.setState({ invalidPass: data.message ? data.message[0] : 'Something went wrong' });
          },
        }
      );
  }

  updateEmail() {
    const userInfo = this.props.modalInf;
    this.props.updateEmail(
      {
        email: userInfo.email,
        password: userInfo.password,
      },
      {
        onSuccess: () => this.props.getMe(),
        onError: (data) => {
          this.setState({ modalError: data.message ? data.message : 'Something went wrong' });
        },
      }
    );
    this.onClose();
  }

  render() {
    const { signOut, me, isLoading, modalInf } = this.props;
    return (
      <div className="user">
        <div className="user__container">
          <div className="user__data">
            <button className="btn btn--color-secondary user__btn-edit">
              {!isLoading && (
                <FontAwesomeIcon
                  icon={faPen}
                  className="user__btn-edit-icon"
                  onClick={() => this.editUser()}
                />
              )}
            </button>
            {me.avatar ? (
              <Avatar view={avatarViews.ROUND} width={90} avatarUrl={me.avatar.url} />
            ) : (
              <FontAwesomeIcon icon={faUser} size="lg" className="user__profile__icon" />
            )}
            <p className="user__name">
              {(me.firstName ? me.firstName : ' ') + ' ' + (me.lastName ? me.lastName : ' ')}
            </p>
            <p className="user__name">{me.username}</p>
            <p className="user__email">{me.email}</p>
            <p className="user__phone">{me.phone ? me.phone : '-'}</p>
          </div>
          {this.state.modalEdit && (
            <ModalEdit
              loading={false}
              isOpen={this.state.modalEdit}
              params={modalInf}
              changeValue={(name, value) => this.props.changeUserValue(name, value)}
              onSubmit={() => this.updateUser()}
              onClose={() => this.onClose()}
              className="modal-users"
            />
          )}
          {this.state.modalChangeEmail && (
            <UpdateEmail
              modal={this.state.modalChangeEmail}
              onClose={() => this.onClose()}
              title="updateEmail"
              info={modalInf}
              onChange={(name, value) => this.props.changeUserValue(name, value)}
              onSubmit={() => this.updateEmail()}
              onClose={() => this.onClose()}
            />
          )}
          {this.state.modalConfirmEmail && (
            <ConfirmEmail
              modal={this.state.modalConfirmEmail}
              onClose={() => this.onClose()}
              title="updateEmail"
              info={modalInf}
              onSubmit={(code) => this.props.confirmEmail({ code: code })}
              onClose={() => this.onClose()}
            />
          )}
          {this.state.modalChangePass && (
            <Modal isOpen={this.state.modalChangePass} fade>
              <ModalHeader toggle={() => this.onClose()}>{i18n.t('usersModals:changePassword')}</ModalHeader>
              <ModalBody>
                <div className="form-row">
                  <span className="mb-2 ml-3">Old Password</span>
                  <FormInput
                    name="oldPassword"
                    label="Password"
                    type={this.state.oldInput}
                    label={i18n.t('fields:password')}
                    value={this.state.oldPass}
                    className={`form-item form-item--one mb-10`}
                    onChange={(name, value) => {
                      this.setState({ oldPass: value });
                    }}
                    changeType={() =>
                      this.setState((prev) => ({
                        oldInput: prev.oldInput === 'password' ? 'text' : 'password',
                      }))
                    }
                  />
                  {this.state.invalidPass && (
                    <div className="invalid-feedback ml-2 mb-2" style={{ marginTop: '-8px' }}>
                      {this.state.invalidPass}
                    </div>
                  )}
                  <span className="mb-2 ml-3">New Password</span>
                  <FormInput
                    name="newPassword"
                    label="Password"
                    type={this.state.newInput}
                    label={i18n.t('fields:password')}
                    value={this.state.newPass}
                    className="form-item form-item--one mb-10"
                    onChange={(name, value) => this.setState({ newPass: value })}
                    changeType={() =>
                      this.setState((prev) => ({
                        newInput: prev.newInput === 'password' ? 'text' : 'password',
                      }))
                    }
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <button type="button" className="btn btn--dark" onClick={() => this.onClose()}>
                  {i18n.t('buttons:cancel')}
                </button>
                <button className="btn btn--secondary" type="submit" onClick={() => this.changePass()}>
                  {i18n.t('buttons:save')}
                </button>
              </ModalFooter>
            </Modal>
          )}
          {this.state.modalError && (
            <InformationModal
              isOpen={this.state.modalError}
              header={'Error'}
              errorMsg={this.state.modalError}
              onClose={() => this.onClose()}
            />
          )}
          <div className="user__buttons">
            <button
              className="btn btn--block btn--color-secondary btn--larger mb-10"
              onClick={() => this.setState({ modalChangePass: true })}
            >
              {i18n.t('buttons:changePassword')}
            </button>
            <button
              className="btn btn--block btn--color-secondary btn--larger mb-10"
              onClick={() => this.setState({ modalChangeEmail: true })}
            >
              {i18n.t('buttons:updateEmail')}
            </button>
            <button
              className="btn btn--block btn--color-secondary btn--larger mb-10"
              onClick={() => this.setState({ modalConfirmEmail: true })}
            >
              {i18n.t('buttons:confirmEmail')}
            </button>
            <button
              className="btn btn--block btn--color-secondary btn--larger mb-10"
              onClick={() => this.props.resendEmail()}
            >
              {i18n.t('buttons:resendEmail')}
            </button>
            <button className="btn btn--block btn--color-error btn--larger" onClick={signOut}>
              {i18n.t('buttons:signOut')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

UserProfile.propTypes = {
  UserProfile: PropTypes.array,
  dispatch: PropTypes.func,
};

const mapStateToProps = ({ users, roles }) => {
  const { me, isLoading, modalInf } = users;
  return {
    me,
    modalInf,
    isLoading,
    roles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(actions.signOut()),
  getMe: (actions) => dispatch(userActions.getMe(actions)),
  getRoles: (params, actions) => dispatch(roleActions.getRoles(params, actions)),
  changeUserValue: (key, value) => dispatch(userActions.changeUserValue(key, value)),
  changeModalValue: (key, value) => dispatch(userActions.changeModalValue(key, value)),
  uploadMedia: (params, actions) => dispatch(userActions.uploadMedia(params, actions)),
  updateMe: (params, actions) => dispatch(userActions.updateMe(params, actions)),
  changePassword: (params, actions) => dispatch(userActions.changePassword(params, actions)),
  updateEmail: (params, actions) => dispatch(userActions.updateEmail(params, actions)),
  confirmEmail: (params, actions) => dispatch(userActions.confirmEmail(params, actions)),
  resendEmail: (actions) => dispatch(userActions.resendEmail(actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
