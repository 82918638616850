import React, { useState } from 'react';
import { CustomInput } from 'reactstrap';
import i18n from '../../i18n';

const SwitchJSONEditors = ({ id, children }) => {
  const [isPlaintJSON, setPlaintJSON] = useState(false);

  return (
    <>
      <div className="form-switch-item mb-14">
        <CustomInput
          type="switch"
          checked={isPlaintJSON}
          onChange={(e) => {
            setPlaintJSON(e.target.checked);
          }}
          id={id}
          name="PlaintJSON"
          className="custom-switch--small"
        />
        <p className="form-switch-label">{i18n.t('workflowModals:labelPlainJSON')}</p>
      </div>

      {isPlaintJSON ? children[1] : children[0]}
    </>
  );
};

export default SwitchJSONEditors;
