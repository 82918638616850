export const GET_EQUIPMENTS = 'GET_EQUIPMENTS';
export const GET_EQUIPMENTS_RESULT = 'GET_EQUIPMENTS_RESULT';
export const GET_EQUIPMENTS_ERROR = 'GET_EQUIPMENTS_ERROR';

export const GET_EQUIPMENT = 'GET_EQUIPMENT';

export const ADD_EQUIPMENT = 'ADD_EQUIPMENT';
export const ADD_EQUIPMENT_SUCCESS = 'ADD_EQUIPMENT_SUCCESS';
export const ADD_EQUIPMENT_ERROR = 'ADD_EQUIPMENT_ERROR';

export const UPDATE_EQUIPMENT = 'UPDATE_EQUIPMENT';
export const UPDATE_EQUIPMENT_SUCCESS = 'UPDATE_EQUIPMENT_SUCCESS';
export const UPDATE_EQUIPMENT_ERROR = 'UPDATE_EQUIPMENT_ERROR';

export const DELETE_EQUIPMENT = 'DELETE_EQUIPMENT';
export const DELETE_EQUIPMENT_SUCCESS = 'DELETE_EQUIPMENT_SUCCESS';
export const DELETE_EQUIPMENT_ERROR = 'DELETE_EQUIPMENT_ERROR';
