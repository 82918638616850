import React, { Component, Fragment } from 'react';
// libs
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import Media from 'react-media';
import moment from 'moment';
// import { PopoverHeader, PopoverBody, Popover } from 'reactstrap';
// state
import * as actions from '../../actions/app.actions';
import * as playerActions from '../../actions/player.actions';
import * as userActions from '../../actions/users.actions';
import * as rolesActions from '../../actions/roles.actions';

import i18n from '../../i18n';

// icons, images
import {
  faUserSecret,
  faUsers,
  faUsersCog,
  faUserFriends,
  faBell,
  faCopy,
  faCogs,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/images/logo.svg';
import { ReactComponent as DasboardIcon } from '../../assets/images/dashboardIcon.svg';
import { ReactComponent as TrainingMarkupIcon } from '../../assets/images/trainingMarkupIcon.svg';
// import closeImg from '../../assets/images/close-green.svg';

// constants
import {
  WATCH,
  DASHBOARD,
  TRAININGMARKUP,
  SETTINGS,
  SUBJECTS,
  USERS,
  EQUIPMENTS,
  MEMBERS,
  WORKFLOW,
  LIMITS,
  CAMERAS,
  ROLES,
  PERSONS,
  CARS,
  LOCATIONS,
  EQUIPMENTTYPES,
  ALERTMANAGEMENT,
  ALERTROUTES,
  REPORT,
  ANALYTICAL_REPORTS,
  ACCOUNTS,
  ACCOUNTLIMITS,
  ACCOUNTUSERS,
} from '../../constants/routes.constants';
import { avatarViews } from '../../constants/userConstants';
import { MEDIA_QUERY_SM } from '../../constants/app.constants';

// components
import UserRouter from '../../routes/user.routes';
import DropdownMenuItem from '../../components/DropdownMenuItem';
import Avatar from '../../components/Avatar';
import { pageAuthorities } from '../../constants/roles.constants';

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowMobileMenu: false,
      isOpen: false,
      roles: [],
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.props.getMe();
  }

  exit = () => {
    this.props.signOut();
  };

  showMobileMenu = (e) => {
    const closeMenu = e.target.getAttribute('href') || e.target.getAttribute('fill');
    const toggle = e.target.getAttribute('data-btn');
    if (toggle) {
      this.setState((prevState) => ({ isShowMobileMenu: !prevState.isShowMobileMenu }));
    } else if (closeMenu) {
      this.setState({ isShowMobileMenu: false });
    }
  };

  toggle() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  handleMoveToCamera = (alert) => {
    const { pathname } = this.props.router.location;
    if (`${WATCH}/${alert.cameraUuid}` === pathname) {
      this.props.setCurrent(alert.timestamp);
    } else {
      this.props.dispatch(push(`/replace`));
      setTimeout(() => {
        this.props.dispatch(
          push({
            pathname: `${WATCH}/${alert.cameraUuid}`,
            state: { setCurrent: moment(alert.timestamp).toDate(), alert },
          })
        );
      }, 0);
    }

    this.toggle();
  };

  render() {
    const { isShowMobileMenu, isOpen } = this.state;
    const { me } = this.props.users;

    const authorities = me && me.authorities ? me.authorities : [];

    return (
      <Fragment>
        <div className="page-wrapper">
          <header className={cx('header', { 'menu-open': isShowMobileMenu })} onClick={this.showMobileMenu}>
            <Link to="/" className="logo">
              <img src={logo} alt="logo" />
            </Link>
            <nav className="nav header__nav">
              {authorities.some((auth) =>
                [...pageAuthorities['Dashboard'], ...pageAuthorities['CameraView']].includes(auth)
              ) && (
                <NavLink
                  to={DASHBOARD}
                  activeClassName="header__nav-link--active"
                  className="header__nav-link"
                >
                  <DasboardIcon /> {i18n.t('nav:dashboard')}
                </NavLink>
              )}
              {authorities.some((auth) =>
                [...pageAuthorities['WorkflowList'], ...pageAuthorities['CamerasList']].includes(auth)
              ) && (
                <DropdownMenuItem
                  title={i18n.t('nav:environmentDesigner')}
                  icon={faCogs}
                  menuItems={[
                    authorities.some((auth) => pageAuthorities['WorkflowList'].includes(auth)) && {
                      to: WORKFLOW,
                      title: i18n.t('nav:workflow'),
                    },
                    authorities.some((auth) => pageAuthorities['CamerasList'].includes(auth)) && {
                      to: CAMERAS,
                      title: i18n.t('nav:cameras'),
                    },
                  ].filter(Boolean)}
                />
              )}
              {authorities.some((auth) => pageAuthorities['TrainingMarkup'].includes(auth)) && (
                <NavLink
                  to={TRAININGMARKUP}
                  activeClassName="header__nav-link--active"
                  className="header__nav-link"
                >
                  <TrainingMarkupIcon /> {i18n.t('nav:trainingMarkup')}
                </NavLink>
              )}
              <DropdownMenuItem
                title={i18n.t('nav:administration')}
                icon={faUserSecret}
                menuItems={[
                  authorities.some((auth) => pageAuthorities['AccountsList'].includes(auth)) && {
                    to: ACCOUNTS,
                    title: i18n.t('nav:accounts'),
                  },
                  authorities.some((auth) => pageAuthorities['AccountUsers'].includes(auth)) && {
                    to: ACCOUNTUSERS,
                    title: i18n.t('nav:accountUsers'),
                  },
                  authorities.some((auth) => pageAuthorities['AccountLimits'].includes(auth)) && {
                    to: ACCOUNTLIMITS,
                    title: i18n.t('nav:accountLimits'),
                  },
                  authorities.some((auth) => pageAuthorities['RolesList'].includes(auth)) && {
                    to: ROLES,
                    title: i18n.t('nav:roles'),
                  },
                  authorities.some((auth) => pageAuthorities['UsersList'].includes(auth)) && {
                    to: USERS,
                    title: i18n.t('nav:users'),
                  },
                  authorities.some((auth) => pageAuthorities['Subjects'].includes(auth)) && {
                    to: PERSONS,
                    title: i18n.t('nav:persons'),
                  },
                  authorities.some((auth) => pageAuthorities['Subjects'].includes(auth)) && {
                    to: CARS,
                    title: i18n.t('nav:cars'),
                  },
                  authorities.some((auth) => pageAuthorities['LocationsList'].includes(auth)) && {
                    to: LOCATIONS,
                    title: i18n.t('nav:locations'),
                  },
                  authorities.some((auth) => pageAuthorities['EquipmentList'].includes(auth)) && {
                    to: EQUIPMENTTYPES,
                    title: i18n.t('nav:equipmentTypes'),
                  },
                  authorities.some((auth) => pageAuthorities['EquipmentList'].includes(auth)) && {
                    to: EQUIPMENTS,
                    title: i18n.t('nav:equipments'),
                  },
                  { to: LIMITS, title: i18n.t('nav:limits') },
                ].filter(Boolean)}
              />
              {authorities.some((auth) => pageAuthorities['AlertsList'].includes(auth)) && (
                <DropdownMenuItem
                  title={i18n.t('nav:alerts')}
                  icon={faBell}
                  menuItems={[
                    { to: ALERTMANAGEMENT, title: i18n.t('nav:alerts') },
                    { to: ALERTROUTES, title: i18n.t('nav:alertRoutes') },
                  ]}
                />
              )}
              <DropdownMenuItem
                title={i18n.t('nav:reporting')}
                icon={faCopy}
                menuItems={[
                  { to: `${REPORT}/1`, title: i18n.t('reporting:maintenanceReport') },
                  { to: ANALYTICAL_REPORTS, title: i18n.t('reporting:analyticalReport') },
                ]}
              />
              <Media query={MEDIA_QUERY_SM}>
                {(matches) =>
                  !matches && (
                    <Link to={`/user/me`}>
                      <div
                        className="header__user-avatar"
                        style={{ backgroundImage: `url(${me.avatar ? me.avatar.url : ''})` }}
                      />
                    </Link>
                  )
                }
              </Media>
            </nav>
            <div className="header__nav-btn-grop">
              <Media query={MEDIA_QUERY_SM}>
                {(matches) =>
                  matches ? (
                    <Link to={`/user/me`}>
                      <Avatar
                        view={avatarViews.ROUND}
                        width={32}
                        avatarUrl={me && me.avatar ? me.avatar.url : ''}
                      />
                    </Link>
                  ) : (
                    <button type="button" className="mobile-btn" data-btn="mobileBtn">
                      <span data-btn="mobileBtn" />
                    </button>
                  )
                }
              </Media>
            </div>
          </header>
          {me && (
            <div className="page-content">
              <UserRouter user={me} />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

User.propTypes = {
  signOut: PropTypes.func,
  dispatch: PropTypes.func,
};

const mapStateToProps = ({ notifications, router, users }) => {
  return { notifications, router, users };
};

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(actions.signOut()),
  setCurrent: (time) => dispatch(playerActions.setCurrent(time)),
  getMe: (actions) => dispatch(userActions.getMe(actions)),
  getRole: (id, actions) => dispatch(rolesActions.getRole(id, actions)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
