import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormInput, FormSelect } from '../../components/Form';
import i18n from '../../i18n';

const AccountModal = ({ modal, add, onClose, title, info, onChange, onSubmit }) => {
  const [inputType, changeInputType] = useState('password');
  return (
    <Modal isOpen={modal} fade>
      <ModalHeader toggle={() => onClose()}>{i18n.t(`accountsModals:${title}`)}</ModalHeader>
      <ModalBody>
        <FormInput
          name="name"
          type="text"
          label={i18n.t('fields:name')}
          value={info.name}
          className="form-item form-item--one mb-10"
          onChange={(name, value) => {
            onChange(name, value);
          }}
          errorMessage={false}
        />
        {add && (
          <>
            <FormInput
              name="userName"
              type="text"
              label={i18n.t('fields:username')}
              value={info.userName}
              className="form-item form-item--one mb-10"
              onChange={(name, value) => {
                onChange(name, value);
              }}
              errorMessage={false}
            />
            <FormInput
              name="password"
              type={inputType}
              label={i18n.t('fields:password')}
              value={info.password}
              className="form-item form-item--one mb-10"
              onChange={(name, value) => {
                onChange(name, value);
              }}
              changeType={() => changeInputType(inputType === 'password' ? 'text' : 'password')}
              errorMessage={false}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn--dark" onClick={() => onClose()}>
          {i18n.t('buttons:cancel')}
        </button>
        <button
          className="btn btn--secondary"
          type="submit"
          disabled={info.name === ''}
          onClick={() => onSubmit()}
        >
          {i18n.t('buttons:save')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default AccountModal;
