import * as ActionTypes from '../constants/models.constants';

export const getModels = (params, actions) => ({
  type: ActionTypes.GET_MODELS,
  payload: params,
  actions,
});

export const getModel = (uuid, actions) => ({
  type: ActionTypes.GET_MODEL,
  payload: uuid,
  actions,
});

export const addModel = (params, actions) => ({
  type: ActionTypes.ADD_MODEL,
  payload: params,
  actions,
});

export const updateModel = (uuid, params, actions) => ({
  type: ActionTypes.UPDATE_MODEL,
  payload: { uuid, params },
  actions,
});

export const deleteModel = (uuid, actions) => ({
  type: ActionTypes.DELETE_MODEL,
  payload: uuid,
  actions,
});
