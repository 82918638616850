import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'animate.css';

import App from './app';
import configureStore, { history } from './config/store';
import FontAwersomeIcons from './utils/icons';
import * as serviceWorker from './serviceWorker';
import './styles/app.scss';
import './i18n';

FontAwersomeIcons();
export const store = configureStore();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('Service worker registration successful, scope is:', registration.scope);
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}

ReactDOM.render(
  <Provider store={store}>
    <App history={history} getState={store.getState} />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
