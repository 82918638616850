import { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormInput, FormSelect } from '../../../../components/Form';
import i18n from '../../../../i18n';

const ConfirmEmail = ({ modal, onClose, title, onSubmit }) => {
  const [code, changeCode] = useState('');
  return (
    <Modal isOpen={modal} fade>
      <ModalHeader toggle={() => onClose()}>{i18n.t(`accountsModals:${title}`)}</ModalHeader>
      <ModalBody>
        <FormInput
          name="code"
          type="text"
          label={i18n.t('fields:code')}
          value={code}
          className="form-item form-item--one mb-10"
          onChange={(name, value) => {
            changeCode(value);
          }}
          errorMessage={false}
        />
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn--dark" onClick={() => onClose()}>
          {i18n.t('buttons:cancel')}
        </button>
        <button
          className="btn btn--secondary"
          type="submit"
          disabled={code === ''}
          onClick={(code) => onSubmit(code)}
        >
          {i18n.t('buttons:save')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmEmail;
