import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Pagination = ({ size, page, total, onPage }) => {
  const max = size * (page + 1);
  const counting = () => {
    let res;
    if (page === 0 && max < total) {
      res = `1-${max}`;
    } else if (page > 0 && max < total) {
      res = `${size * page + 1}-${max}`;
    } else {
      res = `${size * page + 1}-${total}`;
    }
    return res;
  };

  return (
    <div className="pagination">
      <div className="pagination-size">
        {counting()} of {total}
      </div>
      <button className="btn btn--link" disabled={page === 0} onClick={() => onPage(page - 1)}>
        <FontAwesomeIcon icon="angle-left" />
      </button>
      <button className="btn btn--link" disabled={max >= total} onClick={() => onPage(page + 1)}>
        <FontAwesomeIcon icon="angle-right" />
      </button>
    </div>
  );
};

export default Pagination;
