import i18n from '../../../../i18n';

import BaseModal from './BaseModal';

class ModalEdit extends BaseModal {
  componentDidMount() {
    this.verifyStreamUrl(this.props.params.camera.streamUrl);
  }
}

ModalEdit.defaultProps = {
  ...BaseModal.defaultProps,
  header: i18n.t('cameraModals:editTitle'),
};

export default ModalEdit;
