import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import DatetimeRangePicker from 'react-datetime-range-picker';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import i18n from '../../i18n';

import Table from '../../components/Table';
import ReportFilter from '../../components/Report/ReportFilter';

import * as reportActions from '../../actions/reports.action';
import * as workflowActions from '../../actions/workflows.action';
import * as cameraActions from '../../actions/cameras.actions';

import { technicalReports } from '../../mocks/techReports';

const ReportsList = ({
  maintenanceFilters,
  changeValue,
  changeMaintenanceFilter,
  resetMaintenanceFilters,
  getWorkflows,
  getCameras,
}) => {
  const [workflowOptions, setWorkflowOptions] = useState([]);
  const [cameraOptions, setCameraOptions] = useState([]);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [reportRows, setReportRows] = useState([]);
  const [runningStatuses, setRunningStatuses] = useState({});

  const pickerRef = useRef(null);

  useEffect(() => {
    getWorkflows(
      {
        page: 0,
        size: 100,
      },
      {
        onSuccess: (workflows) => {
          setWorkflowOptions([
            {
              label: 'All',
              value: 'select',
            },
            ...workflows.items.map((workflow) => ({
              label: workflow.name,
              value: workflow.uuid,
              type: workflow.processingConfig.type,
              cameras: workflow.cameras.map((camera) => ({ label: camera.name, value: camera.uuid })),
            })),
          ]);
          parseRunningStatuses(workflows);
        },
      }
    );
    getCameras(
      {
        page: 0,
        size: 100,
      },
      {
        onSuccess: (cameras) =>
          setCameraOptions([
            {
              label: 'All',
              value: 'select',
            },
            ...cameras.items.map((camera) => ({
              label: camera.name,
              value: camera.uuid,
            })),
          ]),
      }
    );

    return () => {
      resetMaintenanceFilters();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(runningStatuses).length) {
      const updatedReports = technicalReports.map((report) => {
        const updatedReport = { ...report };
        updatedReport.workflow.status = runningStatuses[report.workflow.uuid].status;
        updatedReport.cameras = updatedReport.cameras.map((camera) => {
          if (runningStatuses[report.workflow.uuid].cameras[camera.uuid] === 'RUNNING') {
            return {
              ...camera,
              status:
                runningStatuses[report.workflow.uuid].cameras[camera.uuid] === 'RUNNING'
                  ? 'Active'
                  : 'Inactive',
            };
          } else {
            return {
              ...camera,
              status:
                runningStatuses[report.workflow.uuid].cameras[camera.uuid] === 'RUNNING'
                  ? 'Active'
                  : 'Inactive',
            };
          }
        });
        return updatedReport;
      });
      setReportRows(updatedReports);
    }
  }, [runningStatuses]);

  const parseRunningStatuses = (workflows) => {
    const statuses = {};
    workflows.items.map((workflow) => {
      statuses[workflow.uuid] = {
        status: workflow.runningStatus.processingStatus === 'RUNNING' ? 'Active' : 'Inactive',
        cameras: workflow.runningStatus.camerasStatus.reduce(
          (acc, curr) => ({ ...acc, [curr.cameraUuid]: curr.status }),
          {}
        ),
      };
    });
    setRunningStatuses(statuses);
  };

  const createRows = (rows) => {
    return rows.map((item) => ({
      id: item.workflow.uuid,
      cells: [
        {
          label: item.workflow.name,
          cellComponent: (
            <div>
              <h3>{item.workflow.name}</h3>
              <p style={{ color: item.workflow.status === 'Active' ? 'green' : 'red' }}>
                {item.workflow.status}
              </p>
              {item.workflow.status === 'Active' ? (
                <p>Started: {moment(item.workflow.lastStart).format('MMMM Do YYYY, h:mm:ss a')}</p>
              ) : (
                <p>Last start: {moment(item.workflow.lastStart).format('MMMM Do YYYY, h:mm:ss a')}</p>
              )}

              <p>Duration: {moment.duration(item.workflow.duration, 'milliseconds').format()}</p>
            </div>
          ),
        },
        {
          label: item.camera,
          cellComponent: (
            <div>
              <h2 style={{ textDecoration: 'underline' }}>
                Total: Instance - {item.workflow.instance}, FPS - {item.workflow.fps}
              </h2>
              <ul>
                {item.cameras.map((camera) => (
                  <li style={{ marginBottom: '5px' }} key={camera.uuid}>
                    <p>
                      {camera.name} ({camera.fps} FPS) -{' '}
                      <span style={{ color: camera.status === 'Active' ? 'green' : 'red' }}>
                        {camera.status}
                      </span>
                    </p>
                    <div style={{ marginLeft: '20px' }}>
                      <p>Start Date: {moment(camera.startDate).format('MMMM Do YYYY, h:mm:ss a')}</p>
                      {camera.status !== 'Active' && (
                        <p>Stop Date: {moment(camera.endDate).format('MMMM Do YYYY, h:mm:ss a')}</p>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ),
        },
      ],
    }));
  };

  const headerOptions = [
    {
      label: 'Workflow',
    },
    {
      label: 'Cameras',
    },
  ];

  const filters = (
    <div className="page__filters">
      <ReportFilter
        title="Workflow"
        options={workflowOptions}
        selected={maintenanceFilters.workflow}
        onClick={(value) => changeMaintenanceFilter('workflow', value)}
      />

      <ReportFilter
        title="Camera"
        options={cameraOptions}
        selected={maintenanceFilters.camera}
        onClick={(value) => changeMaintenanceFilter('camera', value)}
      />

      <div className="datetime-range-filter__wrapper" ref={pickerRef}>
        <DatetimeRangePicker
          className="datetime-range-filter"
          startDate={maintenanceFilters.fromDate ? maintenanceFilters.fromDate : new Date()}
          endDate={maintenanceFilters.toDate ? maintenanceFilters.toDate : new Date()}
          onFocus={() => {
            setPickerOpen(true);
          }}
          onBlur={() => {
            setPickerOpen(false);
          }}
          onStartDateChange={(startDate) => {
            changeMaintenanceFilter('fromDate', startDate);
          }}
          onEndDateChange={(endDate) => {
            changeMaintenanceFilter('toDate', endDate);
          }}
        />
        <FontAwesomeIcon
          icon={faCalendarAlt}
          className={`datetime-range-icon_from ${!pickerOpen ? 'holey' : ''}`}
          onClick={() => {
            pickerRef.current.onclick();
          }}
        />
        <FontAwesomeIcon
          icon={faCalendarAlt}
          className={`datetime-range-icon_to ${!pickerOpen ? 'holey' : ''}`}
          onClick={() => {
            pickerRef.current.onclick();
          }}
        />
      </div>
    </div>
  );

  return (
    <div className="report-page">
      <Table
        rows={createRows(
          reportRows.filter((report) => {
            if (maintenanceFilters.workflow.value !== 'select') {
              if (report.workflow.uuid !== maintenanceFilters.workflow.value) return false;
            }
            if (maintenanceFilters.camera.value !== 'select') {
              if (!report.cameras.some((camera) => camera.uuid === maintenanceFilters.camera.value))
                return false;
            }
            return true;
          })
        )}
        headerOptions={headerOptions}
        title={i18n.t('reporting:maintenanceReport')}
        totalRows={
          reportRows.filter((report) => {
            if (maintenanceFilters.workflow.value !== 'select') {
              if (report.workflow.uuid !== maintenanceFilters.workflow.value) return false;
            }
            if (maintenanceFilters.camera.value !== 'select') {
              if (!report.cameras.some((camera) => camera.uuid === maintenanceFilters.camera.value))
                return false;
            }
            return true;
          }).length
        }
        filtersElements={filters}
        isLoading={false}
        hideAddButton
      />
    </div>
  );
};

const mapStateToProps = ({ reports }) => {
  const { maintenanceFilters } = reports;
  return {
    maintenanceFilters,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeValue: (key, value) => dispatch(reportActions.changeValue(key, value)),
  changeMaintenanceFilter: (key, value) => dispatch(reportActions.changeMaintenanceFilter(key, value)),
  resetMaintenanceFilters: () => dispatch(reportActions.resetMaintenanceFilters()),
  getWorkflows: (params, actions) => dispatch(workflowActions.getWorkflows(params, actions)),
  getCameras: (params, actions) => dispatch(cameraActions.getCameras(params, actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsList);
