import * as ActionTypes from '../constants/subjects.constants';
import * as EventTypes from '../constants/event.constants';

const initialState = {
  list: {
    items: [],
    total: 0,
  },
  query: {
    types: [EventTypes.ALERT_OCCURRED],
    size: 10,
    page: 0,
  },
  isLoading: false,
};

export const subjectEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SUBJECT_EVENTS:
    case ActionTypes.GET_SUBJECT_EVENTS_NEXT_PAGE:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_SUBJECT_EVENTS_RESULT: {
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items, total },
        query: { ...state.query, size, page },
        isLoading: false,
      };
    }

    case ActionTypes.GET_SUBJECT_EVENTS_NEXT_PAGE_RESULT: {
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items: [...state.list.items, ...items], total },
        query: { ...state.query, size, page },
        isLoading: false,
      };
    }

    case ActionTypes.GET_SUBJECT_EVENTS_ERROR:
    case ActionTypes.GET_SUBJECT_EVENTS_NEXT_PAGE_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.RESET_SUBJECT_EVENTS_STATE:
      return initialState;

    default:
      return state;
  }
};
