import * as ActionTypes from '../constants/workflows.constants';

export const getWorkflows = (params, actions) => ({
  type: ActionTypes.GET_WORKFLOWS,
  payload: params,
  actions,
});

export const getWorkflow = (uuid, actions) => ({
  type: ActionTypes.GET_WORKFLOW,
  payload: { uuid },
  actions,
});

export const getWorkflowsUsingCameras = (uuid, actions) => ({
  type: ActionTypes.GET_WORKFLOWS_USING_CAMERAS,
  payload: uuid,
  actions,
});

export const addWorkflow = (params, actions) => ({
  type: ActionTypes.ADD_WORKFLOW,
  payload: params,
  actions,
});

export const updateWorkflow = (uuid, params, actions) => ({
  type: ActionTypes.UPDATE_WORKFLOW,
  payload: { uuid, params },
  actions,
});

export const deleteWorkflow = (uuid, actions) => ({
  type: ActionTypes.DELETE_WORKFLOW,
  payload: uuid,
  actions,
});

export const addProcessingToWorkflow = (uuid, params, actions) => ({
  type: ActionTypes.ADD_PROCESSING_TO_WORKFLOW,
  payload: { uuid, params },
  actions,
});

export const getWorkflowProcessing = (uuid, actions) => ({
  type: ActionTypes.GET_WORKFLOW_PROCESSING,
  payload: { uuid },
  actions,
});

export const addAnalyticsToWorkflow = (uuid, params, actions) => ({
  type: ActionTypes.ADD_ANALYTICS_TO_WORKFLOW,
  payload: { uuid, params },
  actions,
});

export const getWorkflowAnalytics = (uuid, actions) => ({
  type: ActionTypes.GET_WORKFLOW_ANALYTICS,
  payload: { uuid },
  actions,
});

export const deleteWorkflowAnalytic = (workflowUuid, analyticsUuid, actions) => ({
  type: ActionTypes.DELETE_WORKFLOW_ANALYTICS,
  payload: { workflowUuid, analyticsUuid },
  actions,
});

export const addTriggersToWorkflow = (uuid, params, actions) => ({
  type: ActionTypes.ADD_TRIGGERS_TO_WORKFLOW,
  payload: { uuid, params },
  actions,
});

export const getWorkflowTriggers = (uuid, params, actions) => ({
  type: ActionTypes.GET_WORKFLOW_TRIGGERS,
  payload: { uuid, params },
  actions,
});
export const getWorkflowCameras = (uuid, params, actions) => ({
  type: ActionTypes.GET_WORKFLOW_CAMERAS,
  payload: { uuid, params },
  actions,
});

export const addCamerasToWorkflow = (uuid, params, actions) => ({
  type: ActionTypes.ADD_CAMERAS_TO_WORKFLOW,
  payload: { uuid, params },
  actions,
});

export const deleteWorkflowCamera = (workflowUuid, cameraUuid, actions) => ({
  type: ActionTypes.DELETE_WORKFLOW_CAMERA,
  payload: { workflowUuid, cameraUuid },
  actions,
});

export const setZonesToWorkflowCamera = (workflowUuid, cameraUuid, params, actions) => ({
  type: ActionTypes.SET_ZONES_TO_WORKFLOW_CAMERA,
  payload: { workflowUuid, cameraUuid, params },
  actions,
});

export const getZonesForWorkflowCamera = (workflowUuid, cameraUuid, actions) => ({
  type: ActionTypes.GET_ZONES_FOR_WORKFLOW_CAMERA,
  payload: { workflowUuid, cameraUuid },
  actions,
});

export const getAnalyticsForWorkflowCamera = (workflowUuid, cameraUuid, actions) => ({
  type: ActionTypes.GET_ANALYTICS_FOR_WORKFLOW_CAMERA,
  payload: { workflowUuid, cameraUuid },
  actions,
});

export const getTriggersForWorkflowCamera = (workflowUuid, cameraUuid, params, actions) => ({
  type: ActionTypes.GET_TRIGGERS_FOR_WORKFLOW_CAMERA,
  payload: { workflowUuid, cameraUuid, params },
  actions,
});

export const updateWorkflowCameraTrigger = (workflowUuid, cameraUuid, triggerUuid, params, actions) => ({
  type: ActionTypes.UPDATE_WORKFLOW_CAMERA_TRIGGER,
  payload: { workflowUuid, cameraUuid, triggerUuid, params },
  actions,
});

export const addZonesToWorkflowCamera = (workflowUuid, cameraUuid, params, actions) => ({
  type: ActionTypes.ADD_ZONES_FOR_WORKFLOW_CAMERA,
  payload: { workflowUuid, cameraUuid, params },
  actions,
});

export const deleteZoneFromWorkflowCamera = (workflowUuid, cameraUuid, zoneUuid, actions) => ({
  type: ActionTypes.DELETE_ZONE_FROM_WORKFLOW_CAMERA,
  payload: { workflowUuid, cameraUuid, zoneUuid },
  actions,
});

export const getProcessingConfigs = (type, actions) => ({
  type: ActionTypes.GET_PROCESSING_CONFIGS,
  payload: { type },
  actions,
});

export const getProcessingConfig = (uuid, actions) => ({
  type: ActionTypes.GET_PROCESSING_CONFIG,
  payload: { uuid },
  actions,
});

export const getAnalyticBaseConfig = (type, actions) => ({
  type: ActionTypes.GET_ANALYTIC_BASE_CONFIG,
  payload: { type },
  actions,
});

export const getAvailableAnalytics = (uuid, actions) => ({
  type: ActionTypes.GET_AVAILABLE_ANALYTICS,
  payload: { uuid },
  actions,
});

export const getAvailableTriggers = (uuid, actions) => ({
  type: ActionTypes.GET_AVAILABLE_TRIGGERS,
  payload: { uuid },
  actions,
});

export const startWorkflow = (uuid, actions) => ({
  type: ActionTypes.START_WORKFLOW,
  payload: { uuid },
  actions,
});

export const stopWorkflow = (uuid, actions) => ({
  type: ActionTypes.STOP_WORKFLOW,
  payload: { uuid },
  actions,
});

export const getExistingProcessingTypes = (actions) => ({
  type: ActionTypes.GET_EXISTING_PROCESSING_TYPES,
  actions,
});

export const changeWorkflowRunningStatus = (data) => ({
  type: ActionTypes.CHANGE_WORKFLOW_RUNNING_STATUS,
  payload: data,
});

export const changeCameraRunningStatus = (data) => ({
  type: ActionTypes.CHANGE_CAMERA_RUNNING_STATUS,
  payload: data,
});

export const changeProcessingRunningStatus = (data) => ({
  type: ActionTypes.CHANGE_PROCESSING_RUNNING_STATUS,
  payload: data,
});

export const changeAnalyticRunningStatus = (data) => ({
  type: ActionTypes.CHANGE_ANALYTIC_RUNNING_STATUS,
  payload: data,
});

export const changeTriggerRunningStatus = (data) => ({
  type: ActionTypes.CHANGE_TRIGGER_RUNNING_STATUS,
  payload: data,
});

export const changeWorkflowFilters = (key, value) => ({
  type: ActionTypes.CHANGE_WORKFLOW_FILTERS,
  payload: { key, value },
});

export const getWorkflowPresets = (params, actions) => ({
  type: ActionTypes.GET_WORKFLOW_PRESETS,
  payload: params,
  actions,
});

export const getWorkflowPreset = (uuid, actions) => ({
  type: ActionTypes.GET_WORKFLOW_PRESET,
  payload: uuid,
  actions,
});

export const addWorkflowPreset = (params, actions) => ({
  type: ActionTypes.ADD_WORKFLOW_PRESET,
  payload: params,
  actions,
});

export const updateWorkflowPreset = (uuid, params, actions) => ({
  type: ActionTypes.UPDATE_WORKFLOW_PRESET,
  payload: { uuid, params },
  actions,
});

export const deleteWorkflowPreset = (params, actions) => ({
  type: ActionTypes.DELETE_WORKFLOW_PRESET,
  payload: params,
  actions,
});
