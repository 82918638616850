import { takeEvery, all, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import * as action from '../actions/player.actions';
import * as ActionTypes from '../constants/player.constants';
import { getCameraEventsGroupedTimestamps, getCameraRecordings } from './cameras.sagas';
import { getNearestTimeStamp } from '../utils';
import moment from 'moment';

export const EVENTS_CACHE_TIME = 120000; //120000 == 2 min

export function* setStepForward({ payload }) {
  const player = yield select((s) => s.player);
  const { timestamps, current } = player.controls;
  const result = getNearestTimeStamp(current, timestamps.alerts, 'next');
  yield result && put(action.setCurrent(result));
}

export function* setStepBackward({ payload }) {
  const player = yield select((s) => s.player);
  const { timestamps, current } = player.controls;
  const result = getNearestTimeStamp(current, timestamps.alerts, 'back');
  yield result && put(action.setCurrent(result));
}

export function* setStepStart({ payload }) {
  const alerts = yield select((s) => s.player.controls.timestamps.alerts);
  yield put(action.setCurrent(alerts[0]));
}

export function* setStepEnd({ payload }) {
  const alerts = yield select((s) => s.player.controls.timestamps.alerts);
  yield put(action.setCurrent(alerts[alerts.length - 1]));
}

function* copyEventsToCache({ payload: time }) {
  const startTS = Number.isInteger(time) ? time : moment(time).valueOf();
  const endTS = startTS + EVENTS_CACHE_TIME;
  const eventsCache = yield select((state) => state.player.events.eventsCache);
  const events = yield select((state) => {
    return state.player.events.list.items.filter((event) => {
      return startTS <= event.timestamp && event.timestamp <= endTS;
    });
  });
  const alerts = yield select((state) => {
    return state.player.alerts.list.items.filter((alert) => {
      return startTS <= alert.timestamp && alert.timestamp <= endTS;
    });
  });
  const newEventsCache = events.concat(alerts);

  if (!(!newEventsCache.length && !eventsCache.length)) {
    eventsCache.forEach((event) => {
      if (newEventsCache.findIndex((newEvent) => newEvent.timestamp === event.timestamp) === -1) {
        newEventsCache.push(event);
      }
    });

    yield put(action.setEventsCache(_.orderBy(newEventsCache, ['timestamp'], ['asc'])));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.SET_RANGE_PLAYER, getCameraEventsGroupedTimestamps),
    takeEvery(ActionTypes.SET_RANGE_PLAYER, getCameraRecordings),
    takeEvery(ActionTypes.SET_STEP_FORWARD, setStepForward),
    takeEvery(ActionTypes.SET_STEP_BACKWARD, setStepBackward),
    takeEvery(ActionTypes.SET_STEP_START, setStepStart),
    takeEvery(ActionTypes.SET_STEP_END, setStepEnd),
    takeEvery(ActionTypes.SET_CURRENT_PLAYER, copyEventsToCache),
    takeEvery(ActionTypes.COPY_EVENTS_TO_CACHE, copyEventsToCache),
  ]);
}
