import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import cx from 'classnames';

const Filter = ({ title, options, className, onClick, activeValue = null }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [active, setActive] = useState('All');
  return (
    <Dropdown
      className={cx('filter-item', className ? className : '', { 'filter-item--active': isSelectOpen })}
      isOpen={isSelectOpen}
      toggle={() => setIsSelectOpen(!isSelectOpen)}
    >
      <DropdownToggle className="w-100">
        <p
          data-toggle="tooltip"
          data-placement="top"
          title={activeValue !== null ? activeValue : active}
          className="filter-item__title"
        >
          {activeValue !== null
            ? activeValue !== 'All'
              ? activeValue.substring(0, 20)
              : title
            : active !== 'All'
            ? active.substring(0, 20)
            : title}
        </p>
        <FontAwesomeIcon
          icon={isSelectOpen ? faChevronUp : faChevronDown}
          className="filter-item__chevron-down"
        />
      </DropdownToggle>
      <DropdownMenu container="body">
        <DropdownItem
          key="all"
          onClick={() => {
            setActive('All');
            onClick(title, 'All');
          }}
        >
          All
        </DropdownItem>
        {options?.map((item, index) => (
          <DropdownItem
            key={item.name ? item.name : index}
            onClick={() => {
              setActive(item.name ? item.name : item);
              onClick(title, item.value ? item.value : item);
            }}
            divider={item.divider}
          >
            {item.name ? item.name : item}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

Filter.defaultProps = {
  className: '',
};

Filter.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  menuAlignRight: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Filter;
