import { push } from 'connected-react-router';
import { takeEvery, call, put, all } from 'redux-saga/effects';
import Cookies from 'universal-cookie';

import * as ActionTypes from '../constants/app.constants';
import { signIn } from '../api/app';
import i18n from '../i18n';

const cookies = new Cookies();
export function* login({ payload, setError }) {
  try {
    const { username, password } = payload;
    if (username && password) {
      const response = yield call(signIn, payload);
      const { success, results } = response;
      if (success) {
        localStorage.setItem('token', results.accessToken);
        localStorage.setItem('accountUuid', results.accountUuid);
        yield put({ type: ActionTypes.LOGIN_USER_RESULT });
      }
    }
  } catch (error) {
    console.log(error);
    setError({ password: error.code === 404 ? i18n.t('signIn:loginError') : i18n.t('signIn:serverError') });
    yield put({ type: ActionTypes.LOGIN_USER_ERROR });
  }
}

export function* logout() {
  try {
    cookies.remove('token');
    localStorage.removeItem('token');
    localStorage.removeItem('accountUuid');
    yield put({ type: ActionTypes.SIGN_OUT_USER_RESULT });
    yield put(push('/'));
  } catch (error) {
    console.log(error);
    yield put({ type: ActionTypes.SIGN_OUT_USER_ERROR });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ActionTypes.LOGIN_USER, login), takeEvery(ActionTypes.SIGN_OUT_USER, logout)]);
}
