import * as actions from '../actions/notification.actions';
import storeService from './StoreService';
const CLOSE_DELAY = 15000;

class NotificationsService {

  add(notification) {
        storeService.dispatch(actions.add(notification));
  }

  remove(notificationId) {
    storeService.dispatch(actions.remove(notificationId));
  }

  disable(notificationId) {
    storeService.dispatch(actions.disable(notificationId));
  }
}

export default new NotificationsService();
