/* global DocumentTouch */

import queryString from 'query-string';
import DateTimeService from '../services/DateTimeService';
import moment from 'moment';

export const GTS = (d) => DateTimeService.getTimestamp(d);
export const queryStringify = (query) => queryString.stringify(query, { arrayFormat: 'comma' });
export const isArrayLength = (arr = []) => !!arr && !!arr.length;
export const isNotEmptyString = (str) => typeof str === 'string' && !!str.length;

export const duplicateVideo = (startDate, clipDuration, fullDuration, url, detectionsUrl) => {
  const recordings = [];
  let count = 0;
  let from = startDate;
  while (count * clipDuration < fullDuration) {
    recordings.unshift({ from, to: from + clipDuration, url, detectionsUrl });
    from += clipDuration;
    count += 1;
  }
  return recordings;
};

export const getNearestTimeStamp = (curr, arr, type = null) => {
  let res;
  let current = curr ? GTS(curr) : +moment().format('x');
  if (!type || !(type && /^(back|next)$/.test(type))) {
    console.error('func: getNearestTimeStamp - need setup type parameter or setup type (back || next)');
    return curr;
  }

  if (type === 'back') {
    res = arr.filter((x) => current > x).reverse()[0] || null;
  } else if (type === 'next') {
    res = arr.filter((x) => current < x)[0] || null;
  }
  return res;
};

export const isTouchDevice = () => {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  var mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};

export const base64FromString = (val) => `data:image/jpeg;base64,${val}`;

export const searchRecordingByTimestamp = (current, recordings) => {
  const timestamp = moment(current).valueOf();

  if (!recordings.length) {
    return null;
  }

  return (
    recordings.filter((x) => {
      const to = x.to ? x.to : moment().valueOf();
      if (x.from <= timestamp && timestamp <= to) {
        return x;
      }

      return null;
    })[0] || null
  );
};

export const getPlayTime = (curr, recording) => {
  if (!recording) {
    return null;
  }

  let time = 0;
  let currTimestamp = DateTimeService.getTimestamp(curr);
  time = currTimestamp - recording.from;

  return time / 1000;
};

console.unicorn = console.log.bind(console, '🦄');
console.pizza = console.log.bind(console, '🍕');
console.beer = console.log.bind(console, '🍺');
console.poo = console.log.bind(console, '💩');

export const spreadRange = (r = '') => {
  if (!r) return { t: 24, c: 'h' };
  return {
    t: parseInt(`${r}`),
    c: r[r.length - 1] !== 'm' ? 'h' : 'm',
  };
};

export const callWithVideoDuration = (url, callback) => {
  const video = document.createElement('video');
  video.src = url;
  video.oncanplay = () => {
    callback(url, video.duration);
  };
};
