export const CHANGE_VALUE = 'CHANGE_VALUE';
export const CHANGE_GENERAL_VALUE = 'CHANGE_GENERAL_VALUE';
export const SET_LOADING = 'SET_LOADING';

export const RESET_PROCESSING_TAB = 'RESET_PROCESSING_TAB';
export const CHANGE_PROCESSOR_CONFIG = 'CHANGE_PROCESSOR_CONFIG';
export const CHANGE_BATCH_SIZE = 'CHANGE_BATCH_SIZE';

export const ADD_ANALYTIC = 'ADD_ANALYTIC';
export const REMOVE_ANALYTIC = 'REMOVE_ANALYTIC';
export const CHANGE_ANALYTIC = 'CHANGE_ANALYTIC';

export const ADD_TRIGGER = 'ADD_TRIGGER';
export const REMOVE_TRIGGER = 'REMOVE_TRIGGER';
export const CHANGE_TRIGGER = 'CHANGE_TRIGGER';

export const ADD_CAMERA = 'ADD_CAMERA';
export const REMOVE_CAMERA = 'REMOVE_CAMERA';
export const CHANGE_CAMERA = 'CHANGE_CAMERA';

export const resetProcessingTab = () => ({
  type: RESET_PROCESSING_TAB,
});

export const setLoading = (key, value) => ({
  type: SET_LOADING,
  payload: {
    key,
    value,
  },
});

export const changeValue = (key, value) => ({
  type: CHANGE_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeGeneralValue = (key, value) => ({
  type: CHANGE_GENERAL_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeProcessorConfig = (type, index, data) => ({
  type: CHANGE_PROCESSOR_CONFIG,
  payload: {
    type,
    index,
    data,
  },
});

export const changeBatchSize = (type, value) => ({
  type: CHANGE_BATCH_SIZE,
  payload: {
    type,
    value,
  },
});

export const addAnalytic = () => ({
  type: ADD_ANALYTIC,
});

export const removeAnalytic = (index) => ({
  type: REMOVE_ANALYTIC,
  payload: { index },
});

export const changeAnalytic = (index, key, value) => ({
  type: CHANGE_ANALYTIC,
  payload: { index, key, value },
});

export const addTrigger = () => ({
  type: ADD_TRIGGER,
});

export const removeTrigger = (index) => ({
  type: REMOVE_TRIGGER,
  payload: { index },
});

export const changeTrigger = (index, key, value) => ({
  type: CHANGE_TRIGGER,
  payload: { index, key, value },
});

export const addCamera = () => ({
  type: ADD_CAMERA,
});

export const removeCamera = (index) => ({
  type: REMOVE_CAMERA,
  payload: { index },
});

export const changeCamera = (index, key, value) => ({
  type: CHANGE_CAMERA,
  payload: { index, key, value },
});
