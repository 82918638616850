import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Filter from '../Filter';
import i18n from '../../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const TableFilters = ({ filters, onFilterChange, search, searchSubmit = {} }) => {
  const [searchValue, setSearch] = useState('')
  return (
    <>
    {search &&
      <div className="table__filters-search">
        <FontAwesomeIcon icon={faSearch} className="table__filters-search-btn" onClick={() => searchSubmit(searchValue)} />
        <input placeholder={i18n.t('common:search')} className="table__filters-search-input" onChange={(e) => setSearch(e.target.value)}/>
      </div>
    }
      <div className="table__filters-wrapper">
        {filters && filters.length && filters.map(({ title, options, menuAlignRight }) => (
          <Filter key={title} title={title} options={options} menuAlignRight={menuAlignRight} onClick={(title, key) => onFilterChange(title, key)}/>
        ))}
      </div>
    </>
  );
};

TableFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default TableFilters;
