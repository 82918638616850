import * as eventExport from './_via_event';
import * as utilExport from './_via_util';
import * as fileExport from './_via_file';
import * as constExport from './_via_const';
import { changeModalValue } from '../../../actions/training.action';

export function _via_control_panel(control_panel_container, via) {
  this._ID = '_via_control_panel_';
  this.c = control_panel_container;
  this.via = via;

  // registers on_event(), emit_event(), ... methods from
  // _via_event to let this module listen and emit events
  eventExport._via_event.call(this);

  this._init();
}

_via_control_panel.prototype._init = function (type) {
  this.c.innerHTML = '';

  this.c.appendChild(this.via.vm.c);
  this._add_view_manager_tools();

  this._add_spacer();

  this._add_project_tools();

  this._add_spacer();

  this._add_region_shape_selector();

  this._add_spacer();

  var editor = utilExport._via_util_get_svg_button('micon_insertcomment', 'Show/Hide Attribute Editor');
  editor.addEventListener(
    'click',
    function () {
      this.emit_event('editor_toggle', {});
    }.bind(this)
  );
  this.c.appendChild(editor);

  this._add_spacer();

  if (document.getElementById('micon_search')) {
    var magnifier = utilExport._via_util_get_svg_button(
      'micon_search',
      'Enable/disable magnifying glass to inspect finer details'
    );
    magnifier.addEventListener(
      'click',
      function () {
        this.emit_event('magnifier_toggle', {}); // control_panel -> view_annotator (bound in _via.js)
      }.bind(this)
    );
    this.c.appendChild(magnifier);

    var fit_screen = utilExport._via_util_get_svg_button(
      'micon_fit_screen',
      'Fit Image to Screen Height or Width'
    );
    fit_screen.addEventListener(
      'click',
      function () {
        this.emit_event('fit_screen', {}); // control_panel -> view_annotator (bound in _via.js)
      }.bind(this)
    );
    this.c.appendChild(fit_screen);

    var zoomin = utilExport._via_util_get_svg_button('micon_zoomin', 'Zoom In');
    zoomin.addEventListener(
      'click',
      function () {
        this.emit_event('zoom_in', {}); // control_panel -> view_annotator (bound in _via.js)
      }.bind(this)
    );
    this.c.appendChild(zoomin);

    var zoomout = utilExport._via_util_get_svg_button('micon_zoomout', 'Zoom Out');
    zoomout.addEventListener(
      'click',
      function () {
        this.emit_event('zoom_out', {}); // control_panel -> view_annotator (bound in _via.js)
      }.bind(this)
    );
    this.c.appendChild(zoomout);

    this._add_spacer();
  }

  this._add_spacer();

  var keyboard = utilExport._via_util_get_svg_button('micon_keyboard', 'Keyboard Shortcuts');
  keyboard.addEventListener(
    'click',
    function () {
      utilExport._via_util_page_show('page_keyboard_shortcut');
    }.bind(this)
  );
  this.c.appendChild(keyboard);
};

_via_control_panel.prototype._add_spacer = function () {
  var spacer = document.createElement('div');
  spacer.setAttribute('class', 'spacer');
  this.c.appendChild(spacer);
};

_via_control_panel.prototype._add_view_manager_tools = function () {
  var prev_view = utilExport._via_util_get_svg_button(
    'micon_navigate_prev',
    'Show Previous File',
    'show_prev'
  );
  prev_view.addEventListener('click', this.via.vm._on_prev_view.bind(this.via.vm));
  this.c.appendChild(prev_view);

  var next_view = utilExport._via_util_get_svg_button('micon_navigate_next', 'Show Next File', 'show_next');
  next_view.addEventListener('click', this.via.vm._on_next_view.bind(this.via.vm));
  this.c.appendChild(next_view);

  var add_media_local = utilExport._via_util_get_svg_button(
    'micon_add_circle',
    'Add Video File in Local Computer',
    'add_media_local'
  );
  add_media_local.addEventListener('click', this.via.vm._on_add_media_local.bind(this.via.vm));
  this.c.appendChild(add_media_local);

  var del_view = utilExport._via_util_get_svg_button(
    'micon_remove_circle',
    'Remove the Current File',
    'remove_media'
  );
  del_view.addEventListener('click', this.via.vm._on_del_view.bind(this.via.vm));
  this.c.appendChild(del_view);
};

_via_control_panel.prototype._add_region_shape_selector = function () {
  if (document.getElementById('shape_point') === null) {
    return;
  }

  var rect = utilExport._via_util_get_svg_button('shape_rectangle', 'Rectangle', 'RECTANGLE');
  rect.addEventListener(
    'click',
    function () {
      this._set_region_shape('RECTANGLE');
    }.bind(this)
  );
  this.c.appendChild(rect);

  var circle = utilExport._via_util_get_svg_button('shape_circle', 'Circle', 'CIRCLE');
  circle.style.fill = '#fff';
  circle.style.color = '#fff';
  circle.addEventListener(
    'click',
    function () {
      this._set_region_shape('CIRCLE');
    }.bind(this)
  );
  this.c.appendChild(circle);

  var ellipse = utilExport._via_util_get_svg_button('shape_ellipse', 'Ellipse', 'ELLIPSE');
  ellipse.addEventListener(
    'click',
    function () {
      this._set_region_shape('ELLIPSE');
    }.bind(this)
  );
  this.c.appendChild(ellipse);

  var polygon = utilExport._via_util_get_svg_button('shape_polygon', 'Polygon', 'POLYGON');
  polygon.addEventListener(
    'click',
    function () {
      this._set_region_shape('POLYGON');
    }.bind(this)
  );
  this.c.appendChild(polygon);

  var point = utilExport._via_util_get_svg_button('shape_point', 'Point', 'POINT');
  point.addEventListener(
    'click',
    function () {
      this._set_region_shape('POINT');
    }.bind(this)
  );
  this.c.appendChild(point);

  this.shape_selector_list = {
    POINT: point,
    RECTANGLE: rect,
    CIRCLE: circle,
    ELLIPSE: ellipse,
    POLYGON: polygon,
  };
};

_via_control_panel.prototype._set_region_shape = function (shape) {
  this.emit_event('region_shape', { shape: shape });
  for (var si in this.shape_selector_list) {
    if (si === shape) {
      this.shape_selector_list[si].classList.add('svg_button_selected');
    } else {
      this.shape_selector_list[si].classList.remove('svg_button_selected');
    }
  }
};

_via_control_panel.prototype._add_project_tools = function () {
  var load = utilExport._via_util_get_svg_button('micon_open', 'Open Project');
  load.addEventListener(
    'click',
    function () {
      utilExport._via_util_file_select_local(
        constExport._VIA_FILE_SELECT_TYPE.JSON,
        this._project_load_on_local_file_select.bind(this),
        false
      );
    }.bind(this)
  );
  this.c.appendChild(load);

  var save = utilExport._via_util_get_svg_button('micon_save', 'Save current Project');
  save.addEventListener(
    'click',
    function () {
      this.via.d.project_save();
    }.bind(this)
  );
  this.c.appendChild(save);

  var import_export_annotation = utilExport._via_util_get_svg_button(
    'micon_import_export',
    'Import or Export Annotations'
  );
  import_export_annotation.addEventListener('click', this._page_show_import_export.bind(this));
  this.c.appendChild(import_export_annotation);
};

_via_control_panel.prototype._page_show_import_export = function (d) {
  var action_map = {
    via_page_button_import: this._page_on_action_import.bind(this),
    via_page_button_export: this._page_on_action_export.bind(this),
  };
  utilExport._via_util_page_show('page_import_export', action_map);
};

_via_control_panel.prototype._page_on_action_import = function (d) {
  if (d._action_id === 'via_page_button_import') {
    if (d.via_page_import_pid !== '') {
      this.via.s._project_pull(d.via_page_import_pid).then(
        function (remote_rev) {
          try {
            var project = JSON.parse(remote_rev);
            // clear remote project identifiers
            project.project.pid = constExport._VIA_PROJECT_ID_MARKER;
            project.project.rev = constExport._VIA_PROJECT_REV_ID_MARKER;
            project.project.rev_timestamp = constExport._VIA_PROJECT_REV_TIMESTAMP_MARKER;
            this.via.d.project_load_json(project);
          } catch (e) {
            utilExport._via_util_msg_show('Malformed response from server: ' + e);
          }
        }.bind(this),
        function (err) {
          utilExport._via_util_msg_show(err + ': ' + d.via_page_import_pid);
        }.bind(this)
      );
      return;
    }

    if (d.via_page_import_via2_project_json.length === 1) {
      utilExport._via_util_load_text_file(
        d.via_page_import_via2_project_json[0],
        this._project_import_via2_on_local_file_read.bind(this)
      );
      return;
    }
    utilExport._via_util_msg_show('To import an existing shared project, you must enter its project-id.');
  }
};

_via_control_panel.prototype._page_on_action_export = function (d) {
  if (d._action_id === 'via_page_button_export') {
    this.via.ie.export_to_file(d.via_page_export_format);
  }
};

_via_control_panel.prototype._project_load_on_local_file_select = function (e) {
  if (e.target.files.length === 1) {
    utilExport._via_util_load_text_file(e.target.files[0], this._project_load_on_local_file_read.bind(this));
  }
};

_via_control_panel.prototype._project_load_on_local_file_read = function (project_data_str) {
  this.via.d.project_load(project_data_str);
};

_via_control_panel.prototype._project_import_via2_on_local_file_read = function (project_data_str) {
  this.via.d.project_import_via2_json(project_data_str);
};

_via_control_panel.prototype._share_show_info = function () {
  if (this.via.d.project_is_remote()) {
    this.via.s.exists(this.via.d.store.project.pid).then(
      function () {
        this.via.s._project_pull(this.via.d.store.project.pid).then(
          function (ok) {
            try {
              var d = JSON.parse(ok);
              var remote_rev_timestamp = new Date(parseInt(d.project.rev_timestamp));
              var local_rev_timestamp = new Date(parseInt(this.via.d.store.project.rev_timestamp));

              var pinfo = '<table>';
              pinfo += '<tr><td>Project Id</td><td>' + d.project.pid + '</td></tr>';
              pinfo +=
                '<tr><td>Remote Revision</td><td>' +
                d.project.rev +
                ' (' +
                remote_rev_timestamp.toUTCString() +
                ')</td></tr>';
              pinfo +=
                '<tr><td>Local Revision</td><td>' +
                this.via.d.store.project.rev +
                ' (' +
                local_rev_timestamp.toUTCString() +
                ')</td></tr>';
              pinfo += '</table>';
              if (d.project.rev !== this.via.d.store.project.rev) {
                pinfo +=
                  '<p>Your version of this project is <span style="color:red;">old</span>. Press <svg class="svg_icon" onclick="" viewbox="0 0 24 24"><use xlink:href="#micon_download"></use></svg> to fetch the most recent version of this project.</p>';
              } else {
                pinfo +=
                  '<p>You already have the <span style="color:blue;">latest</span> revision of this project.</p>';
              }

              document.getElementById('via_page_share_project_info').innerHTML = pinfo;
              document.getElementById('via_page_share_project_id').innerHTML = d.project.pid;
              utilExport._via_util_page_show('page_share_already_shared');
            } catch (e) {
              console.log(e);
              utilExport._via_util_msg_show('Malformed server response.' + e);
            }
          }.bind(this),
          function (pull_err) {
            utilExport._via_util_msg_show('Failed to pull project.');
            console.warn(pull_err);
          }.bind(this)
        );
      }.bind(this),
      function (exists_err) {
        utilExport._via_util_page_show('page_share_not_shared_yet');
        console.warn(exists_err);
      }.bind(this)
    );
  } else {
    utilExport._via_util_page_show('page_share_not_shared_yet');
  }
};

_via_control_panel.prototype._share_show_pull = function () {
  if (this.via.d.project_is_remote()) {
    // check if remote project has newer version
    this.via.s._project_pull(this.via.d.store.project.pid).then(
      function (ok) {
        try {
          var d = JSON.parse(ok);
          if (d.project.rev === this.via.d.store.project.rev) {
            utilExport._via_util_msg_show('You already have the latest revision of this project');
            return;
          } else {
            this.via.d.project_merge_rev(d);
          }
        } catch (e) {
          utilExport._via_util_msg_show('Malformed response from server.');
          console.warn(e);
        }
      }.bind(this),
      function (err) {
        utilExport._via_util_msg_show('Failed to pull project.');
        console.warn(err);
      }.bind(this)
    );
  } else {
    var action_map = {
      via_page_button_open_shared: this._page_on_action_open_shared.bind(this),
    };
    utilExport._via_util_page_show('page_share_open_shared', action_map);
  }
};

_via_control_panel.prototype._page_on_action_open_shared = function (d) {
  if (d._action_id === 'via_page_button_open_shared') {
    this.via.s.pull(d.via_page_input_pid);
  }
};

_via_control_panel.prototype._page_on_action_fileuri_bulk_add = function (d) {
  if (d.via_page_fileuri_urilist.length) {
    this.fileuri_bulk_add_from_url_list(d.via_page_fileuri_urilist);
  }

  if (d.via_page_fileuri_importfile.length === 1) {
    switch (parseInt(d.via_page_fileuri_filetype)) {
      case fileExport._VIA_FILE_TYPE.IMAGE:
        utilExport._via_util_load_text_file(
          d.via_page_fileuri_importfile[0],
          this.fileuri_bulk_add_image_from_file.bind(this)
        );
        break;
      case fileExport._VIA_FILE_TYPE.AUDIO:
        utilExport._via_util_load_text_file(
          d.via_page_fileuri_importfile[0],
          this.fileuri_bulk_add_audio_from_file.bind(this)
        );
        break;
      case fileExport._VIA_FILE_TYPE.VIDEO:
        utilExport._via_util_load_text_file(
          d.via_page_fileuri_importfile[0],
          this.fileuri_bulk_add_video_from_file.bind(this)
        );
        break;
      default:
        utilExport._via_util_load_text_file(
          d.via_page_fileuri_importfile[0],
          this.fileuri_bulk_add_auto_from_file.bind(this)
        );
    }
  }
};

_via_control_panel.prototype.fileuri_bulk_add_image_from_file = function (uri_list_str) {
  this.fileuri_bulk_add_from_url_list(uri_list_str, fileExport._VIA_FILE_TYPE.IMAGE);
};

_via_control_panel.prototype.fileuri_bulk_add_audio_from_file = function (uri_list_str) {
  this.fileuri_bulk_add_from_url_list(uri_list_str, fileExport._VIA_FILE_TYPE.AUDIO);
};

_via_control_panel.prototype.fileuri_bulk_add_video_from_file = function (uri_list_str) {
  this.fileuri_bulk_add_from_url_list(uri_list_str, fileExport._VIA_FILE_TYPE.VIDEO);
};

_via_control_panel.prototype.fileuri_bulk_add_auto_from_file = function (uri_list_str) {
  this.fileuri_bulk_add_from_url_list(uri_list_str, 0);
};

_via_control_panel.prototype.fileuri_bulk_add_from_url_list = function (uri_list_str, type) {
  var uri_list = uri_list_str.split('\n');
  if (uri_list.length) {
    var filelist = [];
    for (var i = 0; i < uri_list.length; ++i) {
      if (uri_list[i] === '' || uri_list[i] === ' ' || uri_list[i] === '\n') {
        continue; // skip
      }
      var filetype;
      if (type === 0 || typeof type === 'undefined') {
        filetype = utilExport._via_util_infer_file_type_from_filename(uri_list[i]);
      } else {
        filetype = type;
      }

      filelist.push({
        fname: uri_list[i],
        type: filetype,
        loc: utilExport._via_util_infer_file_loc_from_filename(uri_list[i]),
        src: uri_list[i],
      });
    }
    this.via.vm._file_add_from_filelist(filelist);
  }
};
