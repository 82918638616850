import { takeEvery, call, put, select, all } from 'redux-saga/effects';

import * as ActionTypes from '../constants/equipmentType.constants';
import * as api from '../api/equipmentType';

export function* getEquipmentTypes({ payload, actions = {} }) {
  const equipmentTypes = yield select((s) => s.equipmentType);
  try {
    const response = yield call(api.getEquipmentTypes, { ...equipmentTypes.query, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_EQUIPMENT_TYPES_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EQUIPMENT_TYPES_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getEquipmentType({ payload, actions = {} }) {
  try {
    const response = yield call(api.getEquipmentType, payload.uuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addEquipmentType({ payload, actions = {} }) {
  try {
    const response = yield call(api.addEquipmentType, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_EQUIPMENT_TYPE_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_EQUIPMENT_TYPE_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateEquipmentType({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateEquipmentType, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_EQUIPMENT_TYPE_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_EQUIPMENT_TYPE_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteEquipmentType({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteEquipmentType, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_EQUIPMENT_TYPE_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_EQUIPMENT_TYPE_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* equipmentTypeSagas() {
  yield all([
    takeEvery(ActionTypes.GET_EQUIPMENT_TYPES, getEquipmentTypes),
    takeEvery(ActionTypes.GET_EQUIPMENT_TYPE, getEquipmentType),
    takeEvery(ActionTypes.ADD_EQUIPMENT_TYPE, addEquipmentType),
    takeEvery(ActionTypes.UPDATE_EQUIPMENT_TYPE, updateEquipmentType),
    takeEvery(ActionTypes.DELETE_EQUIPMENT_TYPE, deleteEquipmentType),
  ]);
}