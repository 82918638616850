import { takeEvery, call, put, select, all } from 'redux-saga/effects';

import * as ActionTypes from '../constants/accounts.constants';
import * as api from '../api/accounts';

export function* getAccounts({ payload, actions = {} }) {
  const accounts = yield select((s) => s.accounts);
  try {
    const response = yield call(api.getAccounts, { ...accounts.query, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_ACCOUNTS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ACCOUNTS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addAccount({ payload, actions = {} }) {
  try {
    const response = yield call(api.addAccount, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_ACCOUNT_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_ACCOUNT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateAccount({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateAccount, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_ACCOUNT_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_ACCOUNT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getAccountUsers({ payload, actions = {} }) {
  const users = yield select((s) => s.users);
  try {
    const response = yield call(api.getAccountUsers, payload.uuid, { ...users.query, ...payload.params });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_USERS_ACCOUNT_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USERS_ACCOUNT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getUserToAccount({ payload, actions = {} }) {
  try {
    const response = yield call(api.getUserToAccount, payload.uuid, payload.userUuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addUserToAccount({ payload, actions = {} }) {
  try {
    const response = yield call(api.addUserToAccount, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteUserToAccount({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteUserToAccount, payload.uuid, payload.userUuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getAccountLimits({ payload, actions = {} }) {
  try {
    const response = yield call(api.getAccountLimits, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_LIMITS_ACCOUNT_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_LIMITS_ACCOUNT_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* accountsSagas() {
  yield all([
    takeEvery(ActionTypes.GET_ACCOUNTS, getAccounts),
    takeEvery(ActionTypes.ADD_ACCOUNT, addAccount),
    takeEvery(ActionTypes.UPDATE_ACCOUNT, updateAccount),
    takeEvery(ActionTypes.GET_USERS_ACCOUNT, getAccountUsers),
    takeEvery(ActionTypes.GET_USER_TO_ACCOUNT, getUserToAccount),
    takeEvery(ActionTypes.ADD_USER_TO_ACCOUNT, addUserToAccount),
    // takeEvery(ActionTypes.UPDATE_USER_TO_ACCOUNT, updateUserToAccount),
    takeEvery(ActionTypes.DELETE_USER_TO_ACCOUNT, deleteUserToAccount),
    takeEvery(ActionTypes.GET_LIMITS_ACCOUNT, getAccountLimits),
    // takeEvery(ActionTypes.UPDATE_LIMIT_TO_ACCOUNT, updateLimitToAccount),
  ]);
}
