import * as ActionTypes from '../constants/accounts.constants';

export const getAccounts = (params, actions) => ({
  type: ActionTypes.GET_ACCOUNTS,
  payload: params,
  actions,
});

export const addAccount = (params, actions) => ({
  type: ActionTypes.ADD_ACCOUNT,
  payload: params,
  actions,
});

export const updateAccount = (params, actions) => ({
  type: ActionTypes.UPDATE_ACCOUNT,
  payload: params,
  actions,
});

export const changeAccountValue = (key, value) => ({
  type: ActionTypes.CHANGE_ACCOUNT_VALUE,
  payload: { key, value },
});

export const changeModalValue = (key, value) => ({
  type: ActionTypes.CHANGE_MODAL_ACCOUNT_VALUE,
  payload: { key, value },
});

export const changePageValue = (value) => ({
  type: ActionTypes.CHANGE_ACCOUNT_PAGE_VALUE,
  payload: value,
});

export const getAccountLimits = (uuid, actions) => ({
  type: ActionTypes.GET_LIMITS_ACCOUNT,
  payload: uuid,
  actions,
});
