import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormInput, FormSelect } from '../../../components/Form';
import i18n from '../../../i18n';

const DatasetModal = ({ modal, modelTypes, baseModel, onClose, info, onChange, onSubmit }) => {
  return (
    <Modal isOpen={modal} fade>
      <ModalHeader toggle={() => onClose()}>{i18n.t(`training:addDataset`)}</ModalHeader>
      <ModalBody>
        <FormInput
          name="name"
          type="text"
          label={i18n.t('fields:name')}
          value={info.name}
          className="form-item form-item--one"
          onChange={(name, value) => {
            onChange(name, value);
          }}
          errorMessage={false}
        />
        <div className="form-row">
          <p className="h4 mr-2 m-2">Labels</p>
          <button
            className="btn btn--secondary m-2"
            onClick={() => onChange(`datasets`, info.datasets.concat(''))}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
        <div className="form-row">
          {info.datasets.map((input, index1) => (
            <FormInput
              name="datasets"
              label={i18n.t('training:label')}
              value={input}
              className="form-item form-item--three mb-10"
              delet={true}
              onDelete={(name, value) => {
                onChange(
                  `datasets`,
                  info.datasets.filter((data, index2) => index1 !== index2)
                );
              }}
              onChange={(name, value) => {
                onChange(
                  `datasets`,
                  info.datasets.map((data, index2) => (index1 === index2 ? (data = value) : data))
                );
              }}
              errorMessage={false}
            />
          ))}
        </div>
        <FormSelect
          name="modelType"
          label={i18n.t('training:modelType')}
          selected={info.modelType}
          options={modelTypes}
          isSearch={true}
          className="form-item form-item--one mb-10"
          onChange={(name, value) => {
            onChange(name, value);
          }}
          errorMessage={false}
        />
        <div className="form-row">
          <p className="h4 mr-2 m-2">Parameters</p>
          <button
            className="btn btn--secondary m-2"
            onClick={() => onChange(`parameters`, info.parameters.concat(''))}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
        <div className="form-row">
          {info.parameters.map((input, index1) => (
            <FormInput
              name="parameters"
              label={i18n.t('training:parameter')}
              value={input}
              className="form-item form-item--three mb-10"
              delet={true}
              onDelete={(name, value) => {
                onChange(
                  `parameters`,
                  info.parameters.filter((data, index2) => index1 !== index2)
                );
              }}
              onChange={(name, value) => {
                onChange(
                  `parameters`,
                  info.parameters.map((data, index2) => (index1 === index2 ? (data = value) : data))
                );
              }}
              errorMessage={false}
            />
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn--dark" onClick={() => onClose()}>
          {i18n.t('buttons:cancel')}
        </button>
        <button
          className="btn btn--secondary"
          type="submit"
          disabled={!info.name || !info.modelType || !info.datasets}
          onClick={() => onSubmit()}
        >
          {i18n.t('buttons:save')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default DatasetModal;
