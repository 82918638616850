import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// constants
import { DASHBOARD } from '../../../../constants/routes.constants';

import InfiniteScroll from '../../../../components/InfiniteScroll';
import CameraEventElement from '../../../../components/CameraEventElement';
import ModelsService from '../../../../services/ModelsService';
import * as camerasActions from '../../../../actions/cameras.actions';
import * as actions from '../../../../actions/player.actions';
import * as notificationActions from '../../../../actions/notification.actions';
import { isArrayLength, spreadRange, GTS } from '../../../../utils';
import i18n from '../../../../i18n';
import { subjectTypes } from '../../../../constants/alerts.constants';
import WatchFilter from '../../../../components/WatchFilter';

class CameraViewAlerts extends Component {
  constructor(props) {
    super(props);
    this.scroll = React.createRef();
    this.isMobile = window.innerWidth < 768;
    this.alertsInterval = null;
    this.state = {
      alerts: [],
      active: 'All',
      stopScroll: false,
    };
  }

  componentDidMount() {
    const { location = {} } = this.props.router;
    if (location.state && Object.keys(location.state).length) {
      const alert = location.state.alert;
      const timestamp = moment(alert.eventTimestamp).format('x');
      if (this.isSetOldAlert(timestamp)) {
        this.props.setCurrentTime(alert.timestamp);
        this.props.setRecordingScreen(alert);
      }
    }
  }

  selectedAlert = (alert) => {
    this.setState({ stopScroll: this.isMobile ? false : true }, () => {
      if (this.isSetOldAlert(alert.timestamp)) {
        this.props.setCurrentTime(alert.timestamp);
        this.props.setRecordingScreen(alert);
      } else {
        this.props.setCurrentTime(alert.timestamp);
      }
    });

    if (this.isMobile) {
      document.querySelector('.camera-view').scrollTo(0, 0);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { stopScroll } = this.state;
    const { current, list, alerts } = this.props;
  };

  componentWillUnmount() {
    clearInterval(this.alertsInterval);
  }

  isSetOldAlert = (timestamp) => {
    let getRange = spreadRange(process.env.REACT_APP_DEFAULT_PLAYER_RANGE);
    let lastTimestamp = +moment().subtract(getRange.t, getRange.c).format('x');

    return lastTimestamp >= timestamp;
  };

  onScrolledBottom = () => {
    const { total, page, size, isLoading } = this.props;
    if ((page + 1) * size < total && !isLoading) {
      this.props.getCameraAlerts({ page: page + 1 });
    }
  };

  render() {
    const { isLoading, camera, uuid } = this.props;

    return (
      <div className="dashboard__alerts-wrapper h-100">
        <WatchFilter
          title={i18n.t('watch:latestActivityTitle')}
          filters={[]}
          onChange={(title, key) => this.setState({ active: key })}
        />
        <InfiniteScroll
          loading={isLoading}
          className="latest-alerts"
          onScrolledBottom={this.onScrolledBottom}
          reff={(ref) => (this.scroll = ref)}
        >
          <div className="latest-alerts__list-wrapper">
            <div className="latest-alerts__list">
              {this.props.alerts &&
              this.props.alerts.find((alert) => alert.cameraUuid === this.props.uuid)?.data?.length !== 0
                ? this.props.alerts
                    .find((alert) => alert.cameraUuid === this.props.uuid)
                    ?.data.map((e, index) => {
                      return (
                        <CameraEventElement
                          id={e.notificationId}
                          to={{
                            pathname: `${DASHBOARD}/${e.cameraUuid}`,
                            state: { setCurrent: e.timestamp, alert: e },
                          }}
                          item={{
                            subject:
                              subjectTypes.filter((data) => data.triggers.includes(e.eventType))[0]?.name +
                              ' ' +
                              (e.data?.plateLabel ? e.data.plateLabel : '') +
                              (e.data?.information?.name !== undefined ? e.data.information?.name : ''),
                            action: e.workflowName,
                            camera: camera,
                            timestamp: e.time,
                            thumbUrl: e.thumbUrl ? e.thumbUrl : e.frameUrl,
                          }}
                          onTimeClick={() => {
                            this.props.setCurrentTime(new Date(e.time));
                          }}
                          deleteAlert={(id) => {
                            this.props.remove(id, uuid);
                          }}
                        />
                      );
                    })
                : !isLoading && (
                    <div className="selected-camera__no-selected">
                      <p className="text-text-no-selected selected-camera__no-connected">
                        {i18n.t('notFound')}
                      </p>
                    </div>
                  )}
            </div>
          </div>
        </InfiniteScroll>
      </div>
    );
  }
}

CameraViewAlerts.propTypes = {
  notifications: PropTypes.arrayOf(ModelsService.getAlertModel()),
  size: PropTypes.number,
  page: PropTypes.number,
  setCurrentTime: PropTypes.func,
  current: PropTypes.string,
  isLoading: PropTypes.bool,
};

const mapStateToProps = ({ player, router, notifications }) => {
  const { list, query, isLoading } = player.events;
  const alerts = notifications.alerts;
  return {
    size: query.size,
    page: query.page,
    list: list.items,
    total: list.total,
    current: player.controls.current,
    isLoading,
    router,
    alerts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentTime: (time) => dispatch(actions.setCurrent(time)),
  setRecordingScreen: (alert) => dispatch(actions.setRecordingScreen(alert)),
  getCameraAlerts: (params) => dispatch(camerasActions.getCameraAlerts(null, params)),
  remove: (id, uuid) => dispatch(notificationActions.remove(id, uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CameraViewAlerts);
