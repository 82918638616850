import { all, call, put, takeEvery } from 'redux-saga/effects';

import * as ActionTypes from '../constants/historyData.constants';
import * as api from '../api/historyData';

export function* getHistoryData({ payload, actions = {} }) {
  try {
    const response = yield call(api.getHistoryData, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_HISTORY_DATA_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_HISTORY_DATA_ERROR, payload: error });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ActionTypes.GET_HISTORY_DATA, getHistoryData)]);
}
