export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_RESULT = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const GET_AUTHORITIES = 'GET_AUTHORITIES';
export const GET_AUTHORITIES_RESULT = 'GET_AUTHORITIES_RESULT';
export const GET_AUTHORITIES_ERROR = 'GET_AUTHORITIES_ERROR';

export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_RESULT = 'GET_ROLE_RESULT';
export const GET_ROLE_ERROR = 'GET_ROLE_ERROR';

export const ADD_ROLE = 'ADD_ROLE';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_ERROR = 'ADD_ROLE_ERROR';

export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';

export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';

export const CHANGE_PAGE_VALUE = 'CHANGE_PAGE_VALUE';
export const CHANGE_ROLE_VALUE = 'CHANGE_ROLE_VALUE';

export const Authorities = [
  {
    name: 'All',
    id: 'ALL',
    view: false,
    edit: true,
  },
  {
    name: 'Dashboard - Widget',
    id: 'WIDGET',
    view: true,
    edit: true,
  },
  {
    name: 'Environment Designer - Cameras',
    id: 'CAMERA',
    view: true,
    edit: true,
  },
  {
    name: 'Environment Designer - Workflows',
    id: 'WORKFLOW',
    view: true,
    edit: true,
  },
  {
    name: 'Administration - Roles',
    id: 'ROLE',
    view: true,
    edit: true,
  },
  {
    name: 'Administration - Equipment',
    id: 'EQUIPMENT',
    view: true,
    edit: true,
  },
  {
    name: 'Administration - Locations',
    id: 'LOCATION',
    view: true,
    edit: true,
  },
  {
    name: 'Administration - Persons',
    id: 'SUBJECT',
    view: true,
    edit: true,
  },
  {
    name: 'Administration - Users',
    id: 'USER',
    view: true,
    edit: true,
  },
  {
    name: 'Administration - Resource Limits',
    id: 'RESOURCE_LIMIT',
    view: true,
    edit: true,
  },
  {
    name: 'Alerts - Alerts',
    id: 'EVENT',
    view: true,
    edit: false,
  },
  {
    name: 'Dashboard - Camera Live View',
    id: 'DATA',
    view: true,
    edit: false,
  },
  {
    name: 'Training & Markup - Dataset',
    id: 'DATASET',
    view: true,
    edit: true,
  },
  {
    name: 'Training & Markup - Model',
    id: 'MODEL',
    view: true,
    edit: true,
  },
  {
    name: 'Alerts - Alert Configuration',
    id: 'ALERT_ROUTE',
    view: true,
    edit: true,
  },
  {
    name: 'Environment Designer - Schema',
    id: 'SCHEMA',
    view: true,
    edit: false,
  },
];

export const pageAuthorities = {
  Dashboard: ['READ_WIDGET', 'WRITE_WIDGET', 'ALL'],
  CameraView: ['READ_DATA', 'ALL'],
  Subjects: ['READ_SUBJECT', 'WRITE_SUBJECT', 'ALL'],
  Events: ['READ_EVENT', 'ALL'],
  UsersList: ['READ_USER', 'WRITE_USER', 'ALL'],
  EquipmentList: ['READ_EQUIPMENT', 'WRITE_EQUIPMENT', 'ALL'],
  WorkflowList: ['READ_WORKFLOW', 'WRITE_WORKFLOW', 'READ_SCHEMA', 'ALL'],
  TrainingMarkup: ['READ_DATASET', 'WRITE_DATASET', 'READ_MODEL', 'WRITE_MODEL', 'ALL'],
  AlertsList: ['READ_ALERT_ROUTE', 'WRITE_ALERT_ROUTE', 'ALL'],
  RolesList: ['READ_ROLE', 'WRITE_ROLE', 'ALL'],
  PersonsList: ['READ_SUBJECT', 'WRITE_SUBJECT', 'ALL'],
  CarsList: ['READ_SUBJECT', 'WRITE_SUBJECT', 'ALL'],
  LocationsList: ['READ_LOCATION', 'WRITE_LOCATION', 'ALL'],
  EquipmentTypesList: ['READ_EQUIPMENT', 'WRITE_EQUIPMENT', 'ALL'],
  MaintenanceHistory: ['READ_EQUIPMENT', 'WRITE_EQUIPMENT', 'ALL'],
  CamerasList: ['READ_CAMERA', 'WRITE_CAMERA', 'ALL'],
  UserLimitsList: ['READ_RESOURCE_LIMIT', 'WRITE_RESOURCE_LIMIT', 'ALL'],
  AccountsList: ['ADMINISTRATION'],
  AccountUsers: ['ADMINISTRATION'],
  AccountLimits: ['ADMINISTRATION'],
};
