import * as ActionTypes from '../constants/subjects.constants';

const initialState = {
  person: {},
  loadingGetPerson: false,
  loadingAddPerson: false,
  loadingUpdatePerson: false,
  loadingDeletePerson: false,
  loadingDeletePhoto: false,
};

export const personReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PERSON:
      return {
        ...state,
        loadingGetPerson: true,
      };

    case ActionTypes.ADD_PERSON:
      return {
        ...state,
        loadingAddPerson: true,
      };

    case ActionTypes.UPDATE_PERSON:
      return {
        ...state,
        loadingUpdatePerson: true,
      };

    case ActionTypes.DELETE_PERSON:
      return {
        ...state,
        loadingDeletePerson: true,
      };

    case ActionTypes.GET_PERSON_SUCCESS:
      return {
        ...state,
        person: action.payload,
        loadingGetPerson: false,
      };

    case ActionTypes.UPDATE_PERSON_SUCCESS:
      return {
        ...state,
        person: action.payload,
        loadingUpdatePerson: false,
      };

    case ActionTypes.DELETE_PERSON_SUCCESS:
      return {
        ...state,
        person: {},
        loadingDeletePerson: false,
      };

    case ActionTypes.GET_PERSON_ERROR:
      return {
        ...state,
        loadingGetPerson: false,
      };

    case ActionTypes.ADD_PERSON_SUCCESS:
    case ActionTypes.ADD_PERSON_ERROR:
      return {
        ...state,
        loadingAddPerson: false,
      };

    case ActionTypes.UPDATE_PERSON_ERROR:
      return {
        ...state,
        loadingUpdatePerson: false,
      };

    case ActionTypes.DELETE_PERSON_ERROR:
      return {
        ...state,
        loadingDeletePerson: false,
      };

    case ActionTypes.RESET_PERSON_STATE:
      return initialState;

    default:
      return state;
  }
};
