import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Loader from '../../../../components/Loader';
import i18n from '../../../../i18n';
import { FormInput } from '../../../../components/Form';

import * as workflowActions from '../../../../actions/workflows.action';

const DetachCameraModal = ({
  title,
  workflowUuid,
  isOpen,
  onSubmit,
  onClose,
  isLoading,
  className,
  getWorkflowCameras,
}) => {
  const [cameras, setCameras] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);

  useEffect(() => {
    getWorkflowCameras(
      workflowUuid,
      {},
      {
        onSuccess: (cameras) => {
          setCameras([
            ...cameras.items.map((camera) => ({
              name: camera.name,
              uuid: camera.uuid,
            })),
          ]);
        },
      }
    );
  }, []);

  const deleteCamera = (index) => {
    setCameras((prev) => [...prev.filter((camera, i) => i !== index)]);
    setDeletedIds((prev) => [...prev, cameras[index].uuid]);
  };

  const generateCameras = cameras.map((camera, index) => (
    <div key={index} className="form__creation">
      <div className="form__creation__header">
        <div className="form-row">
          <FormInput
            className="form-item form-item--one"
            type="text"
            label={i18n.t('workflowModals:labelAttachedCamera')}
            value={camera.name}
            disabled={true}
            onChange={() => {}}
          />
        </div>

        <button className="form__creation__header-btn" onClick={() => deleteCamera(index)}>
          <FontAwesomeIcon icon={faTimes} className="form__creation__header-times" />
        </button>
      </div>
    </div>
  ));

  return (
    <Modal isOpen={isOpen} className={cx('', className)} fade>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>

      <ModalBody>
        <div className="workflow-form__tab-content">
          {cameras.length ? generateCameras : 'No connected cameras'}
        </div>
      </ModalBody>

      <ModalFooter>
        <Loader loading={isLoading} />
        <button type="button" className="btn btn--dark" disabled={isLoading} onClick={onClose}>
          {i18n.t('buttons:cancel')}
        </button>

        <button
          className="btn btn--secondary"
          type="submit"
          onClick={() => onSubmit(workflowUuid, deletedIds)}
        >
          {i18n.t('buttons:save')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getWorkflowCameras: (uuid, params, actions) =>
    dispatch(workflowActions.getWorkflowCameras(uuid, params, actions)),
});

export default connect(null, mapDispatchToProps)(DetachCameraModal);
