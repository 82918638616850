import { takeEvery, call, put, select, all } from 'redux-saga/effects';

import * as ActionTypes from '../constants/schemas.constants';
import * as api from '../api/schemas';

export function* getProcessingTypes({ actions = {} }) {
  try {
    const response = yield call(api.getProcessingTypes);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess([...results.map((type) => ({ label: type, value: type }))]);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getAnalyticTypes({ payload, actions = {} }) {
  try {
    const response = yield call(api.getAnalyticTypes, payload.type);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess([...results.map((type) => ({ label: type, value: type }))]);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getTriggerTypes({ payload, actions = {} }) {
  try {
    const response = yield call(api.getTriggerTypes, payload.type);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess([...results.map((type) => ({ label: type, value: type }))]);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getProcessingSchema({ payload, actions = {} }) {
  try {
    const response = yield call(api.getProcessingSchema, payload.type);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getModelSchema({ payload, actions = {} }) {
  try {
    const response = yield call(api.getModelSchema, payload.procConfUUID, payload.modelId);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getAnalyticSchema({ payload, actions = {} }) {
  try {
    const response = yield call(api.getAnalyticSchema, payload.type);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getTriggerSchema({ payload, actions = {} }) {
  try {
    const response = yield call(api.getTriggerSchema, payload.type);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.GET_PROCESSING_TYPES, getProcessingTypes),
    takeEvery(ActionTypes.GET_PROCESSING_SCHEMA, getProcessingSchema),
    takeEvery(ActionTypes.GET_MODEL_SCHEMA, getModelSchema),
    takeEvery(ActionTypes.GET_ANALYTIC_TYPES, getAnalyticTypes),
    takeEvery(ActionTypes.GET_ANALYTIC_SCHEMA, getAnalyticSchema),
    takeEvery(ActionTypes.GET_TRIGGER_SCHEMA, getTriggerSchema),
    takeEvery(ActionTypes.GET_TRIGGERS_TYPES, getTriggerTypes),
  ]);
}
