import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CustomInput, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import {
  getWorkflowsUsingCameras,
  getAnalyticsForWorkflowCamera,
  getZonesForWorkflowCamera,
} from '../../../../../actions/workflows.action';
import * as actions from '../../../../../actions/cameras.actions';
import {
  showAccumulation,
  showActivity,
  showHeatmap,
  showLines,
  showQueues,
} from '../../../../../constants/cameras.constants';
import { HeatMap } from '../../../../../components/Player/PlayerAlert/PlayerOptions/HeatMap';
import { FormSelect } from '../../../../../components/Form';
import i18n from '../../../../../i18n';

const WidgetModal = ({
  cameras,
  modalOpen,
  closeModal,
  addWidget,
  getAnalyticsForWorkflowCamera,
  getWorkflowsUsingCameras,
  getZonesForWorkflowCamera,
  changeCamerasValue,
}) => {
  const [activeCamera, setActiveCamera] = useState('');
  const [workflows, setWorkflows] = useState([]);
  const [activeWorkflow, setActiveWorkflow] = useState('');
  const [widgets, setWidgets] = useState([]);
  const [activeWidget, setActiveWidget] = useState('');
  const [inout, setInout] = useState([]);
  const [queue, setQueue] = useState([]);
  const [activity, setActivity] = useState([]);
  const [heatmap, setHeatmap] = useState(null);

  useEffect(() => {
    setActiveCamera('');
    setWorkflows([]);
    setActiveWorkflow('');
    setActiveWidget('');
  }, [modalOpen]);

  const chooseCamera = (camera) => {
    setActiveCamera(camera);
    setWorkflows([]);
    setActiveWorkflow('');
    setActiveWidget('');
    getWorkflowsUsingCameras(camera.value, {
      onSuccess: (data) => {
        setWorkflows(data);
      },
    });
  };

  const chooseWorkflow = (workflow) => {
    setActiveWorkflow(workflow);
    setWidgets([]);
    setActiveWidget('');
    getAnalyticsForWorkflowCamera(workflow.value, activeCamera.value, {
      onSuccess: (data) => {
        data.length !== 0 &&
          data.forEach((type) => {
            showHeatmap.includes(type.type) &&
              setWidgets((prev) => [...prev, { label: 'Heatmap Widget', value: 'heatmap' }]);
            if (showActivity.includes(type.type)) {
              setWidgets((prev) => [...prev, { label: 'Realtime Activity Widget', value: 'activity' }]);
              getZonesForWorkflowCamera(workflow.value, activeCamera.value, {
                onSuccess: (items) => {
                  setActivity(items.zoi.map((item) => ({ name: item.name, uuid: item.id, checked: true })));
                },
              });
            }
            if (showAccumulation.includes(type.type)) {
              setWidgets((prev) => [
                ...prev,
                { label: 'Accumulation Activity Widget', value: 'accumActivity' },
              ]);
              getZonesForWorkflowCamera(workflow.value, activeCamera.value, {
                onSuccess: (items) => {
                  setActivity(items.zoi.map((item) => ({ name: item.name, uuid: item.id, checked: true })));
                },
              });
            }
            if (showQueues.includes(type.type)) {
              setWidgets((prev) => [...prev, { label: 'Queue Widget', value: 'queue' }]);
              getZonesForWorkflowCamera(workflow.value, activeCamera.value, {
                onSuccess: (items) => {
                  setQueue(items.zoi.map((item) => ({ name: item.name, uuid: item.id, checked: true })));
                },
              });
            }
            if (showLines.includes(type.type)) {
              setWidgets((prev) => [...prev, { label: 'In/Out Widget', value: 'inout' }]);
              getZonesForWorkflowCamera(workflow.value, activeCamera.value, {
                onSuccess: (items) => {
                  setInout(items.lines.map((item) => ({ name: item.name, uuid: item.id, checked: true })));
                },
              });
            }
          });
      },
    });
  };

  const addDashboardWidget = () => {
    inout.filter((item) => item.checked === true).length !== 0 ||
    queue.filter((item) => item.checked === true).length !== 0 ||
    activity.filter((item) => item.checked === true).length !== 0 ||
    heatmap
      ? addWidget({
          camera: { name: activeCamera.label, uuid: activeCamera.value },
          workflow: { name: activeWorkflow.label, uuid: activeWorkflow.value },
          type: activeWidget.value,
          height: Math.ceil(
            (inout.filter((item) => item.checked === true).length +
              queue.filter((item) => item.checked === true).length +
              (activeWidget.value === 'accumActivity' &&
                activity.filter((item) => item.checked === true).length * 2.6) +
              (activeWidget.value === 'activity' &&
                activity.filter((item) => item.checked === true).length)) /
              5
          ),
          position: 0,
          width: 1,
          config: {
            inoutLines:
              inout.filter((item) => item.checked === true).length !== 0 &&
              inout.filter((item) => item.checked === true),
            queueZones:
              queue.filter((item) => item.checked === true).length !== 0 &&
              queue.filter((item) => item.checked === true),
            activityZones:
              activity.filter((item) => item.checked === true).length !== 0 &&
              activity.filter((item) => item.checked === true),
            heatmap: heatmap !== null && heatmap,
          },
        })
      : closeModal();
  };

  return (
    <Modal isOpen={modalOpen} fade>
      <ModalHeader>Add Analytics</ModalHeader>
      <ModalBody>
        <div className="form-row_mb-10">
          <FormSelect
            label={i18n.t('workflowModals:cameraTab')}
            selected={activeCamera}
            isSearch={true}
            options={cameras.map((item) => ({ label: item.name, value: item.uuid }))}
            className="form-item form-item--one"
            onChange={(camera) => chooseCamera(camera)}
          />
        </div>
        {activeCamera && (
          <div className="form-row_mb-10">
            <FormSelect
              label={i18n.t('workflowModals:labelWorkflowName')}
              options={workflows.map((item) => ({ label: item.name, value: item.uuid }))}
              selected={activeWorkflow}
              isSearch={true}
              className="form-item form-item--one"
              onChange={(workflow) => chooseWorkflow(workflow)}
            />
          </div>
        )}
        {activeWorkflow && (
          <FormSelect
            label={i18n.t('workflowModals:availableWidget')}
            options={widgets}
            selected={activeWidget}
            isSearch={true}
            className="form-item form-item--one"
            onChange={(widget) => setActiveWidget(widget)}
          />
        )}
        {activeWidget.value === 'heatmap' && (
          <div className="form-item form-item--one">
            <HeatMap
              className="player-big-widget"
              setHeatmapInterval={(from, to) =>
                setHeatmap({
                  from: from,
                  to: to !== null ? to : Date.now(),
                  online: to !== null ? false : true,
                  heatmapUpdateInterval: from,
                })
              }
              buttons={['Set', 'Set']}
            />
          </div>
        )}
        {activeWidget.value === 'inout' && (
          <div className="form-item form-item--two">
            {inout.map((item) => (
              <div className="player-big-widget">
                <p>{item.name}</p>
                <CustomInput
                  type="switch"
                  id={item.uuid}
                  name={item.name}
                  checked={item.checked}
                  onChange={() =>
                    setInout((prev) =>
                      prev.map((inout) =>
                        inout.uuid === item.uuid
                          ? { name: item.name, uuid: item.uuid, checked: !item.checked }
                          : inout
                      )
                    )
                  }
                />
              </div>
            ))}
          </div>
        )}
        {activeWidget.value === 'queue' && (
          <div className="form-item form-item--two">
            {queue.map((item) => (
              <div className="player-big-widget">
                <p>{item.name}</p>
                <CustomInput
                  key={item.uuid}
                  type="switch"
                  id={item.uuid}
                  name={item.name}
                  checked={item.checked}
                  onChange={() =>
                    setQueue((prev) =>
                      prev.map((queue) =>
                        queue.uuid === item.uuid
                          ? { name: item.name, uuid: item.uuid, checked: !item.checked }
                          : queue
                      )
                    )
                  }
                />
              </div>
            ))}
          </div>
        )}
        {(activeWidget.value === 'activity' || activeWidget.value === 'accumActivity') && (
          <div className="form-item form-item--two">
            {activity.map((item) => (
              <div className="player-big-widget">
                <p>{item.name}</p>
                <CustomInput
                  key={item.uuid}
                  type="switch"
                  id={item.uuid}
                  name={item.name}
                  checked={item.checked}
                  onChange={() =>
                    setActivity((prev) =>
                      prev.map((act) =>
                        act.uuid === item.uuid
                          ? { name: item.name, uuid: item.uuid, checked: !item.checked }
                          : act
                      )
                    )
                  }
                />
              </div>
            ))}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="table__page-button btn btn--dark" onClick={() => closeModal()}>
          Cancel
        </button>
        <button className="table__page-button btn btn--secondary" onClick={() => addDashboardWidget()}>
          Add
        </button>
      </ModalFooter>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getWorkflowsUsingCameras: (uuid, actions) => dispatch(getWorkflowsUsingCameras(uuid, actions)),
  getAnalyticsForWorkflowCamera: (workflowUuid, cameraUuid, actions) =>
    dispatch(getAnalyticsForWorkflowCamera(workflowUuid, cameraUuid, actions)),
  getZonesForWorkflowCamera: (workflowUuid, cameraUuid, actions) =>
    dispatch(getZonesForWorkflowCamera(workflowUuid, cameraUuid, actions)),
  changeCamerasValue: (key, value) => dispatch(actions.changeCamerasValue(key, value)),
});

export default connect(null, mapDispatchToProps)(WidgetModal);
