import * as ActionTypes from '../constants/workflows.constants';

const initialState = {
  list: {
    items: [],
    total: 0,
  },
  query: {
    size: 20,
    page: 0,
  },
  live: '',
  loading: {
    getWorkflows: false,
    nextPage: false,
  },
  type: 'All',
  status: 'All',
  name: '',
  sortName: 'asc',
  loadingAddWorkflow: false,
  loadingUpdateWorkflow: false,
  loadingDeleteWorkflow: false,
};

export const workflowsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_WORKFLOWS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getWorkflows: true,
        },
      };

    case ActionTypes.GET_WORKFLOWS_RESULT:
      const { items, total, page } = action.payload;
      return {
        ...state,
        list: { items, total },
        query: { ...state.query, page },
        loading: {
          ...state.loading,
          getWorkflows: false,
        },
        loadingAddWorkflow: false,
        loadingUpdateWorkflow: false,
        loadingDeleteWorkflow: false,
      };

    case ActionTypes.GET_WORKFLOWS_ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getWorkflows: false,
        },
      };

    case ActionTypes.ADD_WORKFLOW:
      return {
        ...state,
        loadingAddWorkflow: true,
      };

    case ActionTypes.ADD_WORKFLOW_SUCCESS:
      return {
        ...state,
        loadingAddWorkflow: false,
      };

    case ActionTypes.ADD_WORKFLOW_ERROR:
      return {
        ...state,
        loadingAddWorkflow: false,
      };

    case ActionTypes.UPDATE_WORKFLOW:
      return {
        ...state,
        loadingUpdateWorkflow: true,
      };

    case ActionTypes.UPDATE_WORKFLOW_SUCCESS:
      return {
        ...state,
        loadingUpdateWorkflow: false,
      };

    case ActionTypes.UPDATE_WORKFLOW_ERROR:
      return {
        ...state,
        loadingUpdateWorkflow: false,
      };

    case ActionTypes.DELETE_WORKFLOW:
      return {
        ...state,
        loadingDeleteWorkflow: true,
      };

    case ActionTypes.DELETE_WORKFLOW_SUCCESS:
      return {
        ...state,
        loadingDeleteWorkflow: false,
      };

    case ActionTypes.DELETE_WORKFLOW_ERROR:
      return {
        ...state,
        loadingDeleteWorkflow: false,
      };

    case ActionTypes.CHANGE_WORKFLOW_RUNNING_STATUS:
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.map((item) => {
            if (item.uuid === action.payload.uuid) {
              return action.payload;
            }
            return item;
          }),
        },
      };

    case ActionTypes.CHANGE_CAMERA_RUNNING_STATUS:
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.map((item) => {
            if (
              item.runningStatus.camerasStatus.some(
                (cameraStatus) => cameraStatus.cameraUuid === action.payload.camera.uuid
              )
            ) {
              return {
                ...item,
                runningStatus: {
                  ...item.runningStatus,
                  camerasStatus: item.runningStatus.camerasStatus.map((cameraStatus) => {
                    if (cameraStatus.cameraUuid === action.payload.camera.uuid) {
                      return {
                        ...cameraStatus,
                        status: action.payload.status,
                      };
                    }
                    return cameraStatus;
                  }),
                },
              };
            }
            return item;
          }),
        },
      };

    case ActionTypes.CHANGE_PROCESSING_RUNNING_STATUS:
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.map((item) => {
            if (item.uuid === action.payload.workflow.uuid) {
              return {
                ...item,
                runningStatus: {
                  ...item.runningStatus,
                  camerasStatus: item.runningStatus.camerasStatus.map((cameraStatus) => {
                    if (cameraStatus.cameraUuid === action.payload.camera.uuid) {
                      return {
                        ...cameraStatus,
                        processingStatus: action.payload.status,
                      };
                    }
                    return cameraStatus;
                  }),
                },
              };
            }
            return item;
          }),
        },
      };

    case ActionTypes.CHANGE_ANALYTIC_RUNNING_STATUS:
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.map((item) => {
            if (item.uuid === action.payload.workflow.uuid) {
              return {
                ...item,
                runningStatus: {
                  ...item.runningStatus,
                  camerasStatus: item.runningStatus.camerasStatus.map((cameraStatus) => {
                    if (cameraStatus.cameraUuid === action.payload.camera.uuid) {
                      return {
                        ...cameraStatus,
                        analyticsStatus: cameraStatus.analyticsStatus.map((analyticStatus) => {
                          if (analyticStatus.analyticsUuid === action.payload.analyticsUuid) {
                            return {
                              ...analyticStatus,
                              status: action.payload.status,
                            };
                          }
                        }),
                      };
                    }
                    return cameraStatus;
                  }),
                },
              };
            }
            return item;
          }),
        },
      };

    case ActionTypes.CHANGE_TRIGGER_RUNNING_STATUS:
      return {
        ...state,
        list: {
          ...state.list,
          items: state.list.items.map((item) => {
            if (item.uuid === action.payload.workflow.uuid) {
              return {
                ...item,
                runningStatus: {
                  ...item.runningStatus,
                  camerasStatus: item.runningStatus.camerasStatus.map((cameraStatus) => {
                    if (cameraStatus.cameraUuid === action.payload.camera.uuid) {
                      return {
                        ...cameraStatus,
                        triggersStatus: action.payload.status,
                      };
                    }
                    return cameraStatus;
                  }),
                },
              };
            }
            return item;
          }),
        },
      };

    case ActionTypes.CHANGE_WORKFLOW_FILTERS:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    default:
      return state;
  }
};
