import { takeEvery, call, put, all, select, take, race } from 'redux-saga/effects';
import moment from 'moment';

import DateTimeService from '../services/DateTimeService';
import { getOffsetTime } from '../components/Player/constants';
import { spreadRange } from '../utils';
import * as ActionTypes from '../constants/cameras.constants';
import * as api from '../api/cameras';
import * as streamApi from '../api/stream';

const DEFAULT_RANGE = getOffsetTime(process.env.REACT_APP_DEFAULT_PLAYER_RANGE + '');
const GTS = (d) => DateTimeService.getTimestamp(d);

export function* getCameras({ payload, actions = {} }) {
  const cameras = yield select((s) => s.cameras);
  try {
    const response = yield call(api.getCameras, { ...cameras.query, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_CAMERAS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CAMERAS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getWidgets({ payload, actions = {} }) {
  try {
    const response = yield call(api.getWidgets, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_DASHBOARD_WIDGETS_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_DASHBOARD_WIDGETS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getReadyHeatmap({ payload, actions = {} }) {
  let { workflowUuid, cameraUuid, params } = payload;
  try {
    const response = yield call(api.getReadyHeatmap, workflowUuid, cameraUuid, params);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_READY_HEATMAP_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_READY_HEATMAP_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getReadyHeatmapForQueues({ payload, actions = {} }) {
  let { workflowUuid, cameraUuid, params } = payload;
  try {
    const response = yield call(api.getReadyHeatmapForQueues, workflowUuid, cameraUuid, params);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_READY_HEATMAP_FOR_QUEUES_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_READY_HEATMAP_FOR_QUEUES_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getCamerasNextPage() {
  const cameras = yield select((s) => s.cameras);
  const next = cameras.query.page + 1;
  try {
    const response = yield call(api.getCameras, { ...cameras.query, page: next });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_CAMERAS_NEXT_PAGE_RESULT, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CAMERAS_NEXT_PAGE_ERROR });
  }
}

export function* getCamera({ payload, actions = {} }) {
  try {
    const response = yield call(api.getCamera, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_CAMERA_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CAMERA_ERROR, payload: error });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getDatastream({ payload, actions = {} }) {
  try {
    const response = yield call(api.getDatastream, payload.workflowUuid, payload.cameraUuid);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_DATASTREAM_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_DATASTREAM_ERROR, payload: error });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getCameraEvents({ payload }) {
  let { uuid, params } = payload;
  const player = yield select((s) => s.player);
  const { size, page } = player.events.list;
  const query = {
    ...player.events.query,
    from: GTS() - 86400000,
    to: GTS(),
    size,
    page,
    ...params,
  };
  try {
    const response = yield call(api.getCameraEvents, uuid || player.camera.uuid, query);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_CAMERA_EVENTS_RESULT, payload: results });
      if (results.total > results.size + results.size * results.page) {
        yield put({
          type: ActionTypes.GET_CAMERA_EVENTS,
          payload: { uuid: payload.uuid, params: { page: results.page + 1 } },
        });
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CAMERA_EVENTS_ERROR, payload: error });
  }
}

export function* getCameraEventsGroupedTimestamps() {
  const player = yield select((s) => s.player);
  const { range } = player.controls;
  var groupInterval = 60,
    timeback = 60000;
  switch (range) {
    case '10m':
      groupInterval = 600;
      timeback = 600000;
      break;
    case '1hr':
      groupInterval = 3600;
      timeback = 3600000;
      break;
    case '12hr':
      groupInterval = 43200;
      timeback = 43200000;
      break;
    case '24hr':
      groupInterval = 86400;
      timeback = 86390000;
      break;
  }
  const query = {
    from: GTS() - timeback,
    groupingInterval: groupInterval,
    cameraUuid: player.camera.cameraUuid,
    workflowUuid: player.camera.activeWorkflowUuid,
  };
  try {
    const response = yield call(api.getCameraEventsGroupedTimestamps, query);
    const { success, results } = response;
    if (success) {
      yield put({
        type: ActionTypes.GET_CAMERA_EVENTS_GROUPED_TIMESTAMPS_RESULT,
        payload: { results: results, interval: groupInterval },
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CAMERA_EVENTS_GROUPED_TIMESTAMPS_ERROR, payload: error });
  }
}

export function* getCameraAlerts({ payload }) {
  let { uuid, params } = payload;
  const player = yield select((s) => s.player);
  const query = { ...player.alerts.query, ...params };
  try {
    const response = yield call(api.getCameraAlerts, uuid || player.camera.uuid, query);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_CAMERA_ALERTS_RESULT, payload: results });

      let getRange = spreadRange(process.env.REACT_APP_DEFAULT_PLAYER_RANGE);
      let lastItemResult = results.items[results.items.length - 1];
      let lastTimestamp = +moment().subtract(getRange.t, getRange.c).format('x');

      if (lastItemResult.timestamp > lastTimestamp) {
        if (results.total > results.size + results.size * results.page) {
          yield put({
            type: ActionTypes.GET_CAMERA_ALERTS,
            payload: { uuid: payload.uuid, params: { page: results.page + 1 } },
          });
        }
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CAMERA_ALERTS_ERROR, payload: error });
  }
}

export function* getCameraRecordings({ payload, actions = {} }) {
  let { uuid, params } = payload;
  const player = yield select((s) => s.player);
  const query = {
    ...player.events.query,
    from: GTS() - 86400000,
    to: GTS(),
    ...params,
  };
  try {
    const response = yield call(api.getCameraRecordings, uuid || player.camera.uuid, query);
    const { success, results } = response;
    if (success) {
      // yield put({ type: ActionTypes.GET_CAMERA_RECORDINGS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    // yield put({ type: ActionTypes.GET_CAMERA_RECORDINGS_ERROR, payload: error });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* verifyCamera({ payload, actions = {} }) {
  try {
    const response = yield call(api.verifyCamera, payload);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
      if (actions.onComplete) {
        actions.onComplete(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
    if (actions.onComplete) {
      actions.onComplete(error);
    }
  }
}

export function* addCamera({ payload, actions = {} }) {
  try {
    const response = yield call(api.addCamera, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_CAMERA_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_CAMERA_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  } finally {
    if (actions.onComplete) {
      actions.onComplete();
    }
  }
}

export function* addWidget({ payload, actions = {} }) {
  try {
    const response = yield call(api.addWidget, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_DASHBOARD_WIDGET_SUCCESS, payload: payload });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  } finally {
    if (actions.onComplete) {
      actions.onComplete();
    }
  }
}

export function* updateCamera({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateCamera, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_CAMERA_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_CAMERA_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  } finally {
    if (actions.onComplete) {
      actions.onComplete();
    }
  }
}

export function* deleteCamera({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteCamera, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_CAMERA_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_CAMERA_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  } finally {
    if (actions.onComplete) {
      actions.onComplete();
    }
  }
}

export function* deleteWidget({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteWidget, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_WIDGET_SUCCESS, payload: payload });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  } finally {
    if (actions.onComplete) {
      actions.onComplete();
    }
  }
}

export function* getFilters({ payload, actions = {} }) {
  try {
    const response = yield call(api.getFilters, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_FILTERS_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_FILTERS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addFilter({ payload, actions = {} }) {
  try {
    const response = yield call(api.addFilter, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_FILTER_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_FILTER_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getFrames({ payload }) {
  let { uuid, params } = payload;
  const events = yield select((s) => s.events);
  try {
    const response = yield call(api.getCameraFrames, uuid, { ...events.query, ...params });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_CAMERA_FRAMES_RESULT, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CAMERA_FRAMES_ERROR });
  }
}

export function* getFramesNextPage({ payload }) {
  let { uuid } = payload;
  const events = yield select((s) => s.events);
  const next = events.query.page + 1;
  try {
    const response = yield call(api.getCameraFrames, uuid, { ...events.query, page: next });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_CAMERA_FRAMES_NEXT_PAGE_RESULT, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CAMERA_FRAMES_NEXT_PAGE_ERROR });
  }
}

export function* liveStreamDrawerStart({ payload, actions = {} }) {
  try {
    const response = yield call(streamApi.liveStreamDrawerStart, payload);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* liveStreamDrawerStop({ payload, actions = {} }) {
  try {
    const response = yield call(streamApi.liveStreamDrawerStop, payload);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.GET_CAMERA, getCamera),
    takeEvery(ActionTypes.GET_CAMERAS, getCameras),
    takeEvery(ActionTypes.GET_CAMERAS_NEXT_PAGE, getCamerasNextPage),
    takeEvery(ActionTypes.GET_CAMERA_EVENTS, function* (arg) {
      yield race({
        task: call(getCameraEvents, arg),
        cancel: take(ActionTypes.SET_CAMERA_UNMOUNT),
      });
    }),
    takeEvery(ActionTypes.GET_CAMERA_EVENTS_GROUPED_TIMESTAMPS, function* (arg) {
      yield race({
        task: call(getCameraEventsGroupedTimestamps, arg),
        cancel: take(ActionTypes.SET_CAMERA_UNMOUNT),
      });
    }),
    takeEvery(ActionTypes.GET_CAMERA_ALERTS, function* (arg) {
      yield race({
        task: call(getCameraAlerts, arg),
        cancel: take(ActionTypes.SET_CAMERA_UNMOUNT),
      });
    }),
    takeEvery(ActionTypes.GET_CAMERA_RECORDINGS, function* (arg) {
      yield race({
        task: call(getCameraRecordings, arg),
        cancel: take(ActionTypes.SET_CAMERA_UNMOUNT),
      });
    }),
    takeEvery(ActionTypes.VERIFY_CAMERA, verifyCamera),
    takeEvery(ActionTypes.GET_FILTERS, getFilters),
    takeEvery(ActionTypes.ADD_FILTER, addFilter),
    takeEvery(ActionTypes.GET_READY_HEATMAP, getReadyHeatmap),
    takeEvery(ActionTypes.GET_READY_HEATMAP_FOR_QUEUES, getReadyHeatmapForQueues),
    takeEvery(ActionTypes.ADD_CAMERA, addCamera),
    takeEvery(ActionTypes.UPDATE_CAMERA, updateCamera),
    takeEvery(ActionTypes.DELETE_CAMERA, deleteCamera),
    takeEvery(ActionTypes.GET_CAMERA_FRAMES, getFrames),
    takeEvery(ActionTypes.GET_CAMERA_FRAMES_NEXT_PAGE, getFramesNextPage),
    takeEvery(ActionTypes.GET_DASHBOARD_WIDGETS, getWidgets),
    takeEvery(ActionTypes.ADD_DASHBOARD_WIDGET, addWidget),
    takeEvery(ActionTypes.DELETE_WIDGET, deleteWidget),
    takeEvery(ActionTypes.LIFE_STREAM_DRAWER_START, liveStreamDrawerStart),
    takeEvery(ActionTypes.LIFE_STREAM_DRAWER_STOP, liveStreamDrawerStop),
    takeEvery(ActionTypes.GET_DATASTREAM, getDatastream),
  ]);
}
