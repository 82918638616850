import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import i18n from '../../i18n';
import Table from '../../components/Table';
import WorkflowModal from './Modals/WorkflowModal/WorkflowModal';
import AttachCameraModal from './Modals/AttachCameraModal/AttachCameraModal';
import ModifyCameraModal from './Modals/ModifyCameraModal/ModifyCameraModal';
import DetachCameraModal from './Modals/DetachCameraModal/DetachCameraModal';
import ModalDelete from '../../components/ModalDelete';
import InformationModal from '../../components/InformationModal/InformationModal';
import DropdownDotMenu from '../../components/DropdownDotMenu';

import {
  workflowStatuses,
  configStatuses,
  commonStatuses,
  workflowStates,
  workflowSuccessStates,
  workflowErrorStates,
} from '../../constants/workflows.constants';

import * as workflowActions from '../../actions/workflows.action';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormInput } from '../../components/Form';

const WORKFLOW_STARTING_EVENTS = [
  'STARTING_CAMERA_CONSUMER',
  'CAMERA_CONSUMER_STARTED',
  'SAVING_CAMERA_CONFIG',
  'CAMERA_CONFIG_SAVED',
  'CONNECTING_PROCESSING',
  'PROCESSING_CONNECTED',
  'CONNECTING_ANALYTICS',
  'ANALYTICS_CONNECTED',
  'CONNECTING_EVENT_GENERATOR',
  'EVENT_GENERATOR_CONNECTED',
  'CAMERA_STARTED',
  'WORKFLOW_STARTED',
  'WORKFLOW_UPDATED',
];

const WORKFLOW_STOPPING_EVENTS = [
  'DISCONNECTING_EVENT_GENERATOR',
  'EVENT_GENERATOR_DISCONNECTED',
  'DISCONNECTING_ANALYTICS',
  'ANALYTICS_DISCONNECTED',
  'DISCONNECTING_PROCESSING',
  'PROCESSING_DISCONNECTED',
  'DELETING_CAMERA_CONFIG',
  'CAMERA_CONFIG_DELETED',
  'CAMERA_STOPPED',
  'WORKFLOW_STOPPED',
  'WORKFLOW_UPDATED',
];

const WorkflowList = ({
  workflowsItems,
  total,
  size,
  page,
  status,
  type,
  name,
  sortName,
  isLoading,
  loadingAddWorkflow,
  loadingUpdateWorkflow,
  loadingDeleteWorkflow,
  getWorkflows,
  getExistingProcessingTypes,
  addWorkflow,
  updateWorkflow,
  deleteWorkflow,
  addCamerasToWorkflow,
  deleteWorkflowCamera,
  setZonesToWorkflowCamera,
  startWorkflow,
  stopWorkflow,
  changeWorkflowRunningStatus,
  changeCameraRunningStatus,
  changeProcessingRunningStatus,
  changeAnalyticRunningStatus,
  changeTriggerRunningStatus,
  changeWorkflowFilters,
  addWorkflowPreset,
}) => {
  const [modalInfo, setModalInfo] = useState({ id: 0, type: 'create', isOpen: false, errorMsg: '' });
  const [isModalLoading, setModalLoading] = useState(false);
  const [pendingWorkflows, setPendingWorkflows] = useState([]);
  const [processing, setProcessing] = useState([
    { title: 'Type', options: [] },
    { title: 'Status', options: ['Active', 'Inactive'] },
  ]);
  const [firstRender, setFirstRender] = useState(true);

  const [newNotifications, setNotifications] = useState([]);

  useEffect(() => {
    getWorkflows({}, { onSuccess: () => setFirstRender(false) });

    getExistingProcessingTypes({
      onSuccess: (data) =>
        data[0].label
          ? {}
          : setProcessing([
              { name: 'type', title: 'Type', options: data },
              { name: 'status', title: 'Status', options: ['Active', 'Inactive'] },
            ]),
    });
  }, []);

  useEffect(() => {
    getFilteredWorkflows();
  }, [name, type, status, sortName]);

  const mockChangeWorkflowStatus = (workflow, type) => {
    [...(type === 'start' ? WORKFLOW_STARTING_EVENTS : WORKFLOW_STOPPING_EVENTS)].map((action, index) => {
      let event = {};
      if (action === 'WORKFLOW_UPDATED') {
        event = {
          eventType: 'WORKFLOW_UPDATED',
          data: {
            ...workflow,
            started: type === 'start',
            runningStatus: {
              camerasStatus: workflow.runningStatus.camerasStatus.map((cameraStatus) => {
                const cameraStatusUpdated = {
                  ...cameraStatus,
                  status: type === 'start' ? commonStatuses.RUNNING : commonStatuses.STOPPED,
                  processingStatus: type === 'start' ? commonStatuses.RUNNING : commonStatuses.STOPPED,
                  triggersStatus: type === 'start' ? commonStatuses.RUNNING : commonStatuses.STOPPED,
                  analyticsStatus: cameraStatus.analyticsStatus.map((analyticStatus) => {
                    const analyticStatusUpdated = {
                      ...analyticStatus,
                      status: type === 'start' ? commonStatuses.RUNNING : commonStatuses.STOPPED,
                    };
                    return analyticStatusUpdated;
                  }),
                };
                return cameraStatusUpdated;
              }),
            },
          },
        };
      } else {
        event = {
          eventType: 'WORKFLOW_STATE_CHANGED',
          data: {
            action,
            workflow: { name: workflow.name, uuid: workflow.uuid },
            camera: { name: workflow.cameras[0].name, uuid: workflow.cameras[0].uuid },
          },
        };
      }
      setTimeout(() => {
        mockHandleNotification(event);
      }, 250 * index);
    });
  };

  const mockHandleNotification = (event) => {
    switch (event.eventType) {
      case 'WORKFLOW_STATE_CHANGED':
        setNotifications((prev) => [event, ...prev]);
        NotificationManager.info(
          event.data.action.substring(0, 20),
          event.data.workflow?.name + `\n` + event.data.camera?.name,
          3000
        );
        break;

      case 'WORKFLOW_UPDATED':
        changeWorkflowRunningStatus(event.data);
        break;

      case 'CAMERA_STATUS_CHANGED':
        changeCameraRunningStatus(event.data);
        break;

      case 'PROCESSING_STATUS_CHANGED':
        changeProcessingRunningStatus(event.data);
        break;

      case 'ANALYTICS_STATUS_CHANGED':
        changeAnalyticRunningStatus(event.data);
        break;

      case 'EVENT_GENERATOR_STATUS_CHANGED':
        changeTriggerRunningStatus(event.data);
        break;

      default:
    }
  };

  useEffect(() => {
    if (
      newNotifications.length &&
      newNotifications[0].eventType &&
      newNotifications[0].eventType === 'WORKFLOW_STATE_CHANGED'
    ) {
      if (
        !workflowSuccessStates.includes(newNotifications[0].data.action) &&
        !workflowErrorStates.includes(newNotifications[0].data.action)
      ) {
        if (pendingWorkflows.some((workflow) => workflow.uuid === newNotifications[0].data.workflow.uuid)) {
          setPendingWorkflows(
            [...pendingWorkflows].map((workflow) =>
              workflow.uuid === newNotifications[0].data.workflow.uuid
                ? { ...workflow, state: newNotifications[0].data.action }
                : workflow
            )
          );
        } else {
          setPendingWorkflows([
            ...pendingWorkflows,
            { uuid: newNotifications[0].data.workflow.uuid, state: newNotifications[0].data.action },
          ]);
        }
      } else {
        setPendingWorkflows([
          ...pendingWorkflows.filter((workflow) => workflow.uuid !== newNotifications[0].data.workflow.uuid),
        ]);
      }
    }
  }, [newNotifications]);

  useEffect(() => {
    !firstRender && !loadingAddWorkflow && getFilteredWorkflows();
  }, [loadingAddWorkflow]);

  useEffect(() => {
    !firstRender && !loadingUpdateWorkflow && getFilteredWorkflows();
  }, [loadingUpdateWorkflow]);

  useEffect(() => {
    !firstRender && !loadingDeleteWorkflow && getFilteredWorkflows();
  }, [loadingDeleteWorkflow]);

  const addStartHandlers = () => ({
    onSuccess: (res) => {
      openInfoModal(
        'Starting workflow',
        'Your workflow has been successfully started. The status will be updated in a few minutes!'
      );
    },
    onError: (error) => {
      openInfoModal('Starting workflow', `The start of your workflow ended with an error!`, error.message);
    },
  });

  const addStopHandlers = () => ({
    onSuccess: (res) => {
      openInfoModal(
        'Stopping workflow',
        'Your workflow has been successfully stopped. The status will be updated in a few minutes!'
      );
    },
    onError: (error) => {
      openInfoModal('Stopping workflow', 'The stop of your workflow ended with an error!', error.message);
    },
  });

  const getFilteredWorkflows = () => {
    getWorkflows({
      page: 0,
      size: 20,
      name: name,
      processingType: type !== 'All' ? type : null,
      enabled: status !== 'All' ? (status === 'Active' ? true : false) : null,
    });
  };

  const getWorkflowNextPage = (data) => {
    getWorkflows({
      page: data,
      size: 20,
      name: name,
      processingType: type !== 'All' ? type : null,
      enabled: status !== 'All' ? (status === 'Active' ? true : false) : null,
    });
  };

  const openInfoModal = (header, body, errorMsg) => {
    setModalInfo({
      id: 0,
      type: 'info',
      isOpen: true,
      info: {
        header,
        body,
        errorMsg,
      },
    });
  };

  const handleSubmitModal = () => {};

  const handleSubmitAttachModal = () => {
    handleCloseModal();
    getFilteredWorkflows();
  };

  const handleSubmitModifyModal = () => {
    handleCloseModal();
  };

  const handleSubmitDetachModal = (workflowUuid, camerasIds) => {
    if (camerasIds.length) {
      camerasIds.forEach((uuid) => {
        deleteWorkflowCamera(workflowUuid, uuid, { onSuccess: () => getFilteredWorkflows() });
      });
    }

    handleCloseModal();
  };

  const handleDeleteWorkflow = () => {
    deleteWorkflow(modalInfo.id, {});
    handleCloseModal();
  };

  const handleOpenModal = () => {
    setModalInfo({
      type: 'create',
      isOpen: true,
    });
  };

  const handleCloseModal = () => {
    setModalInfo({ id: 0, name: '', info: {}, type: 'create', isOpen: false, errorMsg: '' });
  };

  const checkCameraStatus = (cameraStatus) => {
    let isCameraActive = cameraStatus.status === commonStatuses.RUNNING;
    if (isCameraActive && cameraStatus.processingStatus) {
      if (cameraStatus.processingStatus !== commonStatuses.RUNNING) {
        isCameraActive = false;
      }
    }
    if (isCameraActive && cameraStatus.triggersStatus) {
      if (cameraStatus.triggersStatus !== commonStatuses.RUNNING) {
        isCameraActive = false;
      }
    }
    if (isCameraActive && cameraStatus.analyticsStatus && cameraStatus.analyticsStatus.length) {
      if (cameraStatus.analyticsStatus.some((analytic) => analytic.status !== commonStatuses.RUNNING)) {
        isCameraActive = false;
      }
    }

    return isCameraActive;
  };

  const checkWorkflowStatus = (runningStatus, started) => {
    let workflowStatus = workflowStatuses.INACTIVE;

    if (runningStatus.camerasStatus && runningStatus.camerasStatus.length) {
      if (runningStatus.camerasStatus.every((cameraStatus) => checkCameraStatus(cameraStatus))) {
        workflowStatus = workflowStatuses.ACTIVE;
      } else if (
        runningStatus.camerasStatus.some((cameraStatus) => checkCameraStatus(cameraStatus)) ||
        started
      ) {
        workflowStatus = workflowStatuses.PARTLY;
      }
    }

    return workflowStatus;
  };

  const createRows = (rows) => {
    return rows.map((item) => {
      const workflowStatus = checkWorkflowStatus(item.runningStatus, item.started);

      return {
        id: item.uuid,
        cells: [
          {
            label: item.name,
            cellComponent: <h5 className="table__cell table__cell--bold">{item.name}</h5>,
          },
          {
            label: item.processingConfig && item.processingConfig.type,
          },
          {
            label: item.cameras.length ? (
              <p className="table__cell__list">
                {item.cameras.map((camera, index) => {
                  const cameraStatus = item.runningStatus.camerasStatus.find(
                    (cameraStatus) => cameraStatus.cameraUuid === camera.uuid
                  );

                  return (
                    <>
                      <span className="table__cell__name-indicator">
                        {camera.name}{' '}
                        <FontAwesomeIcon
                          style={{ fontSize: '8px' }}
                          className={checkCameraStatus(cameraStatus) ? 'text-text-active' : 'text-text-error'}
                          icon={faDotCircle}
                        />
                      </span>
                      {index !== item.cameras.length - 1 && ', '}
                    </>
                  );
                })}
              </p>
            ) : (
              'No cameras connected'
            ),
          },
          {
            label: workflowStatus,
            cellComponent: (
              <span
                className={
                  workflowStatus === workflowStatuses.ACTIVE
                    ? 'text-text-active'
                    : workflowStatus === workflowStatuses.PARTLY
                    ? 'text-text-partly'
                    : ''
                }
              >
                {workflowStatus === workflowStatuses.ACTIVE || workflowStatus === workflowStatuses.PARTLY
                  ? workflowStatuses.ACTIVE
                  : pendingWorkflows.some((workflow) => workflow.uuid === item.uuid)
                  ? workflowStatuses.PENDING
                  : workflowStatuses.INACTIVE}{' '}
                {workflowStatus === workflowStatuses.PARTLY && (
                  <FontAwesomeIcon
                    style={{ fontSize: '12px' }}
                    className="text-text-partly"
                    icon={faExclamationCircle}
                  />
                )}
              </span>
            ),
          },
          {
            label: 'menu',
            align: 'right',
            cellComponent: (
              <DropdownDotMenu
                options={[
                  {
                    name: 'Edit',
                    onClick: () => {
                      setModalInfo({
                        id: item.uuid,
                        type: 'edit',
                        isOpen: true,
                        errorMsg: '',
                      });
                    },
                  },
                  { divider: true },
                  {
                    name:
                      workflowStatus === workflowStatuses.ACTIVE || workflowStatus === workflowStatuses.PARTLY
                        ? 'Stop'
                        : 'Start',
                    onClick: () => {
                      if (
                        workflowStatus === workflowStatuses.ACTIVE ||
                        workflowStatus === workflowStatuses.PARTLY
                      ) {
                        stopWorkflow(item.uuid, addStopHandlers());
                        mockChangeWorkflowStatus(item, 'stop');
                        openInfoModal('Stopping workflow', 'Please wait for the result of the stop');
                      } else {
                        openInfoModal('Starting workflow', 'Please wait for the result of the start');
                        startWorkflow(item.uuid, addStartHandlers());
                        mockChangeWorkflowStatus(item, 'start');
                      }
                    },
                    hasBorderBottom: true,
                    disabled:
                      item.configStatus !== configStatuses.READY &&
                      item.configStatus !== configStatuses.STARTED,
                  },
                  { divider: true },
                  {
                    name: 'Attach Cameras',
                    onClick: () => {
                      setModalInfo({
                        id: item.uuid,
                        name: item.name,
                        type: 'attach',
                        isOpen: true,
                        errorMsg: '',
                      });
                    },
                  },
                  {
                    name: 'Modify Cameras',
                    onClick: () => {
                      setModalInfo({
                        id: item.uuid,
                        name: item.name,
                        type: 'modify',
                        isOpen: true,
                        errorMsg: '',
                      });
                    },
                    disabled: !item.cameras.length,
                  },
                  {
                    name: 'Detach Cameras',
                    onClick: () => {
                      setModalInfo({
                        id: item.uuid,
                        name: item.name,
                        type: 'detach',
                        isOpen: true,
                        errorMsg: '',
                      });
                    },
                    disabled: !item.cameras.length,
                  },
                  { divider: true },
                  {
                    name: 'Delete',
                    onClick: () => {
                      setModalInfo({
                        id: item.uuid,
                        name: item.name,
                        type: 'delete',
                        isOpen: true,
                        errorMsg: '',
                      });
                    },
                  },
                  {
                    name: 'Create Preset',
                    onClick: () => {
                      setModalInfo({
                        id: item.uuid,
                        name: null,
                        type: 'preset',
                        isOpen: true,
                      });
                    },
                  },
                ]}
              />
            ),
          },
        ],
      };
    });
  };

  const headerOptions = [
    {
      label: 'Name',
      sortable: true,
      onSortClick: () => changeWorkflowFilters('sortName', sortName === 'desc' ? 'asc' : 'desc'),
    },
    {
      label: 'Processing type',
    },
    {
      label: 'Cameras',
    },
    {
      label: 'Status',
    },
    {
      label: '',
    },
  ];

  return (
    <>
      <Table
        rows={createRows(workflowsItems)}
        headerOptions={headerOptions}
        title={i18n.t('nav:workflow')}
        onClick={() => handleOpenModal('create')}
        totalRows={total}
        size={size}
        page={page}
        search={true}
        searchSubmit={(value) => changeWorkflowFilters('name', value)}
        isLoading={isLoading}
        filters={processing}
        onChangeFilters={(title, key) => changeWorkflowFilters(title.toLowerCase(), key)}
        disabled={false}
        changePage={(data) => getWorkflowNextPage(data)}
      />

      <NotificationContainer enterTimeout={400} leaveTimeout={400} />
      {modalInfo.isOpen && (modalInfo.type === 'create' || modalInfo.type === 'edit') && (
        <WorkflowModal
          isOpen={modalInfo.isOpen}
          type={modalInfo.type}
          title={
            modalInfo.type === 'create'
              ? i18n.t('workflowModals:addTitle')
              : i18n.t('workflowModals:editTitle')
          }
          className="modal-workflow"
          workflowUuid={modalInfo.id}
          onSubmit={handleSubmitModal}
          onClose={handleCloseModal}
          isLoading={isModalLoading}
          errorMsg={modalInfo.errorMsg}
          addWorkflow={addWorkflow}
          updateWorkflow={updateWorkflow}
          deleteWorkflow={deleteWorkflow}
          checkWorkflowStatus={checkWorkflowStatus}
        />
      )}
      {modalInfo.isOpen && modalInfo.type === 'attach' && (
        <AttachCameraModal
          title={i18n.t('workflowModals:attachTitle', { workflowName: modalInfo.name })}
          workflowUuid={modalInfo.id}
          isOpen={modalInfo.isOpen}
          className="modal-attach-camera"
          onSubmit={handleSubmitAttachModal}
          onClose={handleCloseModal}
          isLoading={isModalLoading}
          errorMsg={modalInfo.errorMsg}
        />
      )}
      {modalInfo.isOpen && modalInfo.type === 'preset' && (
        <Modal isOpen={modalInfo.isOpen} fade>
          <ModalHeader toggle={handleCloseModal}>Create Preset</ModalHeader>
          <ModalBody>
            <div className="form-row_mb-10">
              <FormInput
                type="text"
                label={i18n.t('workflowModals:addPreset')}
                value={modalInfo.name}
                className="form-item form-item--one"
                onChange={(name) =>
                  setModalInfo({
                    id: modalInfo.id,
                    name: name,
                    type: 'preset',
                    isOpen: true,
                  })
                }
                errorMessage={false}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn--dark" onClick={handleCloseModal}>
              {i18n.t('buttons:cancel')}
            </button>
            <button
              className="btn btn--secondary"
              onClick={() => {
                if (modalInfo.name !== null) {
                  addWorkflowPreset({ name: modalInfo.name, workflowUuid: modalInfo.id });
                  handleCloseModal();
                }
              }}
            >
              {i18n.t('buttons:ok')}
            </button>
          </ModalFooter>
        </Modal>
      )}
      {modalInfo.isOpen && modalInfo.type === 'modify' && (
        <ModifyCameraModal
          title={i18n.t('workflowModals:modifyTitle', { workflowName: modalInfo.name })}
          workflowUuid={modalInfo.id}
          isOpen={modalInfo.isOpen}
          className="modal-detach-camera"
          onSubmit={handleSubmitModifyModal}
          onClose={handleCloseModal}
          isLoading={isModalLoading}
          errorMsg={modalInfo.errorMsg}
        />
      )}
      {modalInfo.isOpen && modalInfo.type === 'detach' && (
        <DetachCameraModal
          title={i18n.t('workflowModals:detachTitle', { workflowName: modalInfo.name })}
          workflowUuid={modalInfo.id}
          isOpen={modalInfo.isOpen}
          className="modal-detach-camera"
          onSubmit={handleSubmitDetachModal}
          onClose={handleCloseModal}
          isLoading={isModalLoading}
          errorMsg={modalInfo.errorMsg}
        />
      )}
      {modalInfo.isOpen && modalInfo.type === 'delete' && (
        <ModalDelete
          loading={isModalLoading}
          isOpen={modalInfo.isOpen}
          errorMsg={modalInfo.errorMsg}
          header={i18n.t('workflowModals:deleteTitle')}
          body={i18n.t('workflowModals:deleteBody', { workflowName: modalInfo.name })}
          onClose={handleCloseModal}
          onDelete={handleDeleteWorkflow}
        />
      )}
      {modalInfo.isOpen && modalInfo.type === 'info' && (
        <InformationModal
          isOpen={modalInfo.isOpen}
          header={modalInfo.info.header}
          body={modalInfo.info.body}
          errorMsg={modalInfo.info.errorMsg}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    list: { items: workflowsItems, total },
    query: { size, page },
    loading: { getWorkflows: isLoading },
    loadingAddWorkflow,
    loadingUpdateWorkflow,
    loadingDeleteWorkflow,
    status,
    type,
    name,
    sortName,
  } = state.workflows;
  return {
    workflowsItems,
    total,
    size,
    page,
    status,
    type,
    name,
    sortName,
    isLoading,
    loadingAddWorkflow,
    loadingUpdateWorkflow,
    loadingDeleteWorkflow,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getWorkflows: (params, actions) => dispatch(workflowActions.getWorkflows(params, actions)),
  addWorkflow: (params, actions) => dispatch(workflowActions.addWorkflow(params, actions)),
  updateWorkflow: (uuid, params, actions) => dispatch(workflowActions.updateWorkflow(uuid, params, actions)),
  deleteWorkflow: (params, actions) => dispatch(workflowActions.deleteWorkflow(params, actions)),
  addCamerasToWorkflow: (uuid, params, actions) =>
    dispatch(workflowActions.addCamerasToWorkflow(uuid, params, actions)),
  deleteWorkflowCamera: (workflowUuid, cameraUuid, actions) =>
    dispatch(workflowActions.deleteWorkflowCamera(workflowUuid, cameraUuid, actions)),
  setZonesToWorkflowCamera: (workflowUuid, cameraUuid, params, actions) =>
    dispatch(workflowActions.setZonesToWorkflowCamera(workflowUuid, cameraUuid, params, actions)),
  startWorkflow: (uuid, actions) => dispatch(workflowActions.startWorkflow(uuid, actions)),
  stopWorkflow: (uuid, actions) => dispatch(workflowActions.stopWorkflow(uuid, actions)),
  getExistingProcessingTypes: (actions) => dispatch(workflowActions.getExistingProcessingTypes(actions)),
  changeWorkflowRunningStatus: (data) => dispatch(workflowActions.changeWorkflowRunningStatus(data)),
  changeCameraRunningStatus: (data) => dispatch(workflowActions.changeCameraRunningStatus(data)),
  changeProcessingRunningStatus: (data) => dispatch(workflowActions.changeProcessingRunningStatus(data)),
  changeAnalyticRunningStatus: (data) => dispatch(workflowActions.changeAnalyticRunningStatus(data)),
  changeTriggerRunningStatus: (data) => dispatch(workflowActions.changeTriggerRunningStatus(data)),
  changeWorkflowFilters: (key, value) => dispatch(workflowActions.changeWorkflowFilters(key, value)),
  addWorkflowPreset: (params, actions) => dispatch(workflowActions.addWorkflowPreset(params, actions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowList);
