export const GET_SUBJECTS = 'GET_SUBJECTS';
export const GET_SUBJECTS_RESULT = 'GET_SUBJECTS_RESULT';
export const GET_SUBJECTS_ERROR = 'GET_SUBJECTS_ERROR';

export const GET_SUBJECT = 'GET_SUBJECT';
export const GET_SUBJECT_SUCCESS = 'GET_SUBJECT_SUCCESS';
export const GET_SUBJECT_ERROR = 'GET_SUBJECT_ERROR';

export const GET_SUBJECT_EVENTS = 'GET_SUBJECT_EVENTS';
export const GET_SUBJECT_EVENTS_RESULT = 'GET_SUBJECT_EVENTS_RESULT';
export const GET_SUBJECT_EVENTS_ERROR = 'GET_SUBJECT_EVENTS_ERROR';

export const GET_SUBJECT_EVENTS_NEXT_PAGE = 'GET_SUBJECT_EVENTS_NEXT_PAGE';
export const GET_SUBJECT_EVENTS_NEXT_PAGE_RESULT = 'GET_SUBJECT_EVENTS_NEXT_PAGE_RESULT';
export const GET_SUBJECT_EVENTS_NEXT_PAGE_ERROR = 'GET_SUBJECT_EVENTS_NEXT_PAGE_ERROR';

export const ADD_SUBJECT = 'ADD_SUBJECT';
export const ADD_SUBJECT_SUCCESS = 'ADD_SUBJECT_SUCCESS';
export const ADD_SUBJECT_ERROR = 'ADD_SUBJECT_ERROR';

export const UPDATE_SUBJECT = 'UPDATE_SUBJECT';
export const UPDATE_SUBJECT_SUCCESS = 'UPDATE_SUBJECT_SUCCESS';
export const UPDATE_SUBJECT_ERROR = 'UPDATE_SUBJECT_ERROR';

export const DELETE_SUBJECT = 'DELETE_SUBJECT';
export const DELETE_SUBJECT_SUCCESS = 'DELETE_SUBJECT_SUCCESS';
export const DELETE_SUBJECT_ERROR = 'DELETE_SUBJECT_ERROR';

export const RESET_SUBJECT_STATE = 'RESET_SUBJECT_STATE';
export const RESET_SUBJECT_EVENTS_STATE = 'RESET_SUBJECT_EVENTS_STATE';

export const SET_TEXT_SEARCH_SUBJECTS = 'SET_TEXT_SEARCH_SUBJECTS';
export const SET_NUMBER_PAGE_SUBJECTS = 'SET_NUMBER_PAGE_SUBJECTS';

export const SUBJECT_TYPE_KNOWN = 'KNOWN';
export const SUBJECT_SEX_MALE = 'MALE';
export const SUBJECT_SEX_FEMALE = 'FEMALE';
export const SUBJECT_SEX_OTHER = 'OTHER';

export const personsSex = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
};

export const GET_PERSONS = 'GET_PERSONS';
export const GET_PERSONS_RESULT = 'GET_PERSONS_RESULT';
export const GET_PERSONS_ERROR = 'GET_PERSONS_ERROR';

export const ADD_PERSON = 'ADD_PERSON';
export const ADD_PERSON_SUCCESS = 'ADD_PERSON_SUCCESS';
export const ADD_PERSON_ERROR = 'ADD_PERSON_ERROR';

export const GET_PERSON = 'GET_PERSON';
export const GET_PERSON_SUCCESS = 'GET_PERSON_SUCCESS';
export const GET_PERSON_ERROR = 'GET_PERSON_ERROR';

export const UPDATE_PERSON = 'UPDATE_PERSON';
export const UPDATE_PERSON_SUCCESS = 'UPDATE_PERSON_SUCCESS';
export const UPDATE_PERSON_ERROR = 'UPDATE_PERSON_ERROR';

export const DELETE_PERSON = 'DELETE_PERSON';
export const DELETE_PERSON_SUCCESS = 'DELETE_PERSON_SUCCESS';
export const DELETE_PERSON_ERROR = 'DELETE_PERSON_ERROR';

export const GET_PERSON_EVENTS = 'GET_PERSON_EVENTS';
export const GET_PERSON_EVENTS_RESULT = 'GET_PERSON_EVENTS_RESULT';
export const GET_PERSON_EVENTS_ERROR = 'GET_PERSON_EVENTS_ERROR';

export const GET_PERSON_EVENTS_NEXT_PAGE = 'GET_PERSON_EVENTS_NEXT_PAGE';
export const GET_PERSON_EVENTS_NEXT_PAGE_RESULT = 'GET_PERSON_EVENTS_NEXT_PAGE_RESULT';
export const GET_PERSON_EVENTS_NEXT_PAGE_ERROR = 'GET_PERSON_EVENTS_NEXT_PAGE_ERROR';

export const RESET_PERSON_STATE = 'RESET_PERSON_STATE';
export const RESET_PERSON_EVENTS_STATE = 'RESET_PERSON_EVENTS_STATE';

export const SET_TEXT_SEARCH_PERSONS = 'SET_TEXT_SEARCH_PERSONS';
export const SET_NUMBER_PAGE_PERSONS = 'SET_NUMBER_PAGE_PERSONS';

export const GET_CARS = 'GET_CARS';
export const GET_CARS_RESULT = 'GET_CARS_RESULT';
export const GET_CARS_ERROR = 'GET_CARS_ERROR';

export const ADD_CAR = 'ADD_CAR';
export const ADD_CAR_SUCCESS = 'ADD_CAR_SUCCESS';
export const ADD_CAR_ERROR = 'ADD_CAR_ERROR';

export const GET_CAR = 'GET_CAR';
export const GET_CAR_SUCCESS = 'GET_CAR_SUCCESS';
export const GET_CAR_ERROR = 'GET_CAR_ERROR';

export const UPDATE_CAR = 'UPDATE_CAR';
export const UPDATE_CAR_SUCCESS = 'UPDATE_CAR_SUCCESS';
export const UPDATE_CAR_ERROR = 'UPDATE_CAR_ERROR';

export const DELETE_CAR = 'DELETE_CAR';
export const DELETE_CAR_SUCCESS = 'DELETE_CAR_SUCCESS';
export const DELETE_CAR_ERROR = 'DELETE_CAR_ERROR';

export const RESET_CAR_STATE = 'RESET_CAR_STATE';
