import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { WATCH } from '../../../constants/routes.constants';
// import Search from '../../../components/Search';
import ModalAdd from './Modals/ModalAdd';
import ModalEdit from './Modals/ModalEdit';
import ModalDelete from '../../../components/ModalDelete';
import {
  getCameras,
  verifyCamera,
  addCamera,
  updateCamera,
  deleteCamera,
} from '../../../actions/cameras.actions';
import { isArrayLength } from '../../../utils';
import Loader from '../../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModelsService from '../../../services/ModelsService';

const MODAL_ADD = 'modalAdd';
const MODAL_EDIT = 'modalEdit';
const MODAL_DELETE = 'modalDelete';
const defaultCamera = {
  name: '',
  location: '',
  streamUrl: '',
  fps: 5
};
const defaultModal = {
  isOpen: false,
  errorMsg: '',
  camera: {},
  resetError: function() {
    this.errorMsg = '';
  },
};

class Settings extends Component {
  state = {
    modalAdd: { ...defaultModal },
    modalEdit: { ...defaultModal },
    modalDelete: { ...defaultModal },
  };

  componentDidMount() {
    this.props.getCameras();
  }

  onSearch = name => {
    //todo - need api
    //this.props.getCameras({ name });
  };

  addCamera = params => {
    this.props.addCamera(params, this.getModalRequestActions(MODAL_ADD));
  };

  updateCamera = params => {
    this.props.updateCamera(
      { ...params, uuid: this.state.modalEdit.camera.uuid },
      this.getModalRequestActions(MODAL_EDIT)
    );
  };

  deleteCamera = () => {
    this.props.deleteCamera(this.state.modalDelete.camera.uuid, this.getModalRequestActions(MODAL_DELETE));
  };

  toggleModal = (modalName, camera) => {
    this.setState({
      [modalName]: {
        ...this.state[modalName],
        isOpen: !this.state[modalName].isOpen,
        camera,
      },
    });
  };

  setModalError = (modalName, error) => {
    this.setState({
      [modalName]: {
        ...this.state[modalName],
        errorMsg: error.message,
      },
    });
  };

  getModalRequestActions = modalName => {
    return {
      onSuccess: () => {
        this.closeModal(modalName);
        this.props.getCameras();
      },
      onError: error => this.setModalError(modalName, error),
    };
  };

  closeModal = modalName => {
    this.setState({ [modalName]: { ...defaultModal } });
  };

  render() {
    const {
      list,
      isLoading,
      loadingAddCamera,
      loadingUpdateCamera,
      loadingDeleteCamera,
      verifyCamera,
    } = this.props;
    const { modalAdd, modalEdit, modalDelete } = this.state;
    return (
      <div className="mt-20 w-100">
        <Loader loading={isLoading} />
        <div className="head-page-list">
          <button
            className="btn btn--secondary"
            onClick={() => this.toggleModal('modalAdd', { ...defaultCamera })}
          >
            {i18n.t('settings:buttonAddCamera')}
          </button>
          {/* TODO: https://jira.softarex.com/browse/LUN-167 */}
          {/* <Search onSearch={this.onSearch} /> */}
          <div className="pagination">1 of {list.length}</div>
        </div>
        <div className="wrap-settings-camera-list">
          <ul className="list settings-camera-list">
            <li className="settings-camera-list-element">
              <div className="settings-camera-list-name">{i18n.t('settings:tableLabelCameraName')}</div>
              <div className="settings-camera-list-location">{i18n.t('settings:tableLabelLocation')}</div>
            </li>
            {isArrayLength(list) &&
              list.map(camera => (
                <li key={camera.uuid} className="settings-camera-list-element">
                  <div className="settings-camera-list-name">
                    <FontAwesomeIcon icon="video" className="mr-10" />
                    <Link to={`${WATCH}/${camera.uuid}`}>{camera.name}</Link>
                  </div>
                  <div className="settings-camera-list-location">
                    <FontAwesomeIcon icon="map-marker-alt" className="mr-10" />
                    {camera.location}
                  </div>
                  <div className="settings-camera-list-btn-group">
                    <button
                      onClick={() => this.toggleModal(MODAL_EDIT, camera)}
                      className="btn--transparent mr-10"
                    >
                      <FontAwesomeIcon icon="pen" />
                    </button>
                    <button
                      onClick={() => this.toggleModal(MODAL_DELETE, camera)}
                      className="btn--transparent"
                    >
                      <FontAwesomeIcon icon="trash-alt" />
                    </button>
                  </div>
                </li>
              ))}
          </ul>
        </div>
        {modalAdd.isOpen && (
          <ModalAdd
            loading={loadingAddCamera}
            params={modalAdd}
            onClose={() => this.closeModal(MODAL_ADD)}
            onSubmit={this.addCamera}
            verifyCamera={verifyCamera}
            className="modal-settings"
          />
        )}
        {modalEdit.isOpen && (
          <ModalEdit
            loading={loadingUpdateCamera}
            params={modalEdit}
            onClose={() => this.closeModal(MODAL_EDIT)}
            onSubmit={this.updateCamera}
            verifyCamera={verifyCamera}
            className="modal-settings"
          />
        )}
        {modalDelete.isOpen && (
          <ModalDelete
            loading={loadingDeleteCamera}
            isOpen={modalDelete.isOpen}
            errorMsg={modalDelete.errorMsg}
            header={i18n.t('cameraModals:deleteTitle')}
            body={i18n.t('cameraModals:deleteBody', { cameraName: modalDelete.camera.name })}
            onClose={() => this.closeModal(MODAL_DELETE)}
            onDelete={this.deleteCamera}
          />
        )}
      </div>
    );
  }
}

Settings.propTypes = {
  list: PropTypes.arrayOf(ModelsService.getCameraModel()),
  isLoading: PropTypes.bool,
  loadingAddCamera: PropTypes.bool,
  loadingUpdateCamera: PropTypes.bool,
  loadingDeleteCamera: PropTypes.bool,
  getCameras: PropTypes.func,
  verifyCamera: PropTypes.func,
  addCamera: PropTypes.func,
  updateCamera: PropTypes.func,
  deleteCamera: PropTypes.func,
};

const mapStateToProps = state => {
  const {
    list: { items },
    loading: { getCameras },
    loadingAddCamera,
    loadingUpdateCamera,
    loadingDeleteCamera,
  } = state.cameras;
  return {
    list: items,
    isLoading: getCameras,
    loadingAddCamera,
    loadingUpdateCamera,
    loadingDeleteCamera,
  };
};

const mapDispatchToProps = dispatch => ({
  getCameras: params => dispatch(getCameras(params)),
  verifyCamera: (url, actions) => dispatch(verifyCamera(url, actions)),
  addCamera: (params, actions) => dispatch(addCamera(params, actions)),
  updateCamera: (params, actions) => dispatch(updateCamera(params, actions)),
  deleteCamera: (uuid, actions) => dispatch(deleteCamera(uuid, actions)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
