import * as ActionTypes from '../constants/locations.constant';

export const getLocations = (params, actions) => ({
  type: ActionTypes.GET_LOCATIONS,
  payload: params,
  actions,
});

export const getLocation = (uuid, actions) => ({
  type: ActionTypes.GET_LOCATION,
  payload: { uuid },
  actions,
});

export const addLocation = (params, actions) => ({
  type: ActionTypes.ADD_LOCATION,
  payload: { params },
  actions,
});

export const updateLocation = (uuid, params, actions) => ({
  type: ActionTypes.UPDATE_LOCATION,
  payload: { uuid, params },
  actions,
});

export const deleteLocation = (uuid, actions) => ({
  type: ActionTypes.DELETE_LOCATION,
  payload: { uuid },
  actions,
});
