import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import UploadPhotos from '../../../../components/UploadPhotos';
import * as actions from '../../../../actions/subject.actions';
import i18n from '../../../../i18n';

class SubjectPhotos extends Component {
  render() {
    const { subject, updateSubject, getCallback } = this.props;
    return (
      <div className="file-upload">
        <label>
          {i18n.t('imageUploader:labelPhotos')}
          <button type="button" className="btn btn--link" id="photos-requirements">
            <FontAwesomeIcon icon="info-circle" />
            {i18n.t('imageUploader:labelRequirements')}
          </button>
          <UncontrolledPopover trigger="legacy" placement="bottom-start" target="photos-requirements">
            <PopoverBody>
              <p>{i18n.t('imageUploader:requirementsTooltipPartOne')}</p>
              <p>{i18n.t('imageUploader:requirementsTooltipPartTwo')}</p>
              <p>{i18n.t('imageUploader:requirementsTooltipPartThree')}</p>
              <p>{i18n.t('imageUploader:requirementsTooltipPartFour')}</p>
            </PopoverBody>
          </UncontrolledPopover>
        </label>
        <UploadPhotos subject={subject} get={getCallback} update={updateSubject} />
      </div>
    );
  }
}

SubjectPhotos.propTypes = {
  updateSubject: PropTypes.func,
  getCallback: PropTypes.func,
  subject: PropTypes.object,
};

const mapStateToProps = ({ subjectView }) => {
  return { subject: subjectView.subject.subject };
};

const mapDispatchToProps = dispatch => ({
  updateSubject: (params, reqActions) => dispatch(actions.updateSubject(params, reqActions)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubjectPhotos);
