export function createArrowPath(fromX, fromY, toX, toY) {
  const arrowPath = new Path2D();

  const headlen = 10;
  const angle = Math.atan2(toY - fromY, toX - fromX);

  arrowPath.moveTo(fromX, fromY);
  arrowPath.lineTo(toX, toY);

  arrowPath.moveTo(toX, toY);
  arrowPath.lineTo(
    toX - headlen * Math.cos(angle - Math.PI / 7),
    toY - headlen * Math.sin(angle - Math.PI / 7)
  );

  arrowPath.lineTo(
    toX - headlen * Math.cos(angle + Math.PI / 7),
    toY - headlen * Math.sin(angle + Math.PI / 7)
  );

  arrowPath.lineTo(toX, toY);
  arrowPath.lineTo(
    toX - headlen * Math.cos(angle - Math.PI / 7),
    toY - headlen * Math.sin(angle - Math.PI / 7)
  );

  return arrowPath;
}

export function createPolygonPath(points, potentialPoint) {
  const polygonPath = new Path2D();

  points.forEach((point, index) => {
    if (index === 0) {
      polygonPath.moveTo(point.x, point.y);
    } else {
      polygonPath.lineTo(point.x, point.y);
    }
  });

  if (potentialPoint && potentialPoint.x) {
    polygonPath.lineTo(potentialPoint.x, potentialPoint.y);
    polygonPath.lineTo(points[0].x, points[0].y);
    if (isNearPoint(potentialPoint.x, potentialPoint.y, points[0].x, points[0].y)) {
      polygonPath.arc(points[0].x, points[0].y, 5, 0, 2 * Math.PI);
    }
  }

  return polygonPath;
}

export function createDoublePolygonPath(points, entrypoint, potentialPoint) {
  const polygonPath = new Path2D();

  points.forEach((point, index) => {
    if (index === 0) {
      polygonPath.moveTo(point.x, point.y);
    } else {
      polygonPath.lineTo(point.x, point.y);
    }
  });

  entrypoint.forEach((point, index) => {
    if (index === 0) {
      polygonPath.moveTo(point.x, point.y);
    } else {
      polygonPath.lineTo(point.x, point.y);
    }
  });

  if (potentialPoint && potentialPoint.x) {
    polygonPath.lineTo(potentialPoint.x, potentialPoint.y);

    if (entrypoint.length) {
      polygonPath.lineTo(entrypoint[0].x, entrypoint[0].y);
      if (isNearPoint(potentialPoint.x, potentialPoint.y, entrypoint[0].x, entrypoint[0].y)) {
        polygonPath.arc(entrypoint[0].x, entrypoint[0].y, 5, 0, 2 * Math.PI);
      }
    } else {
      polygonPath.lineTo(points[0].x, points[0].y);
      if (isNearPoint(potentialPoint.x, potentialPoint.y, points[0].x, points[0].y)) {
        polygonPath.arc(points[0].x, points[0].y, 5, 0, 2 * Math.PI);
      }
    }
  }
  return polygonPath;
}

export function createRectanglePath(x1, y1, width, height) {
  const rectanglePath = new Path2D();
  rectanglePath.rect(x1, y1, width, height);

  return rectanglePath;
}

export function createLineWithArrowPath(x1, y1, x2, y2) {
  const linePath = new Path2D();
  linePath.moveTo(x1, y1);
  linePath.lineTo(x2, y2);

  const centralPoint = calculateCentralPoint(x1, y1, x2, y2);
  const distantPoint = calculateDistantPoint(x1, y1, x2, y2, 30);

  linePath.addPath(createArrowPath(centralPoint.x, centralPoint.y, distantPoint.x, distantPoint.y));

  return linePath;
}

export const isNearPoint = (x, y, x1, y1) => {
  return Math.abs(x - x1) < 5 && Math.abs(y - y1) < 5;
};

export const isWithinElement = (x, y, element) => {
  const { type, x1, x2, y1, y2 } = element;
  if (type === 'rectangle') {
    const minX = Math.min(x1, x2);
    const maxX = Math.max(x1, x2);
    const minY = Math.min(y1, y2);
    const maxY = Math.max(y1, y2);
    return x >= minX && x <= maxX && y >= minY && y <= maxY;
  } else if (type === 'polygon') {
    return element.points.some((point, index) => {
      if (index === element.points.length - 1) {
      } else {
        return isPointBetween(
          x,
          y,
          point.x,
          point.y,
          element.points[index + 1].x,
          element.points[index + 1].y
        );
      }
    });
  } else if (type === 'doublePolygon') {
    return (
      element.points.some((point, index) => {
        if (index === element.points.length - 1) {
        } else {
          return isPointBetween(
            x,
            y,
            point.x,
            point.y,
            element.points[index + 1].x,
            element.points[index + 1].y
          );
        }
      }) ||
      element.entrypoint.some((point, index) => {
        if (index === element.points.length - 1) {
        } else {
          return isPointBetween(
            x,
            y,
            point.x,
            point.y,
            element.points[index + 1].x,
            element.points[index + 1].y
          );
        }
      })
    );
  } else {
    return isPointBetween(x, y, x1, y1, x2, y2);
  }
};

export const isPointBetween = (x, y, x1, y1, x2, y2) => {
  const a = { x: x1, y: y1 };
  const b = { x: x2, y: y2 };
  const c = { x, y };
  const offset = distance(a, b) - (distance(a, c) + distance(b, c));
  return Math.abs(offset) < 1;
};

export const distance = (a, b) => Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));

export const calculateCentralPoint = (x1, y1, x2, y2) => {
  const point = {};

  const ABx = x2 - x1;
  const ABy = y2 - y1;

  point.x = x1 + ABx / 2;
  point.y = y1 + ABy / 2;

  return point;
};

export const calculateDistantPoint = (x1, y1, x2, y2, l) => {
  const point = {};

  const C = calculateCentralPoint(x1, y1, x2, y2);

  const ABx = x2 - x1;
  const ABy = y2 - y1;
  const ABLength = Math.sqrt(ABx * ABx + ABy * ABy);

  const NABx = ABx / ABLength;
  const NABy = ABy / ABLength;

  const PNABx = -NABy;
  const PNABy = NABx;

  point.x = C.x + l * PNABx;
  point.y = C.y + l * PNABy;

  return point;
};

export const getElementIndexAtPosition = (x, y, elements) => {
  return elements.findIndex((element) => isWithinElement(x, y, element));
};

export function createDoublePolygonElement(
  name,
  points,
  entrypoint,
  potentialPoint,
  type,
  clientWidth,
  clientHeight
) {
  const relPoints = points.map((point) => ({ x: point.x / clientWidth, y: point.y / clientHeight }));
  const relEntrypoint = entrypoint.map((point) => ({ x: point.x / clientWidth, y: point.y / clientHeight }));
  const figurePath = createDoublePolygonPath(points, entrypoint, potentialPoint);
  return { name, points, entrypoint, type, figurePath, relPoints, relEntrypoint };
}

export function createPolygonElement(name, points, potentialPoint, type, clientWidth, clientHeight) {
  const relPoints = points.map((point) => ({ x: point.x / clientWidth, y: point.y / clientHeight }));
  const figurePath = createPolygonPath(points, potentialPoint);
  return { name, points, type, figurePath, relPoints };
}

export function createElement(name, x1, y1, x2, y2, type, clientWidth, clientHeight) {
  const relative = {
    x1: x1 / clientWidth,
    y1: y1 / clientHeight,
    x2: x2 / clientWidth,
    y2: y2 / clientHeight,
  };
  let normal = {};

  if (type === 'line') {
    const distantPoint = calculateDistantPoint(x1, y1, x2, y2, 30);

    normal = {
      x: distantPoint.x / clientWidth,
      y: distantPoint.y / clientHeight,
    };
  }

  const figurePath =
    type === 'line' ? createLineWithArrowPath(x1, y1, x2, y2) : createRectanglePath(x1, y1, x2 - x1, y2 - y1);

  return type === 'line'
    ? { name, x1, y1, x2, y2, type, figurePath, relative, normal }
    : { name, x1, y1, x2, y2, type, figurePath, relative };
}
