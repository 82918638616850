import { takeEvery, call, put, select, all } from 'redux-saga/effects';

import * as ActionTypes from '../constants/users.constants';
import * as api from '../api/users';

export function* getUsers({ payload, actions = {} }) {
  const users = yield select((s) => s.users);
  try {
    const response = yield call(api.getUsers, { ...users.query, ...payload });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_USERS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USERS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getUser({ payload, actions = {} }) {
  try {
    const response = yield call(api.getUser, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_USER_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_USER_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getMe(actions = {}) {
  try {
    const response = yield call(api.getMe);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_ME_RESULT, payload: results });
      actions.onSuccess(results);
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ME_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* changePassword({ payload, actions = {} }) {
  try {
    const response = yield call(api.changePassword, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.CHANGE_PASSWORD_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.CHANGE_PASSWORD_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addUser({ payload, actions = {} }) {
  try {
    const response = yield call(api.addUser, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_USER_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_USER_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* uploadMedia({ payload, actions = {} }) {
  try {
    const response = yield call(api.uploadMedia, payload);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateUser({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateUser, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_USER_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_USER_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateMe({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateMe, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_USER_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_USER_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteUser({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteUser, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_USER_SUCCESS, payload: payload });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_USER_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addRolesToUser({ payload, actions = {} }) {
  try {
    const response = yield call(api.addRolesToUser, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}
export function* updateRolesToUser({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateRolesToUser, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteRoleToUser({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteRoleToUser, payload.uuid, payload.roleUuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateEmail({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateEmail, payload);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* confirmEmail({ payload, actions = {} }) {
  try {
    const response = yield call(api.confirmEmail, payload);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* resendEmail({ actions = {} }) {
  try {
    const response = yield call(api.resendEmail);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* usersSagas() {
  yield all([
    takeEvery(ActionTypes.GET_USERS, getUsers),
    takeEvery(ActionTypes.GET_USER, getUser),
    takeEvery(ActionTypes.GET_ME, getMe),
    takeEvery(ActionTypes.CHANGE_PASSWORD, changePassword),
    takeEvery(ActionTypes.ADD_USER, addUser),
    takeEvery(ActionTypes.UPLOAD_MEDIA, uploadMedia),
    takeEvery(ActionTypes.UPDATE_USER, updateUser),
    takeEvery(ActionTypes.UPDATE_ME, updateMe),
    takeEvery(ActionTypes.DELETE_USER, deleteUser),
    takeEvery(ActionTypes.ADD_ROLES_TO_USER, addRolesToUser),
    takeEvery(ActionTypes.UPDATE_ROLES_TO_USER, updateRolesToUser),
    takeEvery(ActionTypes.DELETE_ROLE_TO_USER, deleteRoleToUser),
    takeEvery(ActionTypes.UPDATE_EMAIL, updateEmail),
    takeEvery(ActionTypes.CONFIRM_EMAIL, confirmEmail),
    takeEvery(ActionTypes.RESEND_EMAIL, resendEmail),
  ]);
}
