import * as ActionTypes from '../constants/cameras.constants';

const initialState = {
  list: {
    items: [],
    total: 0,
  },
  Location: 'All',
  Status: 'All',
  locations: [],
  query: {
    size: 20,
    page: 0,
  },
  screencasts: [],
  live: [],
  loading: {
    getCameras: false,
    nextPage: false,
  },
  camerasFilters: {
    name: '',
    sortName: 'name',
    sortType: 'asc',
    status: 'All',
  },
  loadingAddCamera: false,
  loadingUpdateCamera: false,
  loadingDeleteCamera: false,
  widgets: [],
  loadingWidgets: false,
  subscribeWidgets: [],
  activeWidget: [],
  accumWidgets: [],
  activeAccumWidget: [],
  addedWidget: '',
  InOutWidget: [],
  queues: [],
  activities: [],
  accumActivities: [],
  heatmapImage: [],
};

export const camerasReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CAMERAS:
      return {
        ...state,
        loading: {
          ...state.loading,
          getCameras: true,
        },
      };

    case ActionTypes.ADD_CAMERA:
      return {
        ...state,
        loadingAddCamera: true,
      };

    case ActionTypes.UPDATE_CAMERA:
      return {
        ...state,
        loadingUpdateCamera: true,
      };

    case ActionTypes.DELETE_CAMERA:
      return {
        ...state,
        loadingDeleteCamera: true,
      };

    case ActionTypes.GET_CAMERAS_RESULT:
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items, total },
        query: { size, page },
        loading: {
          ...state.loading,
          getCameras: false,
        },
        loadingAddCamera: false,
        loadingUpdateCamera: false,
        loadingDeleteCamera: false,
      };

    case ActionTypes.GET_CAMERAS_NEXT_PAGE:
      return {
        ...state,
        loading: {
          ...state.loading,
          nextPage: true,
        },
      };

    case ActionTypes.GET_CAMERAS_NEXT_PAGE_RESULT: {
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items: [...state.list.items, ...items], total },
        query: { size, page },
        loading: {
          ...state.loading,
          nextPage: false,
        },
      };
    }

    case ActionTypes.GET_CAMERAS_NEXT_PAGE_ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          nextPage: false,
        },
      };

    case ActionTypes.GET_CAMERAS_ERROR:
      return {
        ...state,
        loading: {
          ...state.loading,
          getCameras: false,
        },
      };

    case ActionTypes.ADD_CAMERA_ERROR:
      return {
        ...state,
        loadingAddCamera: false,
      };

    case ActionTypes.UPDATE_CAMERA_ERROR:
      return {
        ...state,
        loadingUpdateCamera: false,
      };

    case ActionTypes.DELETE_CAMERA_ERROR:
      return {
        ...state,
        loadingDeleteCamera: false,
      };

    case ActionTypes.SET_SUBSCRIBE_CAMERA: {
      const screencasts = state.screencasts;
      const filter = screencasts.filter((x) => x.uuid !== action.payload.uuid);
      return {
        ...state,
        screencasts: [...filter, action.payload],
      };
    }

    case ActionTypes.SET_UNSUBSCRIBE_CAMERA: {
      const screencasts = state.screencasts.filter((x) => x.uuid !== action.payload);
      return {
        ...state,
        screencasts: [...screencasts],
      };
    }

    // TODO: TEMP SET LIVE
    case ActionTypes.GET_CAMERA:
      return {
        ...initialState,
      };

    case ActionTypes.SET_CAMERA_LIVE:
      return {
        ...state,
        live: [...state.live, action.payload],
      };

    case ActionTypes.SET_CAMERA_LIVE_RESET:
      return {
        ...state,
        live: state.live.filter((item) => item != action.payload),
      };

    case ActionTypes.GET_DASHBOARD_WIDGETS:
      return {
        ...state,
        loadingWidgets: true,
      };

    case ActionTypes.GET_DASHBOARD_WIDGETS_SUCCESS:
      return {
        ...state,
        loadingWidgets: false,
      };

    case ActionTypes.GET_DASHBOARD_WIDGETS_ERROR:
      return {
        ...state,
        loadingWidgets: false,
      };

    case ActionTypes.ADD_DASHBOARD_WIDGET_SUCCESS:
      return {
        ...state,
        loadingWidgets: false,
        addedWidget: action.payload,
      };

    case ActionTypes.CHANGE_CAMERAS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case ActionTypes.CHANGE_CAMERAS_FILTERS:
      return {
        ...state,
        camerasFilters: {
          ...state.camerasFilters,
          [action.payload.key]: action.payload.value,
        },
      };

    case ActionTypes.CHANGE_WIDGETS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value.some((value) =>
          state[action.payload.key].map((item) => item.id === value.id)
        )
          ? [
              ...state[action.payload.key].filter(
                (item) => !action.payload.value.map((it) => it.id).includes(item.id)
              ),
              ...action.payload.value,
            ]
          : [...state[action.payload.key], ...action.payload.value],
      };

    case ActionTypes.DELETE_WIDGET_SUCCESS:
      const wid = state.widgets.find((widget) => widget.uuid === action.payload);
      return {
        ...state,
        widgets: state.widgets.filter((item) => item.uuid !== wid.uuid),
        subscribeWidgets: state.subscribeWidgets.filter(
          (it, index) =>
            index !==
            state.subscribeWidgets.indexOf(`${wid.camera.uuid}--${ActionTypes.widgetAnalytics[wid.type]}`)
        ),
        accumWidgets: state.accumWidgets.filter(
          (it, index) => index !== state.accumWidgets.indexOf(wid.camera.uuid)
        ),
      };

    default:
      return state;
  }
};
