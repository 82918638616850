import * as types from './actions';
import { workflowModalTabs, workflowStatuses } from '../../../../constants/workflows.constants';

export const initialState = {
  availableTabs: [workflowModalTabs[0].value],
  activeTab: workflowModalTabs[0].value,
  activeTabChanged: false,
  collapse: 0,
  loading: {
    schemas: false,
    baseConfig: false,
  },
  workflowName: '',
  workflowStatus: workflowStatuses.INACTIVE,
  workflowUUID: null,
  processingConfigUuid: null,
  schemasObj: {},
  uiSchemas: {},
  configs: {},
  defaultConfigs: {},
  baseConfigs: [],
  baseConfig: null,
  processingTypes: [],
  processingType: null,
  general: {
    cameras: [],
    selectedCamera: null,
    cameraFrame: null,
  },
  analyticTypes: [],
  triggerTypes: {},
  cameras: [
    {
      selected: null,
      cameraFrame: null,
    },
  ],
  analytics: [
    {
      type: null,
      schema: {},
      baseConfigs: [],
      baseConfig: null,
      config: null,
    },
  ],
  triggers: [],
  presetsChoice: 0,
  wfPresets: [],
  choosedPreset: null,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.CHANGE_VALUE:
      return {
        ...state,
        [payload.key]: payload.value,
      };

    case types.CHANGE_GENERAL_VALUE:
      return {
        ...state,
        general: {
          ...state.general,
          [payload.key]: payload.value,
        },
      };

    case types.SET_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload.key]: payload.value,
        },
      };

    case types.CHANGE_PROCESSOR_CONFIG:
      return {
        ...state,
        configs: {
          ...state.configs,
          [payload.type]: {
            ...state.configs[payload.type],
            processors: state.configs[payload.type].processors.map((processor, i) =>
              i === payload.index ? payload.data : processor
            ),
          },
        },
      };

    case types.CHANGE_BATCH_SIZE:
      return {
        ...state,
        configs: {
          ...state.configs,
          [payload.type]: {
            ...state.configs[payload.type],
            optimalBatchSize: payload.value,
          },
        },
      };

    case types.RESET_PROCESSING_TAB:
      return {
        ...state,
        collapse: 0,
        schemasObj: {},
        uiSchemas: {},
        configs: {},
        defaultConfigs: {},
        baseConfigs: [],
        baseConfig: null,
      };

    case types.ADD_ANALYTIC:
      return {
        ...state,
        analytics: [
          ...state.analytics,
          {
            type: null,
            schema: {},
            baseConfigs: [],
            baseConfig: null,
            config: null,
          },
        ],
      };

    case types.REMOVE_ANALYTIC:
      return {
        ...state,
        analytics: [...state.analytics.filter((analytic, index) => index !== payload.index)],
      };

    case types.CHANGE_ANALYTIC:
      return {
        ...state,
        analytics: [
          ...state.analytics.map((analytic, index) => {
            if (index === payload.index) {
              return {
                ...analytic,
                [payload.key]: payload.value,
              };
            } else {
              return analytic;
            }
          }),
        ],
      };

    case types.ADD_TRIGGER:
      return {
        ...state,
        triggers: [
          ...state.triggers,
          {
            type: null,
            schema: {},
            baseConfigs: [],
            baseConfig: null,
            config: null,
          },
        ],
      };

    case types.REMOVE_TRIGGER:
      return {
        ...state,
        triggers: [...state.triggers.filter((trigger, index) => index !== payload.index)],
      };

    case types.CHANGE_TRIGGER:
      return {
        ...state,
        triggers: [
          ...state.triggers.map((trigger, index) => {
            if (index === payload.index) {
              return {
                ...trigger,
                [payload.key]: payload.value,
              };
            } else {
              return trigger;
            }
          }),
        ],
      };

    case types.ADD_CAMERA:
      return {
        ...state,
        cameras: [
          ...state.cameras,
          {
            selected: null,
            cameraFrame: null,
          },
        ],
      };

    case types.REMOVE_CAMERA:
      return {
        ...state,
        cameras: [...state.cameras.filter((camera, index) => index !== payload.index)],
      };

    case types.CHANGE_CAMERA:
      return {
        ...state,
        cameras: [
          ...state.cameras.map((camera, index) => {
            if (index === payload.index) {
              return {
                ...camera,
                [payload.key]: payload.value,
              };
            } else {
              return camera;
            }
          }),
        ],
      };

    default:
      return state;
  }
};
