import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import Form from '@rjsf/core';
import cx from 'classnames';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardBody,
  CustomInput,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { JsonEditor as Editor } from 'jsoneditor-react';

import { FormInput, FormNumericInput, FormSelect } from '../../../../components/Form';
import i18n from '../../../../i18n';
import Loader from '../../../../components/Loader';
import AccordionItem from '../../../../components/Accordion/AccordionItem';
import SwitchJSONEditors from '../../../../components/SwitchJSONEditors/SwitchJSONEditors';

import * as modalActions from './actions';
import { reducer, initialState } from './reducer';

import { workflowModalTabs, configStatuses } from '../../../../constants/workflows.constants';

import * as schemasActions from '../../../../actions/schemas.action';
import * as camerasActions from '../../../../actions/cameras.actions';
import * as workflowsActions from '../../../../actions/workflows.action';
import * as workflowActions from '../../../../actions/workflows.action';

const WorkflowModal = ({
  title,
  isOpen,
  type,
  workflowUuid,
  onSubmit,
  onClose,
  isLoading,
  className,
  errorMsg,
  addWorkflow,
  updateWorkflow,
  deleteWorkflow,
  checkWorkflowStatus,
  getProcessingTypes,
  getProcessingSchema,
  getProcessingConfigs,
  getProcessingConfig,
  getModelSchema,
  getAnalyticSchema,
  getAnalyticBaseConfig,
  getTriggerSchema,
  addProcessingToWorkflow,
  addAnalyticsToWorkflow,
  addTriggersToWorkflow,
  getAvailableAnalytics,
  getAvailableTriggers,
  getWorkflow,
  getWorkflowProcessing,
  getWorkflowAnalytics,
  getWorkflowTriggers,
  getWorkflowPresets,
  getWorkflows,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isModalOpen, setModalOpen] = useState(false);
  const [triggerError, setTriggerError] = useState([]);

  useEffect(() => {
    getProcessingTypes({
      onSuccess: (types) => dispatch(modalActions.changeValue('processingTypes', types)),
    });

    getWorkflowPresets(
      { size: 100 },
      {
        onSuccess: (presets) => dispatch(modalActions.changeValue('wfPresets', presets.items)),
      }
    );

    if (type === 'edit') {
      fetchWorkflowData(workflowUuid);
      dispatch(modalActions.changeValue('availableTabs', [...workflowModalTabs.map((tab) => tab.value)]));
    }
  }, []);

  const fetchWorkflowData = (workflowUuid) => {
    getWorkflow(workflowUuid, {
      onSuccess: (data) => {
        const workflowStatus = checkWorkflowStatus(data.runningStatus, data.started);
        dispatch(
          modalActions.changeValue('workflowStatus', checkWorkflowStatus(data.runningStatus, data.started))
        );
        dispatch(modalActions.changeValue('workflowName', data.name));
        dispatch(modalActions.changeValue('workflowUUID', data.uuid));

        if (data.processingConfig && data.processingConfig.uuid) {
          dispatch(modalActions.changeValue('processingConfigUuid', data.processingConfig.uuid));

          getWorkflowProcessing(workflowUuid, {
            onSuccess: (processing) => {
              dispatch(
                modalActions.changeValue('processingType', { label: processing.type, value: processing.type })
              );
              dispatch(
                modalActions.changeValue('baseConfig', {
                  label: `${processing.type} - ${data.name}`,
                  value: processing.uuid,
                })
              );

              getProcessingConfigs(processing.type, {
                onSuccess: (configs) => {
                  dispatch(
                    modalActions.changeValue('baseConfigs', [
                      ...configs.map((config) => {
                        if (config.base) {
                          return config.name
                            ? { label: `${config.type} - ${config.name}`, value: config.uuid }
                            : { label: config.type, value: config.uuid };
                        } else {
                          return { label: `${config.type} - ${config.workflow.name}`, value: config.uuid };
                        }
                      }),
                    ])
                  );
                },
              });

              makeProcessingConfigs(processing);

              getProcessingSchema(processing.type, {
                onSuccess: (schemas) => {
                  makeSchemas(schemas);
                },
                onError: (error) => {},
              });
            },
            onError: (error) => {},
          });

          getAvailableAnalytics(workflowUuid, {
            onSuccess: (types) => {
              dispatch(
                modalActions.changeValue('analyticTypes', [
                  ...types.map((type) => ({ label: type, value: type })),
                ])
              );
            },
          });

          getWorkflowAnalytics(workflowUuid, {
            onSuccess: (analytics) => {
              dispatch(
                modalActions.changeValue('analytics', [
                  ...analytics.map((analytic) => ({
                    ...analytic,
                    type: { label: analytic.type, value: analytic.type },
                    schema: makeSchemaWithoutHidden(analytic.schema),
                  })),
                ])
              );
            },
          });

          getAvailableTriggers(workflowUuid, {
            onSuccess: (triggers) => {
              const availableTriggers = [
                ...triggers.map((trigger) => ({ ...trigger, config: {}, enabled: true })),
              ];
              getWorkflowTriggers(
                workflowUuid,
                { scope: 'WORKFLOW' },
                {
                  onSuccess: (triggers) => {
                    dispatch(modalActions.changeValue('triggers', [...availableTriggers, ...triggers]));
                  },
                }
              );
            },
          });
        }
      },
      onError: () => onClose(),
    });
  };

  useEffect(() => {
    dispatch(modalActions.changeValue('configs', []));
    if (state.baseConfig) {
      dispatch(modalActions.setLoading('baseConfig', true));
      getProcessingConfig(state.baseConfig.value, {
        onSuccess: (baseConfig) => {
          makeProcessingConfigs(baseConfig);
          dispatch(modalActions.setLoading('baseConfig', false));
        },
        onError: (error) => {
          dispatch(modalActions.setLoading('baseConfig', false));
        },
      });
    }
  }, [state.baseConfig]);

  const verifyTab = () => {
    switch (state.activeTab) {
      case workflowModalTabs[0].value:
        return verifyGeneralTab();
        break;

      case workflowModalTabs[1].value:
        return verifyProcessingTab();
        break;

      case workflowModalTabs[2].value:
        return verifyAnalyticsTab();
        break;

      case workflowModalTabs[3].value:
        return verifyTriggersTab();
        break;

      default:
        return false;
    }
  };

  const verifyGeneralTab = () => {
    if (state.workflowName.trim() && (state.processingType || state.choosedPreset)) {
      !state.workflowUUID
        ? addWorkflow(
            { name: state.workflowName, presetUuid: state.choosedPreset },
            {
              onSuccess: (res) => {
                dispatch(modalActions.changeValue('workflowUUID', res.uuid));
              },
              onError: (err) => {
                console.log(err);
              },
            }
          )
        : updateWorkflow(
            state.workflowUUID,
            { name: state.workflowName, presetUuid: state.choosedPreset },
            {}
          );

      dispatch(modalActions.resetProcessingTab());

      dispatch(modalActions.setLoading('schemas', true));
      if (state.choosedPreset !== null) {
        dispatch(modalActions.changeValue('choosedPreset', null));
        onClose();
      } else {
        getProcessingConfigs(state.processingType.value, {
          onSuccess: (configs) => {
            dispatch(
              modalActions.changeValue('baseConfigs', [
                ...configs.map((config) => {
                  if (config.base) {
                    return config.name
                      ? { label: `${config.type} - ${config.name}`, value: config.uuid }
                      : { label: config.type, value: config.uuid };
                  } else {
                    return { label: `${config.type} - ${config.workflow.name}`, value: config.uuid };
                  }
                }),
              ])
            );
          },
        });

        getProcessingSchema(state.processingType.value, {
          onSuccess: (schemas) => {
            makeSchemas(schemas);
            dispatch(modalActions.setLoading('schemas', false));
          },
          onError: (error) => {
            dispatch(modalActions.setLoading('schemas', false));
          },
        });
      }

      return true;
    }
    return false;
  };

  const verifyProcessingTab = () => {
    if (state.baseConfig) {
      const nodes = { ...state.configs };
      const newNodes = {};
      Object.keys(nodes).map((key) => {
        newNodes[`${key}.json`] = nodes[key];
      });
      const data = {
        baseProcessingConfigUuid: state.baseConfig.value,
        createNewProcessingNode: true,
        nodes: newNodes,
      };

      addProcessingToWorkflow(state.workflowUUID, data, {
        onSuccess: () => {
          type === 'create' && getWorkflows();
          getAvailableAnalytics(state.workflowUUID, {
            onSuccess: (types) => {
              dispatch(
                modalActions.changeValue('analyticTypes', [
                  ...types.map((type) => ({ label: type, value: type })),
                ])
              );
            },
          });
        },
      });

      return true;
    }
    return false;
  };

  const verifyAnalyticsTab = () => {
    const analytics = [];
    state.analytics.map((analytic) => {
      if (analytic.config) {
        analytics.push({ uuid: analytic.uuid, config: analytic.config, type: analytic.type.value });
      }
    });

    addAnalyticsToWorkflow(state.workflowUUID, analytics, {
      onSuccess: () => {
        getAvailableTriggers(state.workflowUUID, {
          onSuccess: (triggers) => {
            const availableTriggers = [
              ...triggers.map((trigger) => ({
                ...trigger,
                config: {},
                enabled: trigger.scope === 'DATA_STREAM' ? false : true,
              })),
            ];
            getWorkflowTriggers(
              state.workflowUUID,
              { scope: 'WORKFLOW' },
              {
                onSuccess: (triggers) => {
                  dispatch(modalActions.changeValue('triggers', [...availableTriggers, ...triggers]));
                },
              }
            );
          },
        });
      },
    });

    return true;
  };

  const verifyTriggersTab = () => {
    let errorArray = [];
    const triggers = state.triggers.map((trigger) => ({
      uuid: trigger.uuid,
      analyticsUuid: trigger.analyticsUuid,
      config: trigger.config,
      enabled: trigger.enabled,
      type: trigger.type,
    }));

    triggers.length
      ? triggers.forEach((trigger, index) =>
          addTriggersToWorkflow(state.workflowUUID, [trigger], {
            onSuccess: () => {
              index === triggers.length - 1 && errorArray.length === 0 && onClose();
            },
            onError: () => {
              errorArray.push(trigger.type);
              index === triggers.length - 1 && setTriggerError(errorArray);
            },
          })
        )
      : onClose();
    return true;
  };

  const changeActiveTab = (callback) => {
    dispatch(modalActions.changeValue('activeTabChanged', true));
    callback();
  };

  const switchTab = (tab) => {
    if (state.availableTabs.includes(tab)) {
      if (state.activeTabChanged) {
        if (verifyTab()) {
          dispatch(modalActions.changeValue('activeTab', tab));
          dispatch(modalActions.changeValue('activeTabChanged', false));
        }
      } else {
        dispatch(modalActions.changeValue('activeTab', tab));
      }
    }
  };

  const activateNextTab = () => {
    const currentTabIndex = workflowModalTabs.findIndex((tab) => tab.value === state.activeTab);
    if (currentTabIndex + 1 === workflowModalTabs.length) {
      verifyTab();
    } else {
      const nextTab = workflowModalTabs[currentTabIndex + 1].value;
      if (verifyTab()) {
        if (!state.availableTabs.includes(nextTab)) {
          const tabs = [...state.availableTabs];
          tabs.push(nextTab);
          dispatch(modalActions.changeValue('availableTabs', tabs));
        }
        dispatch(modalActions.changeValue('activeTab', nextTab));
        dispatch(modalActions.changeValue('activeTabChanged', false));
      }
    }
  };

  const makeSchemas = (schemasArray) => {
    const schemasObject = {};
    const uiSchemas = {};

    schemasArray.map((item) => {
      const fieldName = item.fileName.split('.')[0];
      schemasObject[fieldName] = [];
      uiSchemas[fieldName] = [];

      item.content.properties.processors.items.map((processor) => {
        const procCopy = { ...processor };
        const confProperties = processor.dependencies.type.oneOf[0].properties.configuration.properties;

        confProperties &&
          Object.keys(confProperties).forEach((property) => {
            if (confProperties[property].hidden || confProperties[property].initModification) {
              delete procCopy.dependencies.type.oneOf[0].properties.configuration.properties[property];
            }
          });

        schemasObject[fieldName].push(procCopy);

        const uiSchema = {};

        uiSchemas[fieldName].push(uiSchema);
      });
    });

    dispatch(modalActions.changeValue('uiSchemas', uiSchemas));
    dispatch(modalActions.changeValue('schemasObj', schemasObject));
  };

  const makeSchemaWithoutHidden = (schema) => {
    const schemaObject = { ...schema };

    Object.keys(schemaObject.properties).map((property) => {
      if (schemaObject.properties[property].hidden) {
        delete schemaObject.properties[property];
      }
    });

    return schemaObject;
  };

  const updateSchemasWithModels = (configUUID, configs) => {
    Object.keys(configs).map((type) => {
      if (state.schemasObj.hasOwnProperty(type)) {
        configs[type].processors.map((processor, i) => {
          if (processor.configuration && processor.configuration.model) {
            addModelSchemaToSchemas(configUUID, processor.configuration.model.id, type, i);
          }
        });
      }
    });
  };

  const addModelSchemaToSchemas = (configUUID, modelId, type, index) => {
    getModelSchema(configUUID, modelId, {
      onSuccess: (schema) => {
        const updatedProcessor = state.schemasObj[type][index];
        updatedProcessor.dependencies.type.oneOf[0].properties.configuration.properties.model.properties.configuration =
          schema.content;

        const updatedSchemas = {
          ...state.schemasObj,
          [type]: [
            ...state.schemasObj[type].map((processor, i) => (i === index ? updatedProcessor : processor)),
          ],
        };
        dispatch(modalActions.changeValue('schemasObj', updatedSchemas));
      },
      onError: () => {},
    });
  };

  const makeProcessingConfigs = (baseConfig) => {
    const configs = {};
    const { nodes } = baseConfig;

    Object.keys(nodes).map((key) => {
      const fieldName = key.split('.')[0];
      configs[fieldName] = nodes[key];
    });

    dispatch(modalActions.changeValue('configs', configs));
    dispatch(modalActions.changeValue('defaultConfigs', configs));

    updateSchemasWithModels(baseConfig.uuid, configs);
  };

  const resetProcessorForm = (key, index) => {
    const configs = { ...state.configs };
    configs[key].processors[index] = state.defaultConfigs[key].processors[index];

    dispatch(modalActions.changeValue('configs', configs));
  };

  const changeAccordionItem = (key) => {
    dispatch(modalActions.changeValue('collapse', state.collapse === key ? 0 : key));
  };

  const changeAnalyticType = (index, type) => {
    dispatch(modalActions.changeAnalytic(index, 'type', type));
    getAnalyticSchema(type.value, {
      onSuccess: (schema) => {
        dispatch(modalActions.changeAnalytic(index, 'schema', makeSchemaWithoutHidden(schema.content)));
      },
    });
    getAnalyticBaseConfig(type.value, {
      onSuccess: (baseConfig) => {
        dispatch(modalActions.changeAnalytic(index, 'config', baseConfig.config));
      },
    });
  };

  const generateAnalytics = state.analytics.map((analytic, index) => (
    <div key={index} className="form__creation">
      <div className="form__creation__header">
        <div className="form-row">
          <FormSelect
            label={i18n.t('workflowModals:labelAnalyticType')}
            selected={analytic.type}
            options={state.analyticTypes}
            isSearch={true}
            className="form-item form-item--two"
            onChange={(type) => changeAnalyticType(index, type)}
            errorMessage={false}
          />

          <FormSelect
            label={i18n.t('workflowModals:labelBaseConfig')}
            selected={analytic.baseConfig}
            options={analytic.baseConfigs}
            isSearch={true}
            className="form-item form-item--two"
            onChange={(baseConfig) => {}}
            errorMessage={false}
          />
        </div>

        <button
          className="form__creation__header-btn"
          onClick={() => dispatch(modalActions.removeAnalytic(index))}
        >
          <FontAwesomeIcon icon={faTimes} className="form__creation__header-times" />
        </button>
      </div>

      <div className="form__creation-fields">
        <Form
          schema={analytic.schema}
          formData={analytic.config}
          onChange={(data) => {
            dispatch(modalActions.changeAnalytic(index, 'config', data.formData));
          }}
          onError={() => {}}
          children={[]}
        />
      </div>
    </div>
  ));

  const generateTriggers = state.triggers.map((trigger, index) =>
    trigger.scope === 'WORKFLOW' ? (
      <div key={index} className="form__creation">
        <div className="form__creation__header">
          <div className="form-row">
            <FormInput
              type="text"
              className="form-item form-item--two"
              label="Trigger type"
              value={trigger.type}
              onChange={() => {}}
            />

            {trigger.analyticsType ? (
              <FormInput
                type="text"
                className="form-item form-item--two"
                label="Analytic type"
                value={trigger.analyticsType}
                onChange={() => {}}
              />
            ) : (
              <div className="form-item form-item--two form-item--text">Trigger for processing</div>
            )}
          </div>
        </div>

        <div className="form-switch-item mb-14">
          <CustomInput
            type="switch"
            checked={trigger.enabled}
            onChange={(e) => dispatch(modalActions.changeTrigger(index, 'enabled', e.target.checked))}
            id={`${trigger.type}-${index}`}
            name="Enabled"
            className="custom-switch--small"
          />
          <p className="form-switch-label">{i18n.t('workflowModals:labelEnabled')}</p>
        </div>
        <div className="form__creation-fields">
          <Form
            schema={trigger.schema}
            formData={trigger.config}
            onChange={(data) => {
              dispatch(modalActions.changeTrigger(index, 'config', data.formData));
            }}
            onError={() => {}}
            children={[]}
          />
        </div>
      </div>
    ) : null
  );

  const handleCloseWorkflowModal = () => {
    type === 'create' && state.workflowUUID ? setModalOpen(true) : onClose();
  };

  const handleDeleteWorkflow = () => {
    deleteWorkflow(state.workflowUUID, {});
    setModalOpen(false);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} className={cx('', className)} fade>
        <ModalHeader toggle={handleCloseWorkflowModal}>{title}</ModalHeader>

        <ModalBody>
          <Nav tabs className="workflow-form__tabs">
            <NavItem>
              <NavLink
                className={cx({ active: state.activeTab === workflowModalTabs[0].value })}
                onClick={() => switchTab(workflowModalTabs[0].value)}
                disabled={!state.availableTabs.includes(workflowModalTabs[0].value)}
              >
                {i18n.t('workflowModals:generalTab')}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={cx({ active: state.activeTab === workflowModalTabs[1].value })}
                onClick={() => switchTab(workflowModalTabs[1].value)}
                disabled={!state.availableTabs.includes(workflowModalTabs[1].value)}
              >
                {i18n.t('workflowModals:processingTab')}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={cx({ active: state.activeTab === workflowModalTabs[2].value })}
                onClick={() => switchTab(workflowModalTabs[2].value)}
                disabled={!state.availableTabs.includes(workflowModalTabs[2].value)}
              >
                {i18n.t('workflowModals:analyticsTab')}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={cx({ active: state.activeTab === workflowModalTabs[3].value })}
                onClick={() => switchTab(workflowModalTabs[3].value)}
                disabled={!state.availableTabs.includes(workflowModalTabs[3].value)}
              >
                {i18n.t('workflowModals:triggersTab')}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={state.activeTab} className="workflow-form__tab-content">
            <TabPane tabId={workflowModalTabs[0].value}>
              <div className="form-row_mb-10">
                <FormInput
                  type="text"
                  label={i18n.t('workflowModals:labelWorkflowName')}
                  value={state.workflowName}
                  className="form-item form-item--one"
                  onChange={(type) =>
                    changeActiveTab(() => dispatch(modalActions.changeValue('workflowName', type)))
                  }
                  errorMessage={false}
                />
              </div>
              {type !== 'edit' && (
                <div className="d-flex flex-row m-3">
                  <div className="form-check d-flex flex-row mr-5 pl-0">
                    <input
                      className="mr-3"
                      style={{ cursor: 'pointer' }}
                      type="radio"
                      name="presetsChoice"
                      id="scratch"
                      checked={state.presetsChoice === 0}
                      onChange={() => {
                        dispatch(modalActions.changeValue('presetsChoice', 0));
                        dispatch(modalActions.changeValue('choosedPreset', null));
                      }}
                    />
                    <label className="form-check-label" htmlFor="scratch">
                      Create from scratch
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="mr-3"
                      style={{ cursor: 'pointer' }}
                      type="radio"
                      name="presetsChoice"
                      id="preset"
                      checked={state.presetsChoice === 1}
                      onChange={() => dispatch(modalActions.changeValue('presetsChoice', 1))}
                    />
                    <label className="form-check-label" htmlFor="preset">
                      Use preset
                    </label>
                  </div>
                </div>
              )}
              {state.presetsChoice === 0 ? (
                <div className="form-row_mb-28">
                  <FormSelect
                    label={i18n.t('workflowModals:labelProcessingType')}
                    selected={state.processingType}
                    options={state.processingTypes}
                    isSearch={true}
                    className="form-item form-item--one"
                    onChange={(type) =>
                      changeActiveTab(() => dispatch(modalActions.changeValue('processingType', type)))
                    }
                    errorMessage={false}
                    disabled={state.processingConfigUuid}
                  />
                </div>
              ) : (
                <div className="presets--list">
                  {state.wfPresets.length !== 0 ? (
                    state.wfPresets.map((preset) => (
                      <div className="form-check pl-0">
                        <input
                          className="mr-3"
                          style={{ cursor: 'pointer' }}
                          type="radio"
                          name="presets"
                          id={preset.uuid}
                          checked={state.choosedPreset === preset.uuid}
                          onChange={() => dispatch(modalActions.changeValue('choosedPreset', preset.uuid))}
                        />
                        <label className="form-check-label" htmlFor={preset.uuid}>
                          {preset.name}
                        </label>
                      </div>
                    ))
                  ) : (
                    <div className="w-100 text-center">No Available Presets </div>
                  )}
                </div>
              )}
            </TabPane>

            <TabPane tabId={workflowModalTabs[1].value}>
              <div className="form-row">
                <FormSelect
                  label={i18n.t('workflowModals:labelBaseConfig')}
                  selected={state.baseConfig}
                  options={state.baseConfigs}
                  isSearch={true}
                  className="form-item form-item--one"
                  onChange={(baseConfig) => dispatch(modalActions.changeValue('baseConfig', baseConfig))}
                  errorMessage={false}
                />
              </div>

              <div className="mt-20">
                {state.processingTypes && !state.processingType && 'Please select processing type'}
                {state.processingType &&
                  state.baseConfigs &&
                  !state.baseConfig &&
                  'Please select base config'}
                <Loader loading={state.loading.schemas || state.loading.baseConfig} />
              </div>

              {Object.keys(state.schemasObj).length !== 0 &&
                Object.keys(state.configs).length !== 0 &&
                Object.keys(state.schemasObj).map((key, index) => {
                  return (
                    <AccordionItem
                      key={key}
                      title={key.replace(/[A-Z]/g, (a) => ' ' + a).trim()}
                      isOpen={state.collapse === index + 1}
                      onOpen={() => {
                        changeAccordionItem(index + 1);
                      }}
                    >
                      <FormNumericInput
                        label={i18n.t('workflowModals:labelBatchSize')}
                        value={state.configs && state.configs[key] && state.configs[key].optimalBatchSize}
                        className="form-item form-item--one mb-16"
                        onChange={(value) => dispatch(modalActions.changeBatchSize(key, value))}
                        errorMessage={false}
                      />
                      {state.schemasObj[key].map((schema, index) => {
                        return (
                          <>
                            <div key={index}>
                              <span> {`Processor #${index + 1}`}</span>
                              <button className="btn_reset" onClick={() => resetProcessorForm(key, index)}>
                                Reset form
                              </button>
                            </div>

                            <SwitchJSONEditors key={'Editor' + index} id={`${key}-${index}`}>
                              <Form
                                schema={schema}
                                uiSchema={
                                  state.uiSchemas && state.uiSchemas[key] && state.uiSchemas[key][index]
                                }
                                formData={
                                  state.configs && state.configs[key] && state.configs[key].processors[index]
                                }
                                onChange={(data) => {
                                  dispatch(modalActions.changeProcessorConfig(key, index, data.formData));
                                }}
                                onError={() => {}}
                                children={[]}
                              />

                              <Editor
                                value={
                                  state.configs && state.configs[key] && state.configs[key].processors[index]
                                }
                                onChange={(data) => {
                                  dispatch(modalActions.changeProcessorConfig(key, index, data));
                                }}
                                schema={schema}
                              />
                            </SwitchJSONEditors>
                          </>
                        );
                      })}
                    </AccordionItem>
                  );
                })}
            </TabPane>

            <TabPane tabId={workflowModalTabs[2].value}>
              {generateAnalytics}
              <button
                className="form__creation__add-btn"
                onClick={() => dispatch(modalActions.addAnalytic())}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </TabPane>

            <TabPane tabId={workflowModalTabs[3].value}>
              {state.triggers.filter((trigger) => trigger.scope === 'WORKFLOW').length
                ? generateTriggers
                : 'There is no available workflow triggers. Camera-specific triggers will be available on camera attach'}
            </TabPane>
          </TabContent>
        </ModalBody>

        <ModalFooter>
          <Loader loading={isLoading} />
          {/*{message || null}*/}
          <button
            type="button"
            className="btn btn--dark"
            disabled={isLoading}
            onClick={handleCloseWorkflowModal}
          >
            {i18n.t('buttons:cancel')}
          </button>
          <button
            className="btn btn--secondary"
            onClick={() => activateNextTab()}
            // disabled={isLoading || !cameraFrame || !isValid}
            disabled={false}
          >
            {state.presetsChoice === 1 || state.activeTab === workflowModalTabs[3].value
              ? i18n.t('buttons:save')
              : i18n.t('buttons:next')}
          </button>
        </ModalFooter>

        {isModalOpen && (
          <Modal isOpen={isModalOpen} className="modal_small" fade>
            <ModalHeader toggle={() => setModalOpen(false)}>
              {i18n.t('workflowModals:cancelCreation')}
            </ModalHeader>
            <ModalBody>
              <div className="form-row">
                You have not finished configuring the workflow. What do you want to do with it?
              </div>
            </ModalBody>
            <ModalFooter>
              <button className="btn btn--secondary" type="submit" onClick={handleDeleteWorkflow}>
                {i18n.t('buttons:delete')}
              </button>
              <button
                className="btn btn--secondary"
                type="submit"
                onClick={() => {
                  setModalOpen(false);
                  onClose();
                }}
              >
                {i18n.t('buttons:save')}
              </button>
            </ModalFooter>
          </Modal>
        )}
      </Modal>
      {triggerError.length > 0 && (
        <Modal isOpen={triggerError.length > 0 ? true : false} fade>
          <ModalHeader
            toggle={() => {
              onClose();
              setTriggerError([]);
            }}
          >
            {i18n.t('workflowModals:errorTrigger')}
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              These triggers have not been validated and workflow is saved without them:
            </div>
            {triggerError.map((item) => (
              <div className="form-row">{item}</div>
            ))}
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn--secondary"
              type="submit"
              onClick={() => {
                onClose();
                setTriggerError([]);
              }}
            >
              {i18n.t('buttons:ok')}
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getWorkflows: (params, actions) => dispatch(workflowActions.getWorkflows(params, actions)),
  getProcessingTypes: (actions) => dispatch(schemasActions.getProcessingTypes(actions)),
  getProcessingSchema: (type, actions) => dispatch(schemasActions.getProcessingSchema(type, actions)),
  getProcessingConfigs: (type, actions) => dispatch(workflowsActions.getProcessingConfigs(type, actions)),
  getProcessingConfig: (uuid, actions) => dispatch(workflowsActions.getProcessingConfig(uuid, actions)),
  getModelSchema: (uuid, id, actions) => dispatch(schemasActions.getModelSchema(uuid, id, actions)),
  getAnalyticSchema: (type, actions) => dispatch(schemasActions.getAnalyticSchema(type, actions)),
  getTriggerSchema: (type, actions) => dispatch(schemasActions.getTriggerSchema(type, actions)),
  getAnalyticBaseConfig: (type, actions) => dispatch(workflowsActions.getAnalyticBaseConfig(type, actions)),
  addProcessingToWorkflow: (uuid, params, actions) =>
    dispatch(workflowsActions.addProcessingToWorkflow(uuid, params, actions)),
  addAnalyticsToWorkflow: (uuid, params, actions) =>
    dispatch(workflowsActions.addAnalyticsToWorkflow(uuid, params, actions)),
  addTriggersToWorkflow: (uuid, params, actions) =>
    dispatch(workflowsActions.addTriggersToWorkflow(uuid, params, actions)),
  getAvailableAnalytics: (uuid, actions) => dispatch(workflowsActions.getAvailableAnalytics(uuid, actions)),
  getAvailableTriggers: (uuid, actions) => dispatch(workflowsActions.getAvailableTriggers(uuid, actions)),
  getWorkflow: (uuid, actions) => dispatch(workflowsActions.getWorkflow(uuid, actions)),
  getWorkflowProcessing: (uuid, actions) => dispatch(workflowsActions.getWorkflowProcessing(uuid, actions)),
  getWorkflowAnalytics: (uuid, actions) => dispatch(workflowsActions.getWorkflowAnalytics(uuid, actions)),
  getWorkflowTriggers: (uuid, params, actions) =>
    dispatch(workflowsActions.getWorkflowTriggers(uuid, params, actions)),
  getWorkflowPresets: (params, actions) => dispatch(workflowsActions.getWorkflowPresets(params, actions)),
});

export default connect(null, mapDispatchToProps)(WorkflowModal);
