export const GET_DATASET_MARKUP = 'GET_DATASET_MARKUP';
export const GET_DATASET_MARKUP_RESULT = 'GET_DATASET_MARKUP_RESULT';
export const GET_DATASET_MARKUP_ERROR = 'GET_DATASET_MARKUP_ERROR';

export const GET_DATASETS_MARKUP = 'GET_DATASETS_MARKUP';
export const GET_DATASETS_MARKUP_RESULT = 'GET_DATASETS_MARKUP_RESULT';
export const GET_DATASETS_MARKUP_ERROR = 'GET_DATASETS_MARKUP_ERROR';

export const ADD_DATASET_MARKUP = 'ADD_DATASET_MARKUP';
export const ADD_DATASET_MARKUP_SUCCESS = 'ADD_DATASET_MARKUP_SUCCESS';
export const ADD_DATASET_MARKUP_ERROR = 'ADD_DATASET_MARKUP_ERROR';

export const UPDATE_DATASET_MARKUP = 'UPDATE_DATASET_MARKUP';
export const UPDATE_DATASET_MARKUP_SUCCESS = 'UPDATE_DATASET_MARKUP_SUCCESS';
export const UPDATE_DATASET_MARKUP_ERROR = 'UPDATE_DATASET_MARKUP_ERROR';

export const DELETE_DATASET_MARKUP = 'DELETE_DATASET_MARKUP';
export const DELETE_DATASET_MARKUP_SUCCESS = 'DELETE_DATASET_MARKUP_SUCCESS';
export const DELETE_DATASET_MARKUP_ERROR = 'DELETE_DATASET_MARKUP_ERROR';

export const GET_DATASET_MARKUP_MEDIA = 'GET_DATASET_MARKUP_MEDIA';
export const GET_DATASET_MARKUP_MEDIA_RESULT = 'GET_DATASET_MARKUP_MEDIA_RESULT';
export const GET_DATASET_MARKUP_MEDIA_ERROR = 'GET_DATASET_MARKUP_MEDIA_ERROR';

export const ADD_DATASET_MARKUP_MEDIA = 'ADD_DATASET_MARKUP_MEDIA';
export const ADD_DATASET_MARKUP_MEDIA_SUCCESS = 'ADD_DATASET_MARKUP_MEDIA_SUCCESS';
export const ADD_DATASET_MARKUP_MEDIA_ERROR = 'ADD_DATASET_MARKUP_MEDIA_ERROR';

export const UPDATE_DATASET_MARKUP_MEDIA = 'UPDATE_DATASET_MARKUP_MEDIA';
export const UPDATE_DATASET_MARKUP_MEDIA_SUCCESS = 'UPDATE_DATASET_MARKUP_MEDIA_SUCCESS';
export const UPDATE_DATASET_MARKUP_MEDIA_ERROR = 'UPDATE_DATASET_MARKUP_MEDIA_ERROR';

export const DELETE_DATASET_MARKUP_MEDIA = 'DELETE_DATASET_MARKUP_MEDIA';
export const DELETE_DATASET_MARKUP_MEDIA_SUCCESS = 'DELETE_DATASET_MARKUP_MEDIA_SUCCESS';
export const DELETE_DATASET_MARKUP_MEDIA_ERROR = 'DELETE_DATASET_MARKUP_MEDIA_ERROR';

export const ADD_SAMPLE = 'ADD_SAMPLE';
export const ADD_SAMPLE_SUCCESS = 'ADD_SAMPLE_SUCCESS';
export const ADD_SAMPLE_ERROR = 'ADD_SAMPLE_ERROR';

export const CHANGE_MODAL_TRAINING_VALUE = 'CHANGE_MODAL_TRAINING_VALUE';
export const CHANGE_TRAINING_VALUE = 'CHANGE_TRAINING_VALUE';
export const RESET_DATASET_MARKUP_STATE = 'RESET_DATASET_MARKUP_STATE';
export const SET_VIA = 'SET_VIA';
export const CHANGE_FILES = 'CHANGE_FILES';

export const modelTypes = [
  { label: 'Detection', value: 'detection' },
  { label: 'Segmentation', value: 'segmentation' },
];

export const baseModel = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
];
