import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '../../components/Table';
import i18n from '../../i18n';
import DropdownDotMenu from '../../components/DropdownDotMenu';
import { Link } from 'react-router-dom';

import { EQUIPMENTS } from '../../constants/routes.constants';
import ModalAdd from './Modals/ModalAdd';
import ModalEdit from './Modals/ModalEdit';
import * as actions from '../../actions/equipment.actions';
import { getLocations } from '../../actions/locations.actions';
import { getEquipmentTypes } from '../../actions/equipmentType.actions';

const MODAL_ADD = 'modalAdd';
const MODAL_EDIT = 'modalEdit';
const MODAL_DELETE = 'modalDelete';

const defaultModal = {
  isOpen: false,
  errorMsg: '',
  user: {},
  resetError: function () {
    this.errorMsg = '';
  },
};

class EquipmentList extends Component {
  state = {
    modalAdd: { ...defaultModal },
    modalEdit: { ...defaultModal },
    modalDelete: { ...defaultModal },
  };

  componentDidMount() {
    this.props.getEquipments(
      {},
      {
        onSuccess: (items) => {},
        onError: (error) => {
          console.log(error);
        },
      }
    );
  }

  toggleModal = (modalName) => {
    this.setState({
      [modalName]: {
        ...this.state[modalName],
        isOpen: !this.state[modalName].isOpen,
      },
    });
  };

  closeModal = (modalName) => {
    this.setState({ [modalName]: { ...defaultModal } });
  };

  editModal = (data) => {
    this.setState({
      modalEdit: {
        isOpen: true,
        data: data,
      },
    });
  };

  createRows = (rows) => {
    return rows.map((equipment) => ({
      id: equipment.uuid,
      cells: [
        {
          label: equipment.name,
        },
        {
          label: equipment.type?.name,
          cellComponent: (
            <Link to={`${EQUIPMENTS}/${equipment.uuid}`} className="link table__cell table__cell--bold">
              {equipment.type?.name}
            </Link>
          ),
        },
        {
          label: equipment.location?.name,
        },
        {
          label: equipment.arucoMarker,
        },
        {
          label: equipment.lastMaintenance,
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                {
                  name: 'Edit',
                  onClick: () => {
                    this.editModal(equipment);
                    this.setState({ editUuid: equipment.uuid });
                  },
                },
                { divider: true },
                {
                  name: 'Delete',
                  onClick: () =>
                    this.props.deleteEquipment(equipment.uuid, {
                      onSuccess: () => this.props.getEquipments({}, {}),
                    }),
                },
              ]}
            />
          ),
        },
      ],
    }));
  };

  render() {
    const headerOptions = [
      {
        label: 'Name',
      },
      {
        label: 'Type',
      },
      {
        label: 'Location',
      },
      {
        label: 'ArUco Marker',
      },
      {
        label: 'Last Maintenance',
      },
      {
        label: '',
      },
    ];

    const addModal = () => {
      this.setState({
        modalAdd: { isOpen: true },
      });
    };

    const updateEquip = (value) => {
      this.props.updateEquipment(
        this.state.editUuid,
        {
          accountUuid: localStorage.getItem('accountUuid'),
          arucoMarker: value.arucoMarket ? value.arucoMarket : modalEdit.data.arucoMarker,
          description: value.description ? value.description : modalEdit.data.description,
          lastMaintenance: value.lastMaintenance
            ? value.lastMaintenance.substring(0, 10)
            : modalEdit.data.lastMaintenance,
          maintenancePeriod: value.maintenancePeriod
            ? value.maintenancePeriod
            : modalEdit.data.maintenancePeriod,
          location: {
            accountUuid: localStorage.getItem('accountUuid'),
            name: value.location ? value.location.label : modalEdit.data.location.name,
            uuid: value.location ? value.location.value : modalEdit.data.location.uuid,
          },
          name: value.equipmentName ? value.equipmentName : modalEdit.data.name,
          type: {
            accountUuid: localStorage.getItem('accountUuid'),
            name: value.type ? value.type.label : modalEdit.data.type.name,
            uuid: value.type ? value.type.value : modalEdit.data.type.uuid,
          },
          zoi: [],
        },
        { onSuccess: () => this.props.getEquipments({}, {}) }
      );
    };

    const addEquip = (value) => {
      this.props.addEquipment(
        {
          accountUuid: localStorage.getItem('accountUuid'),
          arucoMarker: value.arucoMarket,
          description: value.description,
          lastMaintenance: value.lastMaintenance?.substring(0, 10),
          maintenancePeriod: value.maintenancePeriod,
          location: value.location
            ? {
                accountUuid: localStorage.getItem('accountUuid'),
                name: value.location.label,
                uuid: value.location.value,
              }
            : null,
          name: value.equipmentName,
          type: value.type
            ? {
                accountUuid: localStorage.getItem('accountUuid'),
                name: value.type?.label,
                uuid: value.type?.value,
              }
            : null,
          zoi: [],
        },
        {
          onSuccess: () => {
            this.closeModal(MODAL_ADD);
            this.props.getEquipments({}, {});
          },
        }
      );
    };

    const { modalAdd, modalEdit } = this.state;
    const { items, total } = this.props.list;
    const { size, page } = this.props.query;

    return (
      <>
        <Table
          rows={this.createRows(items)}
          headerOptions={headerOptions}
          title={i18n.t('nav:equipment')}
          totalRows={total}
          isLoading={this.props.isLoading}
          size={size}
          page={page}
          changePage={(page) => this.props.getEquipments({ page: page }, {})}
          onClick={() => {
            addModal();
          }}
        />
        {modalAdd.isOpen && (
          <ModalAdd
            loading={false}
            params={modalAdd}
            onSubmit={(data) => {
              addEquip(data);
              this.closeModal(MODAL_ADD);
            }}
            onClose={() => this.closeModal(MODAL_ADD)}
            getLocations={this.props.getLocations}
            getEquipmentTypes={this.props.getEquipmentTypes}
            className="modal-users"
          />
        )}
        {modalEdit.isOpen && (
          <ModalEdit
            loading={false}
            params={modalEdit}
            onSubmit={(data) => {
              updateEquip(data);
              this.closeModal(MODAL_EDIT);
            }}
            onClose={() => this.closeModal(MODAL_EDIT)}
            getLocations={this.props.getLocations}
            getEquipmentTypes={this.props.getEquipmentTypes}
            className="modal-users"
          />
        )}
      </>
    );
  }
}

EquipmentList.propTypes = {
  usersList: PropTypes.array,
  getEquipments: PropTypes.func,
};

const mapStateToProps = ({ equipment }) => {
  const { list, isLoading, query, page } = equipment;
  return { list, isLoading, query, page };
};

const mapDispatchToProps = (dispatch) => ({
  getEquipments: (params, action) => dispatch(actions.getEquipments(params, action)),
  getEquipment: (uuid, action) => dispatch(actions.getEquipment(uuid, action)),
  addEquipment: (params, action) => dispatch(actions.addEquipment(params, action)),
  updateEquipment: (uuid, params, action) => dispatch(actions.updateEquipment(uuid, params, action)),
  deleteEquipment: (params, action) => dispatch(actions.deleteEquipment(params, action)),
  getEquipmentTypes: (params, action) => dispatch(getEquipmentTypes(params, action)),
  getLocations: (params, action) => dispatch(getLocations(params, action)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentList);
