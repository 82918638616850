import * as ActionTypes from '../constants/roles.constants';

export const getRoles = (params, actions) => ({
  type: ActionTypes.GET_ROLES,
  payload: params,
  actions,
});

export const getRole = (id, actions) => ({
  type: ActionTypes.GET_ROLE,
  payload: id,
  actions,
});

export const getAuthorities = (uuid, actions) => ({
  type: ActionTypes.GET_AUTHORITIES,
  payload: uuid,
  actions,
});

export const addRole = (params, actions) => ({
  type: ActionTypes.ADD_ROLE,
  payload: params,
  actions,
});

export const updateRole = (params, actions) => ({
  type: ActionTypes.UPDATE_ROLE,
  payload: params,
  actions,
});

export const deleteRole = (uuid, actions) => ({
  type: ActionTypes.DELETE_ROLE,
  payload: uuid,
  actions,
});

export const changePageValue = (key, value) => ({
  type: ActionTypes.CHANGE_PAGE_VALUE,
  payload: { key, value },
});

export const changeRoleValue = (key, value) => ({
  type: ActionTypes.CHANGE_ROLE_VALUE,
  payload: { key, value },
});
