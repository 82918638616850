import { takeEvery, call, put, select, all, take } from 'redux-saga/effects';

import * as ActionTypes from '../constants/workflows.constants';
import * as api from '../api/workflows';

export function* getWorkflows({ payload, actions = {} }) {
  const workflows = yield select((s) => s.workflows);
  try {
    const response = yield call(api.getWorkflows, {
      ...workflows.query,
      sort: `name-${workflows.sortName}`,
      ...payload,
    });
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_WORKFLOWS_RESULT, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_WORKFLOWS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getWorkflow({ payload, actions = {} }) {
  try {
    const response = yield call(api.getWorkflow, payload.uuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getWorkflowsUsingCameras({ payload, actions = {} }) {
  try {
    const response = yield call(api.getWorkflowsUsingCameras, payload);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getWorkflowPresets({ payload, actions = {} }) {
  try {
    const response = yield call(api.getWorkflowPresets, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.GET_WORKFLOW_PRESETS_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_WORKFLOW_PRESETS_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getWorkflowPreset({ payload, actions = {} }) {
  try {
    const response = yield call(api.getWorkflowPresets, payload);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addWorkflowPreset({ payload, actions = {} }) {
  try {
    const response = yield call(api.addWorkflowPreset, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_WORKFLOW_PRESET_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_WORKFLOW_PRESET_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateWorkflowPreset({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateWorkflowPreset, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_WORKFLOW_PRESET_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_WORKFLOW_PRESET_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteWorkflowPreset({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteWorkflowPreset, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_WORKFLOW_PRESET_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_WORKFLOW_PRESET_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addWorkflow({ payload, actions = {} }) {
  try {
    const response = yield call(api.addWorkflow, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.ADD_WORKFLOW_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.ADD_WORKFLOW_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateWorkflow({ payload, actions = {} }) {
  try {
    const response = yield call(api.updateWorkflow, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPDATE_WORKFLOW_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_WORKFLOW_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteWorkflow({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteWorkflow, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.DELETE_WORKFLOW_SUCCESS, payload: results });
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    yield put({ type: ActionTypes.DELETE_WORKFLOW_ERROR });
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addProcessingToWorkflow({ payload, actions = {} }) {
  try {
    const response = yield call(api.addProcessingToWorkflow, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getWorkflowProcessing({ payload, actions = {} }) {
  try {
    const response = yield call(api.getWorkflowProcessing, payload.uuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addAnalyticsToWorkflow({ payload, actions = {} }) {
  try {
    const response = yield call(api.addAnalyticsToWorkflow, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getWorkflowAnalytics({ payload, actions = {} }) {
  try {
    const response = yield call(api.getWorkflowAnalytics, payload.uuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteWorkflowAnalytic({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteWorkflowAnalytic, payload.workflowUuid, payload.analyticsUuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addTriggersToWorkflow({ payload, actions = {} }) {
  try {
    const response = yield call(api.addTriggersToWorkflow, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* updateWorkflowCameraTrigger({ payload, actions = {} }) {
  try {
    const response = yield call(
      api.updateWorkflowCameraTrigger,
      payload.workflowUuid,
      payload.cameraUuid,
      payload.triggerUuid,
      payload.params
    );
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getWorkflowTriggers({ payload, actions = {} }) {
  try {
    const response = yield call(api.getWorkflowTriggers, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getWorkflowCameras({ payload, actions = {} }) {
  try {
    const response = yield call(api.getWorkflowCameras, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addCamerasToWorkflow({ payload, actions = {} }) {
  try {
    const response = yield call(api.addCamerasToWorkflow, payload.uuid, payload.params);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteWorkflowCamera({ payload, actions = {} }) {
  try {
    const response = yield call(api.deleteWorkflowCamera, payload.workflowUuid, payload.cameraUuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* setZonesToWorkflowCamera({ payload, actions = {} }) {
  try {
    const response = yield call(
      api.setZonesToWorkflowCamera,
      payload.workflowUuid,
      payload.cameraUuid,
      payload.params
    );
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* addZonesToWorkflowCamera({ payload, actions = {} }) {
  try {
    const response = yield call(
      api.addZonesToWorkflowCamera,
      payload.workflowUuid,
      payload.cameraUuid,
      payload.params
    );
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getZonesForWorkflowCamera({ payload, actions = {} }) {
  try {
    const response = yield call(api.getZonesForWorkflowCamera, payload.workflowUuid, payload.cameraUuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getAnalyticsForWorkflowCamera({ payload, actions = {} }) {
  try {
    const response = yield call(api.getAnalyticsForWorkflowCamera, payload.workflowUuid, payload.cameraUuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getTriggersForWorkflowCamera({ payload, actions = {} }) {
  try {
    const response = yield call(
      api.getTriggersForWorkflowCamera,
      payload.workflowUuid,
      payload.cameraUuid,
      payload.params
    );
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* deleteZoneFromWorkflowCamera({ payload, actions = {} }) {
  try {
    const response = yield call(
      api.getZonesForWorkflowCamera,
      payload.workflowUuid,
      payload.cameraUuid,
      payload.zoneUuid
    );
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getProcessingConfigs({ payload, actions = {} }) {
  try {
    const response = yield call(api.getProcessingConfigs, payload);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getProcessingConfig({ payload, actions = {} }) {
  try {
    const response = yield call(api.getProcessingConfig, payload.uuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getExistingProcessingTypes({ actions = {} }) {
  try {
    const response = yield call(api.getExistingProcessingTypes);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getAnalyticBaseConfig({ payload, actions = {} }) {
  try {
    const response = yield call(api.getAnalyticBaseConfig, payload.type);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getAvailableAnalytics({ payload, actions = {} }) {
  try {
    const response = yield call(api.getAvailableAnalytics, payload.uuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* getAvailableTriggers({ payload, actions = {} }) {
  try {
    const response = yield call(api.getAvailableTriggers, payload.uuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* startWorkflow({ payload, actions = {} }) {
  try {
    const response = yield call(api.startWorkflow, payload.uuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export function* stopWorkflow({ payload, actions = {} }) {
  try {
    const response = yield call(api.stopWorkflow, payload.uuid);
    const { success, results } = response;
    if (success) {
      if (actions.onSuccess) {
        actions.onSuccess(results);
      }
    }
  } catch (error) {
    if (actions.onError) {
      actions.onError(error);
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ActionTypes.GET_PROCESSING_CONFIGS, getProcessingConfigs),
    takeEvery(ActionTypes.GET_PROCESSING_CONFIG, getProcessingConfig),
    takeEvery(ActionTypes.GET_ANALYTIC_BASE_CONFIG, getAnalyticBaseConfig),
    takeEvery(ActionTypes.GET_WORKFLOWS, getWorkflows),
    takeEvery(ActionTypes.GET_WORKFLOW, getWorkflow),
    takeEvery(ActionTypes.ADD_WORKFLOW, addWorkflow),
    takeEvery(ActionTypes.UPDATE_WORKFLOW, updateWorkflow),
    takeEvery(ActionTypes.DELETE_WORKFLOW, deleteWorkflow),
    takeEvery(ActionTypes.ADD_PROCESSING_TO_WORKFLOW, addProcessingToWorkflow),
    takeEvery(ActionTypes.GET_WORKFLOW_PROCESSING, getWorkflowProcessing),
    takeEvery(ActionTypes.ADD_ANALYTICS_TO_WORKFLOW, addAnalyticsToWorkflow),
    takeEvery(ActionTypes.GET_WORKFLOW_ANALYTICS, getWorkflowAnalytics),
    takeEvery(ActionTypes.DELETE_WORKFLOW_ANALYTICS, deleteWorkflowAnalytic),
    takeEvery(ActionTypes.ADD_TRIGGERS_TO_WORKFLOW, addTriggersToWorkflow),
    takeEvery(ActionTypes.UPDATE_WORKFLOW_CAMERA_TRIGGER, updateWorkflowCameraTrigger),
    takeEvery(ActionTypes.GET_WORKFLOW_TRIGGERS, getWorkflowTriggers),
    takeEvery(ActionTypes.ADD_CAMERAS_TO_WORKFLOW, addCamerasToWorkflow),
    takeEvery(ActionTypes.GET_WORKFLOW_CAMERAS, getWorkflowCameras),
    takeEvery(ActionTypes.DELETE_WORKFLOW_CAMERA, deleteWorkflowCamera),
    takeEvery(ActionTypes.GET_ANALYTICS_FOR_WORKFLOW_CAMERA, getAnalyticsForWorkflowCamera),
    takeEvery(ActionTypes.GET_TRIGGERS_FOR_WORKFLOW_CAMERA, getTriggersForWorkflowCamera),
    takeEvery(ActionTypes.SET_ZONES_TO_WORKFLOW_CAMERA, setZonesToWorkflowCamera),
    takeEvery(ActionTypes.ADD_ZONES_FOR_WORKFLOW_CAMERA, addZonesToWorkflowCamera),
    takeEvery(ActionTypes.GET_ZONES_FOR_WORKFLOW_CAMERA, getZonesForWorkflowCamera),
    takeEvery(ActionTypes.DELETE_ZONE_FROM_WORKFLOW_CAMERA, deleteZoneFromWorkflowCamera),
    takeEvery(ActionTypes.GET_AVAILABLE_ANALYTICS, getAvailableAnalytics),
    takeEvery(ActionTypes.GET_AVAILABLE_TRIGGERS, getAvailableTriggers),
    takeEvery(ActionTypes.START_WORKFLOW, startWorkflow),
    takeEvery(ActionTypes.STOP_WORKFLOW, stopWorkflow),
    takeEvery(ActionTypes.GET_EXISTING_PROCESSING_TYPES, getExistingProcessingTypes),
    takeEvery(ActionTypes.GET_WORKFLOWS_USING_CAMERAS, getWorkflowsUsingCameras),
    takeEvery(ActionTypes.GET_WORKFLOW_PRESETS, getWorkflowPresets),
    takeEvery(ActionTypes.GET_WORKFLOW_PRESET, getWorkflowPreset),
    takeEvery(ActionTypes.ADD_WORKFLOW_PRESET, addWorkflowPreset),
    takeEvery(ActionTypes.UPDATE_WORKFLOW_PRESET, updateWorkflowPreset),
    takeEvery(ActionTypes.DELETE_WORKFLOW_PRESET, deleteWorkflowPreset),
  ]);
}
