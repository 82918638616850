import { queryStringify } from '../utils/index';
import request from '../utils/request';

export function getMyLimits(params) {
  return request({
    url: `/resource-limits`,
    method: 'get',
    data: params,
  });
}

export function getLimits(params) {
  return request({
    url: `/resource-limits/users`,
    method: 'get',
    data: params,
  });
}

export function addLimit(params) {
  return request({
    url: '/resource-limits/users',
    method: 'post',
    data: params,
  });
}

export function updateLimit(params) {
  return request({
    url: `/resource-limits/users`,
    method: 'post',
    data: params,
  });
}

export function deleteLimit(uuid) {
  return request({
    url: `/resource-limits/users/${uuid}`,
    method: 'delete',
  });
}

export function getAccountLimits(params) {
  return request({
    url: `/resource-limits/accounts`,
    method: 'get',
    data: params,
  });
}

export function addAccountLimit(params) {
  return request({
    url: '/resource-limits/accounts',
    method: 'post',
    data: params,
  });
}

export function updateAccountLimit(params) {
  return request({
    url: `/resource-limits/accounts`,
    method: 'post',
    data: params,
  });
}

export function deleteAccountLimit(uuid) {
  return request({
    url: `/resource-limits/accounts/${uuid}`,
    method: 'delete',
  });
}

export function getUserAllLimits(uuid) {
  return request({
    url: `/resource-limits/users/${uuid}/all`,
    method: 'get',
  });
}
