import { takeEvery, call, put, all } from 'redux-saga/effects';
import * as ActionTypes from '../constants/medias.constants';
import * as api from '../api/medias';

export function* uploadMedia({ payload, callbacks }) {
  try {
    const response = yield call(api.uploadMedia, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.UPLOAD_MEDIA_RESULT, payload: results });
      callbacks && callbacks(response);
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPLOAD_MEDIA_ERROR });
    callbacks && callbacks(error);
  }
}

export function* removeMedia({ payload }) {
  try {
    const response = yield call(api.removeMedia, payload);
    const { success, results } = response;
    if (success) {
      yield put({ type: ActionTypes.REMOVE_MEDIA_RESULT, payload: results });
    }
  } catch (error) {
    yield put({ type: ActionTypes.REMOVE_MEDIA_ERROR });
  }
}

export default function* mediasSagas() {
  yield all([
    takeEvery(ActionTypes.UPLOAD_MEDIA, uploadMedia),
    takeEvery(ActionTypes.REMOVE_MEDIA, removeMedia),
  ]);
}
