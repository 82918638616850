import i18n from '../i18n';
export const E_CODE_COMMON = 1;
export const E_CODE_INVALID_URL = 2;
export const E_CODE_INVALID_FRAME_RATE = 3;
export const ERROR_CODES = {
  [E_CODE_COMMON]: 'common:somethingWentWrong',
  [E_CODE_INVALID_URL]: 'settings:invalidUrl',
  [E_CODE_INVALID_FRAME_RATE]: 'settings:invalidFrameRate',
};

class ErrorService {
  getErrorMsgByErrorCode(errorCode) {
    if (errorCode && ERROR_CODES.hasOwnProperty(errorCode)) {
      return i18n.t(ERROR_CODES[errorCode]);
    }

    return i18n.t(ERROR_CODES[E_CODE_COMMON]);
  }
}

export default new ErrorService();
