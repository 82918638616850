export const GET_EQUIPMENT_TYPES = 'GET_EQUIPMENT_TYPES';
export const GET_EQUIPMENT_TYPES_RESULT = 'GET_EQUIPMENT_TYPES_RESULT';
export const GET_EQUIPMENT_TYPES_ERROR = 'GET_EQUIPMENT_TYPES_ERROR';

export const GET_EQUIPMENT_TYPE = 'GET_EQUIPMENT_TYPE';

export const ADD_EQUIPMENT_TYPE = 'ADD_EQUIPMENT_TYPE';
export const ADD_EQUIPMENT_TYPE_SUCCESS = 'ADD_EQUIPMENT_TYPE_SUCCESS';
export const ADD_EQUIPMENT_TYPE_ERROR = 'ADD_EQUIPMENT_TYPE_ERROR';

export const UPDATE_EQUIPMENT_TYPE = 'UPDATE_EQUIPMENT_TYPE';
export const UPDATE_EQUIPMENT_TYPE_SUCCESS = 'UPDATE_EQUIPMENT_TYPE_SUCCESS';
export const UPDATE_EQUIPMENT_TYPE_ERROR = 'UPDATE_EQUIPMENT_TYPE_ERROR';

export const DELETE_EQUIPMENT_TYPE = 'DELETE_EQUIPMENT_TYPE';
export const DELETE_EQUIPMENT_TYPE_SUCCESS = 'DELETE_EQUIPMENT_TYPE_SUCCESS';
export const DELETE_EQUIPMENT_TYPE_ERROR = 'DELETE_EQUIPMENT_TYPE_ERROR';
