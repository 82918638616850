import * as ActionTypes from '../constants/reports.constants';

const initialState = {
  analyticFilters: {
    workflow: null,
    camera: null,
    fromDate: null,
    toDate: null,
  },
  reportWorkflowType: '',
  maintenanceFilters: {
    workflow: {
      label: 'All',
      value: 'select',
    },
    camera: {
      label: 'All',
      value: 'select',
    },
    fromDate: null,
    toDate: null,
  },
};

export const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_REPORTS_VALUE: {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }

    case ActionTypes.CHANGE_ANALYTIC_FILTER_VALUE: {
      return {
        ...state,
        analyticFilters: {
          ...state.analyticFilters,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case ActionTypes.RESET_ANALYTIC_FILTERS: {
      return {
        ...state,
        analyticFilters: {
          workflow: null,
          camera: null,
          fromDate: new Date(),
          toDate: new Date(),
        },
      };
    }

    case ActionTypes.CHANGE_MAINTENANCE_FILTER_VALUE: {
      return {
        ...state,
        maintenanceFilters: {
          ...state.maintenanceFilters,
          [action.payload.key]: action.payload.value,
        },
      };
    }

    case ActionTypes.RESET_MAINTENANCE_FILTERS: {
      return {
        ...state,
        maintenanceFilters: {
          workflow: {
            label: 'All',
            value: 'select',
          },
          camera: {
            label: 'All',
            value: 'select',
          },
          fromDate: null,
          toDate: null,
        },
      };
    }

    default:
      return state;
  }
};
