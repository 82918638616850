import React from 'react';
import i18n from '../../../i18n';
import BaseModal from './BaseModal';

class ModalAdd extends BaseModal {}

ModalAdd.defaultProps = {
  ...BaseModal.defaultProps,
  header: i18n.t('equipmentTypesModals:addTitle'),
};

export default ModalAdd;
