import { queryStringify } from '../utils';
import request from '../utils/request';

export function getWorkflows(params) {
  return request({
    url: `/workflows?${queryStringify(params)}`,
    method: 'get',
  });
}

export function getWorkflow(uuid) {
  return request({
    url: `/workflows/${uuid}`,
    method: 'get',
  });
}

export function startWorkflow(uuid) {
  return request({
    url: `/workflows/${uuid}/start`,
    method: 'get',
  });
}

export function stopWorkflow(uuid) {
  return request({
    url: `/workflows/${uuid}/stop`,
    method: 'get',
  });
}

export function addWorkflow(params) {
  return request({
    url: '/workflows',
    method: 'post',
    data: params,
  });
}

export function updateWorkflow(uuid, params) {
  return request({
    url: `/workflows/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteWorkflow(uuid) {
  return request({
    url: `/workflows/${uuid}`,
    method: 'delete',
  });
}

export function addProcessingToWorkflow(uuid, params) {
  return request({
    url: `/workflows/${uuid}/processing`,
    method: 'post',
    data: params,
  });
}

export function getWorkflowProcessing(uuid) {
  return request({
    url: `/workflows/${uuid}/processing`,
    method: 'get',
  });
}

export function addAnalyticsToWorkflow(uuid, params) {
  return request({
    url: `/workflows/${uuid}/analytics`,
    method: 'post',
    data: params,
  });
}

export function getWorkflowAnalytics(uuid) {
  return request({
    url: `/workflows/${uuid}/analytics`,
    method: 'get',
  });
}

export function deleteWorkflowAnalytic(workflowUuid, analyticsUuid) {
  return request({
    url: `/workflows/${workflowUuid}/analytics/${analyticsUuid}`,
    method: 'delete',
  });
}

export function addTriggersToWorkflow(uuid, params) {
  return request({
    url: `/workflows/${uuid}/triggers`,
    method: 'post',
    data: params,
  });
}

export function getWorkflowTriggers(uuid, params) {
  return request({
    url: `/workflows/${uuid}/triggers?${queryStringify(params)}`,
    method: 'get',
  });
}

export function getWorkflowCameras(uuid, params) {
  return request({
    url: `/workflows/${uuid}/cameras?${queryStringify(params)}`,
    method: 'get',
  });
}

export function addCamerasToWorkflow(uuid, params) {
  return request({
    url: `/workflows/${uuid}/cameras`,
    method: 'post',
    data: params,
  });
}

export function deleteWorkflowCamera(workflowUuid, cameraUuid) {
  return request({
    url: `/workflows/${workflowUuid}/cameras/${cameraUuid}`,
    method: 'delete',
  });
}

export function setZonesToWorkflowCamera(workflowUuid, cameraUuid, params) {
  return request({
    url: `/workflows/${workflowUuid}/cameras/${cameraUuid}/zones`,
    method: 'post',
    data: params,
  });
}

export function getZonesForWorkflowCamera(workflowUuid, cameraUuid) {
  return request({
    url: `/workflows/${workflowUuid}/cameras/${cameraUuid}/zones`,
    method: 'get',
  });
}

export function getAnalyticsForWorkflowCamera(workflowUuid, cameraUuid) {
  return request({
    url: `/workflows/${workflowUuid}/cameras/${cameraUuid}/analytics`,
    method: 'get',
  });
}

export function getTriggersForWorkflowCamera(workflowUuid, cameraUuid, params) {
  return request({
    url: `/workflows/${workflowUuid}/cameras/${cameraUuid}/triggers?${queryStringify(params)}`,
    method: 'get',
  });
}

export function updateWorkflowCameraTrigger(workflowUuid, cameraUuid, triggerUuid, params) {
  return request({
    url: `/workflows/${workflowUuid}/cameras/${cameraUuid}/triggers/${triggerUuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteZoneFromWorkflowCamera(workflowUuid, cameraUuid, zoneUuid) {
  return request({
    url: `/workflows/${workflowUuid}/cameras/${cameraUuid}/zones/${zoneUuid}`,
    method: 'delete',
  });
}

export function addZonesToWorkflowCamera(workflowUuid, cameraUuid, params) {
  return request({
    url: `/workflows/${workflowUuid}/cameras/${cameraUuid}/zones`,
    method: 'put',
    data: params,
  });
}

export function getProcessingConfigs(type) {
  return request({
    url: `/workflows/processing?${queryStringify(type)}`,
    method: 'get',
  });
}

export function getExistingProcessingTypes() {
  return request({
    url: `/workflows/types`,
    method: 'get',
  });
}

export function getWorkflowsUsingCameras(cameraUuid) {
  return request({
    url: `/workflows/cameras/${cameraUuid}`,
    method: 'get',
  });
}

export function getProcessingConfig(uuid) {
  return request({
    url: `/workflows/processing/${uuid}`,
    method: 'get',
  });
}

export function getAnalyticBaseConfig(type) {
  return request({
    url: `/workflows/analytics/${type}`,
    method: 'get',
  });
}

export function getAvailableAnalytics(uuid) {
  return request({
    url: `/workflows/${uuid}/availableAnalytics`,
    method: 'get',
  });
}

export function getAvailableTriggers(uuid) {
  return request({
    url: `/workflows/${uuid}/availableTriggers`,
    method: 'get',
  });
}


export function getWorkflowPresets(params) {
  return request({
    url: `/workflows/presets`,
    method: 'get',
    data: params
  });
}

export function getWorkflowPreset(uuid) {
  return request({
    url: `/workflows/presets/${uuid}`,
    method: 'get',
  });
}

export function addWorkflowPreset(params) {
  return request({
    url: '/workflows/presets',
    method: 'post',
    data: params,
  });
}

export function updateWorkflowPreset(uuid, params) {
  return request({
    url: `/workflows/presets/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteWorkflowPreset(uuid) {
  return request({
    url: `/workflows/presets/${uuid}`,
    method: 'delete',
  });
}
