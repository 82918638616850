import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import DashBoard from '../container/user/DashBoard';
import CameraView from '../container/user/DashBoard/CameraView';
import Subjects from '../container/user/Subjects';
import SubjectView from '../container/user/Subjects/SubjectView';
import Settings from '../container/user/Settings';
import NotFound from '../container/public/notFound';
import MembersList from '../container/MembersList';
import UsersList from '../container/UsersList';
import EquipmentList from '../container/EquipmentList';
import WorkflowList from '../container/WorkflowList';
import UserProfile from '../container/user/UserProfile';
import * as R from '../constants/routes.constants';
import Markup from '../container/TrainingMarkup';
import AlertsList from '../container/AlertsList';
import AlertRoutesList from '../container/AlertRoutesList';
import RolesList from '../container/RolesList';
import PersonsList from '../container/PersonsList';
import CarsList from '../container/CarsList';
import LocationsList from '../container/LocationsList';
import MaintenanceHistory from '../container/MaintenanceHistory';
import EquipmentTypesList from '../container/EquipmentTypesList';
import ReportsList from '../container/ReportsList';
import AnalyticalReportsPage from '../container/AnalyticalReportsPage';
import CamerasList from '../container/CamerasList';
import LimitsList from '../container/LimitsList';
import UserLimitsList from '../container/LimitsList/UserLimits';
import AccountsList from '../container/AccountsList';
import AccountUsers from '../container/UsersList/AccountUsers';
import AccountLimits from '../container/LimitsList/AccountLimits';

import { pageAuthorities } from '../constants/roles.constants';

const UserRouter = ({ user }) => {
  const checkRole = (page = 'Dashboard') => {
    if (user.authorities?.some((auth) => pageAuthorities[page].includes(auth))) {
      return true;
    } else {
      console.log("You don't have permission to visit this page");
      return false;
    }
  };
  return (
    <Switch>
      <Route path="/" exact render={() => <Redirect to={R.DASHBOARD} />} />
      <Route
        exact
        path={R.DASHBOARD}
        render={() => (checkRole('Dashboard') ? <DashBoard /> : <Redirect to={R.USERPROFILE} />)}
      />
      <Route
        exact
        path={`${R.DASHBOARD}/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`}
        component={checkRole('CameraView') ? CameraView : NotFound}
      />
      <Route exact path={R.SUBJECTS} component={checkRole('Subjects') ? Subjects : NotFound} />
      <Route exact path={R.LIMITS} component={LimitsList} />
      <Route
        exact
        path={`${R.USERLIMITS}/:uuid`}
        component={checkRole('UserLimitsList') ? UserLimitsList : NotFound}
      />
      <Route exact path={`${R.SUBJECTS}/:uuid`} component={checkRole('Subjects') ? SubjectView : NotFound} />
      <Route exact path={R.SETTINGS} component={Settings} />
      <Route exact path={R.MEMBERS} component={MembersList} />
      <Route exact path={R.USERS} component={checkRole('UsersList') ? UsersList : NotFound} />
      <Route exact path={R.ACCOUNTS} component={checkRole('AccountsList') ? AccountsList : NotFound} />
      <Route exact path={R.ACCOUNTUSERS} component={checkRole('AccountUsers') ? AccountUsers : NotFound} />
      <Route
        exact
        path={`${R.ACCOUNTUSERS}/:uuid`}
        component={checkRole('AccountUsers') ? AccountUsers : NotFound}
      />
      <Route
        exact
        path={`${R.ACCOUNTLIMITS}/:uuid`}
        component={checkRole('AccountLimits') ? AccountLimits : NotFound}
      />
      <Route exact path={R.ACCOUNTLIMITS} component={checkRole('AccountLimits') ? AccountLimits : NotFound} />
      <Route exact path={R.EQUIPMENTS} component={checkRole('EquipmentList') ? EquipmentList : NotFound} />
      <Route
        exact
        path={`${R.EQUIPMENTS}/:id`}
        component={checkRole('EquipmentList') ? MaintenanceHistory : NotFound}
      />
      <Route
        exact
        path={R.EQUIPMENTTYPES}
        component={checkRole('EquipmentList') ? EquipmentTypesList : NotFound}
      />
      <Route exact path={R.WORKFLOW} component={checkRole('WorkflowList') ? WorkflowList : NotFound} />
      <Route exact path={R.CAMERAS} component={checkRole('CamerasList') ? CamerasList : NotFound} />
      <Route exact path={R.USERPROFILE} component={UserProfile} />
      <Route exact path={R.TRAININGMARKUP} component={checkRole('TrainingMarkup') ? Markup : NotFound} />
      <Route exact path={R.ALERTMANAGEMENT} component={checkRole('AlertsList') ? AlertsList : NotFound} />
      <Route exact path={R.ALERTROUTES} component={checkRole('AlertsList') ? AlertRoutesList : NotFound} />
      <Route exact path={R.ROLES} component={checkRole('RolesList') ? RolesList : NotFound} />
      <Route exact path={R.PERSONS} component={checkRole('Subjects') ? PersonsList : NotFound} />
      <Route exact path={R.CARS} component={checkRole('Subjects') ? CarsList : NotFound} />
      <Route exact path={R.LOCATIONS} component={checkRole('LocationsList') ? LocationsList : NotFound} />
      <Route exact path={`${R.REPORT}/:id`} component={ReportsList} />
      <Route exact path={`${R.ANALYTICAL_REPORTS}`} component={AnalyticalReportsPage} />
      <Route exact path={'/replace'} component={() => <Redirect to={R.DASHBOARD} />} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default UserRouter;
