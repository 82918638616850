import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../components/Table';
import i18n from '../../i18n';
import DropdownDotMenu from '../../components/DropdownDotMenu';
import { userStatuses } from '../../constants/userConstants';
import moment from 'moment';

const MaintenanceProceduresTable = ({ items }) => {
  const createRows = (rows) => {
    return rows.map((item) => ({
      id: item.id,
      cells: [
        {
          label: item.name,
          cellComponent: <h5 className="table__cell table__cell--bold">{item.name}</h5>,
        },
        {
          label: moment(item.date).format('MMMM DD, YYYY'),
        },
        {
          label: Array.isArray(item.operations) ? item.operations.join('\n') : item.operations || '-',
        },
        {
          label: 'menu',
          align: 'right',
          cellComponent: (
            <DropdownDotMenu
              options={[
                { name: 'Edit', onClick: () => {} },
                { name: 'Deactivate', onClick: () => {}, hasBorderBottom: true },
                { divider: true },
                { name: 'Delete', onClick: () => {} },
              ]}
            />
          ),
        },
      ],
    }));
  };
  const headerOptions = [
    {
      label: i18n.t('common:name'),
    },
    {
      label: i18n.t('common:date'),
    },
    {
      label: i18n.t('equipment:operation'),
    },
    {
      label: '',
    },
  ];

  return (
    <Table
      rows={createRows(items)}
      headerOptions={headerOptions}
      title={i18n.t('equipment:maintenanceProcedures')}
      totalRows={56}
      isLoading={false}
      disableWreapperPadding
    />
  );
};

MaintenanceProceduresTable.propTypes = {
  items: PropTypes.array,
};

export default MaintenanceProceduresTable;
