export const GET_ALERTS = 'GET_ALERTS';
export const GET_ALERTS_RESULT = 'GET_ALERTS_RESULT';
export const GET_ALERTS_ERROR = 'GET_ALERTS_ERROR';
export const GET_ALERTS_NEXT_PAGE = 'GET_ALERTS_NEXT_PAGE';
export const GET_ALERTS_NEXT_PAGE_RESULT = 'GET_ALERTS_NEXT_PAGE_RESULT';
export const GET_ALERTS_NEXT_PAGE_ERROR = 'GET_ALERTS_NEXT_PAGE_ERROR';
export const ADD_ALERT = 'ADD_ALERT';

export const GET_ALERT_ROUTES = 'GET_ALERT_ROUTES';
export const GET_ALERT_ROUTES_RESULT = 'GET_ALERT_ROUTES_RESULT';
export const GET_ALERT_ROUTES_ERROR = 'GET_ALERT_ROUTES_ERROR';
export const ADD_ALERT_ROUTE = 'ADD_ALERT_ROUTE';
export const GET_ALERT_ROUTE = 'GET_ALERT_ROUTE';
export const DELETE_ALERT_ROUTE = 'DELETE_ALERT_ROUTE';

export const CHANGE_ALERT_VALUE = 'CHANGE_ALERT_VALUE';

export const ALERT_TIME_FORMAT = 'h:mm:ss A';

export const subjectTypes = [
  {
    type: ['FACE_DETECTED', 'FACE_ALERT_OCCURRED'],
    name: 'Person Detected',
    triggers: ['KnownFaceVectorSubjects', 'UnknownFaceVectorSubjects'],
  },
  {
    type: ['WORKFLOW_STARTED'],
    name: 'Workflow started',
    triggers: [],
  },
  {
    type: ['WORKFLOW_STOPPED'],
    name: 'Workflow stopped',
    triggers: [],
    error: true,
  },
  { type: ['GROUP_MIN_DISTANCE_DETECTED'], name: 'Group Min Distance Alert', triggers: ['GroupMinDistance'] },
  { type: ['SMALL_GROUP_DETECTED'], name: 'Small Group Detected', triggers: ['SmallGroup'] },
  { type: ['LARGE_GROUP_DETECTED'], name: 'Large Group Detected', triggers: ['LargeGroup'] },
  {
    type: ['MIN_DISTANCE_IN_ZONE_DETECTED'],
    name: 'Min Distance in Zone Alert',
    triggers: ['ZoneMinDistance'],
  },
  { type: ['LOW_OCCUPANCY_DETECTED'], name: 'Low Occupancy Detected', triggers: ['ZoneLowOccupancy'] },
  { type: ['HIGH_OCCUPANCY_DETECTED'], name: 'High Occupancy Detected', triggers: ['ZoneHighOccupancy'] },
  {
    type: ['LOW_ACTIVITY_IN_INTERVAL_OCCUMULATED'],
    name: 'Low Activity in Interval Alert',
    triggers: ['AccumulationIntervalLowActivity'],
  },
  {
    type: ['HIGH_ACTIVITY_IN_INTERVAL_OCCUMULATED'],
    name: 'High Activity in Interval Alert',
    triggers: ['AccumulationIntervalHighActivity'],
  },
  {
    type: ['OUTSIDE_ZONE_DETECTED'],
    name: 'Object Outside Zone Detected',
    triggers: ['DetectionsOutsideZone'],
  },
  { type: ['FACE_WITHOUT_MASK_DETECTED'], name: 'Face Without Mask Detected', triggers: ['FaceWithoutMask'] },
  { type: ['LARGE_QUEUE_DETECTED'], name: 'Large Queue Detected', triggers: ['LargeQueue'] },
  { type: ['TOO_FEW_PEOPLE_DETECTED'], name: 'Too Few People Alert', triggers: ['TooFewPeople'] },
  { type: ['TOO_MANY_PEOPLE_DETECTED'], name: 'Too Many People Alert', triggers: ['TooManyPeople'] },
  { type: ['CAR_DAMAGE_PERCENT_DETECTED'], name: 'Car Damage Percent Alert', triggers: ['CarDamagePercent'] },
  {
    type: ['CAR_DAMAGE_SEVERITY_DETECTED'],
    name: 'Car Damage Severity Alert',
    triggers: ['CarDamageSeverity'],
  },
  {
    type: ['CAR_DETECTED', 'CAR_ALERT_OCCURRED'],
    name: 'Car Detected',
    triggers: ['KnownLicensePlateSubjects', 'UnknownLicensePlateSubjects'],
  },
  {
    type: ['WRONG_LINE_POSITION_DETECTED'],
    name: 'Wrong Line Position Alert',
    triggers: ['WrongLinePosition'],
  },
  {
    type: ['LOW_ACTIVITY_IN_ZONE_DETECTED'],
    name: 'Detected Low Activity In Zone',
    triggers: ['ZoneLowActivity'],
  },
  {
    type: ['HIGH_ACTIVITY_IN_ZONE_DETECTED'],
    name: 'Detected High Activity In Zone',
    triggers: ['ZoneHighActivity'],
  },
  {
    type: ['ZONE_ACTIVITY_DETECTED'],
    name: 'Activity in Zone Detected',
    triggers: ['ZoneActivityDetected'],
  },
  { type: ['INSIDE_ZONE_DETECTED'], name: 'Object Inside Zone Detected', triggers: ['DetectionsInsideZone'] },
  {
    type: ['INTERVAL_ACCUMULATION_READY_ALERT'],
    name: 'Interval Accumulation Is Ready',
    triggers: ['IntervalAccumulationAnalyticsReady'],
  },
  { type: ['WORKFLOW_STATUS_CHANGED'], name: 'Workflow status changed', triggers: ['WorkflowStatus'] },
];

export const Analytics = [
  { type: ['FACE_RECOGNITION', 'FACE_PROPERTY'], analytic: [{ title: 'Show bbox and info' }] },
  {
    type: ['ALPR_FOR_BARRIER_GATES', 'ALPR_WITH_DEWARP'],
    analytic: [{ title: 'plate bbox' }, { title: 'car bbox' }, { title: 'info' }],
  },
];

// export const eventTypes = [
//   'ACCUMULATION_INTERVAL_HIGH_ACTIVITY',
//   'ACCUMULATION_INTERVAL_LOW_ACTIVITY',
//   'CAR_DAMAGE_PERCENT',
//   'CAR_DAMAGE_SEVERITY',
//   'DETECTIONS_INSIDE_ZONE',
//   'FACE_WITHOUT_MASK',
//   'GROUP_MIN_DISTANCE',
//   'INTERVAL_ACCUMULATION_ANALYTICS_READY',
//   'KNOWN_FACE_VECTOR_SUBJECTS',
//   'KNOWN_LICENSE_PLATE_SUBJECTS',
//   'LARGE_GROUP',
//   'LARGE_QUEUE',
//   'SMALL_GROUP',
//   'TOO_FEW_PEOPLE',
//   'TOO_MANY_PEOPLE',
//   'UNKNOWN_FACE_VECTOR_SUBJECTS',
//   'UNKNOWN_LICENSE_PLATE_SUBJECTS',
//   'WRONG_LINE_POSITION',
//   'ZONE_ACTIVITY_DETECTED',
//   'ZONE_HIGH_ACTIVITY',
//   'ZONE_HIGH_OCCUPANCY',
//   'ZONE_LOW_ACTIVITY',
//   'ZONE_LOW_OCCUPANCY',
//   'ZONE_MIN_DISTANCE',
// ];

export const eventTypes = [
  'AccumulationIntervalHighActivity',
  'AccumulationIntervalLowActivity',
  'CarDamagePercent',
  'CarDamageSeverity',
  'DetectionsInsideZone',
  'FaceWithoutMask',
  'GroupMinDistance',
  'IntervalAccumulationAnalyticsReady',
  'KnownFaceVectorSubjects',
  'KnownLicensePlateSubjects',
  'LargeGroup',
  'LargeQueue',
  'SmallGroup',
  'TooFewPeople',
  'TooManyPeople',
  'UnknownFaceVectorSubjects',
  'UnknownLicensePlateSubjects',
  'WrongLinePosition',
  'ZoneActivityDetected',
  'ZoneHighActivity',
  'ZoneHighOccupancy',
  'ZoneLowActivity',
  'ZoneLowOccupancy',
  'ZoneMinDistance',
];

// export const timelineKnownEventTypes = ['KNOWN_FACE_VECTOR_SUBJECTS'];
export const timelineKnownEventTypes = ['KnownFaceVectorSubjects'];

export const timelineEventTypes = ['KnownFaceVectorSubjects', 'UnknownFaceVectorSubjects'];

export const destinations = {
  EMAIL: 'Email',
};
