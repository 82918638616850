import * as ActionTypes from '../constants/cameras.constants';
import * as PlayerTypes from '../constants/player.constants';

const initialState = {
  list: {
    items: [],
    total: 0,
  },
  query: {
    size: 100,
    page: 0,
  },
  isLoading: false,
  isError: null,
};

export const cameraAlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CAMERA_ALERTS:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.ADD_CAMERA_ALERT: {
      const items = [action.payload, ...state.list.items].sort((a, b) => a - b);
      return {
        ...state,
        list: {
          ...state.list,
          items,
          total: state.list.total + 1,
        },
        query: {
          ...state.query,
          page: Math.floor(items.length / state.query.size),
        },
      };
    }

    case ActionTypes.GET_CAMERA_ALERTS_RESULT: {
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items: [...state.list.items, ...items].sort((a, b) => a - b), total },
        query: { size, page },
        isLoading: false,
      };
    }

    case ActionTypes.GET_CAMERA_ALERTS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case PlayerTypes.SET_RANGE_PLAYER:
      return initialState;

    case ActionTypes.SET_CAMERA_UNMOUNT:
      return initialState;

    default:
      return state;
  }
};
