import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Rect, Line, Text, Arrow } from 'react-konva';

import * as eventTypes from '../../constants/event.constants';
import moment from 'moment';
import { calculateCentralPoint, calculateDistantPoint } from '../../utils/draws';
import { contrastingColors } from '../../constants/workflows.constants';
import { defaultOffsetsByFps } from '../../constants/cameras.constants';

const CLEAR_DETECTION_DELAY = 200;

const DrawingLayerRecordings = ({
  width,
  height,
  recordPause,
  detectionType,
  tsDetections,
  zones,
  showOptions,
  cameraFPS,
  getHistoryDataTS,
  recordingFromTime,
  getPlayerOffsetTime,
  setCurrent,
}) => {
  const canvasEl = useRef(null);
  const [currentDetectionsTS, setCurrentDetectionsTS] = useState(null);
  const [findOffset, setFindOffset] = useState(null);

  const refTsDetections = useRef({});
  const recordingFromTimeRef = useRef(0);
  const playerPauseRef = useRef(null);

  useEffect(() => {
    recordingFromTimeRef.current = recordingFromTime;
  }, [recordingFromTime]);

  useEffect(() => {
    playerPauseRef.current = recordPause;
    if (!recordPause) {
      findAndSetTimeout();
    } else {
      findNearestNextAndSetCurrent();
    }
  }, [recordPause]);

  useEffect(() => {
    refTsDetections.current = tsDetections;
    if (!playerPauseRef.current) {
      findAndSetTimeout();
    }
  }, [tsDetections]);

  const findNearestNextAndSetCurrent = () => {
    const timestamps = Object.keys(refTsDetections.current).sort();
    if (timestamps.length < 2) return;

    const playerTime = getPlayerOffsetTime();
    let minDiff = Math.abs(playerTime - timestamps[0]);
    let nearestNextTS = timestamps[0];

    timestamps.map((ts, index, array) => {
      if (index !== array.length - 1) {
        if (ts - playerTime >= 0 && minDiff > ts - playerTime) {
          minDiff = ts - playerTime;
          nearestNextTS = ts;
        }
      }
      return ts;
    });

    if (Math.abs(nearestNextTS - playerTime) < 1000) {
      setCurrent(recordingFromTimeRef.current + Number(nearestNextTS), true);
      // setTimeout(() => setCurrentDetectionsTS(refTsDetections.current[nearestNextTS]), CLEAR_DETECTION_DELAY);
    }
  };

  const findAndSetTimeout = () => {
    const oldTimestamps = Object.keys(tsDetections).sort();
    const timestamps = Object.keys(refTsDetections.current).sort();
    if (timestamps.length < 2 || oldTimestamps[0] !== timestamps[0] || playerPauseRef.current) return;

    setCurrentDetectionsTS(findNearestTS(refTsDetections.current, getPlayerOffsetTime()));

    const playerTime = getPlayerOffsetTime();

    let minDiff = Math.abs(playerTime - timestamps[0]);
    let nextTS = timestamps[0];

    timestamps.map((ts, index, array) => {
      if (index !== array.length - 1) {
        if (ts - playerTime >= 0 && minDiff > ts - playerTime) {
          minDiff = ts - playerTime;
          nextTS = ts;
        }
      }
      return ts;
    });

    const startOffset = nextTS - playerTime;

    if (startOffset >= 0 && startOffset <= 1500) {
      setTimeout(() => {
        findAndSetTimeout();
      }, startOffset);
    } else {
      setTimeout(() => {
        findAndSetTimeout();
      }, 1500);
    }
  };

  const findNearestTS = (timestamps, timeTS) => {
    if (!timestamps || !Object.keys(timestamps).length) return [];
    const timeWithOffset = timeTS;

    const tsKeys = Object.keys(timestamps);
    let minDiff = Math.abs(timeWithOffset - tsKeys[0]);
    let nearestTS = tsKeys[0];

    tsKeys.map((ts, index, array) => {
      if (index !== array.length - 1) {
        if (minDiff > Math.abs(timeWithOffset - ts)) {
          minDiff = Math.abs(timeWithOffset - ts);
          nearestTS = ts;
        }
      }

      return ts;
    });

    if (Math.abs(nearestTS - timeWithOffset) < 20) {
      // setTimeout(() => {
      //   setCurrentDetectionsTS(null);
      // }, CLEAR_DETECTION_DELAY);
      return timestamps[nearestTS];
    } else {
      return null;
    }
  };

  const drawLines =
    zones.lines && zones.lines.length
      ? zones.lines.map((line, index) => {
          const points = [
            line.endPoints[0].x * width,
            line.endPoints[0].y * height,
            line.endPoints[1].x * width,
            line.endPoints[1].y * height,
          ];
          const centralPoint = calculateCentralPoint(...points);
          const normalPoint = calculateDistantPoint(...points, 30);

          return (
            <>
              <Line points={points} stroke={contrastingColors[index]} />
              <Arrow
                points={[centralPoint.x, centralPoint.y, normalPoint.x, normalPoint.y]}
                stroke={contrastingColors[index]}
                fill={contrastingColors[index]}
              />
            </>
          );
        })
      : null;

  const drawZoi =
    zones.zoi && zones.zoi.length
      ? zones.zoi.map((polygon, index) => {
          const points = [];
          const entrypoint = [];

          polygon.points.map((point) => {
            points.push(point.x * width, point.y * height);
          });

          polygon.entrypoint.map((point) => {
            entrypoint.push(point.x * width, point.y * height);
          });

          return (
            <>
              <Line
                closed
                stroke={contrastingColors[index]}
                fill={`${
                  contrastingColors[index] +
                  (currentDetectionsTS?.zonesActivityStatus.find((zone) => zone.zoneId === polygon.id)
                    ?.zoneActive
                    ? '4D'
                    : '00')
                }`}
                strokeWidth={3}
                points={points}
              />
              <Line closed stroke={contrastingColors[index]} strokeWidth={3} points={entrypoint} />
            </>
          );
        })
      : null;

  const drawDetections = (detections) => {
    switch (detectionType) {
      case 'FACE_RECOGNITION':
        return detections.map((detection) => {
          return detection.faceBoundingBox ? (
            <>
              <Rect
                stroke={detection.found ? 'yellow' : 'red'}
                fill={'transparent'}
                strokeWidth={3}
                cornerRadius={2}
                x={detection.faceBoundingBox.left * width}
                y={detection.faceBoundingBox.top * height}
                width={(detection.faceBoundingBox.right - detection.faceBoundingBox.left) * width}
                height={(detection.faceBoundingBox.bottom - detection.faceBoundingBox.top) * height}
              />
            </>
          ) : null;
        });

      case 'FACE_PROPERTY':
        return detections.map((detection) => {
          return detection.faceBoundingBox ? (
            <>
              <Rect
                stroke={detection.found ? 'yellow' : 'red'}
                fill={'transparent'}
                strokeWidth={3}
                cornerRadius={2}
                x={detection.faceBoundingBox.left * width}
                y={detection.faceBoundingBox.top * height}
                width={(detection.faceBoundingBox.right - detection.faceBoundingBox.left) * width}
                height={(detection.faceBoundingBox.bottom - detection.faceBoundingBox.top) * height}
              />
              <Text
                fontFamily="NunitoSans"
                fontVariant="small-caps"
                align={'center'}
                verticalAlign={'center'}
                text={
                  ('Mask Presence: ',
                  detection.maskPresence,
                  '\nGender: ',
                  detection.gender,
                  '\nAge: ',
                  detection.age)
                }
                fontSize={16}
                fontStyle={'bold'}
                x={detection.carBoundingBox.left * width + 10}
                y={detection.carBoundingBox.bottom * height + 10}
              />
            </>
          ) : null;
        });

      case eventTypes.LARGE_GROUP_DETECTED:
      case eventTypes.SMALL_GROUP_DETECTED:
      case eventTypes.GROUP_MIN_DISTANCE_DETECTED:
      case eventTypes.LARGE_QUEUE_DETECTED:
        return null;

      case 'ALPR':
      case 'ALPR_WITH_DEWARP':
      case 'ALPR_FOR_BARRIER_GATES':
        return detections.map((detection) => {
          return (
            <>
              <Rect
                stroke={'yellow'}
                fill={'transparent'}
                strokeWidth={3}
                cornerRadius={2}
                x={detection.carBoundingBox.left * width}
                y={detection.carBoundingBox.top * height}
                width={(detection.carBoundingBox.right - detection.carBoundingBox.left) * width}
                height={(detection.carBoundingBox.bottom - detection.carBoundingBox.top) * height}
              />
              {detection.plateLabel && (
                <Text
                  fontFamily="NunitoSans"
                  fontVariant="small-caps"
                  align={'center'}
                  verticalAlign={'center'}
                  text={detection.plateLabel}
                  fontSize={16}
                  fontStyle={'bold'}
                  x={detection.carBoundingBox.left * width + 10}
                  y={detection.carBoundingBox.top * height + 10}
                />
              )}
              {detection.plateBoundingBox && (
                <Rect
                  stroke={'yellow'}
                  fill={'transparent'}
                  strokeWidth={3}
                  cornerRadius={2}
                  x={detection.plateBoundingBox.left * width}
                  y={detection.plateBoundingBox.top * height}
                  width={(detection.plateBoundingBox.right - detection.plateBoundingBox.left) * width}
                  height={(detection.plateBoundingBox.bottom - detection.plateBoundingBox.top) * height}
                />
              )}
            </>
          );
        });

      default:
        return null;
    }
  };

  return (
    <Stage style={{ position: 'absolute', zIndex: 10 }} ref={canvasEl} width={width} height={height}>
      <Layer>
        {showOptions.isShowLines && drawLines}
        {showOptions.isShowZones && drawZoi}
        {showOptions.isShowObjects && currentDetectionsTS && currentDetectionsTS.length
          ? drawDetections(currentDetectionsTS)
          : null}
      </Layer>
    </Stage>
  );
};

export default DrawingLayerRecordings;
