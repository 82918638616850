import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../../i18n';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import cx from 'classnames';
import Loader from '../../../../components/Loader';
import { RTSP_REGEXP } from '../../../../constants/cameras.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import errorService, { E_CODE_INVALID_FRAME_RATE } from '../../../../services/ErrorService';

const VERIFY_DELAY = 700;
const VERIFY_PERIOD_DELAY = 1000;
const validationSchema = yup.object().shape({
  name: yup
    .string()
    .max(250, i18n.t('validationMessages:length', { field: 'name', length: 250 }))
    .required(i18n.t('validationMessages:required', { field: 'name' })),
  location: yup
    .string()
    .max(250, i18n.t('validationMessages:length', { field: 'location', length: 250 }))
    .required(i18n.t('validationMessages:required', { field: 'location' })),
  streamUrl: yup
    .string()
    .matches(RTSP_REGEXP, i18n.t('validationMessages:url', { field: 'url' }))
    .required(i18n.t('validationMessages:required', { field: 'url' })),
});

class BaseModal extends Component {
  state = {
    hasStreamError: false,
    isVerification: false,
    connecting: false,
    message: null,
    cameraFrame: null,
    verifyTimer: null,
    verifyDelayTimer: null,
    notVerifiedUrl: null,
    verifiedUrl: null,
  };

  static getDerivedStateFromProps(nextProps) {
    const {
      params: { errorMsg },
    } = nextProps;

    if (errorMsg) {
      return { message: errorMsg };
    }

    return null;
  }

  verificationFailed = (errorMsg = null) => {
    this.setState({
      message: errorMsg,
      hasStreamError: true,
      isVerification: false,
      connecting: false,
      cameraFrame: null,
    });
  };

  hideMessage = () => {
    if (this.state.message) {
      this.setState({ message: null });
      if (this.props.params.errorMsg && typeof this.props.params.resetError === 'function') {
        this.props.params.resetError();
      }
    }
  };

  verifyStreamUrl = (streamUrl) => {
    if (!this.state.isVerification) {
      if (this.verifyDelayTimer) {
        clearTimeout(this.verifyDelayTimer);
      }

      this.verifyDelayTimer = setTimeout(() => {
        this.setState(
          {
            message: null,
            hasStreamError: false,
            isVerification: true,
            connecting: !this.state.cameraFrame,
            notVerifiedUrl: null,
            verifiedUrl: null,
          },
          () => {
            this.props.verifyCamera(streamUrl, {
              onSuccess: (verification) => {
                if (verification.success) {
                  this.setState(
                    {
                      isVerification: false,
                      connecting: false,
                      cameraFrame: `data:image/jpeg;base64,${verification.data}`,
                      verifiedUrl: streamUrl,
                    },
                    () => {
                      if (!this.state.notVerifiedUrl) {
                        if (this.verifyTimer) {
                          clearTimeout(this.verifyTimer);
                        }

                        this.verifyTimer = setTimeout(() => {
                          this.verifyStreamUrl(this.state.verifiedUrl);
                        }, VERIFY_PERIOD_DELAY);
                      }
                    }
                  );
                } else {
                  let errorMsg = null;
                  const errorCode = verification.errorCode ? parseInt(verification.errorCode, 10) : null;

                  if (errorCode === E_CODE_INVALID_FRAME_RATE) {
                    errorMsg = errorService.getErrorMsgByErrorCode(errorCode);
                  }

                  this.verificationFailed(errorMsg);
                }
              },
              onError: (error) => {
                this.verificationFailed(error.message);
              },
              onComplete: () => {
                if (this.state.notVerifiedUrl) {
                  this.verifyStreamUrl(this.state.notVerifiedUrl);
                }
              },
            });
          }
        );
      }, VERIFY_DELAY);
    } else {
      this.setState({ notVerifiedUrl: streamUrl });
    }
  };

  onSubmit = (values, { setSubmitting }) => {
    const {
      params: { camera },
    } = this.props;

    if (
      camera.name === values.name &&
      camera.location === values.location &&
      camera.streamUrl === values.streamUrl
    ) {
      this.setState({ message: i18n.t('form:hasNoChanges') });
    } else {
      this.props.onSubmit(values);
    }

    setSubmitting(false);
  };

  render() {
    const { hasStreamError, connecting, message, cameraFrame } = this.state;
    const {
      params: { isOpen, camera },
      header,
      onClose,
      loading,
      submitBtnText,
      closeBtnText,
      className,
    } = this.props;

    return (
      <Modal isOpen={isOpen} className={cx('', className)} fade>
        <ModalHeader toggle={onClose}>{header}</ModalHeader>
        <Formik
          onSubmit={this.onSubmit}
          initialValues={{
            name: camera.name,
            location: camera.location,
            streamUrl: camera.streamUrl,
            fps: camera.fps,
          }}
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values: { name, location, streamUrl, fps = 5 },
              touched,
              errors,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isValid,
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                <ModalBody>
                  <div className="form-fields">
                    <div className={`form-group form-group-compress`}>
                      <input
                        name="name"
                        type="text"
                        value={name}
                        onBlur={handleBlur}
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue('name', e.target.value);
                          this.hideMessage();
                        }}
                      />
                      <label className={`form-group-label${name ? '--active' : ''}`} htmlFor="name">
                        {i18n.t('cameraModals:labelName')}
                      </label>
                      {errors.name && touched.name && <div className="invalid-feedback">{errors.name}</div>}
                    </div>
                    <div className="form-group form-group-compress">
                      <input
                        name="location"
                        type="text"
                        value={location}
                        onBlur={handleBlur}
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue('location', e.target.value);
                          this.hideMessage();
                        }}
                      />
                      <label className={`form-group-label${location ? '--active' : ''}`} htmlFor="location">
                        {i18n.t('cameraModals:labelLocation')}
                      </label>
                      {errors.location && touched.location && (
                        <div className="invalid-feedback">{errors.location}</div>
                      )}
                    </div>
                    <div className="form-group form-group-compress">
                      <input
                        name="streamUrl"
                        type="text"
                        value={streamUrl}
                        onBlur={handleBlur}
                        className="form-control"
                        onChange={(e) => {
                          const streamUrl = e.target.value;
                          setFieldValue('streamUrl', streamUrl);
                          this.hideMessage();

                          if (streamUrl.length && RTSP_REGEXP.test(streamUrl)) {
                            this.verifyStreamUrl(streamUrl);
                          }
                        }}
                      />
                      <label className={`form-group-label${streamUrl ? '--active' : ''}`} htmlFor="streamUrl">
                        {i18n.t('cameraModals:labelStreamUrl')}
                      </label>
                      {errors.streamUrl && touched.streamUrl && (
                        <div className="invalid-feedback">{errors.streamUrl}</div>
                      )}
                    </div>
                    <div className="form-group form-group-compress">
                      <input
                        name="fps"
                        type="text"
                        value={fps}
                        onBlur={handleBlur}
                        className="form-control"
                        onChange={(e) => {
                          const fps = e.target.value;
                          setFieldValue('fps', fps);
                          this.hideMessage();
                        }}
                      />
                      <label className={`form-group-label${fps ? '--active' : ''}`} htmlFor="streamUrl">
                        {i18n.t('cameraModals:labelFps')}
                      </label>
                      {errors.fps && touched.fps && (
                        <div className="invalid-feedback">{errors.streamUrl}</div>
                      )}
                    </div>
                  </div>
                  <div className="video-upload">
                    <label htmlFor="streamUrl">{i18n.t('cameraModals:labelPreview')}</label>
                    <div className="camera-preview">
                      {!streamUrl || errors.streamUrl ? (
                        <>
                          <FontAwesomeIcon icon="video" />
                          <span>{i18n.t('cameraModals:previewNoConnected')}</span>
                        </>
                      ) : (
                        <Fragment>
                          {connecting && (
                            <div className="connect">
                              <Loader loading={connecting} />
                              <span>{i18n.t('cameraModals:previewLoading')}</span>
                            </div>
                          )}
                          {hasStreamError && (
                            <div className="error">
                              <FontAwesomeIcon icon="unlink" />
                              <span>{i18n.t('cameraModals:previewError')}</span>
                            </div>
                          )}
                          <img src={cameraFrame} alt="" />
                        </Fragment>
                      )}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Loader loading={loading} />
                  {message || null}
                  <button type="button" className="btn btn--dark" disabled={loading} onClick={onClose}>
                    {closeBtnText}
                  </button>
                  <button
                    className="btn btn--secondary"
                    type="submit"
                    disabled={loading || !cameraFrame || !isValid}
                  >
                    {submitBtnText}
                  </button>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

BaseModal.defaultProps = {
  loading: false,
  submitBtnText: i18n.t('buttons:save'),
  closeBtnText: i18n.t('buttons:cancel'),
};

BaseModal.propTypes = {
  params: PropTypes.shape({
    isOpen: PropTypes.bool,
    camera: PropTypes.shape({
      name: PropTypes.string,
      location: PropTypes.string,
      streamUrl: PropTypes.string,
      fps: PropTypes.number,
    }),
  }),
  loading: PropTypes.bool,
  header: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  verifyCamera: PropTypes.func,
  submitBtnText: PropTypes.string,
  closeBtnText: PropTypes.string,
  className: PropTypes.string,
};

export default BaseModal;
