import request from '../utils/request';

export function liveStreamDrawerStart(params) {
  return request({
    url: `/liveStreamDrawer/start`,
    method: 'post',
    data: params,
  });
}

export function liveStreamDrawerStop(params) {
  return request({
    url: `/liveStreamDrawer/stop`,
    method: 'post',
    data: params,
  });
}
