import moment from 'moment';

export default class DateTimeService {
  static timestamps = timestamp => new Date(timestamp);
  static newDateFormat = date => new Date(moment(date).format());
  static subtractToTS = (val, type) => {
    return Number(
      moment()
        .subtract(val, type)
        .format('x')
    );
  };
  static getTimestamp = date => Number(moment(date).format('x'));
  static getTimestampX = date => Number(moment(date).format('X'));
}
