import * as ActionTypes from '../constants/equipmentType.constants';

export const getEquipmentTypes = (params, actions) => ({
  type: ActionTypes.GET_EQUIPMENT_TYPES,
  payload: params,
  actions,
});

export const getEquipmentType = (uuid, actions) => ({
    type: ActionTypes.GET_EQUIPMENT_TYPE,
    payload: uuid,
    actions,
  });

export const addEquipmentType = (params, actions) => ({
  type: ActionTypes.ADD_EQUIPMENT_TYPE,
  payload: params,
  actions,
});

export const updateEquipmentType = (uuid, params, actions) => ({
  type: ActionTypes.UPDATE_EQUIPMENT_TYPE,
  payload: { uuid, params },
  actions,
});

export const deleteEquipmentType = (uuid, actions) => ({
  type: ActionTypes.DELETE_EQUIPMENT_TYPE,
  payload: uuid,
  actions,
});
