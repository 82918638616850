import React, { Component, useState } from 'react';
import i18n from '../../../i18n';
import { Modal, ModalBody, ModalHeader, ModalFooter, CustomInput } from 'reactstrap';
import Loader from '../../../components/Loader';
import cx from 'classnames';

import { Authorities } from '../../../constants/roles.constants';
import { FormInput } from '../../../components/Form';

const ModalRole = ({
  edit,
  name,
  description,
  params,
  isOpen,
  onClose,
  onSubmit,
  loading,
  className,
  changeValue,
  changeAuthValue,
}) => {
  return (
    <Modal isOpen={isOpen} className={cx('', className)} fade>
      <ModalHeader toggle={onClose}>
        {edit ? i18n.t('rolesModals:editTitle') : i18n.t('rolesModals:addTitle')}
      </ModalHeader>
      <form autoComplete="off">
        <ModalBody>
          <div className="form-fields mb-28">
            <FormInput
              name="name"
              type="text"
              label={i18n.t('rolesModals:labelName')}
              value={name}
              className="form-item form-item--one"
              onChange={(name, value) => changeValue(name, value)}
              errorMessage={false}
            />
          </div>
          <div className="form-fields mb-28">
            <FormInput
              name="description"
              type="text"
              label={i18n.t('rolesModals:description')}
              value={description}
              className="form-item form-item--one"
              onChange={(name, value) => changeValue(name, value)}
              errorMessage={false}
            />
          </div>

          <div className="mb-10">
            <h2 className="form-group-title">{i18n.t('rolesModals:labelPermissions')}</h2>
            <div className="table__wrapper">
              <div className="table__wrapper__form-inner table__wrapper__inner scrollable--table">
                <table className="table__table">
                  <thead>
                    <tr>
                      <th></th>
                      <th key={'Page/Modal'} align={'left'}>
                        Page/Modal
                      </th>
                      <th key={'View'} align={'right'}>
                        View
                      </th>
                      <th key={'Edit'} align={'right'}>
                        Edit
                      </th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {Authorities.map((auth, index) => (
                      <tr key={auth.id}>
                        <td></td>
                        <td key={index} id={'Label'} align={'left'}>
                          {auth.name}
                        </td>
                        <td id={'View1'} align={'right'}>
                          {auth.view && (
                            <CustomInput
                              name={`${auth.name}-view`}
                              id={`${auth.id}-view`}
                              disabled={params?.includes('ALL')}
                              type="checkbox"
                              checked={params?.includes(`READ_${auth.id}`)}
                              onChange={(e) => changeAuthValue(`READ_${auth.id}`, e.target.checked)}
                            />
                          )}
                        </td>
                        <td key={'UsersEdit1'} id={'Edit1'} align={'right'}>
                          {auth.edit && (
                            <CustomInput
                              name={`${auth.name}-edit`}
                              id={`${auth.id}-edit`}
                              disabled={params?.includes('ALL') && auth.id !== 'ALL'}
                              type="checkbox"
                              checked={params?.includes(auth.id === 'ALL' ? auth.id : `WRITE_${auth.id}`)}
                              onChange={(e) =>
                                changeAuthValue(
                                  auth.id === 'ALL' ? auth.id : `WRITE_${auth.id}`,
                                  e.target.checked
                                )
                              }
                            />
                          )}
                        </td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Loader loading={loading} />
          {null}
          <button type="button" className="btn btn--dark" onClick={onClose}>
            {i18n.t('buttons:cancel')}
          </button>
          <button
            className="btn btn--secondary"
            type="button"
            disabled={name === '' || params.length === 0}
            onClick={() => onSubmit()}
          >
            {i18n.t('buttons:save')}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ModalRole;
