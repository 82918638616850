class StoreService {
  constructor() {
    this._dispatch = null;
    this._getState = null;
  }

  init(dispatch, getState) {
    this._dispatch = dispatch;
    this._getState = getState;
  }

  dispatch(val) {
    if (this._dispatch) {
      return this._dispatch(val);
    }

    return null;
  }

  getState() {
    if (this._getState) {
      return this._getState();
    }

    return null;
  }
}

export default new StoreService();
