import React from 'react';
import moment from 'moment';

import {
  SUBJECT_SEX_MALE,
  SUBJECT_SEX_FEMALE,
  SUBJECT_SEX_OTHER,
} from '../../../constants/subjects.constants';
import { isNotEmptyString } from '../../../utils';
import i18n from '../../../i18n';

export const getSubjectAge = subject => {
  let result = '';
  if (subject.birthday) {
    result = moment(subject.birthday).format('LL');
  } else if (isNotEmptyString(subject.age)) {
    result = `${subject.age} years`;
  }
  return result ? <span>{result}</span> : null;
};

export const getSubjectSex = sex => {
  switch (sex) {
    case SUBJECT_SEX_MALE: {
      return i18n.t('subjects:sexMale');
    }
    case SUBJECT_SEX_FEMALE: {
      return i18n.t('subjects:sexFemale');
    }
    case SUBJECT_SEX_OTHER: {
      return i18n.t('subjects:sexOther');
    }
    default: {
      return '';
    }
  }
};
