import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PlayerCollapse from '../../PlayerCollapse';
import i18n from '../../../../i18n';
import { CustomInput, Table } from 'reactstrap';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import cx from 'classnames';
import Loading from '../../../Loading';
import { HeatMap } from './HeatMap';
import { contrastingColors } from '../../../../constants/workflows.constants';

const PlayerOptions = ({
  playerAnalytics,
  playerHeatmap,
  playerWorkflows,
  playerAccumAnalytics,
  activeWorkflowUuid,
  chooseWorkflow,
  options,
  setHeatmapInterval,
  objects,
  activity,
  accumActivity,
  lines,
  zones,
  queue,
  InOutWidget,
  changeOptionValue,
  started,
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  return playerAnalytics || playerWorkflows ? (
    <div className="player-collapse__player-options">
      <PlayerCollapse title={i18n.t('player:options')}>
        <Dropdown
          className={cx('filter-item mt-3', { 'filter-item--active': isSelectOpen })}
          isOpen={isSelectOpen}
          toggle={() => setIsSelectOpen(!isSelectOpen)}
        >
          <DropdownToggle className="w-80">
            <p className="filter-item__title">
              {!activeWorkflowUuid
                ? 'Workflow'
                : playerWorkflows
                    .find((workflow) => workflow.value === activeWorkflowUuid)
                    .label?.slice(0, 20)}
            </p>
            <FontAwesomeIcon
              icon={isSelectOpen ? faChevronUp : faChevronDown}
              className="filter-item__chevron-down"
            />
          </DropdownToggle>
          <DropdownMenu container="body">
            {playerWorkflows?.map((item, index) => (
              <DropdownItem
                key={index}
                onClick={() => {
                  chooseWorkflow(item);
                }}
                divider={item.divider}
              >
                {item.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        {!started && (
          <small style={{ fontSize: '12px' }} className="text-danger ml-2">
            Workflow is inactive
          </small>
        )}
        {objects && (
          <div className="player-collapse__player-options__item">
            <p>Show objects</p>
            <CustomInput
              type="switch"
              id="showObjects"
              name="showObjects"
              checked={options.isShowObjects}
              onChange={() => changeOptionValue('isShowObjects', !options.isShowObjects)}
            />
          </div>
        )}
        {lines && (
          <div className="player-collapse__player-options__item">
            <p>Show lines</p>
            <CustomInput
              type="switch"
              id="showLines"
              name="showLines"
              checked={options.isShowLines}
              onChange={() => changeOptionValue('isShowLines', !options.isShowLines)}
            />
          </div>
        )}
        {zones && (
          <div className="player-collapse__player-options__item">
            <p>Show zones</p>
            <CustomInput
              type="switch"
              id="showZones"
              name="showZones"
              checked={options.isShowZones}
              onChange={() => changeOptionValue('isShowZones', !options.isShowZones)}
            />
          </div>
        )}
      </PlayerCollapse>
      {InOutWidget && InOutWidget.length > 0 && (
        <PlayerCollapse title={i18n.t('player:inout')}>
          <Table
            responsive
            style={{ color: 'white', marginTop: '10px', fontSize: '12px' }}
            className="widget-table"
            size="sm"
          >
            <thead>
              <tr>
                <th>LINE</th>
                <th>IN</th>
                <th>OUT</th>
              </tr>
            </thead>
            <tbody>
              {InOutWidget.map((inout, index) => (
                <tr style={{ color: contrastingColors[index] }}>
                  <td>{inout.name}</td>
                  <td>{inout.in ? inout.in : '--'}</td>
                  <td>{inout.out ? inout.out : '--'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </PlayerCollapse>
      )}
      {queue && queue.length > 0 && (
        <PlayerCollapse title={i18n.t('player:queue')}>
          <div className="player-collapse__player-options__item player-collapse__player-options__item--analytics">
            <p>QUEUE SIZE</p>
            {queue.map((item, index) => (
              <div
                key={index}
                className="player-collapse__player-options__item"
                style={{ color: `${contrastingColors[item.index]}` }}
              >
                <span>{item.name}</span>
                <span>{item.members}</span>
              </div>
            ))}
          </div>
        </PlayerCollapse>
      )}
      {playerHeatmap[0] && (
        <PlayerCollapse title={i18n.t('player:heatmap')}>
          {!playerHeatmap[1] ? (
            <HeatMap
              className="player-collapse__player-options__item"
              classDate="d-flex flex-column"
              heatmapValue={playerHeatmap[2]}
              setHeatmapInterval={(minDate, maxDate) => setHeatmapInterval(minDate, maxDate)}
              buttons={['Set Interval', 'Get heat map']}
              onChange={() => changeOptionValue('isShowObjects', !options.isShowObjects)}
            />
          ) : (
            <Loading />
          )}
        </PlayerCollapse>
      )}

      {accumActivity && playerAccumAnalytics.length !== 0 && (
        <PlayerCollapse title={i18n.t('player:accumAnalytics')}>
          {playerAccumAnalytics?.map((option, index) => (
            <div
              key={index}
              className="player-collapse__player-options__item player-collapse__player-options__item--analytics"
            >
              {option.title && <p style={{ color: contrastingColors[option.index] }}>{option.title}</p>}
              {option.data?.map((item, index) => (
                <p key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{item.text}</span>
                  <span>{item.value}</span>
                </p>
              ))}
            </div>
          ))}
        </PlayerCollapse>
      )}

      {activity && playerAnalytics.length !== 0 && (
        <PlayerCollapse title={i18n.t('player:analytics')}>
          {playerAnalytics.map((option, index) => (
            <div
              key={index}
              className="player-collapse__player-options__item player-collapse__player-options__item--analytics"
            >
              {option.title && <p style={{ color: contrastingColors[option.index] }}>{option.title}</p>}
              <p style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Working time</span>
                <span>{option.value}</span>
              </p>
            </div>
          ))}
        </PlayerCollapse>
      )}
    </div>
  ) : null;
};

export default PlayerOptions;
