export const GET_LIMITS = 'GET_LIMITS';
export const GET_LIMITS_RESULT = 'GET_LIMITS_RESULT';
export const GET_LIMITS_ERROR = 'GET_LIMITS_ERROR';

export const GET_MY_LIMITS = 'GET_MY_LIMITS';
export const GET_MY_LIMITS_RESULT = 'GET_MY_LIMITS_RESULT';
export const GET_MY_LIMITS_ERROR = 'GET_MY_LIMITS_ERROR';

export const GET_USER_ALL_LIMITS = 'GET_USER_ALL_LIMITS';
export const GET_USER_ALL_LIMITS_RESULT = 'GET_USER_ALL_LIMITS_RESULT';
export const GET_USER_ALL_LIMITS_ERROR = 'GET_USER_ALL_LIMITS_ERROR';

export const GET_LIMIT = 'GET_LIMIT';
export const GET_LIMIT_RESULT = 'GET_LIMIT_RESULT';
export const GET_LIMIT_ERROR = 'GET_LIMIT_ERROR';

export const ADD_LIMIT = 'ADD_LIMIT';
export const ADD_LIMIT_SUCCESS = 'ADD_LIMIT_SUCCESS';
export const ADD_LIMIT_ERROR = 'ADD_LIMIT_ERROR';

export const UPDATE_LIMIT = 'UPDATE_LIMIT';
export const UPDATE_LIMIT_SUCCESS = 'UPDATE_LIMIT_SUCCESS';
export const UPDATE_LIMIT_ERROR = 'UPDATE_LIMIT_ERROR';

export const DELETE_LIMIT = 'DELETE_LIMIT';
export const DELETE_LIMIT_SUCCESS = 'DELETE_LIMIT_SUCCESS';
export const DELETE_LIMIT_ERROR = 'DELETE_LIMIT_ERROR';

export const GET_ACCOUNT_LIMITS = 'GET_ACCOUNT_LIMITS';
export const GET_ACCOUNT_LIMITS_RESULT = 'GET_ACCOUNT_LIMITS_RESULT';
export const GET_ACCOUNT_LIMITS_ERROR = 'GET_ACCOUNT_LIMITS_ERROR';

export const GET_ACCOUNT_LIMIT = 'GET_ACCOUNT_LIMIT';
export const GET_ACCOUNT_LIMIT_RESULT = 'GET_ACCOUNT_LIMIT_RESULT';
export const GET_ACCOUNT_LIMIT_ERROR = 'GET_ACCOUNT_LIMIT_ERROR';

export const ADD_ACCOUNT_LIMIT = 'ADD_ACCOUNT_LIMIT';
export const ADD_ACCOUNT_LIMIT_SUCCESS = 'ADD_ACCOUNT_LIMIT_SUCCESS';
export const ADD_ACCOUNT_LIMIT_ERROR = 'ADD_ACCOUNT_LIMIT_ERROR';

export const UPDATE_ACCOUNT_LIMIT = 'UPDATE_ACCOUNT_LIMIT';
export const UPDATE_ACCOUNT_LIMIT_SUCCESS = 'UPDATE_ACCOUNT_LIMIT_SUCCESS';
export const UPDATE_ACCOUNT_LIMIT_ERROR = 'UPDATE_ACCOUNT_LIMIT_ERROR';

export const DELETE_ACCOUNT_LIMIT = 'DELETE_ACCOUNT_LIMIT';
export const DELETE_ACCOUNT_LIMIT_SUCCESS = 'DELETE_ACCOUNT_LIMIT_SUCCESS';
export const DELETE_ACCOUNT_LIMIT_ERROR = 'DELETE_ACCOUNT_LIMIT_ERROR';

export const CHANGE_PAGE_VALUE = 'CHANGE_PAGE_VALUE';
export const CHANGE_LIMIT_VALUE = 'CHANGE_LIMIT_VALUE';
export const CHANGE_MODAL_VALUE = 'CHANGE_MODAL_VALUE';

export const Resources = [
  { label: 'ANALYTICS_FPS', value: 'ANALYTICS_FPS' },
  { label: 'ANALYTIC_INSTANCE', value: 'ANALYTIC_INSTANCE' },
  { label: 'CAMERA_CONSUMER_FPS', value: 'CAMERA_CONSUMER_FPS' },
  { label: 'CAMERA_CONSUMER_INSTANCE', value: 'CAMERA_CONSUMER_INSTANCE' },
  { label: 'EG_FPS', value: 'EG_FPS' },
  { label: 'PROCESSING_FPS', value: 'PROCESSING_FPS' },
  { label: 'PROCESSING_INSTANCE', value: 'PROCESSING_INSTANCE' },
  { label: 'TOTAL_PROCESSING_FPS', value: 'TOTAL_PROCESSING_FPS' },
  { label: 'TOTAL_PROCESSING_INSTANCE', value: 'TOTAL_PROCESSING_INSTANCE' },
];

export const TotalResourcesRows = [
  { label: 'Camera Consumer Instance', value: 'CAMERA_CONSUMER_INSTANCE' },
  { label: 'Total Processing FPS', value: 'TOTAL_PROCESSING_FPS' },
  { label: 'Total Processing Instance', value: 'TOTAL_PROCESSING_INSTANCE' },
  { label: 'Analytics FPS', value: 'ANALYTICS_FPS' },
  { label: 'Analytics Instance', value: 'ANALYTICS_INSTANCE' },
  { label: 'Event Generator FPS', value: 'EG_FPS' },
];
