import * as ActionTypes from '../constants/equipment.constants';

export const getEquipments = (params, actions) => ({
  type: ActionTypes.GET_EQUIPMENTS,
  payload: params,
  actions,
});

export const getEquipment = (uuid, actions) => ({
    type: ActionTypes.GET_EQUIPMENT,
    payload: uuid,
    actions,
  });

export const addEquipment = (params, actions) => ({
  type: ActionTypes.ADD_EQUIPMENT,
  payload: params,
  actions,
});

export const updateEquipment = (uuid, params, actions) => ({
  type: ActionTypes.UPDATE_EQUIPMENT,
  payload: { uuid, params },
  actions,
});

export const deleteEquipment = (uuid, actions) => ({
  type: ActionTypes.DELETE_EQUIPMENT,
  payload: uuid,
  actions,
});
