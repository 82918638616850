import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import UploadPhotos from '../../../components/UploadPhotos';
import * as actions from '../../../actions/subject.actions';
import i18n from '../../../i18n';

class PersonPhotos extends Component {
  render() {
    const { person, updatePerson, getCallback } = this.props;
    return (
      <div className="file-upload">
        <label>
          {i18n.t('imageUploader:labelPhotos')}
          <button type="button" className="btn btn--link" id="photos-requirements">
            <FontAwesomeIcon icon="info-circle" />
            {i18n.t('imageUploader:labelRequirements')}
          </button>
          <UncontrolledPopover trigger="legacy" placement="bottom-start" target="photos-requirements">
            <PopoverBody>
              <p>{i18n.t('imageUploader:requirementsTooltipPartOne')}</p>
              <p>{i18n.t('imageUploader:requirementsTooltipPartTwo')}</p>
              <p>{i18n.t('imageUploader:requirementsTooltipPartThree')}</p>
              <p>{i18n.t('imageUploader:requirementsTooltipPartFour')}</p>
            </PopoverBody>
          </UncontrolledPopover>
        </label>
        <UploadPhotos subject={person} get={getCallback} update={updatePerson} />
      </div>
    );
  }
}

PersonPhotos.propTypes = {
  updateSubject: PropTypes.func,
  getCallback: PropTypes.func,
  person: PropTypes.object,
};

const mapStateToProps = ({ personView }) => {
  return { person: personView.person.person };
};

const mapDispatchToProps = (dispatch) => ({
  updatePerson: (params, reqActions) => dispatch(actions.updatePerson(params, reqActions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonPhotos);
