import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

const PageWrapper = ({ onBackClick, title, children }) => {
  const history = useHistory();

  const onButtonClick = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      history.location.key ? history.goBack() : window.history.back();
    }
  };
  return (
    <div className="page-content-inner d-flex flex-column h-100">
      <div className="page-content__header">
        <div className="d-flex align-items-center">
          <button className="btn btn---transparent btn--text-primary p-0 mr-2" onClick={onButtonClick}>
            <FontAwesomeIcon
              className="page-content__header-icon"
              icon={faArrowCircleLeft}
              onClick={onBackClick}
            />
          </button>
          <h1 className="page-title">{title}</h1>
        </div>
      </div>
      <div className="page-content__content overflow-auto">{children}</div>
    </div>
  );
};

PageWrapper.propTypes = {
  onBackClick: PropTypes.func,
  title: PropTypes.string,
};

export default PageWrapper;
