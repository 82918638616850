import * as ActionTypes from '../constants/subjects.constants';

const initialState = {
  list: {
    items: [],
    total: 0,
  },
  query: {
    name: '',
    size: 10,
    page: 0,
  },
  isLoading: false,
};

export const carsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CARS:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_CARS_RESULT: {
      const { items, total, size, page } = action.payload;
      return {
        ...state,
        list: { items, total },
        query: { size, page, name: state.query.name },
        isLoading: false,
      };
    }

    case ActionTypes.GET_CARS_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
