import request from '../utils/request';

export function getProcessingTypes() {
  return request({
    url: '/schemas/processing',
    method: 'get',
  });
}

export function getAnalyticTypes(processingType) {
  return request({
    url: `/schemas/processing/${processingType}/analytics`,
    method: 'get',
  });
}

export function getTriggerTypes(analyticType) {
  return request({
    url: `/schemas/analytics/${analyticType}/triggers`,
    method: 'get',
  });
}

export function getProcessingSchema(type) {
  return request({
    url: `/schemas/processing/${type}`,
    method: 'get',
  });
}

export function getModelSchema(procConfUUID, modelId) {
  return request({
    url: `/schemas/model/${procConfUUID}/${modelId}`,
    method: 'get',
  });
}

export function getAnalyticSchema(type) {
  return request({
    url: `/schemas/analytics/${type}`,
    method: 'get',
  });
}

export function getTriggerSchema(type) {
  return request({
    url: `/schemas/triggers/${type}`,
    method: 'get',
  });
}
