import * as ActionTypes from '../constants/app.constants';

const initialState = {
  user: {},
  isLoading: false,
  isLoggedIn: !!localStorage.getItem('token') || false,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_USER:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.LOGIN_USER_RESULT:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
      };
    case ActionTypes.LOGIN_USER_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.SIGN_OUT_USER_RESULT:
      return {
        ...state,
        user: {},
        isLoggedIn: false,
        isLoading: false,
      };
    default:
      return state;
  }
};
