import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

import Loading from '../../../../components/Loading';
import { base64FromString } from '../../../../utils';
import * as actions from '../../../../actions/cameras.actions';
import { LOADING_LIMIT } from '../../../../constants/cameras.constants';
import i18n from '../../../../i18n';

class CameraListElementScreens extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this.subscribe = null;
  }

  render() {
    const { camera, isVisible, enabled, heatmap, config } = this.props;

    return isVisible ? (
      !enabled ? (
        <div className="error">
          <FontAwesomeIcon icon={faVideoSlash} />
          <span>{i18n.t('cameraModals:inactiveCamera')}</span>
        </div>
      ) : config && config.online ? (
        <video
          src={config.heatmapOnline}
          style={{
            width: '100%',
            height: '100%',
          }}
          autoPlay={true}
          loop={true}
        />
      ) : (
        <>
          <img src={camera.previewUrl} alt={camera.name} />
          {heatmap && (
            <img
              src={config.heatmapStatic}
              style={{
                zIndex: 2,
                position: 'absolute',
                opacity: '0.35',
                filter: 'alpha(opacity=35)',
              }}
            />
          )}
        </>
      )
    ) : null;
  }
}

const mapStateToProps = ({ cameras }, { camera, thumbnail }) => {
  const uuid = camera.uuid;
  const screencast = cameras.screencasts.find((x) => x.uuid === uuid);
  return {
    thumbnail:
      screencast && screencast && screencast.thumb !== thumbnail ? base64FromString(screencast.thumb) : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setCameraSubscribe: (frame) => dispatch(actions.setCameraSubscribe(frame)),
  setCameraUnSubscribe: (uuid) => dispatch(actions.setCameraUnSubscribe(uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CameraListElementScreens);
