export const _VIA_USER_ROLE = { ADMIN: 1, ANNOTATOR: 2, REVIEWER: 3 };
export const _VIA_STORE_TYPE = { LOCALSTORAGE: 1, COUCHDB: 2 };

export var _VIA_SVG_NS = 'http://www.w3.org/2000/svg';

export var _VIA_PROJECT_ID_MARKER = '__VIA_PROJECT_ID__';
export var _VIA_PROJECT_REV_ID_MARKER = '__VIA_PROJECT_REV_ID__';
export var _VIA_PROJECT_REV_TIMESTAMP_MARKER = '__VIA_PROJECT_REV_TIMESTAMP__';

export var _VIA_FILE_SELECT_TYPE = { JSON: 2, CSV: 4, TEXT: 8, IMAGE: 16, VIDEO: 32, AUDIO: 64, WEBVTT: 128 };
export var _VIA_MERGE_STRATEGY = { THREE_WAY: 1 };

export var _VIA_ZOOM_MODE = { FITHEIGHT: 1, FITWIDTH: 2, SCALE: 3 };
export var _VIA_DEFAULT_ZOOM_MODE = _VIA_ZOOM_MODE.FITHEIGHT;
export var _VIA_ZOOM_SCALE_VALUE_LIST = [0.2, 0.5, 0.8, 1.0, 2.0, 4.0, 8.0];
export var _VIA_ZOOM_SCALE_DEFAULT_INDEX = 3;
