import React, { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { CustomInput } from 'reactstrap';
import { faChevronLeft, faChevronRight, faClock } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome';

export const HeatMap = ({
  className,
  classDate = '',
  setHeatmapInterval,
  buttons,
  heatmapValue = { interval: 0 },
  onChange,
}) => {
  const [online, setOnline] = useState(heatmapValue.online ? heatmapValue.online : false);
  const [minimaldate, setMinimalDate] = useState(
    heatmapValue.start ? new Date(heatmapValue.start) : new Date()
  );
  const [maximaldate, setMaximalDate] = useState(heatmapValue.end ? new Date(heatmapValue.end) : new Date());
  const [intervalDays, setIntervalDays] = useState(Math.floor(heatmapValue.interval / 86400000));
  const [intervalHours, setIntervalHours] = useState(
    Math.floor((heatmapValue.interval % 86400000) / 3600000)
  );
  const [intervalMinutes, setIntervalMinutes] = useState(
    Math.floor(((heatmapValue.interval % 86400000) % 3600000) / 60000)
  );

  library.add(faChevronLeft, faChevronRight, faClock);
  return (
    <>
      <div className={`${className}`}>
        <p>Online update heat map</p>
        <CustomInput
          type="switch"
          id="showHeatmap"
          name="showHeatmap"
          checked={online}
          onChange={() => {
            setOnline((prev) => !prev);
            onChange && onChange();
          }}
        />
      </div>
      {online ? (
        <div className={`${className}--analytics`}>
          <p>Accumulation interval</p>
          <div className="input-player-container">
            <input
              type="number"
              className="input-player-date"
              name="days"
              placeholder="dd"
              value={intervalDays}
              min="0"
              max="100"
              onChange={(e) => setIntervalDays(e.target.value)}
            ></input>
            <span className="input-player-span">d</span>
            <input
              type="number"
              className="input-player-date"
              name="hours"
              placeholder="hh"
              value={intervalHours}
              min="0"
              max="24"
              onChange={(e) => setIntervalHours(e.target.value)}
            ></input>
            <span className="input-player-span">h</span>
            <input
              type="number"
              className="input-player-date"
              name="minutes"
              placeholder="mm"
              value={intervalMinutes}
              min="0"
              max="60"
              step="5"
              onChange={(e) => setIntervalMinutes(e.target.value)}
            ></input>
            <span className="input-player-span">m</span>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn--secondary mt-2 pl-4 pr-4"
              onClick={() =>
                setHeatmapInterval(
                  intervalDays * 86400000 + intervalHours * 3600000 + intervalMinutes * 60000,
                  null
                )
              }
            >
              {buttons[0]}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};
