export const CHANGE_VALUE = 'CHANGE_VALUE';

export const ADD_CAMERA = 'ADD_CAMERA';
export const REMOVE_CAMERA = 'REMOVE_CAMERA';
export const CHANGE_CAMERA = 'CHANGE_CAMERA';

export const ADD_TRIGGER = 'ADD_TRIGGER';
export const CHANGE_TRIGGER = 'CHANGE_TRIGGER';
export const REMOVE_TRIGGER = 'REMOVE_TRIGGER';

export const changeValue = (key, value) => ({
  type: CHANGE_VALUE,
  payload: {
    key,
    value,
  },
});

export const addCamera = () => ({
  type: ADD_CAMERA,
});

export const removeCamera = (index) => ({
  type: REMOVE_CAMERA,
  payload: { index },
});

export const changeCamera = (index, key, value) => ({
  type: CHANGE_CAMERA,
  payload: { index, key, value },
});

export const addTrigger = () => ({
  type: ADD_TRIGGER,
});

export const removeTrigger = (index) => ({
  type: REMOVE_TRIGGER,
  payload: { index },
});

export const changeTrigger = (index, key, value) => ({
  type: CHANGE_TRIGGER,
  payload: { index, key, value },
});
