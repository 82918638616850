export const _VIA_NAME = 'VGG Image Annotator';
export const _VIA_NAME_SHORT = 'VIA';
export const _VIA_VERSION = '3.0.11';
export const _VIA_URL_CODE_REPO = 'https://gitlab.com/vgg/via';
export const _VIA_URL_PROJECT_PAGE = 'https://www.robots.ox.ac.uk/~vgg/software/via/';

export const _VIA_CONFIG = { MSG_TIMEOUT: 3000 };

export const _VIA_REMOTE_STORE = 'https://zeus.robots.ox.ac.uk/via/store/3.x.y/';
export const _VIA_REMOTE_TIMEOUT = 6000; // in milliseconds

export const _VIA_DEFAULT_ATTRIBUTE_ANCHOR_ID = '';

export var _VIA_FLOAT_FIXED_POINT = 5; // floats are stored as 5 decimal places
export var _VIA_SPATIAL_REGION_MOVE_DELTA = 10; // in pixels
export var _VIA_SPATIAL_REGION_LABEL_MAXLENGTH = 12; // in characters
export var _VIA_SPATIAL_REGION_LABEL_FONT = '12px Sans';
