import * as ActionTypes from '../constants/limits.constants';

export const getMyLimits = (params, actions) => ({
  type: ActionTypes.GET_MY_LIMITS,
  payload: params,
  actions,
});

export const getLimits = (params, actions) => ({
  type: ActionTypes.GET_LIMITS,
  payload: params,
  actions,
});

export const getLimit = (id, actions) => ({
  type: ActionTypes.GET_LIMIT,
  payload: id,
  actions,
});

export const addLimit = (params, actions) => ({
  type: ActionTypes.ADD_LIMIT,
  payload: params,
  actions,
});

export const updateLimit = (params, actions) => ({
  type: ActionTypes.UPDATE_LIMIT,
  payload: params,
  actions,
});

export const deleteLimit = (uuid, actions) => ({
  type: ActionTypes.DELETE_LIMIT,
  payload: uuid,
  actions,
});

export const getAccountLimits = (params, actions) => ({
  type: ActionTypes.GET_ACCOUNT_LIMITS,
  payload: params,
  actions,
});

export const getAccountLimit = (id, actions) => ({
  type: ActionTypes.GET_ACCOUNT_LIMIT,
  payload: id,
  actions,
});

export const addAccountLimit = (params, actions) => ({
  type: ActionTypes.ADD_ACCOUNT_LIMIT,
  payload: params,
  actions,
});

export const updateAccountLimit = (params, actions) => ({
  type: ActionTypes.UPDATE_ACCOUNT_LIMIT,
  payload: params,
  actions,
});

export const deleteAccountLimit = (uuid, actions) => ({
  type: ActionTypes.DELETE_ACCOUNT_LIMIT,
  payload: uuid,
  actions,
});

export const getUserAllLimits = (uuid, actions) => ({
  type: ActionTypes.GET_USER_ALL_LIMITS,
  payload: uuid,
  actions,
});

export const changePageValue = (name, page) => ({
  type: ActionTypes.CHANGE_PAGE_VALUE,
  payload: { name, page },
});

export const changeModalValue = (key, value) => ({
  type: ActionTypes.CHANGE_MODAL_VALUE,
  payload: { key, value },
});

export const changeValue = (key, value) => ({
  type: ActionTypes.CHANGE_LIMIT_VALUE,
  payload: { key, value },
});
