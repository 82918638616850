import * as ActionTypes from '../constants/cameras.constants';
import DateTimeService from '../services/DateTimeService';
import { duplicateVideo } from '../utils';

import airportVideo from '../mocks/clips/airport_15fps_origin.mp4';
import airportVideoDetections from '../mocks/clips/show_objects_video_test.mp4';
import moment from 'moment';

const initialState = {
  list: {
    items: [],
    total: 0,
    size: 10,
    page: 0,
  },
  isLoading: false,
  isError: null,
};

export const cameraRecordingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CAMERA_RECORDINGS:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.SET_CAMERA_RECORDINGS_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          items: action.payload,
          total: action.payload.length,
        },
      };

    case ActionTypes.GET_CAMERA_RECORDINGS_RESULT:
      return {
        ...state,
        list: {
          ...action.payload,
          items: action.payload.items.map((x) => ({
            from: DateTimeService.timestamps(x.from),
            to: x.to ? DateTimeService.timestamps(x.to) : null,
            url: x.url,
          })),
        },
        isLoading: false,
      };

    case ActionTypes.GET_CAMERA_RECORDINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case ActionTypes.SET_CAMERA_UNMOUNT:
      return initialState;

    default:
      return state;
  }
};
