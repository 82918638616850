export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_RESULT = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR';

export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const ADD_ACCOUNT_ERROR = 'ADD_ACCOUNT_ERROR';

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR';

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';

export const CHANGE_ACCOUNT_VALUE = 'CHANGE_ACCOUNT_VALUE';
export const CHANGE_MODAL_ACCOUNT_VALUE = 'CHANGE_MODAL_ACCOUNT_VALUE';
export const CHANGE_ACCOUNT_PAGE_VALUE = 'CHANGE_ACCOUNT_PAGE_VALUE';

export const GET_USERS_ACCOUNT = 'GET_USERS_ACCOUNT';
export const GET_USERS_ACCOUNT_RESULT = 'GET_USERS_ACCOUNT_SUCCESS';
export const GET_USERS_ACCOUNT_ERROR = 'GET_USERS_ACCOUNT_ERROR';

export const GET_USER_TO_ACCOUNT = 'GET_USER_TO_ACCOUNT';

export const ADD_USER_TO_ACCOUNT = 'ADD_USER_TO_ACCOUNT';
export const ADD_USER_TO_ACCOUNT_SUCCESS = 'ADD_USER_TO_ACCOUNT_SUCCESS';
export const ADD_USER_TO_ACCOUNT_ERROR = 'ADD_USER_TO_ACCOUNT_ERROR';

export const UPDATE_USER_TO_ACCOUNT = 'UPDATE_USER_TO_ACCOUNT';
export const UPDATE_USER_TO_ACCOUNT_SUCCESS = 'UPDATE_USER_TO_ACCOUNT_SUCCESS';
export const UPDATE_USER_TO_ACCOUNT_ERROR = 'UPDATE_USER_TO_ACCOUNT_ERROR';

export const DELETE_USER_TO_ACCOUNT = 'DELETE_USER_TO_ACCOUNT';
export const DELETE_USER_TO_ACCOUNT_SUCCESS = 'DELET_USER_TOE_ACCOUNT_SUCCESS';
export const DELETE_USER_TO_ACCOUNT_ERROR = 'DELETE_USER_TO_ACCOUNT_ERROR';

export const GET_LIMITS_ACCOUNT = 'GET_LIMITS_ACCOUNT';
export const GET_LIMITS_ACCOUNT_RESULT = 'GET_LIMITS_ACCOUNT_SUCCESS';
export const GET_LIMITS_ACCOUNT_ERROR = 'GET_LIMITS_ACCOUNT_ERROR';

export const DELETE_LIMIT_TO_ACCOUNT = 'DELETE_LIMIT_TO_ACCOUNT';
export const DELETE_LIMIT_TO_ACCOUNT_SUCCESS = 'DELETE_LIMIT_TO_ACCOUNT_SUCCESS';
export const DELETE_LIMIT_TO_ACCOUNT_ERROR = 'DELETE_LIMIT_TO_ACCOUNT_ERROR';

export const CHANGE_ACCOUNT_USER_VALUE = 'CHANGE_ACCOUNT_USER_VALUE';
export const CHANGE_ACCOUNT_MODAL_USER_VALUE = 'CHANGE_ACCOUNT_MODAL_USER_VALUE';
export const CHANGE_ACCOUNT_USER_PAGE_VALUE = 'CHANGE_ACCOUNT_USER_PAGE_VALUE';
