import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../../i18n';
import { Formik } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Loader from '../../../../components/Loader';
import { FormInput, FormSelect, FormNumericInput, FormDatePicker } from '../../../../components/Form';
import ModelsService from '../../../../services/ModelsService';
import { isNotEmptyString } from '../../../../utils';
import 'react-datepicker/dist/react-datepicker.css';
import {
  SUBJECT_SEX_MALE,
  SUBJECT_SEX_FEMALE,
  SUBJECT_SEX_OTHER,
} from '../../../../constants/subjects.constants';
import isNull from 'lodash/isNull';

const SEX_ALLOWED_VALUES = [SUBJECT_SEX_MALE, SUBJECT_SEX_FEMALE, SUBJECT_SEX_OTHER];
const validationSchema = yup.object().shape({
  name: yup
    .string()
    .max(250, i18n.t('validationMessages:length', { field: 'name', length: 250 }))
    .required(i18n.t('validationMessages:required', { field: 'name' })),
  sex: yup.string(),
  birthday: yup.string().nullable(),
  age: yup.string().max(250, i18n.t('validationMessages:length', { field: 'age', length: 250 })),
  height: yup.string().max(250, i18n.t('validationMessages:length', { field: 'height', length: 250 })),
});

class BaseModal extends Component {
  state = {
    message: null,
  };

  static getDerivedStateFromProps(nextProps) {
    const {
      params: { errorMsg },
    } = nextProps;

    if (errorMsg) {
      return { message: errorMsg };
    }

    return null;
  }

  hideMessage = () => {
    if (this.state.message) {
      this.setState({ message: null });
      if (this.props.params.errorMsg && typeof this.props.params.resetError === 'function') {
        this.props.params.resetError();
      }
    }
  };

  onSubmit = (values, { setSubmitting }) => {
    const {
      params: { subject },
      onSubmit,
    } = this.props;

    if (
      subject.name === values.name &&
      subject.birthday === values.birthday &&
      subject.age === values.age &&
      subject.sex === values.sex &&
      subject.height === values.height
    ) {
      this.setState({ message: i18n.t('form:hasNoChanges') });
    } else {
      const newSubject = { ...subject, ...values, photos: this.state.photos };

      if (isNotEmptyString(newSubject.birthday)) {
        delete newSubject.age;
      } else {
        delete newSubject.birthday;
      }

      onSubmit(newSubject);
    }

    setSubmitting(false);
  };

  getSubjectPhotos() {
    return this.props.params.subject.photos;
  }

  renderSubjectPhotos() {}

  render() {
    const { message } = this.state;
    const {
      params: { isOpen, subject },
      header,
      onClose,
      loading,
      submitBtnText,
      closeBtnText,
      className,
    } = this.props;

    return (
      <Modal isOpen={isOpen} className={cx('', className)} fade>
        <ModalHeader toggle={onClose}>{header}</ModalHeader>
        <Formik
          onSubmit={this.onSubmit}
          initialValues={{
            name: subject.name,
            birthday: subject.birthday,
            age: subject.age,
            sex: subject.sex,
            height: subject.height,
          }}
          validationSchema={validationSchema}
        >
          {(props) => {
            const { values, touched, errors, isValid, handleBlur, handleSubmit, setFieldValue } = props;
            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <ModalBody>
                  <div className="form-fields mb-28">
                    <div className="form-row">
                      <FormInput
                        name="name"
                        type="text"
                        label={i18n.t('subjectModals:labelName')}
                        value={values.name}
                        className="form-item form-item--two"
                        onBlur={handleBlur}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                          this.hideMessage();
                        }}
                        errorMessage={errors.name && touched.name ? errors.name : ''}
                      />

                      <div className="form-item form-item--two">
                        <div className="form-group-field">
                          <label className="form-group-field-label">{i18n.t('subjectModals:labelSex')}</label>
                          <div className="form-radio-group">
                            <div className="form-group-radio">
                              <input
                                name="sex"
                                type="radio"
                                id="sex-1"
                                className="sr-only"
                                checked={
                                  values.sex === SUBJECT_SEX_MALE ||
                                  SEX_ALLOWED_VALUES.indexOf(values.sex) === -1
                                }
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue('sex', e.target.value);
                                  this.hideMessage();
                                }}
                                value={SUBJECT_SEX_MALE}
                              />
                              <label htmlFor="sex-1">{i18n.t('subjectModals:labelSexMale')}</label>
                            </div>

                            <div className="form-group-radio">
                              <input
                                name="sex"
                                type="radio"
                                id="sex-2"
                                className="sr-only"
                                checked={values.sex === SUBJECT_SEX_FEMALE}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue('sex', e.target.value);
                                  this.hideMessage();
                                }}
                                value={SUBJECT_SEX_FEMALE}
                              />
                              <label htmlFor="sex-2">{i18n.t('subjectModals:labelSexFemale')}</label>
                            </div>

                            <div className="form-group-radio">
                              <input
                                name="sex"
                                type="radio"
                                id="sex-3"
                                className="sr-only"
                                checked={values.sex === SUBJECT_SEX_OTHER}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue('sex', e.target.value);
                                  this.hideMessage();
                                }}
                                value={SUBJECT_SEX_OTHER}
                              />
                              <label htmlFor="sex-3">{i18n.t('subjectModals:labelSexOther')}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <FormDatePicker
                        label={i18n.t('subjectModals:labelBirthday')}
                        name="birthday"
                        value={values.birthday ? new Date(values.birthday) : null}
                        dropdownMode="select"
                        className="form-item form-item--two"
                        onBlur={handleBlur}
                        onChange={(name, date) => {
                          setFieldValue(name, !isNull(date) ? new Date(date).toISOString() : null);
                          setFieldValue('age', '');
                          this.hideMessage();
                        }}
                        placeholder="mm/dd/yyyy"
                        errorMessage={errors.birthday && touched.birthday ? errors.birthday : ''}
                      />

                      <FormNumericInput
                        name="age"
                        label={i18n.t('subjectModals:labelAge')}
                        min={0}
                        max={120}
                        step={1}
                        value={values.age}
                        onBlur={handleBlur}
                        className="form-item form-item--four"
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                          setFieldValue('birthday', null);
                          this.hideMessage();
                        }}
                        errorMessage={errors.age && touched.age ? errors.age : ''}
                      />
                    </div>

                    <div className="form-row">
                      <FormSelect
                        label={i18n.t('subjectModals:labelRole')}
                        name="role"
                        selected={{ value: 'superuser', label: 'Superuser' }}
                        options={[]}
                        isSearch={true}
                        className="form-item form-item--two"
                        onBlur={handleBlur}
                        onChange={(name, value) => {
                          this.hideMessage();
                        }}
                        errorMessage={errors.height && touched.height ? errors.height : ''}
                      />

                      <FormSelect
                        label={i18n.t('subjectModals:labelWorkingPlace')}
                        name="workingPlace"
                        selected={null}
                        options={[]}
                        isSearch={true}
                        className="form-item form-item--two"
                        onBlur={handleBlur}
                        onChange={(name, value) => {
                          this.hideMessage();
                        }}
                        errorMessage={errors.height && touched.height ? errors.height : ''}
                      />
                    </div>
                  </div>
                  {this.renderSubjectPhotos()}
                </ModalBody>

                <ModalFooter>
                  <Loader loading={loading} />
                  {message || null}
                  <button type="button" className="btn btn--dark" disabled={loading} onClick={onClose}>
                    {closeBtnText}
                  </button>
                  <button className="btn btn--secondary" type="submit" disabled={!isValid || loading}>
                    {submitBtnText}
                  </button>
                </ModalFooter>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

BaseModal.defaultProps = {
  loading: false,
  submitBtnText: i18n.t('buttons:save'),
  closeBtnText: i18n.t('buttons:cancel'),
};

BaseModal.propTypes = {
  params: PropTypes.shape({
    isOpen: PropTypes.bool,
    subject: ModelsService.getSubjectModel(),
  }),
  loading: PropTypes.bool,
  header: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  submitBtnText: PropTypes.string,
  closeBtnText: PropTypes.string,
  className: PropTypes.string,
};

export default BaseModal;
