import * as ActionTypes from '../constants/subjects.constants';

const initialState = {
  car: {},
  loadingGetCar: false,
  loadingAddCar: false,
  loadingUpdateCar: false,
  loadingDeleteCar: false,
};

export const carReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CAR:
      return {
        ...state,
        loadingGetCar: true,
      };

    case ActionTypes.ADD_CAR:
      return {
        ...state,
        loadingAddCar: true,
      };

    case ActionTypes.UPDATE_CAR:
      return {
        ...state,
        loadingUpdateCar: true,
      };

    case ActionTypes.DELETE_CAR:
      return {
        ...state,
        loadingDeleteCar: true,
      };

    case ActionTypes.GET_CAR_SUCCESS:
      return {
        ...state,
        car: action.payload,
        loadingGetCar: false,
      };

    case ActionTypes.UPDATE_CAR_SUCCESS:
      return {
        ...state,
        car: action.payload,
        loadingUpdateCar: false,
      };

    case ActionTypes.DELETE_CAR_SUCCESS:
      return {
        ...state,
        car: {},
        loadingDeleteCar: false,
      };

    case ActionTypes.GET_CAR_ERROR:
      return {
        ...state,
        loadingGetCar: false,
      };

    case ActionTypes.ADD_CAR_SUCCESS:
    case ActionTypes.ADD_CAR_ERROR:
      return {
        ...state,
        loadingAddPerson: false,
      };

    case ActionTypes.UPDATE_CAR_ERROR:
      return {
        ...state,
        loadingUpdateCar: false,
      };

    case ActionTypes.DELETE_CAR_ERROR:
      return {
        ...state,
        loadingDeleteCar: false,
      };

    case ActionTypes.RESET_CAR_STATE:
      return initialState;

    default:
      return state;
  }
};
