import moment from 'moment';

export const technicalReports = [
  {
    workflow: {
      uuid: '4f2e9ac1-df80-43c2-9501-8eac1ef5ef7d',
      name: 'Automatic License Plate Recognition',
      status: 'Active',
      lastStart: moment().subtract(7, 'days'),
      duration: moment().valueOf() - moment().subtract(7, 'days').valueOf(),
      instance: 1,
      fps: 10,
    },
    cameras: [
      {
        uuid: '58233a49-82b8-46b1-8093-ccf066765c2b',
        name: 'RTSP alpr drone video',
        fps: 10,
        status: 'Inactive',
        startDate: moment().subtract(6, 'days'),
        endDate: moment().subtract(2, 'days'),
      },
    ],
  },
  {
    workflow: {
      uuid: 'bfd2e636-5611-4013-b980-6da6907e637b',
      name: 'Automatic License Plate Recognition In The Wild',
      status: 'Active',
      lastStart: moment().subtract(36, 'hours'),
      duration: moment().valueOf() - moment().subtract(36, 'hours'),
      instance: 1,
      fps: 10,
    },
    cameras: [
      {
        uuid: 'cbf7f988-5dfa-4bbb-82bc-22a29e83c6bd',
        name: 'RTSP alpr in the wild video',
        fps: 10,
        status: 'Active',
        startDate: moment().subtract(36, 'hours'),
        endDate: null,
      },
    ],
  },
  {
    workflow: {
      uuid: 'd913427f-9a07-4a73-8508-202b90dcc354',
      name: 'Face Recognition',
      status: 'Active',
      lastStart: moment().subtract(776, 'hours'),
      duration: moment().valueOf() - moment().subtract(776, 'hours'),
      instance: 1,
      fps: 10,
    },
    cameras: [
      {
        uuid: '17103f67-f926-4c0e-9ef5-6f6ec5108c7e',
        name: 'RTSP face video',
        fps: 10,
        status: 'Active',
        startDate: moment().subtract(776, 'hours'),
        endDate: moment().subtract(6, 'hours'),
      },
    ],
  },
  {
    workflow: {
      uuid: 'c42f3c10-781b-4e40-807e-53b1264d324e',
      name: 'Heat Maps Generation By People Tracks',
      status: 'Active',
      lastStart: moment().subtract(76, 'hours'),
      duration: moment().valueOf() - moment().subtract(76, 'hours'),
      instance: 1,
      fps: 5,
    },
    cameras: [
      {
        uuid: 'e58b2df3-d924-4b15-95ee-90e90d462411',
        name: 'RTSP elevator video',
        fps: 5,
        status: 'Active',
        startDate: moment().subtract(76, 'hours'),
        endDate: null,
      },
    ],
  },
  {
    workflow: {
      uuid: '6eed24c3-3931-4e75-adf8-051c6b7fcc9a',
      name: 'Lines Crossing Based on Background Subtraction (Cars)',
      status: 'Active',
      lastStart: moment().subtract(215, 'hours'),
      duration: moment().valueOf() - moment().subtract(215, 'hours'),
      instance: 1,
      fps: 15,
    },
    cameras: [
      {
        uuid: '00edd2af-4514-4624-9a3e-345a112eaff0',
        name: 'RTSP alpr video 15FPS',
        fps: 15,
        status: 'Active',
        startDate: moment().subtract(215, 'hours'),
        endDate: moment().subtract(15, 'hours'),
      },
    ],
  },
  {
    workflow: {
      uuid: 'b81c229d-b8b6-4e19-b43e-620a07172eea',
      name: 'Lines Crossing Based on People Tracking (Detection Based)',
      status: 'Active',
      lastStart: moment().subtract(59, 'hours'),
      duration: moment().valueOf() - moment().subtract(59, 'hours'),
      instance: 1,
      fps: 10,
    },
    cameras: [
      {
        uuid: '82b1c09d-4f81-4c70-a449-f7e09f6ecfc4',
        name: 'RTSP shopping video',
        fps: 10,
        status: 'Active',
        startDate: moment().subtract(59, 'hours'),
        endDate: moment().subtract(9, 'hours'),
      },
    ],
  },
  {
    workflow: {
      uuid: 'd6c7c8ba-637e-4820-b615-55ba0ce19548',
      name: 'People Groups Analytics',
      status: 'Active',
      lastStart: moment().subtract(516, 'hours'),
      duration: moment().valueOf() - moment().subtract(516, 'hours'),
      instance: 1,
      fps: 10,
    },
    cameras: [
      {
        uuid: '82b1c09d-4f81-4c70-a449-f7e09f6ecfc4',
        name: 'RTSP shopping video',
        fps: 10,
        status: 'Active',
        startDate: moment().subtract(516, 'hours'),
        endDate: moment().subtract(116, 'hours'),
      },
    ],
  },
  {
    workflow: {
      uuid: '01993646-97d8-4744-8186-9e6207a377e3',
      name: 'People Queues Analytics',
      status: 'Active',
      lastStart: moment().subtract(305, 'hours'),
      duration: moment().valueOf() - moment().subtract(305, 'hours'),
      instance: 1,
      fps: 3,
    },
    cameras: [
      {
        uuid: '13a663f2-8b06-4989-98d8-1144b814d683',
        name: 'RTSP airport video (queues)',
        fps: 3,
        status: 'Active',
        startDate: moment().subtract(305, 'hours'),
        endDate: moment().subtract(105, 'hours'),
      },
    ],
  },
  {
    workflow: {
      uuid: 'ce4dd62d-ef6c-4c3d-ac89-2a15955f1fd2',
      name: 'Zone Occupancy Detection',
      status: 'Active',
      lastStart: moment().subtract(176, 'hours'),
      duration: moment().valueOf() - moment().subtract(176, 'hours'),
      instance: 1,
      fps: 10,
    },
    cameras: [
      {
        uuid: 'd57f4c61-5958-4c5b-8b32-ec6972acac69',
        name: 'RTSP elevator horizont video',
        fps: 10,
        status: 'Active',
        startDate: moment().subtract(176, 'hours'),
        endDate: moment().subtract(16, 'hours'),
      },
    ],
  },
  {
    workflow: {
      uuid: '299e6f06-5b63-43eb-8f4e-5fe97ec29023',
      name: 'Zones Activity Analytics Based on People Tracking',
      status: 'Active',
      lastStart: moment().subtract(1076, 'minutes'),
      duration: moment().valueOf() - moment().subtract(1076, 'hours'),
      instance: 1,
      fps: 5,
    },
    cameras: [
      {
        uuid: '21722ecf-6650-4449-b841-030b2ac2a589',
        name: 'RTSP factory video',
        fps: 5,
        status: 'Active',
        startDate: moment().subtract(1076, 'hours'),
        endDate: moment().subtract(576, 'hours'),
      },
    ],
  },
  {
    workflow: {
      uuid: 'da8705df-cb4b-44bb-a7bb-793e41ed3ca6',
      name: 'Zones Activity Analytics Based on Welding Detection',
      status: 'Active',
      lastStart: moment().subtract(13, 'hours'),
      duration: moment().valueOf() - moment().subtract(13, 'hours'),
      instance: 1,
      fps: 10,
    },
    cameras: [
      {
        uuid: '6826b5a5-81dd-4dcb-97ed-14fbbbb099aa',
        name: 'RTSP welding video',
        fps: 10,
        status: 'Active',
        startDate: moment().subtract(13, 'hours'),
        endDate: moment().subtract(10, 'hours'),
      },
    ],
  },
];
