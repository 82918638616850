export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_RESULT = 'LOGIN_USER_RESULT';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const SIGN_OUT_USER_RESULT = 'SIGN_OUT_USER_RESULT';
export const SIGN_OUT_USER_ERROR = 'SIGN_OUT_USER_ERROR';

export const MEDIA_QUERY_SM = {
  minWidth: 767,
};
